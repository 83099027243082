import React, { useContext, useState } from "react"
import './styles.scss'

import { clickUploadInput } from "containers/userSettings/utils"
import ImageCropper from "elements/imageCropper"
import ReactModal from "react-modal"
import { ReactComponent as Close } from 'assets/img/close.svg'
import miscUtils from "utils/miscUtils"
import { UserProfileContext } from "../UserProfile"
import { SSOMethod } from "containers/IntegrationsContainer/SingleSignOn/domain"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { UserService } from "api/services/users"
import imageCompression from "browser-image-compression"
import { login } from "store/actions/authorizeActions";
import store from "store/configureStore";
import { setCookieValue } from "utils/cookieStorage"
import { CommonConstants } from "constants/common"
import { toast, ToastType } from "react-toastify"

const ProfilePhoto = () => {

    const { userData, setUserData,
        editData, setEditData,
        uid,
        group, profileNewSrc,
        setProfileNewSrc, profileSrc, setProfileSrc,
        profileFile,
        setProfileFile,
        showProfilePhotoModal, setShowProfilePhotoModal,
    } = useContext(UserProfileContext)

    const [isCropperOpen, setIsCropperOpen] = useState<boolean>(false);

    const profileUpdatesDisabled = () => {
        return (
            group &&
            group.sso_enabled &&
            group.sso_type === SSOMethod.OAUTH &&
            group.oauth_sso &&
            group.oauth_sso.disable_user_profile_updates
        )
    }

    const getCustomLockMessage = () => {
        if (
            group &&
            group.oauth_sso
        ) {
            return group.oauth_sso.custom_user_profile_update_instruction;
        }

        return "...";
    }

    const profileUpdateLocked = () => {
        return (
            miscUtils.useNbeaFlow(group) ||
            (
                group &&
                group.sso_enabled &&
                group.sso_type &&
                group.sso_type === SSOMethod.OAUTH &&
                group.oauth_sso &&
                group.oauth_sso.disable_user_profile_updates
            )
        )
    }

    function handleProfileCrop(crop: any) {
        setProfileFile(crop.file);
        setProfileSrc(crop.url);
        setIsCropperOpen(false);
    }

    const getUserDetails = (isUserUpdated?: boolean) => {
        UserService.getInstance()
            .getUserProfile(uid)
            .then((userResponse: any) => {
                if (userResponse.data.profile_image) {
                    setProfileSrc(userResponse.data.profile_image);
                }
                if (isUserUpdated) {
                    store.store.dispatch(login(userResponse.data));
                    setCookieValue(CommonConstants.USER_KEY, userResponse.data);
                }
            });
    };

    const savePhoto = () => {
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        if (profileFile) {
            imageCompression(profileFile, options).then(function (
                compressedFile: any
            ) {
                const dataImage = new FormData();
                dataImage.append(
                    "profile_image",
                    miscUtils.blobToFile(compressedFile, profileFile)
                );
                UserService.getInstance()
                    .updateUserProfileImage(uid, dataImage)
                    .then((res: any) => {
                        if (res.data) {
                            setUserData({
                                ...userData,
                                profile_image: res.data.profile_image
                            })
                        }
                    })
                    .finally(() => {
                    getUserDetails(true)
                })
            });
        } else {
            UserService.getInstance().updateUserProfile(uid, editData)
                .finally(() => {
                    getUserDetails(true)
                })
        }
    }

    const deletePhoto = () => {
        setUserData({
            ...userData,
            profile_image: null
        })
        UserService.getInstance().updateUserProfile(
            uid, { profile_image: null }).finally(() => getUserDetails(true))
    }

    const [showChangePhoto, setShowChangePhoto] = useState<boolean>(false)

    React.useEffect(() => {
        if (profileFile) {

            const img = new Image();
            img.src = URL.createObjectURL(profileFile);

            img.onload = () => {
                if (img.height < 180 || img.width < 180) {
                    setProfileFile(null);
                    setIsCropperOpen(false);
                    toast("Minimal required size for profile image is 180x180.", {
                        type: ToastType.ERROR,
                        autoClose: 3000
                    });
                }
            };
        }
    }, [profileFile]);

    return (
        <ReactModal
            isOpen={showProfilePhotoModal}
            style={{
                content: {
                    width: '100%',
                    borderRadius: '4px',
                    height: '100%',
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    transform: "translate(-50%, -50%)",
                    position: "fixed",
                    background: "transparent",
                    border: 0,
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    flexDirection: 'column',
                },
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    zIndex: 99999,
                }
            }}
        >

            {profileUpdateLocked() ? (
                <div
                    className="locked-profile-container"
                >
                    <span
                        className="locked-profile-icon"
                    >
                        <FontAwesomeIcon
                            icon={faUserLock}
                        />
                    </span>
                    <p
                        className="locked-profile-label"
                        dangerouslySetInnerHTML={{
                            __html: getCustomLockMessage(),
                        }}
                    />
                </div>
            ) : (<></>)}

            <ImageCropper
                aspect={1}
                maxWidth={180}
                maxHeight={180}
                sourceImage={profileNewSrc}
                isOpen={isCropperOpen}
                onCropCancel={() => {
                    setProfileFile(null);
                    setProfileSrc(userData.profile_image);
                    setIsCropperOpen(false);
                }}
                onCropFinished={handleProfileCrop}
                imageSrc={profileFile}
            />

            <div className="photo-modal">
                <span className="profile-image-text">Profile Photo</span>
                <input
                    id="uploader-input"
                    onChange={e => {
                        const MaxPhotoFileSize: number = 10000000; //unit -> bytes
                        const validateSize = (file: any, maximumSize: number): boolean => {
                            if (file && file.size > maximumSize) {
                                return false;
                            }
                            return true;
                        }

                        if (e.target.files) {
                            if (!validateSize(e.target.files[0], MaxPhotoFileSize)) {
                                toast("Maximum profile image upload size is 10MB.", {
                                    type: ToastType.ERROR,
                                    autoClose: 3000,
                                });
                                return false;
                            }

                        setProfileFile(
                            e.target.files ? e.target.files[0] : null
                        );
                        setProfileNewSrc(
                            URL.createObjectURL(
                                //@ts-ignore
                                e.target.files ? e.target.files[0] : null
                            )
                        );
                        setIsCropperOpen(true);
                        }
                    }}
                    type="file"
                    value=""
                    name="profile-picture"
                    accept="image/png, image/jpeg"
                    hidden
                />
                <div
                    className="user-profile-image-wrapper-2"
                    onClick={() => clickUploadInput(group)}
                    onMouseEnter={() => setShowChangePhoto(true)}
                    onMouseLeave={() => setShowChangePhoto(false)}
                >
                    {
                        showChangePhoto &&
                        <span className="change-photo"> {profileSrc === null ? 'Upload photo' : 'Change photo'}</span>
                    }
                    {
                        profileSrc === null
                            ?
                            <span className="no-image-text-2">No image</span>
                            :
                            <img
                                className="user-profile-image-2"
                                src={profileSrc}
                                alt='profile picture'
                            />
                    }
                </div>

                <span
                    className="change-photo-text"
                    onClick={() => {
                        profileSrc === null ? clickUploadInput(group) : savePhoto()
                    }}
                >
                    {profileSrc === null ? 'Upload photo' : 'Save photo'}
                </span>

                {profileSrc !== null &&
                    <span
                        className="delete-photo-text"
                        onClick={() => {
                            if (profileUpdatesDisabled()) {
                                return;
                            }
                            setProfileSrc(null);
                            setEditData({
                                ...editData,
                                profile_image: null,
                            })
                            deletePhoto()
                        }}
                    >
                        Delete photo
                    </span>
                }

                <div
                    className="close-photo-modal"
                    onClick={() => setShowProfilePhotoModal(false)}
                >
                    <Close width={30} height={30} />
                </div>
            </div>
        </ReactModal>
    )
}

export default ProfilePhoto
