import React, { useState, useEffect, useLayoutEffect } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { IGroupContainerProps } from "./IGroupContainerProps";
import PublicRoute from "routes/PublicRoute";
import MainLoadingComponent from "components/mainLoading";
import Loadable from "react-loadable";
import SidebarComponent from "components/sidebar";
import SidebarForChannelComponent from "components/sidebar/sidebarForChannel";
import { connect } from "react-redux";
import { Map } from "immutable";
import { getCookieValue, setCookieValue } from "../../utils/cookieStorage";
import PrivateRoute from "routes/PrivateRoute";
import { globalActions } from "store/actions";
import store from "../../store/configureStore";
import useWindowSize from "utils/windowSize";
import "./group-container.scss";
import "./group-container-responsive.scss";
// import ScreeningPopUp from "containers/MembershipQuestions/ScreeningPopUp";
import SidebarLoader from "components/sidebar/sidebarLoader";
import { DisplayVariant } from "containers/Library/Community/domain";
import { PurchaseTypes } from "containers/AppPurchase/domain";
import { ContentType } from "containers/Library/Admin/domain";
import { widgetScriptInnerMarkup } from "./domain";
import miscUtils from "utils/miscUtils";
import moment from "moment";
import { SSOMethod } from "containers/IntegrationsContainer/SingleSignOn/domain";
import ProfileSlideout from "components/ProflieSlideout";
import useScrollTopOffset from "utils/useScrollTopOffset";
import useSlideoutProfile from "hooks/slideoutProfileHook";
import { CATEGORY_TYPE } from "enums";
import VerifyAccountComponent from "containers/selfSignup/VerifyAccountComponent";
import NotFound from "containers/notFound";
import { useAtom } from "jotai";
import { activeSubReloadAtom, showActiveCHatAtom, showMessagePopUpAtom } from "showMessageMenuAtom";
import SignUpDisabled from "components/SignUpDisabled";
import {
  useQuery,
} from 'react-query';
import CommunityService from "api/react-query/services/CommunityService";
import PlanDisabled from "components/PlanDisabled";
import Gleap from 'gleap';
import appConfig from "appConfig";
import AdminMemberDetailsPopUp from "containers/AdminMemberDetailsPopUp";
import CompletedQuiz from "containers/Library/Community/Courses/CompletedQuiz/CompletedQuiz";
import CommunityOnboardingSteps from "containers/CommunityOnboardingSteps";
import { GroupService } from "api/services/groups";
import { getlocalStorageValue, setlocalStorageValue } from "utils/localStorage";
// import EmailWhitelabelingSuccess from "components/EmailWhitelabelingSuccess";

const OWNER_ROLE_ID: number = 1;
const ONBOARDING_MESSAGE_COOKIE: string = "onboarding-message";

const AsyncGroupView: any = Loadable({
  loader: () => import("containers/groupView"),
  loading: MainLoadingComponent
});

const AsyncGroupSettings: any = Loadable({
  loader: () => import("containers/groupSettings"),
  loading: MainLoadingComponent
});

const AsyncSinglePost: any = Loadable({
  loader: () => import("containers/singlePost"),
  loading: MainLoadingComponent
});

const AsyncHome: any = Loadable({
  loader: () => import("containers/home"),
  loading: MainLoadingComponent
});

const AsyncRouterSwitch: any = Loadable({
  loader: () => import("containers/routerSwitch"),
  loading: MainLoadingComponent
});

const AsyncUnsubscribeNotifications: any = Loadable({
  loader: () => import("containers/unsubscribeNotifications"),
  loading: MainLoadingComponent
});

const AsyncGroupBillingCancellation: any = Loadable({
  loader: () => import("containers/groupBillingCancellation"),
  loading: MainLoadingComponent
});

const AsyncStripeCheck: any = Loadable({
  loader: () => import("containers/StripeCheck"),
  loading: MainLoadingComponent
});

const AsyncConfirmIntegration: any = Loadable({
  loader: () => import("containers/IntegrationsContainer/ConfirmIntegration"),
  loading: MainLoadingComponent
});

// const AsyncPlanSubscription: any = Loadable({
//   loader: () => import("containers/PlanSelection"),
//   loading: MainLoadingComponent
// });

const AsyncJoinGroup: any = Loadable({
  loader: () => import("containers/JoinGroup"),
  loading: MainLoadingComponent
});

const AsyncUserSubscriptions: any = Loadable({
  loader: () => import("containers/UserSubscriptions"),
  loading: MainLoadingComponent
});

const AsyncManageSubscription: any = Loadable({
  loader: () => import("containers/ManageSubscription"),
  loading: MainLoadingComponent
});

// const AsyncCourseAccess: any = Loadable({
//   loader: () => import("containers/CourseAccess"),
//   loading: MainLoadingComponent
// });

const AsyncLibraryContainer: any = Loadable({
  loader: () => import("containers/Library/Community"),
  loading: MainLoadingComponent
});

const AsyncLibraryCourseContainer: any = Loadable({
  loader: () => import("containers/Library/Community/LibraryCourseContainer"),
  loading: MainLoadingComponent
})

const AsyncLecturesContainer: any = Loadable({
  loader: () => import("containers/Library/Community/Courses/Lectures/LectureContainer"),
  loading: MainLoadingComponent
});

const AsyncGroupMembersPublic: any = Loadable({
  loader: () => import("containers/groupMembersPublic"),
  loading: MainLoadingComponent
});

const AsyncEventsContainer: any = Loadable({
  loader: () => import("containers/events/EventsCommunityContainer"),
  loading: MainLoadingComponent
});

const AsyncMembershipQuestionsPreview: any = Loadable({
  loader: () => import("containers/MembershipQuestions/MembershipQuestionsPreview"),
  loading: MainLoadingComponent,
});

const AsyncCourseIntro: any = Loadable({
  loader: () => import("containers/Library/Courses/CourseIntro"),
  loading: MainLoadingComponent,
});

const AsyncCategoryView: any = Loadable({
  loader: () => import("containers/Library/Community/ViewCategoryCommunity/ViewCategoryCommunity"),
  loading: MainLoadingComponent,
});

// const AsyncLibraryItemIntro: any = Loadable({
//   loader: () => import("containers/Library/Community/LibraryItemIntro"),
//   loading: MainLoadingComponent,
// });

const AsyncAppPurchase: any = Loadable({
  loader: () => import("containers/AppPurchase"),
  loading: MainLoadingComponent,
});

const AsyncOAuthAuthorizationContainer: any = Loadable({
  loader: () => import("containers/OAuthAuthorizationContainer"),
  loading: MainLoadingComponent,
})

const AsyncGroupOnboarding: any = Loadable({
  loader: () => import("containers/createGroup/GroupOnboarding"),
  loading: MainLoadingComponent,
})

// const AsyncFeedContainer: any = Loadable({
//   loader: () => import("containers/FeedContainer"),
//   loading: MainLoadingComponent,
// })

const AsyncLivePageContainer: any = Loadable({
  loader: () => import("containers/PageBuilder/LivePageContainer"),
  loading: MainLoadingComponent,
})

const AsyncLivePagePreviewContainer: any = Loadable({
  loader: () => import("containers/PageBuilder/LivePagePreviewContainer"),
  loading: MainLoadingComponent,
})


const GroupContainer: React.FC<IGroupContainerProps> = props => {
  const windowSize = useWindowSize();
  const [currentGroup, getCurrentGroup] = useState<any>();
  const [isSideBar, setIsSideBar] = useState<any>(false);
  const [currentChannel, getCurrentChannel] = useState<any>();
  const [preview, setPreview] = useState<any>();
  const [isInvite, setIsInvite] = useState<boolean>(false);
  const [sideBarIsOpen, setSideBarIsOpen] = useState<string>("");
  const [publicSideBarIsOpen, setPublicSideBarIsOpen] = useState<string>("");
  const [onCourseLecturePage, setOnCourseLecturePage] = useState<string>("");
  const [newUserClass, setNewUserClass] = useState<string>("");
  const [membersPageClass, setMembersPageClass] = useState<string>("");
  const [memberCommunityUnreadCount,] = useState<number>(0);

  //membership screening
  const [isScreeningPopupOpen, setIsScreeningPopupOpen] = useState<boolean>(false);
  const [userHasScreened,] = useState<boolean>(false);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);

  const {
    isAuthed,
    subdomain,
    global,
    groups: { userGroups },
    toggleHamburgerMenu,
    closeHamburgerMenu,
    openHamburgerMenu,
    globalUser,
    toggleOnboardingChecklist,
    setCommunityFavicon
  } = props;

  // user profile slide out
  const [playSlideoutAnimation, setPlaySlideoutAnimation] = useState<boolean>(false);
  const [selectedSlideoutProfile, setSelectedSlideoutProfile] = useState<any>(null);
  const scrollTopOffset = useScrollTopOffset("content-wrap");
  const slideoutProfileData = global.slideoutProfileData;

  const { closeSlideout } = useSlideoutProfile();

  const getCommunityQuery = useQuery(
    [CommunityService.keyIds.GET_COMMUNITY, currentGroup || subdomain],
    CommunityService.queryFunctions.getCommunity,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (
      getCommunityQuery.data &&
      miscUtils.isTeamMember(getCommunityQuery.data.user_membership)
    ) {
      Gleap.initialize(appConfig.gleap.api_key as string);
      Gleap.identify(`${getCommunityQuery.data.user_membership.user.id}`, {
        name: getCommunityQuery.data.user_membership.user.full_name,
        email: getCommunityQuery.data.user_membership.user.email,
      });
    }
  }, [getCommunityQuery.data]);

  useEffect(() => {
    closeSlideout();
  }, [])

  useLayoutEffect(() => {
    if (
      window.location.href.includes('invite?signup=true') &&
      getCommunityQuery.data &&
      getCommunityQuery.data.sso_enabled &&
      getCommunityQuery.data.sso_type === SSOMethod.OAUTH
    ) {
      props.history.push('/signin');
    }
  }, [window.location.href, getCommunityQuery.data]);

  useEffect(() => {
    setIsOnboarding(getCommunityQuery.data && isAuthed && miscUtils.isAdmin(getCommunityQuery.data.user_membership) && window.location.pathname === "/onboarding")
  }, [getCommunityQuery.data, window.location.pathname, isAuthed])

  useEffect(() => {
    if (
      getCommunityQuery.data &&
      getCommunityQuery.data.user_membership &&
      miscUtils.isAdmin(getCommunityQuery.data.user_membership)
    ) {
      const widgetScript: HTMLElement = document.createElement('script');
      widgetScript.innerHTML = widgetScriptInnerMarkup;
      document.documentElement.appendChild(widgetScript);
    }
  }, [getCommunityQuery.data]);

  // when a member Reactivating a subscription, we refetch group data
  const [activeSubReload, setActiveSubReload] = useAtom(activeSubReloadAtom);

  useEffect(() => {
    // getGroup();
    getCommunityQuery.refetch();
    return () => {
      setTimeout(() => {
        setActiveSubReload(false)
      }, 3000)
    }
  }, [isAuthed, activeSubReload]);

  useEffect(() => {
    if (
      getCommunityQuery.data &&
      getCommunityQuery.data.user_membership &&
      getCommunityQuery.data.user_membership.role_id === OWNER_ROLE_ID &&
      validTimeFromCommunityCreation() &&
      getCookieValue(ONBOARDING_MESSAGE_COOKIE) !== getCommunityQuery.data.id
    ) {
      setCookieValue(ONBOARDING_MESSAGE_COOKIE, getCommunityQuery.data.id);
    }
  });

  useEffect(() => {
    if ((window.location.pathname === "/invite" || window.location.pathname === "/invite-preview") && !isAuthed) {
      setIsInvite(true);
    } else {
      setIsInvite(false);
      if (getCommunityQuery.data && getCommunityQuery.data.plans.length === 1 && getCommunityQuery.data.plans[0].name === "free") {
        setIsInvite(false);
      }
    }

    if (window.location.pathname.includes("/members")) {
      setMembersPageClass("community-members-page");
    } else {
      setMembersPageClass("");
    }

    const query = new URLSearchParams(props.location.search);
    const invite = query.get("invite");
    if (invite && invite === "true") {
      setIsInvite(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  useEffect(() => {
    if (userGroups
      && !window.location.pathname.includes('group_billing_cancellation')
      && !window.location.pathname.includes('purchase')
      // && window.location.pathname !== "/onboarding"
    ) {
      if (
        (userGroups.data.length === 0 && window.location.pathname === '/' && !subdomain)
        // || (props.location.pathname.split("/")[1] === "posts" && !currentChannel) // Removed this so side bar can show on not found/ access restricted page for single post.
        || isOnboarding
      ) {
        setPreview("");
        setNewUserClass("empty-user-groups");
      } else {
        setPreview("channel-preview");
        setNewUserClass("");
      }
    } else {
      if (!isInvite
        && window.location.pathname !== '/signin'
        && !window.location.pathname.includes('group_billing_cancellation')
        && !window.location.pathname.includes('purchase')
        && !isOnboarding
      ) {
        setPreview("channel-preview");
      } else {
        if (window.location.pathname.includes('purchase')) {
          // const pathParts = window.location.pathname.split('/')
          // if (
          //   (pathParts[2] && pathParts[2].includes('purchase')) &&
          //   ((pathParts[3] && !pathParts[3].includes('purchase')) || !pathParts[3])
          // ) {
          //   setPreview("channel-preview");
          // } else {
          //   setPreview("")
          // }

          if (window.location.pathname.split("/").pop() === "purchase") {
            setPreview("")
          } else {
            setPreview("channel-preview");
          }
        } else {
          setPreview("");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroups, currentChannel, isInvite, props.location.pathname, window.location.pathname, isOnboarding]);

  useEffect(() => {
    if (
      (window.location.pathname.includes("library") || window.location.pathname.includes("courses")) &&
      window.location.pathname.includes("/lectures")
    ) {
      // toggleHamburgerMenu();
      // setOnCourseLecturePage("res-course-lecture");
    } else {
      setOnCourseLecturePage("");
    }

    if (window.innerWidth > 700
      && !window.location.pathname.includes('group_billing_cancellation')
      && !window.location.pathname.includes('purchase')
      && (window.location.pathname !== '/' && !subdomain)
    ) {
      setSideBarIsOpen("sidebar-is-open");

      if (!isAuthed && !isInvite && window.location.pathname !== '/signin' && !isOnboarding) {
        setPublicSideBarIsOpen("sidebar-channel-only-wrap-is-open");
      } else {
        setPublicSideBarIsOpen("");
      }
    } else {
      setSideBarIsOpen("");
    }

    if (window.location.pathname === '/signin') {
      setPreview("")
    }
  }, [props.location.pathname, isInvite]);

  useEffect(() => {
    if (
      ((window.location.pathname.includes("/library") || window.location.pathname.includes("/courses")) &&
        window.location.pathname.includes("/lectures")) ||
      window.location.pathname.split("/").pop() === "purchase" ||
      window.location.pathname.split("/")[1] === "pages" ||
      window.location.pathname.split("/")[1] === "pages-preview"
    ) {
      setTimeout(() => {
        closeHamburgerMenu();
      }, 100);
    }

    if (!isAuthed && !getCookieValue("sidebar") && window.location.pathname === "/" && windowSize.width > 991) {
      setTimeout(() => {
        openHamburgerMenu();
      }, 100)
    }
  }, [props.location.pathname]);

  const sideBarValue = getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value
  useEffect(() => {
    if (
      (sideBarValue
        && !window.location.pathname.includes('group_billing_cancellation')
        && ((window.location.pathname === '/' && subdomain !== null)
          || (window.location.pathname !== '/' && subdomain !== undefined)
        ))
    ) {
      setSideBarIsOpen("sidebar-is-open");
    } else {
      setSideBarIsOpen("");
    }

    if (!isAuthed) {
      if (sideBarValue && !isInvite && window.location.pathname !== '/signin' && !isOnboarding) {
        setPublicSideBarIsOpen("sidebar-channel-only-wrap-is-open");
      } else {
        setPublicSideBarIsOpen("");
      }
    }

    setIsSideBar(sideBarValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideBarValue, isInvite]);

  useEffect(() => {
    if (
      !window.location.pathname.split("/").includes("settings")
      && (!window.location.pathname.includes("/lectures") && window.location.pathname.includes("/library"))
      && window.innerWidth > 991
      && !isOnboarding
    ) {
      setlocalStorageValue("sidebar", true);
    }

    if (window.location.pathname.split("/").pop() === "purchase" && preview === undefined) {
      setPreview("");
      closeHamburgerMenu();
    }

    if(
      window.location.pathname.split("/")[1] === "pages" ||
      window.location.pathname.split("/")[1] === "pages-preview"
    ) {
      setPreview("");
      closeHamburgerMenu();
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (window.location.pathname.includes("settings") && isSideBar) {
      setIsSideBar(false);
      toggleHamburgerMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (currentGroup || subdomain) {
      getCommunityQuery.refetch();

      if (props.location.pathname.includes("post")) {
        getCommunityQuery.refetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup, subdomain, props.location.pathname, isAuthed]);

  useEffect(() => {
    if (getCommunityQuery.data && isAuthed && miscUtils.isAdmin(getCommunityQuery.data.user_membership) && !getCommunityQuery.data.onboarding_completed) {
      props.history.push("/onboarding")
    }
    // if (group && group.user_membership && group.user_membership.unread_messages_count > 0) {
    //   setMemberCommunityUnreadCount(group.user_membership.unread_messages_count);
    // }
  }, [getCommunityQuery.data, isAuthed]);

  useEffect(() => {
    if (isAuthed) {
      if (global.toggleSidebar && getlocalStorageValue("sidebar") && !getlocalStorageValue("sidebar").value) {
        setlocalStorageValue("sidebar", true);
      }

      if (!global.toggleSidebar && getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value) {
        setlocalStorageValue("sidebar", false);
      }
    }
  }, [global.toggleSidebar]);

  useEffect(() => {
    if (
      getCommunityQuery.data &&
      !global.selectedGroup
    ) {
      store.store.dispatch(globalActions.setSelectedGroup(getCommunityQuery.data));
    }
  }, [getCommunityQuery, global]);

  function getGroupFromSinglePostPage(group: any) {
    getCurrentGroup(group);
  }

  function getChannelFromSinglePostPage(channel: any) {
    getCurrentChannel(channel);
  }

  const search = props.location.search;
  // @ts-ignore
  // eslint-disable-next-line
  const params = new URLSearchParams(search);

  function toggleInvite() {
    setIsInvite(false);
  }

  const shouldSidebarLoaderShow = (): boolean => {
    return (
      subdomain && getCommunityQuery.isLoading && (window.location.pathname.split('/')[1] !== 'posts') &&
      (window.location.pathname.split("/")[1] !== "pages") &&
      (window.location.pathname.split("/")[1] !== "pages-preview") &&
      (!window.location.pathname.includes("plan-subscription")) &&
      (!window.location.pathname.includes("sso/oauth/callback")) &&
      (!window.location.pathname.includes("plan-subscription-preview")) &&
      (!window.location.pathname.includes("membership-questions-preview")) &&
      (window.location.pathname.split("/").pop() !== "purchase") &&
      (!(window.location.pathname.includes("library") && window.location.pathname.includes("/lectures") && window.location.pathname.includes("/view"))) &&
      (!(window.location.pathname.includes("courses") && window.location.pathname.includes("/lectures"))) &&
      (window.location.pathname.split("/").pop() !== "invite") &&
      (window.location.pathname.split("/").pop() !== "invite-preview") &&
      (window.location.pathname.split("/").pop() !== "signin") &&
      (!(!props.uid && (window.location.pathname.split("/").length === 3 || window.location.pathname.split("/").length === 4) && (window.location.pathname.split("/")[1] === "courses" || window.location.pathname.split("/")[1] === "library")))
      && !isOnboarding
    )
  }


  const validTimeFromCommunityCreation = (): boolean => {
    const allowedDifferenceInMinutes: number = 1;
    const communityCreationDate = moment.utc(getCommunityQuery.data.created_at);
    const currentTime = moment.utc();

    return currentTime.diff(communityCreationDate, 'minutes') <= allowedDifferenceInMinutes;
  }

  const userIsAnAuthorizedAdmin = (): boolean => {
    return (
      getCommunityQuery.data &&
      getCommunityQuery.data.user_membership &&
      miscUtils.isTeamMember(getCommunityQuery.data.user_membership) &&
      !miscUtils.isModerator(getCommunityQuery.data.user_membership)
    )
  }

  useEffect(() => {
    if (!window.location.pathname.includes("/settings")) {
      setTimeout(() => {
        setPlaySlideoutAnimation(global.userProfileSlider);
      }, 100);
    }
  }, [global.userProfileSlider]);

  useEffect(() => {
    if (slideoutProfileData && !window.location.pathname.includes("/settings")) {
      setSelectedSlideoutProfile(slideoutProfileData.user);
    } else {
      setSelectedSlideoutProfile(undefined);
    }
  }, [slideoutProfileData])

  useEffect(() => {
    if (global.userProfileSlider) {
      closeSlideout();
    }
  }, [window.location.pathname]);

  const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);
  const [, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);


  useEffect(() => {
    if (isAuthed) {
      if (window.location.search) {
        const messageParam = new URLSearchParams(window.location.search);
        const chatRoomID = messageParam.get("chatRoom");
        const messagePopUpOpen = messageParam.get("chatOpen");

        if (chatRoomID && messagePopUpOpen === "true") {
          const chatRoomIDNumber: any = Number(chatRoomID)
          setOpenMessagePopUp(true)
          document.body.style.overflow = 'hidden';
          setActiveChatroomMessage(chatRoomIDNumber)
        }
      }
    }
    else {
      // To avoid setting empty strings
      if (window.location.search) {
        setlocalStorageValue("next_link", window.location.search);
      }
    }

  }, [isAuthed, window.location.search]);

  // Onboarding steps section
  const [requiredDefaultKeys, setRequiredDefaultKeys] = useState<any>()
  const groupData = getCommunityQuery && getCommunityQuery.data
  const userId = props.uid
  const [isOnboardingStepsPopupOpen, setIsOnboardingStepsPopupOpen] = useState<boolean>(false);
  const [requiredDefaultFields, setRequiredDefaultFields] = useState<any>()

  function getCommunityFieldsData() {
    GroupService.getInstance()
      .getCommunityFields(groupData && groupData.id, 'default')
      .then((response: any) => {
        if (response) {
          const requiredResData = response.data && response.data.length > 0 &&
            response.data.filter((res: any) => res.required);
          setRequiredDefaultFields(requiredResData)
        }
      })
  }

  const _memberData = groupData && groupData.user_membership && groupData.user_membership.user

  useEffect(() => {
    if (
      // dont call on theses page
      (!window.location.pathname.includes('/plan-subscription')) &&
      (!window.location.pathname.includes('/onboarding')) &&
      (!window.location.pathname.includes('/settings')) &&
      (!window.location.pathname.includes('/purchase')) &&
      groupData &&
      groupData.user_membership &&
      groupData.user_membership.user &&
      userId &&
      !miscUtils.isTeamMember(groupData.user_membership)
    ) {
      getCommunityFieldsData()
    }
  }, [_memberData, groupData])


  useEffect(() => {
    const temprequiredDefaultFieldsList: any = []
    if (requiredDefaultFields !== null && requiredDefaultFields !== undefined && _memberData) {
      requiredDefaultFields.map((data: any) => {
        if (data.name === 'City' && _memberData.location === null) {
          temprequiredDefaultFieldsList.push('location')
        }
        if (data.name === 'Country' && _memberData.country_id === null) {
          temprequiredDefaultFieldsList.push('country_id')
        }
        if (data.name === 'Timezone' && _memberData.timezone_id === null) {
          temprequiredDefaultFieldsList.push('timezone_id')
        }
        if (data.name === 'Profile photo' && _memberData.profile_image === null) {
          temprequiredDefaultFieldsList.push('profile_image')
        }
        if (data.name === 'Headline' && _memberData.headline === null) {
          temprequiredDefaultFieldsList.push('headline')
        }
        if (data.name === 'Job title' && _memberData.job_title === null) {
          temprequiredDefaultFieldsList.push('job_title')
        }
        if (data.name === 'Bio' && _memberData.about_me === null) {
          temprequiredDefaultFieldsList.push('about_me')
        }
        if (data.name === 'Instagram' && _memberData.instagram === null) {
          temprequiredDefaultFieldsList.push('instagram',)
        }
        if (data.name === 'Facebook' && _memberData.facebook === null) {
          temprequiredDefaultFieldsList.push('facebook',)
        }
        if (data.name === 'LinkedIn' && _memberData.linkedin === null) {
          temprequiredDefaultFieldsList.push('linkedin')
        }
        if (data.name === 'Web URL' && _memberData.website === null) {
          temprequiredDefaultFieldsList.push('website')
        }
        if (data.name === 'Twitter' && _memberData.twitter === null) {
          temprequiredDefaultFieldsList.push('twitter')
        }
      })
      setRequiredDefaultKeys(temprequiredDefaultFieldsList)
    }
  }, [requiredDefaultFields, groupData, _memberData])

  const prevPopUpStatesRef: any = React.useRef([]);

  useEffect(() => {
    prevPopUpStatesRef.current.push(isOnboardingStepsPopupOpen);
  }, [isOnboardingStepsPopupOpen]);


  useEffect(() => {
    const trueExists = prevPopUpStatesRef.current.includes(true);
    if (isAuthed && groupData && groupData.user_membership && requiredDefaultKeys) {
      if (trueExists) {
        return
      }
      else {
        const steps = miscUtils.onboardingPopUpShouldOpen(groupData, isAuthed, requiredDefaultKeys)
        if (steps.length > 0) setIsOnboardingStepsPopupOpen(true)
      }
      // setTimeout(() => {
      //   const steps = miscUtils.onboardingPopUpShouldOpen(groupData, isAuthed, requiredDefaultKeys)
      //   if (steps.length > 0) setIsOnboardingStepsPopupOpen(true)
      // }, 5000);
    }
  }, [groupData, isAuthed, requiredDefaultKeys])
// end of  Onboarding steps section

  useEffect(() => {
    if (!isAuthed) {
      if ((
        window.location.pathname.split("/").length === 3 &&
        window.location.pathname.split("/")[1] === ContentType.COURSE //course outline page
      )) {
        setPreview("");
        closeHamburgerMenu();
      }

      if ((
        window.location.pathname.split("/").length === 3 &&
        window.location.pathname.split("/")[1] === 'events' //event view page
      )) {
        setPreview("");
        closeHamburgerMenu();
      }
    }
  }, [window.location.pathname, isAuthed]);

  useEffect(() => {
    if (!isAuthed) {
      if ((
        window.location.pathname.split("/").length === 3 &&
        window.location.pathname.split("/")[1] === 'events' && //event view page
        preview
      )) {
        setPreview("");
        closeHamburgerMenu();
      }
    }
  }, [isAuthed, preview]);


  if (
    getCommunityQuery.isLoading
  ) {
    return <></>
  }

  // if you are on articles view page and logout out, you should still see the sidebar
  const showSideBaronArticles = !isAuthed && window.location.pathname.includes('library/articles/lectures/view/')

  return (

    <div
      id="groupapp-main-wrap-container"
      className={
        getCommunityQuery.data && !getCommunityQuery.isLoading
          ? isSideBar && (isAuthed || showSideBaronArticles) && !isInvite && !isOnboarding
            ? `main-wrap ${sideBarIsOpen} ${newUserClass} ${onCourseLecturePage} ${membersPageClass} ${window.location.pathname.split("/").includes("settings") ||
            window.location.pathname.includes("plan-subscription") ||
            window.location.pathname.includes("user-subscriptions") ||
            window.location.pathname.includes("manage-subscription") ||
            window.location.pathname.includes(
              "plan-subscription-preview"
            ) ||
            preview
            }`
            : `main-wrap ${newUserClass} ${publicSideBarIsOpen} ${window.location.pathname.split("/").includes("settings") ||
              window.location.pathname.includes("plan-subscription") ||
              window.location.pathname.includes("user-subscriptions") ||
              window.location.pathname.includes("manage-subscription") ||
              window.location.pathname.includes(
                "plan-subscription-preview"
              ) ||
              window.location.pathname.includes("membership-questions-preview") ||
              (window.location.pathname.includes("/lectures")) ||
              (window.location.pathname.includes("sso/oauth/callback"))
              ? null
              : preview
            }`
          : isSideBar
            && isAuthed
            && !window.location.pathname.includes("email_unsubscription")
            && !window.location.pathname.includes("group_billing_cancellation")
            ? `main-wrap ${sideBarIsOpen} ${newUserClass}`
            : `main-wrap `
      }
    >
      {shouldSidebarLoaderShow() &&
        (
          <SidebarLoader
          />
        )}
      {isAuthed && !getCommunityQuery.isLoading ? (
        <>
          {getCommunityQuery.data && !isInvite
            && !window.location.pathname.includes("group_billing_cancellation")
            && !window.location.pathname.includes("email_unsubscription")
            && !window.location.pathname.includes("/sso/oauth/callback")
            && window.location.pathname.split("/")[1] !== "pages"
            && window.location.pathname.split("/")[1] !== "pages-preview"
            && !isOnboarding
            && (
              <>
                <SidebarComponent
                  subdomain={subdomain}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  currentChannel={currentChannel}
                  memberCommunityUnreadCount={memberCommunityUnreadCount}
                />
              </>
            )}
        </>
      ) : (
        <>
          {!isInvite
            && !window.location.pathname.includes("group_billing_cancellation")
            && !window.location.pathname.includes("email_unsubscription")
            && !window.location.pathname.includes("/sso/oauth/callback")
            && !isOnboarding
            && window.location.pathname.split("/")[1] !== "pages" 
            && window.location.pathname.split("/")[1] !== "pages-preview"
            &&
            getCommunityQuery.data && (
              <>
                {windowSize.width > 991 ? (
                  <SidebarComponent
                    subdomain={subdomain || (global && global.currentSlug)}
                    group={getCommunityQuery.data && getCommunityQuery.data}
                    currentChannel={currentChannel}
                    isSubdomain={subdomain ? true : false}
                  />
                ) : (
                  <SidebarForChannelComponent
                    subdomain={subdomain || (global && global.currentSlug)}
                    group={getCommunityQuery.data && getCommunityQuery.data}
                    currentChannel={currentChannel}
                    isSubdomain={subdomain ? true : false}
                  />
                )}
              </>
            )}
        </>
      )}
      <div id="content-wrap" className={`content-wrap flex flex-justify-center ${!isAuthed && "no-auth center"} ${window.location.pathname.includes('channel') || window.location.pathname === "/" ? 'content-wrap--channel-view' : ''
        } ${(
            window.location.pathname.split('/')[1] === 'posts' ||
            window.location.pathname.split('/')[1] === 'pages' ||
        window.location.pathname.split('/')[1] === 'pages-preview' ||
        window.location.pathname.split('/')[1] === 'email_unsubscription'
        ) ? 'content-wrap--single-post' : ''
        } ${window.location.pathname === '/filter' ? 'content-wrap--search-view' : ''
        } ${
          window.location.pathname.split('/')[1] === 'pages' ?
          'content-wrap--landing-page' : ''
        }`} >
        {!subdomain ? (
          <Switch>
            <Route
              authed={isAuthed}
              group={true}
              exact={true}
              path={"/group/:groupSlug/members"}
              render={() => <AsyncGroupMembersPublic
                authed={isAuthed}
                theme={global.theme}
                screeningState={{
                  userHasScreened,
                  isScreeningPopupOpen,
                  setIsScreeningPopupOpen,
                }}
              />}
            />
            <PublicRoute
              exact={true}
              path="/group/:groupSlug?"
              group={getCommunityQuery.data}
              component={
                <AsyncGroupView
                  toggleOnboardingChecklist={toggleOnboardingChecklist}
                  toggleInvite={toggleInvite}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  loadingGroup={getCommunityQuery.isLoading}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/channel/:groupSlug/:channelId"
              component={
                <AsyncGroupView
                  toggleOnboardingChecklist={toggleOnboardingChecklist}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  loadingGroup={getCommunityQuery.isLoading}
                />
              }
            />
            <Route
              exact={false}
              path="/group/:groupSlug?/settings"
              component={AsyncGroupSettings}
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/posts/:id"
              component={
                <AsyncSinglePost
                  authed={isAuthed}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  getChannelFromSinglePostPage={getChannelFromSinglePostPage}
                  group={getCommunityQuery.data}
                />
              }
            />
            <PrivateRoute
              user={globalUser}
              authed={isAuthed}
              exact={true}
              path={"/"}
              group={getCommunityQuery.data && getCommunityQuery.data}
              component={<AsyncHome />}
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/email_unsubscription"
              component={<AsyncUnsubscribeNotifications isAuthed={isAuthed} />}
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/group/:groupSlug/group_billing_cancellation"
              component={userIsAnAuthorizedAdmin() ? (
                <AsyncGroupBillingCancellation isAuthed={isAuthed} subdomain={subdomain} />
              ) : (
                <NotFound user message="Unauthorized Page" />
              )}
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/stripe-connect"
              component={
                <AsyncStripeCheck
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/integration-authorized"
              component={
                <AsyncConfirmIntegration
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/group/:groupSlug?/plan-subscription"
              component={
                <AsyncAppPurchase
                  userId={props.uid}
                  group={props.group}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  purchaseType={PurchaseTypes.GROUP_PLANS}
                  theme={global.theme}
                  authed={isAuthed}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/group/:groupSlug?/plan-subscription/checkout"
              component={
                <AsyncAppPurchase
                  userId={props.uid}
                  group={props.group}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  purchaseType={PurchaseTypes.GROUP_PLAN}
                  theme={global.theme}
                  authed={isAuthed}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/group/:groupSlug?/pages-preview/:pageSlug"
              component={
                <AsyncLivePagePreviewContainer
                group={getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/group/:groupSlug?/pages/:pageSlug"
              component={
                <AsyncLivePageContainer
                group={getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/group/:groupSlug?/plan-subscription-preview"
              component={
                <AsyncAppPurchase
                userId={props.uid}
                group={props.group}
                screeningState={{
                  userHasScreened,
                  isScreeningPopupOpen,
                  setIsScreeningPopupOpen,
                }}
                purchaseType={PurchaseTypes.GROUP_PLANS}
                theme={global.theme}
                authed={isAuthed}
                preview
                subdomain={subdomain}
              />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/group/:groupSlug?/manage-subscription"
              component={
                <AsyncManageSubscription
                  theme={global.theme}
                  userId={props.uid}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/user-subscriptions"
              component={
                <AsyncUserSubscriptions
                  userId={props.uid}
                  subdomain={subdomain}
                />
              }
            />
          </Switch>
        ) : (
          <Switch>
              <PublicRoute
                exact={false}
                group={getCommunityQuery.data}
                path="/email_unsubscription"
                component={<AsyncUnsubscribeNotifications isAuthed={isAuthed} />}
              />
              <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/sso/oauth/callback"
              component={
                <AsyncOAuthAuthorizationContainer
                  group={getCommunityQuery.data && getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/manage-subscription"
              component={
                <AsyncManageSubscription
                  theme={global.theme}
                  userId={props.uid}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              authed={isAuthed}
              group={getCommunityQuery.data}
              exact={true}
              path="/invite"
              disableRedirect={true}
              component={
                <AsyncGroupView
                  toggleOnboardingChecklist={toggleOnboardingChecklist}
                  subdomain={subdomain}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  loadingGroup={getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              authed={isAuthed}
              group={getCommunityQuery.data}
              exact={true}
              path="/filter"
              disableRedirect={true}
              component={
                <AsyncGroupView
                  toggleOnboardingChecklist={toggleOnboardingChecklist}
                  subdomain={subdomain}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  loadingGroup={getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/stripe-connect"
              component={
                <AsyncStripeCheck
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={false}
              group={getCommunityQuery.data}
              path="/group_billing_cancellation"
              component={userIsAnAuthorizedAdmin() ? (
                <AsyncGroupBillingCancellation isAuthed={isAuthed} subdomain={subdomain} />
              ) : (
                <NotFound user message="Unauthorized Page" />
              )}
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/plan-subscription"
              component={
                <AsyncAppPurchase
                  userId={props.uid}
                  group={props.group}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  purchaseType={PurchaseTypes.GROUP_PLANS}
                  theme={global.theme}
                  authed={isAuthed}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/plan-subscription/checkout"
              component={
                <AsyncAppPurchase
                  userId={props.uid}
                  group={props.group}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  purchaseType={PurchaseTypes.GROUP_PLAN}
                  theme={global.theme}
                  authed={isAuthed}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/pages-preview/:pageSlug"
              component={
                <AsyncLivePagePreviewContainer
                group={getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/pages/:pageSlug"
              component={
                <AsyncLivePageContainer
                group={getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/plan-subscription-preview"
              component={
                <AsyncAppPurchase
                userId={props.uid}
                group={props.group}
                screeningState={{
                  userHasScreened,
                  isScreeningPopupOpen,
                  setIsScreeningPopupOpen,
                }}
                purchaseType={PurchaseTypes.GROUP_PLANS}
                theme={global.theme}
                authed={isAuthed}
                preview
                subdomain={subdomain}
              />
              }
            />
            <PrivateRoute
              exact={false}
              path="/membership-questions-preview"
              group={getCommunityQuery.data && getCommunityQuery.data}
              component={
                <AsyncMembershipQuestionsPreview
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  theme={global.theme}
                />
              }
            />
            <PublicRoute
              exact={false}
              path="/user-subscriptions"
              group={getCommunityQuery.data}
              component={
                <AsyncUserSubscriptions
                  userId={props.uid}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/join_group/:inviteConfigSlug?"
              component={
                <AsyncJoinGroup
                  theme={global.theme}
                  userId={props.uid}
                />
              }
            />
            <PublicRoute
              authed={isAuthed}
              group={getCommunityQuery.data}
              exact={true}
              path="/"
              disableRedirect={true}
              component={
                <AsyncGroupView
                  toggleOnboardingChecklist={toggleOnboardingChecklist}
                  subdomain={subdomain}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  uid={props.uid}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  loadingGroup={getCommunityQuery.isLoading}
                />
              }
            />
            <PrivateRoute
              authed={isAuthed}
              exact={true}
              path={"/members"}
              group={getCommunityQuery.data && getCommunityQuery.data}
              component={
                <AsyncGroupMembersPublic
                  authed={isAuthed}
                  subdomain={subdomain}
                  group={getCommunityQuery.data}
                  theme={global.theme}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/courses"
              component={
                <AsyncLibraryCourseContainer
                  theme={global.theme}
                  subdomain={subdomain}
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  displayVariant={DisplayVariant.COURSE}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/library"
              component={
                <AsyncLibraryContainer
                  theme={global.theme}
                  subdomain={subdomain}
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  displayVariant={DisplayVariant.STANDALONE}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/courses/:contentId?"
              component={
                <AsyncCourseIntro
                  theme={global.theme}
                  subdomain={subdomain}
                  userId={props.uid}
                  user={globalUser}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  isAuthed={isAuthed}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/courses/categories/:categoryId?"
              component={
                <AsyncCategoryView
                  theme={global.theme}
                  subdomain={subdomain}
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  isAuthed={isAuthed}
                  displayVariant={DisplayVariant.COURSE}
                  categoryType={CATEGORY_TYPE.COURSE}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/library/categories/:categoryId?"
              component={
                <AsyncCategoryView
                  theme={global.theme}
                  subdomain={subdomain}
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  isAuthed={isAuthed}
                  displayVariant={DisplayVariant.STANDALONE}
                  categoryType={CATEGORY_TYPE.LIBRARY_CONTENT}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/courses/:courseId?/purchase"
              component={
                <AsyncAppPurchase
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  purchaseType={PurchaseTypes.COURSE}
                  theme={global.theme}
                  authed={isAuthed}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/library/:libraryItemType?/:libraryItemId?"
              component={
                <AsyncLibraryContainer
                theme={global.theme}
                subdomain={subdomain}
                userId={props.uid}
                group={getCommunityQuery.data && getCommunityQuery.data}
                screeningState={{
                  userHasScreened,
                  isScreeningPopupOpen,
                  setIsScreeningPopupOpen,
                }}
                displayVariant={DisplayVariant.STANDALONE}
                singleContentDisplay
              />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/library/:libraryItemType?/:libraryItemId?/purchase"
                component={
                <AsyncAppPurchase
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  purchaseType={PurchaseTypes.LIBRARY}
                  theme={global.theme}
                  subdomain={subdomain}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/courses/:courseId?/lectures/:contentId?"
              component={
                <AsyncLecturesContainer
                  theme={global.theme}
                  user={globalUser}
                  subdomain={subdomain}
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  sideBarIsOpen={sideBarIsOpen}
                  toggleLectureMenuRef={props.toggleLectureMenuRef}
                  authed={isAuthed}
                />
              }
            />
              <PublicRoute
                exact={true}
                group={getCommunityQuery.data}
                path="/courses/:courseId/section/:sectionId/enrollment/:enrollmentId/completed-quiz/:quizId/submission/:submissionId"
                component={
                  <CompletedQuiz group={getCommunityQuery.data} userId={props.uid} history={props.history} />
                }
              />
              <PublicRoute
                exact={true}
                group={getCommunityQuery.data}
              path="/library/:libraryItemType?/lectures/view/:libraryItemId?"
              component={
                <AsyncLecturesContainer
                  theme={global.theme}
                  user={globalUser}
                  subdomain={subdomain}
                  userId={props.uid}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  screeningState={{

                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  sideBarIsOpen={sideBarIsOpen}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/posts/:id"
              component={
                <AsyncSinglePost
                  authed={isAuthed}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  getChannelFromSinglePostPage={getChannelFromSinglePostPage}
                  group={getCommunityQuery.data}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/channel/:channelId"
              disableRedirect={true}
              component={
                <AsyncGroupView
                  toggleOnboardingChecklist={toggleOnboardingChecklist}
                  subdomain={subdomain}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  getGroupFromSinglePostPage={getGroupFromSinglePostPage}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  loadingGroup={getCommunityQuery.isLoading}
                />
              }
            />
            <PrivateRoute
              user={globalUser}
              authed={isAuthed}
              exact={false}
              path="/settings"
              group={getCommunityQuery.data && getCommunityQuery.data}
              component={
                <AsyncGroupSettings
                  isAuthed={isAuthed}
                  subdomain={subdomain}
                  setCommunityFavicon={setCommunityFavicon}
                />
              }
              />
            <PublicRoute
              user={globalUser}
              authed={isAuthed}
              exact={false}
              group={getCommunityQuery.data}
              path="/events"
              component={
                <AsyncEventsContainer
                  subdomain={subdomain}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  theme={global.theme}
                  user={globalUser}
                  userId={props.uid}
                  screeningState={{
                    userHasScreened,
                    isScreeningPopupOpen,
                    setIsScreeningPopupOpen,
                  }}
                  authed={isAuthed}
                />
              }
              />
            <PublicRoute
              user={globalUser}
              authed={isAuthed}
              exact
              group={getCommunityQuery.data}
              path="/signup-disabled"
              component={
                <SignUpDisabled />
              }
            />
            <PublicRoute
              user={globalUser}
              authed={isAuthed}
              exact
              group={getCommunityQuery.data}
              path="/plan-disabled"
              component={
                <PlanDisabled />
              }
            />
            <PrivateRoute
              user={globalUser}
              authed={isAuthed}
              exact={true}
              path="/onboarding"
              group={getCommunityQuery.data && getCommunityQuery.data}
              component={
                <AsyncGroupOnboarding
                  subdomain={subdomain}
                  group={getCommunityQuery.data && getCommunityQuery.data}
                  theme={global.theme}
                />
              }
            />
            <PublicRoute
              exact={true}
              group={getCommunityQuery.data}
              path="/verify-email?s=false"
              component={
                <VerifyAccountComponent
                  // @ts-ignore
                  groupId={getCommunityQuery.data && getCommunityQuery.data.id}
                  CommuinityTheme={getCommunityQuery.data && getCommunityQuery.data.custom_branding}
                />
              }
              />
            <Route
              render={() => {
                return (
                  <AsyncRouterSwitch
                    switchToMain={true}
                    subdomain={subdomain}
                  />
                );
              }}
            />
          </Switch>
        )}
        {selectedSlideoutProfile && playSlideoutAnimation && (
          <ProfileSlideout
            group={getCommunityQuery.data}
            playSlideoutAnimation={playSlideoutAnimation}
            setPlaySlideoutAnimation={setPlaySlideoutAnimation}
            selectedSlideoutProfile={selectedSlideoutProfile}
            showMenu={(window.location.pathname === "/settings/members") || (window.location.pathname === "/members")}
            extraHeight="20px"
            topOffset={scrollTopOffset}
            communityView={!window.location.pathname.includes("/settings")}
            classes={slideoutProfileData && slideoutProfileData.classes}
            groupMembersCallbacksRef={slideoutProfileData.groupMembersCallbacksRef}
          />
        )}
      </div>
      <>
        {/* {getCommunityQuery.data && getCommunityQuery.data.membership_questions_config && getCommunityQuery.data.membership_questions_config.active && (
          <ScreeningPopUp
            group={getCommunityQuery.data}
            isOpen={isScreeningPopupOpen}
            setIsOpen={setIsScreeningPopupOpen}
            setUserHasScreened={setUserHasScreened}
            theme={global.theme}
          />
        )} */}
        <AdminMemberDetailsPopUp group={getCommunityQuery.data} />

        {isAuthed && groupData && groupData.user_membership && requiredDefaultKeys &&
          <CommunityOnboardingSteps
            group={getCommunityQuery.data}
            isOpen={isOnboardingStepsPopupOpen}
            setIsOpen={setIsOnboardingStepsPopupOpen}
          userId={props.uid}
          requiredDefaultKeys={requiredDefaultKeys}
          getCommunityQuery={getCommunityQuery}
          setRequiredDefaultKeys={setRequiredDefaultKeys}
          memberData={_memberData}
          />
        }
      </>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: IGroupContainerProps) => {
  return {
    toggleHamburgerMenu: () => dispatch(globalActions.toggleHamburgerMenu()),
    closeHamburgerMenu: () => dispatch(globalActions.closeHamburgerMenu()),
    openHamburgerMenu: () => dispatch(globalActions.openHamburgerMenu()),
  };
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const global = Map(state.get("global", {})).toJS() as any;
  const globalUser: any = state.getIn(["authorize", "user"]);
  const groups = Map(state.get("group", {})).toJS() as any;

  return {
    globalUser,
    global,
    groups,
    guest: authorize.guest,
    uid: authorize.uid,
    isAuthed: authorize.authed,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupContainer as any) as any
) as any;
