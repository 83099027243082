import React from 'react';
import anchorme from 'anchorme';
import { ICourseCurriculumBlockPreviewProps } from './ICourseCurriculumBlockPreviewProps';
import { ReactComponent as LayoutIcon } from './icons/layout.svg';
import CourseCurriculumBlockSectionPreview from './CourseCurriculumBlockSectionPreview';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import BlockTextContainer, { BlockSubTextContainer } from '../BlockComponents/BlockTextContainer';
import styled from 'styled-components';

const CourseCurriculumBlockCurriculumContainer: any = styled.div`
  width: 100%;
  margin-top: 25px;
`;

const CourseCurriculumBlockCurriculumEmptyContainer: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #FFFFFF;
  padding: 80px 0;
`;

const CourseCurriculumBlockCurriculumEmptyIconContainer: any = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CourseCurriculumBlockCurriculumEmptyTitle: any = styled.h1`
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29.05px;
  color: #333333;
`;

const CourseCurriculumBlockCurriculumEmptySubtext: any = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: #666666;
`;

const CourseCurriculumBlockPreview: React.FC<ICourseCurriculumBlockPreviewProps> = (props) => {

  const getCurriculumContentElements = () => {
    if(
      !props.blockData.content.length
    ) {
      return (
        <CourseCurriculumBlockCurriculumEmptyContainer
        >
          <CourseCurriculumBlockCurriculumEmptyIconContainer
          >
            <LayoutIcon
            />
          </CourseCurriculumBlockCurriculumEmptyIconContainer>

          <CourseCurriculumBlockCurriculumEmptyTitle
          >
            Add course
          </CourseCurriculumBlockCurriculumEmptyTitle>

          <CourseCurriculumBlockCurriculumEmptySubtext
          >
            Choose one of your courses on the left side menu and curriculum will appear here.
          </CourseCurriculumBlockCurriculumEmptySubtext>
        </CourseCurriculumBlockCurriculumEmptyContainer>
      )
    }

    return (
      <>
      {props.blockData.content.map((section: any, index: number) => (
        <CourseCurriculumBlockSectionPreview
        key={section.id}
        blockData={props.blockData}
        section={section}
        index={index}
        pageStyles={props.pageStyles}
        />
      ))}
      </>
    )

  }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <BlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
          <BlockTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="heading"
          fallbackTextColor="#000000"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.heading),
          }}
          />

          <BlockSubTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="subtext"
          fallbackTextColor="#666666"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.subtext),
          }}
          />

          <CourseCurriculumBlockCurriculumContainer
          >
            {getCurriculumContentElements()}
          </CourseCurriculumBlockCurriculumContainer>
        </BlockContentContainer>
    </BlockContainer>
  )
}

export default CourseCurriculumBlockPreview;