import React from 'react';
import { IImageBlockPreviewProps } from './IImageBlockPreviewProps';
import { ReactComponent as ImageIcon } from './icons/image.svg';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import styled from 'styled-components';
import BlockImage from '../BlockComponents/BlockImage';

const ImageBlockContentContainer = styled(BlockContentContainer)`
    max-width: 100%;
    width: ${(props: any) => 
        props.design.image_full_width ?
        `100%` :
        props.design.content_container_max_width ?
        `${props.design.content_container_max_width}px` :
        `0px`
    };
    align-items: ${(props: any) => 
        props.design.image_alignment === 'left' ?
        'flex-start' :
        props.design.image_alignment === 'right' ?
        'flex-end' :
        'center'
    };
`;

const ImageBlockImage = styled(BlockImage)`
    max-width: 100%;
    width: ${(props: any) =>
        props.design.image_full_width ?
        `100%` :
        props.design.image_width ? 
        `${props.design.image_width}px` : 
        `100%`
    };
`;

const ImageBlockNoImageContainer = styled.div`
    width: 100%;
    height: 490px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D5D8DF;

    &.mobile {
        height: 250px;
    }

    @media(max-width: 550px) {
        height: 250px;
    }
`;

const ImageBlockNoImageIconContainer = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageBlockPreview: React.FC<IImageBlockPreviewProps> = (props) => {

    const onClickImage = () => {
        if(props.buildingPreview) {
            return;
        }
        
        if(
            props.blockData &&
            props.blockData.image_link
        ) {
            window.open(props.blockData.image_link, '_blank');
        }
    }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <ImageBlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
            {props.blockData.image_url ? (
            <ImageBlockImage
            design={props.blockData.design}
            src={props.blockData.image_url}
            alt={props.blockData.image_alt}
            onClick={onClickImage}
            />
            ) : (
                <ImageBlockNoImageContainer
                className={`${props.view ? props.view : ''}`}
                >
                    <ImageBlockNoImageIconContainer
                    >
                        <ImageIcon
                        />
                    </ImageBlockNoImageIconContainer>
                </ImageBlockNoImageContainer>
            )}
        </ImageBlockContentContainer>
    </BlockContainer>
  )
}

export default ImageBlockPreview;