import { UserService } from "api/services/users";
import imageCompression from "browser-image-compression";
import { SSOMethod } from "containers/IntegrationsContainer/SingleSignOn/domain"
import miscUtils from "utils/miscUtils";
import { toast, ToastType } from "react-toastify"

export const profileUpdatesDisabled = (group: any) => {
    return (
        group &&
        group.sso_enabled &&
        group.sso_type === SSOMethod.OAUTH &&
        group.oauth_sso &&
        group.oauth_sso.disable_user_profile_updates
    )
}

export const clickUploadInput = (group: any) => {
    if (profileUpdatesDisabled(group)) {
        return;
    }
    const uploadInput: HTMLElement = document.getElementById("uploader-input") as HTMLElement;
    uploadInput.click();
}

export const profileUpdateLocked = (group: any) => {
    return (
        miscUtils.useNbeaFlow(group) ||
        (
            group &&
            group.sso_enabled &&
            group.sso_type &&
            group.sso_type === SSOMethod.OAUTH &&
            group.oauth_sso &&
            group.oauth_sso.disable_user_profile_updates
        )
    )
}

export const getCustomLockMessage = (group: any) => {
    if (group && group.oauth_sso) {
        return group.oauth_sso.custom_user_profile_update_instruction;
    }

    return "...";
}

export const handleNullTimezoneId = (data: { timezone_id: string; }) => {
    if (data && data.timezone_id && data.timezone_id === "null") {
        return { ...data, timezone_id: null };
    }
    return data;
}

export const handleFormSubmit = (
    uid: number,
    editData: any,
    profileFile: any,
    getUserDetails: any
) => {
    const error = false;
    const errorLimit = false;

    const options = {
        maxSizeMB: 10,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    };

    if (!error) {
        const editPromises = [];

        editPromises.push(
            UserService.getInstance().updateUserProfile(uid, editData)
        );

        if (profileFile) {
            imageCompression(profileFile, options).then(function (
                compressedFile: any
            ) {
                const dataImage = new FormData();
                dataImage.append(
                    "profile_image",
                    miscUtils.blobToFile(compressedFile, profileFile)
                );
                editPromises.push(
                    UserService.getInstance().updateUserProfileImage(uid, dataImage)
                );
            });
        }

        if (!errorLimit) {
            Promise.all(editPromises).then(() => {
                setTimeout(() => getUserDetails(true), 2000);
                toast("Successfully saved changes!", {
                    type: ToastType.SUCCESS,
                    autoClose: 3000
                });
            });
        }
    }
};