import React, { useState } from "react";
import anchorme from "anchorme";
import Moment from "react-moment";
import ReactHtmlParser from "react-html-parser"
import { useAtom } from "jotai";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { IMessagingSidebarProps } from "./IMessagingSidebarProps";
import "./messaging-sidebar.scss";
import "./messaging-sidebar-responsive.scss";
import NewMessageMenu from "../NewMessageMenu/NewMessageMenu";
import { ReactComponent as NochatsIcon } from "containers/messaging/images/chat.svg";
import { SideBarMessagesLoader } from "components/contentLoaders";
import miscUtils from "utils/miscUtils";
import UserComponent from "components/userComponent";
import {
  chatsAtom,
  chatsLoadingAtom,
  disableActiveChatSelectionAtom,
  showActiveCHatAtom,
  showNewDirectMessageMenuAtom,
  showNewGroupMessageMenuAtom
} from "showMessageMenuAtom";
import { DirectChatsContext } from "containers/main/MainComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import { MessagingService } from "api/services/messaging";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MessagingSidebar: React.FC<IMessagingSidebarProps> = props => {
  const {
    group,
    isChatAdmin,
    chatroom, setChatroom,
    setIsEditGroupchat,
    theme,
    setIsShowChatroom,
    setIsLoadingMessages, setToggleShowProfiles,
    setIsCreateDirectChat,
    isMemberAddGroupChat
  } = props;

  const {
    memberCommunityUnreadCount,
    setChatIsclicked,
    getCommunityUnread,
  } = React.useContext(DirectChatsContext)

  const [, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);

  const [directChats, setCommunityChats] = useAtom(chatsAtom) as any;
  const [isLoading,] = useAtom(chatsLoadingAtom);

  function isActiveChat(chat: any) {
    if (chat.id === chatroom.id) {
      return true;
    } else {
      return false;
    }
  }

  function getChattingWithMember(members: any) {
    const _member = members.filter((member: any) => {
      return (member.community_member.id !== group && group.user_membership.id);
    });
    return _member[0];
  }

  const [toggleInbox, setToggleInbox] = useState<boolean>(true);
  const [toggleUnread, setToggleUnread] = useState<boolean>(false);
  const [, setShowNewGroupMessageMenu] = useAtom(showNewGroupMessageMenuAtom);
  const [, setShowNewDirectMessageMenu] = useAtom(showNewDirectMessageMenuAtom);
  const [, setIsShowActiveChatSelection] = useAtom(disableActiveChatSelectionAtom);

  function isMobileView() {
    return window.screen.width < 520;
  }

  const ChatList = () => {
    return (
      <>
        {directChats.data && directChats.data.map((chat: any) => {
          if (chat.type === 'group') {
            return (
              <li className="group-chats" key={chat.updated_at}>
                <span
                  className='mes-open'
                  onClick={() => {
                    setChatroom(chat);
                    localStorage.setItem("isShowChatroom", "yes");
                    setIsShowChatroom(true);
                    setToggleShowProfiles(false);
                    if (chat.id !== chatroom.id) {
                      setIsLoadingMessages(true);
                    }
                    setActiveChatroomMessage(chat.id);
                    if (chat.unread_messages_count > 0) {
                      setChatIsclicked(true);
                      getCommunityUnread();
                    }
                    setShowNewGroupMessageMenu(false);
                    setShowNewDirectMessageMenu(false);
                    if (isMobileView()) {
                      setIsShowActiveChatSelection(true);
                    }
                  }}
                >
                  <Dms
                    chat={chat}
                    toggleUnread={toggleUnread}
                    toggleInbox={toggleInbox}
                    member={getChattingWithMember(chat.members)}
                    isChatAdmin={isChatAdmin}
                    isActiveChat={isActiveChat}
                    isMemberAddGroupChat={isMemberAddGroupChat}
                  />
                </span>
              </li>
            );
          } else if (chat.type === 'individual') {
            const groupMembership = group && group.user_membership && group.user_membership.id;

            const memberCanChat = chat.members.find((chatMember: any) => {
              if (
                chatMember.community_member &&
                chatMember.community_member.user !== null &&
                chatMember.community_member.user !== undefined &&
                chatMember.community_member.id !== groupMembership
              ) {
                return true;
              }
            });

            return (
              <li key={chat.updated_at} className="group-chats">
                <span
                  className='mes-open'
                  onClick={() => {
                    setChatroom(chat);
                    localStorage.setItem("isShowChatroom", "yes");
                    setIsShowChatroom(true);
                    setToggleShowProfiles(false);
                    setIsCreateDirectChat(false);
                    if (chat.id !== chatroom.id) {
                      setIsLoadingMessages(true);
                    }
                    setActiveChatroomMessage(chat.id);
                    if (chat.unread_messages_count > 0) {
                      getCommunityUnread();
                      setChatIsclicked(true);
                    }
                    setShowNewGroupMessageMenu(false);
                    setShowNewDirectMessageMenu(false);
                    if (isMobileView()) {
                      setIsShowActiveChatSelection(true);
                    }
                  }}
                >
                  <Dms
                    chat={chat}
                    toggleUnread={toggleUnread}
                    toggleInbox={toggleInbox}
                    member={memberCanChat}
                    isChatAdmin={isChatAdmin}
                    isActiveChat={isActiveChat}
                    isMemberAddGroupChat={isMemberAddGroupChat}
                  />
                </span>
              </li>
            );
          } else {
            return <React.Fragment key={chat.updated_at} />;
          }
        })}
      </>
    );
  };

  const ScrollSpinner = () => {

    return (
      <div className="ScrollSpinner">
        <FontAwesomeIcon icon={faSpinner} />
        <p onClick={() => loadMoreChats()}>Loading more messages</p>
      </div>
    );
  };


  function getIndividualChatMembers(chat: any) {
    try {
      const encounteredIds = new Set();
      const individualChat = chat.members.filter((member: any) => {
        if (
          member.community_member &&
          (member.community_member.user !== null && member.community_member.user !== undefined)
        ) {
          const memberId = member.community_member.id;
          if (!encounteredIds.has(memberId)) {
            encounteredIds.add(memberId);
            return true;
          }
        }
      });

      return individualChat;
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  }

  const loadMoreChats = async () => {
    try {
      const _chatrooms: any = await MessagingService.getInstance().loadMoreChatrooms(directChats && directChats.chatrooms.next_page_url);
      const sortedChats = _chatrooms && _chatrooms.data.filter((chat: any) => {
        if (chat.type === "group") {
          return chat.members.length >= 2;
        } else if (chat.type === "individual") {
          const individualChat = getIndividualChatMembers(chat);
          if (individualChat && individualChat.length > 1) {
            return true;
          }
        }
      })
      setCommunityChats(
        {
          data: [...directChats.data, ...sortedChats],
          chatrooms: _chatrooms
        },
      )
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div className="messages-sidebar">
      <div className="messages-sidebar-mes-menu">
        <NewMessageMenu
          setIsEditGroupchat={setIsEditGroupchat}
          theme={theme}
          setIsShowChatroom={setIsShowChatroom}
          group={group}
        />
      </div>

      <div className='messages-sidebar-dropdown'>
        {isLoading ?
          <div>
            {Array.from({ length: 9 }, (_, index) => (
              <SideBarMessagesLoader key={index} />
            ))}
          </div>
          :
          <>
            <InboxUnread
              setToggleInbox={setToggleInbox}
              setToggleUnread={setToggleUnread}
              toggleInbox={toggleInbox}
              toggleUnread={toggleUnread}
            />

            {(directChats.data.length === 0 || directChats.data === null) &&
              <div className='no-messages'>
                <span
                  style={{ color: (theme && theme.custom) && theme.custom.fontColor }}
                  className='icon'
                >
                  <NochatsIcon />
                </span>
                <span className='heading'>No messages</span>
                <span className='sub-heading'>Send a message</span>
              </div>
            }

            {toggleUnread && memberCommunityUnreadCount === 0 && directChats.data.length > 0 && (
              <div className='no-messages'>
                <span className='no-messages-text'>No unread direct messages.</span>
              </div>
            )
            }

            <ul className="chats-list" id='chats-list'>
              <InfiniteScroll
                scrollableTarget="chats-list"
                dataLength={directChats.data.length}
                next={() => loadMoreChats()}
                hasMore={directChats.chatrooms.next_page_url}
                loader={toggleInbox && <ScrollSpinner />}
              >
                <ChatList />
              </InfiniteScroll>
            </ul>
          </>
        }
      </div>
    </div >
  );
};

const Dms = (
  { chat,
    toggleUnread,
    toggleInbox,
    member,
    isActiveChat,
    isMemberAddGroupChat
  }:
    {
      chat: any,
      toggleUnread: boolean,
      toggleInbox: boolean
      member: any
      isChatAdmin: any
      isActiveChat: any
      isMemberAddGroupChat: boolean
    }
) => {

  const [showNewGroupMessageMenu,] = useAtom(showNewGroupMessageMenuAtom);
  const [showNewDirectMessageMenu,] = useAtom(showNewDirectMessageMenuAtom);
  const [isShowActiveChatSelection,] = useAtom(disableActiveChatSelectionAtom);
  const { memberCommunityUnreadCount, } = React.useContext(DirectChatsContext)

  function isMobileView() {
    if (isActiveChat(chat) && window.screen.width > 520 && !showNewGroupMessageMenu && !showNewDirectMessageMenu) {
      return true
    }
    else if (isActiveChat(chat) && window.screen.width < 520 && !showNewGroupMessageMenu && !showNewDirectMessageMenu && isShowActiveChatSelection) {
      return true;
    }
  }

  return (
    <>
      {(
        toggleInbox || (toggleUnread && chat.unread_messages_count > 0)
      )
        &&
        <div className={`messages-body ${isMobileView() ? "active" : ""}`}>
          {
            chat.type === 'individual' && member && member.community_member && member.community_member.user &&
            <div>
                {member && member.community_member.user.profile_image ?
                <div className="messages-body-image">
                  <img
                    src={member.community_member.user.profile_image}
                    alt="Community Member Profile"
                    className='profile-image'
                  />
                </div>
                :
                <div
                  data-color={miscUtils.getColorInitial(member.community_member.user.full_name)}
                  className="profile-no-image"
                >
                  {miscUtils.getNameInitials(member.community_member.user.full_name)}
                </div>
              }
            </div>
          }

          {
            chat.type === 'group' &&
            <GroupProfileImages chat={chat} isMemberAddGroupChat={isMemberAddGroupChat} />
          }

          {
            chat.type === 'group' &&
            <div className='messages-body-wrapper'>
              <div className='message-timestamp'>
                <div className='recipient-name'>
                  {chat.title}
                </div>

                  <div className="recipient-timestamp">
                    <Moment
                      utc={true}
                      fromNow={true}
                      ago={true}
                    >
                      {chat.updated_at}
                    </Moment>
                    <span className="ago">{" "} ago</span>
                  </div>
                </div>
                {
                  chat.latest_message ?
                  <div className="message-content-count">
                      {
                        (chat.unread_messages_count > 0) && (memberCommunityUnreadCount > 0)
                          ?
                          <span className='unread-message-content'>
                            {ReactHtmlParser(anchorme(chat.latest_message.message))}
                          </span>
                          :
                          <span className='message-content'>
                            {ReactHtmlParser(anchorme(chat.latest_message.message))}
                          </span>
                      }
                      {
                        chat.unread_messages_count > 0 && (memberCommunityUnreadCount > 0) &&
                        <div className='unread-messages-container'></div>
                      }
                    </div>
                    :
                    <>
                    </>
                }
            </div>
          }

          {
            chat.type === 'individual' &&
            member.community_member &&
            member.community_member.user &&
            (
              <div className='messages-body-wrapper'>
                <div className='message-timestamp'>
                  <div className='recipient-name'>
                    {member.community_member.user.full_name}
                  </div>
                  <div className="recipient-timestamp">
                    <Moment
                      utc={true}
                      fromNow={true}
                      ago={true}
                    >
                      {chat.updated_at}
                    </Moment>
                    <span className="ago">{" "} ago</span>
                  </div>
                </div>
                {
                  chat.latest_message ?
                    <div className="message-content-count">
                      {
                        (chat.unread_messages_count > 0) && (memberCommunityUnreadCount > 0)
                          ?
                          <span className='unread-message-content'>
                            {ReactHtmlParser(anchorme(chat.latest_message.message))}
                          </span>
                          :
                          <span className='message-content'>
                            {ReactHtmlParser(anchorme(chat.latest_message.message))}
                          </span>
                      }
                      {
                        (chat.unread_messages_count > 0) && (memberCommunityUnreadCount > 0) &&
                        <div className='unread-messages-container'></div>
                      }
                    </div>
                    :
                    <>
                    </>
                }
              </div>
            )
          }
        </div>
      }
    </>
  )
}

const GroupProfileImages = ({ chat, isMemberAddGroupChat }: { chat: any, isMemberAddGroupChat: any }) => {
  const { membersRef } = React.useContext(DirectChatsContext)
  const membersCount = membersRef.current !== null && chat.id === membersRef.current.chatroomID && membersRef.current.members_count
  const sortedMembers = chat.members.length > 0 && chat.members.sort((a: any, b: any) => a.id - b.id);

  return (
    <ul className="group-profile-pic">
      {sortedMembers.slice(0, 2).map((member: any, index: any) => (
        <li key={index}>
          <UserComponent
            key={chat.id}
            hideAdmin={true}
            user={member.community_member.user}
            hideName={true}
            profilePicture={member.community_member.user && member.community_member.user.profile_image}
            showProfileSlider={false}
          />
        </li>
      ))}
      {chat.members_count > 2 && (
        <li className="message-plus-members">
          <UserComponent
            key={chat.id}
            hideAdmin={true}
            user={chat.members[chat.members.length - 1].community_member.user}
            hideName={true}
            profilePicture={chat && chat.members && chat.members.length > 0 && chat.members[chat.members.length - 1].community_member && chat.members[chat.members.length - 1].community_member.user && chat.members[chat.members.length - 1].community_member.user.profile_image}
            showProfileSlider={false}
          />
          <span className="member-count">+{membersCount ? membersCount - 2 : chat.members_count - 2}</span>
        </li>
      )}
    </ul>
  )
}

const InboxUnread = (
  {
    setToggleInbox,
    setToggleUnread,
    toggleInbox,
    toggleUnread,
  }:
    {
      setToggleInbox: (arg0: boolean) => void,
      setToggleUnread: (arg0: boolean) => void,
      toggleInbox: boolean,
      toggleUnread: boolean,
    }
) => {

  const {
    memberCommunityUnreadCount,
    getCommunityUnread
  } = React.useContext(DirectChatsContext)

  return (
    <div className='inbox-header'>
      <span
        className={`inbox-header-inbox ${toggleInbox && 'active'}`}
        onClick={() => {
          setToggleInbox(true);
          setToggleUnread(false);
          getCommunityUnread()
          if (memberCommunityUnreadCount > 0) {
            getCommunityUnread()
          }
        }}
      >
        Inbox
      </span>
      <span
        className={`inbox-header-unread ${toggleUnread && 'active'}`}
        onClick={() => {
          setToggleInbox(false);
          setToggleUnread(true);
          if (memberCommunityUnreadCount > 0) {
            getCommunityUnread()
          }
        }}
      >
        Unread {(memberCommunityUnreadCount > 0) && `(${memberCommunityUnreadCount})`}
      </span>
    </div>
  )
}


export default withRouter(
  connect(null, null)(MessagingSidebar as any) as any
) as any;
