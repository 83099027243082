import React, { useState, useEffect } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import MainLoadingComponent from "components/mainLoading";
import Loadable from "react-loadable";
import SidebarComponent from "components/sidebar";
import SidebarForChannelComponent from "components/sidebar/sidebarForChannel";
import { connect } from "react-redux";
import { Map } from "immutable";
import appConfig from "appConfig";
import PrivateRoute from "routes/PrivateRoute";
import { ISearchContainerProps } from "./ISearchContainerProps";
import { setlocalStorageValue, getlocalStorageValue } from "utils/localStorage";

const AsyncSearch: any = Loadable({
  loader: () => import("containers/search"),
  loading: MainLoadingComponent
});

const AsyncRouterSwitch: any = Loadable({
  loader: () => import("containers/routerSwitch"),
  loading: MainLoadingComponent
});

const SearchContainer: React.FC<ISearchContainerProps> = props => {
  const [subdomain, setCurrentSubdomain] = useState<any>();
  const [isSideBar, setIsSideBar] = useState<any>("");
  const [sideBarIsOpen, setSideBarIsOpen] = useState<string>("");

  const { isAuthed, global } = props;

  useEffect(() => {
    const [currentSubdomain] = window.location.hostname.split(".");

    if (window.innerWidth < 992) {
      setlocalStorageValue("sidebar", false);
    }

    if (window.innerWidth > 992) {
      setSideBarIsOpen("sidebar-is-open");
    }

    if (
      currentSubdomain &&
      !appConfig.reservedSubdomains.includes(currentSubdomain)
    ) {
      setCurrentSubdomain(currentSubdomain);
    } else {
      setCurrentSubdomain(null);
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value) {
      setSideBarIsOpen("sidebar-is-open");
    }
    setIsSideBar(getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value]);

  return (
    <div
      className={
        isSideBar && isAuthed ? `main-wrap ${sideBarIsOpen}` : "main-wrap"
      }
    >
      {isAuthed ? (
        <SidebarComponent subdomain={subdomain} group={null} />
      ) : (
        <SidebarForChannelComponent
          subdomain={subdomain || (global && global.currentSlug)}
        />
      )}
      <div
        className={`content-wrap flex flex-justify-center ${!isAuthed &&
          "no-auth"}`}
      >
        {!subdomain ? (
          <Switch>
            <PrivateRoute
              authed={isAuthed}
              group={props.group}
              exact={true}
              path={"/search"}
              component={<AsyncSearch subdomain={subdomain} />}
            />
          </Switch>
        ) : (
          <Switch>
            <PrivateRoute
              authed={isAuthed}
              group={props.group}
              exact={true}
              path={"/search"}
              component={<AsyncSearch subdomain={subdomain} />}
            />
            <Route
              render={() => {
                return (
                  <AsyncRouterSwitch
                    switchToMain={true}
                    subdomain={subdomain}
                  />
                );
              }}
            />
          </Switch>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: ISearchContainerProps) => {
  return {};
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const global = Map(state.get("global", {})).toJS() as any;
  const globalUser: any = state.getIn(["authorize", "user"]);

  return {
    globalUser,
    global,
    guest: authorize.guest,
    uid: authorize.uid,
    isAuthed: authorize.authed,
    group: global.selectedGroup
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchContainer as any) as any
);
