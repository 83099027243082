import React from "react";
import "./userComments.scss";

import Moment from "react-moment";
import ReadMore from "utils/readMore/readMore";
import anchorme from "anchorme";
import { GroupService } from "api/services/groups";
import { ReactComponent as Comments } from 'assets/img/comments.svg'
import Dropdown from "elements/dropdown";
import { UserService } from "api/services/users";
import { toast, ToastType } from "react-toastify";
import CommentInput from "components/commentInput";
import PostsFeedLoader from "components/contentLoaders/PostsFeedLoader";
import { UserProfileContext } from "../UserProfile";
import { ReactComponent as DownloadIcon } from 'components/group/groupPost/download.svg';
import ExpandedInlineImage from "components/ExpandedInlineImage/ExpandedInlineImage";

const UserComments = (props: {
  userId: number
  group: any
  userPosts: any
}) => {
  const { userId, group } = props
  const { groupData, } = React.useContext(UserProfileContext)

  const [userComments, setUserComments] = React.useState<any>(null);
  const [isEditing, setIsEditing] = React.useState<number>();

  const getUserComments = async () => {
    await UserService.getInstance()
      .getUserAuthoredComments(groupData !== undefined && groupData, userId)
      .then((res: any) => {
        if (res && res.data) {
          setUserComments(res.data);
        }
      });
  };

  React.useEffect(() => {
    getUserComments()
  }, [groupData, isEditing]);

  const isLoading = React.useMemo(() => { return userComments === null }, [userComments]);

  return (
    <>
      <div className="user-profile-body-middle-comments">
        <span>{userComments && userComments.length > 0 && 'Your comments'}</span>
      </div>

      {
        isLoading ?
          <div className="events-loader user-profile-comments-loader-container">
            <PostsFeedLoader
              padding={2}
              styles={{ background: "#FFFFFF", borderRadius: "6px" }}
            />
          </div>
          :
          (
            userComments.length > 0 ?
              userComments.map((comment: any) =>
              (
                <li
                  key={comment.id}
                  className='comment-container'
                >
                  <Comment
                    comment={comment && comment}
                    comments={userComments}
                    setUserComments={setUserComments}
                    group={group}
                    isEditing={isEditing === comment.id}
                    setIsEditing={setIsEditing}
                  />
                </li>
              ))
              :
              <span className="empty-payload">No authored comments yet.</span>
          )
      }
    </>
  );
};

export default UserComments


const Comment = (props: {
  comment: any,
  comments: any,
  setUserComments: any
  group: any
  isEditing: boolean
  setIsEditing: any
}) => {

  const { comment, comments, setUserComments, isEditing, setIsEditing } = props
  const { groupData, } = React.useContext(UserProfileContext)

  const commentActions: Array<any> = [];
  const commnetInputReference = React.useRef(null);

  function removeCommentFromList(commentId: number) {
    const updatedList = comments.filter((comment: { id: number; }) => comment.id !== commentId);
    setUserComments(updatedList);
  }

  function handleDeleteComment() {
    GroupService.getInstance()
      .deletePostComment(comment.id)
      .then(() => {
        removeCommentFromList(comment.id)
        toast("Comment Deleted!", {
          type: ToastType.SUCCESS,
          autoClose: 2000,
        })
      })
  }

  commentActions.push({ label: "Edit", callback: () => setIsEditing(comment.id) });
  commentActions.push({ label: "Delete", callback: handleDeleteComment });

  const defualtUrl = comment.post && comment.post.group.url

  const [imageSrc, setImageSrc] = React.useState<string>('');
  const containersRef = React.useRef<any>([])
  const [showComponent, setShowComponent] = React.useState<boolean>(false);

  const handleImageClick = React.useCallback((event: MouseEvent) => {
    const clickedImage = event.target as HTMLElement;
    if (clickedImage) {
      containersRef.current = clickedImage
      setImageSrc(containersRef.current.src)
      setShowComponent(true)
    }
  }, []);

  React.useEffect(() => {
    handleImageCommentClick();
  }, []);

  function handleImageCommentClick() {
    const containers = document.querySelectorAll(`.inline-img-${comment.id} figure`);

    if (containers) {
      containers.forEach((container) => {
        const images = container.querySelectorAll('img');
        images.forEach((image) => {
          (image as HTMLElement).style.cursor = 'pointer';
          image.addEventListener('click', handleImageClick);
        });

        return () => {
          images.forEach((image) => {
            image.removeEventListener('click', handleImageClick);
          });
        };
      });
    }
  }

  return (
    <div className={`comments-inner-container inline-img-${comment.id}`} >
      <ExpandedInlineImage
        imageSrc={imageSrc}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        isCommentComponent
      />
      <div className="timestamp-menu-dots" >
        <Dropdown hideChevron={true} actions={commentActions} commentsView />
      </div>

      <div className="timestamp-menu-dots-small">
        <Dropdown hideChevron={true} actions={commentActions} commentsView />
      </div>

      <span className='comment-icon'>
        <Comments width={21} height={18} strokeWidth={2} />
      </span>

      <div className='comment-header-wrapper'>
        {
          comment.post &&
          <div className='comment-header-wrapper-post-name'>
            <div className='comment-header-section'>
              <div className='commented-on'>
                <div>
                  <div className='commented-one'>
                      <span className="commented-on-text">
                        You commented on {comment.post.author.full_name}'s {"  "}
                      </span>
                    <button className="post-slug">
                        <a href={`${defualtUrl}/posts/${comment.post.slug}`} target="_self">
                        post
                        </a>
                      </button>
                      {"  "} in
                    </div>

                    <div className="channel-tooltip">
                      {
                        comment.post && comment.post.postable_type === 'channels'
                          ?
                          <span className="channel-tooltip-name" >
                            {comment.post.postable.name}
                          </span>
                          :
                          comment.post && comment.post.postable_type === 'coursecontents'
                            ?
                            <span className="channel-tooltip-name" >
                              {comment.post.postable.course.title}
                          </span>
                          :
                          <span className="channel-tooltip-name">
                            {comment.post.postable.title}
                          </span>
                      }
                    </div>
                  </div>

                  <div className="timestamp">
                    <Moment
                      utc={true}
                      fromNow={true}
                      ago={true}
                      style={{
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '15px',
                        color: '#767A8A',
                      }}
                    >
                      {comment.created_at}
                    </Moment>
                    <span>
                      {""} ago
                    </span>
                  </div>
                </div>

              </div>

          </div>
        }
        {
          isEditing ?
            <CommentInput
              group={groupData}
              inputReference={commnetInputReference}
              postId={comment.post.id}
              user={comment.commentator}
              editMode
              comment={comment}
              onCancelEdit={() => setIsEditing(null)}
            />
            :
            <div
              className="comment-container-content"
              onClick={handleImageCommentClick}
            >
              {
                comment.comment.length <= 280 &&
                <span dangerouslySetInnerHTML={{ __html: anchorme(comment.comment) }} />
              }
              {
                comment.comment.length > 280 &&
                <ReadMore
                  text={comment.comment}
                  numberOfLines={1}
                  lineHeight={1}
                  showLessButton
                />
              }
              {
                comment.files !== null && comment.files.length > 0 &&
                comment.files.map((file: any, index: any) =>
                (
                  <div className="comment-file-content" key={index}>
                    <div className="comment-file-content-container">
                      <a
                        className="comment-file-content-container-download-link"
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: (groupData && groupData.custom_branding) ? groupData.custom_branding.primaryColor : '#888' }}
                      >
                        <DownloadIcon />
                      </a>
                      <div className="comment-file-content-container-details">
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="comment-file-content-container-details-name">
                            {file.original_file_name}
                          </span>
                        </a>
                        <span className="comment-file-content-container-details-size">
                          {(file.file_size / 1000000).toFixed(2)} MB
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
        }
      </div>

    </div>
  )
}
