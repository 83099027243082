import React from 'react';
import { IDraftContentProps } from './IDraftContentProps';
import { ContentState, convertFromHTML, convertFromRaw, EditorState } from "draft-js";
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/linkify/lib/plugin.css';
import createLinkPlugin from '@draft-js-plugins/anchor';
import '@draft-js-plugins/anchor/lib/plugin.css';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import "./DraftContent.scss";
import miscUtils from 'utils/miscUtils';
import 'draft-js/dist/Draft.css';

import MentionComponent from 'components/PostEditor/MentionComponent';
import ExpandedInlineImage from 'components/ExpandedInlineImage/ExpandedInlineImage';

const MAX_VIEWABLE_POST_DESCRIPTION_LENGTH: number = 1000;
const MAX_VIEWABLE_POST_DESCRIPTION_HEIGHT: number = 250;

//mentions plugin
const mentionPlugin = createMentionPlugin({
  //@ts-ignore
  mentionComponent: MentionComponent,
  theme: {
    mention: 'draft-content-mention-text',
  },
  mentionPrefix: '@',
});
// eslint-disable-next-line no-shadow

//static toolbar plugin
const staticToolbarPlugin = createToolbarPlugin();

//linkify plugin
const linkifyPlugin = createLinkifyPlugin({
  theme: {
    link: 'postable-draft-linkify-text',
  },
  target: '_blank',
});

//anchor plugin
const linkPlugin = createLinkPlugin({
  theme: {
    input: 'postable-draft-link-input',
    inputInvalid: 'postable-draft-link-input--invalid',
    link: 'postable-draft-link-text',
  },
  linkTarget: '_blank',
});

//image plugin
const imagePlugin = createImagePlugin();

const plugins = [mentionPlugin, staticToolbarPlugin, linkifyPlugin, linkPlugin, imagePlugin];

const DraftContent: React.FC<IDraftContentProps> = (props) => {
  const draftContentWrapperRef = React.useRef<any>();

  const [mountCounter, setMountCounter] = React.useState<number>(0);
  const [postDescriptionLength, setPostDescriptionLength] = React.useState<number>(() => {
    if (props.post.description) {
      return (miscUtils.stripHTML(props.post.description as string)).length;
    }

    return 0;
  });
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [editorState, setEditorState] = React.useState<EditorState>(() => {
    if (props.post.description_json) {
      const contentState: ContentState = convertFromRaw(JSON.parse(props.post.description_json));
      return EditorState.createWithContent(contentState);
    } else {
      const blocksFromHtml = convertFromHTML(props.post.description ? props.post.description : "");
      const contentState: ContentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
      return EditorState.createWithContent(contentState);
    }
  });

  React.useEffect(() => {
    if (props.post.description) {
      setPostDescriptionLength((miscUtils.stripHTML(props.post.description as string)).length)
    }
  }, [props.post.description]);

  React.useEffect(() => {
    if (mountCounter > 0) {
      if (props.post.description_json) {
        const contentState: ContentState = convertFromRaw(JSON.parse(props.post.description_json));
        //@ts-ignore
        const newState: EditorState = EditorState.push(editorState, contentState);
        setEditorState(newState)
      } else {
        const blocksFromHtml = convertFromHTML(props.post.description ? props.post.description : "");
        const contentState: ContentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
        //@ts-ignore
        const newState: EditorState = EditorState.push(editorState, contentState);
        setEditorState(newState);
      }
    }
  }, [props.post]);

  const [imageSrc, setImageSrc] = React.useState<string>('');
  const containersRef = React.useRef<any>([])
  const [showComponent, setShowComponent] = React.useState<boolean>(false);

  const handleImageClick = React.useCallback((event: MouseEvent) => {
    const clickedImage = event.target as HTMLElement;
    if (clickedImage) {
      containersRef.current = clickedImage
      setImageSrc(containersRef.current.src)
      setShowComponent(true)
    }
  }, []);

  React.useEffect(() => {
    setMountCounter(1);
    const containers = document.querySelectorAll(`.inline-img-${props.post.id} figure`);

    if (containers) {
      containers.forEach((container) => {
        const images = container.querySelectorAll('img');
        images.forEach((image) => {
          (image as HTMLElement).style.cursor = 'pointer'
          image.addEventListener('click', handleImageClick);
        });

        return () => {
          images.forEach((image) => {
            image.removeEventListener('click', handleImageClick);
          });
        };
      })

    }
  }, []);

  const postIsLong = () => {
    return (
      postDescriptionLength > MAX_VIEWABLE_POST_DESCRIPTION_LENGTH ||
      (draftContentWrapperRef.current && draftContentWrapperRef.current.clientHeight > MAX_VIEWABLE_POST_DESCRIPTION_HEIGHT)
    )
  }

  return (
    <div className={`draft-content-container inline-img-${props.post.id}`}>
      <ExpandedInlineImage
        imageSrc={imageSrc}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        isCommentComponent
      />
      <div 
      className={`draft-content-wrapper ${postIsLong() && !showAll && !props.disableCharacterLimit ? 'draft-content-wrapper--restricted' : ''} ${props.smallHeader ? 'draft-content-wrapper--smallheader' : ''} ${props.draftContentWrapperClassName ? props.draftContentWrapperClassName : ''}`}
      ref={draftContentWrapperRef}
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          readOnly
        />
      </div>

      {
        postIsLong() && !props.disableCharacterLimit &&
        (
          <div className="draft-content-action-container">
            <p
              className="draft-content-action-button"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "Show less" : "See more"}</p>
          </div>
        )
      }
    </div>
  )
};

export default React.memo(DraftContent);
