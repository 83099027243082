import React, { useEffect, useState, useRef } from "react";

import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { stateToHTML } from "draft-js-export-html";
import _ from 'lodash';

import "./CommentInput.scss";
import { ICommentInputProps } from "./ICommentInputProps";
import UserComponent from "components/userComponent";
import PostEditor from "components/PostEditor";
import { GroupService } from "../../api/services/groups";
import { PostService } from "api/services/post";
import ImageButton from 'components/post/PostableDraft/ImageButton/ImageButton';
import GIFButton from 'components/post/PostableDraft/GIFButton/GIFButton';
import FileButton from "components/post/PostableDraft/FileButton/FileButton";

export const getHtmlState = (newState: EditorState) => {
  // eslint-disable-next-line
  const contentState = newState.getCurrentContent();
  // eslint-disable-next-line
  const options = {
    // eslint-disable-next-line consistent-return
    entityStyleFn: (entity: any) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "mention") {
        const data = entity.getData();
        return {
          element: "span",
          attributes: {
            "data-mention-id": _.get(data, "mention.id"),
            class: "mentioned-member"
          },
          style: {
            // Put styles here...
          }
        };
      }
    }
  };
  return stateToHTML(contentState, options)
    .replace(/(<p>)/g, "")
    .replace(/(<br>)/g, "")
    .replace(/(<\/p>)/g, "");
}

const CommentInput: React.FC<ICommentInputProps> = props => {
  const { postId, group, onPost, theme } = props;
  const ProfileImage = group && group.user_membership && group.user_membership.user && group.user_membership.user.profile_image

  const resetEditorReference = useRef<any>(null);

  const [commentInput, setCommentInput] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [names, setNames] = useState<any>([]);
  const [posting, setPosting] = useState<boolean>(true);
  const [isEditorInFocus, setIsEditorInFocus] = useState<boolean>(false);
  const [showGIFComp, setShowGIFComp] = useState<boolean>(false)
  const [commentJSON, setCommentJSON] = useState<any>(() => {
    if (props.editMode) {
      return props.comment.comment_json;
    }

    return null;
  });

  const [editorState, setEditorState] = useState<any>(() => {
    if (props.editMode && props.comment.comment_json) {
      const contentState: ContentState = convertFromRaw(JSON.parse(props.comment.comment_json));
      return EditorState.createWithContent(contentState);
    }

    if (props.editMode && !props.comment.comment_json) {
      const blocksFromHtml = convertFromHTML(props.comment.comment ? props.comment.comment : "");
      const contentState: ContentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
      return EditorState.createWithContent(contentState);
    }

    return EditorState.createEmpty();
  });

  const [commentFile, setCommentFile] = React.useState<any>(
    () => {
      if (
        props.editMode &&
        Array.isArray(props.comment.files) &&
        (props.comment.files.length > 0 || props.comment.files !== null)
      ) {
        return props.comment.files.map((file: {
          original_file_name: any;
          file_size: number;
          id: number;
          url: string,
        }) => {
          return {
            name: file.original_file_name,
            size: file.file_size,
            file_id: file.id,
            file_src: file.url,
          };
        });

      }
      return null;
    }
  );

  // used to keep track the length of the draft editor js content
  const [commentDescriptionLength, setCommentDescriptionLength] = useState<string>('')

  const getMentionsString = () => {
      let mentionsString: string = "";

      selectedUsers.forEach((mention: any) => {
          if(mentionsString.trim() === "") {
              mentionsString = `${mention}`;
          } else {
              mentionsString = `${mentionsString},${mention}`;
          }
      });

      return mentionsString;
  }

  const getCommentFormData = () => {
    const commentFileObject = new FormData();

    if (commentFile !== null) {
      for (let i = 0; i < commentFile.length; i++) {
        if ((commentFile[i] instanceof Blob)) {
          commentFileObject.append("media_files[]", commentFile[i]);
        }
        continue;
      }
    }

    commentFileObject.append("comment", commentInput);
    commentFileObject.append("comment_json", commentJSON);
    commentFileObject.append("mentions", getMentionsString());

    return commentFileObject;
  }

  useEffect(() => {
    if (editorState) {
      setCommentInput(getHtmlState(editorState));
      setCommentJSON(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  function getId(id: any) {
    const tempHolder = selectedUsers;
    tempHolder.push(id);
    setSelectedUsers(tempHolder.filter(onlyUnique));
  }

  function getName(name: any) {
    const tempNames = names;
    tempNames.push(name);
    setNames(tempNames.filter(onlyUnique));
  }

  const onEditorChange = (newEditorState: any) => {
    const contentState = newEditorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const newMentions: any = [];

    // Extract mentions from the raw content state
    rawContentState.blocks.forEach((block: any) => {
      if (block.entityRanges.length > 0) {
        block.entityRanges.forEach((entityRange: any) => {
          const entity = rawContentState.entityMap[entityRange.key];
          if (entity.type === 'mention') {
            newMentions.push(entity.data.mention.id);
          }
        });
      }
    });

    // Identify removed mentions
    const removedMentions = selectedUsers.filter((mention: any) => !newMentions.includes(mention));
    if (removedMentions.length > 0) {
      // Update mentions state to reflect removed mentions
      setSelectedUsers(newMentions);
    }
};

  function createComment() {
    // @ts-ignore
    if (commentInput.length !== 0) {
      setPosting(false);
    }
    const commentWithFileObject = getCommentFormData();

    GroupService.getInstance()
      .createPostComment(
        postId,
        commentWithFileObject
      )
      .then((response: any) => {
        setCommentInput("");
        setEditorState(EditorState.createEmpty());
        setSelectedUsers([]);
        setNames([]);
        setPosting(true);
        setCommentFile(null)
        if (onPost) {
          onPost(response.data);
        }
        resetEditorReference.current();
      })
  }

  const editComment = () => {
    // @ts-ignore
    if (commentInput.length !== 0) {
      setPosting(false);
    }

    const commentObject = getCommentFormData();

    PostService.getInstance()
      .updateComment(
        props.comment.id,
        commentObject
      )
      .then((response: any) => {
        setCommentInput("");
        setEditorState(EditorState.createEmpty());
        setSelectedUsers([]);
        setNames([]);
        setPosting(true);
        if (onPost) {
          onPost(response.data);
        }
        resetEditorReference.current();
        props.onCancelEdit();
      })
  }

  const replyToComment = () => {
    // @ts-ignore
    if (commentInput.length !== 0) {
      setPosting(false);
    }
    const commentReplyWithFileObject = getCommentFormData();
    PostService.getInstance()
      .replyToComment(
        props.group.id,
        props.comment.id,
        commentReplyWithFileObject
      )
      .then((response: any) => {
        setCommentInput("");
        setEditorState(EditorState.createEmpty());
        setSelectedUsers([]);
        setNames([]);
        setPosting(true);
        if (onPost) {
          onPost(response.data);
        }

        resetEditorReference.current();
      })
  }

  const onCancelEdit = () => {
    setCommentInput("");
    setEditorState(EditorState.createEmpty());
    setSelectedUsers([]);
    setNames([]);
    props.onCancelEdit();
  }

  const getEditorState = React.useCallback((newState: EditorState) => {
    let finalState = newState;
    setEditorState(finalState);
  }, [setEditorState]);

  const clickInlineImageInput = () => {
    const inlineImageInputElement: HTMLElement = document.getElementById(
      `comment-draft-editor-inline-image-input-${props.postId && props.postId}-${props.comment && props.comment.id}`
    ) as HTMLElement;

    if (inlineImageInputElement) {
      inlineImageInputElement.click();
    }
  }

  const clickFileAttachmentInput = () => {
    const inlineImageInputElement: HTMLElement = document.getElementById(
      `comment-draft-editor-file-attachment-input-${props.postId && props.postId}-${props.comment && props.comment.id}`
    ) as HTMLElement;

    if (inlineImageInputElement) {
      inlineImageInputElement.click();
    }
  }

  const getButtonText = () => {
    if (props.editMode) {
      if (!posting) {
        return 'Saving...';
      } else {
        return 'Edit';
      }
    } else {
      if (!posting) {
        return 'Replying...';
      } else {
        return 'Reply';
      }
    }
  }

  const whiteCommunityTheme = props.theme && props.theme.custom && props.theme.custom.primaryColor === '#ffffff'

  return (
    <div className={`post-comment-input ${window.location.pathname.includes('lectures') || window.location.pathname.includes('events') ?
      'post-comment-input--misalign' :
      ''
      } ${props.editMode ? 'post-comment-input--editmode' : ''}
    ${((window.location.pathname.includes('channel') || window.location.pathname.split('/').length === 1) && props.editMode) ?
        'post-comment-input--channel-editmode' : ''} ${props.replyView ? 'post-comment-input--reply' : ''
      }
      ${(window.location.pathname.split("/")[1] === "courses") && !window.location.pathname.includes('lectures') ? 'post-comment-input--courses-section' : ''}
      `}
    >
      <UserComponent
        user={(group.user_membership && group.user_membership.user) || (props.loggedInUser && props.loggedInUser) || props.user && props.user}
        profilePicture={ProfileImage}
        group={group}
      />
      <div
        className={`
          ${commentInput && commentInput.length > 0
            ? "comment-input flex-w-wrap"
            : "comment-input flex-w-wrap"} ${props.level ? `level-${props.level}` : ''
          }
          ${(window.location.pathname.split("/")[1] === "courses") && !window.location.pathname.includes('lectures') ? `course-section-level-${props.level}` : ''}
          ${props.lectureView ? 'comment-input-lecture-view' : ''}`
        }
        onClick={() => {
          if (props.inputReference && props.inputReference.current && props.inputReference.current.editor) {
            props.inputReference.current.editor.focus();
          }
        }}
      >
        <div className="PostEditor__container">
          <div className={`PostEditor ${props.level ? `level-${props.level}` : ''}`}>
            <PostEditor
              group={group}
              onEditorChange={onEditorChange}
              getEditorState={getEditorState}
              getId={getId}
              getName={getName}
              ref={props.inputReference}
              className={`PostEditor__input ${props.singlePostView ? 'PostEditor__input__singlepage' : ''} ${props.editMode ? 'PostEditor__input__editmode' : ""}`}
              placeholder={props.editMode ? "Edit comment" : "Write a comment"}
              editorState={editorState}
              resetEditorReference={resetEditorReference}
              onFocus={() => setIsEditorInFocus(true)}
              onBlur={() => setIsEditorInFocus(false)}
              setShowGIFComp={setShowGIFComp}
              showGIFComp={showGIFComp}
              postId={props.postId}
              commentId={props.comment && props.comment.id}
              commentData={props.comment && props.comment}
              editMode={props.editMode}
              commentFile={commentFile}
              setCommentFile={setCommentFile}
              clickFileAttachmentInput={clickFileAttachmentInput}
              setCommentDescriptionLength={setCommentDescriptionLength}
            />
          </div>
        </div>

        <span
          className={`comment-action-input ${commentDescriptionLength.length === 0 ? 'empty-comment' : 'not-empty-comment'} ${isEditorInFocus ? 'focused-editor' : ''}`}
          style={{
            backgroundColor: (theme && theme.custom) && commentDescriptionLength.length > 0 && theme.custom.primaryColor,
            color: whiteCommunityTheme ? '#000' : '',
            border: whiteCommunityTheme ? '1px solid #000' : ''
          }}
          onClick={(e: any) => {
            e.stopPropagation();

            if (commentInput.length === 0) {
              return
            }

            if (props.editMode) {
              editComment();
              return;
            }

            if (props.replyView) {
              replyToComment();
              return;
            }

            if (posting) {
              createComment();
            }

          }}
        >
          {getButtonText()}

        </span>

        <ImageButton onClick={clickInlineImageInput} />
        <GIFButton onClick={() => setShowGIFComp(!showGIFComp)} />
        <FileButton onClick={clickFileAttachmentInput} />

      </div>
      {props.editMode &&
        <span className="cancel-comment-edit" onClick={onCancelEdit}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      }
    </div>
  );
};

export default CommentInput;
