import React from 'react'

const Library: React.FC<{ strokeColor?: string }> = ({ strokeColor }) => {
    return (
        <>
            {
                strokeColor ?
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.04402 19.2518C3.08994 19.8139 3.18851 20.3306 3.4358 20.816C3.81929 21.5686 4.43121 22.1805 5.18386 22.564C5.6692 22.8113 6.1859 22.9099 6.748 22.9558C7.28919 23 7.95354 23 8.7585 23H16.8384C17.3655 23 17.8203 23 18.1949 22.9694C18.5902 22.9371 18.9834 22.8658 19.3618 22.673C19.9263 22.3854 20.3852 21.9265 20.6728 21.362C20.8656 20.9836 20.937 20.5904 20.9693 20.195C20.9967 19.8593 20.9998 19.4593 20.9998 19C20.9998 14.3871 20.9998 9.77429 20.9998 5.16144C20.9998 4.6343 20.9999 4.17954 20.9693 3.80497C20.937 3.40963 20.8656 3.01641 20.6728 2.63803C20.3852 2.07354 19.9263 1.6146 19.3618 1.32698C18.9834 1.13419 18.5902 1.06287 18.1949 1.03057C17.8203 0.999969 17.3655 0.999984 16.8384 1H8.75852C7.95358 0.999989 7.28918 0.99998 6.748 1.0442C6.1859 1.09012 5.6692 1.18869 5.18386 1.43598C4.43121 1.81947 3.81929 2.43139 3.4358 3.18404C3.18851 3.66938 3.08994 4.18608 3.04402 4.74818C2.9998 5.28937 2.99981 5.95372 2.99982 6.75869V17.2413C2.99981 17.9123 2.98941 18.5835 3.04402 19.2518ZM18.9759 20.0322C18.9537 20.3038 18.916 20.4045 18.8908 20.454C18.795 20.6422 18.642 20.7951 18.4538 20.891C18.4044 20.9162 18.3036 20.9539 18.032 20.9761C17.7486 20.9992 17.3764 21 16.7998 21H8.79982C7.94324 21 7.36095 20.9992 6.91086 20.9625C6.47245 20.9266 6.24824 20.8617 6.09184 20.782C5.71552 20.5903 5.40956 20.2843 5.21781 19.908C5.03863 19.5563 4.95003 19.2518 5.01661 18.7397C5.14421 17.7581 5.98349 17 6.99982 17H18.9998C18.9998 18.005 19.0578 19.0295 18.9759 20.0322Z"
                            fill={strokeColor} />
                    </svg>
                    :
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 19V16H7C5.34315 16 4 17.3431 4 19M8.8 22H16.8C17.9201 22 18.4802 22 18.908 21.782C19.2843 21.5903 19.5903 21.2843 19.782 20.908C20 20.4802 20 19.9201 20 18.8V5.2C20 4.07989 20 3.51984 19.782 3.09202C19.5903 2.71569 19.2843 2.40973 18.908 2.21799C18.4802 2 17.9201 2 16.8 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22Z" stroke="#767A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
            }
        </>
    )
}

export default Library
