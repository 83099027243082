import React from 'react';
import { IImageGridTextBlockPreviewProps } from './IImageGridTextBlockPreviewProps';
import ImageTextInGridPreview from './ImageTextInGridPreview';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import styled from 'styled-components';
import BlockContainer from '../BlockComponents/BlockContainer';

const ImageGridTextBlockContentContainer: any = styled(BlockContentContainer)`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${(props: any) =>
        props.design.content_container_alignment === 'left' ?
        `flex-start` :
        props.design.content_container_alignment === 'right' ?
        `flex-end` :
        `center`
    };

    &.mobile {
        padding-left: 5px;
        padding-right: 5px;
    }

    @media(max-width: 550px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`;

const ImageGridTextBlockPreview: React.FC<IImageGridTextBlockPreviewProps> = (props) => {

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <ImageGridTextBlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
            {props.blockData.blocks.map((block: any, index: number) => (
                <ImageTextInGridPreview
                key={block.render_id}
                index={index}
                parentLength={props.blockData.blocks.length}
                buildingPreview={props.buildingPreview}
                blockData={{
                    ...block,
                    design: {
                        ...props.blockData.design,
                    }
                }}
                view={props.view}
                pageStyles={props.pageStyles}
                pageFormsOpenState={props.pageFormsOpenState}
                setPageFormsOpenState={props.setPageFormsOpenState}
                />
            ))}
        </ImageGridTextBlockContentContainer>
    </BlockContainer>
  )
}

export default ImageGridTextBlockPreview;