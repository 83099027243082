import React, { useContext, useEffect, useState } from "react";
import "./userProfileDetailsEdit.scss";
import "./UserProfileDetails-responsive.scss";

import { UserService } from "api/services/users";
import { login } from "store/actions/authorizeActions";
import store from "store/configureStore";
import { setCookieValue } from "utils/cookieStorage";
import { CommonConstants } from "constants/common";
import { toast, ToastType } from "react-toastify";
import imageCompression from "browser-image-compression";
import miscUtils from "utils/miscUtils";
import { SSOMethod } from "containers/IntegrationsContainer/SingleSignOn/domain";

import { UserProfileContext } from "../UserProfile";

import { ReactComponent as Twitter } from 'assets/img/twitter.svg'
import { ReactComponent as Facebook } from 'assets/img/facebook.svg'
import { ReactComponent as IG } from 'assets/img/instagram.svg'
import { ReactComponent as LinkedIn } from 'assets/img/linkedin.svg'
import { ReactComponent as Website } from 'assets/img/globe.svg'

interface IUserProfileDetailsComponentProps {
  setShowEdit: any;
}

const UserProfileDetailsEdit: React.FC<IUserProfileDetailsComponentProps> = ({ setShowEdit }) => {
  const [countries, setCountries] = useState<any>([]);
  const [timezones, setTimezones] = useState<any>([]);

  const {
    userData,
    setUserData,
    editData,
    setEditData,
    uid,
    group,
    profileNewSrc,
    setProfileSrc,
    profileFile,
    groupData
  } = useContext(UserProfileContext)

  const options = {
    maxSizeMB: 10,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  const maxHeadlineLength = 120;

  useEffect(() => {
    if (
      userData &&
      userData.timezone_id &&
      userData.timezone_id === "null"
    ) {
      setUserData({
        ...userData,
        timezone_id: null,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (
      editData &&
      editData.timezone_id &&
      editData.timezone_id === "null"
    ) {
      setEditData({
        ...editData,
        timezone_id: null,
      });
    }
  }, [editData]);

  useEffect(() => {
    getCountries();
    getTimezones();
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileNewSrc) {
      setProfileSrc(profileNewSrc);
    }
  }, [profileNewSrc]);


  const getUserDetails = (isUserUpdated?: boolean) => {
    UserService.getInstance()
      .getUserProfile(uid)
      .then((userResponse: any) => {
        setUserData(userResponse.data);
        if (userResponse.data.profile_image) {
          setProfileSrc(userResponse.data.profile_image);
        }
        if (isUserUpdated) {
          store.store.dispatch(login(userResponse.data));
          setCookieValue(CommonConstants.USER_KEY, userResponse.data);
        }
      });
  };

  const getCountries = () => {
    UserService.getInstance()
      .getCountries()
      .then((countriesResponse: any) => {
        setCountries(countriesResponse.data);
      });
  };

  const getTimezones = () => {
    UserService.getInstance()
      .getTimezones()
      .then((timezonesResponse: any) => {
        setTimezones(timezonesResponse.data);
      });
  };

  const handleForm = () => {
    const error = false;
    const errorLimit = false;

    if (!error) {
      const editPromises: any[] = [];

      editPromises.push(
        UserService.getInstance().updateUserProfile(uid, editData)
      );


      if (profileFile) {
        imageCompression(profileFile, options).then(function (
          compressedFile: any
        ) {
          const dataImage = new FormData();
          dataImage.append(
            "profile_image",
            miscUtils.blobToFile(compressedFile, profileFile)
          );
          editPromises.push(
            UserService.getInstance().updateUserProfileImage(uid, dataImage)
          );
        });
      }

      if (!errorLimit) {
        Promise.all(editPromises).then(() => {
          setTimeout(() => getUserDetails(true), 2000);
          toast("Successfully saved changes!", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
          setTimeout(() => {
            setShowEdit(false)
          }, 1000)
        });
      }
    }
  };

  const onChange = (e: any) => {

    if (e.target.name === "headline" && e.target.value.length <= maxHeadlineLength) {
      setUserData({ ...userData, [e.target.name]: e.target.value });
      setEditData({ ...editData, [e.target.name]: e.target.value })
    } else if (e.target.name !== 'headline') {
      setUserData({ ...userData, [e.target.name]: e.target.value });
      setEditData({ ...editData, [e.target.name]: e.target.value })
    }
  };

  const profileUpdatesDisabled = () => {
    return (
      group &&
      group.sso_enabled &&
      group.sso_type === SSOMethod.OAUTH &&
      group.oauth_sso &&
      group.oauth_sso.disable_user_profile_updates
    )
  }

  const handleDiscard = () => {
    getUserDetails()
    setTimeout(() => {
      setShowEdit(false)
    }, 1000)
  }

  const [profileUpdateIns, setProfileUpdateIns] = useState<string | null>(null)
  const [isProfileUpdateDisabled, setIsProfileUpdateDisabled] = useState<boolean>(false)

  const [isSSOEnabled, setIsSSOEnabled] = useState<boolean>(() => {
    if (groupData) {
      return (!!groupData.sso_enabled)
    }
    return false;
  });

  useEffect(() => {
    if (groupData) {
      setIsSSOEnabled(!!groupData.sso_enabled);
      if (groupData.oauth_sso) {
        const { disable_user_profile_updates, custom_user_profile_update_instruction } = groupData.oauth_sso
        setProfileUpdateIns(custom_user_profile_update_instruction)
        setIsProfileUpdateDisabled(disable_user_profile_updates)
      }
    }
  }, [groupData])


  return (
    <>
      <form
        onSubmit={e => e.preventDefault()}
        className={"flex flex-direction-column settings-edit-user"}
      >
        <div className="user-settings">

          {
            isSSOEnabled && isProfileUpdateDisabled &&
            <span className="user-profile-details-disabled-edit">
              {profileUpdateIns}
            </span>
          }

          <div className="user-settings-form-group">
            <label>Full name</label>
            <input
              onChange={onChange}
              value={(userData && userData.full_name) || ""}
              name="full_name"
              type="text"
              aria-label="Full name"
              disabled={profileUpdatesDisabled()}
            />
          </div>

          <div className="user-settings-form-group">
            <label>Headline</label>
            <input
              onChange={onChange}
              value={(userData && userData.headline) || ""}
              name="headline"
              type="text"
              aria-label="Headline"
              disabled={profileUpdatesDisabled()}
              maxLength={maxHeadlineLength}
            />
          </div>

          <div className="user-settings-form-group">
            <label>Email</label>
            <input
              onChange={onChange}
              value={(userData && userData.email) || ""}
              name="email"
              type="text"
              aria-label="Email"
              disabled={profileUpdatesDisabled()}
            />
          </div>

          <div className="user-settings-form-group">
            <label>Country</label>
            <select
              onChange={onChange}
              name="country_id"
              disabled={profileUpdatesDisabled()}>
              <option value="null" className="user-settings-form-group-country-options">Select Country</option>
              {countries &&
                countries.map((country: any) => {
                  return (
                    <option
                      key={country.id}
                      value={country.id}
                      selected={
                        userData.country
                        && userData.country.id === country.id
                      }
                    >
                      {country.name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className="user-settings-form-group">
            <label>City</label>
            <input
              onChange={onChange}
              value={(userData && userData.location) || ""}
              name="location"
              type="text"
              aria-label="Location"
              disabled={profileUpdatesDisabled()}
            />
          </div>

          <div className="user-settings-form-group">
            <label>Timezone</label>
            <select
              onChange={onChange}
              name="timezone_id"
              disabled={profileUpdatesDisabled()}
            >
              <option value="null">Select Timezone</option>
              {timezones &&
                timezones.map((timezone: any) => {
                  return (
                    <option
                      key={timezone.id}
                      value={timezone.id}
                      selected={
                        userData.timezone &&
                        userData.timezone.id === timezone.id
                      }
                    >
                      {timezone.text}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className="user-settings-form-group">
            <label>Job title</label>
            <input
              onChange={onChange}
              value={(userData && userData.job_title) || ""}
              name="job_title"
              type="text"
              aria-label="Job title"
              disabled={profileUpdatesDisabled()}
            />
          </div>

          <div className="user-settings-form-group">
            <label>Bio</label>
            <textarea
              onChange={onChange}
              value={(userData && userData.about_me) || ""}
              name="about_me"
              disabled={profileUpdatesDisabled()}
            />
          </div>
        </div>
      </form>
      <span className="social-media-section-header">Website & Social media</span>
      <div className="social-media-section">
        <label>Website URL</label>
        <div className="social-media">
          <div className="social-media-icon">
            <Website />
          </div>
          <input
            type="text"
            onChange={onChange}
            value={(userData && userData.website) || ""}
            placeholder="Enter URL"
            name="website"
            aria-label="Website"
            disabled={profileUpdatesDisabled()}
          />
        </div>

        <label>Linkedin URL</label>
        <div className="social-media">
          <div className="social-media-icon">
            <LinkedIn />
          </div>
          <input
            type="text"
            onChange={onChange}
            value={(userData && userData.linkedin) || ""}
            placeholder="Enter profile link"
            name="linkedin"
            aria-label="Linkedin"
            disabled={profileUpdatesDisabled()}
          />
        </div>

        <label>Facebook URL</label>
        <div className="social-media">
          <div className="social-media-icon">
            <Facebook />
          </div>
          <input
            type="text"
            onChange={onChange}
            value={(userData && userData.facebook) || ""}
            placeholder="Enter profile link"
            name="facebook"
            aria-label="Facebook"
            disabled={profileUpdatesDisabled()}
          />
        </div>

        <label>Instagram URL</label>
        <div className="social-media">
          <div className="social-media-icon">
            <IG />
          </div>
          <input
            type="text"
            onChange={onChange}
            value={(userData && userData.instagram) || ""}
            placeholder="Enter profile link"
            name="instagram"
            aria-label="Instagram"
            disabled={profileUpdatesDisabled()}
          />
        </div>

        <label>Twitter URL</label>
        <div className="social-media">
          <div className="social-media-icon">
            <Twitter />
          </div>
          <input
            type="text"
            onChange={onChange}
            value={(userData && userData.twitter) || ""}
            placeholder="Enter profile link"
            name="twitter"
            aria-label="Twitter"
            disabled={profileUpdatesDisabled()}
          />
        </div>
      </div>

      <div className="profile-action-save">
        <button
          className='btn-disard'
          onClick={() => handleDiscard()}>
          Discard
        </button>

        <button
          className={`${profileUpdatesDisabled() ? 'disabled' : 'btn-save'}`}
          onClick={handleForm} disabled={profileUpdatesDisabled()}
        >
          Save Changes
        </button>

      </div>


    </>
  );
};

export default UserProfileDetailsEdit 