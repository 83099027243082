import React, { useEffect, useState } from "react";
import Collapse from "rc-collapse";
import "rc-collapse/assets/index.css";
import { ISidebarComponentProps } from "./ITopBarComponentsProps";
import { Map } from "immutable";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import "./SidebarComponent.scss";
import "./sidebar-responsive.scss";
import { PlusWithCircle, SearchIcon } from "../../constants/icons";
import { ReactComponent as CloseIcon } from "assets/img/close.svg";
// import InviteMembersIcon from './newIcons/AddMembers';
import HomeIcon from './newIcons/Home';
// import FeedIcon from './newIcons/Feed';
import LibraryIcon from './newIcons/Library';
import CourseIcon from './newIcons/Course';
import CalenderIcon from './newIcons/Calender';
// import MessageIcon from './newIcons/Message';
import PeopleIcon from './newIcons/People';
import { push } from "connected-react-router";
import miscUtils from "../../utils/miscUtils";
import { Link } from "react-router-dom";
import { globalActions } from "../../store/actions";
import "../../assets/styles/abstracts/_colors.scss";
import { GroupService } from "api/services/groups";
import ShareGroup from "components/shareGroup";
import store from "../../store/configureStore";
import { toast, ToastType } from "react-toastify";
import AppModal from "elements/modal/appModal";
import { ChannelVisibility } from "containers/channels/channelDetails/domain";
import { Emoji } from "emoji-mart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { getChannelCollections, getUnAssignedChannels } from "./domain";
import { ChannelService } from "api/services/channels";
import ChannelHashTag from "./newIcons/ChannelHashTag";
import { useHistory } from 'react-router';
import { getlocalStorageValue } from "utils/localStorage";
import InvitePlusIcon from "./newIcons/InvitePlusIcon";
import ReactModal from "react-modal";

const SidebarComponent: React.FC<ISidebarComponentProps> = (props: any) => {
  const history = useHistory();
  const {
    toggleHamburgerMenu,
    subdomain,
    groups: { userGroups, loadSidebarGroup, postData },
    global: { currentView, goToSidebarChannel, refreshSidebar, theme },
    setChannelSidebarStatus,
    group,
    // memberCommunityUnreadCount
  } = props;

  const [isSideBar, setIsSideBar] = useState<any>("");
  const [areGroupsEmpty, checkGroups] = useState<boolean>();
  const [groupChannels, setGroupChannels] = useState<{ data: [] }>({
    data: []
  });
  const [groupChannelsLoading, setGroupChannelsLoading] = useState<boolean>(
    false
  );
  const [selectedChannel, selectChannel] = useState<number>(-1);
  const [selectedGroup, selectGroup] = useState<any>(-1);
  const [, setIsAdmin] = useState<boolean>(false);
  const [postsSearchQuery, setPostsSearchQuery] = useState<string>("");
  const [loaded, setLoaded] = useState<any>(null);
  const [isGroup, setIsGroup] = useState<boolean>(false);
  const [sidebarGroups, setSidebarGroups] = useState<any>();
  const [chosenChannel, setChosenChannel] = useState<any>();
  const [isModalOpen, setToggleModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false) //used for inactive subscription pop up
  const [channelCollections, setChannelCollections] = useState<Array<any>>([]);
  const [unAssignedChannels, setUnAssignedChannels] = useState<Array<any>>([]);
  const [unAssignedChannelsActive, setUnAssignedChannelsActive] = useState<boolean>(true);
  const [shouldShowHomePage, setShouldShowHomePage] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.group &&
      props.group.home_page_config &&
      props.group.home_page_config.status
    ) {
      setShouldShowHomePage(true);
    }
  }, [props.group]);

  useEffect(() => {
    if (groupChannels.data.length > 0) {
      if (channelCollections.length === 0) {
        setChannelCollections(getChannelCollections(groupChannels.data));
      }
      if (unAssignedChannels.length === 0) {
        setUnAssignedChannels(getUnAssignedChannels(groupChannels.data));
      }
    }
  }, [groupChannels]);

  useEffect(() => {
    if (groupChannels.data.length > 0) {
      // setTimeout(() => {
        setChannelCollections(getChannelCollections(groupChannels.data));
        setUnAssignedChannels(getUnAssignedChannels(groupChannels.data));
      // }, 500)
    }
  }, [window.location.pathname, groupChannels, group])

  useEffect(() => {
    setIsSideBar(getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value);
  }, []);

  function lockBackground() {
    if (isModalOpen) {
      document.body.classList.add("stop-scrolling");
    }
  }

  useEffect(() => {
    if (props.location.pathname) {
      unlockBackground();
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (props.global.selectedGroup) {
      setGroupChannels({ ...groupChannels, data: props.global.selectedGroup.channels });

    }
  }, [props.global.selectedGroup]);

  useEffect(() => {
    lockBackground();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  function unlockBackground() {
    document.body.classList.remove("stop-scrolling");
  }

  function checkingGroups() {
    if (userGroups) {
      if (
        Object.keys(userGroups.data).length === 0 &&
        userGroups.data.constructor === Object
      ) {
        setTimeout(() => {
          checkGroups(true);
        }, 1000);
      }
      if (userGroups.data === undefined || userGroups.data.length === 0) {
        setTimeout(() => {
          checkGroups(true);
        }, 1000);
      }
    }
  }

  useEffect(() => {
    if (userGroups) {
      checkingGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroups]);

  useEffect(() => {
    if (
      userGroups &&
      (!sidebarGroups ||
        (sidebarGroups && sidebarGroups.data.length < userGroups.data.length))
    ) {
      setSidebarGroups(userGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroups, JSON.stringify(userGroups)]);

  useEffect(() => {
    if (userGroups) {
      setSidebarGroups(userGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userGroups)]);

  useEffect(() => {
    if (!postsSearchQuery.length) {
      props.setPostsQuery(postsSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsSearchQuery]);

  useEffect(() => {
    if (group) {
      store.store.dispatch(globalActions.setSelectedGroup(group));
    }
    if (props.location.pathname) {
      const groupData: any = miscUtils.getGroupSlugChannel(props.location.pathname);
      setIsGroup(subdomain || groupData.slug.length > 0);
      switchToGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.location.pathname,
    subdomain,
    goToSidebarChannel,
    refreshSidebar,
    group
  ]);

  useEffect(() => {
    checkSelectedGroupPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarGroups, selectedGroup, selectedChannel]);

  useEffect(() => {
    checkPostPage(
      postData && postData.channel_id,
      postData && postData.group.slug
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData, group]);

  const canShowHomePage = () => {
    return (
      (
        props.group &&
        props.group.home_page_config &&
        props.group.home_page_config.status
      ) &&
      window.location.pathname === "/"
    )
  }

  const showCommunityCoverScreen = (): boolean => {
    return ((//closed community
      props.group &&
      props.group.status === "closed" &&
      (
        (props.group.membership_status &&
          props.group.membership_status !== "member") || (!props.uid)
      ) &&
      !miscUtils.isTeamMember(props.group.user_membership)
    ) || ( //open community
        props.group &&
        props.group.status === "open" &&
        (
          (props.group.membership_status &&
            props.group.membership_status !== "member") || (!props.uid)
        ) &&
        props.group.default_channel &&
        !(props.group.default_channel.visibility === ChannelVisibility.PUBLIC && props.group.default_channel.status === "open") &&
        !canShowHomePage() &&
        !miscUtils.isTeamMember(props.group.user_membership)
      ))
  }

  function switchToGroup() {
    if (groupChannelsLoading) {
      return;
    }
    if (group && group.id && !loaded) {
      setGroupChannels({ data: [] });
      setGroupChannelsLoading(true);
      setLoaded("loaded");
      if (props.group && props.group.channels) {
        setGroupChannels({ ...groupChannels, data: props.group.channels });
        selectGroup(group.slug);
        if (chosenChannel) {
          selectChannel(chosenChannel);
        }
        if (props.location.pathname.split("/").includes("channel")) {
          setPostsSearchQuery("");
          const params = props.location.pathname.split("/");
          selectChannel(params.pop());
        } else if (props.location.pathname.split("/").includes("posts")) {
          selectChannel(props.currentChannel);
        } else {
          selectChannel(group.default_channel.slug);
        }
        setGroupChannelsLoading(false);
      } else {
        const channels: any = [];
        channels.push(group.default_channel);
        setGroupChannels({ data: channels });
        selectGroup(group.slug);
        selectChannel(group.default_channel.slug);
        setGroupChannelsLoading(false);
      }

    }
    // validate active channels on load
    const params = props.location.pathname.split("/").pop();
    const INVALID_ACTIVE_CHANEL = selectedChannel !== parseInt(params);
    // force active channel reset on home route
    if (group && props.location.pathname === "/" && !shouldShowHomePage && !showCommunityCoverScreen()) {
      selectChannel(group.default_channel.slug);
    }
    else if (group && INVALID_ACTIVE_CHANEL) { // validate active channels against route chanel id
      selectChannel(params)
    }
  }

  function viewChannel(channelId: number) {
    if (window.innerWidth < 992) {
      toggleHamburgerMenu();
    }

    setChosenChannel(channelId);
    selectChannel(channelId);
    props.goToChannel(selectedGroup, channelId, subdomain);
  }

  function getPeopleLinkStyle() {
    if (theme && theme.custom && theme.custom.primaryColor && theme.custom.primaryColor !== '#ffffff' && window.location.pathname.split("/").includes("members")) {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  function getEventsLinkStyle() {
    if (theme && theme.custom && theme.custom.primaryColor && theme.custom.primaryColor !== '#ffffff' && window.location.pathname.split("/").includes("events")) {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  function getHomeLinkStyle() {
    if (theme && theme.custom && theme.custom.primaryColor !== '#ffffff' && window.location.pathname === "/" && !showCommunityCoverScreen()) {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  function getLibraryLinkStyles(url: string) {
    if (theme && theme.custom && theme.custom.primaryColor && theme.custom.primaryColor !== '#ffffff' && window.location.pathname.split("/").includes(url)) {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  function checkPostPage(channelId: number, group: string) {
    if (group) {
      selectChannel(channelId);
      setIsGroup(group.length > 0);
      switchToGroup();
      setTimeout(() => setChannelSidebarStatus(true), 2000);
    }
  }

  function checkSelectedGroupPermission() {
    if (userGroups && selectedGroup && sidebarGroups) {
      let isAdminOfSelectedGroup = false;
      sidebarGroups.data.forEach((group: any) => {
        if (
          group.group.slug === selectedGroup &&
          (group.admin || group.owner)
        ) {
          isAdminOfSelectedGroup = true;
        }
      });
      setIsAdmin(isAdminOfSelectedGroup);
    }
  }

  const MyLoader = () => {
    return (
      <ContentLoader
        height={162}
        width={280}
        speed={1}
        primaryColor="#FFF"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="5" ry="5" width="280" height="42" />
        <rect x="0" y="60" rx="4" ry="4" width="280" height="42" />
        <rect x="0" y="120" rx="5" ry="5" width="280" height="42" />
      </ContentLoader>
    );
  };

  function openModal() {
    setToggleModal(true);
  }

  function closeModal() {
    unlockBackground();
    setToggleModal(false);
  }

  function changeGroupInviteLink() {
    if (group) {
      GroupService.getInstance()
        .changeGroupInviteLink(group.id)
        .then(() => { });
    }
  }

  function setChannelToActive(selectedChannel: any, channel: any) {
    return selectedChannel === channel.slug &&
      !window.location.pathname.split("/").includes("library") &&
      !window.location.pathname.split("/").includes("members") &&
      !window.location.pathname.split("/").includes("courses")
      ? "channel-link active"
      : "channel-link";
  }

  const clickOnAdminPanel = () => {
    if (
      props.group &&
      !props.group.active_subscription &&
      miscUtils.isModerator(props.group.user_membership)
    ) {
      setIsOpen(true);
      toast("Please contact an admin", {
        type: ToastType.INFO,
        autoClose: 2000,
      });
      return;
    }
    history.push('/settings/members');
  }

  const getDisplayedUnreadPosts = (count: number) => {
    if (count <= 9) {
      return `${count}`
    }

    return '9+'
  }

  const readChannelPosts = (channelId: any) => {
    ChannelService.getInstance()
      .readChannelPosts(channelId)
      .then(() => { })
      .catch(() => { });
  }

  const clearChannelPostCount = (channelId: any, channelIndex: any, collectionIndex?: any) => {
    if (collectionIndex || collectionIndex === 0) {
      if (channelCollections[collectionIndex].channels[channelIndex].unread_post_count > 0) {
        const channelCollectionsCopy: any = JSON.parse(JSON.stringify(channelCollections));

        channelCollectionsCopy[collectionIndex].channels[channelIndex] = {
          ...channelCollectionsCopy[collectionIndex].channels[channelIndex],
          unread_post_count: 0,
        }

        setChannelCollections([...channelCollectionsCopy]);

        readChannelPosts(channelId);
      }
    } else {
      if (unAssignedChannels[channelIndex] && unAssignedChannels[channelIndex].unread_post_count > 0) {
        const unAssignedChannelsCopy: any = JSON.parse(JSON.stringify(unAssignedChannels));

        unAssignedChannelsCopy[channelIndex] = {
          ...unAssignedChannelsCopy[channelIndex],
          unread_post_count: 0,
        }

        setUnAssignedChannels([...unAssignedChannelsCopy]);

        readChannelPosts(channelId);
      }
    }
  }

  const themeIconColor = theme &&
    theme.custom &&
    theme.custom.primaryColor !== '#ffffff' &&
    theme.custom.primaryColor 

  const shouldShowMenuText = (
    (group && group.menu['courses']['show'] && group.published_courses_count > 0) ||
    (group && group.menu['library']['show'] && group.published_library_contents_count > 0) ||
    (group && group.menu['events']['show'] && group.published_events_count && group.published_events_count > 0) ||
    (group && group.menu['people']['show'] && group.user_membership && group.user_membership.request_status === 'member') ||
    group && group.menu['invite']['show'] ||
    shouldShowHomePage
  )
  return (
    <div
      className={
        (sidebarGroups && sidebarGroups.data.length > 0) || group
          ? "sidebar"
          : "sidebar new-user"
      }
    >
      <div className="sidebar__wrap">
        <div className="sidebar__content">
          {loadSidebarGroup && groupChannelsLoading ? (
            <>
              {isSideBar && !window.location.pathname.split("/").includes("settings") ? (
                <div className="sidebar-content-loader">
                  <MyLoader />
                </div>
              ) : (
                <div className="sidebar-content-loader-closed">
                  <MyLoader />
                </div>
              )}
            </>
          ) : sidebarGroups || group ? (
            <div className="sidebar__content-group-list">
              {isGroup && (
                <div className="sidebar__content-channels-list">
                    <PerfectScrollbar
                      className="sidebar-scrollable-content"
                      options={{
                        wheelPropagation: false,
                      }}
                  >
                      <div className="sidebar__content-channels-list--header">
                        {props.group && props.group.status === "open" ? (
                          <div className="group-name">
                            {(props.group && props.group.name) || currentView}
                          </div>
                        ) : (
                          <>
                            {props.group &&
                              props.group.membership_status === "member" ? (
                              <div className="group-name">
                                {currentView || (props.group && props.group.name)}
                              </div>
                            ) : (
                              <div className="group-name-closed">
                                {currentView || (props.group && props.group.name)}
                              </div>
                            )}
                          </>
                        )}
                        {((props.group &&
                          props.group.user_membership &&
                          props.group.user_membership.owner) ||
                          (props.group &&
                            props.group.user_membership &&
                            (miscUtils.isAdmin(props.group.user_membership) ||
                              miscUtils.isModerator(props.group.user_membership)
                            ))) && (
                            <button
                              className="admin-link"
                              onClick={clickOnAdminPanel}
                            >
                            Admin Panel
                            </button>
                          )}
                        {props.group && props.group.status === "open" && (
                          <div className="sidebar-channel__search-wrap">
                            <input
                              placeholder={"Search"}
                              value={postsSearchQuery}
                              onChange={e => setPostsSearchQuery(e.target.value)}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  props.setPostsQuery(postsSearchQuery);
                                }
                              }}
                              className="channel-search"
                            />
                            <SearchIcon />
                          </div>
                        )}
                        {props.group && props.group.status !== "open" && (
                          <>
                            {props.group &&
                              props.group.membership_status === "member" && (
                                <div className="sidebar-channel__search-wrap">
                                  <input
                                    placeholder={"Search"}
                                    value={postsSearchQuery}
                                    onChange={e =>
                                      setPostsSearchQuery(e.target.value)
                                    }
                                    onKeyDown={e => {
                                      if (e.key === "Enter") {
                                        props.setPostsQuery(postsSearchQuery);
                                      }
                                    }}
                                    className="channel-search"
                                  />
                                  <SearchIcon />
                                </div>
                              )}
                          </>
                        )}
                      </div>

                      <div className="joined-channels">
                        <div className={`sidebar-actions ${props.group && !props.group.menu['invite']['show'] ? 'sidebar-actions-padding-bottom' : ""} ${!shouldShowMenuText ? 'no-borderBottom' : ''}`}>
                          {
                            shouldShowMenuText &&
                          <h1 className="sidebar-actions-menu-text">Menu</h1>
                          }
                          {shouldShowHomePage && (
                            <Link
                              style={getHomeLinkStyle()}
                              className={`action ${(window.location.pathname === "/" && !showCommunityCoverScreen()) && "active-courses"}`}
                              to={
                                subdomain
                                  ? `/`
                                  : `/group/${group && group.slug}/`
                              }
                            >
                              <HomeIcon
                                strokeColor={
                                  themeIconColor !== null && (window.location.pathname === "/" && !showCommunityCoverScreen())
                                    ?
                                    themeIconColor
                                    :
                                    themeIconColor === null && (window.location.pathname === "/" && !showCommunityCoverScreen())
                                      ?
                                      '#767A8A'
                                      :
                                      undefined 
                                }
                              />
                              Home
                            </Link>)}

                          {group && group.menu['courses']['show'] && group.published_courses_count > 0 && (
                            <Link
                              style={getLibraryLinkStyles("courses")}
                              className={`action ${window.location.pathname.split("/").includes(
                                "courses"
                              ) && "active-courses"}`}
                              to={
                                subdomain
                                  ? `/courses`
                                  : `/group/${group && group.slug}/courses`
                              }
                            >
                              <CourseIcon
                                strokeColor={
                                  themeIconColor !== null && window.location.pathname.split("/").includes("courses")
                                    ?
                                    themeIconColor
                                    :
                                    themeIconColor === null && window.location.pathname.split("/").includes("courses")
                                      ?
                                      '#767A8A'
                                      :
                                      undefined
                                }
                              />
                              Courses
                            </Link>)}

                          {group && group.menu['library']['show'] && group.published_library_contents_count > 0 && (
                            <Link
                              style={getLibraryLinkStyles("library")}
                              className={
                                `action 
                              ${window.location.pathname.split("/").includes("library") && "active-courses"}
                              `}
                              to={
                                subdomain
                                  ? `/library`
                                  : `/group/${group && group.slug}/library`
                              }
                            >
                              <LibraryIcon
                                strokeColor={
                                  themeIconColor !== null && window.location.pathname.split("/").includes("library")
                                    ?
                                    themeIconColor
                                    :
                                    themeIconColor === null && window.location.pathname.split("/").includes("library")
                                      ?
                                      '#767A8A'
                                      :
                                      undefined
                                }
                              />
                              Library
                            </Link>)}

                          {group && group.menu['events']['show'] && group.published_events_count && group.published_events_count > 0 ? (
                            <Link
                              style={getEventsLinkStyle()}
                              className={`action ${window.location.pathname.split("/").includes("events") && "active-courses"}`}
                              to={
                                subdomain
                                  ? `/events`
                                  : `/group/${group && group.slug}/events`
                              }
                            >
                              <CalenderIcon
                                strokeColor={
                                  themeIconColor !== null && window.location.pathname.split("/").includes("events")
                                    ?
                                    themeIconColor
                                    :
                                    themeIconColor === null && window.location.pathname.split("/").includes("events")
                                      ?
                                      '#767A8A'
                                      :
                                      undefined
                                }
                              />
                              Events
                            </Link>
                          ) : <></>}

                          {group && group.menu['people']['show'] && group.user_membership && group.user_membership.request_status === 'member' && (
                            <Link
                              style={window.location.pathname === "/members" ? getPeopleLinkStyle() : {}}
                              className={`action ${window.location.pathname === "/members" ? "active-courses" : ''}`}
                              to={
                                subdomain
                                  ? `/members`
                                  : `/group/${group && group.slug}/members`
                              }
                            >
                              <PeopleIcon
                                strokeColor={
                                  themeIconColor !== null && window.location.pathname.split("/").includes("members")
                                    ?
                                    themeIconColor
                                    :
                                    themeIconColor === null && window.location.pathname.split("/").includes("members")
                                      ?
                                      '#767A8A'
                                      :
                                      undefined
                                }
                              />
                              People
                            </Link>
                          )}

                          {group && group.menu['invite']['show'] && (
                            <span
                              onClick={() => openModal()}
                              className="invite-members-action"
                            >
                              <InvitePlusIcon
                                strokeColor={
                                  themeIconColor !== null && isModalOpen
                                    ?
                                    themeIconColor
                                    :
                                    themeIconColor === null && isModalOpen
                                      ?
                                      '#767A8A'
                                      :
                                      undefined
                                }
                              />
                              Invite members
                            </span>)}

                        </div>
                        <div className="joined-channels__scroll-area">
                          {!groupChannelsLoading && (
                            channelCollections.map((collection: any, collectionIndex: any) => (
                              <Collapse
                                accordion={null}
                                className="header-parent"
                                defaultActiveKey={collection.open ? "0" : null}
                                onChange={(key: Array<any>) => setChannelCollections(channelCollections.map((channelCollection: any) => {
                                  if (channelCollection.id === collection.id) {
                                    return {
                                      ...channelCollection,
                                      open: key.length > 0,
                                    }
                                  }

                                  return channelCollection;
                                }))}
                                expandIcon={() => <FontAwesomeIcon icon={collection.open ? faChevronDown : faChevronUp} />}
                              >
                                <Collapse.Panel
                                  header={collection.name.toUpperCase()}
                                  className="header-item"
                                >
                                  <ul>
                                    {collection.channels.map((channel: any, channelIndex: any) => (
                                      <Link
                                        to={`/channel/${channel.slug}`}
                                        onClick={() => {
                                          clearChannelPostCount(channel.id, channelIndex);
                                          viewChannel(channel.slug);
                                        }}
                                      >
                                        <li
                                          key={channelIndex}
                                          onClick={() => {
                                            clearChannelPostCount(channel.id, channelIndex, collectionIndex);
                                            viewChannel(channel.slug);
                                          }
                                          }
                                          style={miscUtils.getChannelLinksColor(channel, selectedChannel, theme)}
                                          className={setChannelToActive(
                                            selectedChannel,
                                            channel
                                          )}
                                        >
                                          <div
                                            title={channel.name}
                                            className="gp-sidebar-channel-container"
                                          >
                                            {/* <p
                                              style={{
                                                color: theme && theme.custom && selectedChannel === channel.slug ? theme.custom.primaryColor : channel.unread_post_count ? '#333' : "",
                                                margin: 0,
                                                padding: 0,
                                                fontWeight: channel.unread_post_count ? 'bold' : 'normal'
                                              }}
                                              className="gp-channel-hashtag"
                                            >#</p> */}
                                            <div
                                            className="gp-channel-emoji-container"
                                            >
                                              {
                                                channel.emoji ?
                                                <span
                                                  className="sidebar-emoji"
                                                  dangerouslySetInnerHTML={{
                                                    //@ts-ignore
                                                    __html: Emoji({
                                                      html: true,
                                                      set: "apple",
                                                      emoji: channel.emoji,
                                                      size: 18
                                                    })
                                                  }}
                                                /> : 
                                                <ChannelHashTag
                                                  fill={
                                                    theme &&
                                                      theme.custom &&
                                                      !window.location.pathname.split("/").includes("library") &&
                                                      !window.location.pathname.split("/").includes("members") &&
                                                      !window.location.pathname.split("/").includes("courses") &&
                                                      selectedChannel === channel.slug ?
                                                      theme.custom.primaryColor :
                                                      channel.unread_post_count ?
                                                        '#000' :
                                                        undefined
                                                  }
                                                />
                                              }
                                            </div>
                                            <p
                                              className={`gp-sidebar-channel-name ${channel.unread_post_count ? 'gp-sidebar-channel-name--badge' : ''}`}
                                            >
                                              {channel.name}
                                            </p>
                                          </div>
                                          {channel.unread_post_count ? (
                                            <div
                                              className={`items-count-container--channel`}
                                              style={{
                                                backgroundColor: (theme && theme.custom) ? theme.custom.primaryColor : "",
                                              }}
                                            >
                                              <p>{getDisplayedUnreadPosts(channel.unread_post_count)}</p>
                                            </div>
                                          ) : (<></>)}
                                        </li>
                                      </Link>
                                    ))}
                                  </ul>
                                </Collapse.Panel>
                              </Collapse>
                            ))
                          )}
                          {!groupChannelsLoading && unAssignedChannels.length > 0 && (
                          <Collapse
                              accordion={null}
                              className="header-parent"
                              defaultActiveKey="0"
                              onChange={(key: Array<any>) => setUnAssignedChannelsActive(key.length > 0)}
                              expandIcon={() => <FontAwesomeIcon icon={unAssignedChannelsActive ? faChevronDown : faChevronUp} />}
                          >
                            <Collapse.Panel
                                header="CHANNELS"
                                className="header-item"
                            >
                              <ul>
                                {unAssignedChannels.map((channel: any, channelIndex: any) => (
                                  <Link
                                    to={`/channel/${channel.slug}`}
                                    onClick={() => {
                                      clearChannelPostCount(channel.id, channelIndex);
                                      viewChannel(channel.slug);
                                    }}
                                  >
                                    <li
                                      key={channelIndex}
                                      onClick={() => {
                                        clearChannelPostCount(channel.id, channelIndex);
                                        viewChannel(channel.slug);
                                      }
                                      }
                                      style={miscUtils.getChannelLinksColor(channel, selectedChannel, theme)}
                                      className={setChannelToActive(
                                        selectedChannel,
                                        channel
                                      )}
                                    >
                                      <div
                                        title={channel.name}
                                        className="gp-sidebar-channel-container"
                                      >
                                        {/* <p
                                          style={{
                                            color: theme && theme.custom && selectedChannel === channel.slug ? theme.custom.primaryColor : "",
                                            margin: 0,
                                            padding: 0,
                                          }}
                                          className="gp-channel-hashtag"
                                        >#</p> */}
                                        <div
                                        className="gp-channel-emoji-container"
                                        >
                                          {
                                            channel.emoji ?
                                            <span
                                              className="sidebar-emoji"
                                              dangerouslySetInnerHTML={{
                                                //@ts-ignore
                                                __html: Emoji({
                                                  html: true,
                                                  set: "apple",
                                                  emoji: channel.emoji,
                                                  size: 18
                                                })
                                              }}
                                            /> : 
                                            <ChannelHashTag
                                              fill={
                                                theme &&
                                                  theme.custom &&
                                                  !window.location.pathname.split("/").includes("library") &&
                                                  !window.location.pathname.split("/").includes("members") &&
                                                  !window.location.pathname.split("/").includes("courses") &&
                                                  selectedChannel === channel.slug ?
                                                  theme.custom.primaryColor :
                                                  channel.unread_post_count ?
                                                    '#000' :
                                                    undefined
                                              }
                                            />
                                          }
                                        </div>
                                        <p
                                          className={`gp-sidebar-channel-name ${channel.unread_post_count ? 'gp-sidebar-channel-name--badge' : ''}`}
                                        >
                                          {channel.name}
                                        </p>
                                      </div>
                                      {channel.unread_post_count ? (
                                        <div
                                          className={`items-count-container--channel`}
                                          style={{
                                            backgroundColor: (theme && theme.custom && theme.custom.primaryColor !== '#ffffff') ? theme.custom.primaryColor : "",
                                          }}
                                        >
                                          <p>{getDisplayedUnreadPosts(channel.unread_post_count)}</p>
                                        </div>
                                      ) : (<></>)}
                                    </li>
                                  </Link>
                                ))}
                              </ul>
                            </Collapse.Panel>
                          </Collapse>
                          )}
                          {groupChannelsLoading && (
                            <ContentLoader
                              height={220}
                              width={200}
                              speed={1}
                              primaryColor="#dbdbdb"
                              secondaryColor="#ecebeb"
                            >
                              <rect
                                x="0"
                                y="0"
                                rx="3"
                                ry="3"
                                width="200"
                                height="20"
                              />
                              <rect
                                x="0"
                                y="35"
                                rx="3"
                                ry="3"
                                width="200"
                                height="34"
                              />
                              <rect
                                x="0"
                                y="100"
                                rx="3"
                                ry="3"
                                width="70"
                                height="16"
                              />
                              <rect
                                x="10"
                                y="130"
                                rx="3"
                                ry="3"
                                width="120"
                                height="16"
                              />
                              <rect
                                x="10"
                                y="160"
                                rx="3"
                                ry="3"
                                width="120"
                                height="16"
                              />
                              <rect
                                x="10"
                                y="190"
                                rx="3"
                                ry="3"
                                width="120"
                                height="16"
                              />
                            </ContentLoader>
                          )}
                        </div>
                      </div>
                  </PerfectScrollbar>
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              {areGroupsEmpty && (
                <button
                  className={"btn btn-create-group"}
                  onClick={() => {
                    props.createGroup();
                  }}
                >
                  <PlusWithCircle />
                  <span>Create community</span>
                </button>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      {isModalOpen && (
        <ReactModal
          isOpen={isModalOpen}
          className='invite-member-details-popup'
          style={{
            content: {
              width: "500px",
              maxWidth: "calc(100vw - 10px)",
              minHeight: "250px",
              borderRadius: "4px",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 99999,
            }
          }}
        >
          <div className="invite--group__header-card">
            <button
              type="button"
              className="close-modal"
              onClick={() => {
                closeModal();
              }}
            >
              <CloseIcon />
            </button>
            <ShareGroup
              theme={theme}
              usersGroups={userGroups}
              groupData={group}
              generateNewLink={true}
              changeLink={changeGroupInviteLink}
              closeModal={closeModal}
            />
          </div>
        </ReactModal>
      )}
      <>
        <AppModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title="Community Subscription Expired"
          message="Please contact an admin to renew the subscription"
          buttonText="OK"
        />
      </>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: ISidebarComponentProps) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const groups = Map(state.get("group", {})).toJS() as any;
  const uid: string = state.getIn(["authorize", "uid"]) as string;
  return {
    uid,
    global,
    groups
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ISidebarComponentProps
) => {
  return {
    createGroup: () => {
      dispatch(push("/creategroup"));
    },
    goToChannel: (
      groupSlug: string,
      channelId: number,
      isSubdomain: boolean
    ) => {
      dispatch(
        push(
          isSubdomain
            ? `/channel/${channelId}`
            : `/channel/${groupSlug}/${channelId}`
        )
      );
    },
    setSidebarVisibility: (status: boolean) =>
      dispatch(globalActions.setSidebarVisibility(status)),
    setPostsQuery: (query: string) =>
      dispatch(globalActions.setPostsQuery(query)),
    setChannelSidebarStatus: (status: boolean) =>
      dispatch(globalActions.setChannelSidebar(status)),
    toggleHamburgerMenu: () => dispatch(globalActions.toggleHamburgerMenu())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarComponent as any) as any
) as any;
