export type SelectedEntity = {entityName: string, id: number | null}

export enum EntityTypes {
    PDF = 'pdf',
    VIDEO = 'video',
    AUDIO = 'audio',
    CHANNEL = 'channel',
    EVENT = 'event',
    SUBSCRIPTION = 'subscription',
    SEGMENT = 'segment',
    EMBED = 'embed',
    COURSE = 'course',
    ARTICLE = 'article',
}