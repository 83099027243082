import React from 'react'

import './signup-disabled.scss'

const PlanDisabled = () => {
    return (
        <div className='signup-closed-container'>
            <span className='signup-closed-container-header'>
                The community plans are disabled.
            </span>

            <span className='signup-closed-container-subtext'>
                Contact your community admin.
            </span>
        </div>
    )
}

export default PlanDisabled