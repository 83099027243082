import { injectable } from "inversify";
// import databaseInstance from "../../index";
import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';

const CryptoJS = require("crypto-js");

@injectable()
export class UploadService {
//   private dbInstance: any = databaseInstance;

  private static instance: UploadService;

  constructor() {
    UploadService.instance = this;
  }

  public static getInstance() {
    if (!UploadService.instance) {
      UploadService.instance = new UploadService();
    }
    return UploadService.instance;
  }

  public getUploadExpirationTime = () => {
    const sixHoursFromNow = new Date(Date.now() + (6 * 60 * 60 * 1000));
    const unixTimestamp = Math.floor(sixHoursFromNow.getTime() / 1000);

    return unixTimestamp;
  }

  public getUploadSignature: (
    bunnyLibraryId: any,
    bunnyLibraryApiKey: any,
    expirationTime: string,
    fileId: string,
  ) => string = (bunnyLibraryId, bunnyLibraryApiKey, expirationTime, fileId) => {
    const message = `${bunnyLibraryId}${bunnyLibraryApiKey}${expirationTime}${fileId}`

    const hash = CryptoJS.SHA256(message);
    return hash;
  }

  public createBunnyVideoObject: (
    bunnyLibraryId: any,
    bunnyLibraryApiKey: any,
    videoTitle: string,
  ) => Promise<void> = (bunnyLibraryId, bunnyLibraryApiKey, videoTitle) => {
    return new Promise<void>((resolve, reject) => {
        const apiEndpoint = `https://video.bunnycdn.com/library/${bunnyLibraryId}/videos`;

        axios.post(apiEndpoint, {
            title: videoTitle,
        }, {
            headers: {
                'AccessKey': bunnyLibraryApiKey,
            }
        })
        .then((response: any) => {
            resolve(response);
        })
        .catch((error: any) => {
            reject(error);
        });
    })
  }
}


