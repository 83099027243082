export enum DraftHandle {
    HANDLED = "handled",
    NOTHANDLED = "not-handled",
}
export const SEARCH_MEMBERS_LIMIT: number = 10;
export enum ENTITY_MUTABILITY {
    IMMUTABLE = 'IMMUTABLE',
    MUTABLE = 'MUTABLE',
    SEGMENTED = 'SEGMENTED',
}
