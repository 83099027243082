import React from 'react';
import './lib-selector.scss';
import Scrollbars from 'react-custom-scrollbars-2';
import { AudienceTypes } from 'containers/AdminMessaging/domain';

const LibraryDropdownSelector = ({ selectedSegment, onSegmentSelected, closeSelectPopUp }: {
  selectedSegment: string;
  onSegmentSelected: any;
  closeSelectPopUp?: Function;
}) => {

  const segments = [
    {
      label: 'PDFs',
      value: AudienceTypes.PDF,
    },
    {
      label: 'Audios',
      value: AudienceTypes.AUDIO,
    },
    {
      label: 'Videos',
      value: AudienceTypes.VIDEO,
    },
    {
      label: 'Embeds',
      value: AudienceTypes.EMBED,
    },
  ]

  return (
    <div className="lib-selector-container">
      <>
        <div className="lib-selector-collections-container">
          <Scrollbars
            className="lib-selector-collections-scrollarea"
          >
            {segments.map((segment: any) => {
              if (segment.value === selectedSegment) {
                return;
              }
              return (
                <div
                  className="lib-selector-segment-container"
                  onClick={() => {
                    if (closeSelectPopUp) {
                      closeSelectPopUp();
                    }
                    onSegmentSelected(segment);
                  }}
                >
                  <p className="lib-selector-segment-name">{segment.label}</p>
                </div>
              )
            })}
          </Scrollbars>
        </div>
      </>
    </div>
  )
}

export default LibraryDropdownSelector;