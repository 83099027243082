import React, { useState, useEffect } from "react";
import "./MessagingContainer.scss";
import "./messaging-container-responsive.scss";
import { IMessagingContainerProps } from "./IMessagingContainerProps";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import MessagingSidebar from "./MessagingSidebar/MessagingSidebar";
import Chatroom from "./Chatroom";

import Pusher from "pusher-js";
import Echo from "laravel-echo";
import moment from 'moment-timezone';
import miscUtils from "utils/miscUtils";
import appConfig from "../../appConfig";

import { MessagingService } from "api/services/messaging";
import ReactModal from "react-modal";
import { ReactComponent as CloseIcon } from "assets/img/close.svg";
import { showMessagePopUpAtom, showNewDirectMessageMenuAtom, showNewGroupMessageMenuAtom } from "showMessageMenuAtom";
import { useAtom } from "jotai";
import NewDirectMessage from "./NewDirectMessage";
import NewGroupMessage from "./NewGroupMessage";
import { DirectChatsContext } from "containers/main/MainComponent";

const MessagingContainer: React.FC<IMessagingContainerProps> = props => {
  const { group, history, theme, setMemberCommunityUnreadCount, subdomain } = props;

  const {
    chatroom,
    setChatroom,
    isCreateDirectchat,
    setIsCreateDirectchat,
    isMemberAddGroupChat, setIsMemberAddGroupChat
  } = React.useContext(DirectChatsContext)

  const [groupChats, setGroupChats] = useState<any>([]);
  const [directChats, setDirectChats] = useState<any>([]);
  const [isShowProfiles, setToggleShowProfiles] = useState<boolean>();
  const [isGroupChat, setIsGroupChat] = useState<boolean>();
  const [isViewProfile, setIsViewProfile] = useState<boolean>(false);
  const [isEditGroupchat, setIsEditGroupchat] = useState<boolean>();
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [userTimezone, setUserTimezone] = useState<String>();
  const [
    // isNoChat
    ,
    setIsNoChat] = useState<boolean>();
  const [currentPath, setCurrentPath] = useState<string>();
  const [isShowChatroom, setIsShowChatroom] = useState<boolean>();
  const [isUnauthorizedChatroom, setIsUnauthorizedChatroom] = useState<boolean>();
  const [echo, setEcho] = useState<any>();
  const [onlineMembers, setOnlineMembers] = useState<any>([]);
  const [memberRemoved, setMemberRemoved] = useState<any>("");
  const [chat, setChat] = useState<any>({});
  const [lastChatroom, setLastChatroom] = useState<any>();

  const pusherOptions = {
    key: appConfig.pusher.key,
    cluster: appConfig.pusher.cluster,
    broadcaster: appConfig.pusher.broadcaster,
    forceTLS: appConfig.pusher.forceTLS,
    encrypted: appConfig.pusher.encrypted,
    authEndpoint: appConfig.pusher.authEndpoint,
    auth: appConfig.pusher.auth
  };

  const membersOnline: any = { data: [] };

  function initEcho() {
    console.log("connect pusher", Pusher);
    return new Echo(pusherOptions);
  }

  useEffect(() => {

    lockBackground();
    const tz = moment.tz.guess();
    setUserTimezone(tz);

    if (!echo) {
      const echoObj = initEcho();
      setEcho(echoObj);
    }
    isMobileView() && localStorage.getItem("isShowChatroom") ? setIsShowChatroom(true) : setIsShowChatroom(false);
  }, [])

  useEffect(() => {
    if (echo && group) {
      listenForOnlineOffline();
    }
  }, [echo, group])

  useEffect(() => {
    setCurrentPath(history.location.pathname);
  }, [history.location.pathname])

  function listenForOnlineOffline() {
    if (echo) {
      echo.join('letschat_' + group.id)
        .here((members: any) => {
          membersOnline.data = members;
          setOnlineMembers(membersOnline.data);
        })
        .joining((member: any) => {
          if (membersOnline.data.filter((_member: any, index: any) => _member.id === member.id).length < 1) {
            membersOnline.data = [...membersOnline.data, member];
          }
          setOnlineMembers(membersOnline.data);
        })
        .leaving((member: any) => {
          membersOnline.data.forEach((_member: any, index: any) => {
            if (_member.id === member.id) {
              membersOnline.data.splice(index, 1);
            }
          })
          setOnlineMembers(membersOnline.data);
          setMemberRemoved((Math.random() + 1).toString(36).substring(7));
        })
        .listen('UserOnline', (e: any) => {
        })
        .listen('UserOffline', (e: any) => {
        })
    }
  }

  function toggleProfilesView() {
    if (isShowProfiles) {
      setToggleShowProfiles(false);
    } else {
      setToggleShowProfiles(true);
    }
  }

  function lockBackground() {
    document.body.classList.add("stop-scrolling");
  }

  function isChatAdmin(chat: any, user_id: number) {
    if (chat.owner) {
      if (user_id === chat.owner.id) {
        return true;
      } else {
        return false;
      }
    }
  }

  function isMobileView() {
    return window.screen.width < 520;
  }

  const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);
  const [, setShowNewGroupMessageMenu] = useAtom(showNewGroupMessageMenuAtom);
  const [, setShowNewDirectMessageMenu] = useAtom(showNewDirectMessageMenuAtom);

  function goBack() {
    const messageParam = new URLSearchParams(window.location.search);
    const chatRoomID = messageParam.get("chatRoom");
    const messagePopUpOpen = messageParam.get("chatOpen");
    setIsCreateDirectchat(false);
    setShowNewGroupMessageMenu(false)
    setShowNewDirectMessageMenu(false)
    if (!isShowChatroom || !isMobileView()) {
      if (chatRoomID && messagePopUpOpen === "true") {
        const urlWithoutParams = window.location.href.split('?')[0];
        window.history.pushState(null, "", urlWithoutParams)
      }
      setOpenMessagePopUp(false)
      document.body.style.overflow = 'auto';

    } else {
      if (chatRoomID && messagePopUpOpen === "true") {
        const urlWithoutParams = window.location.href.split('?')[0];
        window.history.pushState(null, "", urlWithoutParams)
      }
      setChatroom({})
      setOpenMessagePopUp(false)
      document.body.style.overflow = 'auto';

    }
  }

  function isMemberOnline(member: any, _onlineMembers?: any) {
    if (!_onlineMembers) {
      _onlineMembers = onlineMembers;
    }
    let memberStatus: boolean = false;
    _onlineMembers.forEach((onlineMember: any, index: any) => {
      if (member.community_member.user && member.community_member.user.id === onlineMember.id) {
        memberStatus = true;
      }
    })
    return memberStatus;
  }

  function setActiveChatroom(chatId: any) {
    MessagingService.getInstance()
      .saveActiveChatroom(group && group.id, chatId)
      .then((active_chatroom: any) => {
      })
  }

  const [isLoadingGroupchats, setIsLoadingGroupchats] = useState<boolean>(true);

  function getGroupchats() {
    MessagingService.getInstance()
      .getUserChatrooms(group && group.id, "", 1, "group")
      .then((chatrooms: any) => {
        setGroupChats(chatrooms.data)
        setIsLoadingGroupchats(false);
      })
  }

  const [showNewDirectMessageMenu,] = useAtom(showNewDirectMessageMenuAtom);
  const [showNewGroupMessageMenu,] = useAtom(showNewGroupMessageMenuAtom);

  return (
    <ReactModal
      isOpen={true}
      style={{
        content: {
          width: "98%",
          height: "97%",
          borderRadius: '8px',
          inset: 0,
          position: "fixed",
          border: 0,
          overflow: "hidden",
          padding: 0,
          margin: 'auto',
          backgroundColor: 'white',
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 999,
          padding: 0,
          margin: 0,
        }
      }}
    >
      <div
        className="messages-wrapper"
        id="message-wrapper"
      >
        <a
          className="link__to-group"
          onClick={() => goBack()}
          style={miscUtils.customThemeSet(theme) ? { color: theme.custom.primaryColor } : {}}
        >
          <span className="icon"><CloseIcon /></span>
        </a>
        <div className={`messages-container ${isShowProfiles && (!isGroupChat || isViewProfile) ? "active-profile" : ""} ${isShowProfiles && isGroupChat ? "active-members" : ""} ${isShowChatroom ? "mobile-view" : ""}`}>
          <MessagingSidebar
            group={group}
            groupChats={groupChats}
            setGroupChats={setGroupChats}
            directChats={directChats}
            isChatAdmin={isChatAdmin}
            chatroom={chatroom}
            setChatroom={setChatroom}
            setIsEditGroupchat={setIsEditGroupchat}
            setIsNoChat={setIsNoChat}
            currentPath={currentPath}
            theme={theme}
            setIsShowChatroom={setIsShowChatroom}
            setCurrentPath={setCurrentPath}
            isUnauthorizedChatroom={isUnauthorizedChatroom}
            echo={echo}
            setMemberCommunityUnreadCount={setMemberCommunityUnreadCount}
            setIsLoadingMessages={setIsLoadingMessages}
            setToggleShowProfiles={setToggleShowProfiles}
            chat={chat}
            setChat={setChat}
            lastChatroom={lastChatroom}
            setLastChatroom={setLastChatroom}
            setActiveChatroom={setActiveChatroom}
            setIsCreateDirectChat={setIsCreateDirectchat}
            isLoadingGroupchats={isLoadingGroupchats}
            getGroupchats={getGroupchats}
            isMemberAddGroupChat={isMemberAddGroupChat}
          />
          {!showNewDirectMessageMenu && !showNewGroupMessageMenu &&
            <Chatroom
              group={group}
              toggleProfilesView={toggleProfilesView}
              isShowProfiles={isShowProfiles}
              isGroupChat={isGroupChat}
              setIsGroupChat={setIsGroupChat}
              isChatAdmin={isChatAdmin}
              groupChats={groupChats}
              setGroupChats={setGroupChats}
              chatroom={chatroom}
              setChatroom={setChatroom}
              isViewProfile={isViewProfile}
              setIsViewProfile={setIsViewProfile}
              isLoadingMessages={isLoadingMessages}
              setIsLoadingMessages={setIsLoadingMessages}
              userTimezone={userTimezone}
              directChats={directChats}
              theme={theme}
              setIsCreateDirectchat={setIsCreateDirectchat}
              isCreateDirectchat={isCreateDirectchat}
              setIsUnauthorizedChatroom={setIsUnauthorizedChatroom}
              isUnauthorizedChatroom={isUnauthorizedChatroom}
              echo={echo}
              onlineMembers={onlineMembers}
              isMemberOnline={isMemberOnline}
              setCurrentPath={setCurrentPath}
              memberRemoved={memberRemoved}
            setMemberCommunityUnreadCount={setMemberCommunityUnreadCount}
            subdomain={subdomain}
            setLastChatroom={setLastChatroom}
            setActiveChatroom={setActiveChatroom}
              setChat={setChat}
              setIsEditGroupchat={setIsEditGroupchat}
              setIsShowChatroom={setIsShowChatroom}
              setIsMemberAddGroupChat={setIsMemberAddGroupChat}
            />
          }
          {
            showNewDirectMessageMenu &&
            <NewDirectMessage
              group={group}
              directChats={directChats}
              setDirectChats={setDirectChats}
              setChatroom={setChatroom}
              setIsCreateDirectchat={setIsCreateDirectchat}
              setIsLoadingMessages={setIsLoadingMessages}
            />
          }
          {
            showNewGroupMessageMenu &&
            <NewGroupMessage
              group={group}
              groupChats={groupChats}
              setGroupChats={setGroupChats}
              isEditGroupchat={isEditGroupchat}
              setIsEditGroupchat={setIsEditGroupchat}
              chatroom={chatroom}
              theme={theme}
              setIsLoadingMessages={setIsLoadingMessages}
              chat={chat}
              setChatroom={setChatroom}
              getGroupchats={getGroupchats}
              directChats={directChats}
            />
          }
        </div>
      </div>
    </ReactModal>
  );
};

export default withRouter(
  connect(null, null)(MessagingContainer as any) as any
) as any;
