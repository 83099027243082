import React from 'react'

import './member-subscription.scss'
import { ReactComponent as SubIcon } from 'assets/img/subscription.svg'
import moment from 'moment'
import miscUtils from 'utils/miscUtils'
import { AdminMemberDetailsContext } from '../AdminMemberDetails'
import ContentLoader from 'react-content-loader'

const SubscriptionMemberDetails = ({ userMembership }: { userMembership: any }) => {

    const { isLoading, settingsPage } = React.useContext(AdminMemberDetailsContext)

    const formatBillingInterval = (sub: any) => {
        if (sub.interval === "one_time") {
            return "One time"
        }

        if (sub.interval === "month") {
            switch (sub.interval_count) {
                case 1:
                    return "Monthly";
                case 3:
                    return "Every 3 months";
                case 6:
                    return "Every 6 months";
                default:
                    return ""
            }
        }

        if (sub.interval === "year") {
            return "Yearly";
        }

        return "";
    }

    return (
        <div className={`member-sub-container ${settingsPage ? 'community-styles' : ''}`}>
            <div className="header">
                <SubIcon />
                <h1>Subscription</h1>
            </div>
            {isLoading ?
                <>
                    {
                        Array(7).fill('').map((_e, i) => (
                            <div
                                style={
                                    {
                                        width: '100%',
                                    }
                                }
                            >
                                <ContentLoader
                                    height={window.innerWidth <= 500 ? 90 : 20}
                                    width={120}
                                    speed={2}
                                    primaryColor="#d9d9d9"
                                    secondaryColor="#ecebeb"
                                    viewBox="0 0 120 20"
                                >
                                    <rect x="0" y="0" rx="2" ry="2" width={60} height={4} />
                                    <rect x="0" y="8" rx="2" ry="2" width={90} height={4} />
                                </ContentLoader>
                            </div>
                        ))}
                </>
                :
                <>
                    {userMembership && userMembership.temp_plan !== null && userMembership.active_subscription === null ?
                        <div className='body-section'>
                            <div>
                                <span>Plan name</span>
                                <div>
                                    {userMembership.temp_plan.name ?
                                        <h4>{userMembership.temp_plan.name}</h4>
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                            </div>
                            <div>
                                <span>Plan type</span>
                                <div>
                                    {userMembership.temp_plan.type ?
                                        <h4>{userMembership.temp_plan.type.replace(/_/g, " ")}</h4>
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                            </div>
                            <div>
                                <span>Amount</span>
                                {userMembership.temp_plan.price ?
                                    <h4>
                                        {miscUtils.getCurrencySymbol(userMembership.temp_plan.currency)}
                                        {userMembership.temp_plan.price} {" "}
                                        {miscUtils.getResourceCurrency(userMembership.temp_plan.currency, userMembership.group && userMembership.group.currency)}
                                    </h4>
                                    :
                                    <h4> - </h4>
                                }
                            </div>
                            <div>
                                <span>Status</span>
                                <h4> Pending </h4>
                            </div>
                            <div>
                                <span>Started</span>
                                <h4>
                                    {moment.utc(userMembership.temp_plan.created_at).local().format('MMM D, YYYY')}
                                </h4>
                            </div>
                            <div>
                                <span>Billing cycle</span>
                                <div>
                                    {userMembership.temp_plan.interval
                                        ?
                                        // <h4>{userMembership.temp_plan.interval.replace(/_/g, " ")}</h4>
                                        <h4>{formatBillingInterval(userMembership.temp_plan)}</h4>
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                            </div>
                            <div>
                                <span>Next invoice</span>
                                <h4> - </h4>
                            </div>
                        </div>
                        :
                        <div className='body-section'>
                            <div>
                                <span>Plan name</span>
                                {
                                    userMembership.role_id === 4 || userMembership.role_id === 3 ?
                                        <>
                                            {userMembership.active_subscription ?
                                                <h4>
                                                    {userMembership.active_subscription.plan.name}
                                                </h4>
                                                :
                                                <div>
                                                    {
                                                        userMembership.previous_plan ?
                                                            <h4>{userMembership.previous_plan.name}</h4>
                                                            :
                                                            <h4> - </h4>
                                                    }

                                                </div>
                                            }
                                        </>
                                        :
                                        <h4> - </h4>
                                }
                            </div>
                            <div>
                                <span>Plan type</span>
                                {
                                    userMembership.role_id === 4 || userMembership.role_id === 3 ?
                                        <>
                                            {
                                                userMembership.active_subscription ?
                                                    <div>
                                                        {
                                                            userMembership.active_subscription.plan.type
                                                                ?
                                                                <h4>{userMembership.active_subscription.plan.type.replace(/_/g, " ")}</h4>
                                                                :
                                                                <h4> - </h4>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        {
                                                            userMembership.previous_plan && userMembership.previous_plan.type ?
                                                                <h4>{userMembership.previous_plan.type}</h4>
                                                                :
                                                                <h4> - </h4>
                                                        }

                                                    </div>
                                            }
                                        </>
                                        :
                                        <h4> - </h4>
                                }
                            </div>
                            <div>
                                <span>Amount</span>
                                {
                                    userMembership.role_id === 4 || userMembership.role_id === 3 ?
                                        <>
                                            {
                                                userMembership.active_subscription ?
                                                    <h4>
                                                        {miscUtils.getCurrencySymbol(userMembership.active_subscription.plan.currency)}
                                                        {userMembership.active_subscription.plan.price} {" "}
                                                        {miscUtils.getResourceCurrency(userMembership.active_subscription.plan.currency, userMembership.group && userMembership.group.currency)}
                                                    </h4>
                                                    :
                                                    <div>
                                                        {
                                                            userMembership.previous_plan ?
                                                                <h4>
                                                                    {miscUtils.getCurrencySymbol(userMembership.previous_plan.currency)}
                                                                    {userMembership.previous_plan.price} {" "}
                                                                    {miscUtils.getResourceCurrency(userMembership.previous_plan.currency, userMembership.group && userMembership.group.currency)}
                                                                </h4>
                                                                :
                                                                <h4> - </h4>
                                                        }

                                                    </div>
                                            }
                                        </>
                                        :
                                        <h4> - </h4>
                                }
                            </div>
                            <div>
                                <span>Status</span>
                                {
                                    userMembership.role_id === 4 || userMembership.role_id === 3 ?
                                        <>
                                            {
                                                userMembership.blocked ?
                                                    <h4>
                                                        Blocked
                                                    </h4>
                                                    :
                                                    <>
                                                        {
                                                            userMembership.subscription_status ?
                                                                <h4>{userMembership.subscription_status.replace(/_/g, " ")}</h4>
                                                                :
                                                                <h4> - </h4>
                                                        }
                                                    </>
                                            }
                                        </>
                                        :
                                        <h4> - </h4>
                                }
                            </div>
                            <div>
                                <span>Started</span>
                                {
                                    userMembership.role_id === 4 || userMembership.role_id === 3 ?
                                        <>
                                            {
                                                userMembership.active_subscription_start_date ?
                                                    <h4>
                                                        {moment.utc(userMembership.active_subscription_start_date).local().format('MMM D, YYYY')}
                                                    </h4>
                                                    :
                                                    <h4> - </h4>
                                            }
                                        </>
                                        :
                                        <h4> - </h4>
                                }
                            </div>
                            <div>
                                <span>Billing cycle</span>
                                {
                                    userMembership.role_id === 4 || userMembership.role_id === 3 ?
                                        <>
                                            {
                                                userMembership.active_subscription ?
                                                    <div>
                                                        {
                                                            userMembership.active_subscription.plan.interval
                                                                ?
                                                                // <h4>{userMembership.active_subscription.plan.interval.replace(/_/g, " ")}</h4>
                                                                <h4>{formatBillingInterval(userMembership.active_subscription.plan)}</h4>
                                                                :
                                                                <h4> - </h4>
                                                        }
                                                    </div>
                                                    :
                                                    <h4> - </h4>
                                            }
                                        </>
                                        :
                                        <h4> - </h4>
                                }
                            </div>
                            {
                                userMembership.subscription_status.includes('on grace') &&
                                    (userMembership.role_id === 4 || userMembership.role_id === 3)
                                    ?
                                    <div>
                                        <span>Cancels on</span>

                                        <h4>
                                            {moment.utc(userMembership.active_subscription.ends_on).local().format('MMM D, YYYY')}
                                        </h4>

                                    </div>
                                    :
                                    <div>
                                        <span>Next invoice</span>
                                        {
                                            (userMembership.role_id === 4 || userMembership.role_id === 3) ?
                                                <>
                                                    {
                                                        userMembership.active_subscription &&
                                                            userMembership.active_subscription_end_date &&
                                                            userMembership.active_subscription.plan &&
                                                            !userMembership.active_subscription.plan.type.includes('one_time')
                                                            ?
                                                            <h4>
                                                                {moment.utc(userMembership.active_subscription_end_date).local().format('MMM D, YYYY')}
                                                            </h4>
                                                            :
                                                            <h4> - </h4>
                                                    }
                                                </>
                                                :
                                                <h4> - </h4>
                                        }
                                    </div>
                            }
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default SubscriptionMemberDetails