import React, { useEffect, useRef, useState } from "react";
import { IGroupPostComponentProps } from "./IGroupPostComponentProps";
import UserComponent from "../../userComponent";
import useComponentVisible from "../../../utils/visible/visible";
import "./GroupPost.scss";
import { CloseIcon } from "../../../constants/icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCommentSlash, faHeart, faLock } from "@fortawesome/free-solid-svg-icons";
import { GroupService } from "../../../api/services/groups";
import PostComment from "../../commentInput";
import CommentComponent from "../../commentComponent";
import { Element } from "react-scroll";
import anchorme from "anchorme";
import LinkPreview from "../../linkPreview";
import { toast, ToastType } from "react-toastify";
import { ReactComponent as Pinned } from "./flag.svg";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Map } from "immutable";
import Modal from "elements/modal/Modal";
import CopyToClipboard from "react-copy-to-clipboard";
import { NotificationService } from "api/services/notification";
import { ChannelService } from "api/services/channels";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import WistiaPlayer from "components/WistiaPlayer";
import CloudflarePlayer from "../../CloudflarePlayer";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { v4 as uuidv4 } from "uuid";
import miscUtils from "../../../utils/miscUtils";
import ModerationService from "api/services/moderation";
import { PostService } from "api/services/post";
import PostableEditor from "components/post/PostableEditor";
import 'draft-js/dist/Draft.css';
import DraftContent from "./DraftContent";
import { ReactComponent as DownloadIcon } from './download.svg';
import ReactModal from 'react-modal';
import { ReactComponent as LoveIcon } from 'components/commentComponent/icons/love.svg';
import { ReactComponent as LoveIconFill } from 'components/commentComponent/icons/love-fill.svg';
import { ReactComponent as CommentIcon } from 'components/commentComponent/icons/comment.svg';
import { ReactComponent as ShareIcon } from './icons/share.svg';
import Reacters from "./Reacters";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import GroupPostListView from "./GroupPostListView";
import { ChannelLayouts } from "containers/channels/channelDetails/domain";
import GroupPostCardView from "./GroupPostCardView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InnerHTML from 'dangerously-set-html-content';
import { PostableTypes } from "containers/CommunityHomePage/Community/domain";
import ReactPhotoCollageContainer from "./PhotoCollageContainer";
import { ReactComponent as SpinIcon } from "assets/img/Spin-1s-18px.svg";
import BunnyVideoPlayer from "components/BunnyVideoPlayer";
import ExpandedInlineImage from "components/ExpandedInlineImage/ExpandedInlineImage";
import { useAtom } from "jotai";
import { FeaturedPostAddedAtom, scrollToCommentAtom } from "showMessageMenuAtom";
import ConfirmModal from "containers/messaging/ConfirmModal";
import { setlocalStorageValue } from "utils/localStorage";
import { useHistory } from 'react-router';
import { ReactComponent as RedirectIcon } from "assets/img/redirect.svg";
import FlexyPopOnMe from "elements/flexypoponme";

const GroupPostComponent: React.FC<IGroupPostComponentProps> = props => {

  const opts = {
    playerVars: {
      rel: 0,
      autoplay: 0,
      cc_load_policy: 0,
      iv_load_policy: 3,
      modestbranding: 1
    }
  };
  const {
    admin,
    user,
    post,
    group,
    disableBroadcast,
    onPostRequest,
    view,
    canInteract,
    theme,
    pinnedpost,
    moderationView,
    refreshGroupPostsWithoutLoad,
    groups: { userGroups },
    pinnable = true
    // postableType,
  } = props;

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const [isAdmin, getAdmin] = useState<boolean>(false);
  const [isAlowed, setAlowed] = useState<boolean>(false);
  const [isModalOpen, setToggleModal] = useState<boolean>();
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const [commentsCount, setCommentsCount] = useState<any>(0);
  const [firstLevelCommentsCount, setFirstLevelCommentsCount] = useState<number>(0);
  const [canGetComments, setCanGetComments] = useState<boolean>(() => !!props.singlePostView);
  const [videoType, setVideoType] = useState<string>("");
  const [videoId, setVideoId] = useState<string>("");
  const [canBroadcast, setCanBroadcast] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [ableToInteract, setAbleToInteract] = useState(false);
  const [editBody, setEditBody] = useState({
    title: post.title,
    description: post.description
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editorFullScreen, setEditorFullScreen] = useState<boolean>(() => {
    return document.documentElement.clientWidth <= 550 //default to true on mobile
  });

  const [screenWidth, setScreenWidth] = useState<number>(document.documentElement.clientWidth);
  const [reactersData, setReactersData] = useState<any>(null);
  const [loadingReacters, setLoadingReacters] = useState<boolean>(false);
  const [isReactersModalOpen, setIsReactersModalOpen] = useState<boolean>(false);
  const [comments, setComments] = useState<{ data: any[]; total: 0 }>({
    data: [],
    total: 0
  });
  const [postReactionLikeState, setPostReactionLikeState] = useState<any>(() => {
    if (props.post) {
      return ({
        liked: props.post.liked,
        reaction_like_count: props.post.reaction_like_count,
      });
    }

    return ({
      liked: false,
      reaction_like_count: 0,
    })
  });
  const [showCommentSection, setShowCommentSection] = useState<boolean>(!!props.singlePostView);
  const [postData, setPostData] = useState<any>(post);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [isFlexyOpen, setIsFlexyOpen] = useState<boolean>(true)

  useEffect(() => {
    if(
      props.post &&
      props.post.comment
    ) {
      setComments({
        ...comments,
        data: [props.post.comment],
      })
    }
  }, [props.post]);

  useEffect(() => {
    if (screenWidth <= 550 && !editorFullScreen) {
      setEditorFullScreen(true);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (window.location.pathname === '/' || post.postable_type === 'articles') {
      setPostData(post)
    }
  }, [post, window.location.pathname]);

  useEffect(() => {
    if (canInteract) {
      setAbleToInteract(canInteract);
    }
    if (!canInteract) {
      if (
        group.user_membership &&
        (((post.postable_type === "App\\Channel" || post.postable_type === "channels") &&
          post.postable &&
          post.postable.membership_status === "member") ||
          miscUtils.isAdmin(group.user_membership))
      ) {
        setAbleToInteract(true);
      }
    }
    if (group.user_membership) {
      if (
        miscUtils.isAdmin(group.user_membership) ||
        miscUtils.isOwner(group.user_membership) ||
        miscUtils.isModerator(group.user_membership)
      ) {
        getAdmin(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canInteract, props.post]);

  useEffect(() => {
    if (
      (!disableBroadcast &&
        group.user_membership &&
        miscUtils.isAdmin(group.user_membership) &&
        group.user_membership.user &&
        props.user &&
        group.user_membership.user.id === props.user.id) ||
      (admin && !disableBroadcast)
    ) {
      setCanBroadcast(true);
    }
  }, [disableBroadcast]);

  useEffect(() => {
    if (post && post.media_url && post.type === "video") {
      if (post.media_url.includes("you")) {
        setVideoType("youtube");
        let urlParse;
        const videoURL = post.media_url.split("/");
        videoURL.map((part: any, index: any) => {
          if (part.includes("watch")) {
            urlParse = part.split("=")[1];
            setVideoId(urlParse);
          } else {
            if (index === 3) {
              setVideoId(part);
            }
          }
          return null;
        });
      }

      if (post.media_url.includes("vimeo")) {
        setVideoType("vimeo");
        setVideoId(post.media_url);
      }
      if (post.media_url.includes("wistia")) {
        setVideoType("wistia");
        const videoURL = post.media_url.split("/");
        setVideoId(videoURL[4]);
      }

      if (post.stream_id) {
        setVideoType("cloudflare");
        setVideoId(post.stream_id);
      }

      if (post.media_url.includes("bunnycdn")) {
        setVideoType("bunny");
      }
    }
  }, [post]);
  // const [retryKey, setRetryKey] = useState(0);

  // const handleError = () => {
  //   setTimeout(() => {
  //     setRetryKey((prevKey) => prevKey + 1);
  //   }, 5000);
  // };

  useEffect(() => {
    if (post && post.all_comments_count) {
      setCommentsCount(post.all_comments_count);
    }

    if (post && post.comments_count) {
      setFirstLevelCommentsCount(post.comments_count);
    }

    library.add(faHeart);
    library.add(faCommentSlash);

    const resizeCallback = () => {
      setScreenWidth(document.documentElement.clientWidth);
    };
    window.addEventListener("resize", resizeCallback);

    return () => {
      window.removeEventListener("resize", resizeCallback);
    };
  }, []);

  const commentInput = useRef(null);


  useEffect(() => {
    if (post && !moderationView && canGetComments && comments.data.length === 0 && !props.disableReactions) {
      refreshComments(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canGetComments]);

  const onChange = (e: any) => {
    setEditBody({ ...editBody, [e.target.name]: e.target.value });
  };

  function deletePost() {
    GroupService.getInstance()
      .deleteGroupPost(post.id)
      .then(() => {
        if (props.onPostDeleted) {
          props.onPostDeleted(post.id);
        } else if (onPostRequest) {
          onPostRequest();
        }
        if (props.location.pathname.includes("posts")) {
          props.history.push("/");
        }
      });
  }

  function editPost() {
    GroupService.getInstance()
      .editGroupPost(post.id, editBody)
      .then(() => {
        setEditBody({ title: "", description: "" });
        refreshGroupPostsWithoutLoad();
      });
  }

  function broadcastPost() {
    GroupService.getInstance()
      .broadcastPost(post.id)
      .then(() => {
        toast("Post successfully broadcasted", {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
      });
  }

  const [loadingComments, setLoadingComments] = useState<boolean>(false);

  // used for comment linking
  const urlParams = new URLSearchParams(window.location.search);
  const _commentId: any = urlParams.get("commentId");

  function refreshComments(limit?: number, newComment?: any) {
    if (_commentId !== null && limit !== undefined) {
      if (post && post.comment) {
        setComments({
          ...comments,
          data: [post && post.comment],
        });
        return
      }
    }
    setLoadingComments(true)
    GroupService.getInstance()
      .getPostComments(post.id, limit)
      .then((com: any) => {
        if (newComment) {
          setComments({ ...comments, data: [...com.data, newComment] });
        } else {
          setComments({ ...comments, data: com.data });
        }
      })
      .finally(() => {
        setLoadingComments(false)
      });
  }

  function showMoreComments(newLimit: number) {
    refreshComments(newLimit);
  }

  function reactToPost() {
    if (!props.group || (props.group && props.group.membership_status !== "member") && !props.isEnrolled) {
      toast("Only community members can react to this post", {
        type: ToastType.ERROR,
        autoClose: 3000,
      });
      return;
    }

    if (!postReactionLikeState.liked) {
      setPostReactionLikeState({
        liked: true,
        reaction_like_count: postReactionLikeState.reaction_like_count + 1,
      });
      GroupService.getInstance()
        .reactToPost(post.id)
        .then((response: any) => {
        });
    } else {
      setPostReactionLikeState({
        liked: false,
        reaction_like_count: postReactionLikeState.reaction_like_count > 0 ? postReactionLikeState.reaction_like_count - 1 : 0,
      });
      GroupService.getInstance()
        .unreactToPost(post.id)
        .then(() => {
        });
    }
  }

  function coppyToClipboard() {
    // localStorage.setItem("route", `${props.location.pathname}`);
    toast("Post URL copied", {
      type: ToastType.SUCCESS,
      autoClose: 3000
    });
  }

  function joinGroup() {
    if (props.location.pathname.split("/")[1] === "posts") {
      GroupService.getInstance()
        .joinGroup(props.user.id, props.post.group.id)
        .then((response: any) => {
          setToggleModal(false);
          closeModal();
          setAlowed(true);
        });
    } else {
      GroupService.getInstance()
        .joinGroup(props.user.id, props.post.group.id)
        .then((response: any) => {
          props.onGroupRequest();
          setToggleModal(false);
          closeModal();
        });
    }
  }

  function closeModal() {
    unlockBackground();
    setToggleModal(false);
  }

  function closeModalImage() {
    unlockBackground();
    setImagePreview(false);
  }

  function unlockBackground() {
    document.body.classList.remove("stop-scrolling");
  }

  function lockBackground() {
    if (isModalOpen || imagePreview) {
      document.body.classList.add("stop-scrolling");
    }
  }

  useEffect(() => {
    lockBackground();
  });

  useEffect(() => {
    if (isEditMode) {
      document.body.classList.add("stop-scrolling");
    } else {
      document.body.classList.remove("stop-scrolling");
    }
  }, [isEditMode]);

  function updateUserNotifications(userId: any, postId: any) {
    NotificationService.getInstance()
      .updateUserNotifications(userId, postId)
      .then((response: any) => {
        onPostRequest();
        toast(`${response.message}`, {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
      });
  }

  function pinPost(channelId: any, postId: any) {
    ChannelService.getInstance()
      .pinPost(channelId, postId)
      .then((response: any) => {
        if (onPostRequest) {
          onPostRequest();
        }
      });
  }

  function unPinPost(channelId: any, postId: any) {
    ChannelService.getInstance()
      .unPinPost(channelId, postId)
      .then((response: any) => {
        if (onPostRequest) {
          onPostRequest();
        }
      });
  }

  function pinPostFromPostable(contentId: any, postId: any, contentType: string) {
    PostService.getInstance()
      .pinPost(contentId, postId, contentType)
      .then((response) => {
        if (onPostRequest) {
          onPostRequest();
        }
      })
  }

  function unpinPostFromPostable(contentId: any, postId: any, contentType: string) {
    PostService.getInstance()
      .unpinPost(contentId, postId, contentType)
      .then(() => {
        if (onPostRequest) {
          onPostRequest();
        }
      });
  }

  const flagPost = (postId: any) => {
    ModerationService.getInstance().flagPost(props.group.id, postId).then(() => {
      toast("Post flagged!", {
        type: ToastType.SUCCESS,
        autoClose: 2000
      });
    });
  };

  const isPublicView = (): boolean => {
    return view === "public" ||
      (group && group.membership_status === "not a member");
  }

  const getPostReacters = () => {
    setTimeout(() => {
      setIsReactersModalOpen(true);
    }, 100);
    setLoadingReacters(true);
    PostService.getInstance()
      .getReactersToPost(props.group.id, props.post.id)
      .then((response: any) => {
        setReactersData(response.data);
        setLoadingReacters(false);
      }).finally(() => {
        setLoadingReacters(false);
      })
  }

  const loadMoreReacters = () => {
    PostService.getInstance()
      .loadMore(reactersData.next_page_url)
      .then((response: any) => {
        setReactersData(response.data);
      });
  }

  const getSubText = (): string | null => {
    if (
      props.post &&
      props.post.postable &&
      props.post.postable.title
    ) {
      return `Posted in: ${props.post.postable.title}`;
    }

    if (
      props.post &&
      props.post.postable &&
      props.post.postable.name
    ) {
      return `Posted in: ${props.post.postable.name}`;
    }

    return null;
  }

  const getPostableName = () => {
    if (
      props.post &&
      props.post.is_assignment
    ) {
      return 'Assignment';
    }

    if (
      props.post &&
      props.post.postable
    ) {
      if (props.post.postable_type && props.post.postable_type === "coursecontents") {
        return props.post.postable && props.post.postable.course && props.post.postable.course.title;
      }
      return props.post.postable.name || props.post.postable.title;
    }

    return null;
  }

  const likeUnlikeReaction = () => {
    if (moderationView) {
      return;
    }

    if (ableToInteract || props.user) {
      reactToPost();
    } else {
      if (window.location.pathname !== '/') {
        setlocalStorageValue("next_link", window.location.pathname);
      }
      props.publicLogin();
    }
  }

  const getPostListProps = () => {
    const { post, ...postListProps } = props;
    return postListProps
  }

  const updateCommentingStatus = () => {
    if (isComponentVisible) {
      setIsComponentVisible(false);
    }

    PostService.getInstance()
      .updatePost(post.id, {
        type: post.type,
        commenting_closed: !post.commenting_closed
      })
      .then((response: any) => {
        toast(`Post commenting ${!post.commenting_closed ? 'closed' : 'opened'}`, {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        if (post.postable_type === 'articles' || post.postable_type === 'coursecontents') {
          onPostRequest();
        }
        if (props.refreshGroupPostsWithoutLoad) {
          props.refreshGroupPostsWithoutLoad(response.data);
        }
        // update post
        setPostData(response.data)
      })
  }

  const convertPostToArticle = () => {
    if (isComponentVisible) {
      setIsComponentVisible(false);
    }

    PostService.getInstance()
      .convertToArticle(group.id, post.id)
      .then((response: any) => {
        toast(`Post converted to article`, {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        props.history.push(`/settings/library/articles/${response.data.id}/edit`)
        if (props.refreshGroupPostsWithoutLoad) {
          props.refreshGroupPostsWithoutLoad(response.data);
        }
      })
  }

  const hidePostCardBottomPadding = (): boolean => {
    return (
      props.disableReactions ||
      post.commenting_closed ||
      (!user && !showCommentSection) ||
      moderationView ||
      (!ableToInteract && !isAlowed) ||
      !showCommentSection
    )
  }

  const getPostableField = (fieldName: string) => {
    if (postData.postable && postData.postable_type) {
      switch (postData.postable_type) {
        case PostableTypes.CHANNELS:
          return postData.postable[fieldName];
        case PostableTypes.COURSECONTENTS:
          if (postData.postable.course) {
            return postData.postable.course[fieldName];
          }
        default:
          return postData.postable[fieldName];
      }
    }

    return false;
  }

  const memoizedFiles = React.useMemo(() => postData.files, [postData]) || []

  interface Setting {
    width?: string;
    height?: string[];
    layout: number[];
    photos: { source: string }[];
    showNumOfRemainingPhotos: boolean;
  }

  const setting: Setting = {
    photos: memoizedFiles.map((file: any) => (
      {
        source: `${file.url}?${performance.now()}`,
        originalFileName: `${file.original_file_name}`
      })),
    showNumOfRemainingPhotos: true,
    layout: []
  };

  if (memoizedFiles.length === 1) {
    setting.layout = [1];
  } else {
    setting.layout = [1, 3];
  }

  const [, setIsFeaturedPostAdded] = useAtom(FeaturedPostAddedAtom);

  const featureAPost = () => {
    PostService.getInstance()
      .createFeaturePost(group.id, postData.postable_id, post.id)
      .then((response: any) => {
        if (props.postView && props.postView === (ChannelLayouts.List) || props.postView && props.postView === (ChannelLayouts.Card)) {
          onPostRequest()
          toast("Post successfully featured", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
        } else {
          props.refreshGroupPostsWithoutLoad();
          setIsFeaturedPostAdded(true)
          toast("Post successfully featured", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
        }

      }).finally(() => setIsFeaturedPostAdded(false))
  }

  const removefeaturedPost = () => {
    PostService.getInstance()
      .deleteFeaturedPost(group.id, postData.postable_id, post.id)
      .then((response: any) => {
        if (props.postView && props.postView === (ChannelLayouts.List) || props.postView && props.postView === (ChannelLayouts.Card)) {
          onPostRequest()
          setIsFeaturedPostAdded(true)
        }
        else {
          refreshGroupPostsWithoutLoad();
          setIsFeaturedPostAdded(true)
          toast(`${response.message}`, {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
        }
      }).finally(() => setIsFeaturedPostAdded(false))
  }

  const openDeleteModal = () => {
    setDeleteModalOpen(true)
    setIsComponentVisible(false);
    setIsFlexyOpen(false)
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false)
    setIsFlexyOpen(true)
  }

  const showingHomePage = () => {
    return (
      (
        props.group &&
        props.group.home_page_config &&
        props.group.home_page_config.status
      ) &&
      window.location.pathname === "/"
    )
  }

  // comment highlighting
  const [scrollToComment, setScrollToComment] = useAtom(scrollToCommentAtom);
  const qhistory = useHistory();

  useEffect(() => {
    if (_commentId === null) {
      setScrollToComment(undefined)
    }
  }, [window.location.search])


  const handleClearQueryParams = () => {
    const urlWithoutQuery = window.location.pathname;
    qhistory.replace(urlWithoutQuery);
  };

  return props && postData ? (
    <>
      {props.postView && props.postView === ChannelLayouts.List ? (
        <>
          <GroupPostListView
            {...getPostListProps()}
            post={postData}
            theme={theme}
            commentsCount={commentsCount}
            likeUnlikeReaction={likeUnlikeReaction}
            group={group}
            setIsEditMode={setIsEditMode}
            isAdmin={isAdmin}
            deletePost={deletePost}
            canBroadcast={canBroadcast}
            broadcastPost={broadcastPost}
            updateUserNotifications={updateUserNotifications}
            user={user}
            unpinPostFromPostable={unpinPostFromPostable}
            unPinPost={unPinPost}
            pinPostFromPostable={pinPostFromPostable}
            pinPost={pinPost}
            match={props.match}
            flagPost={flagPost}
            pinnedpost={pinnedpost}
            view={view}
            updateCommentingStatus={updateCommentingStatus}
            postReactionLikeState={postReactionLikeState}
            pinnable={pinnable}
            removefeaturedPost={removefeaturedPost}
            featureAPost={featureAPost}
            openDeleteModal={openDeleteModal}
            isFlexyOpen={isFlexyOpen}
            convertPostToArticle={convertPostToArticle}
          />
        </>
      ) : props.postView && props.postView === ChannelLayouts.Card ?
        (
          <>
            <GroupPostCardView
              {...getPostListProps()}
              post={postData}
              theme={theme}
              commentsCount={commentsCount}
              likeUnlikeReaction={likeUnlikeReaction}
              group={group}
              setIsEditMode={setIsEditMode}
              isAdmin={isAdmin}
              deletePost={deletePost}
              canBroadcast={canBroadcast}
              broadcastPost={broadcastPost}
              updateUserNotifications={updateUserNotifications}
              user={user}
              unpinPostFromPostable={unpinPostFromPostable}
              unPinPost={unPinPost}
              pinPostFromPostable={pinPostFromPostable}
              pinPost={pinPost}
              match={props.match}
              flagPost={flagPost}
              pinnedpost={pinnedpost}
              view={view}
              updateCommentingStatus={updateCommentingStatus}
              postReactionLikeState={postReactionLikeState}
              global={props.global}
              pinnable={pinnable}
              removefeaturedPost={removefeaturedPost}
              featureAPost={featureAPost}
              openDeleteModal={openDeleteModal}
              isFlexyOpen={isFlexyOpen}
              convertPostToArticle={convertPostToArticle}
            />
          </>
        ) :
        (
          <>
            <div
              className={`post-item ${props.lectureView ? "post-item-lecture-view" : ""} ${props.moderationView ? "post-item-moderation-view" : ""} ${props.messagingView ? "post-item-messaging-view" : ""} ${isPublicView() ? "post-item--public" : ""}`}
            >
              <div className={`card card-post ${moderationView ? "card-post-moderation" : ""} post-card--wrap ${isPublicView() ? "post-card--public" : ""} ${props.announcementPost ? 'post-card--announcement' : ''} ${postData.cover_image_url ? 'post-card--cover' : ''} ${hidePostCardBottomPadding() ? 'post-card--hide-bottom-padding' : ''}`}>
                {postData.cover_image_url ? (
                  <div
                    className="gp-post-cover-container"
                  >
                    <img
                      className="gp-post-cover-image"
                      src={postData.cover_image_url}
                    />
                  </div>
                ) : (<></>)}
                <div className={`card-header flex flex-align-center flex-justify-space-between ${(props.newsFeedView || window.location.pathname === "/filter" || props.post.is_assignment) ? 'relative' : ''}`}>
                  {(props.newsFeedView || window.location.pathname === "/filter" || props.post.is_assignment) && getPostableName() ? (
                    <Tippy
                      content={getPostableName()}
                    >
                      <div
                        className="postable-container"
                      >
                        <p
                          className="postable-text"
                        >{getPostableName()}</p>
                      </div>
                    </Tippy>
                  ) : <></>}
                  <div className="pin-icon">
                    <div className="post-user">
                      <UserComponent
                        user={props.announcementPost ? {
                          profile_image: props.group && props.group.profile_image,
                          full_name: props.group.name,
                        } : postData.author}
                        timeStamp={props.announcementPost && postData.updated_at ? postData.updated_at : postData.published_at}
                        adminId={admin && admin}
                        roleId={props.announcementPost ? null : postData.author_role_id}
                        postView
                        onClickTimestamp={() => {
                          if (props.messagingView || post.postable_type === 'articles') {
                            return;
                          }

                          return `/posts/${postData.slug}`;
                        }}
                        messagingView={props.messagingView}
                        subText={props.moderationView ? getSubText() : null}
                        extraMarkup={(props.newsFeedView || window.location.pathname === "/filter") && (
                          <div
                            className="postable-responsive-container"
                          >
                            <p
                              className="postable-responsive-text"
                            >{getPostableName()}</p>
                          </div>
                        )}
                        minimizableName
                        group={group}
                        showProfileSlider={(!post.author || props.announcementPost ||
                          !props.group ||
                          (props.group && props.group.membership_status !== "member")) ? false : true}
                        isPlacedonIntendedComp={true}
                        isAnnouncementPost={props.announcementPost}
                      />

                    </div>
                    {(pinnedpost || props.announcementPost) && (
                      <Pinned
                        fill={
                          theme && theme.custom ? theme.custom.primaryColor : "#365DE6"
                        }
                      />
                    )}
                  </div>

                    {props.user && !moderationView && window.location.pathname !== "/filter" && (
                    <div className="dropdown-menu" ref={ref}>

                      <FlexyPopOnMe
                        closeOnComponentClick
                        placement='bottom-end'
                        hideTippyArrow
                        component={

                          <ul className="group-dropdown-post dropdown">
                            <li>
                              {((group && group.user_membership && miscUtils.isAdmin(group.user_membership)) || props.user.id === postData.author.id) && (!props.announcementPost) && !post.is_assignment && (
                                <button
                                  onClick={() => {
                                    if (post.postable_type === 'articles') {
                                      props.history.replace(`/settings/library/articles/${post.postable_id}/edit`);
                                    }
                                    else {
                                      setIsComponentVisible(false);
                                      setIsEditMode(true);
                                    }
                                  }}
                                  className="group-post-dropdown-btn group-post-dropdown-btn--edit-post"
                                >
                                  <p className="main-text">Edit</p>
                                </button>
                              )}
                              {canBroadcast && (
                                <button
                                  onClick={() => broadcastPost()}
                                  className="group-post-dropdown-btn"
                                >
                                  <p className="main-text">Broadcast</p>
                                </button>
                              )}
                              {!props.announcementPost && isAdmin && pinnable && post.postable_type !== 'articles' && (
                                <>
                                  {postData.pinned_at ? (
                                    <button
                                      onClick={() => {
                                        if (
                                          window.location.pathname.includes("lectures") && (window.location.pathname.includes("library") || window.location.pathname.includes("courses"))
                                        ) {
                                          if (props.match.params.contentId) {
                                            unpinPostFromPostable(props.match.params.contentId, postData.id, 'course-content')
                                          }

                                          if (props.match.params.libraryItemId) {
                                            unpinPostFromPostable(postData.postable_id, postData.id, props.match.params.libraryItemType.slice(0, -1))
                                          }
                                        } else if (
                                          window.location.pathname.split("/")[1] === "events"
                                        ) {
                                          unpinPostFromPostable(postData.postable_id, postData.id, 'event')
                                        } else {
                                          unPinPost(postData.postable_id, postData.id);
                                        }
                                      }}
                                      className="group-post-dropdown-btn"
                                    >
                                      <p className="main-text">Unpin post</p>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        if (
                                          window.location.pathname.includes("lectures") && (window.location.pathname.includes("library") || window.location.pathname.includes("courses"))
                                        ) {
                                          if (props.match.params.contentId) {
                                            pinPostFromPostable(props.match.params.contentId, postData.id, 'course-content')
                                          }

                                          if (props.match.params.libraryItemId) {
                                            pinPostFromPostable(postData.postable_id, postData.id, props.match.params.libraryItemType.slice(0, -1))
                                          }
                                        } else if (
                                          window.location.pathname.split("/")[1] === "events"
                                        ) {
                                          pinPostFromPostable(postData.postable_id, post.id, 'event')
                                        } else {
                                          pinPost(postData.postable_id, postData.id);
                                        }
                                      }}
                                      className="group-post-dropdown-btn"
                                    >
                                      <p className="main-text">Pin post</p>
                                    </button>
                                  )}
                                </>
                              )}
                              {/* Feature a post */}
                              {post && (miscUtils.isTeamMember(props.group && props.group.user_membership)) && ((window.location.pathname.split("/")[1] === 'channel') || (window.location.pathname === '/' && !showingHomePage())) ? (
                                <button
                                  className="group-post-dropdown-btn"
                                  onClick={() => post.featured_at === null ? featureAPost() : removefeaturedPost()}
                                >
                                  <p className="main-text">
                                    {post.featured_at === null ? 'Feature this post' : 'Remove featured post'}
                                  </p>
                                </button>
                              ) : (<></>)}

                              {!props.announcementPost && props.user && props.user.id && (
                                <button
                                  onClick={() => updateUserNotifications(user.id, postData.id)}
                                  className="group-post-dropdown-btn"
                                >
                                  <p className="main-text">
                                    {post &&
                                      post.post_settings &&
                                      post.post_settings.email_notification
                                      ? "Turn off post notifications"
                                      : "Turn on post notifications"}
                                  </p>
                                </button>
                              )}

                              {post && (miscUtils.isTeamMember(props.group && props.group.user_membership)) && !props.disableReactions && !props.announcementPost ? (
                                <button
                                  className="group-post-dropdown-btn"
                                  onClick={updateCommentingStatus}
                                >
                                  <p
                                    className="main-text"
                                  >
                                    {post.commenting_closed ? 'Open commenting' : 'Close commenting'}
                                  </p>
                                </button>
                              ) : (<></>)}

                              {/* Convert into an article */}
                              {
                                post &&
                                  post.postable_type !== 'articles' &&
                                  (miscUtils.isTeamMember(props.group && props.group.user_membership)) &&
                                  !props.announcementPost ? (
                                  <button
                                    className="group-post-dropdown-btn"
                                    onClick={convertPostToArticle}
                                  >
                                    <p className="main-text">Convert into an article</p>
                                  </button>
                                ) : (<></>)}

                              {/* members can flag a post */}
                              {(!props.announcementPost) && (props.user.id !== postData.author.id) &&
                                post.postable_type !== 'articles' &&
                                <button
                                  onClick={() => flagPost(postData.id)}
                                  className="group-post-dropdown-btn"
                                >
                                  <p className="main-text">Flag post</p>
                                </button>}
                              {/* end members can flag a post */}

                              {(!props.announcementPost && (isAdmin || (props.user.id === postData.author.id && !post.is_assignment))) &&
                                post.postable_type !== 'articles' && (
                                  <button
                                    onClick={() => openDeleteModal()}
                                    className="group-post-dropdown-btn"
                                  >
                                    <p className="main-text">Delete post</p>
                                  </button>
                                )}
                            </li>
                          </ul>

                        }>
                        <button
                          className="btn btn-dots btn-post-dots"
                          onClick={() => setIsComponentVisible(true)}
                        >
                          <span />
                          <span />
                          <span />
                        </button>

                      </FlexyPopOnMe>
                      {/* {isComponentVisible && (

                      )
                      } */}
                    </div>
                  )}
                </div>
                <div className="card-content-body post-content-area">
                  <div className={`content ${props.announcementPost ? 'content--announcement' : ''}`}>
                    {editMode && postData && postData.type === "text" ? (
                      <div className="edit-inputs">
                        <textarea
                          name="description"
                          placeholder="description"
                          className="edit-post-input"
                          onChange={onChange}
                          defaultValue={postData.description}
                        />
                      </div>
                    ) : editMode && postData && postData.type === "q&a" ? (
                      <div className="edit-inputs">
                        <input
                          type="text"
                          form-datatype='text'
                          name="title"
                          placeholder="title"
                          className="edit-post-input post-title"
                          onChange={onChange}
                          defaultValue={postData.title}
                        />
                        <textarea
                          name="description"
                          placeholder="description"
                          className="edit-post-input"
                          onChange={onChange}
                          defaultValue={postData.description}
                        />
                      </div>
                    ) : editMode && postData && postData.type === "link" ? (
                      <div className="edit-inputs">
                        <textarea
                          name="description"
                          placeholder="description"
                          className="edit-post-input"
                          onChange={onChange}
                          defaultValue={postData.description}
                        />
                      </div>
                    ) : editMode && postData && postData.type === "video" ? (
                      <div className="edit-inputs">
                        <textarea
                          name="description"
                          placeholder="description"
                          className="edit-post-input"
                          onChange={onChange}
                          defaultValue={postData.description}
                        />
                      </div>
                    ) : (
                      <>
                        {postData && postData.is_assignment && postData.postable && props.newsFeedView ? (
                          <div
                            className="gp-post-postable-preview-container"
                          >
                            <div
                              className="gp-post-postable-preview-wrapper"
                            >
                              <p
                                className="gp-post-postable-preview-title"
                              >
                                {postData.postable.title}
                              </p>
                            </div>
                          </div>
                        ) : (<></>)}
                        {postData && postData.title ? (
                          <h1
                            className="gp-post-title"
                          >{postData.title}</h1>
                        ) : (<></>)}
                        {
                          postData && postData.description && postData.description_json && postData.description_json !== "null" &&
                          <div className="post-content-description">
                            <DraftContent
                              post={postData}
                              theme={theme}
                              disableCharacterLimit={props.disableCharacterLimit}
                            />
                          </div>
                        }
                        {
                          postData && postData.description && (!postData.description_json || postData.description_json === "null") &&
                          <div className="post-content-description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: anchorme(postData.description)
                              }}
                            />
                          </div>
                        }
                        {postData && postData.type === "video" && (
                          <>
                            {videoType === "youtube" && (
                              <YouTube
                                // @ts-ignore
                                opts={opts}
                                videoId={videoId}
                                className={"yt-player"}
                                containerClassName={`yt-player-container--group-posts ${props.announcementPost ? 'yt-player-container--group-posts--announcement' : 'yt-player-container--group-posts'} ${post.postable_type === 'articles' ? 'yt-player-container--group-posts--article-video' : ""}`}
                              />
                            )}
                            {videoType === "vimeo" && (
                              <Vimeo video={videoId} className={`vimeo-player--group-posts ${props.announcementPost ? 'announcement' : ''}`} />
                            )}
                            {videoType === "wistia" && (
                              <>
                                <WistiaPlayer
                                  videoId={videoId}
                                />
                                {!props.announcementPost && <div className="group-posts-top-margin" />}
                              </>
                            )}
                            {videoType === "cloudflare" &&
                              postData.stream_status === "ready" && (
                                <>
                                  <CloudflarePlayer
                                    videoId={videoId}
                                    height={382}
                                    thumbnail={post.media_thumbnail}
                                    announcementView={props.announcementPost}
                                  />
                                  {!props.announcementPost && <div className="group-posts-top-margin" />}
                                </>
                              )}
                            {videoType === "bunny" ? (
                              // postData.stream_status === "ready" ? (
                              <>
                                <BunnyVideoPlayer
                                  videoUrl={post.media_url}
                                />
                                {!props.announcementPost && <div className="group-posts-top-margin" />}
                              </>
                              // ) : (<></>)
                            ) : (<></>)}
                          </>
                        )}
                        {postData && postData.type === "audio" && (
                          <>
                            <div className="post-content-description">
                              <div>
                                <AudioPlayer
                                  key={uuidv4()}
                                  style={{ boxShadow: "none" }}
                                  preload="none"
                                  src={postData.media_url}
                                  showJumpControls={false}
                                  customAdditionalControls={[]}
                                  header={postData.original_file_name}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {postData && postData.type === "link" && (
                          <>
                            <div className="post-content-description">
                              {postData.media_url && (
                                <>
                                  <a href={postData.media_url}>{postData.media_url}</a>
                                </>
                              )}
                            </div>
                            {postData.meta_data && (
                              <LinkPreview
                                url={postData.meta_data.url}
                                metaData={postData.meta_data}
                                announcementView={props.announcementPost}
                              />
                            )}
                          </>
                        )}
                        {postData && postData.type === "text" && !editMode && (
                          <>
                            {memoizedFiles !== null && memoizedFiles.length > 0 && memoizedFiles.length === 1 &&
                              <div className="image-wrapper">
                                <img
                                            src={memoizedFiles[0].url}
                                  alt="post_image"
                                  className={`post-image ${props.announcementPost ? 'post-image--announcement' : ''}`}
                                  onClick={() => {
                                    setImagePreview(true);
                                  }}
                                            // onError={handleError}
                                            // key={retryKey}
                                />
                                <ExpandedInlineImage
                                  imageSrc={memoizedFiles[0].url}
                                  showComponent={imagePreview}
                                  setShowComponent={closeModalImage}
                                  isCommentComponent={false}
                                  originalFileName={memoizedFiles[0].original_file_name}
                                />
                              </div>
                            }
                            {memoizedFiles !== null && memoizedFiles.length > 0 && memoizedFiles.length > 1 &&
                              <div className="image-wrapper">
                                <ReactPhotoCollageContainer {...setting} memoizedFiles={memoizedFiles} />
                              </div>
                            }
                          </>
                        )}
                        {postData && postData.type === "q&a" && (
                          <>{postData.files && postData.files.length > 0 &&
                            postData.files.map((file: any) => (
                              <div className="image-wrapper">
                                <img
                                  src={file.url}
                                  alt="post_imaage"
                                  className="post-image"
                                />
                              </div>
                            ))}
                          </>
                        )}
                        {
                          postData && postData.type === "attachment" && (
                            <>
                              {postData.files && postData.files.length > 0 &&
                                postData.files.map((file: any) => (
                                  <div className="post-content-description">
                                    <div className="post-content-attachment-container">
                                      <a
                                        className="post-content-attachment-download-link"
                                        href={file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          color: theme && theme.custom && theme.custom.primaryColor ? theme.custom.primaryColor : '#888'
                                        }}
                                      >
                                        <DownloadIcon />
                                      </a>
                                      <div className="post-content-attachment-details-container">
                                        <a
                                          href={file.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p className="post-content-attachment-details-name">{file.original_file_name}</p>
                                        </a>
                                        <p className="post-content-attachment-details-size">{(file.file_size / 1000000).toFixed(2)} MB</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </>
                          )}
                        {postData && postData.type === "embed" ? (
                          <div className="gp-post-embed-attachment-wrapper">
                            <InnerHTML html={postData.media_url} />
                          </div>
                        ) : (<></>)}
                      </>
                    )}
                  </div>
                </div>
                {!moderationView && !props.announcementPost && !props.disableReactions && (
                  <div className="post-card-stats-container top-like-stat">
                    <p
                      className="post-card-stats-like-count"
                      onClick={() => {
                        if (view === "public") {
                          props.publicLogin();
                          return;
                        }

                        getPostReacters();
                      }}
                    >{postReactionLikeState.reaction_like_count} Like{postReactionLikeState.reaction_like_count === 1 ? "" : "s"}</p>


                    {/* <p
                      className="post-card-stats-comment-count"
                      onClick={() => {
                        if (!canGetComments && commentsCount > 0) {
                          setCanGetComments(true);
                        }

                        setShowCommentSection(!showCommentSection);
                      }}
                    >{commentsCount} Comment{commentsCount === 1 ? "" : "s"}</p> */}
                  </div>
                )}
                {!props.messagingView && !props.announcementPost && (
                  <div
                    className={
                      `card-content-action flex flex-align-center 
                        ${!moderationView ? 'card-content-action--no-margin' : ''} 
                        post-card-post-actions 
                        ${hidePostCardBottomPadding() ? 'card-content-action--no-border-bottom' : ''}
                        ${(!user && showCommentSection && !loadingComments && comments.data.length > 0) ? 'card-content-action--logged-out-view' : ''}`
                    }
                  >
                    {editMode ? (
                      <div className="flex">
                        <button
                          className="edit-btn flex btn btn-primary"
                          onClick={() => {
                            editPost();
                            setEditMode(false);
                          }}
                        >
                          Save edited post
                        </button>
                        <button
                          className="edit-btn flex btn btn-primary"
                          onClick={() => {
                            setEditMode(false);
                          }}
                        >
                          Exit edit mode
                        </button>
                      </div>
                    ) : (
                      <>
                        {postData && (
                          <Modal
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                            size={"640px"}
                          >
                            <div>
                              <div className="join-group-main-container">
                                <button
                                  type="button"
                                  className="close-modal"
                                  onClick={() => {
                                    closeModal();
                                  }}
                                >
                                  <CloseIcon />
                                </button>
                                <div className="join-group-container">
                                  <div className="join-group-left-side">
                                    {postData.group && postData.group.profile_image && (
                                      <img
                                        src={postData.group && postData.group.profile_image}
                                        alt={"group-profile"}
                                      />
                                    )}
                                  </div>
                                  <div className="join-group-right-side">
                                    {postData.group && postData.group.name && (
                                      <h2>{postData.group.name}</h2>
                                    )}
                                    {postData.group && postData.group.description && (
                                      <p>{postData.group.description}</p>
                                    )}
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        joinGroup();
                                      }}
                                    >
                                      Join
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal>
                        )}
                        <div className="post-card-action-button--wrapper">
                          <div className="post-card-action-button--wrapper-upper">
                            <div className="post-card-action-button--wrapper-first">
                              {!props.disableReactions ? (
                                <>
                                  <button
                                    onClick={likeUnlikeReaction}
                                    className="flex flex-align-center post-card-action-button"
                                  >
                                    {postReactionLikeState.liked ? <span><LoveIconFill /></span> : <span><LoveIcon /></span>}
                                    {postReactionLikeState.liked ? <span className="post-liked-text">Liked</span> : <span>Like</span>}

                                    {/* <span>Like</span> */}
                                  </button>
                                  <button
                                    onClick={() => {
                                      if (moderationView || props.disableReactions) {
                                        setShowCommentSection(!showCommentSection);
                                        return;
                                      }

                                          if (!props.group || (props.group && props.group.membership_status !== "member") && !props.isEnrolled) {
                                            toast("Only community members can react to this post", {
                                              type: ToastType.ERROR,
                                              autoClose: 3000,
                                            });
                                            return;
                                          }

                                      if (!props.user) {
                                        if (window.location.pathname !== '/') {
                                          setlocalStorageValue("next_link", window.location.pathname);
                                        }
                                        props.publicLogin();
                                      }

                                      if (!canGetComments && commentsCount > 0) {
                                        setCanGetComments(true);
                                      }

                                      setShowCommentSection(!showCommentSection);
                                    }
                                    }
                                    className="flex flex-align-center post-card-action-button"
                                  >
                                    <span><CommentIcon /></span>
                                    <span>Comment</span>
                                  </button>
                                </>
                              ) : (<></>)}
                              {
                                post.postable_type !== 'articles' &&
                                <CopyToClipboard
                                  text={`${window.location.origin}/posts/${postData.slug}`}
                                  onCopy={() => {
                                    coppyToClipboard();
                                  }}
                                >
                                  <button
                                    className="flex flex-align-center post-card-action-button post-card-action-button--share"
                                  >
                                    <span><ShareIcon /></span>
                                    <span>Share</span>
                                  </button>
                                </CopyToClipboard>
                              }
                            </div>

                            {!moderationView && !props.announcementPost && !props.disableReactions && (
                              <div className="post-card-stats-container">
                                <p
                                  className="post-card-stats-comment-count"
                                  onClick={() => {
                                    if (!canGetComments && commentsCount > 0) {
                                      setCanGetComments(true);
                                    }
                                    if (commentsCount > 0) {
                                      setShowCommentSection(!showCommentSection);
                                    }
                                  }}
                                >{commentsCount} Comment{commentsCount === 1 ? "" : "s"}</p>

                              </div>
                            )}

                          </div>

                          {/* {!moderationView && !props.announcementPost && !props.disableReactions && commentsCount > 0 && _commentId === null && (
                                <div className="post-card-stats-container comment-stat">
                                  <p
                                    className="post-card-stats-comment-count"
                                    onClick={() => {
                                      if (moderationView || props.disableReactions) {
                                        setShowCommentSection(!showCommentSection);
                                        return;
                                      }

                                      if (!props.user) {
                                        setlocalStorageValue("next_link", window.location.pathname);
                                        props.publicLogin();
                                      }

                                      // if (!canGetComments && commentsCount > 0) {
                                      setCanGetComments(true);
                                      // }

                                      setShowCommentSection(true);
                                    }}
                                  >View {commentsCount} comment{commentsCount === 1 ? "" : "s"}</p>
                                </div>
                              )} */}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>

              {
                (loadingComments && comments.data.length === 0) &&
                <div className="loading-comments-icon">
                  <SpinIcon />
                </div>
              }
              {!moderationView && !props.announcementPost && !props.disableReactions && showCommentSection && (
                <>
                  {
                    post && post.commenting_closed ? (
                      <div
                        className="gp-post-commenting-closed-notification-container"
                      >
                        <div
                          className="gp-post-commenting-closed-notification-wrapper"
                        >
                          <span
                            className="gp-post-commenting-closed-notification-icon"
                          >
                            <FontAwesomeIcon
                              icon={faLock}
                            />
                          </span>
                          <p
                            className="gp-post-commenting-closed-notification-label"
                          >
                            Comments are closed for this post.
                          </p>
                        </div>
                      </div>
                    ) : (<></>)
                  }
                  {(showCommentSection) ? (
                      <div className={
                        `post-comments
                         ${(postData && postData.commenting_closed) ? 'post-comments--commenting-closed' : ''}
                         ${(window.location.pathname.split("/")[1] === "courses") && !window.location.pathname.includes('lectures') ? 'post-comments--courses-section' : ''}
                         `}>
                      {
                        _commentId !== null && commentsCount > 1 &&
                        <div
                          className="load-more-comments"
                          onClick={() => {
                            setComments({
                              data: [],
                              total: 0
                            })
                            refreshComments()
                            handleClearQueryParams()
                            setScrollToComment(undefined)
                            window.scrollTo(0, 0);
                          }}
                        >
                          <RedirectIcon />
                          <span>
                                Show all comments {" "}
                                {/* {commentsCount} comment{commentsCount === 1 ? "" : "s"} */}
                          </span>
                        </div>
                      }

                        {comments.data.map((comment: any, index: number) => {
                        return (
                          <CommentComponent
                            userGroups={userGroups ? userGroups.data : undefined}
                            group={group}
                            post={postData}
                            isOwner={props.isOwner}
                            onPostRequest={onPostRequest}
                            userId={user && user.id}
                            canInteract={ableToInteract}
                            refreshGroupPostsWithoutLoad={refreshGroupPostsWithoutLoad}
                            onCommentRemoved={(commentId: number) => {
                              GroupService.getInstance().getGroupPost(props.group.id, props.post.id)
                                .then((response: any) => {
                                  setCommentsCount(response.all_comments_count);
                                })
                              setFirstLevelCommentsCount(firstLevelCommentsCount - 1);
                              setComments({
                                ...comments,
                                data: comments.data.filter((comment: any) => comment.id !== commentId),
                              });
                            }}
                            key={index}
                            comment={comment}
                            theme={theme}
                            user={user}
                            onPost={() => {
                              // setCommentsCount(commentsCount + 1);
                              refreshComments(comments.data.length);
                            }}
                            setLoadingReacters={setLoadingReacters}
                            setReactersData={setReactersData}
                            view={view}
                            publicLogin={props.publicLogin}
                            setIsReactersModalOpen={setIsReactersModalOpen}
                            incrementCommentsCount={() => {
                              // setCommentsCount(commentsCount + 1)
                              if (scrollToComment) {
                                GroupService.getInstance().getGroupPost(props.group.id, props.post.id, scrollToComment)
                                  .then((response: any) => {
                                    setCommentsCount(response.all_comments_count);
                                    setComments({
                                      ...comments,
                                      data: [response && response.comment],
                                    });
                                  })
                              } else {
                                GroupService.getInstance().getGroupPost(props.group.id, props.post.id)
                                  .then((response: any) => {
                                    setCommentsCount(response.all_comments_count);
                                  })
                              }
                            }}
                            isEnrolled={props.isEnrolled}
                            setCommentsCount={setCommentsCount}
                            commentsCount={commentsCount}
                            setShowCommentSection={setShowCommentSection}
                          />
                        );
                      })}
                      {firstLevelCommentsCount > 5 && comments.data.length !== firstLevelCommentsCount && canGetComments && _commentId === null && comments.data.length > 0 && (
                        <div
                          onClick={() => showMoreComments(comments.data.length + 5)}
                          className="show-more"
                        >
                          <span
                          // className="btn btn-link"
                          >Show more comments </span> {"  "}
                          <span className="show-more-comments-number">({`${comments.data.length} of ${firstLevelCommentsCount}`})</span>
                        </div>
                      )}
                      {comments.data.length > 5 && comments.data.length === firstLevelCommentsCount && _commentId === null && (
                        <div onClick={() => showMoreComments(5)} className="show-more">
                          <span
                          //  className="btn btn-link"
                          >Show less comments</span>
                        </div>
                      )}
                    </div>
                  ) : (<></>)}
                </>
              )}

                {
                  user &&
                  (ableToInteract || isAlowed) &&
                  !moderationView &&
                  !props.announcementPost &&
                  !props.disableReactions &&
                  !(post && post.commenting_closed) &&
                  showCommentSection &&
                  !loadingComments &&
                  // prevent non members from opening the comment section
                  ((props.group && props.group.membership_status === "member") ||
                    (!props.group || (props.group && props.group.membership_status !== "member") && props.isEnrolled)) &&
                  (
                <Element name={`comment-input_${postData.id}`} className="comment-input-container">
                  <div className="comment-wrap-full-box">
                    <PostComment
                      group={group}
                      theme={theme}
                      inputReference={commentInput}
                      postId={postData.id}
                      user={user}
                      onPost={(newComment?: any) => {
                        if (scrollToComment) {
                          setComments({
                            ...comments,
                            data: [post && post.comment, newComment],
                          });
                        }
                        setCommentsCount(commentsCount + 1);
                        setFirstLevelCommentsCount(firstLevelCommentsCount + 1);
                        if (!scrollToComment) {
                          refreshComments(comments.data.length === 0 ? 5 : comments.data.length + 1, firstLevelCommentsCount > 5 && comments.data.length !== firstLevelCommentsCount && newComment);
                        }
                      }}
                      singlePostView={props.singlePostView}
                      lectureView={props.lectureView}
                    />
                  </div>
                </Element>
              )}
            </div>
          </>
        )}
      <>
        <ReactModal
          isOpen={isEditMode}
          style={{
            content: {
              width: editorFullScreen ? "100vw" : "600px",
              maxWidth: editorFullScreen ? "" : "calc(100vw - 10px)",
              height: editorFullScreen ? screenWidth <= 550 ? "auto" : "100vh" : "auto",
              borderRadius: editorFullScreen ? 0 : '4px',
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, -50%)",
              position: "fixed",
              background: "white",
              border: 0,
              padding: 0,
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 99999,
            }
          }}
        >
          <div
            className={`group-post-card-editor-container ${editorFullScreen ? "group-post-card-editor-container--fullscreen" : ""}`}
          >
            <PostableEditor
              group={group}
              editMode
              post={postData}
              onCancelEdit={() => setIsEditMode(false)}
              theme={theme}
              onSubmitEdit={refreshGroupPostsWithoutLoad ?
                async (editedPost: any) => {
                  await refreshGroupPostsWithoutLoad(editedPost)
                  await setPostData(editedPost);
                  setIsEditMode(false)
                } : () => { }}
              closeModal={() => setIsEditMode(false)}
              fullScreen={editorFullScreen}
              toggleFullScreen={() => setEditorFullScreen(!editorFullScreen)}
              requireTitle={!!getPostableField("post_title_required")}
              requireCoverImage={!!getPostableField("cover_image_required")}
            />
          </div>
        </ReactModal>
      </>
      <>
        <Reacters
          reactersData={reactersData}
          loadingReacters={loadingReacters}
          setReactersData={setReactersData}
          setLoadingReacters={setLoadingReacters}
          isOpen={isReactersModalOpen}
          setIsOpen={setIsReactersModalOpen}
          loadMoreReacters={loadMoreReacters}
        />
      </>
      <ConfirmModal
        isModalOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        actionCallback={deletePost}
        modalSubTitle="Are you sure you would like to delete this post?"
        modalTitle="Confirm delete action"
        actionBtn="Delete"
        cancelBtn='Cancel'
      />
    </>
  ) : null;
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IGroupPostComponentProps
) => {
  return {};
};

const mapStateToProps = (
  state: Map<string, any>,
  ownProps: IGroupPostComponentProps
) => {
  const groups = Map(state.get("group", {})).toJS() as any;
  const global = Map(state.get("global", {})).toJS() as any;

  return {
    groups,
    global
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupPostComponent as any) as any
) as any;
