import React, { Component } from "react";
import { IMainLoadingComponentProps } from "./IMainLoadingComponentProps";
import { IMainLoadingComponentState } from "./IMainLoadingComponentState";

export default class MainLoadingComponent extends Component<
  IMainLoadingComponentProps,
  IMainLoadingComponentState
> {
  public loadProgress() {
    return (
      <div className="main__loading">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
    );
  }

  public render() {
    return <div>{this.loadProgress()}</div>;
  }
}
