import React from "react";
import { useHistory } from 'react-router';

import useComponentVisible from "../../../utils/visible/visible";
import { ITopBarMenuComponentProps } from "./ITopBarMenuComponentsProps";
import { authorizeActions } from "../../../store/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import miscUtils from "../../../utils/miscUtils";
import { Map } from "immutable";
import { useQueryClient } from 'react-query';
import './top-bar-menu.scss'

const TopBarMenuComponent: React.FC<ITopBarMenuComponentProps> = props => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { logout, group, disableLogout, user } = props;

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const groupHomePage = Boolean(window.location.href === miscUtils.getBaseDomain() + "/")

  return (
    <div
      className={`header-settings dropdown-menu ${props.lectureView ? 'header-settings-lecture' : ''}`} ref={ref}>
      <div onClick={() => setIsComponentVisible(true)}>
        {
          user.profile_image ?
            <img
              alt='user profile'
              className='logout-profile-image'
              src={user.profile_image}
            />
            :
            <div
              className='logout-profile-image'
              data-color={miscUtils.getColorInitial(`${user.full_name}`)}
            >
              {miscUtils.getNameInitials(user.full_name)}
            </div>
        }
      </div>

      {isComponentVisible && (
        <ul className="logout-popup">

          <span>ACCOUNT</span>
          <div className="logout-popup-acc">
            <div className="logout-popup-acc-first">
              {
                user.profile_image ?
                  <img
                    alt='user profile'
                    className='logout-popup-acc-first-image'
                    src={user.profile_image}
                  />
                  :
                  <div
                    className='logout-popup-acc-first-image'
                    data-color={miscUtils.getColorInitial(`${user.full_name}`)}
                  >
                    {miscUtils.getNameInitials(user.full_name)}
                  </div>
              }
            </div>
            <div className="logout-popup-acc-second">
              <h1>{user.full_name}</h1>
              <small>{user.email}</small>
            </div>
          </div>
          {
            groupHomePage &&
            <li onClick={() => {
              setIsComponentVisible(false)
              history.push({
                pathname: "/about",
                search: "?section=profile-details&hideNotification=true"
              })
            }}>
                Profile details
            </li>
          }

          {
            group &&
            group.menu['profile']['show'] &&
            group.user_membership &&
            group.user_membership.request_status === 'member' &&
            !groupHomePage &&
            <>
              <li onClick={() => {
                setIsComponentVisible(false)
                history.push({
                  pathname: "/about",
                  search: groupHomePage
                    ? "?section=profile-details&hideNotification=true"
                    : "?section=profile-details"
                });

              }}>
                Profile details
              </li>

              <li onClick={() => {
                setIsComponentVisible(false)
                history.push({
                  pathname: "/about",
                  search: "?section=notification-settings"
                })
              }}>
                Notification settings
              </li>

              {
                !miscUtils.isAdmin(group.user_membership) &&
                <li onClick={() => {
                  setIsComponentVisible(false)
                  history.push({
                    pathname: "/about",
                    search: "?section=billing-details"
                  })
                }}>
                    Billing
                </li>
              }
            </>
          }


          <li
            onClick={() => {
              if (disableLogout) return
              queryClient.clear();
              logout(group, props.subdomain)
            }}
            className="logout-button"
          >
            Log out
          </li>
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (
  state: Map<string, any>,
  ownProps: ITopBarMenuComponentProps
) => {
  const uid: string = state.getIn(["authorize", "uid"]) as string;
  const user: any = state.getIn(["authorize", "user"]);
  const disableLogout: any = state.getIn(["global", "disableLogout"]);

  return {
    uid,
    user,
    disableLogout,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ITopBarMenuComponentProps) => {
  return {
    logout: (group: any, subdomain?: any) => {
      dispatch(authorizeActions.dbLogout());

      if (window.location.pathname !== ("/signin" || "/login")) {
        if (subdomain) {
          window.location.assign(`${group ? "/signin" : "/login"}`)
        } else {
          window.location.assign(`${group ? `/group/${group.slug}/signin` : `/group/${group.slug}/login`}`)
        }
      }
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopBarMenuComponent as any) as any
) as any;
