import { isValidHexColor } from "components/PageBuilder/PageBuilderSideBar/BlockSettings/domain";
import styled from "styled-components";

const BlockContainer: any = styled.div<{design: any}>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props: any) => 
        props.design.container_background_color &&
        isValidHexColor(props.design.container_background_color) ?
        props.design.container_background_color :
        'transparent'
    };
    padding-top: ${(props: any) => 
        props.design.container_padding_top ?
        `${props.design.container_padding_top}px` :
        `0px`
    };
    padding-bottom: ${(props: any) => 
        props.design.container_padding_bottom ?
        `${props.design.container_padding_bottom}px` :
        `0px`
    };
`;

export default BlockContainer;