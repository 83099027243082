import React, { createContext, useState } from "react";
import "./userProfileDetails.scss";

import { UserService } from "../../../../api/services/users";

import DeleteConfirmation from "containers/channels/collections/deleteConfirmation";
import { ReactComponent as Trash } from 'assets/img/trash.svg'
import { ReactComponent as Edit } from 'assets/img/edit.svg'
import { ReactComponent as Twitter } from 'assets/img/twitter.svg'
import { ReactComponent as Facebook } from 'assets/img/facebook.svg'
import { ReactComponent as IG } from 'assets/img/instagram.svg'
import { ReactComponent as LinkedIn } from 'assets/img/linkedin.svg'
import { ReactComponent as Website } from 'assets/img/globe.svg'
import UserProfileDetailsEdit from "../userProfileDetailsEdit";
import ReadMore from "utils/readMore/readMore";
import PostsFeedLoader from "components/contentLoaders/PostsFeedLoader";
import { GroupService } from "api/services/groups";
import { UserProfileContext } from "../UserProfile";
import { QuestionTypes } from "containers/Onboarding/MembershipQuestions/QuestionMaker/domain";
import UserCustomFields from "../UserCustomFields/UserCustomFields";
import ContentLoader from "react-content-loader";

export const userCustomFieldsContext = createContext<any>({});

const UserProfileDetails = (props: {
  logout: any;
  uid: number;
  userData: any
  setUserData: any
}) => {
  const { logout, uid, userData } = props

  const [isDeleting, setDeleteState] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState(false)
  const [showEditCustomFields, setShowEditCustomFields] = useState(false)
  const [membershipQuestions, setMembershipQuestions] = useState<any>();
  const [responseState, setResponseState] = React.useState<Array<any>>([]);

  const { groupData, showCustomEditMode, setShowCustomEditMode } = React.useContext(UserProfileContext)

  React.useEffect(() => {
    function getCommunityFieldsData() {
      if (groupData && uid) {
        GroupService.getInstance()
          .getCommunityFields(groupData && groupData.id, 'custom')
          .then((response: any) => {
            if (response && response.data) {
              const customData = response.data
                .filter((newData: any) => newData.required === true || newData.ask_during_sign_up === true)
                .sort((a: any, b: any) => a.position - b.position);
              setMembershipQuestions([...customData]);
            }
          });
      }
    }

    getCommunityFieldsData();

  }, [groupData]);

  const existingMemberAnswer = groupData && groupData.user_membership && groupData.user_membership.screening_response

  React.useEffect(() => {

    if (membershipQuestions) {
      setResponseState(membershipQuestions.map((membershipQuestion: any) => {
        const matchingScreeningQuestion = existingMemberAnswer && existingMemberAnswer.find(
          (screenQuestion: any) => (screenQuestion.type === membershipQuestion.type) &&
            (screenQuestion.question === membershipQuestion.name)
        );

        return {
          question: membershipQuestion.name,
          type: membershipQuestion.type,
          answer: matchingScreeningQuestion ? matchingScreeningQuestion.answer :
            (membershipQuestion.type === QuestionTypes.Checkbox ? [] : ""),
        };
      }));
    }
  }, [membershipQuestions]);


  const handleDeleteUser = () => {
    UserService.getInstance()
      .deleteUser(uid)
      .then(() => {
        logout();
      })
      .catch(() => {
        setDeleteState(false);
      });
  };

  return (
    <>
      <div className="user-profile-body-middle">
        <span
          className="user-profile-body-middle-profile-info-text"
          onClick={() => {
            setShowEdit(false)
            setShowEditCustomFields(false)
          }}>
          Profile info
        </span>
        <button
          className="user-profile-body-middle-button-edit"
          onClick={() => {
            setShowEdit(true)
            setShowEditCustomFields(false)
          }}
        >
          <Edit width={20} height={20} />
          <span className="user-profile-body-middle-text-edit">
            Edit
          </span>
        </button>
      </div>

      {showEditCustomFields &&
        <div>
          <UserCustomFields
            membershipQuestions={membershipQuestions as Array<any>}
            responseState={responseState}
            setResponseState={setResponseState}
            groupData={groupData}
          />
        </div>
      }

      {showEdit &&
        <UserProfileDetailsEdit setShowEdit={setShowEdit} />}

      {userData.length === 0 ?
        <div className="events-loader">
          <PostsFeedLoader
            padding={2}
            styles={{ background: "#FFFFFF", borderRadius: "6px" }}
          />
        </div>
        :

        <>
          {!showEdit &&
            <>
            <div className="user-profile-details-body-wrapper-two">

              <label className="user-profile-details-body-titles">Full name</label>
              <span className="user-profile-details-body-values">{(userData && userData.full_name) || ""}</span>

              <label className="user-profile-details-body-titles">Headline</label>
              {
                (userData && userData.headline) ?
                  <span className="user-profile-details-body-values">{(userData && userData.headline) || ""}</span>
                  :
                  <span className="user-profile-details-body-values">-</span>
              }

              <label className="user-profile-details-body-titles">Email</label>
              <span className="user-profile-details-body-values">{(userData && userData.email) || ""}</span>

              <label className="user-profile-details-body-titles">Country</label>
              {
                (userData.country && userData.country.name) ?
                  <span className="user-profile-details-body-values">{(userData.country && userData.country.name) || ""}</span>
                  :
                  <span className="user-profile-details-body-values">-</span>
              }

              <label className="user-profile-details-body-titles">City</label>
              {
                (userData && userData.location) ?

                  <span className="user-profile-details-body-values">{(userData && userData.location) || ""}</span>
                  :
                  <span className="user-profile-details-body-values">-</span>
              }

              <label className="user-profile-details-body-titles">Timezone</label>
              {
                (userData.timezone && userData.timezone.text) ?
                  <span className="user-profile-details-body-values">{(userData.timezone && userData.timezone.text) || ""} {''}
                  </span>
                  :
                  <span className="user-profile-details-body-values">-</span>
              }

              <label className="user-profile-details-body-titles">Job title</label>
              {
                (userData && userData.job_title) ?
                  <span className="user-profile-details-body-values" style={{ textTransform: 'capitalize' }}>{(userData && userData.job_title) || ""}</span>
                  :
                  <span className="user-profile-details-body-values">-</span>
              }

              <label className="user-profile-details-body-titles">Bio</label>
              {
                userData.about_me ?
                  <>
                    <span className="bio-details">
                      {
                        userData.about_me && userData.about_me.length > 100 ? (
                          <ReadMore
                            text={userData.about_me}
                            readMoreCharacterLimit={100}
                            numberOfLines={1}
                            lineHeight={1}
                            showLessButton
                            onContentChange={() => { }}
                            showMoreText="Read Full Bio"
                            showLessText="Hide Full Bio"
                          />
                        ) : (userData.about_me)
                      }
                    </span>
                  </>
                  :
                  <span
                  // className="user-profile-details-body-values"
                  >-</span>
              }

              <div className="user-profile-details-body-wrapper-two-icons">
                <span className="user-profile-detail-icon-header">Website URL</span>
                {(userData && userData.website) ?
                    <div className="user-profile-detail-icon-body">
                      <Website width={20} height={20} />
                      <a href={userData.website} className="user-profile-detail-icon-body-link">
                        {(userData && userData.website) || ""}
                      </a>
                    </div>
                  :
                  <span
                  // className="user-profile-details-body-values"
                  >-</span>
                }
              </div>

              <div className="user-profile-details-body-wrapper-two-icons">
                <span className="user-profile-detail-icon-header">Linkedin URL</span>
                {(userData && userData.linkedin) ?
                    <div className="user-profile-detail-icon-body">
                      <LinkedIn width={20} height={20} />
                      <a href={userData.linkedin} className="user-profile-detail-icon-body-link">
                        {(userData && userData.linkedin) || ""}
                    </a>
                    </div>
                  :
                  <span
                  // className="user-profile-details-body-values"
                  >-</span>
                }
              </div>

              <div className="user-profile-details-body-wrapper-two-icons">
                <span className="user-profile-detail-icon-header">Facebook URL</span>
                {(userData && userData.facebook) ?
                    <div className="user-profile-detail-icon-body">
                      <Facebook width={20} height={20} />
                      <a href={userData.facebook} className="user-profile-detail-icon-body-link">
                        {(userData && userData.facebook) || ""}
                      </a>
                    </div>
                  :
                  <span
                  // className="user-profile-details-body-values"
                  >-</span>
                }
              </div>

              <div className="user-profile-details-body-wrapper-two-icons">
                <span className="user-profile-detail-icon-header">Instagram URL</span>
                {(userData && userData.instagram) ?
                    <div className="user-profile-detail-icon-body">
                      <IG width={20} height={20} />
                      <a href={userData.instagram} className="user-profile-detail-icon-body-link">
                        {(userData && userData.instagram) || ""}
                      </a>
                    </div>
                  :
                  <span
                  // className="user-profile-details-body-values"
                  >-</span>
                }
              </div>

                <div className="user-profile-details-body-wrapper-two-icons">
                <span className="user-profile-detail-icon-header">Twitter URL</span>
                {(userData && userData.twitter) ?
                    <div className="user-profile-detail-icon-body">
                      <Twitter width={20} height={20} />
                      <a href={userData.twitter} className="user-profile-detail-icon-body-link">
                        {(userData && userData.twitter) || ""}
                      </a>
                  </div>
                  :
                  <span
                  // className="user-profile-details-body-values"
                  >-</span>
                }
              </div>

            </div>

            {
              membershipQuestions === undefined ?
                <div
                  className="questions-loader"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >

                  <>
                    {Array(5).fill('').map((_e, i) => (
                      <div style={{ width: '100%' }}>
                        <ContentLoader
                          height={window.innerWidth <= 500 ? 90 : 15}
                          width={120}
                          speed={2}
                          primaryColor="#d9d9d9"
                          secondaryColor="#ecebeb"
                          viewBox="0 0 120 15"
                        >
                          <rect x="5" y="0" rx="1" ry="1" width={60} height={3} />
                          <rect x="5" y="6" rx="1" ry="1" width={90} height={3} />
                        </ContentLoader>
                      </div>
                    ))}
                  </>
                </div> :
                membershipQuestions && membershipQuestions.length > 0 &&
                <div className="custom-header">
                    <div className="custom-header-div">
                      <span
                        className="custom-fields-edit-text"
                        onClick={() => { setShowCustomEditMode(true) }}
                      >
                        Custom fields
                      </span>

                      <button
                        onClick={() => setShowCustomEditMode(false)}
                        className="custom-header-div-edit-mode"
                      >
                        <Edit width={20} height={20} />
                        <span>Edit</span>
                      </button>

                    </div>

                    <UserCustomFields
                      membershipQuestions={membershipQuestions as Array<any>}
                      responseState={responseState}
                      setResponseState={setResponseState}
                      groupData={groupData}
                    />

                  </div>
            }

            <div className={`user-profile-action-delete ${membershipQuestions && membershipQuestions.length > 0 && !showCustomEditMode ? "user-delete-action-on-custom" : ""}`}>
              <button
                className="user-profile-action-delete-btn"
                onClick={() => setDeleteState(true)}
              >
                <Trash width={20} height={20} />
                <span className="user-profile-action-delete-text">
                  Delete profile
                </span>
              </button>
            </div>


            <DeleteConfirmation
              isOpen={isDeleting}
              onClose={() => setDeleteState(false)}
              onConfirm={() => handleDeleteUser()}
              deletable={userData}
              deletableNameKey='full_name'
            />
          </>

          }
        </>
      }

    </>
  );
};

export default UserProfileDetails
