import React from "react";
import { IButtonPropsComponent } from "./IButtonPropsComponent";
import miscUtils from "../../utils/miscUtils";
import clsx from "clsx";
import "./Button.scss";

const Button = (props: IButtonPropsComponent) => {
  const { useCustomTheme = true, isLink = false } = props;


  return (
    <>
      <button
        type={props.type}
        style={
          useCustomTheme ? miscUtils.getBlockButtonStyle(props.theme) : {}
        }
        className={clsx(
          props.classes,
          { ["button-component btn btn-spec-lr"]: !isLink },
          { ["disabled"]: props.disabled },
          { ["link"]: isLink }
        )}
        onClick={!props.isLoading ? props.onClick : () => { }}
      >
        {!props.isLoading ? props.label : <div>Processing...</div>}
      </button>
    </>
  );
};

export default Button;
