import * as QueryFunctions from './queryFns';
import * as KeyIds from './keyIds';

export default {
    queryFunctions: {
        ...QueryFunctions,
    },
    keyIds: {
        ...KeyIds,
    }
}