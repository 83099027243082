import React from "react";
import WelcomePageComponent from "../welcomePage";
import "./Home.scss";
import { Map } from "immutable";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { IHomeProps } from "./IHomeProps";
// import store from "../../store/configureStore";
// import { push } from "connected-react-router";
// import { getCookieValue } from "../../utils/cookieStorage";

const Home: React.FC<IHomeProps> = props => {
  const { userGroups } = props;

  return <>{userGroups && <WelcomePageComponent userGroups={userGroups}/>}</>;
};

const mapStateToProps = (state: Map<string, any>, ownProps: IHomeProps) => {
  const groups: any = Map(state.get("group", {})).toJS();
  const global = Map(state.get("global", {})).toJS() as any;
  return {
    global,
    userGroups: groups.userGroups
  };
};

export default withRouter(connect(mapStateToProps)(Home as any) as any) as any;
