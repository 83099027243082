import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "./FlexyPopOnMe.scss";


//children must be a single html element

const FlexyPopOnMe: React.FC<{
  children: any;
  component: any;
  placement?: "bottom-start" | "bottom" | "bottom-end" | "top"; //add the extra placement you need here
  alwaysOpen?: boolean;
  disabled?: boolean;
  customCallback?: any;
  hideTippyArrow?: boolean;
  closeOnComponentClick?: boolean;
}> = ({ 
  children, 
  component, 
  placement = "bottom", 
  alwaysOpen = false, 
  disabled, 
  customCallback,
  hideTippyArrow, 
  closeOnComponentClick,
}) => {
  const [open, setOpen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  return (
    <Tippy
      content={closeOnComponentClick ? React.cloneElement(component, {
        onClick: () => {
          setTimeout(() => {
            setOpen(false)
          }, 100);
        }
      }) : component}
      visible={alwaysOpen ? true : open}
      interactive={!alwaysOpen || (alwaysOpen && visible)}
      onClickOutside={() => {
        if(alwaysOpen) {
          setVisible(false);
        } else {
          setOpen(false);
        }
      }}
      placement={placement}
      theme="light-border"
      appendTo={document.body}
      className={
        `${alwaysOpen && !visible ? "tippy-invisible" : ""} ${hideTippyArrow ? 'hide-tippy-arrow' : ''}`
      }
      zIndex={2147483647}
    >
      {React.cloneElement(children, {
        onClick: () => {
          if(disabled) {
            return;
          }

          if(customCallback) {
            customCallback();
            return;
          }

          if (alwaysOpen) {
            setVisible(!visible);
          } else {
            setOpen(!open);
          }
        }
      })}
    </Tippy>
  );
};

export default FlexyPopOnMe;
