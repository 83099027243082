import React, { useEffect } from "react";
import { IWistiaPlayerProps } from "./IWistiaPlayerProps";

const WistiaPlayer: React.FC<IWistiaPlayerProps> = props => {
  const { videoId, height } = props;
  useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script1.async = true;

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div 
    className="wistia-wrapper"
    style={{
      marginTop: 0,
      height: height ? `${height}px` : '',
      backgroundColor: '#333333',
    }}
    >
      <div
        className={`wistia_embed wistia_async_${videoId} videoFoam=true autoPlay=false`}
      />
    </div>
  );
};

export default WistiaPlayer;
