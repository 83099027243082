export enum PageBlockTypes {
    Banner = 'banner',
    Heading = 'heading',
    Subtext = 'subtext',
    Text = 'text',
    Image = 'image',
    ImageText = 'image-text',
    ImageTextColumn = 'image-text-column',
    ImageGridText = 'image-grid-text',
    Video = 'video',
    VideoText = 'video-text',
    Button = 'button',
    Pricing = 'pricing',
    Event = 'event',
    CourseCurriculum = 'course-curriculum',
    FAQ = 'faq',
    CountdownTimer = 'countdown-timer',
}

export enum VideoType {
    Uplaod = 'Upload',
    WebLink = 'Web link',
}

export enum ButtonAction {
    None = 'none',
    Scroll = 'scroll',
    URL = 'url',
    Popup = 'popup',
    URLTab = 'url-tab',
}

export enum PricePeriod {
    Month = 'month',
    Year = 'year',
    Quarterly = 'every 3 months',
    SemiAnnually = 'every 6 months',
    OneTime = 'one-time',
}

export enum ButtonStyles {
    Flat = 'flat',
    Line = 'line',
    Gradient = 'gradient',
}

export enum BackgroundImageSizing {
    Fit = 'fit',
    Fill = 'fill',
    Auto = 'auto',
}

export enum BackgroundImageRepeat {
    None = 'none',
    Horizontally = 'horizontally',
    Vertically = 'vertically',
    Both = 'both',
}

export enum BackgroundImagePosition {
    Top = 'top',
    Center = 'center',
    Bottom = 'bottom',
}

export enum WidgetType {
    Form = 'form',
    Scheduler = 'scheduler',
}

export enum FormFieldTypes {
    FirstName = 'First Name',
    LastName = 'Last Name',
    Email = 'Email',
    PhoneNumber = 'Phone Number',
    UserConsent = 'User Consent',
}

export enum SchedulerTypes {
    Calendly = 'Calendly',
}

export const getDefaultPageStyles: any = () => {
    return ({
        button: {
            button_style: ButtonStyles.Flat,
            button_text_font_size: 13,
            button_text_line_height: 16,
            button_text_color: '#FFFFFF',
            button_background_color: '#EE8F00',
            button_hover_text_color: null,
            button_border_radius: 4,
        },
        fonts: {
            h1: {
                font_family: 'Arial, sans-serif',
                text_color: '#333333',
                font_size: 35,
                line_height: 45,
            },
            h2: {
                font_family: 'Arial, sans-serif',
                text_color: '#333333',
                font_size: 31,
                line_height: 40,
            },
            h3: {
                font_family: 'Arial, sans-serif',
                text_color: '#333333',
                font_size: 27,
                line_height: 35,
            },
            p: {
                font_family: 'Arial, sans-serif',
                text_color: '#666666',
                font_size: 20,
                line_height: 25,
            },
        },
        background: {
            background_color: '#FAFAFA',
            background_image_url: null,
            background_image_color_overlay: null,
            background_image_sizing: BackgroundImageSizing.Fit,
            background_image_position: BackgroundImagePosition.Top,
            background_image_repeat: BackgroundImageRepeat.Both,
            extend_section_background: false,
            background_opacity: 1,
        },
    });
}

export const getDefaultPageBlockData: any = (pageBlockType: PageBlockTypes, pageStyles: any) => {
    switch(pageBlockType) {
        case PageBlockTypes.Banner:
            return ({
                name: '',
                type: pageBlockType,
                image_alt: 'example banner',
                image_url: 'https://static-staging.group.app/posts/images/otYFmmuB0V_kidos_testplan_post__pexels-fauxels-3184302.jpg',
                heading: '<h1><strong>Example Banner</strong></h1>',
                heading_json: `{"blocks":[{"key":"3s2ed","text":"Example Banner","type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                subtext: '<p><strong>Showcase your course or coaching, an announcement, or big feature. Use an eye-catching subheader to give your audience more context</strong></p>',
                subtext_json: `{"blocks":[{"key":"bd1qb","text":"Showcase your course or coaching, an announcement, or big feature. Use an eye-catching subheader to give your audience more context","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":131,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                button_shown: true,
                button_action: ButtonAction.URL,
                button_text: '[Set button action]',
                button_url: '#',
                design: {
                    container_background_color: null,
                    container_padding_top: 120,
                    container_padding_bottom: 120,
                    content_container_max_width: 772,
                    image_opacity: 1,
                    text_alignment: 'center',
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#FFFFFF',
                    heading_h1_color: '#FFFFFF',
                    heading_h2_color: '#FFFFFF',
                    heading_h3_color: '#FFFFFF',
                    heading_p_color: '#FFFFFF',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#FFFFFF',
                    subtext_h1_color: '#FFFFFF',
                    subtext_h2_color: '#FFFFFF',
                    subtext_h3_color: '#FFFFFF',
                    subtext_p_color: '#FFFFFF',
                },
            });
        case PageBlockTypes.Heading:
            return ({
                name: '',
                type: pageBlockType,
                heading: '<h1><strong>Example Text</strong></h1>',
                heading_json: `{"blocks":[{"key":"92iva","text":"Example Text","type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    text_alignment: 'center',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',
                },
            });
        case PageBlockTypes.Subtext:
            return ({
                name: '',
                type: pageBlockType,
                text: `<p>Use this Text block to tell your course or coaching’s story. Write anything from one-liners to detailed paragraphs that tell your visitors more about what you’re selling. This block - along with other blocks that contain text content - supports various text formatting such as header sizes, font styles, alignment, ordered and unordered lists, hyperlinks and colors.</p>`,
                text_json: `{"blocks":[{"key":"bpgvj","text":"Use this Text block to tell your course or coaching’s story. Write anything from one-liners to detailed paragraphs that tell your visitors more about what you’re selling. This block - along with other blocks that contain text content - supports various text formatting such as header sizes, font styles, alignment, ordered and unordered lists, hyperlinks and colors.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    text_alignment: 'center',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    text_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',
                },
            });
        case PageBlockTypes.Text:
            return ({
                name: '',
                type: pageBlockType,
                heading: '<h1><strong>Example Text</strong></h1>',
                heading_json: `{"blocks":[{"key":"92iva","text":"Example Text","type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                text: `<p>Use this Text block to tell your course or coaching’s story. Write anything from one-liners to detailed paragraphs that tell your visitors more about what you’re selling. This block - along with other blocks that contain text content - supports various text formatting such as header sizes, font styles, alignment, ordered and unordered lists, hyperlinks and colors.</p>`,
                text_json: `{"blocks":[{"key":"bpgvj","text":"Use this Text block to tell your course or coaching’s story. Write anything from one-liners to detailed paragraphs that tell your visitors more about what you’re selling. This block - along with other blocks that contain text content - supports various text formatting such as header sizes, font styles, alignment, ordered and unordered lists, hyperlinks and colors.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    text_alignment: 'center',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    text_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',
                },
            });
        case PageBlockTypes.Image:
            return ({
                name: '',
                type: pageBlockType,
                image_alt: 'example canvas',
                image_url: 'https://static-staging.group.app/posts/images/BdmxQX8MRV_kidos_testplan_post__pexels-ketut-subiyanto-4126724.jpg',
                image_link: '#',
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    image_border_radius: 1,
                    image_full_width: false,
                    image_width: null,
                    image_alignment: 'center',
                },
            });
        case PageBlockTypes.ImageText:
            return ({
                name: '',
                type: pageBlockType,
                image_alt: 'example canvas',
                image_url: 'https://static-staging.group.app/posts/images/84S4PhSn9K_kidos_testplan_post__pexels-fauxels-3184170.jpg',
                image_link: '#',
                heading: `<h1><strong>Example Text</strong></h1>`,
                heading_json: `{"blocks":[{"key":"e9rfm","text":"Example Text","type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                subtext: `<p>Use this Image with Text block to balance out your text content with a complementary visual to strengthen messaging and help your students connect with your product, course, or coaching. You can introduce yourself with a profile picture and author bio, showcase a student testimonial with their smiling face, or highlight an experience with a screenshot.</p>`,
                subtext_json: `{"blocks":[{"key":"91h6j","text":"Use this Image with Text block to balance out your text content with a complementary visual to strengthen messaging and help your students connect with your product, course, or coaching. You can introduce yourself with a profile picture and author bio, showcase a student testimonial with their smiling face, or highlight an experience with a screenshot.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                image_layout: 'left',
                button_shown: true,
                button_action: ButtonAction.URL,
                button_text: '[Set button action]',
                button_url: '#',
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 872,
                    text_alignment: 'center',
                    image_border_radius: 4,
                    image_width: null,
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',
                },
            });
        case PageBlockTypes.ImageTextColumn:
            return ({
                name: '',
                type: pageBlockType,
                image_shown: true,
                image_alt: 'example canvas',
                image_url: 'https://static-staging.group.app/posts/images/84S4PhSn9K_kidos_testplan_post__pexels-fauxels-3184170.jpg',
                image_link: '#',
                heading: `<h1><strong>Example Text</strong></h1>`,
                heading_json: `{"blocks":[{"key":"e9rfm","text":"Example Text","type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                subtext: `<p>Use this Image with Text block to balance out your text content with a complementary visual to strengthen messaging and help your students connect with your product, course, or coaching. You can introduce yourself with a profile picture and author bio, showcase a student testimonial with their smiling face, or highlight an experience with a screenshot.</p>`,
                subtext_json: `{"blocks":[{"key":"91h6j","text":"Use this Image with Text block to balance out your text content with a complementary visual to strengthen messaging and help your students connect with your product, course, or coaching. You can introduce yourself with a profile picture and author bio, showcase a student testimonial with their smiling face, or highlight an experience with a screenshot.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                image_layout: 'left',
                button_shown: true,
                button_action: ButtonAction.URL,
                button_text: '[Set button action]',
                button_url: '#',
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 872,
                    column_container_background_color: null,
                    column_container_padding_top: 20,
                    column_container_padding_bottom: 20,
                    column_container_padding_left: 20,
                    column_container_padding_right: 20,
                    column_container_border_radius: 1,
                    column_container_border_color: '#D5D8DF',
                    column_container_border_width: 1,
                    text_alignment: 'center',
                    image_border_radius: 4,
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',
                },
            });
        case PageBlockTypes.ImageGridText:
            return ({
                name: '',
                type: pageBlockType,
                blocks: [
                    {
                        type: pageBlockType,
                        attachment_type: 'image',
                        attachment_shown: true,
                        video_type: VideoType.Uplaod,
                        video_url: null,
                        file: null,
                        image_alt: 'example canvas',
                        image_url: 'https://static-staging.group.app/posts/images/BdmxQX8MRV_kidos_testplan_post__pexels-ketut-subiyanto-4126724.jpg',
                        image_link: '#',
                        heading: `<h2><strong>Example Text</strong></h2>`,
                        heading_json: `{"blocks":[{"key":"e9rfm","text":"Example Text","type":"header-two","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                        subtext: `<p>Use this block to showcase testimonials, features, categories, or more. Each column has its own individual text field. You can also leave the text blank to have it display nothing and just showcase an image.</p>`,
                        subtext_json: `{"blocks":[{"key":"91h6j","text":"Use this block to showcase testimonials, features, categories, or more. Each column has its own individual text field. You can also leave the text blank to have it display nothing and just showcase an image.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                        button_shown: true,
                        button_action: ButtonAction.URL,
                        button_text: '[Set button action]',
                        button_url: '#',
                    },
                    {
                        type: pageBlockType,
                        attachment_type: 'image',
                        attachment_shown: true,
                        video_type: VideoType.Uplaod,
                        video_url: null,
                        file: null,
                        image_alt: 'example canvas',
                        image_url: 'https://static-staging.group.app/posts/images/BdmxQX8MRV_kidos_testplan_post__pexels-ketut-subiyanto-4126724.jpg',
                        image_link: '#',
                        heading: `<h2><strong>Example Text</strong></h2>`,
                        heading_json: `{"blocks":[{"key":"e9rfm","text":"Example Text","type":"header-two","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                        subtext: `<p>Use this block to showcase testimonials, features, categories, or more. Each column has its own individual text field. You can also leave the text blank to have it display nothing and just showcase an image.</p>`,
                        subtext_json: `{"blocks":[{"key":"91h6j","text":"Use this block to showcase testimonials, features, categories, or more. Each column has its own individual text field. You can also leave the text blank to have it display nothing and just showcase an image.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                        button_shown: true,
                        button_action: ButtonAction.URL,
                        button_text: '[Set button action]',
                        button_url: '#',
                    },
                    {
                        type: pageBlockType,
                        attachment_type: 'image',
                        attachment_shown: true,
                        video_type: VideoType.Uplaod,
                        video_url: null,
                        file: null,
                        image_alt: 'example canvas',
                        image_url: 'https://static-staging.group.app/posts/images/BdmxQX8MRV_kidos_testplan_post__pexels-ketut-subiyanto-4126724.jpg',
                        image_link: '#',
                        heading: `<h2><strong>Example Text</strong></h2>`,
                        heading_json: `{"blocks":[{"key":"e9rfm","text":"Example Text","type":"header-two","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                        subtext: `<p>Use this block to showcase testimonials, features, categories, or more. Each column has its own individual text field. You can also leave the text blank to have it display nothing and just showcase an image.</p>`,
                        subtext_json: `{"blocks":[{"key":"91h6j","text":"Use this block to showcase testimonials, features, categories, or more. Each column has its own individual text field. You can also leave the text blank to have it display nothing and just showcase an image.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                        button_shown: true,
                        button_action: ButtonAction.URL,
                        button_text: '[Set button action]',
                        button_url: '#',
                    },
                ],
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    content_container_alignment: 'center',
                    text_alignment: 'center',
                    image_border_radius: 4,
                    image_width: null,
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',
                    single_container_background_color: '#FAFAFA',
                    single_container_border_color: '#FAFAFA',
                    single_container_border_width: 1,
                    single_container_border_radius: 4,
                    cards_per_row: 3,
                    gutter_height: 10,

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',
                },
            })
        case PageBlockTypes.Video:
            return ({
                name: '',
                type: pageBlockType,
                video_type: VideoType.Uplaod,
                video_url: null,
                file: null,
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                },
            });
        case PageBlockTypes.VideoText:
            return ({
                name: '',
                type: pageBlockType,
                video_type: VideoType.Uplaod,
                video_url: null,
                file: null,
                heading: `<h1><strong>Example Text</strong></h1>`,
                heading_json: `{"blocks":[{"key":"e9rfm","text":"Example Text","type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                subtext: `<p>Use this Video with Text block to balance out your text content with a complementary visual to strengthen messaging and help your students connect with your product, course, or coaching. You can introduce yourself with a profile picture and author bio, showcase a student testimonial with their smiling face, or highlight an experience with a screenshot.</p>`,
                subtext_json: `{"blocks":[{"key":"91h6j","text":"Use this Video with Text block to balance out your text content with a complementary visual to strengthen messaging and help your students connect with your product, course, or coaching. You can introduce yourself with a profile picture and author bio, showcase a student testimonial with their smiling face, or highlight an experience with a screenshot.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                video_layout: 'left',
                button_shown: true,
                button_action: ButtonAction.URL,
                button_text: '[Set button action]',
                button_url: '#',
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 872,
                    text_alignment: 'center',
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',
                },
            });
        case PageBlockTypes.Button:
            return ({
                name: '',
                type: pageBlockType,
                button_action: ButtonAction.URL,
                button_text: '[Set button action]',
                button_url: '#',
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',
                    button_alignment: 'center',
                },
            });
        case PageBlockTypes.Pricing:
            return ({
                name: '',
                type: pageBlockType,
                heading: `<h2><strong>Example Text</strong></h2>`,
                heading_json: `{"blocks":[{"key":"e9rfm","text":"Example Text","type":"header-two","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                subtext: '<p>Show different pricing options for your community resources.</p>',
                subtext_json: `{"blocks":[{"key":"bd1qb","text":"Show different pricing options for your community resources.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                button_shown: true,
                button_action: ButtonAction.URL,
                button_text: '[Set button action]',
                button_url: '#',
                blocks: [
                    {
                        type: pageBlockType,
                        title: 'Example Text',
                        text: 'Here you can add a description of your course/product or subscription plan.',
                        currency: 'usd',
                        price: '0',
                        period: PricePeriod.Month,
                        url: '#',
                    }
                ],
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    text_alignment: 'center',
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',
                    price_container_background_color: '#FFFFFF',
                    price_container_border_color: '#D5D8DF',
                    price_container_border_width: 1,

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',

                    //price heading properties
                    price_heading_font_family: 'Arial, sans-serif',
                    price_heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',

                    price_heading_font_size: 27,
                    price_heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size: 27,

                    price_heading_line_height: 35,
                    price_heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,

                    price_heading_color: '#000000',
                    price_heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',

                    //price subtext properties
                    price_subtext_font_family: 'Arial, sans-serif',
                    price_subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    price_subtext_font_size: 20,
                    price_subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    price_subtext_line_height: 25,
                    price_subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    price_subtext_color: '#000000',
                    price_subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //price text properties
                    price_text_font_family: 'Arial, sans-serif',
                    price_text_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',

                    price_text_font_size: 27,
                    price_text_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size: 27,

                    price_text_line_height: 35,
                    price_text_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,

                    price_text_color: '#000000',
                    price_text_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                },
            });
        case PageBlockTypes.Event:
            return ({
                name: '',
                type: pageBlockType,
                image_alt: 'example banner',
                image_url: null,
                event_title: '',
                heading: '<h1><strong>Example Event Title</strong></h1>',
                heading_json: ``,
                subtext: '<p><strong>Select an event to add it to your page.</strong></p>',
                subtext_json: ``,
                subtext_tag: 'p',
                button_shown: true,
                button_action: ButtonAction.URL,
                button_text: '[Set button action]',
                button_url: '#',
                design: {
                    container_background_color: '#1f1e1d',
                    container_padding_top: 120,
                    container_padding_bottom: 120,
                    content_container_max_width: 772,
                    image_opacity: 1,
                    text_alignment: 'center',
                    button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
                    button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
                    button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#FFFFFF',
                    heading_h1_color: '#FFFFFF',
                    heading_h2_color: '#FFFFFF',
                    heading_h3_color: '#FFFFFF',
                    heading_p_color: '#FFFFFF',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#FFFFFF',
                    subtext_h1_color: '#FFFFFF',
                    subtext_h2_color: '#FFFFFF',
                    subtext_h3_color: '#FFFFFF',
                    subtext_p_color: '#FFFFFF',
                },
            });
        case PageBlockTypes.CourseCurriculum:
            return ({
                name: '',
                type: pageBlockType,
                course_title: '',
                heading: `<h2><strong>Course Curriculum</strong></h2>`,
                heading_json: `{"blocks":[{"key":"e9rfm","text":"Course Curriculum","type":"header-two","depth":0,"inlineStyleRanges":[{"offset":0,"length":17,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                subtext: '<p>Select a course in your community and display the course outline.</p>',
                subtext_json: `{"blocks":[{"key":"bd1qb","text":"Select a course in your community and display the course outline.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                expanded: true,
                content: [],
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 872,
                    section_container_background_color: '#EDEEF0',
                    lesson_container_background_color: '#FFFFFF',
                    border_color: '#D5D8DF',
                    border_width: 1,
                    icon_color: '#767A8A',
                    text_alignment: 'center',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //subtext properties
                    subtext_font_family: 'Arial, sans-serif',
                    subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    subtext_font_size: 20,
                    subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    subtext_line_height: 25,
                    subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    subtext_color: '#666666',
                    subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
                    subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
                    subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
                    subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',

                    //section name properties
                    section_name_font_family: 'Arial, sans-serif',
                    section_name_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    section_name_font_size: 20,
                    section_name_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,
                    
                    section_name_line_height: 25,
                    section_name_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    section_name_color: '#000000',
                    section_name_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //lesson name properties
                    lesson_name_font_family: 'Arial, sans-serif',
                    lesson_name_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    lesson_name_font_size: 20,
                    lesson_name_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,
                    
                    lesson_name_line_height: 25,
                    lesson_name_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    lesson_name_color: '#000000',
                    lesson_name_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',
                }
            });
        case PageBlockTypes.FAQ:
            return ({
                name: '',
                type: pageBlockType,
                heading: `<h2><strong>Frequently Asked Questions</strong></h2>`,
                heading_json: ``,
                blocks: [
                    {
                        type: pageBlockType,
                        question: `<p>Use this text to write a question</p>`,
                        question_json: ``,
                        answer: `<p>Use this text to write an answer</p>`,
                        answer_json: ``,
                    },
                ],
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 772,
                    text_alignment: 'center',
                    question_container_background_color: '#EEF5FF',
                    question_container_border_color: '#D5D8DF',
                    question_container_border_width: 1,
                    question_container_icon_color: '#D5D8DF',
                    answer_container_background_color: '#FFFFFF',

                    //heading properties
                    heading_font_family: 'Arial, sans-serif',
                    heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    heading_font_size: 35,
                    heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    heading_line_height: 45,
                    heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    heading_color: '#000000',
                    heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //question text properties
                    question_font_family: 'Arial, sans-serif',
                    question_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    question_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    question_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    question_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    question_font_size: 20,
                    question_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    question_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    question_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    question_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    question_line_height: 25,
                    question_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    question_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    question_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    question_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    question_color: '#000000',
                    question_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    question_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    question_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    question_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

                    //answer text properties
                    answer_font_family: 'Arial, sans-serif',
                    answer_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
                    answer_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
                    answer_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
                    answer_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    answer_font_size: 20,
                    answer_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
                    answer_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
                    answer_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
                    answer_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    answer_line_height: 25,
                    answer_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
                    answer_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
                    answer_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
                    answer_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    answer_color: '#000000',
                    answer_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
                    answer_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
                    answer_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
                    answer_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',
                },
            });
        case PageBlockTypes.CountdownTimer:
            return ({
                name: '',
                type: pageBlockType,
                date: '',
                design: {
                    container_background_color: null,
                    container_padding_top: 20,
                    container_padding_bottom: 20,
                    content_container_max_width: 600,

                    date_font_family: 'Arial, sans-serif',
                    date_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',

                    date_font_size: 35,
                    date_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,

                    date_line_height: 45,
                    date_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,

                    date_color: '#333333',
                    date_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#333333',

                    date_label_font_family: 'Arial, sans-serif',
                    date_label_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

                    date_label_font_size: 20,
                    date_label_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

                    date_label_line_height: 25,
                    date_label_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

                    date_label_color: '#333333',
                    date_label_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#333333',
                },
            });
        default: 
            return ({});
    }
}

export const scrollElementIntoView = (elementId: string) => {
    const elementReference: HTMLElement = document.getElementById(elementId) as HTMLElement;

    if(elementReference) {
        elementReference.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
}

export const getDefaultPageFormData = (pageStyles: any) => {
    return ({
        type: WidgetType.Form,
        name: '',
        heading: '<h3><strong>Example Popup Title</strong></h3>',
        heading_json: ``,
        subtext: `<p>Set custom fields to collect page visitor's info</p>`,
        subtext_json: ``,
        submission_message: `<p>Thank you for your submission!</p>`,
        submission_message_json: ``,
        button_action: ButtonAction.None,
        button_text: '[Set button action]',
        button_url: '#',
        design: {
            text_alignment: 'center',
            container_background_color: '#FFFFFF',
            container_padding_top: 30,
            container_padding_bottom: 30,
            container_width: 500,
            close_icon_color: '#333333',
            button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
            button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
            button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',
            field_input_border_color: '#D5D8DF',
            field_input_label_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',
            field_input_label_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,
            field_input_label_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,
            field_input_label_color: pageStyles ? pageStyles.fonts.p.text_color : '#333333',
            field_input_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',
            field_input_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,
            field_input_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,
            field_input_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',
            field_input_height: 44,
            field_input_background_color: '#FFFFFF',
            field_input_placeholder_color: '#D1D1D1',
            field_input_required_color: `#FF0000`,

            //heading properties
            heading_font_family: 'Arial, sans-serif',
            heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
            heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
            heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
            heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

            heading_font_size: 35,
            heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
            heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
            heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
            heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

            heading_line_height: 45,
            heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
            heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
            heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
            heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

            heading_color: '#000000',
            heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
            heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
            heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
            heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

            //subtext properties
            subtext_font_family: 'Arial, sans-serif',
            subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
            subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
            subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
            subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

            subtext_font_size: 20,
            subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
            subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
            subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
            subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

            subtext_line_height: 25,
            subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
            subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
            subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
            subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

            subtext_color: '#666666',
            subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
            subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
            subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
            subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',

            //submission message properties
            submission_message_font_family: 'Arial, sans-serif',
            submission_message_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
            submission_message_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
            submission_message_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
            submission_message_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

            submission_message_font_size: 20,
            submission_message_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
            submission_message_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
            submission_message_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
            submission_message_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

            submission_message_line_height: 25,
            submission_message_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
            submission_message_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
            submission_message_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
            submission_message_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

            submission_message_color: '#333333',
            submission_message_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#333333',
            submission_message_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#333333',
            submission_message_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#333333',
            submission_message_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#333333',
        },
        fields: [
            {
                type: FormFieldTypes.Email,
                label: FormFieldTypes.Email,
                placeholder: FormFieldTypes.Email,
                is_required: true,
                error_message: "",
            }
        ]
    });
}

export const getDefaultSchedulerData = (pageStyles: any) => {
    return ({
        type: WidgetType.Scheduler,
        scheduler_type: SchedulerTypes.Calendly,
        scheduler_url: '',
        name: '',
        heading: '<h3><strong>Example Scheduler App Title</strong></h3>',
        heading_json: ``,
        subtext: `<p>Set description on the Schedular App widget</p>`,
        subtext_json: ``,
        button_action: ButtonAction.None,
        button_text: '[Set button action]',
        button_url: '#',
        design: {
            text_alignment: 'center',
            container_background_color: '#FFFFFF',
            container_padding_top: 30,
            container_padding_bottom: 30,
            container_width: 500,
            close_icon_color: '#333333',
            button_border_radius: pageStyles ? pageStyles.button.button_border_radius : 4,
            button_background_color: pageStyles ? pageStyles.button.button_background_color : '#EE8F00',
            button_text_color: pageStyles ? pageStyles.button.button_text_color : '#FFFFFF',

            //heading properties
            heading_font_family: 'Arial, sans-serif',
            heading_h1_font_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
            heading_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
            heading_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
            heading_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

            heading_font_size: 35,
            heading_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
            heading_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
            heading_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
            heading_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

            heading_line_height: 45,
            heading_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
            heading_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
            heading_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
            heading_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

            heading_color: '#000000',
            heading_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#000000',
            heading_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#000000',
            heading_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#000000',
            heading_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#000000',

            //subtext properties
            subtext_font_family: 'Arial, sans-serif',
            subtext_font_h1_family: pageStyles ? pageStyles.fonts.h1.font_family : 'Arial, sans-serif',
            subtext_h2_font_family: pageStyles ? pageStyles.fonts.h2.font_family : 'Arial, sans-serif',
            subtext_h3_font_family: pageStyles ? pageStyles.fonts.h3.font_family : 'Arial, sans-serif',
            subtext_p_font_family: pageStyles ? pageStyles.fonts.p.font_family : 'Arial, sans-serif',

            subtext_font_size: 20,
            subtext_h1_font_size: pageStyles ? pageStyles.fonts.h1.font_size : 35,
            subtext_h2_font_size: pageStyles ? pageStyles.fonts.h2.font_size : 31,
            subtext_h3_font_size: pageStyles ? pageStyles.fonts.h3.font_size : 27,
            subtext_p_font_size: pageStyles ? pageStyles.fonts.p.font_size : 20,

            subtext_line_height: 25,
            subtext_h1_line_height: pageStyles ? pageStyles.fonts.h1.line_height : 45,
            subtext_h2_line_height: pageStyles ? pageStyles.fonts.h2.line_height : 40,
            subtext_h3_line_height: pageStyles ? pageStyles.fonts.h3.line_height : 35,
            subtext_p_line_height: pageStyles ? pageStyles.fonts.p.line_height : 25,

            subtext_color: '#666666',
            subtext_h1_color: pageStyles ? pageStyles.fonts.h1.text_color : '#666666',
            subtext_h2_color: pageStyles ? pageStyles.fonts.h2.text_color : '#666666',
            subtext_h3_color: pageStyles ? pageStyles.fonts.h3.text_color : '#666666',
            subtext_p_color: pageStyles ? pageStyles.fonts.p.text_color : '#666666',
        },
    });
}