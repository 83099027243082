import React from 'react';
import './BunnyVideoPlayer.scss';
import { IBunnyVideoPlayerProps } from './IBunnyVideoPlayerProps';

const BunnyVideoPlayer: React.FC<IBunnyVideoPlayerProps> = (props) => {

  const getLibraryId = () => {
    return props.videoUrl.split("/")[4];
  }

  const getVideoId = () => {
    return props.videoUrl.split("/")[5];
  }

  return (
    <div 
    className="gp-bunny-video-player-container"
    >
    <iframe 
    src={`https://iframe.mediadelivery.net/embed/${getLibraryId()}/${getVideoId()}?autoplay=false`}
    //@ts-ignore
    loading="lazy" 
    className="gp-bunny-video-player-iframe" 
    allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" 
    allowFullScreen
    >
    </iframe>
    </div>
  )
}

export default BunnyVideoPlayer