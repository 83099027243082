import React from "react";
import "./Dropdown.scss";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useComponentVisible from "../../utils/visible/visible";
import PopOnMe from "elements/poponme";

const Dropdown: React.FC<any> = props => {
  const { 
    label, 
    actions, 
    data, 
    hideChevron, 
    disabled, 
    showMenuDots, 
    group, 
    useTippy, 
    commentsView, 
    tippyCompatibleActions, 
    analytics,
    hideTippyArrow,
    onClick,
    tippyTriggerStyles,
  } = props;

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const convertToTippyCompatibleActions = (): Array<any> => {
    return actions.map((action: any) => {
      return {
        label: action.label,
        callback: () => action.callback(data, action.value)
      }
    })
  }

  const [labelCalled, setLabelCalled] = React.useState<string | null>(actions[0].label)

  return (
    <div className="header-settings dropdown-menu" ref={ref}>
      {
        showMenuDots && useTippy && (
          <PopOnMe
          actions={!tippyCompatibleActions ? convertToTippyCompatibleActions(): actions}
          hideTippyArrow={hideTippyArrow}
          onClick={onClick}
          >
            <span 
            className = "menu-dots-container tippy"
            style={tippyTriggerStyles ? tippyTriggerStyles : undefined}
            >
              <img className = "menu-dots" src = {require("./dots.svg")}/>
            </span>
          </PopOnMe>
        )
      }
      {showMenuDots && !useTippy && (
        <span className = "menu-dots-container" onClick={() => setIsComponentVisible(true)}>
          <img className = "menu-dots" src = {require("./dots.svg")}/>
        </span>
       )}
      {!showMenuDots && (
        <button
          disabled={disabled}
          type="button"
          className={`${label ? `btn btn-secondary blue ${analytics && 'dropdown-label'}` : "btn btn-dots"} ${commentsView && "comments-view"}`}
          onClick={() => setIsComponentVisible(true)}
        >
          {label && <span >{label}: {analytics && labelCalled}</span>}
          {!label && <span />}
          {!label && <span />}
          {!label && <span />}
          {!hideChevron && <FontAwesomeIcon icon={faChevronDown} />}
        </button>
      )}
      {isComponentVisible && (
        <ul
          className={`group-dropdown dropdown flex flex-direction-column ${actions[3] &&
            actions[3].label === "Export Data " &&
            group.active_subscription.name === "starter" &&
            "width"}`}
        >
          {actions.map((action: any, index: any) => {
            return (
              <li
                key={index}
                className={action && action.hide ? "hide-link" : ""}
              >
                <button
                  onClick={() => {
                    action.callback(data, action.value);
                    setIsComponentVisible(false);
                    setLabelCalled(action.label)
                  }}
                  type="button"
                  className="btn btn-top-bar"
                >
                  {action.label === "Export Data " &&
                  group.active_subscription.name === "starter" &&
                  "Upgrade" ? (
                    <span>{action.label}</span>
                  ) : (
                    <span>{action.label}</span>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;