import React from 'react';
import { IButtonBlockPreviewProps } from './IButtonBlockPreviewProps';
import { ButtonAction, scrollElementIntoView } from 'containers/PageBuilder/SinglePageContainer/domain';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import styled from 'styled-components';
import BlockButton from '../BlockComponents/BlockButton';

const ButtonBlockContentContainer: any = styled(BlockContentContainer)`
    flex-direction: row;
    justify-content: ${(props: any) => 
        props.design.button_alignment === 'left' ?
        'flex-start' :
        props.design.button_alignment === 'right' ?
        'flex-end' :
        props.design.button_alignment
    };
`;

const ButtonBlockButton: any = styled(BlockButton)`
    margin-top: 0px;
    width: fit-content;
`;

const ButtonBlockPreview: React.FC<IButtonBlockPreviewProps> = (props) => {

  const ctaOnClick = () => {
    if(props.buildingPreview) {
        return;
    }

    if(
        props.blockData &&
        (
            props.blockData.button_action === ButtonAction.URL ||
            props.blockData.button_action === ButtonAction.URLTab
        ) &&
        props.blockData.button_url
    ) {
        window.open(
            props.blockData.button_url, 
            props.blockData.button_action === ButtonAction.URLTab ?
            '_blank' :
            '_self'
        );
        return;
    }

    if(
        props.blockData &&
        props.blockData.button_action === ButtonAction.Scroll &&
        props.blockData.button_url &&
        props.containerBaseIdName
    ) {
        scrollElementIntoView(`${props.containerBaseIdName}${props.blockData.button_url}`);
        return;
    }

    if(
        props.blockData &&
        props.blockData.button_action === ButtonAction.Popup &&
        props.blockData.button_url
    ) {
        let nextPageFormsOpenState = JSON.parse(JSON.stringify(props.pageFormsOpenState));

        nextPageFormsOpenState[props.blockData.button_url] = true;

        props.setPageFormsOpenState(nextPageFormsOpenState);
    }
  }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <ButtonBlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
            <ButtonBlockButton
            design={props.blockData.design}
            pageStyles={props.pageStyles}
            onClick={ctaOnClick}
            >
                {props.blockData.button_text}
            </ButtonBlockButton>
        </ButtonBlockContentContainer>
    </BlockContainer>
  )
}

export default ButtonBlockPreview;