import databaseInstance from "api";

const dbInstance: any = databaseInstance;

export const getLivePage = async (params: any) => {
    const { queryKey } = params;
    const [, {
        groupId,
        pageSlug
    }] = queryKey;

    if (!groupId) {
        throw new Error("Community Id Unavailable");
    }

    try {
        const response: any = await dbInstance.get(`/groups/${groupId}/pages/${pageSlug}/live`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}