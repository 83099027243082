import React, { useEffect, useState } from "react";

import ContentLoader from "react-content-loader";
import "./WelcomePageComponent.scss";
import { PlusWithCircle } from "../../constants/icons";
import { IWelcomePageComponentProps } from "./WelcomePageComponentProps";
import { globalActions, groupActions } from "../../store/actions";
import { getTranslate } from "react-localize-redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
// import { IWelcomePageComponentState } from "./WelcomePageComponentState";
// import { GroupService } from "../../api/services/groups";
import GroupCard from "../../components/group/groupListCard";
import { push } from "connected-react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollSpinner from "components/scrollSpinner";
import { GroupService } from "../../api/services/groups";

const WelcomePageComponent: React.FC<IWelcomePageComponentProps> = props => {
  const [groups, setGroupsData] = useState<{
    data: [];
    total: 0;
    next_page_url: "";
  }>({
    data: [],
    total: 0,
    next_page_url: ""
  });
  const [contentLoader, setContentLoader] = useState<boolean>(false);
  // load more groups with infinity scroll component
  function loadMoreGroups() {
    return GroupService.getInstance()
      .loadMore(groups.next_page_url, 30, "")
      .then((response: any) => {
        // update group list
        const groupList: any = [...groups.data, ...response.data];
        // create copy of current group data
        const _groupData: any = { ...groups };
        // re-assign updated group datalist
        _groupData.data = groupList;
        _groupData.next_page_url = response.next_page_url;
        _groupData.per_page = response.per_page;
        _groupData.current_page = response.current_page;
        setGroupsData(_groupData);
      });
  }
  useEffect(() => {
    setContentLoader(true);
    // @ts-ignore
    setGroupsData(props.userGroups);
    setTimeout(() => setContentLoader(false), 1000);
    }, []);
    
  // function refreshGroups() {
  //   setContentLoader(true);
  //   GroupService.getInstance()
  //     .getRecommendedGroups()
  //     .then((response: any) => {
  //       setGroupsData(response.data);
  //       setTimeout(() => setContentLoader(false), 1000);
  //     });
  // }

  const CardLoader = () => {
    return (
      <ContentLoader
        height={570}
        width={550}
        speed={1}
        primaryColor="#dbdbdb"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="550" height="120" />
        <rect x="0" y="150" rx="3" ry="3" width="550" height="120" />
        <rect x="0" y="300" rx="3" ry="3" width="550" height="120" />
        <rect x="0" y="450" rx="3" ry="3" width="550" height="120" />
      </ContentLoader>
    );
  };

  return (
    <div className="content-page flex flex-justify-center">
      <div className="content-page-wrap">
        <div className={`card card-welcome ${contentLoader && 'card-welcome-loading'}`}>
          <h3>Welcome to GroupApp</h3>
          <span>Launch your community or create a new community</span>
          <button
            className={"btn btn-create-group"}
            onClick={() => {
              props.createGroup();
            }}
          >
            <PlusWithCircle />
            <span>Create community</span>
          </button>
        </div>
        <InfiniteScroll
          scrollableTarget={"scroll"}
          dataLength={groups.data.length}
          next={() => loadMoreGroups()}
          hasMore={groups.next_page_url ? true : false}
          loader={<ScrollSpinner label={"Loading..."} />}
        >
          {contentLoader ? (
            <CardLoader />
          ) : (
            groups.data.map((groupMembership: any) => {
              return (
                <GroupCard
                  key={groupMembership.id}
                  // onGroupRequest={refreshGroups}
                  group={groupMembership.group}
                />
              );
            })
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IWelcomePageComponentProps
) => {
  return {
    getGroups: () => {
      dispatch(globalActions.showTopLoading());
      dispatch(groupActions.dbGetAll());
    },
    createGroup: () => {
      dispatch(push("/creategroup"));
    }
  };
};

const mapStateToProps = (state: any, ownProps: IWelcomePageComponentProps) => {
  const uid: string = state.getIn(["authorize", "uid"]) as string;
  return {
    translate: getTranslate(state.get("locale")),
    uid
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WelcomePageComponent as any) as any
) as any;
