import React from 'react'

import './label.scss'
import { Link } from 'react-router-dom'

const EmailWhitelabelingSuccess = () => {
    return (
        <div className='container'>
            <div className='label-closed-container'>
                <span className='label-closed-container-header'>
                    {
                        window.location.pathname === '/community-email-verification-failure' ?
                            'Community email has not been verified'
                            :
                            'Community email has now been verified'
                    }
                </span>
                <span className='label-closed-container-subtext'>
                    {
                        window.location.pathname === '/community-email-verification-failure' ?
                            'Please try again.'
                            :
                            ' The status on your email settings page may take 5 to 10 minutes to update.'
                    }
                </span>
                <Link to='/'>
                    <span className='label-closed-container-link'>
                        Return to dashboard.
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default EmailWhitelabelingSuccess