// @ts-ignore
import { useState, useRowSelect, useTable } from "react-table";
import React, { useEffect } from "react";
import "./GTable.scss";
import Dropdown from "../dropdown";
import { Link } from "react-router-dom";
import miscUtils from "utils/miscUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import ScrollSpinner from "components/scrollSpinner";

function GTable({
  columns,
  data,
  total,
  actions,
  additionalOptions,
  selectionChanged,
  hideActions,
  classes,
  tableType,
  section,
  membersCounter,
  headerCounter,
  group,
  subdomain,
  customCounter,
  hideRowActions,
  exportData,
  useTippy,
  hideTableHeader,
  adminMembersHasMore
}: any) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    state: [{ selectedRows }]
    // @ts-ignore
  } = useTable<any>({ columns, data }, useRowSelect);

  const [adminScrollEnd, setAdminScrollEnd] = React.useState<boolean>(false);

  useEffect(() => {
    if (selectionChanged) {
      selectionChanged(selectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    const adminScrollContainer: HTMLElement = document.getElementById("admin-members-scrollarea") as HTMLElement;
    if(adminScrollContainer) {
      adminScrollContainer.addEventListener('scroll', () => {
        if(
          (adminScrollContainer.scrollHeight - adminScrollContainer.scrollTop === adminScrollContainer.clientHeight)
          ) {
            setAdminScrollEnd(true);
        }
      });
      adminScrollContainer.addEventListener('resize', () => {
        setAdminScrollEnd(false);
      });
    }
  }, []);

  const headerIsSticky = () => {
    return window.location.pathname.includes("members");
  }

  return (
    <>
    <div className={tableType ? "group-table" : "group-table-grid"}>
      <div className={`table-header ${headerIsSticky() ? "table-header--sticky": ""}`} style={hideTableHeader ? {display: 'none'} : {}}>
        <div className="flex">
          <div className="group-pagination">
            <span className="selected">
              {!hideActions && (
                <span className="selected">
                  {`${
                    !hideActions ? selectedRows.length : data.length
                  } ${section} `}{" "}
                  <span>{`of ${total}`}</span>
                </span>
              )}
            </span>
          </div>
          <div className="table-actions">
            {
              section === "Channels" ? (
                !hideActions && !miscUtils.isModerator(group.user_membership) &&
                <Dropdown
                label="Options"
                data={selectedRows}
                actions={actions}
                group={group}
              />
              ) : 
              (
                !hideActions &&
                <Dropdown
                label="Options"
                data={selectedRows}
                actions={actions}
                group={group}
              />
              )
            }
            {additionalOptions &&
              additionalOptions.length &&
              additionalOptions.map((adOption: any, index: any) => {
                if (!adOption.hide) {
                  return (
                    <button
                      key={index}
                      type="button"
                      className="btn btn-secondary blue"
                      onClick={() => adOption.callback(selectedRows[0])}
                    >
                      {adOption.label}
                    </button>
                  );
                }
                return null;
              })}
          </div>
        </div>
        {window.location.pathname.includes("members") && false && (
          <div className="gtable-actions-container">
            {
              !miscUtils.isModerator(group.user_membership) &&
              <div 
              className="group-members-export-data"
              onClick={exportData}
              >
                <span>
                <FontAwesomeIcon icon={faFileExcel} color="#365de6" />
                </span>
                <p>Export Data</p>
              </div>
            }
            <Link
            to={`${
              subdomain
                ? "/settings/members/invite"
                : `/group/${group.slug}/settings/members/invite`
            }`}
            className="invite-members-button"
          >
            Invite Members
          </Link>
          </div>
        )}
      </div>
      <div className={headerIsSticky() ? "admin-members-scrollarea" : ""} id="admin-members-scrollarea">
      <table {...getTableProps()} className={[...classes]}>
        <thead>
          {headerGroups.map((headerGroup: any, i: any) => {
            return (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, i: number) => {
                  if (!column.restricted) {
                    return (
                      <th key={i} {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    );
                  }
                  return null;
                })}
                {hideRowActions === false && (
                  <th key={headerGroup.headers.length}>
                    
                  </th>
                )}
                {(headerCounter && !customCounter) && <th> {membersCounter} members selected</th>}
                {customCounter && (
                  <th>{customCounter} </th>
                )}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {rows.map((row: any, i: number) => {
            return (
              prepareRow(row) || (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    if (!cell.column.restricted) {
                      return (
                        <td key={i} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                    return null;
                  })}
                  {hideRowActions === false && 
                    <td className = "row-actions">
                      <Dropdown
                        label="Options"
                        data={row.original}
                        actions={row.original.actions}
                        showMenuDots = {true}
                        group={group}
                        useTippy={useTippy}
                      />
                    </td>
                  }
                </tr>
              )
            );
          })}
        </tbody>
      </table>
      {
        headerIsSticky() && adminScrollEnd && adminMembersHasMore && <ScrollSpinner label={"Loading..."} />
      }
      </div>
    </div>
    </>
  );
}

export default GTable;
