import React from "react";
import ContentLoader from "react-content-loader";

const MobileChatMesLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={476}
            height={233}
            viewBox="0 0 476 233"
        >
            <rect x="40" y="36" rx="3" ry="3" width="53" height="6" />
            <circle cx="23" cy="44" r="10" />
            <rect x="40" y="47" rx="3" ry="3" width="130" height="6" />
            <rect x="40" y="82" rx="3" ry="3" width="53" height="6" />
            <circle cx="23" cy="90" r="10" />
            <rect x="40" y="93" rx="3" ry="3" width="130" height="6" />
            <rect x="40" y="128" rx="3" ry="3" width="53" height="6" />
            <circle cx="23" cy="136" r="10" />
            <rect x="40" y="139" rx="3" ry="3" width="130" height="6" />
            <rect x="40" y="172" rx="3" ry="3" width="53" height="6" />
            <circle cx="23" cy="180" r="10" />
            <rect x="40" y="183" rx="3" ry="3" width="130" height="6" />
            <rect x="40" y="215" rx="3" ry="3" width="53" height="6" />
            <circle cx="23" cy="223" r="10" />
            <rect x="40" y="226" rx="3" ry="3" width="130" height="6" />
            <rect x="40" y="260" rx="3" ry="3" width="53" height="6" />
            <circle cx="23" cy="267" r="10" />
            <rect x="40" y="271" rx="3" ry="3" width="53" height="6" />
            <rect x="40" y="298" rx="3" ry="3" width="130" height="6" />
            <circle cx="23" cy="305" r="10" />
            <rect x="40" y="309" rx="3" ry="3" width="130" height="6" />
        </ContentLoader>
    )
};

export default MobileChatMesLoader;
