import React, { useEffect, useState } from 'react'

import './topBarMessages.scss'
import { ReactComponent as ChatIcon } from 'assets/img/chatIcon.svg';
import { ReactComponent as NochatsIcon } from "containers/messaging/images/chat.svg";
import miscUtils from 'utils/miscUtils';

import anchorme from 'anchorme';
import ReactHtmlParser from "react-html-parser"
import Moment from 'react-moment';
import NewMessageMenu from 'containers/messaging/NewMessageMenu';
import UserComponent from 'components/userComponent';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { TopBarPopUpsAtom, chatsAtom, disableActiveChatSelectionAtom, showActiveCHatAtom, showMessagePopUpAtom } from 'showMessageMenuAtom';
import { useAtom } from 'jotai';
import appConfig from 'appConfig';
import Echo from 'laravel-echo';
import { TopBarMessageLoader } from 'components/contentLoaders';
import { setCookieValue } from 'utils/cookieStorage';
import { DirectChatsContext } from 'containers/main/MainComponent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MessagingService } from 'api/services/messaging';
import ScrollSpinner from 'components/scrollSpinner';


const TobBarMessages = (
    {
        theme,
        group,
        getCommunityUnread,
        isLoading,
        lectureView,
    }
        :
        {
            theme: any,
            group: any,
            getCommunityUnread: () => void
            isLoading: boolean,
            lectureView: boolean,
        }
) => {

    const pusherOptions = {
        key: appConfig.pusher.key,
        cluster: appConfig.pusher.cluster,
        broadcaster: appConfig.pusher.broadcaster,
        forceTLS: appConfig.pusher.forceTLS,
        encrypted: appConfig.pusher.encrypted,
        authEndpoint: appConfig.pusher.authEndpoint,
        auth: appConfig.pusher.auth
    };

    const { getDirectChats, memberCommunityUnreadCount } = React.useContext(DirectChatsContext)

    const [isOpen, setIsOpen] = useState(false);
    const [toggleInbox, setToggleInbox] = useState<boolean>(true);
    const [toggleUnread, setToggleUnread] = useState<boolean>(false);
    const [echo, setEcho] = useState<any>();

    const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);
    const [, setActiveChatroom] = useAtom(showActiveCHatAtom);
    const [, setIsShowActiveChatSelection] = useAtom(disableActiveChatSelectionAtom);
    const [topBarPopUps, setTopBarPopUps] = useAtom(TopBarPopUpsAtom);

    const body = document.body;

    const openDialog = () => {
        body.style.overflow = 'hidden';
        setIsOpen(!isOpen);
        getCommunityUnread()
        setToggleInbox(true)
        setToggleUnread(false)
        setTopBarPopUps({
            message: true,
            notification: false
        })
    };

    useEffect(() => {
        const handleCloseDialog = (event: MouseEvent) => {
            const hideDialogEl = document.getElementById('mes-dialog') as HTMLDialogElement;
            const dialogDimensions = hideDialogEl.getBoundingClientRect();

            if (
                event.clientX < dialogDimensions.left ||
                event.clientX > dialogDimensions.right ||
                // event.clientY < dialogDimensions.top ||
                event.clientY > dialogDimensions.bottom
            ) {
                setIsOpen(false);
                body.style.overflow = 'auto';
            }
        };

        document.addEventListener('mousedown', handleCloseDialog);

        return () => {
            document.removeEventListener('mousedown', handleCloseDialog);
        };
    }, [isOpen]);


    function initEcho() {
        return new Echo(pusherOptions);
    }

    useEffect(() => {
        if (!echo) {
            const echoObj = initEcho();
            setEcho(echoObj);
        }
        if (isOpen) {
            getDirectChats()
            getCommunityUnread()
        }
    }, [isOpen])

    function isMobileView() {
        return window.screen.width < 520;
    }

    const mesPopUpOpen = isOpen && topBarPopUps.message
    const [directChats, setCommunityChats] = useAtom(chatsAtom) as any;

    function getIndividualChatMembers(chat: any) {
        try {
            const encounteredIds = new Set();
            const individualChat = chat.members.filter((member: any) => {
                if (
                    member.community_member &&
                    (member.community_member.user !== null && member.community_member.user !== undefined)
                ) {
                    const memberId = member.community_member.id;
                    if (!encounteredIds.has(memberId)) {
                        encounteredIds.add(memberId);
                        return true;
                    }
                }
            });

            return individualChat;
        } catch (error) {
            // Handle the error
            console.error(error);
        }
    }

    const loadMoreChats = async () => {
        try {
            const chatrooms: any = await MessagingService.getInstance().loadMoreChatrooms(directChats && directChats.chatrooms.next_page_url);
            const sortedChats = chatrooms && chatrooms.data.filter((chat: any) => {
                if (chat.type === "group") {
                    return chat.members.length >= 2;
                } else if (chat.type === "individual") {
                    const individualChat = getIndividualChatMembers(chat);
                    if (individualChat && individualChat.length > 1) {
                        return true;
                    }
                }
            })
            setCommunityChats(
                {
                    data: [...directChats.data, ...sortedChats],
                    chatrooms
                },
            )
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <div className={`top-bar-mes-container ${lectureView ? 'top-bar-mes-container--lecture' : ''}`}>
            <div
                onClick={openDialog}
                style={{ color: (theme && theme.custom) ? theme.custom.fontColor : '#666' }}
                className='mes-icon'
            >
                <ChatIcon />
                {memberCommunityUnreadCount > 0 && (
                    <span className='chats-count'>{memberCommunityUnreadCount}</span>
                )}
            </div>
            <dialog id='mes-dialog' className='mes-dialog' open={mesPopUpOpen}>
                <div className='mes-dialog-header'>
                    <div className="mes-dialog-header-mes-menu">
                        <NewMessageMenu
                            setIsEditGroupchat={() => true}
                            theme={theme}
                            setIsShowChatroom={() => true}
                            group={group}
                        />
                    </div>
                    <InboxUnread
                        setToggleInbox={setToggleInbox}
                        setToggleUnread={setToggleUnread}
                        toggleInbox={toggleInbox}
                        toggleUnread={toggleUnread}
                        memberCommunityUnreadCount={memberCommunityUnreadCount}
                    />
                </div>

                {directChats && directChats.data && (directChats.data.length === 0 || directChats.data === null) && memberCommunityUnreadCount === 0 && !isLoading &&
                    <div className='mes-dialog-chats-no-messages'>
                        <span
                            style={{ color: (theme && theme.custom) && theme.custom.fontColor }}
                            className='icon'
                        >
                            <NochatsIcon />
                        </span>
                        <span className='heading'>No messages</span>
                        <span className='sub-heading'>Send a message</span>
                    </div>
                }
                {isLoading ?
                    <div>
                        {Array.from({ length: 5 }, (_, index) => (
                            <TopBarMessageLoader key={index} />
                        ))}
                    </div>
                    :
                    <div className="mes-dialog-chats" id='mes-dialog-chats'>
                        {directChats && directChats.data.length > 0 &&
                            <InfiniteScroll
                                scrollableTarget="mes-dialog-chats"
                                dataLength={directChats.data.length}
                                next={() => loadMoreChats()}
                                hasMore={directChats.chatrooms.next_page_url}
                                loader={toggleInbox && <ScrollSpinner label="Loading more messages" />}
                            >
                                {directChats.data.map((chat: any, index: any) => (
                                    <ChatComponent
                                        key={index}
                                        chat={chat}
                                        setIsOpen={setIsOpen}
                                        setActiveChatroom={setActiveChatroom}
                                        setOpenMessagePopUp={setOpenMessagePopUp}
                                        getCommunityUnread={getCommunityUnread}
                                        setToggleUnread={setToggleUnread}
                                        isMobileView={isMobileView}
                                        setCookieValue={setCookieValue}
                                        setIsShowActiveChatSelection={setIsShowActiveChatSelection}
                                        toggleUnread={toggleUnread}
                                        toggleInbox={toggleInbox}
                                        directChats={directChats && directChats.data}
                                        group={group} />
                                ))}
                            </InfiniteScroll>
                        }

                        {directChats && directChats.data.length > 0 &&
                            <div className='see-all'>
                                <span
                                    className='see-all-text'
                                    onClick={() => {
                                        setActiveChatroom(directChats.data[0].id)
                                        setOpenMessagePopUp(true)
                                        setIsOpen(false);
                                        if (isMobileView()) {
                                            localStorage.removeItem("isShowChatroom")
                                            setIsShowActiveChatSelection(false)
                                        }
                                    }}
                                >
                                    View all messages
                                </span>
                            </div>
                        }

                        {toggleUnread && memberCommunityUnreadCount === 0 &&
                            directChats && directChats.data && directChats.data.length > 0 &&
                            <div className='no-messages'>
                                <span className='no-messages-text'>No unread direct messages.</span>
                            </div>
                        }
                    </div>
                }
            </dialog>
        </div >
    );
}

export default TobBarMessages


const Dms = (
    {
        chat,
        toggleUnread,
        toggleInbox,
        member,
    }:
        {
            chat: any,
            directChats: any,
            toggleUnread: boolean,
            toggleInbox: boolean
            member?: any
        }
) => {

    return (
        <div>
            {(
                toggleInbox || (toggleUnread && chat.unread_messages_count > 0)
            )
                &&
                <div className='mes-dialog-chats-body'>
                    {
                        chat.type === 'individual' && member && member.community_member && member.community_member.user &&
                        <div>
                            {member && member.community_member.user.profile_image ?
                                <div className="mes-dialog-chats-body-image">
                                    <img
                                        src={member.community_member.user.profile_image}
                                        alt="Community Member Profile"
                                        className='profile-image'
                                    />
                                </div>
                                :
                                <div
                                    data-color={miscUtils.getColorInitial(member.community_member.user.full_name)}
                                    className="profile-no-image"
                                >
                                    {miscUtils.getNameInitials(member.community_member.user.full_name)}
                                </div>
                            }
                        </div>
                    }
                    {
                        chat.type === 'group' &&
                        <GroupProfileImages
                            chat={chat}
                        />
                    }

                    {
                        chat.type === 'group' &&
                        <div className='mes-dialog-chats-body-wrapper'>
                            <div className='message-timestamp'>
                                <div className='recipient-name'>
                                    {chat.title}
                                </div>

                                <div className="recipient-timestamp">
                                    <Moment
                                        utc={true}
                                        fromNow={true}
                                        ago={true}
                                    >
                                        {chat.updated_at}
                                    </Moment>
                                    <span>{""} ago</span>
                                </div>
                            </div>
                            {
                                chat.latest_message ?
                                    <div className="message-content-count">
                                        {
                                            chat.unread_messages_count > 0
                                                ?
                                                <span className='unread-message-content'>
                                                    {ReactHtmlParser(anchorme(chat.latest_message.message))}
                                                </span>
                                                :
                                                <span className='message-content'>
                                                    {ReactHtmlParser(anchorme(chat.latest_message.message))}
                                                </span>
                                        }
                                        {
                                            chat.unread_messages_count > 0 &&
                                            <div className='unread-messages-container'></div>
                                        }
                                    </div>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                    }

                    {
                        chat.type === 'individual' &&
                        member &&
                        member.community_member &&
                        member.community_member.user &&

                        <div className='mes-dialog-chats-body-wrapper'>
                            <div className='message-timestamp'>
                                <div className='recipient-name'>
                                    {member.community_member.user.full_name}
                                </div>

                                    <div className="recipient-timestamp">
                                        <Moment
                                            utc={true}
                                            fromNow={true}
                                            ago={true}
                                        >
                                            {chat.updated_at}
                                        </Moment>
                                        <span>{""} ago</span>
                                    </div>
                                </div>
                                {
                                    chat.latest_message ?
                                        <div className="message-content-count">
                                            {
                                                chat.unread_messages_count > 0
                                                    ?
                                                    <span className='unread-message-content'>
                                                        {ReactHtmlParser(anchorme(chat.latest_message.message))}
                                                    </span>
                                                    :
                                                    <span className='message-content'>
                                                        {ReactHtmlParser(anchorme(chat.latest_message.message))}
                                                    </span>
                                            }
                                            {
                                                chat.unread_messages_count > 0 &&
                                                <div className='unread-messages-container'></div>
                                            }
                                        </div>

                                    :
                                    <>
                                    </>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}


const GroupProfileImages = ({ chat }: { chat: any, }) => {

    const { membersRef } = React.useContext(DirectChatsContext)
    const membersCount = membersRef.current !== null && chat.id === membersRef.current.chatroomID && membersRef.current.members_count
    const sortedMembers = chat.members.length > 0 && chat.members.sort((a: any, b: any) => a.id - b.id);

    return (
        <ul className="group-profile-pic">
            {sortedMembers.slice(0, 2).map((member: any, index: any) => (
                <li key={index}>
                    <UserComponent
                        key={chat.id}
                        hideAdmin={true}
                        user={member.community_member.user}
                        hideName={true}
                        profilePicture={member.community_member.user && member.community_member.user.profile_image}
                        showProfileSlider={false}
                    />
                </li>
            ))}
            {chat.members_count > 2 && (
                <li className="message-plus-members">
                    <UserComponent
                        key={chat.id}
                        hideAdmin={true}
                        user={chat.members[chat.members.length - 1].community_member.user}
                        hideName={true}
                        profilePicture={chat && chat.members && chat.members.length > 0 && chat.members[chat.members.length - 1].community_member && chat.members[chat.members.length - 1].community_member.user && chat.members[chat.members.length - 1].community_member.user.profile_image}
                        showProfileSlider={false}
                    />
                    <span className="member-count">+{membersCount ? membersCount - 2 : chat.members_count - 2}</span>
                </li>
            )}
        </ul>
    )
}

React.memo(GroupProfileImages);


const InboxUnread = (
    {
        setToggleInbox,
        setToggleUnread,
        toggleInbox,
        toggleUnread,
        memberCommunityUnreadCount,
    }:
        {
            setToggleInbox: (arg0: boolean) => void,
            setToggleUnread: (arg0: boolean) => void,
            toggleInbox: boolean,
            toggleUnread: boolean,
            memberCommunityUnreadCount: number
        }
) => {

    return (
        <div className='mes-dialog-header-bottom'>
            <span
                className={`mes-dialog-header-bottom-inbox ${toggleInbox && 'active'}`}
                onClick={() => {
                    setToggleInbox(true);
                    setToggleUnread(false);
                }}
            >
                Inbox
            </span>
            <span
                className={`mes-dialog-header-bottom-unread ${toggleUnread && 'active'}`}
                onClick={() => {
                    setToggleInbox(false);
                    setToggleUnread(true);
                }}
            >
                Unread {(memberCommunityUnreadCount > 0) && `(${memberCommunityUnreadCount})`}
            </span>
        </div>
    )
}


const ChatComponent = (
    {
        chat,
        setIsOpen,
        setActiveChatroom,
        setOpenMessagePopUp,
        getCommunityUnread,
        setToggleUnread,
        isMobileView,
        setCookieValue,
        setIsShowActiveChatSelection,
        toggleUnread,
        toggleInbox,
        directChats,
        group
    }:
        {
            chat: any
            setIsOpen: any
            setActiveChatroom: any
            setOpenMessagePopUp: any
            getCommunityUnread: any
            setToggleUnread: any
            isMobileView: any
            setCookieValue: any
            setIsShowActiveChatSelection: any
            toggleUnread: any
            toggleInbox: any
            directChats: any
            group: any
        }

) => {
    if (chat.type === 'group') {
        return (
            <span
                key={chat.updated_at}
                onClick={() => {
                    setIsOpen(false);
                    setActiveChatroom(chat.id);
                    setOpenMessagePopUp(true);
                    document.body.style.overflow = 'hidden';

                    if (chat.unread_messages_count > 0) {
                        getCommunityUnread();
                        setToggleUnread(false);
                    }
                    if (isMobileView()) {
                        localStorage.setItem("isShowChatroom", "yes");
                        setIsShowActiveChatSelection(true);
                    }
                }}
                className='mes-open'
            >
                {(toggleUnread || toggleInbox) && (
                    <Dms
                        chat={chat}
                        directChats={directChats}
                        toggleInbox={toggleInbox}
                        toggleUnread={toggleUnread}
                    />
                )}
            </span>
        );
    } else if (chat.type === 'individual') {
        const groupMembership = group && group.user_membership && group.user_membership.id;

        const memberCanChat = chat.members.find((chatMember: any) => {
            if (
                chatMember.community_member &&
                (chatMember.community_member.user !== null && chatMember.community_member.user !== undefined) &&
                (chatMember.community_member && chatMember.community_member.id !== groupMembership)
                // &&
                // chatMember.community_member.can_chat
            ) {
                return true;
            }
        });

        return (
            <span
                key={chat.updated_at}
                onClick={() => {
                    setIsOpen(false);
                    setActiveChatroom(chat.id);
                    setOpenMessagePopUp(true);

                    if (chat.unread_messages_count > 0) {
                        getCommunityUnread();
                        setToggleUnread(false);
                    }
                    if (isMobileView()) {
                        localStorage.setItem("isShowChatroom", "yes");
                        setIsShowActiveChatSelection(true);
                    }
                }}
                className='mes-open'
            >
                {(toggleUnread || toggleInbox) && (
                    <Dms
                        chat={chat}
                        directChats={directChats}
                        toggleInbox={toggleInbox}
                        toggleUnread={toggleUnread}
                        member={memberCanChat}
                    />
                )}
            </span>
        );
    }

    return null;
};