import React from 'react'
import ContentLoader from 'react-content-loader';

const TableLoader = () => {
    return (
        <ContentLoader
            height={40}
            width={1060}
            speed={2}
            primaryColor="#d9d9d9"
            secondaryColor="#ecebeb"
        >
            <rect x="30" y="13" rx="4" ry="4" width="12" height="12" />
            <rect x="64" y="13" rx="6" ry="6" width={200} height="12" />
            <rect x="485" y="13" rx="6" ry="6" width={87} height="12" />
            <rect x="685" y="13" rx="6" ry="6" width={87} height="12" />
            <rect x="868" y="13" rx="6" ry="6" width={87} height="12" />

            <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
        </ContentLoader>
    );
}

export default TableLoader