import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader';

import './custom-details.scss'
import { ReactComponent as FormIcon } from 'assets/img/form-outline.svg'

import ReadMore from 'utils/readMore/readMore'
import { AdminMemberDetailsContext } from '../AdminMemberDetails';
import { toast, ToastType } from 'react-toastify';
import { ReactComponent as CopyIcon } from "assets/img/event-copy-bold.svg";
import miscUtils from 'utils/miscUtils';


const CustomFieldsMemberDetails = ({ screeningResponse, group }: { screeningResponse: any, group?: any }) => {

    const { isLoading, settingsPage, } = React.useContext(AdminMemberDetailsContext)
    const [showCopyIcon, setShowCopyIcon] = React.useState<any>(null)
    const [updatedscreeningResponse, setUpdatedscreeningResponse] = useState<any>();

    const handleCopyClick = (copyText: string) => {
        window.navigator.clipboard.writeText(`${copyText}`);
        toast(`Answer copied.`, {
            type: ToastType.SUCCESS,
            autoClose: 3000
        });
    };
    const Admin = Boolean(group && group.user_membership &&
        ((miscUtils.isAdmin(group.user_membership))
            || miscUtils.isModerator((group.user_membership))))

    const screenIsUnAvailable = ((!screeningResponse) && !isLoading)
    const displayPubliclyOnProfile = group && group.display_fields


    useEffect(() => {
        const _updatedScreeningResponse = screeningResponse && screeningResponse.length > 0 && screeningResponse.map((newResponse: any) => {
            const { question } = newResponse;

            if (displayPubliclyOnProfile && displayPubliclyOnProfile.includes(question)) {
                // If the question is in displayPubliclyOnProfile, update the response
                return {
                    ...newResponse,
                };
            }
            else return null

        }).filter(Boolean);
        setUpdatedscreeningResponse(_updatedScreeningResponse)
    }, [screeningResponse])

    const compShouldDisplay = (Admin ||
        (updatedscreeningResponse &&
            updatedscreeningResponse.length > 0))

    return (
        <div className={`custom-container ${settingsPage ? 'admin-mgtop' : ''} ${screenIsUnAvailable || !compShouldDisplay ? 'unavailble' : ''} `}>
            {compShouldDisplay
                &&
                <div className="header">
                    <span className="header-icon">
                        <FormIcon />
                    </span>
                    <h1>Custom fields</h1>
                </div>
            }

            {isLoading ?
                <>
                    {Array(7).fill('').map((_e, i) => (
                        <div style={{ width: '100%', border: `1px solid #d5d8df` }}>
                            <ContentLoader
                                height={window.innerWidth <= 500 ? 90 : 20}
                                width={120}
                                speed={2}
                                primaryColor="#d9d9d9"
                                secondaryColor="#ecebeb"
                                viewBox="0 0 120 20"
                            >
                                <rect x="0" y="0" rx="2" ry="2" width={60} height={4} />
                                <rect x="0" y="8" rx="2" ry="2" width={90} height={4} />
                            </ContentLoader>
                        </div>
                    ))}
                </>
                :
                <>
                    {screeningResponse && screeningResponse.length > 0 && Admin ?
                        <div className='body-section'>
                            {screeningResponse.sort((a: any, b: any) => a.position - b.position).map((newResponse: any) => (
                                <div key={newResponse.question}>
                                    <span>{newResponse.question}</span>
                                    {newResponse.answer ?
                                        <div
                                            className='copy'
                                            onMouseEnter={() => setShowCopyIcon(newResponse.question)}
                                            onMouseLeave={() => setShowCopyIcon(null)}
                                        >
                                            <h4
                                                className="custom-details"
                                                style={{ maxWidth: '330px' }}
                                            >
                                                {
                                                    typeof newResponse.answer === 'object' ?
                                                        <>
                                                            {newResponse.answer.join(', ')}
                                                        </>
                                                        :
                                                        <>
                                                            {newResponse.answer.length > 100 ?
                                                                <ReadMore
                                                                    text={newResponse.answer}
                                                                    readMoreCharacterLimit={100}
                                                                    numberOfLines={1}
                                                                    lineHeight={1}
                                                                    showLessButton
                                                                    onContentChange={() => { }}
                                                                    showMoreText="Read answer"
                                                                    showLessText="Hide answer"
                                                                />
                                                                :
                                                                newResponse.answer
                                                            }
                                                        </>
                                                }
                                            </h4>
                                            {(showCopyIcon && showCopyIcon) === newResponse.question &&
                                                <span
                                                    onClick={() => handleCopyClick(newResponse.answer)}
                                                    className='new-copy-icon'
                                                >
                                                    <CopyIcon />
                                                </span>
                                            }
                                        </div>
                                        :
                                        <h4>-</h4>
                                    }
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            {
                                screeningResponse &&
                                screeningResponse.length > 0 &&
                                updatedscreeningResponse &&
                                updatedscreeningResponse.length > 0 &&
                                <div className='body-section'>
                                        {updatedscreeningResponse.sort((a: any, b: any) => a.position - b.position).map((newResponse: any) => (
                                            <div key={newResponse.question}>
                                                <span>{newResponse.question}</span>
                                                {newResponse.answer ?
                                                    <div
                                                        className='copy'
                                                        onMouseEnter={() => setShowCopyIcon(newResponse.question)}
                                                        onMouseLeave={() => setShowCopyIcon(null)}
                                                    >
                                                        <h4
                                                            className="custom-details"
                                                            style={{ maxWidth: '330px' }}
                                                        >
                                                            {
                                                                typeof newResponse.answer === 'object' ?
                                                                    <>
                                                                        {newResponse.answer.join(', ')}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {newResponse.answer.length > 100 ?
                                                                            <ReadMore
                                                                                text={newResponse.answer}
                                                                                readMoreCharacterLimit={100}
                                                                                numberOfLines={1}
                                                                                lineHeight={1}
                                                                                showLessButton
                                                                                onContentChange={() => { }}
                                                                                showMoreText="Read answer"
                                                                                showLessText="Hide answer"
                                                                            />
                                                                            :
                                                                            newResponse.answer
                                                                        }
                                                                    </>
                                                            }
                                                        </h4>
                                                        {(showCopyIcon && showCopyIcon) === newResponse.question &&
                                                            <span
                                                                onClick={() => handleCopyClick(newResponse.answer)}
                                                                className='new-copy-icon'
                                                            >
                                                                <CopyIcon />
                                                            </span>
                                                        }
                                                    </div>
                                                    :
                                                    <h4>-</h4>
                                                }
                                            </div>
                                        ))}
                                    </div>
                            }
                        </>
                    }
                </>
            }
        </div>
    )
}

export default CustomFieldsMemberDetails