import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import "./ScrollSpinner.scss";

interface IScrollSpinnerProps {
  label: string;
}

const ScrollSpinner: React.FC<IScrollSpinnerProps> = props => {
  const { label } = props;

  return (
    <div className="ScrollSpinner">
      <FontAwesomeIcon icon={faSpinner} />
      <p>{label}</p>
    </div>
  );
};

export default ScrollSpinner;
