import React from 'react'
import ContentLoader from 'react-content-loader';

const LineGraphLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={400}
            height={240}
            viewBox="0 0 400 240"
            style={{ backgroundColor: '#f3f3f3' }}
        >
            <rect x="0" y="200" rx="0" ry="0" width="400" height="1" />
            <rect x="0" y="160" rx="0" ry="0" width="400" height="1" />
            {/* The x-axis */}
            <rect x="0" y="120" rx="0" ry="0" width="400" height="1" />

            {/* Line 1 */}
            <rect x="0" y="80" rx="0" ry="0" width="400" height="1" />

            {/* Line 2 */}
            <rect x="0" y="40" rx="0" ry="0" width="400" height="1" />

            {/* Line 3 */}
            <rect x="0" y="0" rx="0" ry="0" width="400" height="1" />
        </ContentLoader>
    );
};

export default LineGraphLoader