import React from "react";
import "./new-message-menu.scss";
import useComponentVisible from "utils/visible/visible";
import { INewMessageMenuProps } from "./INewMessageMenuProps";
// import { Link } from "react-router-dom";
import { ReactComponent as WriteIcon } from 'assets/img/add.svg';
import { useAtom } from "jotai";
import { showMessagePopUpAtom, showNewDirectMessageMenuAtom, showNewGroupMessageMenuAtom, showNewMessageStartupAtom } from "showMessageMenuAtom";

const NewMessageMenu: React.FC<INewMessageMenuProps> = props => {
  const { setIsEditGroupchat, theme, setIsShowChatroom, group } = props;

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  // const newUrl = window.location.pathname
  const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);
  const [, setShowNewDirectMessageMenu] = useAtom(showNewDirectMessageMenuAtom);
  const [, setShowNewGroupMessageMenu] = useAtom(showNewGroupMessageMenuAtom);
  const [, setNewMessageStartUp] = useAtom(showNewMessageStartupAtom);


  return (
    <div
      className="dropdown-menu new-message-menu" ref={ref}>
      <div className="message-menu-top">
        <span className="message-menu-top-text">Direct messages</span>
        {group && group.messaging_allowed && group.user_membership && group.user_membership.can_chat &&

        <button
          className='write-icon'
          style={{ color: (theme && theme.custom) && theme.custom.primaryColor }}
            onClick={() => {
              setNewMessageStartUp(true)
              setIsComponentVisible(true)
            }}
        >
          <WriteIcon />
        </button>
        }
      </div>

      {isComponentVisible && (
        <ul className="group-dropdown dropdown flex flex-direction-column">
          <li>
            <span
              onClick={() => {
                setIsComponentVisible(false)
                localStorage.setItem("isShowChatroom", "yes")
                setIsShowChatroom(true)
                setOpenMessagePopUp(true)
                document.body.style.overflow = 'hidden';

                setShowNewDirectMessageMenu(true)
                setShowNewGroupMessageMenu(false)
              }}
              className="btn btn-top-bar"
            >
              Direct message
            </span>
          </li>
          <li>
            <span
              onClick={() => {
                setIsComponentVisible(false);
                setIsEditGroupchat(false)
                localStorage.setItem("isShowChatroom", "yes")
                setIsShowChatroom(true)
                setOpenMessagePopUp(true)
                document.body.style.overflow = 'hidden';
                setShowNewDirectMessageMenu(false)
                setShowNewGroupMessageMenu(true)
              }}
              className="btn btn-top-bar"
            >
              Group message
            </span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default NewMessageMenu