import styled from "styled-components";

const PageWrapper: any = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
`;

export default PageWrapper;