import React from 'react';
import ReactModal from 'react-modal';
import { IPageBuilderFormPreviewProps } from './IPageBuilderFormPreviewProps';
import styled from 'styled-components';
import styles from './PageBuilderFormPreview.module.scss';
import { isValidHexColor } from '../PageBuilderSideBar/BlockSettings/domain';
import { ReactComponent as XIcon } from 'containers/channels/collections/deleteConfirmation/icons/x.svg';
import BlockTextContainer, { BlockSubTextContainer } from '../PageBuilderBuildingPreview/BlockComponents/BlockTextContainer';
import anchorme from 'anchorme';
import { ButtonAction, FormFieldTypes } from 'containers/PageBuilder/SinglePageContainer/domain';
import BlockButton from '../PageBuilderBuildingPreview/BlockComponents/BlockButton';
import PageBuilderService from 'api/services/page-builder';
import miscUtils from 'utils/miscUtils';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import useUAIP from 'hooks/useUAIP';
import Cookie from 'js-cookie';


const ContentContainer: any = styled.div<{design: any}>`
    width: 100%;
    border-radius: 4px;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: ${(props: any) => 
      props.design.container_padding_top ?
      `${props.design.container_padding_top}px` :
      `0px`
    };
    padding-bottom: ${(props: any) => 
      props.design.container_padding_bottom ?
      `${props.design.container_padding_bottom}px` :
      `0px`
    };
    background-color: ${(props: any) => 
      props.design.container_background_color &&
      isValidHexColor(props.design.container_background_color) ?
      props.design.container_background_color :
      'transparent'
  };
`;

const IconContainer: any = styled.span<{design: any}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  svg {
    path {
      stroke: ${(props: any) => 
        props.design.close_icon_color &&
        isValidHexColor(props.design.close_icon_color) ?
        props.design.close_icon_color :
        '#333333'
      };
  }
`;

const ContentWrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FieldsContainer: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const FormGroupContainer: any = styled.div<{index: number}>`
  width: 100%;
  margin-top: ${(props: any) => 
    props.index ? 
    `10px` :
    `0px`
  };
`;

const FormGroupLabelContainer: any = styled.div`
  width: 100%;
`;

const FormGroupLabelText: any = styled.p<{design: any, pageStyles: any}>`
  margin: 0;
  padding: 0;
  font-weight: 400;

  font-size: ${(props: any) =>
    props.design.field_input_label_font_size &&
    props.design.field_input_label_font_size <= 50 ?
    `${props.design.field_input_label_font_size}px` :
    `20px`
  };
  line-height: ${(props: any) =>
    props.design.field_input_label_line_height &&
    props.design.field_input_label_line_height <= 50 ?
      `${props.design.field_input_label_line_height}px` :
      `25px`
  };
  font-family: ${(props: any) =>
    props.design.field_input_label_font_family ?
      `${props.design.field_input_label_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) =>
    props.design.field_input_label_color &&
    isValidHexColor(props.design.field_input_label_color) ?
    props.design.field_input_label_color :
    '#000000'
  };
`;

const FormGroupLabelTextRequired: any = styled.span<{design: any}>`
  margin: 0;
  padding: 0;
  font-weight: 400;

  font-size: ${(props: any) =>
    props.design.field_input_label_font_size &&
    props.design.field_input_label_font_size <= 50 ?
    `${props.design.field_input_label_font_size}px` :
    `20px`
  };
  line-height: ${(props: any) =>
    props.design.field_input_label_line_height &&
    props.design.field_input_label_line_height <= 50 ?
      `${props.design.field_input_label_line_height}px` :
      `25px`
  };
  font-family: ${(props: any) =>
    props.design.field_input_label_font_family ?
      `${props.design.field_input_label_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) =>
    props.design.field_input_required_color &&
    isValidHexColor(props.design.field_input_required_color) ?
    props.design.field_input_required_color :
    '#FF0000'
  };
`;

const FormGroupErrorText: any = styled(FormGroupLabelText)`
  margin-top: 5px;
  color: ${(props: any) =>
    props.design.field_input_required_color &&
    isValidHexColor(props.design.field_input_required_color) ?
    props.design.field_input_required_color :
    '#FF0000'
  };
`;

const FormGroupInputContainer: any = styled.div<{design: any, isError: boolean}>`
  width: 100%;
  border: ${(props: any) => 
    props.isError &&
    props.design.field_input_required_color &&
    isValidHexColor(props.design.field_input_required_color) ?
    `1px solid ${props.design.field_input_required_color}` :
    props.design.field_input_border_color &&
    isValidHexColor(props.design.field_input_border_color) ?
    `1px solid ${props.design.field_input_border_color}` :
    `1px solid #D5D8DF` 
  };
  border-radius: 4px;
  margin-top: 5px;
  overflow: hidden;
`;

const FormGroupInputElement: any = styled.input<{design: any}>`
  font-size: ${(props: any) =>
    props.design.field_input_font_size &&
    props.design.field_input_font_size <= 50 ?
    `${props.design.field_input_font_size}px` :
    `20px`
  };
  line-height: ${(props: any) =>
    props.design.field_input_line_height &&
    props.design.field_input_line_height <= 50 ?
      `${props.design.field_input_line_height}px` :
      `25px`
  };
  font-family: ${(props: any) =>
    props.design.field_input_font_family ?
      `${props.design.field_input_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) =>
    props.design.field_input_color &&
    isValidHexColor(props.design.field_input_color) ?
    props.design.field_input_color :
    '#000000'
  };
  width: 100%;
  height: ${(props: any) =>
    props.design.field_input_height &&
    props.design.field_input_height > 5 &&
    props.design.field_input_height < 100 ?
    `${props.design.field_input_height}px` :
    `44px`
  };
  border: none;
  padding: 0 10px;
  background-color: ${(props: any) =>
    props.design.field_input_background_color &&
    isValidHexColor(props.design.field_input_background_color) ?
    props.design.field_input_background_color :
    '#FFFFFF'
  };

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${(props: any) =>
      props.design.field_input_placeholder_color &&
      isValidHexColor(props.design.field_input_placeholder_color) ?
      props.design.field_input_placeholder_color :
      '#D1D1D1'
    };
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: ${(props: any) =>
      props.design.field_input_placeholder_color &&
      isValidHexColor(props.design.field_input_placeholder_color) ?
      props.design.field_input_placeholder_color :
      '#D1D1D1'
    };
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: ${(props: any) =>
      props.design.field_input_placeholder_color &&
      isValidHexColor(props.design.field_input_placeholder_color) ?
      props.design.field_input_placeholder_color :
      '#D1D1D1'
    };
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: ${(props: any) =>
      props.design.field_input_placeholder_color &&
      isValidHexColor(props.design.field_input_placeholder_color) ?
      props.design.field_input_placeholder_color :
      '#D1D1D1'
    };
  }
  &::placeholder { /* Standard */
    color: ${(props: any) =>
      props.design.field_input_placeholder_color &&
      isValidHexColor(props.design.field_input_placeholder_color) ?
      props.design.field_input_placeholder_color :
      '#D1D1D1'
    };
  }
`;

const FormGroupPhoneInputElement: any = styled(PhoneInput)<{design: any}>`
  padding-left: 10px;
  input[type="tel"] {
    font-size: ${(props: any) =>
      props.design.field_input_font_size &&
      props.design.field_input_font_size <= 50 ?
      `${props.design.field_input_font_size}px` :
      `20px`
    };
    line-height: ${(props: any) =>
      props.design.field_input_line_height &&
      props.design.field_input_line_height <= 50 ?
        `${props.design.field_input_line_height}px` :
        `25px`
    };
    font-family: ${(props: any) =>
      props.design.field_input_font_family ?
        `${props.design.field_input_font_family} !important` :
        `-apple-system, Arial !important`
    };
    color: ${(props: any) =>
      props.design.field_input_color &&
      isValidHexColor(props.design.field_input_color) ?
      props.design.field_input_color :
      '#000000'
    };
    width: 100%;
    height: ${(props: any) =>
      props.design.field_input_height &&
      props.design.field_input_height > 5 &&
      props.design.field_input_height < 100 ?
      `${props.design.field_input_height}px` :
      `44px`
    };
    border: none;
    padding: 0 10px;
    background-color: ${(props: any) =>
      props.design.field_input_background_color &&
      isValidHexColor(props.design.field_input_background_color) ?
      props.design.field_input_background_color :
      '#FFFFFF'
    };
  
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: ${(props: any) =>
        props.design.field_input_placeholder_color &&
        isValidHexColor(props.design.field_input_placeholder_color) ?
        props.design.field_input_placeholder_color :
        '#D1D1D1'
      };
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: ${(props: any) =>
        props.design.field_input_placeholder_color &&
        isValidHexColor(props.design.field_input_placeholder_color) ?
        props.design.field_input_placeholder_color :
        '#D1D1D1'
      };
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: ${(props: any) =>
        props.design.field_input_placeholder_color &&
        isValidHexColor(props.design.field_input_placeholder_color) ?
        props.design.field_input_placeholder_color :
        '#D1D1D1'
      };
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: ${(props: any) =>
        props.design.field_input_placeholder_color &&
        isValidHexColor(props.design.field_input_placeholder_color) ?
        props.design.field_input_placeholder_color :
        '#D1D1D1'
      };
    }
    &::placeholder { /* Standard */
      color: ${(props: any) =>
        props.design.field_input_placeholder_color &&
        isValidHexColor(props.design.field_input_placeholder_color) ?
        props.design.field_input_placeholder_color :
        '#D1D1D1'
      };
    }
  }
`;

const FormButton: any = styled(BlockButton)<{isSubmitting: boolean}>`
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  opacity: ${(props: any) => 
    props.isSubmitting ?
    `.5` :
    `1`
  };
`;

const FormConsentContainer: any = styled.div`
  margin-top: 25px;
  width: 100%;
`

const FormConsentWrapper: any = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const FormConsentInput: any = styled.input<{design: any}>`
  width: 18px;
  height: 18px;
  border: ${(props: any) => 
    props.design.field_input_border_color &&
    isValidHexColor(props.design.field_input_border_color) ?
    `1px solid ${props.design.field_input_border_color}` :
    `1px solid #D5D8DF`
  };
`;

const FormConsentLabel: any = styled(FormGroupLabelText)`
  margin-left: 5px;
`;

const SubmittedTextContainer: any = styled.div`
  width: 100%;
  display: flex;
  align-itmes: center;
  justify-content: center;
  padding: 20px;
`;

const PageBuilderFormPreview: React.FC<IPageBuilderFormPreviewProps> = (props) => {

    const [formData, setFormData] = React.useState({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      consent: false,
    });

    const [formDataError, setFormDataError] = React.useState<any>({
      first_name: false,
      last_name: false,
      email: false,
      phone_number: false,
      consent: false,
    });

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);

    const {
      ua,
      ip
    } = useUAIP();

    const closeForm = () => {
        let pageFormsOpenStateCopy = JSON.parse(JSON.stringify(props.pageFormsOpenState));

        if(
            pageFormsOpenStateCopy &&
            pageFormsOpenStateCopy[props.pageForm.render_id]
        ) {
            pageFormsOpenStateCopy[props.pageForm.render_id] = false;

            props.setPageFormsOpenState(pageFormsOpenStateCopy);
        }
    }

    const getInputType = (fieldType: FormFieldTypes) => {
      switch(fieldType) {
        case FormFieldTypes.FirstName:
        case FormFieldTypes.LastName:
          return "text";
        case FormFieldTypes.Email:
          return "email"
        case FormFieldTypes.PhoneNumber:
          return "tel"
      }
    }

    const hasField = (fieldType: FormFieldTypes) => {
      let isField: boolean = false;

      props.pageForm.fields.forEach((field: any) => {
        if(field.type === fieldType) {
          isField = true;
        }
      });

      return isField;
    }

    const getUserConsentField = () => {
      let userConsentField: any;

      props.pageForm.fields.forEach((field: any) => {
        if(field.type === FormFieldTypes.UserConsent) {
          userConsentField = field;
        }
      });

      return userConsentField;
    }

    const getInputName: any = (fieldType: FormFieldTypes) => {
      switch(fieldType) {
        case FormFieldTypes.FirstName:
          return "first_name";
        case FormFieldTypes.LastName:
          return "last_name";
        case FormFieldTypes.Email:
          return "email"
        case FormFieldTypes.PhoneNumber:
          return "phone_number"
      }
    }

    const getInputValue = (fieldType: FormFieldTypes) => {
      switch(fieldType) {
        case FormFieldTypes.FirstName:
          return formData.first_name;
        case FormFieldTypes.LastName:
          return formData.last_name;
        case FormFieldTypes.Email:
          return formData.email;
        case FormFieldTypes.PhoneNumber:
          return formData.phone_number;
        default:
          return '';
      }
    }

    const onChangeFormField = (e: any) => {
      if(formDataError[e.target.name]) {
        setFormDataError({
          ...formDataError,
          [e.target.name]: false,
        });
      }

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    const fieldIsRequired = (fieldType: FormFieldTypes) => {
      let isFieldRequired: boolean = false;

      props.pageForm.fields.forEach((field: any) => {
        if(
          field.type === fieldType &&
          field.is_required
        ) {
          isFieldRequired = true;
        }
      });

      return isFieldRequired;
    }

    const validate = () => {
      let valid = true;
      const formDataErrorCopy = JSON.parse(JSON.stringify(formDataError));

      if(
        hasField(FormFieldTypes.FirstName) &&
        formData.first_name.trim() === "" &&
        fieldIsRequired(FormFieldTypes.FirstName)
      ) {
        formDataErrorCopy.first_name = true;
        valid = false;
      }

      if(
        hasField(FormFieldTypes.LastName) &&
        formData.last_name.trim() === "" &&
        fieldIsRequired(FormFieldTypes.LastName)
      ) {
        formDataErrorCopy.last_name = true;
        valid = false;
      }

      if(
        hasField(FormFieldTypes.Email) &&
        formData.email.trim() === "" &&
        fieldIsRequired(FormFieldTypes.Email)
      ) {
        formDataErrorCopy.email = true;
        valid = false;
      }

      if(
        hasField(FormFieldTypes.Email) &&
        formData.email.trim() &&
        !miscUtils.validateEmail(formData.email.trim())
      ) {
        formDataErrorCopy.email = true;
        valid = false;
      }

      if(
        hasField(FormFieldTypes.PhoneNumber) &&
        !formData.phone_number ||
        (formData.phone_number && formData.phone_number.trim() === "") &&
        fieldIsRequired(FormFieldTypes.PhoneNumber)
      ) {
        formDataErrorCopy.phone_number = true;   
        valid = false;
      }

      if(
        hasField(FormFieldTypes.UserConsent) &&
        !formData.consent &&
        fieldIsRequired(FormFieldTypes.UserConsent)
      ) {
        formDataErrorCopy.consent = true;        
        valid = false;
      }

      if(!valid) {
        setFormDataError(formDataErrorCopy);
      }

      return valid;
    }

    const submitForm = () => {
      if(props.preview) {
        return;
      }

      if(!validate()) {
        return;
      }

      setIsSubmitting(true);

      const payload: any = {
        ...formData,
      }

      const _fbc = Cookie.get('_fbc');

      if(_fbc) {
        payload.fbc = _fbc;
      }

      payload.ua = ua;
      payload.ip = ip;

      PageBuilderService.getInstance()
      .submitFormLead(
        props.pageForm.group_id,
        props.pageForm.widget_id,
        {
          data: payload,
        },
      )
      .then(() => {
        Cookie.remove('_fbc', { path: "/", domain: window.location.hostname });
        if(
          props.pageForm.button_action === ButtonAction.URL
        ) {
          window.open(props.pageForm.button_url, '_self');
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setSubmitted(true);
        if(props.pageForm.button_action !== ButtonAction.None) {
          closeForm();
        }
      });
    }

    const getErrorMessage = (field: any) => {
      if(
        field.type === FormFieldTypes.Email &&
        formData.email.trim()
      ) {
        return `Please enter a valid email address`
      }

      return field.error_message ? 
        field.error_message :
        `${field.label} is required`
    }
    
  return (
    <ReactModal
    isOpen={props.pageFormsOpenState[props.pageForm.render_id]}
    style={{
        content: {
          width: `${props.pageForm.design.container_width}px`,
          maxWidth: props.parentSelectorId ? `100%` : `100vw`,
          borderRadius: "4px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          position: props.parentSelectorId ? 'absolute' : 'fixed',
          border: 0,
          padding: 0,
        },
        overlay: {
          position: props.parentSelectorId ? 'absolute' : 'fixed',
          backgroundColor: `rgba(0, 0, 0, .5)`,
          zIndex: 99999,
        }
    }}
    parentSelector={
      props.parentSelectorId ? 
      () => document.getElementById(props.parentSelectorId as string) as HTMLElement :
      undefined
    }
    portalClassName={styles.modal_portal}
    >
        <ContentContainer
        design={props.pageForm.design}
        >
          <IconContainer
          design={props.pageForm.design}
          onClick={closeForm}
          >
            <XIcon
            />
          </IconContainer>
          {submitted ? (
            <SubmittedTextContainer
            >
              <BlockTextContainer
              design={props.pageForm.design}
              pageStyles={props.pageStyles}
              textKey="submission_message"
              fallbackTextColor="#333333"
              dangerouslySetInnerHTML={{
                  __html: anchorme(
                    props.pageForm.submission_message &&
                    miscUtils.stripHTML(props.pageForm.submission_message).length ?
                    props.pageForm.submission_message : 
                    `<p>Thank you for your submission!</p>`
                  ),
              }}
              />
            </SubmittedTextContainer>
          ) : (
            <ContentWrapper
            >
              <BlockTextContainer
              design={props.pageForm.design}
              pageStyles={props.pageStyles}
              textKey="heading"
              fallbackTextColor="#FFFFFF"
              dangerouslySetInnerHTML={{
                  __html: anchorme(props.pageForm.heading),
              }}
              />
  
  
              <BlockSubTextContainer
              design={props.pageForm.design}
              pageStyles={props.pageStyles}
              textKey="subtext"
              fallbackTextColor="#FFFFFF"
              dangerouslySetInnerHTML={{
                  __html: anchorme(props.pageForm.subtext),
              }}
              />
  
              <FieldsContainer
              >
                {props.pageForm.fields.map((field: any, index: number) => {
                  if(field.type === FormFieldTypes.UserConsent) {
                    return <></>
                  }
  
                  return (
                    <FormGroupContainer
                    index={index}
                    >
                      <FormGroupLabelContainer
                      >
                        <FormGroupLabelText
                        design={props.pageForm.design}
                        >
                          {field.label}
                          {field.is_required ? (
                            <FormGroupLabelTextRequired
                            design={props.pageForm.design}
                            >
                              *
                            </FormGroupLabelTextRequired>
                          ) : <></>}
                        </FormGroupLabelText>
                      </FormGroupLabelContainer>
                      <FormGroupInputContainer
                      design={props.pageForm.design}
                      isError={formDataError[getInputName(field.type)]}
                      >
                        {field.type === FormFieldTypes.PhoneNumber ? (
                          <FormGroupPhoneInputElement
                          international
                          countryCallingCodeEditable={false}
                          design={props.pageForm.design}
                          placeholder={field.placeholder}
                          defaultCountry="US"
                          value={getInputValue(field.type)}
                          onChange={(value: any) => {
                            if(value) {
                              if(formDataError.phone_number) {
                                setFormDataError({
                                  ...formDataError,
                                  phone_number: false,
                                });
                              }
                        
                              setFormData({
                                ...formData,
                                phone_number: value,
                              });
                            }
                          }}
                          />
                        ) : (
                          <FormGroupInputElement
                          design={props.pageForm.design}
                          type={getInputType(field.type)}
                          placeholder={field.placeholder}
                          name={getInputName(field.type)}
                          value={getInputValue(field.type)}
                          onChange={onChangeFormField}
                          />
                        )}
                      </FormGroupInputContainer>
                      {formDataError[getInputName(field.type)] ? 
                        <FormGroupErrorText
                        design={props.pageForm.design}
                        >
                          <FormGroupLabelTextRequired
                          design={props.pageForm.design}
                          >
                            *
                          </FormGroupLabelTextRequired>
                          {
                            getErrorMessage(field)
                          }
                        </FormGroupErrorText> 
                        : 
                        <></>
                      }
                    </FormGroupContainer>
                  )
                })}
              </FieldsContainer>
  
              <FormButton
              design={props.pageForm.design}
              isSubmitting={isSubmitting}
              pageStyles={props.pageStyles}
              onClick={() => submitForm()}
              disabled={isSubmitting}
              >
                {isSubmitting ? `Saving...` : props.pageForm.button_text}
              </FormButton>
  
              {hasField(FormFieldTypes.UserConsent) ? (
                <FormConsentContainer
                >
                  <FormConsentWrapper
                  >
                    <FormConsentInput
                    design={props.pageForm.design}
                    type="checkbox"
                    checked={formData.consent}
                    onClick={() => {
                      if(formDataError.consent) {
                        setFormDataError({
                          ...formDataError,
                          consent: false,
                        });
                      }
                      setFormData({
                        ...formData,
                        consent: !formData.consent,
                      })
                    }}
                    />
                    <FormConsentLabel
                    design={props.pageForm.design}
                    >
                      {getUserConsentField().label}
                    </FormConsentLabel>
                  </FormConsentWrapper>
                  {formDataError.consent ? 
                    <FormGroupErrorText
                    design={props.pageForm.design}
                    >
                      <FormGroupLabelTextRequired
                      design={props.pageForm.design}
                      >
                        *
                      </FormGroupLabelTextRequired>
                      {
                        getUserConsentField().error_message ? 
                        getUserConsentField().error_message :
                        `Please check the box to continue`
                      }
                    </FormGroupErrorText> 
                    : 
                    <></>
                  }
                </FormConsentContainer>
              ) : <></>}
            </ContentWrapper>
          )}
        </ContentContainer>
    </ReactModal>
  )
}

export default PageBuilderFormPreview;