import React from 'react'

import './comm-intro.scss'
import DraftContent from 'components/group/groupPost/DraftContent'
import PostableEditor from 'components/post/PostableEditor'
import ContentLoader from 'react-content-loader'

const CommunityIntroduction = (
    {
        introData,
        group,
        postData,
        setpostData,
        popupRef
    }:
        {
            introData: any
            group: any
            postData: any
            setpostData: any
            popupRef: any
        }
) => {
    const postDataStateRef = React.useRef<any>(null)

    // A workaround for scrolltoTop on the container on windows chrome and smaller screens
    React.useEffect(() => {
        const parentContainer: any = document.getElementById('intro-member-post');

        if (parentContainer) {
            parentContainer.style.display = 'none';
            // Scroll to the top of the pop-up
            if (popupRef.current) popupRef.current.scrollTop = 0;
        }

        setTimeout(() => {
            if (parentContainer) {
                parentContainer.style.display = 'block';
            }
        }, 200);
    }, []);

    return (
        <div className='comm-intro'>
            {introData === undefined
                ?
                <div style={{ width: '620px' }}>
                    <ContentLoader
                        height={44}
                        width={620}
                        speed={2}
                        primaryColor="#d9d9d9"
                        secondaryColor="#ecebeb"
                    >
                        <rect x={174} y="0" rx="6" ry="6" width={280} height={24} />
                    </ContentLoader>
                </div>
                :
                <h1 className='comm-intro-title'>{introData.title}</h1>
            }
            <div className='comm-intro-desc'>
                {
                    introData === undefined
                        ?
                        <ContentLoader
                            height={265}
                            width={680}
                            speed={2}
                            primaryColor="#d9d9d9"
                            secondaryColor="#ecebeb"
                        >
                            <rect x={0} y="0" rx="6" ry="6" width={680} height={250} />
                        </ContentLoader>
                        :
                        <DraftContent
                            post={{
                                id: introData.id,
                                description: introData.description,
                                description_json: introData.description_json
                            }}
                            disableCharacterLimit
                        />
                }
            </div>
            <div className="intro-member-post" id='intro-member-post'>
                {
                    introData === undefined
                        ?
                        <ContentLoader
                            height={265}
                            width={680}
                            speed={2}
                            primaryColor="#d9d9d9"
                            secondaryColor="#ecebeb"
                        >
                            <rect x={0} y="0" rx="6" ry="6" width={680} height={250} />
                        </ContentLoader>
                        :
                        <PostableEditor
                            group={group}
                            messagingMode
                            onSubmitMessagingMode={(arg0: any) => {
                                setpostData(arg0);
                            }}
                            editMode={false}
                            postableType='channel'
                            postDataStateRef={postDataStateRef}
                            announcementMode
                            editorPlaceholder={'Introduce yourself here...'}
                            post={postData}
                        />
                }
            </div>
        </div>
    )
}

export default CommunityIntroduction