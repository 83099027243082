import React, { useEffect, useState } from "react";
import { IUserComponentProps } from "./IUserComponentProps";
import "./UserComponent.scss";
import miscUtils from "../../utils/miscUtils";
import Moment from "react-moment";
import ReadMore from "../../utils/readMore/readMore";
import PrivilegeBadge from "./privilegeBadge";
import { RoleIds } from "containers/teamsContainer/domain";
import useWindowSize from "utils/windowSize";
import { Link, withRouter } from 'react-router-dom';
// import { ISlideoutProfileData } from "types";
// import useSlideoutProfile from "hooks/slideoutProfileHook";
import clsx from "clsx";
import { connect } from "react-redux";
import { Map } from "immutable";
import MiniProfile from "./MiniProfile";
import { UserService } from "api/services/users";
import { useAtom } from "jotai";
import { activeMemberIDAtom, memberDetailsProfilePopupAtom, showMessagePopUpAtom } from "showMessageMenuAtom";
import { getCookieValue } from "utils/cookieStorage";
// import { ReactComponent as LoadingSpiner } from 'assets/img/Spin-1s-18px.svg'
// import { useHistory } from 'react-router'

const MOBILE_WINDOW_WIDTH: number = 550;

const UserComponent: React.FC<IUserComponentProps> = props => {
  const { timeStamp, job_title, about, hideName, showOnlineStatus, user, imageSize = "sm", showProfileSlider = true, group } = props;
  const containerStyles = props.containerStyles ? props.containerStyles : {};

  const windowSize = useWindowSize();
  // const { openSlideout } = useSlideoutProfile();
  // const openedSlideoutProfileData: ISlideoutProfileData = props.global.slideoutProfileData;

  const [canShowSlider, setCanShowSlider] = useState<boolean>(false);
  const [profileImageSize, setProfileImageSize] = useState<{ height: string; width: string }>();
  const [miniProfile, setShowMiniProfile] = useState(false)

  const ProfileImage = user && user.profile_image
  const nonCommunityMember = !props.group || (props.group && props.group.membership_status !== "member")

  const determinePrivilege: () => "Owner" | "Admin" | "Moderator" | 'Member' = () => {
    switch (props.roleId) {
      case RoleIds.Owner:
        return "Owner";
      case RoleIds.Admin:
        return "Admin";
      case RoleIds.Moderator:
        return "Moderator";
      default:
        return "Member";
    }
  }

  // const openProfileSlider = () => {
  //   if (canShowSlider) {
  //     const slideoutProfileData: ISlideoutProfileData = {
  //       user: props.userMembership ? props.userMembership : props.user,
  //       groupMembersCallbacksRef: props.groupMembersCallbacksRef
  //     }
  //     openSlideout(slideoutProfileData, openedSlideoutProfileData && openedSlideoutProfileData.user);
  //   }
  // }

  const [, setActiveMemberID] = useAtom(activeMemberIDAtom);
  const [, setOpenmemberDetailsProfilePopup] = useAtom(memberDetailsProfilePopupAtom);

  const openPeopleDialog = () => {
    // const el = document.getElementById(`people-details-dialog`) as HTMLDialogElement
    // if (el) {
    //   el.showModal();
    //   document.body.style.overflow = 'hidden';
    //   // disableScroll()
    // }
    setOpenmemberDetailsProfilePopup(true)
  }

  const memberID = user && user.id || props.userMembership && props.userMembership.id

  const openProfileSlider = () => {
    if (props.eventGuest) return
    setActiveMemberID(memberID)
    openPeopleDialog()
  }

  const [userDetails, setUserDetails] = useState<any>()
  const channelLocation = window.location.pathname.includes('channel')
  const singlePostLocation = window.location.pathname.includes('posts')
  const coursesLocation = window.location.pathname.includes('courses')
  const libraryLocation = window.location.pathname.includes('library')

  const moderationLocation = window.location.pathname.includes('moderation')
  const membersLocation = window.location.pathname.includes('settings/members')
  const channelsAdminPage = window.location.pathname.includes('people')
  const channelsAddTeamatesPage = window.location.pathname === '/settings/teams/add'
  const homePage = window.location.pathname === '/'
  const feedPage = window.location.pathname === '/feed'
  const AnalyticsPage = window.location.pathname === '/settings/analytics'

  const [openMessagePopUp,] = useAtom(showMessagePopUpAtom);
  const [isfetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => {
    if (props.isAuthed && miscUtils.isGroupMember(group && group)) {
      if (openMessagePopUp || (getCookieValue("user")['uuid'] === (user && user.uuid))) {
        return
      }
      else {
        if (props.userMembership) {
          setUserDetails(props.userMembership && props.userMembership)
        }
        if (channelLocation) {
          UserService.getInstance()
            .getUserMembership(group && group.id, user && user.id)
            .then((res: any) => {
              if (res) {
                setUserDetails(res);
              }
            });
        }
      }
    }
  }, [group && group.id]);

  const fetchMemberDetails = () => {
    if (
      membersLocation ||
      coursesLocation ||
      libraryLocation ||
      moderationLocation ||
      channelsAdminPage ||
      channelsAddTeamatesPage ||
      singlePostLocation ||
      feedPage ||
      homePage 
    ) {
      if (isfetching) return
      if (group && group.membership_status !== "member") return
      setIsFetching(true)
      UserService.getInstance()
        .getUserMembership(group && group.id, user && user.id)
        .then((res: any) => {
          if (res) {
            setUserDetails(res);
          }
        }).finally(() => {
          setIsFetching(false)
        })
    }
  }


  useEffect(() => {
    if (imageSize) {
      switch (imageSize) {
        case "sm":
          setProfileImageSize({ height: "35px", width: "35px" });
          break;

        case "md":
          setProfileImageSize({ height: "48px", width: "48px" });
          break;
      }
    }
  }, [imageSize])

  useEffect(() => {
    if (
      showProfileSlider &&
      props.isAuthed &&
      (user && user.id || props.userMembership && props.userMembership.id) && miscUtils.isGroupMember(group && group)) {
      setCanShowSlider(true);
    } else {
      setCanShowSlider(false);
    }
  }, [props.userMembership, showProfileSlider, group, props.isAuthed])

  const OpenMiniProfile = () => {
    fetchMemberDetails()
    setShowMiniProfile(true)
  }

  const settingsPage = window.location.pathname.includes('/settings')
  const articlePostPage = window.location.pathname.includes('/library/articles/lectures/view')


  return props.user && props.user.full_name && props.user.full_name.length ? (
    <div className={clsx(
      "user-component",
      { ["sliderble"]: (canShowSlider || props.onClick) && !props.eventGuest }
    )}
      onClick={() => {
        if (!showProfileSlider) {
          return
        }
        if (!props.isPlacedonIntendedComp) {
          openProfileSlider();
          props.onClick && props.onClick();
        }
      }}
      style={{
        ...containerStyles,
        alignItems: settingsPage ? 'center' : 'flexStart'
      }}
    >
      {showOnlineStatus && (
        <span className={`status ${user.online_status === "active" ? "active" : "inactive"}`}></span>
      )}

      <div
        className="profile-image-container"
        onMouseEnter={() => {
          !AnalyticsPage && OpenMiniProfile()
        }}
        onMouseLeave={() => setShowMiniProfile(false)}
      >
        {props.user.profile_image ? (
          <img
            alt='user profile'
            className={`profile-image ${window.location.pathname.split("/").includes("messages") && "bg"}  ${(AnalyticsPage || props.eventGuest) && 'no-cursor'}`}
            src={props.user.profile_image}
            style={profileImageSize && { height: profileImageSize.height, width: profileImageSize.width, minHeight: profileImageSize.height, minWidth: profileImageSize.width }}
            onClick={() => {
              !AnalyticsPage && OpenMiniProfile()
            }}
          />
        ) : (
          <div
              className={`profile-image ${props.adminPeoplePage ? 'profile-image--profile-slideout' : ''} ${(AnalyticsPage || props.eventGuest) && 'no-cursor'}`}
            data-color={miscUtils.getColorInitial(`${props.user.full_name}`)}
              onClick={() => {
                !AnalyticsPage && OpenMiniProfile()
              }}
          >
            {miscUtils.getNameInitials(props.user.full_name)}
          </div>
        )}
        {
          !props.isAnnouncementPost &&
          props.isPlacedonIntendedComp &&
          !window.location.pathname.includes('about') &&
          !props.eventGuest &&
          miniProfile &&
          <MiniProfile
            fullName={user && user.full_name}
            nonCommunityMember={nonCommunityMember}
            setShowMiniProfile={setShowMiniProfile}
            ProfileImage={ProfileImage}
            determinePrivilege={determinePrivilege}
            openProfileSlider={openProfileSlider}
            user={user}
            userDetails={userDetails}
            isUserAuthed={props.isAuthed}
          />
        }
      </div>

      <div className="info">
        <div
          className={`info-name ${(props.roleId === RoleIds.Owner || props.roleId === RoleIds.Admin || props.roleId === RoleIds.Moderator) && (windowSize.width <= MOBILE_WINDOW_WIDTH) ? 'info-name-badged' : ''}`}
        >
          {
            !hideName &&
            <p
                className={`name ${props.eventGuest ? 'no-hover' : ''} ${props.adminPeoplePage ? 'highlightable-user-name' : ''} ${props.postView ? 'postview-user-name' : ''} ${props.minimizableName ? 'minimizable-user-name' : ''}`}
              style={props.nameStyles ? { ...props.nameStyles } : {}}
                onClick={() => {
                  AnalyticsPage ? openProfileSlider() : setShowMiniProfile(true)
                }}
            >
              {props.user.full_name}
            </p>
          }

          {
            (props.roleId === RoleIds.Owner ||
              props.roleId === RoleIds.Admin ||
              props.roleId === RoleIds.Moderator
            ) &&
            !props.isCommentComponent
            &&
            <>
              <PrivilegeBadge
                privilege={determinePrivilege()}
                notGroupMember={nonCommunityMember}
              />
            </>
          }
          {timeStamp && <div className="period"></div>}

          {timeStamp && (
            props.onClickTimestamp ? (
              <Link
                to={props.onClickTimestamp}
              >
                <div
                  className={`timestamp 
                  ${props.postView && !props.messagingView && !articlePostPage ? "timestamp--postview" : ""}
                  ${articlePostPage ? 'non-clickable-timeStamp' : ""}
                  `}
                >
                  <Moment utc={true} fromNow ago>
                    {timeStamp}
                  </Moment>
                  <span>ago</span>
                </div>
              </Link>
            ) : (
              <div
                  className={`timestamp 
                ${props.postView && !props.messagingView && !articlePostPage ? "timestamp--postview" : ""}
                ${articlePostPage ? 'non-clickable-timeStamp' : ""}
                `}
              >
                <Moment utc={true} fromNow ago>
                  {timeStamp}
                </Moment>
                <span>ago</span>
              </div>
            )
          )}
        </div>

        {(!hideName && !settingsPage) &&
          (
            <div className="headline">
              <span
                className='headline-text'
              >
                {props.user.headline && props.user.headline}
              </span>
            </div>
          )
        }

        {props.extraMarkup && props.extraMarkup}
        {job_title && <div className="job-title">{job_title}</div>}
        {about && (
          <div className="about">
            {about.length <= 280 && about}
            {about.length > 280 && (
              <ReadMore
                numberOfLines={1}
                lineHeight={1}
                showLessButton={true}
                text={about}
              />
            )}
          </div>
        )}
        {props.subText && (
          <div className="subtext-container">
            <p className="subtext-text">{props.subText}</p>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const selectedGroup: any = state.getIn(["global", "selectedGroup"]);
  const global = Map(state.get("global", {})).toJS() as any;

  return {
    isAuthed: authorize.authed,
    authedUser: authorize.user,
    group: selectedGroup,
    global
  };
};

export default withRouter(
  connect(mapStateToProps, null)(UserComponent as any) as any
) as any;
