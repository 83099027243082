import React from 'react'

import './header.scss'
import miscUtils from 'utils/miscUtils'
import { ReactComponent as Verified } from 'assets/img/verified.svg'
import { ReactComponent as NotVerified } from 'assets/img/not-verified.svg'
import { ReactComponent as Email } from 'assets/img/email.svg'
import { ReactComponent as RingBell } from 'assets/img/ring-bell.svg'
import { ReactComponent as MuteBell } from 'assets/img/mute-bell.svg'
import { ReactComponent as Clock } from 'assets/img/clock.svg'
import { ReactComponent as Message } from 'assets/img/tabler_message.svg'
import moment from 'moment'
import { RoleIds } from 'containers/teamsContainer/domain'
import Dropdown from 'elements/dropdown'
import { getCookieValue } from 'utils/cookieStorage'
import { ToastType, toast } from 'react-toastify'
import { DirectChatsContext } from 'containers/main/MainComponent'
import { useAtom } from 'jotai'
import { memberDetailsProfilePopupAtom, showActiveCHatAtom, showMessagePopUpAtom } from 'showMessageMenuAtom'
import ContentLoader from 'react-content-loader'
import { AdminMemberDetailsContext } from '../AdminMemberDetails'
import { ReactComponent as CopyIcon } from "assets/img/event-copy-bold.svg";
import { ReactComponent as EnrollmentIcon } from "assets/img/enrollment.svg";
import { ReactComponent as EnrollmentDaysIcon } from "assets/img/calendar-clock.svg";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const HOURS_IN_A_DAY: number = 24;

const HeaderMemberDetails = (
    {
        actions,
        userMembership,
        courseSection,
        EnrollmentStatus,
        EnrollmentDaysLeft,
        enrolment,
    }:
        {
            actions: any
            userMembership: any
            courseSection?: any
            EnrollmentStatus?: any
            EnrollmentDaysLeft?: any
            enrolment?: any
        }
) => {
    const [showCopyIcon, setShowCopyIcon] = React.useState<boolean>(false)
    const {
        directChats,
        setChatroom,
        setIsCreateDirectchat,
        group,
    } = React.useContext(DirectChatsContext)
    const { isLoading, Admin } = React.useContext(AdminMemberDetailsContext)

    const [, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);
    const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);

    const member = userMembership && userMembership.membership && userMembership.membership.user
    const nonMember = userMembership && userMembership.membership && (userMembership.membership.approved_on === null)

    const determinePrivilege: () => "Owner" | "Admin" | "Moderator" | 'Member' = () => {
        switch (userMembership.membership.role_id) {
            case RoleIds.Owner:
                return "Owner";
            case RoleIds.Admin:
                return "Admin";
            case RoleIds.Moderator:
                return "Moderator";
            default:
                return "Member";
        }
    }

    function getExistingChat() {
        if (directChats && directChats.length > 0) {
            return directChats.find((chat: { members: any[]; }) => {
                return chat.members.some((chatMember: any) => {
                    return chatMember.community_member.user && chatMember.community_member.user.uuid === (member && member.uuid);
                });
            });
        }
    }

    const openMessage = () => {
        closePeopleDialog()
        const member = { community_member: userMembership.membership && userMembership.membership };
        if ((userMembership.membership && ((!userMembership.membership.can_chat) && !miscUtils.isTeamMember(userMembership.membership))) || !userMembership.membership) {
            toast("Messaging has been disabled for this member.", {
                type: ToastType.ERROR,
                autoClose: 3000,
            });
            return;
        }
        const existingChat = getExistingChat();

        if (existingChat !== null && existingChat !== undefined) {
            localStorage.setItem("isShowChatroom", "yes")
            setActiveChatroomMessage(existingChat.id)
            setOpenMessagePopUp(true)
            document.body.style.overflow = 'hidden';
            localStorage.setItem("activeChatroomId", existingChat.id);
        }
        else {
            if (group && group.messaging_allowed && group.user_membership && group.user_membership.can_chat) {

                const _chatroom = {
                    description: "",
                    members: [{ community_member: group && group.user_membership }, member && member],
                    members_count: 2,
                    owner: group.user_membership,
                    title: "",
                    type: "individual",
                    group_id: group.id,
                    created_at: null,
                    member_id: member && member.community_member && member.community_member.id
                }
                const temp: any = 'temp'
                setChatroom(_chatroom);
                setIsCreateDirectchat(true);
                setActiveChatroomMessage(temp)
                setOpenMessagePopUp(true)
                localStorage.setItem("isShowChatroom", "yes")
            }
            else {
                toast("Messaging has been disabled.", {
                    type: ToastType.ERROR,
                    autoClose: 2000,
                });
                return;
            }
        }
    };

    const [, setOpenmemberDetailsProfilePopup] = useAtom(memberDetailsProfilePopupAtom);

    const closePeopleDialog = () => {
        setOpenmemberDetailsProfilePopup(false)
    }

    const handleCopyClick = (copyText: string, textName: string) => {
        window.navigator.clipboard.writeText(`${copyText}`);
        toast(`${textName} copied.`, {
            type: ToastType.SUCCESS,
            autoClose: 3000
        });
    };

    const getTimeToCourseExpiry = () => {
        if(!(enrolment && enrolment.expires_at)) {
            return {
                unit: '',
                value: 0,
            }
        } 

        const hoursToExpiry = Math.abs(dayjs.utc(enrolment.expires_at).diff(dayjs.utc(), 'hours'));
    
        if(hoursToExpiry < HOURS_IN_A_DAY) {
          return {
            unit: 'hour',
            value: hoursToExpiry,
          }
        }
    
        return {
          unit: 'day',
          value: Math.abs(Math.round(dayjs.utc(enrolment.expires_at).diff(dayjs.utc(), 'hours') / HOURS_IN_A_DAY)),
        }
    }

    return (
        <div className='header-member-detail'>
            {isLoading || !userMembership ?
                <ContentLoader
                    height={window.innerWidth <= 500 ? 90 : 60}
                    width={120}
                    speed={2}
                    primaryColor="#d9d9d9"
                    secondaryColor="#ecebeb"
                    viewBox="0 0 120 60"
                >
                    <circle cx='10' cy={10} r={10} />

                    <rect x="24" y="0" rx="2" ry="2" width={40} height={window.innerWidth <= 500 ? 4 : 4} />
                    <rect x="24" y="8" rx="2" ry="2" width={window.innerWidth <= 500 ? 80 : 60} height={window.innerWidth <= 500 ? 4 : 4} />
                    <rect x="24" y="16" rx="2" ry="2" width={40} height={window.innerWidth <= 500 ? 4 : 4} />

                    <rect x="0" y="30" rx="2" ry="2" width={90} height={4} />
                    <rect x="0" y="36" rx="2" ry="2" width={90} height={4} />

                    <rect x="0" y="48" rx="2" ry="2" width={40} height={10} />

                </ContentLoader>
                :
                <>
                    {member &&
                        <>
                        <div className='header-member-detail-head'>
                            <div className="profile-pic-container">
                                {member.profile_image ? (
                                    <img
                                        alt='user profile'
                                        className='profile-image'
                                        src={member.profile_image}
                                    />
                                ) : (
                                    <div
                                        className='profile-image'
                                        data-color={miscUtils.getColorInitial(`${member.full_name}`)}
                                    >
                                        {miscUtils.getNameInitials(member.full_name)}
                                    </div>
                                )}
                            </div>
                            <div className='name-actions'>

                                <div className={`name-details ${nonMember ? 'non-name-details' : ''}`}>
                                    <div className='name'>
                                        <h1>{member.full_name}</h1>
                                        {
                                            miscUtils.isTeamMember(group.user_membership) &&
                                            <span style={{ width: '30px' }}>
                                                {
                                                    member.verified
                                                        ?
                                                        <Verified />
                                                        :
                                                        <NotVerified />
                                                }
                                            </span>
                                        }
                                    </div>
                                    <div className='date-joined'>
                                        {userMembership.membership.approved_on &&
                                            <h4>Joined {moment.utc(userMembership.membership.approved_on).local().format('MMM D, YYYY')}</h4>
                                        }
                                    </div>
                                    {(userMembership && userMembership.membership.request_status === 'member')
                                        &&
                                        <div className={`role ${determinePrivilege()}`}>
                                            <span>
                                                {determinePrivilege()}
                                            </span>
                                        </div>
                                    }
                                </div>
                                {
                                    (
                                        (
                                            // !miscUtils.isOwner(userMembership.membership) &&
                                            Admin
                                        ) || courseSection)
                                    &&
                                    <div className='member-actions'>
                                        <Dropdown
                                            showMenuDots
                                            tippyCompatibleActions
                                            actions={actions}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            miscUtils.isTeamMember(group.user_membership) &&
                            <div className='middle'>
                                    <div
                                        className='first'
                                        onMouseEnter={() => setShowCopyIcon(true)}
                                        onMouseLeave={() => setShowCopyIcon(false)}
                                    >
                                        <>
                                            <Email />
                                            <h1> {member.email} </h1>
                                            {userMembership && userMembership.email_blacklisted ?
                                                <span className='bell-icon'>
                                                    <MuteBell />
                                                </span>
                                                :
                                                <span className='bell-icon'>
                                                    <RingBell />
                                                </span>

                                            }
                                        </>
                                        {
                                            showCopyIcon &&
                                            <span
                                                    onClick={() => handleCopyClick(member.email, 'Email')}
                                                className='copy-icon'
                                            >
                                                <CopyIcon />
                                            </span>
                                        }
                                    </div>

                                    <div className='second'>
                                        <Clock />
                                        <h4>Last seen {moment.utc(userMembership.last_seen).local().format('MMM D, YYYY')} at {moment.utc(userMembership.last_seen).local().format('h:mm A')}</h4>
                                    </div>
                                </div>
                        }

                        {/* Enrollment status section */}
                        {
                            courseSection &&
                            <div>
                                <div className='enrollment-status'>
                                    <EnrollmentIcon />
                                    <h4>Enrollment status:</h4>
                                    <span style={{ background: EnrollmentStatus ? '#f46c6c' : "#ebf9eb" }}>{EnrollmentStatus ? 'Expired' : 'Active'}</span>
                                </div>

                                {
                                    EnrollmentDaysLeft !== null && !EnrollmentStatus &&
                                    <div className='enrollment-status'>
                                        <EnrollmentDaysIcon />
                                        <h4>Access duration:</h4>
                                            <span className='enrollment-status-days-left'>{getTimeToCourseExpiry().value} {getTimeToCourseExpiry().unit}{getTimeToCourseExpiry().value == 1 ? '' : 's'} left</span>
                                    </div>
                                }
                                </div>
                        }

                        {
                            getCookieValue("user")['uuid'] !== (member && member.uuid) &&
                            userMembership && userMembership.membership.request_status === 'member' &&
                            <div className='footer' onClick={() => openMessage()}>
                                <Message />
                                <h4>Message</h4>
                            </div>
                        }
                        </>
                    }

                </>
            }
        </div >

    )
}

export default HeaderMemberDetails