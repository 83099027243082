import React from 'react';
import anchorme from 'anchorme';
import { ButtonAction, scrollElementIntoView } from 'containers/PageBuilder/SinglePageContainer/domain';
import { IEventBlockPreviewProps } from './IEventBlockPreviewProps';
import { BannerBlockBackgroundImage, BannerBlockContainer, BannerBlockContentContainer } from '../BannerBlockPreview/BannerBlockPreview';
import { BlockBackgroundImageGradient } from '../BlockComponents/BlockBackgroundImage';
import BlockTextContainer, { BlockSubTextContainer } from '../BlockComponents/BlockTextContainer';
import BlockButton from '../BlockComponents/BlockButton';

const EventBlockPreview: React.FC<IEventBlockPreviewProps> = (props) => {

    const ctaOnClick = () => {
        if(props.buildingPreview) {
            return;
        }

        if(
            props.blockData &&
            (
                props.blockData.button_action === ButtonAction.URL ||
                props.blockData.button_action === ButtonAction.URLTab
            ) &&
            props.blockData.button_url
        ) {
            window.open(
                props.blockData.button_url, 
                props.blockData.button_action === ButtonAction.URLTab ?
                '_blank' :
                '_self'
            );
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Scroll &&
            props.blockData.button_url &&
            props.containerBaseIdName
        ) {
            scrollElementIntoView(`${props.containerBaseIdName}${props.blockData.button_url}`);
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Popup &&
            props.blockData.button_url
        ) {
            let nextPageFormsOpenState = JSON.parse(JSON.stringify(props.pageFormsOpenState));

            nextPageFormsOpenState[props.blockData.button_url] = true;

            props.setPageFormsOpenState(nextPageFormsOpenState);
        }
    }

  return (
    <BannerBlockContainer
    design={props.blockData.design}
    >
        {props.blockData.image_url ? (
            <>
            <BannerBlockBackgroundImage
            design={props.blockData.design}
            src={props.blockData.image_url}
            alt={props.blockData.image_alt}
            />
            <BlockBackgroundImageGradient
            />
            </>
        ) : <></>}

        <BannerBlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
            <BlockTextContainer
            design={props.blockData.design}
            pageStyles={props.pageStyles}
            textKey="heading"
            fallbackTextColor="#FFFFFF"
            dangerouslySetInnerHTML={{
                __html: anchorme(props.blockData.heading),
            }}
            />


            <BlockSubTextContainer
            design={props.blockData.design}
            pageStyles={props.pageStyles}
            textKey="subtext"
            fallbackTextColor="#FFFFFF"
            dangerouslySetInnerHTML={{
                __html: anchorme(props.blockData.subtext),
            }}
            />

            {props.blockData.button_shown ? (
                <BlockButton
                design={props.blockData.design}
                pageStyles={props.pageStyles}   
                onClick={ctaOnClick}
                >
                    {props.blockData.button_text}
                </BlockButton>
            ) : <></>}
        </BannerBlockContentContainer>
    </BannerBlockContainer>
  )
}

export default EventBlockPreview;