import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class OnboardingService {
  private dbInstance: any = databaseInstance;

  private static instance: OnboardingService;

  constructor() {
    OnboardingService.instance = this;
  }

  public static getInstance() {
    if (!OnboardingService.instance) {
      OnboardingService.instance = new OnboardingService();
    }
    return OnboardingService.instance;
  }

  public getMembershipQuestionsConfig: (
    groupId: number,
  ) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/membership-questions-config`)
        .then((response: any) => resolve(response));
    });
  };

  public createMembershipQuestionsConfig: (
    groupId: number,
    config: any,
  ) => Promise<void> = (groupId, config) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
       .post(`/groups/${groupId}/membership-questions-config`, config)
       .then((response: any) => resolve(response));
    });
  }

  public updateMembershipQuestionsConfig: (
    groupId: number,
    config: any,
    configId: number,
  ) => Promise<void> = (groupId, config, configId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/membership-questions-config/${configId}`, config)
      .then((response: any) => resolve(response));
    });
  }

  public getMembershipQuestions: (
    groupId: number,
  ) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/membership-questions`)
      .then((response: any) => resolve(response));
    })
  }

  public createMembershipQuestion: (
    groupId: number,
    question: any,
  ) => Promise<void> = (groupId, question) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/membership-questions`, question)
      .then((response: any) => resolve(response))
      .catch((error: any) => reject(error));
    })
  }

  public updateMembershipQuestion: (
    groupId: number,
    questionId: number,
    updatedQuestion: any,
  ) => Promise<void> = (groupId, questionId, updatedQuestion) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/membership-questions/${questionId}`, updatedQuestion)
      .then((response: any) => resolve(response))
      .catch((error: any) => reject(error));
    })
  }

  public deleteMembershipQuestion: (
    groupId: number,
    questionId: number,
  ) => Promise<void> = (groupId, questionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/membership-questions/${questionId}`)
      .then(() => resolve());
    })
  }

  public deleteMembershipQuestionOption: (
    groupId: number,
    questionId: number,
    optionId: number,
  ) => Promise<void> = (groupId, questionId, optionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/membership-questions/${questionId}/answers/${optionId}`)
      .then(() => resolve());
    })
  }

  public respondToMembershipQuestions: (
    groupId: number,
    requestId: number,
    userResponse: any,
  ) => Promise<void> = (groupId, requestId, userResponse) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/membership-requests/${requestId}/screening-response`, userResponse)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public skipMembershipQuestions: (
    groupId: number,
    requestId: number,
  ) => Promise<void> = (groupId, requestId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/membership-requests/${requestId}/skip-screening`)
      .then(() => resolve());
    })
  }

  public updateHomePageConfig: (
    groupId: number,
    configId: number,
    config: any,
  ) => Promise<void> = (groupId, configId, config) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/home-page-config/${configId}`, config)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public getHomePageConfigDetails: (
    groupId: number,
  ) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/home-page-config-detail`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public saveHomePageConfigDetails: (
    groupId: number,
    configDetails: any,
  ) => Promise<void> = (groupId, configDetails) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/home-page-config-detail`, configDetails)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }
}
