import React from 'react';

const VideoIcon = (props: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path d="M19.82 2H4.18C2.97602 2 2 2.97602 2 4.18V19.82C2 21.024 2.97602 22 4.18 22H19.82C21.024 22 22 21.024 22 19.82V4.18C22 2.97602 21.024 2 19.82 2Z" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 2V22" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 2V22" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 12H22" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 7H7" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 17H7" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 17H22" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17 7H22" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
  )
}

export default VideoIcon