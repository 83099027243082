import React, { createContext, useState } from "react";
import "./userProfile.scss";

import { Map } from "immutable";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { authorizeActions } from "../../../store/actions";
import UserProfileDetails from "./userProfileDetails";
import UserProfileHeader from "./userProfileHeader";
import UserComments from "./userComments";
import UserEvents from "./userEvents";
import UserPosts from "./userPosts";
import { UserService } from "api/services/users/UserService";
import login from "containers/login";
import { CommonConstants } from "constants/common";
import store from "store/configureStore";
import { setCookieValue } from "utils/cookieStorage";
import imageCompression from "browser-image-compression";
import miscUtils from "utils/miscUtils";
import { toast, ToastType } from "react-toastify";
import { GroupService } from "api/services/groups";

interface IUserProfileDetailsComponentProps {
  logout: any;
  uid: number;
  group: any;
}

export const UserProfileContext = createContext<any>({});

const UserProfile: React.FC<IUserProfileDetailsComponentProps> = (props) => {
  const { group, uid, logout } = props

  const [activeTab, setActiveTab] = useState("profile");
  const [groupData, setGroupData] = useState<any>(group)
  const [userPosts, setUserPosts] = useState<any>(null);
  const [showCustomEditMode, setShowCustomEditMode] = React.useState<boolean>(true);


  const refreshGroupInformations = () => {
    GroupService.getInstance()
      .getGroup(group && group.slug)
      .then((groupData: any) => {
        if (groupData) {

          setGroupData(groupData);
        }
      });
  }

  const getUserPosts = async () => {
    await UserService.getInstance()
      .getUserAuthoredPosts(groupData !== undefined && groupData, uid)
      .then((res: any) => {
        if (res && res.data) {
          setUserPosts(res.data);
        }
      });
  };
  React.useEffect(() => {
    refreshGroupInformations()
    getUserPosts()
  }, [group !== undefined && group.id])


  const [isUpdated, setIsUpdated] = useState(false)
  const [userData, setUserData] = useState<any>([]);
  const [editData, setEditData] = useState<any>([]);
  const [profileSrc, setProfileSrc] = useState<any>(null);
  const [profileNewSrc, setProfileNewSrc] = useState<any>(null);
  const [profileFile, setProfileFile] = useState<any>(null);
  const [showProfilePhotoModal, setShowProfilePhotoModal] = useState<boolean>(false)


  const handleTabClick = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
    refreshGroupInformations()
  };

  React.useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = (isUserUpdated?: boolean) => {
    UserService.getInstance()
      .getUserProfile(uid)
      .then((userResponse: any) => {
        setUserData(userResponse.data);
        if (userResponse.data.profile_image) {
          setProfileSrc(userResponse.data.profile_image);
        }
        if (isUserUpdated) {
          store.store.dispatch(login(userResponse.data));
          setCookieValue(CommonConstants.USER_KEY, userResponse.data);
        }
      });
  };

  const options = {
    maxSizeMB: 10,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };


  const handleForm = () => {
    const error = false;
    const errorLimit = false;

    if (!error) {
      const editPromises: any[] = [];

      editPromises.push(
        UserService.getInstance().updateUserProfile(uid, editData)
      );


      if (profileFile) {
        imageCompression(profileFile, options).then(function (
          compressedFile: any
        ) {
          const dataImage = new FormData();
          dataImage.append(
            "profile_image",
            miscUtils.blobToFile(compressedFile, profileFile)
          );
          editPromises.push(
            UserService.getInstance().updateUserProfileImage(uid, dataImage)
          );
        });
      }

      if (!errorLimit) {
        Promise.all(editPromises).then(() => {
          setTimeout(() => getUserDetails(true), 2000);
          toast("Successfully saved changes!", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
        });
      }
    }
  };

  return (
    <UserProfileContext.Provider
      value={{
        editData,
        setEditData,
        userData,
        setUserData,
        uid,
        logout,
        group,
        profileSrc,
        getUserDetails,
        profileNewSrc,
        setProfileNewSrc,
        profileFile, setProfileFile,
        showProfilePhotoModal, setShowProfilePhotoModal,
        setProfileSrc,
        handleForm,
        refreshGroupInformations,
        groupData,
        isUpdated, setIsUpdated,
        showCustomEditMode, setShowCustomEditMode
      }}
    >
      <div className="user-profile-container">

        <UserProfileHeader
          group={group}
          userData={userData}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          userId={uid}
          profileSrc={profileSrc}
        />

        {activeTab === "profile" &&
          <UserProfileDetails
            logout={logout}
            uid={uid}
            userData={userData}
            setUserData={setUserData}
          />}

        {activeTab === "posts" &&
          <UserPosts
            userId={uid}
          />
        }


        {activeTab === "events" &&
          <UserEvents
            groupId={group && group.id}
            userId={uid}
          />}

        {activeTab === "comments" &&
          <UserComments
            group={group}
            userId={uid}
            userPosts={userPosts}
          />}

      </div >
    </UserProfileContext.Provider>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IUserProfileDetailsComponentProps
) => {
  return {
    logout: () => {
      dispatch(authorizeActions.dbLogout());
    }
  };
};

const mapStateToProps = (
  state: Map<string, any>,
  ownProps: IUserProfileDetailsComponentProps
) => {
  const uid: string = state.getIn(["authorize", "uid"]) as string;
  return {
    uid
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile as any) as any
);
