import React, { useState } from 'react'

import './bio-details.scss'
import { ReactComponent as MemberIcon } from 'assets/img/member.svg'
import { ReactComponent as CopyIcon } from "assets/img/event-copy-bold.svg";

import ReadMore from 'utils/readMore/readMore'
import { ToastType, toast } from 'react-toastify';
import { AdminMemberDetailsContext } from '../AdminMemberDetails';
import ContentLoader from 'react-content-loader';
import miscUtils from 'utils/miscUtils';


const BioMemberDetails = ({ user, group }: { user: any, group?: any }) => {

    const member = user && user.user
    const { isLoading, settingsPage } = React.useContext(AdminMemberDetailsContext)
    const [showCopyIcon, setShowCopyIcon] = useState<any>('')
    const displayPubliclyOnProfile = group && group.display_fields

    const handleCopyClick = (copyText: string, textName: string) => {
        window.navigator.clipboard.writeText(`${copyText}`);
        toast(`${textName} copied.`, {
            type: ToastType.SUCCESS,
            autoClose: 3000
        });
    };

    const Admin = Boolean(group && group.user_membership &&
        ((miscUtils.isAdmin(group.user_membership))
            || miscUtils.isModerator((group.user_membership))))

    return (
        <div className={`bio-container ${settingsPage ? 'admin-mgtop' : ''} ${displayPubliclyOnProfile === null ? 'unavailble' : ''}`}>
            <div className="header">
                <MemberIcon />
                <h1>Member details</h1>
            </div>

            {isLoading ?
                <>
                    {
                        Array(7).fill('').map((_e, i) => (
                            <div
                                style={
                                    {
                                        width: '100%',
                                    }
                                }
                            >
                                <ContentLoader
                                    height={window.innerWidth <= 500 ? 90 : 20}
                                    width={120}
                                    speed={2}
                                    primaryColor="#d9d9d9"
                                    secondaryColor="#ecebeb"
                                    viewBox="0 0 120 20"
                                >
                                    <rect x="0" y="0" rx="2" ry="2" width={60} height={4} />
                                    <rect x="0" y="8" rx="2" ry="2" width={90} height={4} />
                                </ContentLoader>
                            </div>
                        ))}
                </>
                :
                <>
                    {
                        user &&
                        <div className='body-section'>
                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Job title'))) &&
                                <div>
                                    <span>Job title</span>
                                    {member.job_title
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.job_title}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Job title'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }
                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Headline'))) &&
                                <div>
                                    <span>Headline</span>
                                    {member.headline
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.headline}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Headline'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }
                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Country'))) &&
                                <div>
                                    <span>Country</span>
                                    {member.country
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.country.name}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Country name'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }

                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('City'))) &&
                                <div>
                                        <span>City</span>
                                    {member.location
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.location}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Location name'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }
                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Timezone'))) &&
                                <div>
                                    <span>Timezone</span>
                                    {member.timezone
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.timezone.text}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Timezone'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }
                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Bio'))) &&
                                <div>
                                    <span>Bio</span>
                                    {member.about_me ?
                                        <div
                                            className='copy-bio'
                                            onMouseEnter={() => setShowCopyIcon('Bio')}
                                            onMouseLeave={() => setShowCopyIcon(false)}
                                        >
                                            <h4
                                                className={`bio-details ${settingsPage ? 'settings-max-width' : ''}`}
                                            >
                                                {member.about_me && member.about_me.length > 100 ? (
                                                    <ReadMore
                                                        text={member.about_me}
                                                        readMoreCharacterLimit={100}
                                                        numberOfLines={1}
                                                        lineHeight={1}
                                                        showLessButton
                                                        onContentChange={() => { }}
                                                        showMoreText="Read full bio"
                                                        showLessText="Hide full bio"
                                                    />
                                                ) :
                                                    (member.about_me)
                                                }
                                            </h4>
                                            {
                                                showCopyIcon === 'Bio' &&
                                                <span
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: '18px',
                                                        height: '18px',
                                                    }}
                                                    onClick={() => handleCopyClick(member.about_me, 'Bio')}
                                                >
                                                    <CopyIcon />
                                                </span>
                                            }
                                        </div>
                                        :
                                        <h4>-</h4>
                                    }
                                </div>
                                }
                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Web URL'))) &&
                                <div>
                                    <span>Website URL</span>
                                    {member.website
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.website}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Website URL'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }
                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('LinkedIn'))) &&
                                <div>
                                    <span>LinkedIn URL</span>
                                    {member.linkedin
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.linkedin}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'LinkedIn URL'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }

                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Facebook'))) &&
                                <div>
                                    <span>Facebook URL</span>
                                    {member.facebook
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.facebook}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Facebook URL'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }

                                {(Admin || (displayPubliclyOnProfile && displayPubliclyOnProfile.includes('Instagram'))) &&
                                <div>
                                    <span>Instagram URL</span>
                                    {member.instagram
                                        ?
                                        <DetailLine
                                            showCopyIcon={showCopyIcon}
                                            setShowCopyIcon={setShowCopyIcon}
                                            line={member.instagram}
                                            handleCopyClick={handleCopyClick}
                                            lineTitle={'Instagram URL'}
                                        />
                                        :
                                        <h4> - </h4>
                                    }
                                </div>
                                }
                        </div>
                    }
                </>
            }
        </div>
    )
}

const DetailLine = (
    {
        showCopyIcon,
        setShowCopyIcon,
        line,
        handleCopyClick,
        lineTitle
    }:
        {
            showCopyIcon: any
            setShowCopyIcon: any
            line: any
            handleCopyClick: any
            lineTitle: string
        }
) => {
    const { settingsPage } = React.useContext(AdminMemberDetailsContext)

    return (
        <div
            onMouseEnter={() => setShowCopyIcon(lineTitle)}
            onMouseLeave={() => setShowCopyIcon(false)}
            className="copy"
        >
            <h4
                style={{ maxWidth: settingsPage ? '330px' : '' }}
            >
                {line}
            </h4>
            {
                lineTitle === showCopyIcon &&
                <span
                    className='copy-icon-wrapper'
                    onClick={() => handleCopyClick(line, lineTitle)}>
                    <CopyIcon />
                </span>
            }
        </div >
    )
}

export default BioMemberDetails