import React from 'react';
import ReactModal from 'react-modal';
import { IReactersProps } from './IReactersProps';
import './Reacters.scss';
import useWindowSize from 'utils/windowSize';
import AppLoader from 'components/contentLoaders/appLoader';
import { ReactComponent as XIcon } from './icons/x.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollSpinner from 'components/scrollSpinner';
import Reacter from './Reacter/Reacter';


const Reacters: React.FC<IReactersProps> = (props) => {
  const windowSize = useWindowSize()
  return (
    <ReactModal
          isOpen={props.isOpen}
          style={{
              content: {
                width: windowSize.width <= 550 ? "100vw" : "320px",
                maxWidth: "100vw",
                height:  windowSize.width <= 550 ? "100vh" : "372px",
                borderRadius: windowSize.width <= 550 ? 0 : "4px",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                transform: "translate(-50%, -50%)",
                position: "fixed",
                background: "white",
                border: 0,
                padding: 0,
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 99999,
              }
            }}
          >
          <div 
          className="reacters-container"
          >
              {props.loadingReacters && (
                  <AppLoader
                  label="Loading..."
                  />
              )}
              {!props.loadingReacters && props.reactersData && (
                  <>
                  <div className="reacters-header-container">
                      <h2 className="reacters-header-title">{props.reactersData.total} Like{props.reactersData.total !== 1 ? 's' : ''}</h2>
                      <span 
                      className="reacters-header-x-icon"
                      onClick={() => {
                          props.setIsOpen(false);
                      }}
                      >
                        <XIcon />
                      </span>
                  </div>
                  <div className="reacters-content-container" id="reactors-content-container-scrollarea">
                    {props.reactersData.total === 0 ? (
                        <div className="reacters-content-empty-container">
                            <p className="reacters-content-empty-text">No likes yet!</p>
                        </div>
                    ) : (
                        <InfiniteScroll
                            scrollableTarget="reactors-content-container-scrollarea"
                            dataLength={props.reactersData.data.length}
                            next={() => {
                                props.loadMoreReacters();
                            }}
                            hasMore={props.reactersData.data.next_page_url}
                            loader={<ScrollSpinner label="loading more reacters" />}
                        >
                            {props.reactersData.data.map((reacter: any, index: any) => (
                            <Reacter
                                key={index}
                                reacter={reacter}
                            />
                            ))}
                        </InfiniteScroll>
                    )}
                  </div>
                  </>
              )}
          </div>
          </ReactModal>
  )
}

export default Reacters;