import { isValidHexColor } from "components/PageBuilder/PageBuilderSideBar/BlockSettings/domain";
import { ButtonStyles } from "containers/PageBuilder/SinglePageContainer/domain";
import styled from "styled-components";

const BlockButton: any = styled.button<{
    design: any, 
    pageStyles: any
}>`
    white-space: pre-wrap;
    transition: .5s;
    margin-top: 20px;
    padding: 14px 24px;

    font-weight: 700;

    background-color: ${(props: any) => 
        props.pageStyles.button.button_style === ButtonStyles.Line ?
        'transparent' :
        props.design.button_background_color &&
        isValidHexColor(props.design.button_background_color) ?
        props.design.button_background_color :
        '#EE8F00'
    };
    color: ${(props: any) => 
        props.design.button_text_color &&
        isValidHexColor(props.design.button_text_color) ?
        props.design.button_text_color :
        '#FFFFFF'
    };
    border: ${(props: any) => 
        props.pageStyles.button.button_style === ButtonStyles.Line ?
        props.design.button_background_color &&
        isValidHexColor(props.design.button_background_color) ?
        `1px solid ${props.design.button_background_color}` :
        `1px solid #EE8F00` :
        'none'
    };
    border-radius: ${(props: any) => 
        props.design.button_border_radius &&
        props.design.button_border_radius <= 30 ?
        `${props.design.button_border_radius}px`:
        `4px`
    };
    font-size: ${(props: any) => 
        props.pageStyles.button.button_text_font_size <= 18 ?
        `${props.pageStyles.button.button_text_font_size}px` :
        `18px`
    };
    line-height: ${(props: any) => 
        props.pageStyles.button.button_text_line_height <= 22 ?
        `${props.pageStyles.button.button_text_line_height}px` :
        `22px`
    };
    font-family: ${(props: any) =>
        props.pageStyles.fonts.p.font_family ?
        `${props.pageStyles.fonts.p.font_family} !important` :
        `-apple-system, Arial !important`
    };
    background-image: ${(props: any) => 
        props.pageStyles.button.button_style === ButtonStyles.Gradient ?
        `linear-gradient(to top, rgba(0, 0, 0, .3), ${
            props.design.button_background_color &&
            isValidHexColor(props.design.button_background_color) ?
            props.design.button_background_color :
            '#EE8F00'
        })` :
        'none'
    };

    &:hover {
        color: ${(props: any) => 
            props.pageStyles.button.button_hover_text_color &&
            isValidHexColor(props.pageStyles.button.button_hover_text_color) ?
            props.pageStyles.button.button_hover_text_color :
            props.design.button_text_color &&
            isValidHexColor(props.design.button_text_color) ?
            props.design.button_text_color :
            '#FFFFFF'
        };
    }
`;

export default BlockButton;