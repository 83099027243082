import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import posed from "react-pose";

import "./Modal.scss";

function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const modalBackgroundPoses = {
  open: {
    background: "rgba(0, 0, 0, 0.5)",
    applyAtStart: {
      display: "block"
    }
  },
  closed: {
    background: "rgba(0, 0, 0, 0)",
    applyAtEnd: {
      display: "none"
    }
  }
};

const ModalBackground = styled(posed.div(modalBackgroundPoses))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
`;

const modalPoses = {
  open: {
    opacity: 1,
    transition: {
      opacity: {
        type: "tween",
        duration: 50
      }
    }
  },
  closed: {
    opacity: 0,
    transition: {
      opacity: {
        type: "tween",
        duration: 50
      }
    }
  }
};

const Modal = styled(posed.div(modalPoses))`
  position: fixed;
  background: white;
  width: ${(props: any) => (props.size ? props.size : "544px")};
  height: auto;
  max-height: calc(100vh - 100px);
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: .3rem;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
  overflow: auto;
  max-width: ${(props: any) => (props.fullScreen ? "calc(100vw - 10px)" : "calc(100vw - 20px)")};
`;

export default function ({ isOpen, toggle, children, size, fullScreen }: any) {
  const ref = useRef();

  useOnClickOutside(ref, () => toggle(false));

  useEffect(() => {
    addClassToBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function addClassToBody() {
    if (isOpen) {
      document.body.classList.add("modal-open");
      document.body.classList.add("stop-scrolling");
    } else {
      document.body.classList.remove("modal-open");
      document.body.classList.remove("stop-scrolling");
    }
  }

  return (
    <ModalBackground initialPose="closed" pose={isOpen ? "open" : "closed"}>
      <Modal size={size} fullScreen={fullScreen}>{children}</Modal>
    </ModalBackground>
  );
}
