import React from 'react';
import { IImageTextBlockPreviewProps } from './IImageTextBlockPreviewProps';
import anchorme from 'anchorme';
import { ButtonAction, scrollElementIntoView } from 'containers/PageBuilder/SinglePageContainer/domain';
import BlockContainer from '../BlockComponents/BlockContainer';
import styled from 'styled-components';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import BlockImage from '../BlockComponents/BlockImage';
import BlockTextContainer, { BlockSubTextContainer } from '../BlockComponents/BlockTextContainer';
import BlockButton from '../BlockComponents/BlockButton';

const ImageTextBlockContentContainer: any = styled(BlockContentContainer)`
    flex-direction: row;
    align-items: center;

    &.right {
        flex-direction: row-reverse;
    }

    &.mobile {
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        &.right {
            flex-direction: column;
        }
    }

    @media(max-width: 801px) {
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        &.right {
            flex-direction: column;
        }
    }
`;

const ImageTextBlockImage: any = styled(BlockImage)`
    width: ${(props: any) =>
        props.design.image_width ? 
        `${props.design.image_width}px` : 
        `100%`
    };
    max-width: 448px;

    &.mobile {
        max-width: 100%;
    }

    @media(max-width: 801px) {
        max-width: 100%;
    }
`;

export const ImageTextBlockGutter: any = styled.div`
    min-width: 20px;

    &.mobile {
        min-height: 10px;
    }

    @media(max-width: 801px) {
        min-height: 10px;
    }
`;

export const ImageTextBlockTextContainer: any = styled.div<{design: any}>`
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props: any) => 
        props.design.text_alignment === 'left' ?
        `flex-start` :
        props.design.text_alignment === 'right' ?
        `flex-end` :
        props.design.text_alignment
    }

    &.mobile {
        padding: 0;
    }

    @media(max-width: 801px) {
        padding: 0;
    }
`;

const ImageTextBlockPreview: React.FC<IImageTextBlockPreviewProps> = (props) => {

    const onClickImage = () => {
        if(props.buildingPreview) {
            return;
        }
        
        if(
            props.blockData &&
            props.blockData.image_link
        ) {
            window.open(props.blockData.image_link, '_blank');
        }
    }

    const ctaOnClick = () => {
        if(props.buildingPreview) {
            return;
        }

        if(
            props.blockData &&
            (
                props.blockData.button_action === ButtonAction.URL ||
                props.blockData.button_action === ButtonAction.URLTab
            ) &&
            props.blockData.button_url
        ) {
            window.open(
                props.blockData.button_url, 
                props.blockData.button_action === ButtonAction.URLTab ?
                '_blank' :
                '_self'
            );
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Scroll &&
            props.blockData.button_url &&
            props.containerBaseIdName
        ) {
            scrollElementIntoView(`${props.containerBaseIdName}${props.blockData.button_url}`);
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Popup &&
            props.blockData.button_url
        ) {
            let nextPageFormsOpenState = JSON.parse(JSON.stringify(props.pageFormsOpenState));

            nextPageFormsOpenState[props.blockData.button_url] = true;

            props.setPageFormsOpenState(nextPageFormsOpenState);
        }
    }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <ImageTextBlockContentContainer
        className={`${props.blockData.image_layout} ${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
            <ImageTextBlockImage
            className={`${props.view ? props.view : ''}`}
            design={props.blockData.design}
            src={props.blockData.image_url}
            alt={props.blockData.image_alt}
            onClick={onClickImage}
            />

            <ImageTextBlockGutter
            className={`${props.view ? props.view : ''}`}
            />

            <ImageTextBlockTextContainer
            className={`${props.blockData.image_layout} ${props.view ? props.view : ''}`}
            design={props.blockData.design}
            >
                <BlockTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="heading"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.heading),
                }}
                />

                <BlockSubTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="subtext"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.subtext),
                }}
                />

                {props.blockData.button_shown ? (
                    <BlockButton
                    design={props.blockData.design}
                    pageStyles={props.pageStyles}
                    onClick={ctaOnClick}
                    >
                        {props.blockData.button_text}
                    </BlockButton>
                ) : <></>}
            </ImageTextBlockTextContainer>
        </ImageTextBlockContentContainer>
    </BlockContainer>
  )
}

export default ImageTextBlockPreview;