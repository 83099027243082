import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./VerifyAccount.scss";
import "./VerifyAccount-responsive.scss";

import { Map } from "immutable";
import { useDispatch, connect } from "react-redux";
import { AuthorizeService } from "../../api/services/authorize";
import { IVerifyAccountComponentProps } from "./IVerifyAccountComponentProps";
import CodeInput from "@m3-moretv/react-code-input";
import store from "../../store/configureStore";
import { login } from "../../store/actions/authorizeActions";
import { setCookieValue } from "../../utils/cookieStorage";
import { CommonConstants } from "../../constants/common";
import { toast, ToastType } from "react-toastify";
import ReactPixel from "react-facebook-pixel";
import { setTimeout } from "timers";
import { authorizeActions } from "store/actions";
import {
  useQueryClient,
} from 'react-query';

const VerifyAccountComponent: React.FC<IVerifyAccountComponentProps> = props => {

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const [verificationCode, setVerificationCode] = useState<any>();
  const [codeIsInvalid, setCodeIsInvalid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [redirectHome, setRedirectHome] = useState<boolean>(false);
  const [showPage, setShowPage] = useState<boolean>(false);

  const { uid, user, location, groupId, CommuinityTheme } = props;

  const query = new URLSearchParams(props.history.location.search);
  const cm = query.get("cm");
  const utm_content = query.get("utm_content");

  useEffect(() => {
    ReactPixel.pageView();
    const query = new URLSearchParams(props.history.location.search);
    const s = query.get("s");
    if (s && s === "false") {
      setRedirectHome(true);
    }
    if (user && user.verified) {
      props.history.push("/create-community?s=true");
    }
    setTimeout(() => {
      setShowPage(true);
    }, 1000)
  }, []);

  function verifyAccount() {
    AuthorizeService.getInstance()
      .verifyAccount(uid, verificationCode)
      .then(response => {
        store.store.dispatch(login(response.data));
        setCookieValue(CommonConstants.USER_KEY, response.data);
        ReactPixel.trackCustom('Account Verified');
        if (redirectHome) {
          return props.history.push("/");
        }

        // @ts-ignore
        window.fpr("referral", {email: response.data.email})

        const query = new URLSearchParams(location.search);
        const queryString = query.toString();
        props.history.push(`/create-community${queryString.length > 0 ? `?${queryString}` : ""}`);
      })
      .catch(error => {
        setErrorMessage(error.data.message);
        setCodeIsInvalid(true);
      });
  }

  function updateVerificationCode(newValue: any) {
    setVerificationCode(newValue);
  }

  function resendCode() {
    AuthorizeService.getInstance()
      .resendVerificationCode(groupId)
      .then(() => {
        setVerificationCode(null);
        setCodeIsInvalid(false);
        toast("Verification code has been sent.", {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
      });
  }

  return (
    <>
      {user && showPage && (
        <div className="new-verify">
        <div className="new-signup-logo" />
          <div className="new-verification-content">
            <h2>Please verify account</h2>
          <p>
            Enter the six digit code we sent to <b>{user.email}</b> email address
            to verify your account. If you don't see it, you may need to check
            your spam folder.
          </p>
          <div className="verification-code">
            <p>
              <span>Enter verification code</span>
              <button onClick={resendCode}>Resend code</button>
            </p>
            <div className="verification-code-input">
              <CodeInput
                onChange={newValue => {
                  updateVerificationCode(newValue);
                }}
                fields={6}
                value={""}
                name={"verificationCode"}
              />
            </div>
            {codeIsInvalid && <span className="error"> {errorMessage}</span>}
              <button
                style={CommuinityTheme ? { backgroundColor: CommuinityTheme.primaryColor, color: CommuinityTheme.fontColor, border: 'none' } : {}}
                className="btn btn-new-signup" onClick={verifyAccount}>
                Verify & Continue
              </button>
              {!cm && !(utm_content && utm_content.startsWith("sumo")) ? (
                <button
                className="gp-verify-change-email"
                onClick={() => {
                  queryClient.clear();
                  dispatch(authorizeActions.dbLogout());
                  props.history.push('/signup');
                }}
                >
                  Log out
                </button>
              ) : (<></>)}
          </div>
        </div>
      </div>
      )}
    </>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IVerifyAccountComponentProps
) => {
  return {};
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const user: any = state.getIn(["authorize", "user"]);

  return {
    user,
    uid: authorize.uid
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyAccountComponent as any) as any
);
