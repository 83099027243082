import React, { useState, useEffect } from "react";
import "./search-for-members.scss";
import "./search-for-members-responsive.scss";
import useComponentVisible from "../../../../utils/visible/visible";
import { ISearchForMembersProps } from "./ISearchForMembersProps";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { GroupService }  from "../../../../api/services/groups";
import UserComponent from "components/userComponent";
import { useAtom } from "jotai";
import { showNewDirectMessageMenuAtom } from "showMessageMenuAtom";
import { toast, ToastType } from "react-toastify";

const SearchForMembers: React.FC<ISearchForMembersProps> = props => {
  const {group, addToSelectedMembers, isGroup, createDirectChat, selectedMembers} = props;

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [members, setMembers] = useState<any>({
    per_page: 10,
    data: [],
    total: 0,
    next_page_url: "",
    current_page: 1
  });
  const [availableMembers, setAvailableMembers] = useState<any>([]);
  const [isLoadingMembers, setIsLoadingMembers] = useState<boolean>();

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleConfirmSearch(), 1000);
    return () => clearTimeout(timeOutId);
  }, [searchQuery.length]);

  // useEffect(() => {
  //   if (group) {
  //     setSearchQuery("");
  //   }
  // }, [group && group.id]);

  useEffect(() => {
    if (members.data.length > 0) {
      getAvailableMembers();
    }
  }, [members.data]);

  function handleConfirmSearch() {
    if (group) {
      fetchMembers();
    }
  }

  function fetchMoreMembers() {
    GroupService.getInstance()
      .getGroupMembersInMention(group.id, searchQuery, members.per_page, "", searchQuery.length > 0 ? "" : members.current_page + 1, true)
      .then((_members: any) => {
        const newMembers = members;
        newMembers.next_page_url = _members.next_page_url;
        newMembers.per_page = _members.per_page;
        newMembers.current_page = _members.current_page;
        newMembers.data = [...availableMembers, ..._members.data.slice(0, 2)];

        setMembers(newMembers);
        setIsLoadingMembers(false);
      })
  }

  const [notSearchFound, setNoSearchFound] = useState<any>()
  const [searchedMembers, setSearchedMembers] = useState<any>()
  function fetchMembers() {
    setIsLoadingMembers(true);
    GroupService.getInstance()
      .getGroupMembersInMention(group.id, searchQuery, members.per_page, "", searchQuery.length > 0 ? "" : members.current_page, true)
      .then((_members: any) => {
        if ((searchQuery.length !== 0) && (_members.data.length === 0)) {
          setNoSearchFound('No results found')
          setSearchedMembers(_members.data.length)
          return
        }
        _members.data = _members.data.slice(0, 6)
        setMembers(_members);
        setIsLoadingMembers(false);
        setNoSearchFound(undefined)
        setSearchedMembers(_members.data.length)
      })
  }

  function processSelection(member: any, index: any) {
    setSearchQuery("");
    if (member.community_member && !member.community_member.user && !member.community_member.can_chat) {
      toast("Messaging has been disabled for this member.", {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return;
    }
    addToSelectedMembers(member);

    const _availableMembers: any = members.data;
    _availableMembers.splice(index, 1);
    setAvailableMembers(_availableMembers);

    if (availableMembers.length <= 4 && !isLoadingMembers && searchQuery.length === 0) {
      fetchMoreMembers();
      setIsLoadingMembers(true);
    }
  }

  function getAvailableMembers() {
    let _availableMembers: any = members.data;
    
    if (isGroup) {
      members.data.forEach((member: any, index: any) => {
        selectedMembers.forEach((selectedMember: any, indx: any) => {
          if (member.id === selectedMember.id || member.id === group.user_membership.id) {
            _availableMembers.splice(index, 1);
          }
        })

        if (member.id === group.user_membership.id) {
          _availableMembers.splice(index, 1);
        }
      })
    } else {
      _availableMembers = _availableMembers.filter((member: any) => member.id !== group.user_membership.id);
    }
      
    setAvailableMembers(_availableMembers);
  }
  const [, setShowNewDirectMessageMenu] = useAtom(showNewDirectMessageMenuAtom);

  const createIndividualMessage = (member: any) => {
    createDirectChat(member)
    setShowNewDirectMessageMenu(false)
  }

  return (
    <div className="header-settings dropdown-menu new-message-menu" ref={ref}>
      {isGroup ? (
        <input type="text" className = {`search-group ${selectedMembers.length > 0 ? "click-to-add" : ""}`}
          onClick={() => setIsComponentVisible(true)}
          onFocus={() => setIsComponentVisible(true)}
          placeholder="Search for members"
          onChange={e => {
            setSearchQuery(e.target.value);
            setIsComponentVisible(true)
          }}
          value = {searchQuery}
        />
      ): (
        <input type="text" className = "search-group" placeholder="Search for a member" 
          onClick={() => setIsComponentVisible(true)}
            onFocus={() => setIsComponentVisible(true)}
          onChange={e => {
            setSearchQuery(e.target.value);
            setIsComponentVisible(true)
          }}
          value = {searchQuery}
        />
      )}
      {isComponentVisible && (
        <div className="group-dropdown dropdown search-members-dropdown">
          <ul>
            {
              (notSearchFound && searchQuery.length !== 0 && searchedMembers === 0)
                ?
                <span className="loading-results">{notSearchFound}</span>
                :
                <>
                  {availableMembers.map((member: any, index: any) => (
                    <li
                      key={index}
                      onClick={() => isGroup ? processSelection(member, index)
                        : createIndividualMessage(member)}
                      className="btn btn-top-bar flex justify-content-left"
                    >
                      <UserComponent
                        hideAdmin={true}
                        user={member.user}
                        profilePicture={member.user.profile_image}
                        showProfileSlider={false}
                      />
                    </li>
                  ))}

                </>
            }
          </ul>
      </div>
      )}
    </div>
  );
};

export default withRouter(
  connect(null, null)(SearchForMembers as any) as any
) as any;
