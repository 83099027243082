import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./NotFound.scss";
import { useHistory } from 'react-router'
import miscUtils from "utils/miscUtils";
import { connect } from "react-redux";
import { Map } from "immutable";

interface INotFoundComponentProps {
  user: any;
  message: string | JSX.Element;
  subMessage?: string;
  showSubMessage?: boolean;
  group: any
  hideBoxShadow?: boolean;
}

const NotFoundComponent = (props: INotFoundComponentProps) => {
  const history = useHistory();

  const navigate = () => {
    if (props.user) {
      window.open(`${miscUtils.getBaseDomain()}/creategroup`, "_self");
    } else {
      if (props.group) {
        history.push("/signup");
      } else {
        window.open(`${miscUtils.getBaseDomain()}/signup`, "_self");
      }
    }
  }

  return <div className={props.user ? "not-found-container-logged-in" : "not-found-container-logged-out"}>
    <div className={`not-found-content ${props.hideBoxShadow ? 'box-shadow-none' : ''}`}>
      <img src={require("./link.png")} alt="link" />
      {typeof props.message === "string" ? (
        <div className="heading">{props.message}</div>
      ) : (
        <>
          {props.message}
        </>
      )}

      {props.showSubMessage && (<p className="sub-heading">
        {props.subMessage ?
          props.subMessage
          :
          <span>Create a group and make it yours!{" "}</span>
        }
        <Link className="not-found-link"
          to="#"
          onClick={navigate}>
          {props.user ? "Create now!" : "Sign up now!"}
        </Link>
      </p>)}
    </div>
  </div>;
}

const mapStateToProps = (state: Map<string, any>) => {
  const selectedGroup: string = state.getIn(["group", "selectedGroup"]) as string;

  return {
    group: selectedGroup,
  };
};

export default withRouter(
  connect(mapStateToProps, null)(NotFoundComponent as any) as any
) as any;
