import React from "react";
import { Link } from "react-router-dom";
import "./AccessRestricted.scss";

function AccessRestricted({ user, message = "Access Restricted", authed, group, subHeading, showActionMessage = true }: { user: any, message?: string, authed?: boolean, group: any, subHeading?: string; showActionMessage?: boolean }) {

  return (
    <div className="access-restricted">
      <div className="heading">{message}</div>

      <div className="sub-headding">
        {showActionMessage && !subHeading && (
          !user && !authed ? (
            <Link className="not-found-link" to="/signin">
              Please log in.
            </Link>
            ) : (!user && authed) ? (
            <a className="not-found-link" target="_self" href={group && group.link}>
              Please join community.
            </a>
            ) : (
            <span>Please contact your community admin.</span>
            )
        )}
        
        {subHeading && <span>{subHeading}</span>}
      </div>
    </div>
  )
}

export default AccessRestricted;
