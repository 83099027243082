import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { IRoute } from "./IRoute";
import { connect } from "react-redux";
import { Map } from "immutable";

export class PrivateRoute extends Component<IRoute, any> {
  public render() {
    const { authed, user, path, component, exact, group } = this.props;

    return (
      <Route
        isExact={exact}
        path={path}
        render={() => {
          if (authed) {
            if (
              user &&
              !user.verified &&
              user.shouldVerify &&
              path !== "/verify-account"
            ) {
              return <Redirect to="/verify-account?s=false" />;
            }

            return (() => component)();
          }

          let query = "";
          const search = window.location.search;

          if(search && search.length) {
            query = search;
          }

          return <Redirect to={group ? `/signin${query}` : `/login${query}`} />;
        }}
      />
    );
  }
}

const mapStateToProps = (state: Map<string, any>, nextProps: IRoute) => {
  const user: any = state.getIn(["authorize", "user"]);

  return {
    user,
    authed: state.getIn(["authorize", "authed"]),
  };
};

export default connect(mapStateToProps)(PrivateRoute as any);
