import React from 'react'

import './data-pagination.scss'

const PaginationOptions = (
    {
        lastPage,
        currentPage,
        setCurrentPage
    }:
        {
            lastPage: number,
            currentPage: number,
            setCurrentPage: any
        }
) => {
    // const [currentPage, setCurrentPage] = useState(0);

    const handleNextPage = () => {
        setCurrentPage((prevPage: number) => Math.min(prevPage + 1, lastPage));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage: number) => Math.max(prevPage - 1, 0));
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const renderPaginationOptions = () => {
        const options = [];
        for (let i = 0; i <= lastPage; i++) {
            options.push(
                <option key={i} value={i}>
                    {i + 1}
                </option>
            );
        }
        return options;
    };
    return (
        <div className='pagination'>
            <button
                onClick={handlePrevPage}
                disabled={currentPage === 0}
                className={`previous ${(currentPage === 0) ? 'disable' : ''}`}
            >
                Prev
            </button>
            {/* <span>Page: {currentPage + 1} of {lastPage + 1}</span> */}
            <div className='center'>
                <select
                    className='page-button'
                    value={currentPage}
                    onChange={(e) => handlePageChange(Number(e.target.value))}
                >
                    {renderPaginationOptions()}
                </select>
            </div>
            <button
                onClick={handleNextPage}
                disabled={currentPage === lastPage}
                className={`next ${currentPage === lastPage ? 'disable' : ''}`}
            >
                Next
            </button>
        </div>
    )
}

export default PaginationOptions