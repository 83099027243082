import React from 'react';
import { IPriceInBlockPreviewProps } from './IPriceInBlockPreviewProps';
import miscUtils from 'utils/miscUtils';
import { isValidHexColor } from 'components/PageBuilder/PageBuilderSideBar/BlockSettings/domain';
import styled from 'styled-components';

const PriceInBlockContainer: any = styled.div<{design: any, buildingPreview: boolean}>`
  width: 100%;
  margin-top: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props: any) => 
    props.design.price_container_background_color && 
    isValidHexColor(props.design.price_container_background_color) ?
    props.design.price_container_background_color :
    '#FFFFFF'
  };
  border: ${(props: any) => 
    props.design.price_container_border_color && 
    isValidHexColor(props.design.price_container_border_color) ?
    `${
      props.design.price_container_border_width ?
      props.design.price_container_border_width : 
      1
    }px solid ${props.design.price_container_border_color}` :
    `${
      props.design.price_container_border_width ?
      props.design.price_container_border_width : 
      1
    }px solid #D5D8DF`
  };
  cursor: ${(props: any) =>
    props.buildingPreview ?
    undefined : 
    `pointer`
  };

  &.no-margin-top {
      margin-top: 0;
  }
`;

const PriceInBlockContentContainer: any = styled.div<{design: any}>`
  width: 80%;
  padding: 0 24px;
  border-right: ${(props: any) => 
    props.design.price_container_border_color && 
    isValidHexColor(props.design.price_container_border_color) ?
    `${
      props.design.price_container_border_width ?
      props.design.price_container_border_width : 
      1
    }px solid ${props.design.price_container_border_color}` :
    `${
      props.design.price_container_border_width ?
      props.design.price_container_border_width : 
      1
    }px solid #D5D8DF`
  };

  &.mobile {
      width: 70%;
      padding: 0 10px;
  }

  @media(max-width: 550px) {
      width: 70%;
      padding: 0 10px;
  }
`;

const PriceInBlockContentTitle: any = styled.h3<{design: any, pageStyles: any}>`
  margin: 0;
  padding: 0;
  font-weight: 700;

  font-size: ${(props: any) =>
    props.design.price_heading_h3_font_size &&
    props.design.price_heading_h3_font_size <= 50 ?
    `${props.design.price_heading_h3_font_size}px` :
    `27px`
  };
  line-height: ${(props: any) =>
    props.design.price_heading_h3_line_height &&
    props.design.price_heading_h3_line_height <= 50 ?
      `${props.design.price_heading_h3_line_height}px` :
      `35px`
  };
  font-family: ${(props: any) =>
    props.design.price_heading_h3_font_family?
      `${props.design.price_heading_h3_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) =>
    props.design.price_heading_h3_color &&
    isValidHexColor(props.design.price_heading_h3_color) ?
    props.design.price_heading_h3_color :
    props.design.price_heading_color && 
    isValidHexColor(props.design.price_heading_color) ?
    props.design.price_heading_color :
    '#000000'
  };
`;

const PriceInBlockContentText: any = styled.p<{design: any, pageStyles: any}>`
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-weight: 400;

  font-size: ${(props: any) =>
    props.design.price_subtext_p_font_size &&
    props.design.price_subtext_p_font_size <= 50 ?
    `${props.design.price_subtext_p_font_size}px` :
    `20px`
  };
  line-height: ${(props: any) =>
    props.design.price_subtext_p_line_height &&
    props.design.price_subtext_p_line_height <= 50 ?
      `${props.design.price_subtext_p_line_height}px` :
      `25px`
  };
  font-family: ${(props: any) =>
    props.design.price_subtext_p_font_family ?
      `${props.design.price_subtext_p_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) =>
    props.design.price_subtext_p_color &&
    isValidHexColor(props.design.price_subtext_p_color) ?
    props.design.price_subtext_p_color :
    props.design.price_subtext_color && 
    isValidHexColor(props.design.price_subtext_color) ?
    props.design.price_subtext_color :
    '#000000'
  };
`;

const PriceInBlockPriceContainer: any = styled.div`
  width: 20%;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &.mobile {
      width: 30%;
  }

  @media(max-width: 550px) {
      width: 30%;
  }
`;

const PriceInBlockPriceText: any = styled.h3<{design: any, pageStyles: any}>`
  margin: 0;
  padding: 0;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 5px;

  font-size: ${(props: any) =>
    props.design.price_text_h3_font_size &&
    props.design.price_text_h3_font_size <= 50 ?
    `${props.design.price_text_h3_font_size}px` :
    `27px`
  };
  line-height: ${(props: any) =>
    props.design.price_text_h3_line_height &&
    props.design.price_text_h3_line_height <= 50 ?
      `${props.design.price_text_h3_line_height}px` :
      `35px`
  };
  font-family: ${(props: any) =>
    props.design.price_text_h3_font_family ?
      `${props.design.price_text_h3_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) => 
    props.design.price_text_h3_color &&
    isValidHexColor(props.design.price_text_h3_color) ?
    props.design.price_text_h3_color :
    props.design.price_text_color && 
    isValidHexColor(props.design.price_text_color) ?
    props.design.price_text_color :
    '#000000'
  };

  span:nth-child(2) {
      font-size: 14px !important;
      line-height: 18px !important;
  }
`;

const PriceInBlockPreview: React.FC<IPriceInBlockPreviewProps> = (props) => {

  const ctaOnClick = () => {
    if(props.buildingPreview) {
        return;
    }
    if(
        props.blockData &&
        props.blockData.url
    ) {
        window.open(props.blockData.url, '_self');
        return;
    }
  }

  return (
    <PriceInBlockContainer
    className={`${!props.index ? 'no-margin-top' : ''}`}
    design={props.blockData.design}
    buildingPreview={props.buildingPreview}
    onClick={ctaOnClick}
    >
      <PriceInBlockContentContainer
      className={`${props.view ? props.view : ''}`}
      design={props.blockData.design}
      >
        <PriceInBlockContentTitle
        design={props.blockData.design}
        pageStyles={props.pageStyles}
        >
          {props.blockData.title}
        </PriceInBlockContentTitle>

        <PriceInBlockContentText
        design={props.blockData.design}
        pageStyles={props.pageStyles}
        >
          {props.blockData.text}
        </PriceInBlockContentText>
      </PriceInBlockContentContainer>

      <PriceInBlockPriceContainer
      className={`${props.view ? props.view : ''}`}
      >
        <PriceInBlockPriceText
        design={props.blockData.design}
        pageStyles={props.pageStyles}
        >
          <span
          >
            {miscUtils.getCurrencySymbol(props.blockData.currency)}{" "}{props.blockData.price}{" "}{props.blockData.currency.toUpperCase()}
          </span>
          <span
          >
            {"/"}{" "}{props.blockData.period}
          </span>
        </PriceInBlockPriceText>
      </PriceInBlockPriceContainer>
    </PriceInBlockContainer>
  )
}

export default PriceInBlockPreview;