import React from 'react'

import './signup-disabled.scss'

const SignUpDisabled = () => {
    return (
        <div className='signup-closed-container'>
            <span className='signup-closed-container-header'>
                Sign up is closed
            </span>
            <span className='signup-closed-container-subtext'>
                Contact your community admin.
            </span>
        </div>
    )
}

export default SignUpDisabled