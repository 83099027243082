import React from 'react';
import "./AppToggle.scss";
import { IAppToggleProps } from './IAppToggleProps';

const AppToggle: React.FC<IAppToggleProps> = (props) => {
  return (
    <label className={`groupapp-toggle ${props.size ? `groupapp-toggle-${props.size}` : ''}`}>
      <input 
      type="checkbox" 
      checked={props.checked}
      onChange={() => {
        if(props.disabled) {
          return;
        }

        props.onChange();
      }}
      />
      <span 
      className={`${props.size ? props.size : ''} ${props.darkHandle ? 'dark-handle' : ''}`}
      />
  </label>
  );
};

export default AppToggle;
