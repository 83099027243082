import { PostService } from 'api/services/post';
import imageCompression from 'browser-image-compression';
import React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { toast, ToastType } from 'react-toastify';
import miscUtils from 'utils/miscUtils';
import { AcceptedFileFormats, AttachmentTypes, FooterIconTypes, PostDataState, PostTypes } from '../domain';
import PostableEditorFooter from '../PostableEditorFooter';
import PostAttachment from '../PostAttachment';
import { IPostableEditorProps } from './IPostableEditorProps';
import "./PostableEditor.scss";
import "./PostableEditorResponsive.scss";
import { v4 as uuidv4 } from "uuid";
import { CourseService } from 'api/services/course';
import PostableDraft from '../PostableDraft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as MaximizeIcon } from './icons/maximize.svg';
import { ReactComponent as MinimizeIcon } from './icons/minimize.svg';
import AppToggle from 'elements/apptoggle';
import { GroupService } from 'api/services/groups';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Map } from "immutable";
import { UploadService } from 'api/services/upload';
import * as tus from 'tus-js-client';
import appConfig from 'appConfig';
import { convertToRaw } from 'draft-js';
import { postFileIsUploadingAtom } from 'showMessageMenuAtom';
import { useAtom } from 'jotai';

const options = {
    maxSizeMB: 10,
    maxWidthOrHeight: 1920,
    useWebWorker: true
};

const MaxPhotoFileSize: number = 10000000; //unit -> bytes
const MaxPhotoAttachmentsLimit: number = 5;

const PostableEditor: React.FC<IPostableEditorProps> = (props) => {
    const resetDraftRef = React.useRef<any>(null);
    const uploaderRef = React.useRef<any>(null);
    const abortTusUploadRef = React.useRef<any>(null);

    const { showHeader = true } = props;

    const [acceptedFiles, setAcceptedFiles] = React.useState<string>("");
    const [postType, setPostType] = React.useState<PostTypes>(() => {
        if (props.editMode && props.post) {
            return props.post.type;
        }

        return PostTypes.TEXT;
    });
    const [postDescription, setPostDescription] = React.useState<string>(() => {
        if (props.editMode && props.post) {
            return props.post.description;
        }
        return ""
    });
    const [draftJSON, setDraftJSON] = React.useState<string | null>(() => {
        if (props.editMode && props.post) {
            return props.post.description_json;
        }

        return null;
    });
    const [isMakingRequest, setIsMakingRequest] = React.useState<boolean>(false);
    const [postImage, setPostImage] = React.useState<any>(
        () => {
            if (
                props.editMode &&
                (props.post.type as PostTypes) === PostTypes.TEXT &&
                Array.isArray(props.post.files) &&
                (props.post.files.length > 0 || props.post.files !== null)
            ) {
                return props.post.files.map((file: {
                    original_file_name: any;
                    file_size: number;
                    id: number;
                    url: string,
                }) => {
                    return {
                        name: file.original_file_name,
                        size: file.file_size,
                        image_id: file.id,
                        image_src: file.url,
                    };
                });

            }
            return null;
        }
    );

    const [postFile, setPostFile] = React.useState<any>(() => {
        if (
            props.editMode &&
            (props.post.type as PostTypes) === PostTypes.FILE &&
            Array.isArray(props.post.files) &&
            (props.post.files.length > 0 || props.post.files !== null)
        ) {
            return props.post.files.map((file: {
                original_file_name: any;
                file_size: number;
                id: number;
            }) => {
                return {
                    name: file.original_file_name,
                    size: file.file_size,
                    file_id: file.id,
                };
            });

        }

        return null;
    });

    React.useEffect(() => {
        if (window.location.pathname.includes('/settings/library/articles/')) {

            setPostImage(() => {
                if (
                    props.editMode &&
                    (props.post.type as PostTypes) === PostTypes.TEXT &&
                    Array.isArray(props.post.files) &&
                    (props.post.files.length > 0 || props.post.files !== null)
                ) {
                    return props.post.files.map((file: {
                        original_file_name: any;
                        file_size: number;
                        id: number;
                        url: string,
                    }) => {
                        return {
                            name: file.original_file_name,
                            size: file.file_size,
                            image_id: file.id,
                            image_src: file.url,
                        };
                    });

                }
                return null;
            }
            );

            setPostFile(() => {
                if (
                    props.editMode &&
                    (props.post.type as PostTypes) === PostTypes.FILE &&
                    Array.isArray(props.post.files) &&
                    (props.post.files.length > 0 || props.post.files !== null)
                ) {
                    return props.post.files.map((file: {
                        original_file_name: any;
                        file_size: number;
                        id: number;
                    }) => {
                        return {
                            name: file.original_file_name,
                            size: file.file_size,
                            file_id: file.id,
                        };
                    });

                }

                return null;
            });
        }
    }, [props.post])

    const [activeIcon, setActiveIcon] = React.useState<FooterIconTypes | null>(() => {
        if (!props.post) {
            return null;
        }

        switch (props.post.type as PostTypes) {
            case PostTypes.TEXT:
                if (
                    Array.isArray(props.post.files) &&
                    (props.post.files.length > 0 || props.post.files === null)
                ) {
                    return FooterIconTypes.IMAGE;
                }

                return null;
            case PostTypes.LINK:
                return FooterIconTypes.GLOBE;
            case PostTypes.VIDEO:
                if (
                    props.post.stream_id ||
                    props.post.media_url.includes("bunnycdn")
                ) {
                    return FooterIconTypes.FILM;
                }

                return FooterIconTypes.VIDEO;
            case PostTypes.AUDIO:
                return FooterIconTypes.HEADPHONES;
            case PostTypes.FILE:
                return FooterIconTypes.FILE;
            case PostTypes.EMBED:
                return FooterIconTypes.EMBED;
            default:
                return null;
        }
    });
    const [webLink, setWebLink] = React.useState<string>(() => {
        if (props.editMode && (props.post.type as PostTypes) === PostTypes.LINK) {
            return (props.post.media_url as string);
        }

        return ""
    });
    const [videoLink, setVideoLink] = React.useState<string>(() => {
        if (props.editMode && (props.post.type as PostTypes) === PostTypes.VIDEO && !props.post.stream_id) {
            return (props.post.media_url as string);
        }

        return ""
    });
    const [embededCode, setEmbededCode] = React.useState<string>(() => {
        if (props.editMode && (props.post.type as PostTypes) === PostTypes.EMBED) {

            return (props.post.media_url as string);
        }
        return ""
    });
    const [uploadKey, setUploadKey] = React.useState<any>(Date.now());
    const [audioFile, setAudioFile] = React.useState<any>(() => {
        if (
            props.editMode &&
            (props.post.type as PostTypes) === PostTypes.AUDIO &&
            props.post.file_size &&
            props.post.original_file_name
        ) {
            return {
                name: props.post.original_file_name,
                size: props.post.file_size,
            }
        }

        return null;
    });
    const [videoFile, setVideoFile] = React.useState<any>(() => {
        if (
            props.editMode &&
            (props.post.type as PostTypes) === PostTypes.VIDEO &&
            ((props.post.stream_id &&
                props.post.file_size &&
                props.post.original_file_name) || (props.post.media_url.includes("bunnycdn")))
        ) {
            return {
                name: props.post.original_file_name,
                size: props.post.file_size,
            }
        }
        return null;
    });
    const [fileIsUploading, setFileIsUploading] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState<number>(0);
    const [mentions, setMentions] = React.useState<Array<string>>([]);
    const [broadcastPost, setBroadcastPost] = React.useState<boolean>(false);
    const [fileUploaderWasClicked, setFileUploaderWasClicked] = React.useState<boolean>(false);
    const [attachmentWasSelected, setAttachmentWasSelected] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>(() => {
        if (
            props.editMode
        ) {
            return props.post.title || "";
        }

        return "";
    });
    const [postCoverImage, setPostCoverImage] = React.useState<any>(() => {
        if (
            props.editMode &&
            props.post &&
            props.post.cover_image_file_name &&
            props.post.cover_image_url
        ) {
            return {
                name: props.post.cover_image_file_name,
                url: props.post.cover_image_url
            };
        }

        return null;
    });

    React.useEffect(() => {
        if (props.iconClickReference) {
            props.iconClickReference.current = onIconClick;
        }

        if (props.postValidatorRef) {
            props.postValidatorRef.current = validPost;
        }
    });

    //when accepted files changes, click on s3 file uploader
    React.useEffect(() => {
        if (acceptedFiles.trim() !== "") {
            clickS3FileUploader()
        }
    }, [acceptedFiles]);

    // global upload state
    const [, setPostFileIsUploading] = useAtom(postFileIsUploadingAtom);

    React.useEffect(() => {
        setPostFileIsUploading(fileIsUploading)
    }, [fileIsUploading]);

    const getMentionsString = () => {
        let mentionsString: string = "";

        mentions.forEach((mention: any) => {
            if (mentionsString.trim() === "") {
                mentionsString = `${mention}`;
            } else {
                mentionsString = `${mentionsString},${mention}`;
            }
        });

        return mentionsString;
    }

    const clickS3FileUploader = () => {
        setFileUploaderWasClicked(true);
        const fileUploadInput: HTMLElement = document.querySelector("#postable-editor-file-upload-input") as HTMLElement;
        fileUploadInput.click();
    }

    const clickVideoFileInput = () => {
        setFileUploaderWasClicked(true);
        const videoFileInput: HTMLElement = document.querySelector("#postable-editor-video-upload-input") as HTMLElement;
        videoFileInput.click();
    }

    const updatePostDescription = (htmlFormat: string, jsonFormat: string) => {
        setPostDescription(htmlFormat);
        setDraftJSON(jsonFormat);
    }

    const resetPostData = () => {
        setPostDescription("");
        setPostImage(null);
        setWebLink("");
        setActiveIcon(null);
        setAudioFile(null);
        setPostType(PostTypes.TEXT);
        resetDraftRef.current();
        setUploadProgress(0);
        setUploadKey(new Date());
        setMentions([]);
        setBroadcastPost(false);
    }

    const getPostFormDataImage = (imageMediaFile: any) => {
        const postObject = new FormData();
        postObject.append("type", postType);
        for (let i = 0; i < imageMediaFile.length; i++) {
            if ((imageMediaFile[i] instanceof Blob)) {
                postObject.append(
                    "media_files[]",
                    imageMediaFile[i],
                );
            }
            continue;
        }
        postObject.append("description", postDescription);
        postObject.append("description_json", (draftJSON as string));
        postObject.append("mentions", getMentionsString());
        postObject.append("is_assignment", `${!!props.isAssignment}`);
        if (!props.editMode) {
            postObject.append("postable_type", props.postableType!);
        }
        postObject.append("title", title);
        if (!postCoverImage) {
            postObject.append("cover_image_url", '');
        }
        return postObject;
    }

    const getPostObjectWithCoverImage = async (postObject: any) => {
        try {
            const compressedFile: any = await imageCompression(postCoverImage, options);

            if (postObject instanceof FormData) {
                postObject.append("cover_image", miscUtils.blobToFile(compressedFile, postCoverImage));
                return postObject;
            } else {
                const newPostObject = new FormData();
                for (const [key, value] of Object.entries(postObject)) {
                    newPostObject.append(`${key}`, (value !== null ? value : '' as any))
                }

                newPostObject.append("cover_image", miscUtils.blobToFile(compressedFile, postCoverImage));
                return newPostObject;
            }
        } catch (error) {
            return postObject;
        }
    }

    const onSubmit = async () => {
        if (!validPost()) {
            return;
        }

        if (props.postDataStateRef) {
            props.postDataStateRef.current = PostDataState.WAITING;
        }

        //text type without image attachment
        if ((postType === PostTypes.TEXT && (postImage === null || postImage.length === 0))) {
            if (props.editMode) {
                if (postCoverImage && !postCoverImage.url) {
                    const postObject = await getPostObjectWithCoverImage({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        mentions: getMentionsString(),
                        media_url: null,
                        title,
                        is_assignment: !!props.isAssignment,
                    });
                    editPost(postObject);
                    return;
                } else {
                    editPost({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        mentions: getMentionsString(),
                        media_url: null,
                        title,
                        cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                        is_assignment: !!props.isAssignment,
                    });
                    return;
                }
            }
            if (postCoverImage && !postCoverImage.ur) {
                const postObject = await getPostObjectWithCoverImage({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    mentions: getMentionsString(),
                    title,
                    is_assignment: !!props.isAssignment,
                });
                createPost(postObject);
                return;
            } else {
                createPost({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    mentions: getMentionsString(),
                    title,
                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                    is_assignment: !!props.isAssignment,
                });
                return;
            }
        }

        //text type with image attachment
        if (postType === PostTypes.TEXT && postImage.length > 0) {
            try {
                let tempPostObject: any = []
                for (const file of postImage) {
                    if (!(file instanceof Blob)) {
                        tempPostObject.push(file);
                        continue;
                    }

                    const compressedFile = await imageCompression(file, options);
                    const newfile = miscUtils.blobToFile(compressedFile, file)
                    tempPostObject.push(newfile)
                }

                const postObject = getPostFormDataImage(tempPostObject);

                if (props.editMode) {
                    if (postCoverImage && !postCoverImage.url) {
                        const newPostObject = await getPostObjectWithCoverImage(postObject);
                        editPost(newPostObject);
                        return;
                    } else {
                        editPost(postObject);
                        return;
                    }
                }

                if (postCoverImage && !postCoverImage.url) {
                    const newPostObject = await getPostObjectWithCoverImage(postObject);
                    createPost(newPostObject);
                    return;
                } else {
                    createPost(postObject);
                    return;
                }

            } catch (error) {
                if (props.editMode) {
                    if (postCoverImage && !postCoverImage.url) {
                        const postObject = await getPostObjectWithCoverImage({
                            type: postType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            is_assignment: !!props.isAssignment,
                        });
                        editPost(postObject);
                        return;
                    } else {
                        editPost({
                            type: postType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                            is_assignment: !!props.isAssignment,
                        });
                        return;
                    }
                }

                if (postCoverImage && !postCoverImage.url) {
                    const postObject = await getPostObjectWithCoverImage({
                        type: postType,
                        description: postDescription,
                        description_json: draftJSON,
                        mentions: getMentionsString(),
                        title,
                        is_assignment: !!props.isAssignment,
                    });
                    createPost(postObject);
                    return;
                } else {
                    createPost({
                        type: postType,
                        description: postDescription,
                        description_json: draftJSON,
                        mentions: getMentionsString(),
                        title,
                        cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                        is_assignment: !!props.isAssignment,
                    });
                    return;
                }
            }
        }

        //file type
        if (activeIcon === FooterIconTypes.FILE && postFile.length > 0) {
            try {
                // const postObject = getPostFormData(postFile);
                const postObject = getPostFormDataImage(postFile);
                if (props.editMode) {
                    if (attachmentWasSelected) {
                        if (postCoverImage && !postCoverImage.url) {
                            const newPostObject = await getPostObjectWithCoverImage(postObject);
                            editPost(newPostObject);
                        } else {
                            editPost(postObject);
                        }
                    } else {
                        if (postCoverImage && !postCoverImage.url) {
                            const newPostObject = await getPostObjectWithCoverImage({
                                type: postType,
                                description: postDescription,
                                description_json: draftJSON,
                                mentions: getMentionsString(),
                                title,
                                is_assignment: !!props.isAssignment,
                            });
                            editPost(newPostObject);
                        } else {
                            editPost({
                                type: postType,
                                description: postDescription,
                                description_json: draftJSON,
                                mentions: getMentionsString(),
                                title,
                                cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                                is_assignment: !!props.isAssignment,
                            });
                        }
                    }
                    return;
                }

                if (postCoverImage && !postCoverImage.url) {
                    const newPostObject = await getPostObjectWithCoverImage(postObject);
                    createPost(newPostObject);
                    return;
                } else {
                    createPost(postObject);
                    return;
                }

            } catch (error) {
                if (props.editMode) {
                    if (postCoverImage && !postCoverImage.url) {
                        const newPostObject = await getPostObjectWithCoverImage({
                            type: postType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            is_assignment: !!props.isAssignment,
                        });
                        editPost(newPostObject);
                        return;
                    } else {
                        editPost({
                            type: postType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                            is_assignment: !!props.isAssignment,
                        });
                        return;
                    }
                }

                if (postCoverImage && !postCoverImage.url) {
                    const newPostObject = await getPostObjectWithCoverImage({
                        type: postType,
                        description: postDescription,
                        description_json: draftJSON,
                        mentions: getMentionsString(),
                        title,
                        is_assignment: !!props.isAssignment,
                    });
                    createPost(newPostObject);
                    return;
                } else {
                    createPost({
                        type: postType,
                        description: postDescription,
                        description_json: draftJSON,
                        mentions: getMentionsString(),
                        title,
                        cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                        is_assignment: !!props.isAssignment,
                    });
                    return;
                }
            }
        }

        //web link type
        if (activeIcon === FooterIconTypes.GLOBE) {
            if (props.editMode) {
                if (postCoverImage && !postCoverImage.url) {
                    const newPostObject = await getPostObjectWithCoverImage({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        media_url: webLink,
                        mentions: getMentionsString(),
                        title,
                        is_assignment: !!props.isAssignment,
                    });
                    editPost(newPostObject);
                    return;
                } else {
                    editPost({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        media_url: webLink,
                        mentions: getMentionsString(),
                        title,
                        cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                        is_assignment: !!props.isAssignment,
                    });
                    return;
                }
            }

            if (postCoverImage && !postCoverImage.url) {
                const newPostObject = await getPostObjectWithCoverImage({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: webLink,
                    mentions: getMentionsString(),
                    title,
                    is_assignment: !!props.isAssignment,
                });
                createPost(newPostObject);
                return;
            } else {
                createPost({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: webLink,
                    mentions: getMentionsString(),
                    title,
                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                    is_assignment: !!props.isAssignment,
                });
                return;
            }
        }

        //web video type
        if (activeIcon === FooterIconTypes.VIDEO) {
            if (props.editMode) {
                if (postCoverImage && !postCoverImage.url) {
                    const newPostObject = await getPostObjectWithCoverImage({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        media_url: videoLink,
                        mentions: getMentionsString(),
                        title,
                        is_assignment: !!props.isAssignment,
                    });
                    editPost(newPostObject);
                    return;
                } else {
                    editPost({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        media_url: videoLink,
                        mentions: getMentionsString(),
                        title,
                        cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                        is_assignment: !!props.isAssignment,
                    });
                    return;
                }
            }

            if (postCoverImage && !postCoverImage.url) {
                const newPostObject = await getPostObjectWithCoverImage({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: videoLink,
                    mentions: getMentionsString(),
                    title,
                    is_assignment: !!props.isAssignment,
                });
                createPost(newPostObject);
            } else {
                createPost({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: videoLink,
                    mentions: getMentionsString(),
                    title,
                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                    is_assignment: !!props.isAssignment,
                });
                return;
            }
        }

        //embed type
        if (activeIcon === FooterIconTypes.EMBED) {
            if (props.editMode) {
                if (postCoverImage && !postCoverImage.url) {
                    const newPostObject = await getPostObjectWithCoverImage({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        media_url: embededCode,
                        mentions: getMentionsString(),
                        title,
                        is_assignment: !!props.isAssignment,
                    });
                    editPost(newPostObject);
                    return;
                } else {
                    editPost({
                        type: postType,
                        postable_type: props.postableType,
                        description: postDescription,
                        description_json: draftJSON,
                        media_url: embededCode,
                        mentions: getMentionsString(),
                        title,
                        cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                        is_assignment: !!props.isAssignment,
                    });
                    return;
                }
            }

            if (postCoverImage && !postCoverImage.url) {
                const newPostObject = await getPostObjectWithCoverImage({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: embededCode,
                    mentions: getMentionsString(),
                    title,
                    is_assignment: !!props.isAssignment,
                });
                createPost(newPostObject);
            } else {
                createPost({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: embededCode,
                    mentions: getMentionsString(),
                    title,
                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                    is_assignment: !!props.isAssignment,
                });
                return;
            }
        }

        //video type
        if (activeIcon === FooterIconTypes.FILM) {
            if (fileUploaderWasClicked) {
                setFileUploaderWasClicked(false);
                uploadVideoToBunny()
            } else {
                if (props.editMode) {
                    if (postCoverImage && !postCoverImage.url) {
                        const newPostObject = await getPostObjectWithCoverImage({
                            type: postType,
                            postable_type: props.postableType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            is_assignment: !!props.isAssignment,
                        });
                        editPost(newPostObject);
                    } else {
                        editPost({
                            type: postType,
                            postable_type: props.postableType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                            is_assignment: !!props.isAssignment,
                        });
                    }
                }
            }
        }

        //audio type
        if (activeIcon === FooterIconTypes.HEADPHONES) {
            if (fileUploaderWasClicked) {
                setFileUploaderWasClicked(false);
                uploaderRef.current.uploadFile();
            } else {
                if (props.editMode) {
                    if (postCoverImage && !postCoverImage.url) {
                        const newPostObject = await getPostObjectWithCoverImage({
                            type: postType,
                            postable_type: props.postableType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            is_assignment: !!props.isAssignment,
                        });
                        editPost(newPostObject);
                    } else {
                        editPost({
                            type: postType,
                            postable_type: props.postableType,
                            description: postDescription,
                            description_json: draftJSON,
                            mentions: getMentionsString(),
                            title,
                            cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                            is_assignment: !!props.isAssignment,
                        });
                    }
                }
            }
            return;
        }
    }

    const createPost = (postObject: any) => {

        if (props.messagingMode) {
            if (props.postDataStateRef) {
                props.postDataStateRef.current = PostDataState.READY;
            }

            if (props.setPostDataState) {
                props.setPostDataState(PostDataState.READY);
            }

            if (props.onSubmitMessagingMode) {
                props.onSubmitMessagingMode(postObject);
            }
            return;
        }

        setIsMakingRequest(true);
        PostService.getInstance()
            .createPost(props.group.id, props.postableId, postObject)
            .then((response: any) => {
                if (broadcastPost) {
                    GroupService.getInstance()
                        .broadcastPost(response.id)
                        .then(() => {
                            toast("Post successfully broadcasted", {
                                type: ToastType.SUCCESS,
                                autoClose: 3000,
                            });
                        });
                }
                props.onSubmit();
                resetPostData();
                if (props.closeModal) {
                    props.closeModal();
                }
            })
            .catch(() => {
            })
            .finally(() => setIsMakingRequest(false));
    }

    const editPost = (postObject: any) => {

        if (props.messagingMode) {
            if (props.postDataStateRef) {
                props.postDataStateRef.current = PostDataState.READY;
            }

            if (props.setPostDataState) {
                props.setPostDataState(PostDataState.READY);
            }

            if (props.onSubmitMessagingMode) {
                props.onSubmitMessagingMode(postObject);
            }
            return;
        }

        setIsMakingRequest(true);
        PostService.getInstance()
            .updatePost(props.post.id, postObject)
            .then((response: any) => {
                // props.onCancelEdit()
                // setIsMakingRequest(false);
                props.onSubmitEdit(response.data);
            })
            .catch(() => {
                setIsMakingRequest(false);
            })
            .finally(() => {
                setIsMakingRequest(false);
            })
    }

    const shouldPostAttachmentShow = () => {
        if (props.courseLessonMode) {
            return false;
        }

        switch (activeIcon) {
            case null:
                return false;
            case FooterIconTypes.IMAGE:
                // return !!postImage;
                return postImage && postImage.length > 0;
            case FooterIconTypes.FILE:
                // return !!postFile;
                return postFile && postFile.length > 0;
            case FooterIconTypes.VIDEO:
            case FooterIconTypes.GLOBE:
            case FooterIconTypes.EMBED:
                return true;
            case FooterIconTypes.HEADPHONES:
                return !!audioFile;
            case FooterIconTypes.FILM:
                return !!videoFile;
            default:
                return false;
        }

    }

    const getAttachmentType = () => {
        switch (activeIcon) {
            case FooterIconTypes.IMAGE:
                return AttachmentTypes.IMAGE;
            case FooterIconTypes.GLOBE:
                return AttachmentTypes.LINK;
            case FooterIconTypes.VIDEO:
                return AttachmentTypes.VIDEOLINK;
            case FooterIconTypes.HEADPHONES:
                return AttachmentTypes.AUDIO;
            case FooterIconTypes.FILM:
                return AttachmentTypes.VIDEO;
            case FooterIconTypes.FILE:
                return AttachmentTypes.FILE;
            case FooterIconTypes.EMBED:
                return AttachmentTypes.EMBED;
            default:
                return AttachmentTypes.IMAGE
        }
    }

    const getAttachment = () => {
        switch (activeIcon) {
            case FooterIconTypes.IMAGE:
                return [...postImage];
            case FooterIconTypes.HEADPHONES:
                return audioFile;
            case FooterIconTypes.FILM:
                return videoFile;
            case FooterIconTypes.FILE:
                return postFile;
            default:
                return null;
        }
    }

    const getRemoveAttachmentCallback = () => {
        switch (activeIcon) {
            // case FooterIconTypes.IMAGE:
            // case FooterIconTypes.FILE:
            case FooterIconTypes.GLOBE:
            case FooterIconTypes.VIDEO:
            case FooterIconTypes.EMBED:
                return () => {
                    setActiveIcon(null);
                    setPostImage(null);
                    setPostFile(null);
                    setPostType(PostTypes.TEXT);
                }
            case FooterIconTypes.HEADPHONES:
            case FooterIconTypes.FILM:
                return () => {
                    setActiveIcon(null);
                    setPostType(PostTypes.TEXT);
                    setAudioFile(null);
                    setPostImage(null);
                    setUploadKey(new Date());
                }
            default:
                return () => { }
        }
    }

    const removeIMGAttachment = (image_id?: number, file_name?: string) => {
        if (image_id) {
            const newFiles = postImage.filter((
                file: { image_id: number }) => file.image_id !== image_id
            );
            setPostImage(newFiles);
            setActiveIcon(newFiles.length > 0 ? FooterIconTypes.IMAGE : null);
            setPostType(PostTypes.TEXT);
            GroupService.getInstance()
                .deleteGroupPostImageAttachment(props.post.id, image_id)
                .finally(() => {
                    toast("File Attachment successfully deleted", {
                        type: ToastType.SUCCESS,
                        autoClose: 3000,
                    });
                })
        }
        if (file_name) {
            const newFiles = postImage.filter((
                file: { name: string }) => file.name !== file_name
            );
            setPostImage(newFiles);
            setActiveIcon(newFiles.length > 0 ? FooterIconTypes.IMAGE : null);
            setPostType(PostTypes.TEXT);
        }
    }


    const removeFILEAttachment = (file_id?: number, file_name?: string) => {

        if (file_id) {
            const newFiles = postFile.filter((
                file: { file_id: number }) => file.file_id !== file_id
            );
            setPostFile(newFiles);
            setActiveIcon(newFiles.length > 0 ? FooterIconTypes.FILE : null);
            setPostType(newFiles.length > 0 ? PostTypes.FILE : PostTypes.TEXT);
            GroupService.getInstance()
                .deleteGroupPostImageAttachment(props.post.id, file_id)
                .then(() => {
                    toast("File Attachment successfully deleted from post", {
                        type: ToastType.SUCCESS,
                        autoClose: 3000,
                    });
                })
        }
        if (file_name) {
            const newFiles = postFile.filter((
                file: { name: string }) => file.name !== file_name
            );
            setPostFile(newFiles);
            setActiveIcon(newFiles.length > 0 ? FooterIconTypes.FILE : null);
            setPostType(newFiles.length > 0 ? PostTypes.FILE : PostTypes.TEXT);
        }
    }

    const validPost = (): boolean => {
        if (props.requireCoverImage && !postCoverImage) {
            toast("Post cover image is required", {
                type: ToastType.ERROR,
                autoClose: 3000,
            });
            return false;
        }


        if (props.requireTitle && title.trim() === "") {
            toast("Post title is empty", {
                type: ToastType.ERROR,
                autoClose: 3000,
            });
            return false;
        }

        if ((miscUtils.stripHTML(postDescription) as string).trim() === '' && (!props.announcementMode || props.announcementMode && props.descriptionRequired)) {
            toast(
                props.descriptionRequiredErrorText ?
                    props.descriptionRequiredErrorText :
                    props.courseLessonMode ?
                        "Add text is empty" :
                        "Post description is empty", {
                type: ToastType.ERROR,
                autoClose: 3000,
            });
            return false;
        }
        switch (activeIcon) {
            case null:
                return true;
            case FooterIconTypes.GLOBE:
                if (webLink.trim() === "") {
                    toast("Please add a web link", {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                    });
                    return false;
                }

                if (!miscUtils.isLink(webLink)) {
                    toast("Please enter a valid web link", {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                    });
                    return false;
                }
                return true;
            case FooterIconTypes.VIDEO:
                if (videoLink.trim() === "") {
                    toast("Please add a video link", {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                    });
                    return false;
                }

                if (!miscUtils.isLink(videoLink)) {
                    toast("Please enter a valid video link", {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                    });
                    return false;
                }
                return true;
            case FooterIconTypes.EMBED:
                if (embededCode.trim() === "") {
                    toast("Please add embed code", {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                    });
                    return false;
                }
                return true;
            case FooterIconTypes.HEADPHONES:
                return !!audioFile
            // || !!props.editMode;
            case FooterIconTypes.IMAGE:
                return !!postImage;
            // || !!props.editMode;
            case FooterIconTypes.FILE:
                return !!postFile;
            // || !!props.editMode;
            case FooterIconTypes.FILM:
                return !!videoFile
            // || !!props.editMode;
            default:
                return false;
        }
    }

    const onIconClick = (icon: FooterIconTypes | null) => {

        if (fileIsUploading) {
            toast('An upload is ongoing. Please cancel the upload to continue', {
                type: ToastType.INFO,
                autoClose: 3000,
            })
            return;
        }

        switch (icon) {
            case FooterIconTypes.IMAGE:
                if (
                    postImage &&
                    postImage.length >= MaxPhotoAttachmentsLimit
                ) {
                    toast('Maximum photo attachment limit reached!', {
                        type: ToastType.ERROR,
                        autoClose: 2000,
                    });
                    return;
                }
                const imagePickerInput: HTMLElement = document.querySelector('#postable-editor-image-picker') as HTMLElement;
                imagePickerInput.click();
                break;
            case FooterIconTypes.FILE:
                const filePickerInput: HTMLElement = document.querySelector('#postable-editor-file-picker') as HTMLElement;
                filePickerInput.click();
                break;
            case FooterIconTypes.GLOBE:
                setActiveIcon(icon);
                setPostType(PostTypes.LINK);
                break;
            case FooterIconTypes.EMBED:
                setActiveIcon(icon);
                setPostType(PostTypes.EMBED);
                break;
            case FooterIconTypes.VIDEO:
                setVideoLink("");
                setActiveIcon(icon);
                setPostType(PostTypes.VIDEO);
                break;
            case FooterIconTypes.HEADPHONES:
                if (acceptedFiles === AcceptedFileFormats.UPLOADAUDIO) {
                    clickS3FileUploader();
                } else {
                    setAcceptedFiles(AcceptedFileFormats.UPLOADAUDIO);
                }
                break;
            case FooterIconTypes.FILM:
                // if (acceptedFiles === AcceptedFileFormats.UPLOADVIDEO) {
                //     clickS3FileUploader()
                // } else {
                //     setAcceptedFiles(AcceptedFileFormats.UPLOADVIDEO);
                // }
                clickVideoFileInput();
                break;
            case null:
                setPostType(PostTypes.TEXT);
                setActiveIcon(icon);
                break;
            default:
                break;
        }
    }

    const getAttachmentInputValue = () => {
        switch (activeIcon) {
            case FooterIconTypes.GLOBE:
                return webLink;
            case FooterIconTypes.VIDEO:
                return videoLink;
            case FooterIconTypes.EMBED:
                return embededCode;
            default:
                return "";
        }
    }

    const getAttachmentInputOnChange = () => {
        if (props.readOnly) {
            return;
        }
        switch (activeIcon) {
            case FooterIconTypes.GLOBE:
                return (event: any) => {
                    setWebLink(event.target.value);
                }
            case FooterIconTypes.VIDEO:
                return (event: any) => {
                    setVideoLink(event.target.value);
                }
            case FooterIconTypes.EMBED:
                return (event: any) => {
                    setEmbededCode(event.target.value);
                }
            default:
                return () => { }
        }
    }

    const onFileSelected = (event: any) => {
        const file = event.target.files[0];

        if (!miscUtils.validateFileName(file ? file : null)) {
            setPostType(PostTypes.TEXT);
            return;
        }

        switch (acceptedFiles) {
            case AcceptedFileFormats.UPLOADAUDIO:
                if (
                    file &&
                    file.type !== "audio/mpeg" &&
                    file.type !== "audio/x-m4a" &&
                    file.type !== "audio/flac" &&
                    file.type !== "audio/wav"
                ) {
                    setAudioFile(null);
                    setActiveIcon(null);
                    setUploadKey(Date.now());
                    toast(`File formats allowed is mp3., .m4a, .flac, .wav`, {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                    });
                } else {
                    setActiveIcon(FooterIconTypes.HEADPHONES);
                    setAudioFile(file);
                    setPostType(PostTypes.AUDIO)
                }
                break;
            case AcceptedFileFormats.UPLOADVIDEO:
                if (file && miscUtils.supportVideoFormats(file)) {
                    setVideoFile(null);
                    setActiveIcon(null);
                    setUploadKey(Date.now());
                    toast(`File formats allowed are mp4/mov/avi/flv/webm.`, {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                    })
                } else {
                    setActiveIcon(FooterIconTypes.FILM);
                    setVideoFile(file);
                    setPostType(PostTypes.VIDEO);
                }
                break;
            default:
                break;
        }
    }

    const onVideoFileSelected = (event: any) => {
        const file = event.target.files[0];

        if (!miscUtils.validateFileName(file ? file : null)) {
            setPostType(PostTypes.TEXT);
            return;
        }

        if (file && miscUtils.supportVideoFormats(file)) {
            setVideoFile(null);
            setActiveIcon(null);
            setUploadKey(Date.now());
            toast(`File formats allowed are mp4/mov/avi/flv/webm.`, {
                type: ToastType.ERROR,
                autoClose: 3000,
            })
        } else {
            setActiveIcon(FooterIconTypes.FILM);
            setVideoFile(file);
            setPostType(PostTypes.VIDEO);
        }
    }

    const getSignedUrl = (file: any, callback: any) => {
        let targetPostType: PostTypes.AUDIO | PostTypes.VIDEO = postType === PostTypes.AUDIO ? PostTypes.AUDIO : PostTypes.VIDEO;
        const fileName = `${uuidv4()}_${file.name}`;
        CourseService.getInstance()
            .getS3SignedUrl(props.group.id, fileName, file.size, targetPostType, 'post')
            .then((response: any) => {
                callback(response);
            }, (error: any) => {
                toast(`${error.data.message}`, {
                    type: ToastType.ERROR,
                    autoClose: 3000
                });
                if (targetPostType === PostTypes.AUDIO) {
                    setAudioFile(null);
                } else {
                    setVideoFile(null);
                }
                setFileIsUploading(false);
                setUploadKey(Date.now());
            });

    }

    const onUploadProgress = (progress: any) => {
        if (!fileIsUploading) {
            setFileIsUploading(true);
        }
        if (progress <= 100) {
            setUploadProgress(progress);
        }
    }

    const onUploadError = () => {
        toast(`Unable to upload file please try again`, {
            type: ToastType.ERROR,
            autoClose: 3000
        });
        setFileIsUploading(false);
        setUploadKey(Date.now());
        if (postType === PostTypes.AUDIO) {
            setAudioFile(null);
        } else {
            setVideoFile(null);
        }
    }

    const onUploadSuccess = async (signedUrl: any, file: any) => {
        const parse = new URL(signedUrl.signedUrl);
        const content_url = `${parse.origin}${parse.pathname}`;
        const fileMeta = {
            original_file_name: file.name,
            file_size: file.size,
            storage_id: parse.pathname
        };
        if (postType === PostTypes.VIDEO) {
            toast(`Your video is being processed`, {
                type: ToastType.SUCCESS,
                autoClose: 3000
            });
        }
        if (props.editMode) {
            if (postCoverImage && !postCoverImage.url) {
                const newPostObject = await getPostObjectWithCoverImage({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: content_url,
                    mentions: getMentionsString(),
                    ...fileMeta,
                    title,
                    is_assignment: !!props.isAssignment,
                });
                editPost(newPostObject);
            } else {
                editPost({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: content_url,
                    mentions: getMentionsString(),
                    ...fileMeta,
                    title,
                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                    is_assignment: !!props.isAssignment,
                });
            }
        } else {
            if (postCoverImage && !postCoverImage.url) {
                const newPostObject = await getPostObjectWithCoverImage({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: content_url,
                    mentions: getMentionsString(),
                    ...fileMeta,
                    title,
                    is_assignment: !!props.isAssignment,
                });
                createPost(newPostObject);
            } else {
                createPost({
                    type: postType,
                    postable_type: props.postableType,
                    description: postDescription,
                    description_json: draftJSON,
                    media_url: content_url,
                    mentions: getMentionsString(),
                    ...fileMeta,
                    title,
                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                    is_assignment: !!props.isAssignment,
                });
            }
        }
        setFileIsUploading(false);
    }

    //bunny callbacks
    const uploadVideoToBunny = () => {
        setFileIsUploading(true);
        UploadService.getInstance()
            .createBunnyVideoObject(
                appConfig.bunny.post_videos.library_id,
                appConfig.bunny.post_videos.library_api_key,
                `${uuidv4()}`,
            )
            .then((response: any) => {
                const videoId = response.data.guid;
                const expirationTime = UploadService.getInstance().getUploadExpirationTime();
                const signature = UploadService.getInstance().getUploadSignature(
                    appConfig.bunny.post_videos.library_id,
                    appConfig.bunny.post_videos.library_api_key,
                    `${expirationTime}`,
                    videoId
                );

                const upload: any = new tus.Upload(videoFile, {
                    endpoint: "https://video.bunnycdn.com/tusupload",
                    retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                    headers: {
                        AuthorizationSignature: signature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                        AuthorizationExpire: `${expirationTime}`, // Expiration time as in the signature,
                        VideoId: videoId, // The guid of a previously created video object through the Create Video API call
                        LibraryId: appConfig.bunny.post_videos.library_id as string,
                    },
                    metadata: {
                        filetype: videoFile.type,
                        title: '',
                    },
                    onError: (error) => {
                        setFileIsUploading(false);
                        toast(`Unable to upload file please try again`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        setVideoFile(null);
                        setUploadProgress(0)
                        console.log('BUNNY: ', 'ERROR ', error);
                    },
                    onProgress: (bytesUploaded, bytesTotal) => {
                        onUploadProgress(Math.floor(((bytesUploaded / bytesTotal) * 100)))
                    },
                    onSuccess: async () => {
                        console.log('BUNNY: ', 'SUCCESS ', upload);
                        if (postType === PostTypes.VIDEO) {
                            toast(`Your video is processing. We will notify you when your post is ready`, {
                                type: ToastType.SUCCESS,
                                autoClose: 3000
                            });
                        }

                        const media_url = `https://video.bunnycdn.com/play/${appConfig.bunny.post_videos.library_id}/${videoId}`
                        const fileMeta = {
                            original_file_name: upload.file.name,
                            file_size: upload.file.size,
                        };
                        if (props.editMode) {
                            if (postCoverImage && !postCoverImage.url) {
                                const newPostObject = await getPostObjectWithCoverImage({
                                    type: postType,
                                    postable_type: props.postableType,
                                    description: postDescription,
                                    description_json: draftJSON,
                                    media_url,
                                    mentions: getMentionsString(),
                                    ...fileMeta,
                                    title,
                                    is_assignment: !!props.isAssignment,
                                });
                                editPost(newPostObject);
                            } else {
                                editPost({
                                    type: postType,
                                    postable_type: props.postableType,
                                    description: postDescription,
                                    description_json: draftJSON,
                                    media_url,
                                    mentions: getMentionsString(),
                                    ...fileMeta,
                                    title,
                                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                                    is_assignment: !!props.isAssignment,
                                });
                            }
                        } else {
                            if (postCoverImage && !postCoverImage.url) {
                                const newPostObject = await getPostObjectWithCoverImage({
                                    type: postType,
                                    postable_type: props.postableType,
                                    description: postDescription,
                                    description_json: draftJSON,
                                    media_url,
                                    mentions: getMentionsString(),
                                    ...fileMeta,
                                    title,
                                    is_assignment: !!props.isAssignment,
                                });
                                createPost(newPostObject);
                            } else {
                                createPost({
                                    type: postType,
                                    postable_type: props.postableType,
                                    description: postDescription,
                                    description_json: draftJSON,
                                    media_url,
                                    mentions: getMentionsString(),
                                    ...fileMeta,
                                    title,
                                    cover_image_url: postCoverImage && postCoverImage.url ? postCoverImage.url : null,
                                    is_assignment: !!props.isAssignment,
                                });
                            }
                        }
                        setFileIsUploading(false);
                    }
                });

                //avail abort function
                abortTusUploadRef.current = () => upload.abort();

                // Check if there are any previous uploads to continue.
                upload.findPreviousUploads().then((previousUploads: any) => {
                    // Found previous uploads so we select the first one. 
                    if (previousUploads.length) {
                        upload.resumeFromPreviousUpload(previousUploads[0])
                    }

                    // Start the upload
                    upload.start()
                })
            })
            .catch((error) => {
                console.log('BUNNY: ', error);
                setFileIsUploading(false);
            })
    }

    const attachUploadReference = (uploadReference: any) => {
        uploaderRef.current = uploadReference;
    }

    const onCancelUpload = () => {
        // uploaderRef.current.abort();
        abortTusUploadRef.current();
        setUploadProgress(0);
        setIsMakingRequest(false);
        setFileIsUploading(false);
        setUploadKey(new Date());
        setActiveIcon(null);
        setPostType(PostTypes.TEXT);
        if (activeIcon === FooterIconTypes.HEADPHONES) {
            setAudioFile(null);
        }
        if (activeIcon === FooterIconTypes.FILM) {
            setVideoFile(null);
        }
    }

    const onAddMention = (mentionId: string) => {
        setMentions([
            ...mentions,
            mentionId,
        ]);
    }

    const onEditorChange = (newEditorState: any) => {
        const contentState = newEditorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const newMentions: any = [];

        // Extract mentions from the raw content state
        rawContentState.blocks.forEach((block: any) => {
            if (block.entityRanges.length > 0) {
                block.entityRanges.forEach((entityRange: any) => {
                    const entity = rawContentState.entityMap[entityRange.key];
                    if (entity.type === 'mention') {
                        newMentions.push(entity.data.mention.id);
                    }
                });
            }
        });

        // Identify removed mentions
        const removedMentions = mentions.filter(mention => !newMentions.includes(mention));
        if (removedMentions.length > 0) {
            // Update mentions state to reflect removed mentions
            setMentions(newMentions);
        }
    };

    const validateSize = (file: any, maximumSize: number): boolean => {
        if (file && file.size > maximumSize) {
            return false;
        }
        return true;
    }

    return (
        <div className="new-post-editor-container" id='exists'>
            {!props.messagingMode && !props.hideEditorHeader ? (
                <>
                    <span
                        className="new-post-editor-fullscreen-toggle"
                        onClick={props.toggleFullScreen}
                    >
                        {props.fullScreen ?
                            <MinimizeIcon /> :
                            <MaximizeIcon />
                        }
                    </span>

                    {showHeader && (
                        <div className="new-post-editor-header-container">
                            <h2
                                style={{
                                    color: props.theme && props.theme.custom ? props.theme.custom.primaryColor : '',
                                }}
                            >{props.postableEditorTitle ? props.postableEditorTitle : props.editMode ? "Edit Post" : "Create Post"}</h2>
                            <span
                                onClick={() => {
                                    if (isMakingRequest || fileIsUploading) {
                                        return;
                                    }

                                    if (props.closeModal) {
                                        props.closeModal();
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                />
                            </span>
                        </div>
                    )}
                </>
            ) : (<></>)}
            <PostableDraft
                group={props.group}
                onEditorChange={onEditorChange}
                onStateChange={updatePostDescription}
                resetDraftRef={resetDraftRef}
                editMode={props.editMode}
                draftJSON={draftJSON}
                postDescription={postDescription}
                onAddMention={onAddMention}
                fullScreen={props.fullScreen}
                readOnly={props.readOnly}
                title={title}
                setTitle={setTitle}
                requireTitle={props.requireTitle}
                postCoverImage={postCoverImage}
                setPostCoverImage={setPostCoverImage}
                hideTitleInput={props.hideTitleInput}
                hideCoverImageInput={props.hideCoverImageInput}
                editorPlaceholder={props.editorPlaceholder}
                titlePlaceholder={props.titlePlaceholder}
            />
            {
                shouldPostAttachmentShow() &&
                <PostAttachment
                    attachmentType={getAttachmentType()}
                    theme={props.theme}
                    attachment={getAttachment()}
                    removeAttachment={getRemoveAttachmentCallback()}
                    removeIMGAttachment={removeIMGAttachment}
                    inputValue={getAttachmentInputValue()}
                    inputOnChange={getAttachmentInputOnChange()}
                    fileIsUploading={fileIsUploading}
                    uploadProgress={uploadProgress}
                    activeIcon={activeIcon as FooterIconTypes}
                    onCancelUpload={onCancelUpload}
                    editMode={props.editMode}
                    onIconClick={onIconClick}
                    announcementMode={props.announcementMode}
                    fullScreen={props.fullScreen}
                    removeFILEAttachment={removeFILEAttachment}
                />
            }
            {!props.readOnly ? (
                <PostableEditorFooter
                    theme={props.theme}
                    isMakingRequest={isMakingRequest}
                    onSubmit={onSubmit}
                    activeIcon={activeIcon}
                    onIconClick={onIconClick}
                    fileIsUploading={fileIsUploading}
                    editMode={props.editMode}
                    onCancelEdit={props.onCancelEdit}
                    messagingMode={props.messagingMode}
                    hideSubmitButton={props.hideSubmitButton}
                    courseLessonMode={props.courseLessonMode}
                    submitButtonText={props.submitButtonText}
                    submitButtonTextLoading={props.submitButtonTextLoading}
                />
            ) : (<></>)}
            {!props.editMode && !props.hideBroadcastButton ? (
                miscUtils.isTeamMember(props.group.user_membership)
            ) && !props.messagingMode && (
                    <div className="new-post-editor-broadcast-container">
                        <p className="new-post-editor-broadcast-label">Broadcast post</p>
                        <div className="new-post-editor-broadcast-toggle">
                            <AppToggle
                                checked={broadcastPost}
                                onChange={() => setBroadcastPost(!broadcastPost)}
                                size="small"
                                darkHandle
                            />
                        </div>
                    </div>
                ) : (<></>)}
            <>
                <input
                    onChange={async e => {
                        const newFiles = e.target.files

                        if (newFiles === null) return

                        const newFileArray = Array.from(newFiles);

                        const maxAcceptableAttachments = MaxPhotoAttachmentsLimit - (postImage ? postImage.length : 0)

                        if (newFileArray.length > maxAcceptableAttachments) {
                            toast("The maximum image upload limit number is 5", {
                                type: ToastType.ERROR,
                                autoClose: 2000,
                            });
                            return false;
                        }


                        // const validFiles = newFileArray.filter((file) => {
                        //     if (!validateSize(file, MaxPhotoFileSize)) {
                        //         setPostType(PostTypes.TEXT);
                        //         toast("Maximum image upload size is 10MB", {
                        //             type: ToastType.ERROR,
                        //             autoClose: 3000,
                        //         });
                        //         return false;
                        //     }
                        //     if (!miscUtils.validateFileName(file)) {
                        //         setPostType(PostTypes.TEXT);
                        //         return false;
                        //     }

                        //     if (!miscUtils.validateSpecialChars(file)) {
                        //         setPostType(PostTypes.TEXT);
                        //         return false;
                        //     }

                        //     return true;
                        // });

                        // const validFiles = newFileArray.filter((file) => {
                        //     if (!validateSize(file, MaxPhotoFileSize)) {
                        //         setPostType(PostTypes.TEXT);
                        //         toast("Maximum image upload size is 10MB", {
                        //             type: ToastType.ERROR,
                        //             autoClose: 3000,
                        //         });
                        //         return false;
                        //     }
                        //     if (!miscUtils.validateFileName(file)) {
                        //         setPostType(PostTypes.TEXT);
                        //         return false;
                        //     }
                        //     return true;
                        // });

                        const convertAndValidateFiles = async (fileArray: any) => {
                            const validFiles: any = [];

                            await Promise.all(fileArray.map(async (file: any) => {
                                let processedFile = file;
                                console.log('processedFile', processedFile)
                                // Convert HEIC files to PNG
                                if (processedFile.name.endsWith(".heic") || processedFile.name.endsWith(".HEIC")) {
                                    try {
                                        const convertedBlob: any = await miscUtils.convertHeicToPng(file);
                                        if (convertedBlob) {
                                            const pngFileName = file.name.replace(/\.heic$/i, '.png');
                                            processedFile = new File([convertedBlob], pngFileName, { type: 'image/png' });
                                        }
                                    } catch (error) {
                                        console.error("Error converting HEIC to PNG:", error);
                                        toast("Error converting image. Please try again.", {
                                            type: ToastType.ERROR,
                                            autoClose: 3000,
                                        });
                                        return;
                                    }
                                }

                                if (!validateSize(processedFile, MaxPhotoFileSize)) {
                                    setPostType(PostTypes.TEXT);
                                    toast("Maximum image upload size is 10MB", {
                                        type: ToastType.ERROR,
                                        autoClose: 3000,
                                    });
                                    return;
                                }

                                if (!miscUtils.validateFileName(processedFile)) {
                                    setPostType(PostTypes.TEXT);
                                    return;
                                }

                                if (!miscUtils.validateSpecialChars(processedFile)) {
                                    setPostType(PostTypes.TEXT);
                                    return false;
                                }

                                validFiles.push(processedFile);
                            }));

                            return validFiles;
                        };
                        const validFiles = await convertAndValidateFiles(newFileArray);

                        setPostImage((prevPostImage: any) =>
                            Array.isArray(prevPostImage) ?
                                [...prevPostImage, ...validFiles] : [...validFiles]
                        );
                        setActiveIcon(FooterIconTypes.IMAGE);
                        setPostType(PostTypes.TEXT);
                    }}
                    type="file"
                    value=""
                    accept="image/*, .heic"
                    hidden
                    multiple
                    id="postable-editor-image-picker"
                />

                <input
                    onChange={e => {
                        const newFiles = e.target.files

                        if (newFiles === null) return

                        const newFileArray = Array.from(newFiles);

                        const maxAcceptableAttachments = MaxPhotoAttachmentsLimit - (postFile ? postFile.length : 0)

                        if (newFileArray.length > maxAcceptableAttachments) {
                            toast("The maximum file upload limit number is 5", {
                                type: ToastType.ERROR,
                                autoClose: 2000,
                            });
                            return false;
                        }

                        const validFiles = newFileArray.filter((file) => {
                            if (!validateSize(file, MaxPhotoFileSize)) {
                                setPostType(PostTypes.TEXT);
                                toast("Maximum file upload size is 10MB", {
                                    type: ToastType.ERROR,
                                    autoClose: 3000,
                                });
                                return false;
                            }
                            if (!miscUtils.validateFileName(file)) {
                                setPostType(PostTypes.TEXT);
                                return false;
                            }
                            return true;
                        });

                        if (!attachmentWasSelected) {
                            setAttachmentWasSelected(true);
                        }
                        setPostType(PostTypes.FILE);
                        setPostFile((prevPostFile: any) =>
                            Array.isArray(prevPostFile) ?
                                [...prevPostFile, ...validFiles] : [...validFiles]
                        );
                        setActiveIcon(FooterIconTypes.FILE);
                    }}
                    type="file"
                    value=""
                    accept=".doc,.docx,.html,.htm,.odt,.pdf,.xls,.xlsx,.ods,.ppt,.pptx,.txt"
                    hidden
                    multiple
                    id="postable-editor-file-picker"
                />
                <ReactS3Uploader
                    key={uploadKey}
                    id="postable-editor-file-upload-input"
                    onChange={onFileSelected}
                    getSignedUrl={getSignedUrl}
                    onProgress={onUploadProgress}
                    onError={onUploadError}
                    onFinish={onUploadSuccess}
                    ref={attachUploadReference}
                    uploadRequestHeaders={{ "x-amz-acl": "public-read" }}  // this is the default
                    contentDisposition="auto"
                    autoUpload={false}
                    accept={acceptedFiles}
                />
                <input
                    type="file"
                    accept="video/mp4,video/mov,video/avi,video/x-flv,video/quicktime,video/webm,.flv"
                    hidden
                    onChange={onVideoFileSelected}
                    id="postable-editor-video-upload-input"
                />
            </>
        </div>
    )
}

const mapStateToProps = (
    state: Map<string, any>,
    ownProps: IPostableEditorProps
) => {
    const user: any = state.getIn(["authorize", "user"]);

    return {
        user,
    };
};

export default withRouter(
    connect(mapStateToProps, null)(PostableEditor as any) as any
) as any;
