import { ChannelService } from 'api/services/channels';
import AppLoader from 'components/contentLoaders/appLoader';
import React from 'react';
import './ChannelSelector.scss';
import { IChannelSelectorProps } from './IChannelSelectorProps';
// import { ReactComponent as SearchIcon } from './icons/searchicon.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Emoji } from 'emoji-mart';

const ChannelSelector: React.FC<IChannelSelectorProps> = (props) => {

  const [loadingChannels, setLoadingChannels] = React.useState<boolean>(true);
  const [channels, setChannels] = React.useState<Array<any>>([]);
  const [totalChannelsCount, setTotalChannelsCount] = React.useState<Number>(0);

  React.useEffect(() => {
    getChannels();
  }, [totalChannelsCount]);

  React.useEffect(() => {
    if (props.searchString.length > 0) {
      getChannels(true);
    }
  }, [props.searchString])

  const getChannels = (noLoad?: boolean) => {
    let query = `?limit=${totalChannelsCount}`;
    if (props.searchString.length > 0) {
      query = `?search=${props.searchString}&limit=${totalChannelsCount}`;
    }
    if (!noLoad) {
      setLoadingChannels(true);
    }
    ChannelService.getInstance()
      .getChannels(props.group.id, query)
      .then((response: any) => {
        setChannels(response.data.data);
        setTotalChannelsCount(response.data.total);
      })
      .finally(() => setLoadingChannels(false))
  };

  return (
    <div className="channel-selector-container">
      {
        loadingChannels ?
          (
            <AppLoader
              label="Loading channels..."
            />
          )
          :
          (
            <div className="channel-selector-channels-container">
              {
                channels.length === 0 ?
                  (
                    <div className="channel-selector-empty-container">
                      <p className="channel-selector-empty-text">No results found</p>
                    </div>
                  )
                  :
                  (
                    <PerfectScrollbar
                      className="channel-selector-channels-scrollarea"
                      options={{ wheelPropagation: false }}
                    >
                      {
                        channels.map((channel: any) => {
                          if (props.excludes && props.excludes.includes(channel.id)) {
                            return;
                          }
                          return (
                            <div
                              className="channel-selector-channel-container"
                              onClick={() => { props.onChannelSelected(channel) }}
                              key={channel.id}
                            >
                              <p className="channel-selector-channel-name">#{channel.name}</p>
                              {
                                channel.emoji && (
                                  <span
                                    className="channel-selector-channel-emoji"
                                    dangerouslySetInnerHTML={{
                                      //@ts-ignore
                                      __html: Emoji({
                                        html: true,
                                        set: "apple",
                                        emoji: channel.emoji,
                                        size: 18
                                      })
                                    }}
                                  />
                                )
                              }
                            </div>
                          )
                        })
                      }
                    </PerfectScrollbar>
                  )
              }
            </div>
          )}
    </div>
  )
}

export default ChannelSelector;