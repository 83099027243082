import React from "react";
import ContentLoader from "react-content-loader";

const SideBarMessagesLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={376}
            height={83}
            viewBox="0 0 376 83"
        >
            <rect x="85" y="26" rx="3" ry="3" width="103" height="16" />
            <circle cx="43" cy="44" r="35" />
            <rect x="85" y="47" rx="3" ry="3" width="250" height="16" />
        </ContentLoader>
    )
};

export default SideBarMessagesLoader;