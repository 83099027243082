import { IUserService } from "../../../core/services/users";
import { injectable } from "inversify";
import databaseInstance from "../../index";
import { globalActions } from "../../../store/actions";
import store from "../../../store/configureStore";
import miscUtils from "../../../utils/miscUtils";


@injectable()
export class UserService implements IUserService {
  private dbInstance: any = databaseInstance;

  private static instance: UserService;

  constructor() {
    UserService.instance = this;
  }

  public static getInstance() {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public updateUserProfile: (userId: number, payload: any) => Promise<void> = (
    userId,
    payload
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.put(`/users/${userId}`, payload).then((response: any) => {
        resolve(response);
      });
    });
  };

  public getUserProfile: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/users/${userId}`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public getUserFreeCommunitiesCreatedCount: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/users/${userId}/free-community-count`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public getUserLibraryContents: (userId: number, groupId: number, type: string, page: number, query?: string, has_cateory?: boolean) => Promise<void> = (userId, groupId, type, page, query = "", has_category) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/users/${userId}/groups/${groupId}/library-contents?${type.length > 0 ? `type=${type}` : ''}&page=${page}&search=${query}${has_category !== undefined ? `&has_category=${has_category}` : ""}`).then((response: any) => {
        resolve(response);
      })
      .catch(() => reject());
    });
  };

  public getUserLibraryContentsSnapshot: (
    userId: number,
    groupId: number,
    type: string,
    query?: string,
  ) => Promise<void> = (
    userId, groupId, type, query=""
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get((`/users/${userId}/groups/${groupId}/library-contents?type=${type}${query}`))
      .then((response: any) => {
        resolve(response)
      })
      .catch((error: any) => {
        reject(error);
      })
    })
  }

  public getCountries: () => Promise<void> = () => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/countries`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public getTimezones: () => Promise<void> = () => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/timezones`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public deleteUser: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/users/${userId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public checkEmail: (email: string) => Promise<void> = email => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/email-check`, { email })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public resetPassword: (email: string) => Promise<void> = email => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.post("/password/reset").then((response: any) => {
        resolve(response);
      });
    });
  };

  public updateUserProfileImage: (
    userId: number,
    profileImage: any
  ) => Promise<void> = (userId, profileImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/users/${userId}/profile-image`, profileImage)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateUserPassword: (userId: number, payload: any) => Promise<void> = (
    userId,
    payload
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.put(`/users/${userId}/password`, payload).then(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          store.store.dispatch(globalActions.showMessage(error.data));
        }
      );
    });
  };

  public getUserMembership: (groupId: number, userId: number) => Promise<void> = (groupId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`groups/${groupId}/users/${userId}/membership`).then((response: any) => {
        resolve(response.data);
      });
    });
  };

  public getUserRsvpEvents: (group: any, userId: number, eventType?: string) => Promise<void> = (group, userId, eventType) => {
    let url = '';
    const user_time = miscUtils.getDateInGivenTimezone("Etc/GMT", new Date()).format("YYYY-MM-DD HH:mm:ss");

    if (group && group.subdomain) {
      url += `groups/${group.id}/users/${userId}/rsvped-events?when=${eventType}&user_time=${user_time}`;
    } else {
      url += `users/${userId}/rsvped-events?when=${eventType}&user_time=${user_time}`;
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(url)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public getUserAuthoredPosts: (group: any, userId: number) => Promise<void> = (group, userId) => {
    let url = '';

    if (group && group.subdomain) {
      url += `groups/${group.id}/users/${userId}/authored-posts`;
    } else {
      url += `users/${userId}/authored-posts`;
    }

    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(url)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };


  public getUserAuthoredPostsPostables: (group: any, userId: number) => Promise<void> = (group, userId) => {

    let url = '';

    if (group && group.subdomain) {
      url += `groups/${group.id}/users/${userId}/authored-posts/postables`;
    } else {
      url += `users/${userId}/authored-posts/postables`;
    }

    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(url)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public getUserAuthoredPostsPostableTypeDetails: (
    group: any, userId: number, postableId: number, postableType: string
  ) => Promise<void> = (group, userId, postableId, postableType) => {

    let url = '';

    if (group && group.subdomain) {
      url += `groups/${group.id}/users/${userId}/authored-posts?postable_id=${postableId}&postable_type=${postableType}`;
    } else {
      url += `users/${userId}/authored-posts?postable_id=${postableId}&postable_type=${postableType}`;
    }

      return new Promise<void>((resolve, reject) => {
        this.dbInstance.get(
          url
        ).then((response: any) => {
          resolve(response && response.data);
        }).catch((error: any) => {
          reject(error);
        });
      });
    };

  public getUserAuthoredComments: (group: any, userId: number) => Promise<void> = (group, userId) => {

    let url = '';

    if (group && group.subdomain) {
      url += `groups/${group.id}/users/${userId}/authored-comments?sort=updated_at:desc`;
    } else {
      url += `users/${userId}/authored-comments?sort=updated_at:desc`;
    }

    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(url)
        .then((response: any) => {
          resolve(response && response.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public getUserCommunityPosts: (userId: number, communityId: number) => Promise<void> = (userId, communityId) => {

    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`users/${userId}/authored-posts?group_id=${communityId}`)
        .then((response: any) => {
          resolve(response && response.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public loadMore: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(url)
      .then((response: any) => {
        resolve(response);
      })
      .catch(() => reject());
    })
  }


}
