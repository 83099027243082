import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "./PopOnMe.scss";

//children must be a single html element

const TippyContent: React.FC<{ 
  actions: Array<any>; 
  closeTippy: any, 
  tippyWrapperStyles: any, 
  removeButtonHoverEffect: any, 
  tippyActionButtonStyle: any
}> = ({
  actions,
  closeTippy,
  tippyWrapperStyles,
  removeButtonHoverEffect,
  tippyActionButtonStyle,
}) => {
  return (
    <>
      <div className={`tippy-actions-wrapper`} style={tippyWrapperStyles ? {...tippyWrapperStyles} : {}}>
        {actions.map((action, index) => {
          if(action.useLink) {
            return (
              <a
                className={`tippy-action-button ${removeButtonHoverEffect ? 'no-hover-effect' : ''}`}
                style={tippyActionButtonStyle ? { ...tippyActionButtonStyle } : {}}
                onClick={(event: any) => {
                  event.stopPropagation();
                  setTimeout(() => {
                    closeTippy()
                  }, 100);
                  if (action.data) {
                    action.callback(action.data);
                  } else {
                    action.callback();
                  }
                }}
                key={index}
                target="_self"
                href={action.linkHref}
              >
                <p className="action-label">{action.label}</p>
              </a>
            );
          }
          return (
            <div
              className={`tippy-action-button ${removeButtonHoverEffect ? 'no-hover-effect' : ''}`}
              style={tippyActionButtonStyle ? { ...tippyActionButtonStyle } : {}}
              onClick={(event: any) => {
                event.stopPropagation();
                setTimeout(() => {
                  closeTippy()
                }, 100);
                if (action.data) {
                  action.callback(action.data);
                } else {
                  action.callback();
                }
              }}
              key={index}
            >
              <p className="action-label">{action.label}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

const PopOnMe: React.FC<{
  children: any;
  actions: Array<any>;
  onClick?: any;
  onClickOutside?: any;
  tippyWrapperStyles?: any;
  removeButtonHoverEffect?: boolean;
  hideTippyArrow?: boolean;
  placement?: any;
  appendTo?: any;
  tippyActionButtonStyle?: any
}> = ({ 
  children, 
  actions, 
  onClick, 
  onClickOutside, 
  tippyWrapperStyles, 
  removeButtonHoverEffect,
  hideTippyArrow,
  placement,
  appendTo,
  tippyActionButtonStyle
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const onScrollEvent = () => {
      setTimeout(() => {
        setOpen(false);
      }, 100);
    }

    document.addEventListener("scroll", onScrollEvent);

    return(() => document.removeEventListener("scroll", onScrollEvent));
  }, []);

  return (
    <Tippy
      content={
        <TippyContent 
        actions={actions} 
        closeTippy={() => setOpen(false)} 
        tippyWrapperStyles={tippyWrapperStyles} 
        removeButtonHoverEffect={removeButtonHoverEffect}
          tippyActionButtonStyle={tippyActionButtonStyle}
        />
      }
      visible={open}
      interactive
      onClickOutside={() => {
        if(onClickOutside) {
          onClickOutside();
        }
        setOpen(false)
      }}
      placement={placement ? placement : "bottom-end"}
      theme="light-border"
      appendTo={appendTo ? appendTo : document.body}
      className={`${hideTippyArrow ? 'hide-tippy-arrow' : ''}`}
      zIndex={2147483647}
    >
      {React.cloneElement(children, { onClick: (event: any) => {
        event.stopPropagation();
        if(onClick) {
          onClick(event);
        }
        setOpen(true)
        } })}
    </Tippy>
  );
};

export default PopOnMe;
