import React, { useEffect, useState } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import MainLoadingComponent from "components/mainLoading";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import { Map } from "immutable";
import { IUserAccesContainerProps } from "./IUserAccesContainerProps";
import PublicRoute from "routes/PublicRoute";
import { PrivateRoute } from "../../routes/PrivateRoute";
import appConfig from "../../appConfig";
import miscUtils from "utils/miscUtils";

const AsyncResetPasswordCode: any = Loadable({
  loader: () => import("containers/resetPasswordCode"),
  loading: MainLoadingComponent
});

const AsyncForgotPassword: any = Loadable({
  loader: () => import("containers/forgotPassword"),
  loading: MainLoadingComponent
});

// const AsyncSignup: any = Loadable({
//   loader: () => import("containers/selfSignup"),
//   loading: MainLoadingComponent
// });

const AsyncSelfSignupComponent: any = Loadable({
  loader: () => import("containers/selfSignup"),
  loading: MainLoadingComponent
});

const AsyncLogin: any = Loadable({
  loader: () => import("containers/login"),
  loading: MainLoadingComponent
});

const AsyncUnsubscribeNotifications: any = Loadable({
  loader: () => import("containers/unsubscribeNotifications"),
  loading: MainLoadingComponent
});

const AsyncJoinGroup: any = Loadable({
  loader: () => import("containers/JoinGroup"),
  loading: MainLoadingComponent
});

// const AsyncGroupInvite: any = Loadable({
//   loader: () => import("containers/landingPage/CommunityLandingPage"),
//   loading: MainLoadingComponent
// });

const AsyncNewGroupInvite: any = Loadable({
  loader: () => import("containers/landingPage/NewCommunityLandingPage"),
  loading: MainLoadingComponent
});

const AsyncUserLogin: any = Loadable({
  loader: () => import("containers/AuthContainer/loginComponent"),
  loading: MainLoadingComponent
});

const UserAccesContainer: React.FC<IUserAccesContainerProps> = props => {
  const [render, setRender] = useState<boolean>(false);
  const { isAuthed, globalUser, global, subdomain, group } = props;

  useEffect(() => {
    if (subdomain && window.location.pathname === "/login") {
      let redirect = `${appConfig.domain.protocol}://${subdomain}.${appConfig.domain.baseDomain}`;
      if (appConfig.domain.port) {
        redirect = `${redirect}:${appConfig.domain.port}`;
      }
      window.location.assign(redirect);
    } else {
      setRender(true);
    }
  });

  useEffect(() => {
    if (isAuthed && window.location.pathname === "/signup" && !!getUtmValue()) {
      window.location.assign(`/creategroup?utm_content=${getUtmValue()}`);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/invite" && props.isAuthed) {
      props.history.push("/");
    }
  }, [window.location.pathname])

  const getUtmValue = () => {
    const query = new URLSearchParams(window.location.search);
    const plan_utm = query.get("utm_content");

    return plan_utm;
  }

  return (
    <div className={"main-wrap"}>
      {render && (
        <div
          className={`content-wrap flex flex-justify-center ${!isAuthed &&
            "no-auth"}`}
        >
          <Switch>
            <PublicRoute
              exact={true}
              group={group}
              path="/signup"
              component={<AsyncSelfSignupComponent />}
            />
            <PrivateRoute
              authed={isAuthed}
              group={group}
              user={globalUser}
              exact={true}
              path="/verify-account"
              component={<AsyncSelfSignupComponent />}
            />
            <PrivateRoute
              authed={isAuthed}
              group={group}
              user={globalUser}
              exact={true}
              path="/create-community"
              component={<AsyncSelfSignupComponent />}
            />
            <Route
              exact={true}
              path="/reset-password"
              render={() => <AsyncResetPasswordCode
                theme={global.theme}
                group={group && group}
              />}
            />
            <Route
              exact={true}
              path="/forgot-password"
              render={() => <AsyncForgotPassword group={props.group} theme={global.theme} />}
            />
            <PublicRoute
              authed={isAuthed}
              group={group}
              exact={true}
              path="/"
              component={<AsyncLogin subdomain={subdomain} />}
            />
            <PublicRoute
              authed={isAuthed}
              group={group}
              exact={true}
              path="/login"
              component={<AsyncLogin subdomain={subdomain} />}
            />
            <PublicRoute
              exact={false}
              group={group}
              path="/email_unsubscription"
              component={<AsyncUnsubscribeNotifications isAuthed={isAuthed} />}
            />
            <PublicRoute
              exact={true}
              group={group}
              path="/join_group/:inviteConfigSlug?"
              component={
                <AsyncJoinGroup
                  theme={global.theme}
                  userId={props.uid}
                  group={group && group}
                />
              }
            />
            {/* <PublicRoute
              authed={isAuthed}
              group={group}
              exact={true}
              path="/invite"
              disableRedirect={true}
              component={
                <AsyncGroupInvite
                  subdomain={subdomain}
                  group={group && group}
                  theme={global.theme}
                  planUrl={getUtmValue()}
                />
              }
            /> */}
            <PublicRoute
              authed={isAuthed}
              group={group}
              exact={true}
              path="/invite"
              disableRedirect={true}
              component={
                <AsyncNewGroupInvite
                  subdomain={subdomain}
                  group={group && group}
                  theme={global.theme}
                  planUrl={getUtmValue()}
                />
              }
            />
            <PublicRoute
              authed={isAuthed}
              group={group}
              exact={true}
              path="/invite-preview"
              disableRedirect={true}
              component={
                <AsyncNewGroupInvite
                  subdomain={subdomain}
                  group={group && group}
                  theme={global.theme}
                  planUrl={getUtmValue()}
                />
              }
            />
            {/* <PrivateRoute
              authed={isAuthed}
              group={group}
              exact={true}
              path="/invite-preview"
              disableRedirect={true}
              component={
                <AsyncGroupInvite
                  subdomain={subdomain}
                  group={group && group}
                  theme={global.theme}
                  planUrl={getUtmValue()}
                />
              }
            /> */}
            <PublicRoute
              exact={true}
              group={group}
              path="/signin"
              component={
                <AsyncUserLogin
                  group={group && group}
                  planUrl={getUtmValue()}
                  theme={global.theme}
                  extClass="popup"
                  title="Log In"
                  subtitle="Log in to your account for this community"
                  isAuthed={props.isAuthed}
                  noAccount={true}
                  nbeaFlow={miscUtils.useNbeaFlow(props.group)}
                  OAuthActive={miscUtils.OAuthActivated(props.group)}
                />
              }
            />
          </Switch>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IUserAccesContainerProps
) => {
  return {};
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const global = Map(state.get("global", {})).toJS() as any;
  const globalUser: any = state.getIn(["authorize", "user"]);

  return {
    globalUser,
    global,
    guest: authorize.guest,
    uid: authorize.uid,
    isAuthed: authorize.authed
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserAccesContainer as any) as any
) as any;
