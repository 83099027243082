import React, {useEffect, useState } from "react";
import { INewGroupMessageProps } from "./INewGroupMessageProps";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./new-group-message.scss";
import "./new-group-message-responsive.scss";
import { ReactComponent as CloseIcon } from '../images/close.svg';
// import { ReactComponent as GroupCloseIcon } from 'assets/img/close.svg';
import { MessagingService } from "../../../api/services/messaging";
import { toast, ToastType } from "react-toastify";
import SearchForMembers from "./SearchForMembers/SearchForMembers";
import UserComponent from "components/userComponent";
import miscUtils from "utils/miscUtils";
import { useAtom } from "jotai";
import { chatsAtom, showActiveCHatAtom, showMessagePopUpAtom, showNewGroupMessageMenuAtom } from "showMessageMenuAtom";

const NewGroupMessage: React.FC<INewGroupMessageProps> = props => {
  const {
    group,
    isEditGroupchat,
    setIsEditGroupchat, chat, theme, setIsLoadingMessages, chatroom, setChatroom,
    getGroupchats,
  } = props;

  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [errors, setErrors] = useState<any>();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>();
  const [directChats, setDirectChats] = useAtom(chatsAtom) as any;

  const [formData, setFormData] = useState<any>({
    group_title: "",
    description: "",
    memberIds: []
  });

  useEffect(() => {
    if (isEditGroupchat && chat) {
      setFormData({
        ...formData,
        group_title: chat.title,
        description: chat.description
      })
    }
  }, [chat])   

  useEffect(() => {
    if (isFormSubmitted) {
      validateFormFields();
    }
  }, [selectedMembers, formData])

  useEffect(() => {
    if (!isEditGroupchat) {
      setFormData({
        group_title: "",
        description: "",
        memberIds: []
      });
    }
  }, [isEditGroupchat])  

  const [, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);
  const [, setShowNewGroupMessageMenu] = useAtom(showNewGroupMessageMenuAtom);

  function handleForm() {
    if (isEditGroupchat) {
      editGroupchat();
    } else {
    const memberIds = [...getSelectedMembersIds()];

    const error = validateFormFields();
  
    if (!error) {
      MessagingService.getInstance()
        .createChat(
          group.id,
          "group",
          formData.group_title,
          formData.description,
          memberIds
        )
        .then((response: any) => {
          toast("New group message created successfully.", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
          addToGroupChats(response.data);
          setIsLoadingMessages(true);
          setChatroom({});
          setActiveChatroomMessage(response.data.id)
          setShowNewGroupMessageMenu(false)
        });
      }
    }
  }

  function editGroupchat() {
    MessagingService.getInstance()
    .editGroupchat(
      group.id,
      chat.id,
      formData.group_title,
      formData.description,
    )
    .then((response: any) => {
      toast("Group chat updated successfully.", {
        type: ToastType.SUCCESS,
        autoClose: 3000
      });
      let _groupChats = directChats as any;

      _groupChats.forEach((chat: any, index: any) => {
        if (chat.id === response.data.id) {
          _groupChats[index] = response.data;
        }
      });

      setDirectChats(_groupChats);
      setIsEditGroupchat(false);
      setActiveChatroomMessage(chatroom.id)
      getGroupchats()
      setShowNewGroupMessageMenu(false)
    });
  }

  function validateFormFields() {
    let _errors: any = {};
    let error = false;
    if (!formData.group_title) {
      _errors = {..._errors, group_title: {error_message: "Group title is required."}};
      error = true;
    } else if (!formData.description) {
      _errors = {..._errors, description: {error_message: "Group description is required."}};
      error = true;
    } else if (selectedMembers.length < 1) {
      _errors = { ..._errors, chat_members: { error_message: "Members are required." } };
      error = true;
    }

    setErrors(_errors);
    return error;
  }

  function getSelectedMembersIds() {
    let ids: number[] = new Array();

    if (selectedMembers) {
      selectedMembers.forEach((member: any) => {
        ids.push(member.id);
      });
    }

    return ids;
  }

  function addToSelectedMembers(member: any) {
    const groupChatLimit = group.active_subscription.plan.max_group_chat_size;
    
    if (selectedMembers.length + 1 >= groupChatLimit) {
      toast("Only 10 members are allowed in a group chat.", {
        type: ToastType.WARNING,
        autoClose: 3000
      });
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  }

  function removeSelectedMember(member: any) {
    const filteredMembers = selectedMembers.filter((people: any) => people.id !== member.id);

    setSelectedMembers(filteredMembers);
  }

  function addToGroupChats(chat: any) {
    const { chatrooms } = directChats
    setDirectChats({
      chatrooms,
      data: [chat, ...directChats.data]
    })
    // const _allChats: any = [chat, ...directChats]
    // setDirectChats(_allChats);
  }

  const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);


  const cancelGroupChatComp = () => {
    if (isEditGroupchat) {
      setActiveChatroomMessage(chatroom.id)
      setShowNewGroupMessageMenu(false)
    } else {
      setShowNewGroupMessageMenu(false)
      if (directChats && directChats.length > 0) {
        const allChats = directChats as any
        setActiveChatroomMessage(allChats[0].id)
      } else {
        setOpenMessagePopUp(false)
        document.body.style.overflow = 'auto';

      }
    }
  }

  return (
    <div className="messages-content newgroup">
      <div className="messages-content-header newgroup">
        <h3 className="header-title"> {isEditGroupchat ? "Editing group message" : "Group message"} </h3>
        <p className = "header-subtitle">
          Group messages are best when organized around a topic or event.
        </p>
      </div>
      <div className="messages-content-body newgroup">
        <div className="messages-content-body-wrapper">
          <label>Group topic <span style={{ color: 'red' }}> * </span>
            <span className="added">{(formData && formData.group_title && formData.group_title.length > 0) ? formData.group_title && formData.group_title.length : 0}/{58}</span>
          </label>
          <span className="error-label">
            {errors && errors.group_title && errors.group_title.error_message}
          </span>
          <input className = "group-topic" placeholder="" 
            onChange={(e: any) => {
              setFormData({ ...formData, group_title: e.target.value });
            }}
            value={formData.group_title}
            name="group_title"
            type="text"
            autoComplete={"off"}
            aria-label="Group Title"
            maxLength={58}
          /> 
        </div>
        <div className="messages-content-body-wrapper">
          <label>Description  <span style={{ color: 'red' }}> * </span>
            <span className="added">{(formData && formData.description && formData.description.length > 0) ? formData.description && formData.description.length : 0}/{255}</span>
          </label>
          <span className="error-label">
            {errors && errors.description && errors.description.error_message}
          </span>
          <textarea className = "group-description" maxLength = {255}
            onChange={e =>
              {
                setFormData({ ...formData, description: e.target.value });
              }
            }
            value={formData.description}
            name="description"
            aria-label="Description"
          /> 
        </div>
        {(group && !isEditGroupchat) && (
        <div className="messages-content-body-wrapper">
            <label>Members <span style={{ color: 'red' }}> * </span> <span className="added">{selectedMembers ? selectedMembers.length + 1 : 0}/{group.active_subscription.plan.max_group_chat_size} added</span></label>
          <span className="error-label">
            {errors && errors.chat_members && errors.chat_members.error_message}
          </span>
          <div className = "group-members">
            {selectedMembers && (
              selectedMembers.map((member: any, index: any) => (
                (
                <div 
                  key={index}
                  className="selected-member-item"
                >
                    <UserComponent
                      hideAdmin={true}
                      user={member.user}
                      profilePicture={member.user.profile_image}
                    />
                  <span className = "selected-close"
                    onClick={() => removeSelectedMember(member)}
                  >
                    <CloseIcon className = "member-close"/>
                  </span>
                </div>)
              )
            ))}            
            <SearchForMembers 
              group = {group}
              addToSelectedMembers  = {addToSelectedMembers}
              isGroup = {true}
              selectedMembers = {selectedMembers}
            />
          </div>
        </div>
        )}
        <div className="messages-content-body-wrapper">
          <div className = "create">
            <button
              className="cancel-btn"
              onClick={() => cancelGroupChatComp()}
            >
              Cancel
            </button>
            <button
              className="create-btn"
              style={miscUtils.customThemeSet(theme) ? { color: theme.custom.fontColor, backgroundColor: theme.custom.primaryColor, borderColor: theme.custom.primaryColor } : {}}
              onClick={() => {
                handleForm();
                setIsFormSubmitted(true);
              }}
            >
              {isEditGroupchat ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect(null, null)(NewGroupMessage as any) as any
) as any;