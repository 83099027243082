import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class MessagingService {
  private dbInstance: any = databaseInstance;

  private static instance: MessagingService;

  constructor() {
    MessagingService.instance = this;
  }

  public static getInstance() {
    if (!MessagingService.instance) {
      MessagingService.instance = new MessagingService();
    }
    return MessagingService.instance;
  }

  public createChat: (
    groupId: number, 
    type: string,
    title: string,
    description: string,
    membersIds: any
  ) => Promise<void> = (groupId, type, title, description, membersIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/chat-rooms`, {
          type: type,
          title: title,
          description: description,
          community_members_id: membersIds
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public editGroupchat: (
    groupId: number,
    chatroomId: number, 
    title: string,
    description: string
  ) => Promise<void> = (groupId, chatroomId, title, description) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/chat-rooms/${chatroomId}`, {
          title: title,
          description: description,
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public removeGroupchatMember: (
    groupId: number,
    chatroomId: number,
    memberId: any
  ) => Promise<void> = (groupId, chatroomId, memberId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`groups/${groupId}/chat-rooms/${chatroomId}/members/${memberId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public addGroupchatMember: (
    groupId: number,
    chatroomId: number,
    membersIds: any
  ) => Promise<void> = (groupId, chatroomId, membersIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/chat-rooms/${chatroomId}/members`, {
          group_member_ids: membersIds
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getChatroomMembers: (
    groupId: number,
    chatroomId: number,
    searchQuery?: string
  ) => Promise<void> = (groupId, chatroomId, searchQuery = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/chat-rooms/${chatroomId}/members?q=${searchQuery}`
        )
        .then((response: any) => {
          if (response && response.data) {
            const members: any = response.data;
            resolve(members);
          }
        });
    });
  };

  public getMembersNotInChatroom: (
    groupId: number,
    chatroomId: number,
    canChat: boolean, 
    searchQuery: string, 
    // page: number
  ) => Promise<void> = (groupId, chatroomId, canChat, searchQuery = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/chat-rooms/${chatroomId}/non-members?nsearch=${searchQuery}&can_chat=${canChat}`
        )
        .then((response: any) => {
          if (response && response.data) {
            const groupchatMembers: any = response.data;
            // groupchatMembers.data = this.formatgroupChatMembersData(groupchatMembers);

            resolve(groupchatMembers);
          }
        });
    });
  };

  public getUserChatrooms: (
    groupId: number,
    searchQuery?: string,
    page?: number,
    type?: string,
    // limit?: number
  ) => Promise<void> = (groupId, searchQuery = "", page = 1, type = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/chat-rooms?page=${page}&q=${searchQuery}&type=${type}`
        )
        .then((response: any) => {
          if (response && response.data) {
            const chatrooms: any = response.data;
            resolve(chatrooms);
          }
        });
    });
  };

  public loadMoreChatrooms: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          if (response && response.data) {
            const chatrooms: any = response.data;
            resolve(chatrooms);
          }
        });
    });
  };

  public getChatroom: (
    groupId: number,
    chatroomId: number,
    searchQuery?: string,
    page?: number
  ) => Promise<void> = (groupId, chatroomId, searchQuery = "", page =1) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/chat-rooms/${chatroomId}?page=${page}&q=${searchQuery}`
        )
        .then((response: any) => {
          if (response && response.data) {
            const chatroom: any = response.data;
            resolve(chatroom);
          }
        }).catch((error: any) => {
          reject(error);
        });
    });
  };

  public sendMessageInChatroom: (
    groupId: number,
    chatroomId: number, 
    message: string
  ) => Promise<void> = (groupId, chatroomId, message) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/chat-rooms/${chatroomId}/messages`, {
            message: message
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getChatroomMessages: (
    groupId: number,
    chatroomId: number,
    page?: number,
    limit?: number, 
    startDate?: string,
    endDate?: string
  ) => Promise<void> = (groupId, chatroomId, page, limit, startDate, endDate) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/chat-rooms/${chatroomId}/messages?page=${page}&limit=${limit}&from=${startDate}&to=${endDate}`
        )
        .then((response: any) => {
          if (response && response.data) {
            const messages: any = response.data;
            resolve(messages);
          }
        }).catch((error: any) =>{
          reject(error);
        });
    });
  };

  public deleteMessageInChatroom: (
    messageId: number
  ) => Promise<void> = (messageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/chat-messages/${messageId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public deleteChatroom: (
    groupId: number,
    chatroomId: number
  ) => Promise<void> = (groupId, chatroomId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/chat-rooms/${chatroomId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public leaveChatroom: (
    groupId: number,
    chatroomId: number
  ) => Promise<void> = (groupId, chatroomId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/chat-rooms/${chatroomId}/members`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public likeChatroomMessage: (
    groupId: number,
    chatroomId: number,
    messageId: number,
    reactions: string
  ) => Promise<void> = (
    groupId,
    chatroomId,
    messageId,
    reactions
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/chat-rooms/${chatroomId}/messages/${messageId}/reactions`, {
          type: reactions
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public unlikeChatroomMessage: (
    groupId: number,
    chatroomId: number,
    messageId: number,
    reactions: string
  ) => Promise<void> = (
    groupId,
    chatroomId,
    messageId,
    reactions
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/chat-rooms/${chatroomId}/messages/${messageId}/reactions`, {
          data: {
            type: reactions
          }
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public saveActiveChatroom: (
    groupId: number,
    chatroomId: number
  ) => Promise<void> = (groupId, chatroomId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/active-chat-room`, {
          chat_room_id: chatroomId
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public fetchUserChatHistory: (
    groupId: number,
    userId: number
  ) => Promise<void> = (groupId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/chat-history?user_id=${userId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

}


