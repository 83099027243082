import React from "react";
import ReactModal from "react-modal";

import { ReactComponent as CloseIcon } from "assets/img/close.svg";

const ExpandedInlineImage = (
    { imageSrc, showComponent, setShowComponent, isCommentComponent, originalFileName }:
        {
            imageSrc: string,
            showComponent: boolean,
            setShowComponent: (showComponent: boolean) => void,
            isCommentComponent: boolean,
            originalFileName?: string
        }
) => {

    let filenameWithExtension
    if (isCommentComponent) {
        const parts = imageSrc.split("/");
        const postPart = parts[parts.length - 1];
        const postPartParts = postPart.split("?");
        const filename = postPartParts[0];
        const fileNameWithoutPostString = filename.split("_");
        const newString = fileNameWithoutPostString[fileNameWithoutPostString.length - 1];
        filenameWithExtension = decodeURI(newString.replace(/[^\w\s.-]/gi, " "));
    } else {
        filenameWithExtension = originalFileName && originalFileName.replace(/[^\w\s.-]/gi, " ")
    }

    return (
        <ReactModal
            isOpen={showComponent}
            style={{
                content: {
                    width: "98%",
                    height: "97%",
                    borderRadius: '8px',
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    transform: "translate(-50%, -50%)",
                    position: "fixed",
                    border: 0,
                    overflow: "hidden",
                    padding: 0,
                    margin: 0,
                    backgroundColor: 'white',
                },
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 99999,
                    padding: 0,
                    margin: 0,
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginInline: '16px',
                        marginBlock: '16px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>

                    {
                        (window.innerWidth < 768) ?
                            <span
                                style={{
                                    maxWidth: '220px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {filenameWithExtension}
                            </span>
                            :
                            <span>{filenameWithExtension}</span>
                    }

                    <span
                        onClick={() => setShowComponent(false)}
                        style={{
                            height: 'auto',
                            width: '24px',
                            stroke: '#505050fe',
                            color: '#505050fe',
                            cursor: 'pointer'
                        }}>
                        <CloseIcon />
                    </span>

                </div>

                <span
                    style={{
                        width: '100%',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                ></span>
            </div>

            <div
                style={{
                    width: '100%',
                    height: "100%",
                    padding: '40px',
                }}>

                <img
                    src={imageSrc}
                    alt="inline expanded image"
                    style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '90%',
                    }}
                />
            </div>
        </ReactModal >
    )
}

export default ExpandedInlineImage