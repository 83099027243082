import React from 'react';

const ChevronUpIcon = (props: any) => {
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.274175 6.22491C0.449957 6.40047 0.688237 6.49908 0.936675 6.49908C1.18511 6.49908 1.42339 6.40047 1.59918 6.22491L4.99918 2.82491L8.39918 6.22491C8.485 6.31702 8.5885 6.39089 8.7035 6.44213C8.8185 6.49337 8.94264 6.52093 9.06852 6.52315C9.1944 6.52537 9.31944 6.50221 9.43617 6.45506C9.55291 6.40791 9.65895 6.33773 9.74797 6.2487C9.837 6.15968 9.90718 6.05364 9.95433 5.9369C10.0015 5.82017 10.0246 5.69513 10.0224 5.56925C10.0202 5.44338 9.99264 5.31923 9.9414 5.20424C9.89016 5.08924 9.81628 4.98574 9.72417 4.89991L5.66167 0.837407C5.48589 0.661844 5.24761 0.563232 4.99918 0.563232C4.75074 0.563232 4.51246 0.661844 4.33667 0.837407L0.274175 4.89991C0.0986117 5.07569 0 5.31397 0 5.56241C0 5.81085 0.0986117 6.04913 0.274175 6.22491Z" fill={props.fill ? props.fill : "#666666"}/>
    </svg>
  )
}

export default ChevronUpIcon;