export const isEdgeCard = (index: number, cardsPerRow: number): boolean => {
    return (index + 1) % cardsPerRow === 0;
}

export const isInLastRow = (index: number, parentLength: number, cardsPerRow: number) => {
    const numColumns = cardsPerRow; // Number of columns
    const totalRows = Math.ceil(parentLength / numColumns); // Total number of rows
    const childRow = Math.ceil((index + 1) / numColumns); // Row index of the child
    return childRow === totalRows;
}

export const isLastCard = (index: number, parentLength: number) => {
    return index + 1 === parentLength;
} 

export const getGridCardWidth = (cardsPerRow: number) => {
    switch(cardsPerRow) {
        case 1:
            return '100%';
        case 2:
            return '48%';
        default:
            return '32%';
    }
}

export const getGridCardMargin = (cardsPerRow: number, marginBottom?: boolean) => {
    switch(cardsPerRow) {
        case 1:
            if(marginBottom) {
                return '20px';
            }
            return '0';
        case 2:
            return '4%';
        default:
            return '2%';
    }
}