import React from 'react';
import ScrollSpinner from 'components/scrollSpinner';
import "./AppLoader.scss";

const AppLoader: React.FC<{label?: string, noParent?: boolean}> = ({label, noParent}) => {
    return (
        <div className={`app-loader-container ${noParent ? 'app-loader-container-noparent' : ''}`}>
            <ScrollSpinner label={label ? label : ''}/>
        </div>
    )
}

export default AppLoader;