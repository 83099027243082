import { injectable } from "inversify";
import databaseInstance from "../../index";
// import { Post } from "../../../core/models/posts";

@injectable()
export class ChannelService {
  private dbInstance: any = databaseInstance;

  private static instance: ChannelService;

  constructor() {
    ChannelService.instance = this;
  }

  public static getInstance() {
    if (!ChannelService.instance) {
      ChannelService.instance = new ChannelService();
    }
    return ChannelService.instance;
  }

  public inviteMembersToChannel: (
    channelId: number,
    groupId: number,
    membersIds: any
  ) => Promise<void> = (channelId, groupId, membersIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/channels/${channelId}/members`, {
          group_member_ids: membersIds
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public removeChannelMember: (
    groupId: number,
    channelId: number,
    membersIds: any
  ) => Promise<void> = (groupId, channelId, membersIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`groups/${groupId}/channels/${channelId}/members`, {
          data: {
            channel_member_ids: membersIds
          }
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getChannelMembers: (
    channelId: number,
    groupId: number,
    query?: string
  ) => Promise<void> = (channelId, groupId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/channels/${channelId}/members${query}`
        )
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getNonChannelMembers: (
    channelId: number,
    groupId: number,
    query?: string,
  ) => Promise<void> = (channelId, groupId, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/channels/${channelId}/non-members${query}`)
      .then((response: any) => {
        resolve(response);
      });
    });
  };

  public formatChannelMembersData(members: any){
    return members.data.map((member: any) => {
      let memberType = "";
      let memberStatus = "";

      if (member.approved) {
        memberType = "Member";
        memberStatus = "Active";
      }

      if (member.admin || member.owner) {
        memberType = "Admin";
      }

      if (member.blocked) {
        memberStatus = "Blocked";
      }

      if (!member.job_title) {
        member.job_title = "N/A";
      }

      if (!member.location) {
        member.location = "N/A";
      }

      member.user = member;
      member.user = {
        ...member.user,
        type: memberType,
        status: memberStatus
      };
      return member;
    });
  }

  public leaveGroupChannel: (
    userId: number,
    channelId: number,
    groupId: number
  ) => Promise<void> = (userId, channelId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/users/${userId}/groups/${groupId}/channels/${channelId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public joinOpenChannel: (
    userId: number,
    channelId: number,
    groupId: number
  ) => Promise<void> = (userId, channelId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/users/${userId}/groups/${groupId}/channels`, {
          channel_id: channelId
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public createChannel: (
    groupId: number,
    name: string,
    description: string,
    status: string,
    posting_permission: string
  ) => Promise<void> = (
    postId,
    name,
    description,
    status,
    posting_permission
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${postId}/channels`, {
          name,
          description,
          status,
          posting_permission
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public createNewChannel: (groupId: number, channelDetails: any) => Promise<void> = (groupId, channelDetails) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/channels`, channelDetails)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => reject(error));
    })
  }

  public filterArchivedChannels(channels: any) {
    channels.data = channels.data.map((channel: any) => {
      let channelStatus;

      if (channel.deleted_at) {
        channelStatus = "archived";
      }

      channel = {
        ...channel,
        status: channelStatus || channel.status
      };
      return channel;
    });
    return channels;
  }

  public getUserChannels: (
    userId: number,
    groupId: number,
    search?: string,
    limit?: number
  ) => Promise<void> = (userId, groupId, search, limit) => {
    if (!search) {
      search = "";
    }

    return new Promise<void>((resolve, reject) => {
      if (groupId && userId) {
        let query: any = `/users/${userId}/groups/${groupId}/channels?search=${search}`;
        if (limit) query = `${query}&limit=${limit}`;
        this.dbInstance.get(query).then((response: any) => {
          if (response && response.data) {
            let groupChannels: any = response.data;
            groupChannels = this.filterArchivedChannels(groupChannels);
            resolve(groupChannels);
          }
        });
      }
    });
  };

  public getGroupChannels: (
    groupId: number,
    query?: string
  ) => Promise<void> = (groupId, query) => {
    if (!query) {
      query = "";
    }
    return new Promise<void>((resolve, reject) => {
      if (groupId) {
        this.dbInstance
          .get(`/groups/${groupId}/channels?limit=100&search=${query}`)
          .then((response: any) => {
            if (response && response.data) {
              let groupChannels: any = response.data;
              groupChannels = this.filterArchivedChannels(groupChannels);
              resolve(groupChannels);
            }
          });
      }
    });
  };

  public getChannels: (groupId: number, query?: string) => Promise<void> = (groupId, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/channels${query}`)
      .then((response: any) => {
        resolve(response);
      })
    })
  }

  public getChannel: (groupId: number, channelId: any) => Promise<void> = (groupId, channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/channels/${channelId}`)
      .then((response: any) => {
        resolve(response);
      });
    })
  }

  public loadMore: (url: string, query?: string) => Promise<void> = (url, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url + query)
      .then((response: any) => {
        resolve(response);
      })
    })
  }
  
  public getGroupAndUserChannels(
    groupId: number,
    userId?: number,
    query?: string,
    limit?: number
  ) {
    const channelsPromise = [];
    channelsPromise.push(this.getGroupChannels(groupId, query));
    if (userId) {
      channelsPromise.push(this.getUserChannels(userId, groupId, query, limit));
    }
    return Promise.all(channelsPromise)
      .then(
        (response: any) => {
          let channels: any = { data: [] };
          if (response[1]) {
            channels.data = [...response[0].data, ...response[1].data];
          } else {
            channels.data = response[0].data;
          }

          channels.data = channels.data.reduce((unique: any, o: any) => {
            if (!unique.some((obj: any) => obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);
          channels = this.filterArchivedChannels(channels);
          return channels;
        },
        () => {
          return [];
        }
      )
      .catch(() => {
        return [];
      });
  }

  public archiveGroupChannel: (
    groupId: number,
    channelId: number
  ) => Promise<void> = (groupId, channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/channels/${channelId}/archives`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public unarchiveGroupChannel: (
    groupId: number,
    channelId: number
  ) => Promise<void> = (groupId, channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/channels/${channelId}/archives`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public deleteGroupChannel: (
    channelId: number,
    groupId: number
  ) => Promise<void> = (channelId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/channels/${channelId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateGroupChannel: (
    groupId: number,
    channelId: number,
    payload: any
  ) => Promise<void> = (groupId, channelId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/channels/${channelId}`, payload)
        .then((response: any) => {
          resolve(response);
        })
        .catch(() => reject());
    });
  };

  public pinPost: (channelId: any, postId: any) => Promise<void> = (
    channelId,
    postId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/postables/${channelId}/posts/${postId}/pinned`, {
          postable_type: 'channel',
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getPinnedPost: (channelId: any) => Promise<void> = channelId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/postables/${channelId}/posts/pinned?include=comments&postable_type=channel`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public unPinPost: (channelId: any, postId: any) => Promise<void> = (
    channelId,
    postId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/postables/${channelId}/posts/${postId}/pinned`, {
          data: {
            postable_type: 'channel',
          }
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getChannelPosts: (
    groupId: number,
    channelId: number,
    query: any, 
    limit?: number
  ) => Promise<void> = (groupId, channelId, query, limit = 10) => {
    const { search } = query;
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/channels/${channelId}/posts?sort=published_at:desc&search=${
            search ? search : ""
          }&include=comments&limit=${limit}`
        )
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        }).catch((error: Error) => reject(error));
    });
  };

  public exportChannelMembersList: (
    channelId: number,
  ) => Promise<void> = (channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/channels/${channelId}/members/export`)
      .then((response: any) => {
        resolve(response);
      })
    })
  }

  public uploadChannelCoverImage: (
    channelId: number,
    formData: any,
  ) => Promise<void> = (channelId, formData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/channels/${channelId}/cover-image`, formData)
        .then(() => resolve());
    })
  }

  public getChannelUserProfileImages: (
    channelId: number,
  ) => Promise<void> = (channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/channels/${channelId}/user-profile-images`)
      .then((response: any) => resolve(response));
    })
  }

  public getChannelCollections: (
    groupId: number,
    query?: string,
  ) => Promise<void> = (groupId, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/channel-collections${query}`)
      .then((response: any) => resolve(response));
    });
  }

  public getChannelCollection: (
    groupId: number,
    collectionId: number
  ) => Promise<void> = (groupId, collectionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/channel-collections/${collectionId}`)
      .then((response: any) => resolve(response));
    })
  }

  public createChannelCollection: (
    groupId: number,
    collectionData: any,
  ) => Promise<void> = (groupId, collectionData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/channel-collections`, collectionData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public updateChannelCollection: (
    groupId: number,
    collectionId: number,
    collectionData: any,
  ) => Promise<void> = (groupId, collectionId, collectionData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/channel-collections/${collectionId}`, collectionData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public deleteChannelCollection: (
    groupId: number,
    collectionId: number,
  ) => Promise<void> = (groupId, collectionId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/channel-collections/${collectionId}`)
      .then(() => resolve())
    });
  }

  public reorderChannelCollections: (
    groupId: number,
    newPositionsData: any,
  ) => Promise<void> = (groupId, newPositionsData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/channel-collections/positions`, newPositionsData)
      .then((response: any) => resolve(response));
    });
  }

  public reorderChannelsInCollection: (
    groupId: number,
    collectionId: number,
    newPositionsData: any,
  ) => Promise<void> = (groupId, collectionId, newPositionsData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/channel-collections/${collectionId}/channels/positions`, newPositionsData)
      .then((response: any) => resolve(response));
    })
  }

  public readChannelPosts: (
    // groupId: number,
    channelId: number
  ) => Promise<void> = (channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/channels/${channelId}/unread-posts-count`)
      .then(() => resolve())
      .catch(() => reject());
    })
  }
}
