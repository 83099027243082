import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class PostService {
  private dbInstance: any = databaseInstance;

  private static instance: PostService;

  constructor() {
    PostService.instance = this;
  }

  public static getInstance() {
    if (!PostService.instance) {
      PostService.instance = new PostService();
    }
    return PostService.instance;
  }

  public createPost: (
    groupId: number,
    postableId: number,
    postObject: any
  ) => Promise<void> = (groupId, postableId, postObject) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/postables/${postableId}/posts`, postObject)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public createIntroductionPost: (
    groupId: number,
    postObject: any
  ) => Promise<void> = (groupId, postObject) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/submit-introduction`, postObject)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public getPosts: (
      groupId: number,
      postableId: number,
      postableType: string,
      query?: string,
  ) => Promise<void> = (groupId, postableId, postableType, query="") => {
      return new Promise<void>((resolve, reject) => {
          this.dbInstance
          .get(`/groups/${groupId}/postables/${postableId}/posts?postable_type=${postableType}${query}`)
          .then((response: any) => {
              resolve(response);
          });
      })
  }

  public getPinnedPosts: (
    postableId: number,
    postableType: string,
    query?: string,
  ) => Promise<void> = (postableId, postableType, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/postables/${postableId}/posts/pinned?postable_type=${postableType}${query}`)
        .then((response: any) => {
          resolve(response);
        })
    })
  }

  public sharePost: (
    groupId: number,
    postableId: number,
    postableType: string,
    postObject: any,
  ) => Promise<void>  = (groupId, postableId, postableType, postObject) => {

    if(postObject instanceof FormData) {
      postObject.append("postable_type", postableType)
    } else {
      postObject = {
        ...postObject,
        postable_type: postableType,
      }
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/postables/${postableId}/posts`, postObject)
        .then((response: any) => resolve(response));
    })
  }

  public pinPost: (
    postableId: number,
    postId: number,
    postableType: string,
  ) => Promise<void> = (postableId, postId, postableType) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/postables/${postableId}/posts/${postId}/pinned`, {
          postable_type: postableType,
        })
        .then(() => resolve())
    })
  }

  public unpinPost: (
    postableId: number,
    postId: number,
    postableType: string,
  ) => Promise<void> = (postableId, postId, postableType) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/postables/${postableId}/posts/${postId}/pinned`, {
          data: {
            postable_type: postableType
          }
        })
        .then(() => resolve());
    })
  }

  public updatePost: (
    postId: number,
    postObject: any,
  ) => Promise<void> = (postId, postObject) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/posts/${postId}`, postObject)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public updateComment: (
    commentId: number,
    comment: any,
  ) => Promise<void> = (commentId, comment) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.post(`/comments/${commentId}`, comment)
      .then((response: any) => resolve(response));
    })
  }

  public getCommentComments: (
    groupId: number,
    commentId: number,
  ) => Promise<void> = (groupId, commentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/comments/${commentId}/comments`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public replyToComment: (
    groupId: number,
    commentId: number,
    replyData: any,
  ) => Promise<void> = (groupId, commentId, replyData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/comments/${commentId}/comments`, replyData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public reactToComment: (
    groupId: number,
    commentId: number,
    reactionData: any,
  ) => Promise<void> = (groupId, commentId, reactionData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/comments/${commentId}/reactions`, reactionData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public removeCommentReaction: (
    groupId: number,
    commentId: number,
    reactionData: any,
  ) => Promise<void> = (groupId, commentId, reactionData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/comments/${commentId}/reactions`, {
        data: reactionData,
      })
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public getReactersToPost: (
    groupId: number,
    postId: number,
  ) => Promise<void> = (groupId, postId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/posts/${postId}/reacters`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public getReactersToComment: (
    groupId: number,
    commentId: number,
  ) => Promise<void> = (groupId, commentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/comments/${commentId}/reacters`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public loadMore: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(url)
      .then((response: any) => {
        resolve(response);
      })
      .catch(() => reject());
    })
  }

  public getCustomizedPosts: (
    groupId: number,
    userId: number,
  ) => Promise<void> = (groupId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/users/${userId}/posts`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public getOpenPosts: (
    groupId: number,
  ) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/homepage-posts`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public createFeaturePost: (
    groupId: number,
    channelId: number,
    postId: any
  ) => Promise<void> = (groupId, channelId, postId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/channels/${channelId}/posts/${postId}/featured`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public deleteFeaturedPost: (
    groupId: number,
    channelId: number,
    postId: any
  ) => Promise<void> = (groupId, channelId, postId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .delete(`/groups/${groupId}/channels/${channelId}/posts/${postId}/featured`)
        .then((response: any) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public fetchFeaturedPosts: (
    groupId: number,
    channelId: number,
  ) => Promise<void> = (groupId, channelId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .get(`/groups/${groupId}/channels/${channelId}/posts/featured`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public convertToArticle: (groupId: number, postId: number) => Promise<void> = (groupId, postId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/posts/${postId}/article`)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    })
  }

} 
