import React from 'react'

import './no-data.scss'
import { ReactComponent as DataIcon } from 'assets/img/book-line.svg'

const NoDataState = (
    {
        itemType,
        desc
    }: {
        itemType: string
            desc?: string
    }) => {
    return (
        <div className='no-data-container'>
            <DataIcon />
            <h1>{itemType}</h1>
            <span>{desc}</span>
        </div>
    )
}

export default NoDataState