import { isValidHexColor } from "components/PageBuilder/PageBuilderSideBar/BlockSettings/domain";
import styled from "styled-components";

const BlockTextContainer: any = styled.span<{
    design: any, 
    textKey: string, 
    fallbackTextColor: string
}>`
    * {
        margin: 0;
        padding: 0;
    }

    > *:only-child:empty,
    > *:only-child:has(br:first-child:last-child) {
        display: none;
    }

    * br {
        content: "";
        display: block;
        font-size: 65%;
        height: 16px;
    }

    width: 100%;
    display: block;
    text-align: ${(props: any) => 
        props.design.text_alignment
    };

    ul {
        list-style: disc;
    }

    ul, ol {
        list-style-position: ${(props: any) =>
            props.design.text_alignment === 'left' ?
            'outside' :
            'inside'
        };
        margin-left: ${(props: any) => 
            props.design.text_alignment === 'left' ?
            '1.5em' :
            '0'
        };
    }

    h1, h2, h3 {
        margin: 0;
        padding: 0;
    }

    h1 {
        font-size: ${(props: any) =>
            props.design[`${props.textKey}_h1_font_size`]  &&
            props.design[`${props.textKey}_h1_font_size`]?
            `${props.design[`${props.textKey}_h1_font_size`]}px` :
            `35px`
        };
        line-height: ${(props: any) =>
            props.design[`${props.textKey}_h1_line_height`] &&
            props.design[`${props.textKey}_h1_line_height`] <= 50 ?
            `${props.design[`${props.textKey}_h1_line_height`]}px` :
            `45px`
        };
        font-family: ${(props: any) =>
            props.design[`${props.textKey}_h1_font_family`] ?
            `${props.design[`${props.textKey}_h1_font_family`]} !important` :
            `-apple-system, Arial !important`
        };
        color: ${(props: any) => 
            props.design[`${props.textKey}_h1_color`] &&
            isValidHexColor(props.design[`${props.textKey}_h1_color`]) ?
            props.design[`${props.textKey}_h1_color`] :
            props.fallbackTextColor
        };
    }

    h2 {
        font-size: ${(props: any) =>
            props.design[`${props.textKey}_h2_font_size`] &&
            props.design[`${props.textKey}_h2_font_size`] <= 50 ?
            `${props.design[`${props.textKey}_h2_font_size`]}px` :
            `31px`
        };
        line-height: ${(props: any) =>
            props.design[`${props.textKey}_h2_line_height`] &&
            props.design[`${props.textKey}_h2_line_height`] <= 50 ?
            `${props.design[`${props.textKey}_h2_line_height`]}px` :
            `40px`
        };
        font-family: ${(props: any) =>
            props.design[`${props.textKey}_h2_font_family`] ?
            `${props.design[`${props.textKey}_h2_font_family`]} !important` :
            `-apple-system, Arial !important`
        };
        color: ${(props: any) => 
            props.design[`${props.textKey}_h2_color`] &&
            isValidHexColor(props.design[`${props.textKey}_h2_color`]) ?
            props.design[`${props.textKey}_h2_color`] :
            props.fallbackTextColor
        };
    }

    h3 {
        font-size: ${(props: any) =>
            props.design[`${props.textKey}_h3_font_size`] &&
            props.design[`${props.textKey}_h3_font_size`] <= 50 ?
            `${props.design[`${props.textKey}_h3_font_size`]}px` :
            `27px`
        };
        line-height: ${(props: any) =>
            props.design[`${props.textKey}_h3_line_height`] &&
            props.design[`${props.textKey}_h3_line_height`] <= 50 ?
            `${props.design[`${props.textKey}_h3_line_height`]}px` :
            `35px`
        };
        font-family: ${(props: any) =>
            props.design[`${props.textKey}_h3_font_family`] ?
            `${props.design[`${props.textKey}_h3_font_family`]} !important` :
            `-apple-system, Arial !important`
        };
        color: ${(props: any) => 
            props.design[`${props.textKey}_h3_color`] &&
            isValidHexColor(props.design[`${props.textKey}_h3_color`]) ?
            props.design[`${props.textKey}_h3_color`] :
            props.fallbackTextColor
        };
    }

    p, ul, ol {
        font-size: ${(props: any) =>
            props.design[`${props.textKey}_p_font_size`] &&
            props.design[`${props.textKey}_p_font_size`] <= 50 ?
            `${props.design[`${props.textKey}_p_font_size`]}px` :
            `20px`
        };
        line-height: ${(props: any) =>
            props.design[`${props.textKey}_p_line_height`] &&
            props.design[`${props.textKey}_p_line_height`] <= 50 ?
            `${props.design[`${props.textKey}_p_line_height`]}px` :
            `25px`
        };
        font-family: ${(props: any) =>
            props.design[`${props.textKey}_p_font_family`] ?
            `${props.design[`${props.textKey}_p_font_family`]} !important` :
            `-apple-system, Arial !important`
        };
        color: ${(props: any) => 
            props.design[`${props.textKey}_p_color`] &&
            isValidHexColor(props.design[`${props.textKey}_p_color`]) ?
            props.design[`${props.textKey}_p_color`] :
            props.fallbackTextColor
        };
    }

    a {
        color: #365de6;
    }

    >*:has(br:first-child:last-child) {
        line-height: 16px;
    }
`;

export const BlockSubTextContainer: any = styled(BlockTextContainer)`
    margin-top: 10px;
`

export default BlockTextContainer;