import React from 'react';
import './GroupPostCardView.scss';
import { ReactComponent as NoImageIcon } from '../../../../containers/groupView/GroupDetailsCard/icons/no-image.svg';
import FlexyPopOnMe from 'elements/flexypoponme';
import miscUtils from 'utils/miscUtils';
import { useHistory } from 'react-router';
import { toast, ToastType } from 'react-toastify';
import ReadMore from 'utils/readMore/readMore';
import { ReactComponent as Pinned } from "../flag.svg";
import Moment from 'react-moment';
import { ReactComponent as LoveIcon } from 'components/commentComponent/icons/love.svg';
import { ReactComponent as LoveIconFill } from 'components/commentComponent/icons/love-fill.svg';
import { ReactComponent as CommentIcon } from 'components/commentComponent/icons/comment.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import useSlideoutProfile from 'hooks/slideoutProfileHook';
import { ISlideoutProfileData } from 'types';

const TITLE_CHARACTER_LIMIT: number = 50;

const GroupPostCardView = (props: any) => {

    const history = useHistory();
    const { openSlideout } = useSlideoutProfile();
    const openedSlideoutProfileData: ISlideoutProfileData = props.global.slideoutProfileData;

    const openSinglePostPage = () => {
        history.push(`/posts/${props.post.slug}`);
    }

    const copyToClipboard = () => {
        toast("Post URL copied", {
            type: ToastType.SUCCESS,
            autoClose: 3000
        });
    }

    const getCustomTitle = () => {
        return miscUtils.stripHTML(props.post.description).slice(0, 200);
    }

    return (
        <div
            className={`gp-post-crd-container ${props.hideCover ? 'hidden-cover' : ''}`}
        >
            {(props.pinnedpost || props.announcementPost) ? (
                <div
                    className={`gp-post-crd-pinned-container ${props.hideCover ? 'hidden-cover' : ''}`}
                >
                    <Pinned
                        fill={
                            props.theme && props.theme.custom ? props.theme.custom.primaryColor : "#365DE6"
                        }
                    />
                </div>
            ) : (<></>)}

            {props.hideCover ? (
                <></>
            ) : props.post.cover_image_url ? (
                <div
                    className="gp-post-crd-cover-container"
                    onClick={openSinglePostPage}
                >
                    <img
                        src={props.post.cover_image_url}
                        alt="post-cover"
                        className="gp-post-crd-cover-image"
                    />
                </div>
            ) : (
                <div
                    className="gp-post-crd-cover-container"
                    onClick={openSinglePostPage}
                >
                    <span
                        className="gp-post-crd-cover-no-image"
                    >
                        <NoImageIcon
                        />
                    </span>
                </div>
            )}
            <div
                className={`gp-post-crd-content-container ${props.hiddenCover ? 'hidden-cover' : ''}`}
            >
                <div
                    className="gp-post-crd-content-menu-container"
                >
                    {props.user && props.isFlexyOpen ? (
                        <FlexyPopOnMe
                            closeOnComponentClick
                            component={
                                <>
                                    <ul className="group-dropdown-post dropdown post-dropdown-listview">
                                        <li>
                                            {((props.group && props.group.user_membership && miscUtils.isAdmin(props.group.user_membership)) || (props.user && props.user.id === props.post.author.id)) && (!props.announcementPost) && (
                                                <button
                                                    onClick={() => {
                                                        props.setIsEditMode(true);
                                                    }}
                                                    className="group-post-dropdown-btn group-post-dropdown-btn--edit-post"
                                                >
                                                    <p className="main-text">Edit</p>
                                                </button>
                                            )}
                                            {props.canBroadcast && (
                                                <button
                                                    onClick={() => props.broadcastPost()}
                                                    className="group-post-dropdown-btn"
                                                >
                                                    <p className="main-text">Broadcast</p>
                                                    {/* <p className="sub-text">Notify members</p> */}
                                                </button>
                                            )}

                                            {!props.announcementPost && props.isAdmin && props.pinnable && (
                                                <>
                                                    {props.post.pinned_at ? (
                                                        <button
                                                            onClick={() => {
                                                                if (
                                                                    window.location.pathname.includes("lectures") && (window.location.pathname.includes("library") || window.location.pathname.includes("courses"))
                                                                ) {
                                                                    if (props.match.params.contentId) {
                                                                        props.unpinPostLibrary(props.match.params.contentId, props.post.id, 'course-content')
                                                                    }

                                                                    if (props.match.params.libraryItemId) {
                                                                        props.unpinPostLibrary(props.post.postable_id, props.post.id, props.match.params.libraryItemType.slice(0, -1))
                                                                    }
                                                                } else if (
                                                                    window.location.pathname.split("/")[1] === "events"
                                                                ) {
                                                                    props.unpinPostFromPostable(props.post.postable_id, props.post.id, 'event')
                                                                } else {
                                                                    props.unPinPost(props.post.postable_id, props.post.id);
                                                                }
                                                            }}
                                                            className="group-post-dropdown-btn"
                                                        >
                                                            <p className="main-text">Unpin post</p>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => {
                                                                if (
                                                                    window.location.pathname.includes("lectures") && (window.location.pathname.includes("library") || window.location.pathname.includes("courses"))
                                                                ) {
                                                                    if (props.match.params.contentId) {
                                                                        props.pinPostLibrary(props.match.params.contentId, props.post.id, 'course-content')
                                                                    }

                                                                    if (props.match.params.libraryItemId) {
                                                                        props.pinPostLibrary(props.post.postable_id, props.post.id, props.match.params.libraryItemType.slice(0, -1))
                                                                    }
                                                                } else if (
                                                                    window.location.pathname.split("/")[1] === "events"
                                                                    ) {
                                                                        props.pinPostFromPostable(props.post.postable_id, props.post.id, 'event')
                                                                    } else {
                                                                        props.pinPost(props.post.postable_id, props.post.id);
                                                                    }
                                                                }}
                                                                className="group-post-dropdown-btn"
                                                            >
                                                            <p className="main-text">Pin post</p>
                                                        </button>
                                                    )}
                                                </>
                                            )}

                                            {/* Feature a post */}
                                            {props.post && (miscUtils.isTeamMember(props.group && props.group.user_membership)) ? (
                                                <button
                                                    className="group-post-dropdown-btn"
                                                    onClick={() => props.post.featured_at === null ? props.featureAPost() : props.removefeaturedPost()}
                                                >
                                                    <p className="main-text">
                                                        {props.post.featured_at === null ? 'Feature this post' : 'Remove featured post'}
                                                    </p>
                                                </button>
                                            ) : (<></>)}


                                            {!props.announcementPost && (
                                                <button
                                                    onClick={() => props.updateUserNotifications(props.user.id, props.post.id)}
                                                    className="group-post-dropdown-btn"
                                                >
                                                    <p className="main-text">
                                                        {props.post &&
                                                            props.post.post_settings &&
                                                            props.post.post_settings.email_notification
                                                            ? "Turn off post notifications"
                                                            : "Turn on post notifications"}
                                                    </p>
                                                </button>
                                            )}

                                            {/* end members can flag a post */}
                                            {props.post && (miscUtils.isTeamMember(props.group && props.group.user_membership)) && window.location.pathname.split("/")[1] === 'channel' ? (
                                                <button
                                                    className="group-post-dropdown-btn"
                                                    onClick={props.updateCommentingStatus}
                                                >
                                                    <p
                                                        className="main-text"
                                                    >
                                                        {props.post.commenting_closed ? 'Open commenting' : 'Close commenting'}
                                                    </p>
                                                </button>
                                            ) : (<></>)}

                                            {/* Convert into an article */}
                                            {
                                                props.post &&
                                                    props.post.postable_type !== 'articles' &&
                                                    (miscUtils.isTeamMember(props.group && props.group.user_membership)) &&
                                                    !props.announcementPost ? (
                                                    <button
                                                        className="group-post-dropdown-btn"
                                                        onClick={props.convertPostToArticle}
                                                    >
                                                        <p className="main-text">Convert into an article</p>
                                                    </button>
                                                ) : (<></>)}


                                            {/* members can flag a post */}
                                            {(!props.announcementPost) && (props.user.id !== props.post.author.id) &&
                                                <button
                                                onClick={() => props.flagPost(props.post.id)}
                                                className="group-post-dropdown-btn"
                                            >
                                                <p className="main-text">Flag post</p>
                                            </button>}

                                            {(!props.announcementPost && (props.isAdmin || (props.user && props.user.id === props.post.author.id))) && (
                                                <button
                                                    onClick={() => props.openDeleteModal()}
                                                    className="group-post-dropdown-btn"
                                                >
                                                    <p className="main-text">Delete post</p>
                                                </button>
                                            )}

                                        </li>
                                    </ul>
                                </>
                            }
                        >
                            <div
                                className="gp-post-crd-content-menu-icon"
                            >
                                <button
                                    className="btn btn-dots btn-post-dots"
                                >
                                    <span />
                                    <span />
                                    <span />
                                </button>
                            </div>
                        </FlexyPopOnMe>
                    ) : (<></>)}
                </div>


                {props.post.title ? (
                    <>
                        <Link
                            className={`gp-post-crd-content-title-container ${props.hideCover ? 'hidden-cover' : ''}`}
                            // onClick={openSinglePostPage}
                            to={`/posts/${props.post.slug}`}
                        >
                            <ReadMore
                                text={props.post.title}
                                numberOfLines={1}
                                lineHeight={1}
                                showDefaultButton={false}
                                readMoreCharacterLimit={TITLE_CHARACTER_LIMIT}
                            />
                        </Link>
                    </>
                ) : (
                    <>
                        <Link
                            className={`gp-post-crd-content-title-container ${props.hideCover ? 'hidden-cover' : ''}`}
                            // onClick={openSinglePostPage}
                            to={`/posts/${props.post.slug}`}
                        >
                            <ReadMore
                                text={getCustomTitle()}
                                numberOfLines={1}
                                lineHeight={1}
                                showDefaultButton={false}
                                readMoreCharacterLimit={TITLE_CHARACTER_LIMIT}
                            />
                        </Link>
                    </>
                )}


                <div
                    className="gp-post-crd-content-author-container"
                >
                    <p
                        className="gp-post-crd-content-author-name"
                        onClick={() => {
                            if (
                                props.announcementPost ||
                                !props.group ||
                                (
                                    props.group &&
                                    props.group.membership_status !== "member"
                                )
                            ) {
                                return;
                            }
                            openSlideout({ user: { user: props.post.author } }, openedSlideoutProfileData && openedSlideoutProfileData.user)
                        }}
                    >
                        {props.post.author.full_name}
                    </p>

                    <div
                        className="gp-post-crd-content-author-timestamp"
                        onClick={openSinglePostPage}
                    >
                        <Moment utc={true} fromNow ago>
                            {props.post.published_at}
                        </Moment>
                        <span>ago</span>
                    </div>
                </div>
            </div>

            <div
                className={`gp-post-crd-content-reaction-container ${props.hideCover ? 'hidden-cover' : ''}`}
            >
                {!props.disableReactions ? (
                    <>
                        <div
                            className="gp-post-crd-content-reaction-unit-container no-margin"
                            onClick={(e: any) => {
                                e.stopPropagation();
                                props.likeUnlikeReaction();
                            }}
                        >
                            {props.postReactionLikeState.liked ? <span><LoveIconFill /></span> : <span><LoveIcon /></span>}
                            <p
                                className="gp-post-crd-content-reaction-unit-label"
                            >
                                {props.postReactionLikeState.reaction_like_count}
                            </p>
                        </div>

                        <div
                            className="gp-post-crd-content-reaction-unit-container"
                            onClick={() => {
                                if (props.view === "public") {
                                    props.publicLogin();
                                    return;
                                }

                                openSinglePostPage();
                            }}
                        >
                            {<span><CommentIcon /></span>}
                            <p
                                className="gp-post-crd-content-reaction-unit-label"
                            >
                                {props.commentsCount}
                            </p>
                        </div>
                    </>
                ) : (<></>)}

                <CopyToClipboard
                    text={`${window.location.origin}/posts/${props.post.slug}`}
                    onCopy={() => {
                        copyToClipboard();
                    }}
                >
                    <div
                        className="gp-post-crd-content-reaction-unit-container"
                        onClick={(e: any) => e.stopPropagation()}
                    >
                        <span><ShareIcon /></span>
                    </div>
                </CopyToClipboard>
            </div>

        </div>
    )
}

export default GroupPostCardView;