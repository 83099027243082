import React from 'react';
import HeadlinesPicker from '../HeadlinesPicker';
import editorStyles from './HeadlinesButton.module.scss';

const HeadlinesButton = (props: any) => {
    const onClick = () =>
      // A button can call `onOverrideContent` to replace the content
      // of the toolbar. This can be useful for displaying sub
      // menus or requesting additional information from the user.
      props.onOverrideContent(HeadlinesPicker);
  
      return (
        <div 
        className={editorStyles.headlineButtonWrapper}
        onMouseDown={props.onMouseDown ? props.onMouseDown : undefined}
        >
          <button onClick={onClick} className={editorStyles.headlineButton}>
            H
          </button>
        </div>
      );
  }

  export default HeadlinesButton;