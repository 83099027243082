import React from 'react';
import { ICourseCurriculumBlockSectionPreviewProps } from './ICourseCurriculumBlockSectionPreviewProps';
import ChevronUpIcon from '../icons/ChevronUpIcon';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import AudioIcon from './icons/AudioIcon';
import VideoIcon from './icons/VideoIcon';
import PDFIcon from './icons/PDFIcon';
import EmbedIcon from './icons/EmbedIcon';
import TextIcon from './icons/TextIcon';
import { isValidHexColor } from 'components/PageBuilder/PageBuilderSideBar/BlockSettings/domain';
import styled from 'styled-components';

const CourseCurriculumBlockSectionContainer: any = styled.div<{design: any}>`
  width: 100%;
  border-radius: 4px;
  margin-top: 15px;
  overflow: hidden;
  border: ${(props: any) =>
    props.design.border_color &&
    isValidHexColor(props.design.border_color) ?
    `${
      props.design.border_width ?
      props.design.border_width :
      1
    }px solid ${props.design.border_color}` :
    `${
      props.design.border_width ?
      props.design.border_width :
      1
    }px solid #D5D8DF`
  };

  &.no-margin-top {
      margin-top: 0;
  }
`;

const CourseCurriculumBlockSectionWrapper: any = styled.div<{design: any}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  background-color: ${(props: any) => 
    props.design.section_container_background_color &&
    isValidHexColor(props.design.section_container_background_color) ?
    props.design.section_container_background_color :
    `#EDEEF0`
  };
`;

const CourseCurriculumBlockSectionTitle: any = styled.p<{design: any, pageStyles: any}>`
  margin: 0;
  padding: 0;
  font-weight: 700;
  max-width: 80%;

  font-size: ${(props: any) =>
    props.design.section_name_p_font_size &&
    props.design.section_name_p_font_size <= 50 ?
    `${props.design.section_name_p_font_size}px` :
    `50px`
  };
  line-height: ${(props: any) =>
    props.design.section_name_p_line_height &&
    props.design.section_name_p_line_height <= 50 ?
      `${props.design.section_name_p_line_height}px` :
      `50px`
  };
  font-family: ${(props: any) =>
    props.design.section_name_p_font_family ?
      `${props.design.section_name_p_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) => 
    props.design.section_name_p_color && 
    isValidHexColor(props.design.section_name_p_color) ?
    props.design.section_name_p_color : 
    props.design.section_name_color &&
    isValidHexColor(props.design.section_name_color) ?
    props.design.section_name_color :
    `#333333`
  };
`;

const CourseCurriculumBlockSectionIconContainer: any = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CourseCurriculumBlockLessonsContainer: any = styled.div`
  width: 100%;

  &.display-none {
      display: none;
  }
`;

const CourseCurriculumBlockLessonContainer: any = styled.div<{design: any, index: number}>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: ${(props: any) =>
    props.design.lesson_container_background_color &&
    isValidHexColor(props.design.lesson_container_background_color) ?
    props.design.lesson_container_background_color :
    `#FFFFFF`
  };
  border-top: ${(props: any) =>
    // !props.index ? 
    // 'none' :
    props.design.border_color &&
    isValidHexColor(props.design.border_color) ?
    `${
      props.design.border_width ?
      props.design.border_width :
      1
    }px solid ${props.design.border_color}` :
    `${
      props.design.border_width ?
      props.design.border_width :
      1
    }px solid #D5D8DF`
  };

  // &.no-border-top {
  //     border-top: none;
  // }

  &.border-bottom-radius {
      border-radius: 0 0 4px 4px;
  }
`;

const CourseCurriculumBlockLessonIconContainer: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CourseCurriculumBlockLessonTitle: any = styled.p<{design: any, pageStyles: any}>`
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-weight: 400;

  font-size: ${(props: any) =>
    props.design.lesson_name_p_font_size &&
    props.design.lesson_name_p_font_size <= 50 ?
    `${props.design.lesson_name_p_font_size}px` :
    `20px`
  };
  line-height: ${(props: any) =>
    props.design.lesson_name_p_line_height &&
    props.design.lesson_name_p_line_height <= 50 ?
      `${props.design.lesson_name_p_line_height}px` :
      `25px`
  };
  font-family: ${(props: any) =>
    props.design.lesson_name_p_font_family ?
      `${props.design.lesson_name_p_font_family} !important` :
      `-apple-system, Arial !important`
  };
  color: ${(props: any) =>
    props.design.lesson_name_p_color && 
    isValidHexColor(props.design.lesson_name_p_color) ?
    props.design.lesson_name_p_color : 
    props.design.lesson_name_color &&
    isValidHexColor(props.design.lesson_name_color) ?
    props.design.lesson_name_color :
    `#D5D8DF`
  };

  span {
      margin-left: 10px;
  }
`;

const CourseCurriculumBlockSectionPreview: React.FC<ICourseCurriculumBlockSectionPreviewProps> = (props) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(() => props.blockData.expanded);

    React.useEffect(() => {
      setIsOpen(props.blockData.expanded);
    }, [props.blockData.expanded]);

    const determineIcon = (content: any) => {
        switch(content.type) {
          case "audio":
            return (
              <AudioIcon 
              stroke={props.blockData.design.icon_color}
              />
            );
           case "video":
           case "web-video":
             return (
               <VideoIcon
               stroke={props.blockData.design.icon_color}
               />
             );
            case "pdf":
              return (
                <PDFIcon 
                fill={props.blockData.design.icon_color}
                />
              );
            case "embed":
              return (
                <EmbedIcon 
                stroke={props.blockData.design.icon_color}
                />
              )
            default:
              return (
                <TextIcon 
                stroke={props.blockData.design.icon_color}
                />
              )
        }
      }

  return (
    <CourseCurriculumBlockSectionContainer
    className={`${!props.index ? 'no-margin-top' : ''}`}
    design={props.blockData.design}
    >
        <CourseCurriculumBlockSectionWrapper
        design={props.blockData.design}
        onClick={() => setIsOpen(!isOpen)}
        >
            <CourseCurriculumBlockSectionTitle
            design={props.blockData.design}
            pageStyles={props.pageStyles}
            >
                {props.section.title}
            </CourseCurriculumBlockSectionTitle>

            <CourseCurriculumBlockSectionIconContainer
            >
                {isOpen ? (
                    <ChevronUpIcon
                    fill={props.blockData.design.section_name_color}
                    />
                ) : (
                    <ChevronDownIcon
                    fill={props.blockData.design.section_name_color}
                    />
                )}
            </CourseCurriculumBlockSectionIconContainer>
        </CourseCurriculumBlockSectionWrapper>
        <CourseCurriculumBlockLessonsContainer
        className={`${!isOpen ? 'display-none' : ''}`}
        >
            {props.section.contents.map((content: any, index: number) => (
                <CourseCurriculumBlockLessonContainer
                key={index}
                className={`gp-pb-course-curriculum-block-section-preview-lessons-lesson-container ${!index ? 'no-border-top' : ''} ${
                  index === props.section.contents.length - 1 ?
                  'border-bottom-radius' :
                  ''
                }`}
                design={props.blockData.design}
                index={index}
                >
                    <CourseCurriculumBlockLessonIconContainer
                    >
                        {determineIcon(content)}
                    </CourseCurriculumBlockLessonIconContainer>
                    <CourseCurriculumBlockLessonTitle
                    design={props.blockData.design}
                    pageStyles={props.pageStyles}
                    >
                        {content.title}
                    </CourseCurriculumBlockLessonTitle>
                </CourseCurriculumBlockLessonContainer>
            ))}
        </CourseCurriculumBlockLessonsContainer>
    </CourseCurriculumBlockSectionContainer>
  )
}

export default CourseCurriculumBlockSectionPreview;