import React from 'react';
import styled from 'styled-components';

interface StyledComponentProps { [key: string]: any; }
export const SC: StyledComponentProps = {};

SC.PhotoCollage = styled.div`
`;
SC.PhotoRow = styled.div`
    display: flex;
    height: 300px;
    box-sizing: border-box;
    & + & {
        margin-top: 2px;
        margin-bottom: 8px;
    }
`;
SC.PhotoGrid = styled.div`
    display: flex;
    position: relative;
    flex: 1;
    cursor: pointer;
    & + & {
        margin-left: 2px;
    }
`;
SC.PhotoThumb = styled.div`
    flex: 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;
SC.PhotoMask = styled.div`
    display: block;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    cursor: pointer;
`;
SC.NumOfRemaining = styled.div`
    position: absolute;
    color: #fff;
    font-size: 35px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &:before {
        content: '+';
    }
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99; 
`;

interface RowPhotosProps {
    height: string;
    photos: any;
    openLightbox: any;
    layoutNum: number;
    remainingNum: number;
    showNumOfRemainingPhotos: boolean;
}
const RowPhotos: React.FC<RowPhotosProps> = (props) => {
    const { height, photos, layoutNum, remainingNum, showNumOfRemainingPhotos, openLightbox } = props;

    return (
        <SC.PhotoRow rowHeight={height}>
            {
                photos.map((data: { id: number; source: any; originalFileName: string }, i: React.Key | undefined) => {
                    return (
                        <SC.PhotoGrid key={i} data-id={data.id} onClick={(e: { currentTarget: { dataset: { id: any; }; }; }) => openLightbox(e.currentTarget.dataset.id)}>
                            {
                                showNumOfRemainingPhotos && remainingNum > 0 && data.id === (layoutNum - 1) ?
                                    (
                                        <React.Fragment>
                                            <SC.PhotoMask></SC.PhotoMask>
                                            <SC.NumOfRemaining>{remainingNum}</SC.NumOfRemaining>
                                        </React.Fragment>
                                    ) : null
                            }
                            {/* <div
                                style={{
                                    flex: 1,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: `url(${data.source})`,
                                    padding: '40px',
                                    width: '100%',
                                }}
                            /> */}
                            <img
                                src={data.source}
                                alt={data.originalFileName}
                                style={{
                                    flex: 1,
                                    objectFit: 'cover',
                                    width: '100%',
                                }}
                            />
                        </SC.PhotoGrid>
                    )
                })
            }
        </SC.PhotoRow >
    );
}

interface PhotoCollageComponentProps {
    width: string;
    height: Array<string>;
    layout: Array<number>;
    layoutPhotoMaps: any;
    layoutNum: number;
    remainingNum: number;
    showNumOfRemainingPhotos: boolean;
    openLightbox: any;
}
export const PhotoCollageComponent: React.FC<PhotoCollageComponentProps> = React.memo((props) => {
    const { width, height, layout, layoutPhotoMaps, layoutNum, remainingNum, showNumOfRemainingPhotos, openLightbox } = props;
    return (
        <SC.PhotoCollage collageWidth={width}>
            {
                layout.map((data, i) => {
                    return (
                        <RowPhotos
                            key={i}
                            height={height[i]}
                            photos={layoutPhotoMaps[i]}
                            openLightbox={openLightbox}
                            layoutNum={layoutNum}
                            remainingNum={remainingNum}
                            showNumOfRemainingPhotos={showNumOfRemainingPhotos}
                        />
                    )
                })
            }
        </SC.PhotoCollage>
    );
});
