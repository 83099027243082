import { isValidHexColor } from "components/PageBuilder/PageBuilderSideBar/BlockSettings/domain";
import { BackgroundImageRepeat, BackgroundImageSizing } from "containers/PageBuilder/SinglePageContainer/domain";
import styled from "styled-components";
import miscUtils from "utils/miscUtils";

const getRgbValues = (hex: string) => {
    if(miscUtils.hexToRgb(hex)) {
        return `${miscUtils.hexToRgb(hex)!.r}, ${miscUtils.hexToRgb(hex)!.g}, ${miscUtils.hexToRgb(hex)!.b}`;
    }
    return `0, 0, 0`
}

const PageContainer: any = styled.div<{pageStyles: any}>`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > * {
        position: relative;
        z-index: 1;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: ${(props: any) => 
            props.pageStyles.background &&
            props.pageStyles.background.background_opacity &&
            props.pageStyles.background.background_opacity <= 1 ?
            props.pageStyles.background.background_opacity :
            1 
        };
        background-color: ${(props: any) => 
            props.pageStyles.background &&
            props.pageStyles.background.background_color &&
            isValidHexColor(props.pageStyles.background.background_color ) ?
            props.pageStyles.background.background_color  :
            '#EFF0F2'
        };
        background-image: ${(props: any) =>
            props.pageStyles.background &&
            props.pageStyles.background.background_image_url ?
            props.pageStyles.background.background_image_color_overlay &&
            isValidHexColor(props.pageStyles.background.background_image_color_overlay) ?
            `linear-gradient(rgba(${getRgbValues(props.pageStyles.background.background_image_color_overlay)}, .3), rgba(${getRgbValues(props.pageStyles.background.background_image_color_overlay)}, .3)), url(${props.pageStyles.background.background_image_url})` :
            `url(${props.pageStyles.background.background_image_url})` :
            'none'
        };
        background-size: ${(props: any) =>
            props.pageStyles.background &&
            props.pageStyles.background.background_image_url ?
            props.pageStyles.background.background_image_sizing === BackgroundImageSizing.Fit ?
            `cover` :
            props.pageStyles.background.background_image_sizing === BackgroundImageSizing.Fill ?
            `contain` :
            `auto` :
            `auto auto`
        };
        background-repeat: ${(props: any) => 
            props.pageStyles.background &&
            props.pageStyles.background.background_image_url ?
            props.pageStyles.background.background_image_repeat === BackgroundImageRepeat.Both ?
            `repeat` :
            props.pageStyles.background.background_image_repeat === BackgroundImageRepeat.Horizontally ?
            `repeat-x` :
            props.pageStyles.background.background_image_repeat === BackgroundImageRepeat.Vertically ?
            `repeat-y` :
            `no-repeat` :
            `no-repeat`
        };
        background-position: ${(props: any) => 
            props.pageStyles.background &&
            props.pageStyles.background.background_image_position
        };
    }
`;

export default PageContainer;