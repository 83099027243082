import React, { useEffect, useState } from "react";

import _ from "lodash";
import { toast, ToastType } from "react-toastify";
import { EditorState, getDefaultKeyBinding, RichUtils } from "draft-js";
import createMentionPlugin from '@draft-js-plugins/mention';
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import "draft-js/dist/Draft.css";

import './postEditor.scss';
import MentionComponent from "./MentionComponent";
import EntryComponent from "./EntryComponent";
import { GroupService } from "../../api/services/groups";
import { DraftHandle, ENTITY_MUTABILITY, SEARCH_MEMBERS_LIMIT } from "components/post/PostableDraft/domain";
import miscUtils from "utils/miscUtils";
import GIF from "components/GIF";
import HorizontalSlideScroll from "components/HorizontalSlideScroll";
import { ReactComponent as FileIcon } from "assets/img/file.svg";
import { ReactComponent as TrashIcon } from 'components/post/PostAttachment/trash.svg';
import { ReactComponent as AddIcon } from 'assets/img/add.svg';
import { getHtmlState } from "components/commentInput/CommentInput";

interface PostEditorProps {
  onEditorChange?: any;
  editorState?: EditorState;
  getEditorState?: any;
  resetEditorReference?: any;
  getId?: any;
  getName?: any;
  group?: any;
  className?: any
  onBlur?: any
  onFocus?: any
  placeholder?: any
  onChange?: any
  ref?: any
  setShowGIFComp?: any
  showGIFComp?: boolean
  postId?: any
  commentId?: any
  editMode?: boolean
  commentData?: any
  commentFile?: any
  setCommentFile?: any
  clickFileAttachmentInput?: any
  setCommentDescriptionLength?: any
}

//mentions plugin
// const mentionPlugin = createMentionPlugin({
//   entityMutability: ENTITY_MUTABILITY.IMMUTABLE,
//   //@ts-ignore
//   mentionComponent: MentionComponent,
//   theme: {
//     mention: 'postable-draft-mention-text',
//     mentionSuggestions: 'postable-draft-mention-suggestions-container',
//   },
//   mentionPrefix: '@',
//   supportWhitespace: true,
// });

// const { MentionSuggestions } = mentionPlugin;

//focus and alignment
const focusPlugin = createFocusPlugin();
const alignmentPlugin = createAlignmentPlugin();

const imageDecorators: any = composeDecorators(
  alignmentPlugin.decorator,
  focusPlugin.decorator,
);

//image plugin
//@ts-ignore
const imagePlugin = createImagePlugin({ imageDecorators });


const PostEditor: React.FC<PostEditorProps> = (props) => {
  // const plugins = [mentionPlugin, focusPlugin, alignmentPlugin, imagePlugin];

  const { MentionSuggestions, plugins } = React.useMemo(() => {
    //mentions plugin
    const mentionPlugin = createMentionPlugin({
      entityMutability: ENTITY_MUTABILITY.IMMUTABLE,
      //@ts-ignore
      mentionComponent: MentionComponent,
      theme: {
        mention: 'postable-draft-mention-text',
        mentionSuggestions: 'postable-draft-mention-suggestions-container',
      },
      mentionPrefix: '@',
      supportWhitespace: true,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, focusPlugin, alignmentPlugin, imagePlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const MaxPhotoFileSize: number = 10000000; //unit -> bytes
  const MaxFileAttachmentsLimit: number = 5;
  // const MaxAttachmentFileSize: number = 25000000; //unit -> bytes

  const inlineImageRef = React.useRef<any>(null);

  const [gifLoading, setGIFLoading] = useState(false)
  const [inlineImgLoading, setInlineImgLoading] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [editorState, setEditorState] = useState<EditorState>(() => {
    if (props.editorState) {
      return props.editorState;
    }
    return EditorState.createEmpty();
  });

  useEffect(() => {
    if (props.resetEditorReference) {
      props.resetEditorReference.current = () => {
        setEditorState(EditorState.createEmpty())
      }
    }
  }, [])

  const onOpenChange = React.useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = React.useCallback(({ value }: { value: string }) => {
    searchForMembers(value);
  }, []);

  const searchForMembers = (value: string) => {
    GroupService.getInstance()
      .getGroupMembersInMention(props.group.id, value, SEARCH_MEMBERS_LIMIT)
      .then((response: any) => {
        setSuggestions(response.data.map((groupMember: any) => {
          return {
            name: groupMember.user.full_name,
            avatar: groupMember.user.profile_image,
            id: `${groupMember.user.id}`,
          }
        }))
      })
  }

  const keyBindingFn = (e: React.KeyboardEvent<{}>) => {
    return getDefaultKeyBinding(e);
  };

  const onAddMention = (mention: { id: any; name: any; }) => {
    props.getId(mention.id);
    props.getName(mention.name);
  };

  const handleKeyCommand = (command: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return DraftHandle.HANDLED;
    }
    return DraftHandle.NOTHANDLED;
  }

  const validateSize = (file: any, maximumSize: number): boolean => {
    if (file && file.size > maximumSize) {
      return false;
    }
    return true;
  }

  const handleImageFilePicker = async (event: any) => {
    if (event.target.files) {
      const file: File = event.target.files[0];

      if (!validateSize(file, MaxPhotoFileSize)) {
        toast("Maximum image upload size is 10MB", {
          type: ToastType.ERROR,
          autoClose: 3000,
        });
        return false;
      }
      if (!miscUtils.validateFileName(file)) {
        return false;
      }

      if (!miscUtils.validateSpecialChars(file)) {
        return false;
      }

      const inlineImageFormData = new FormData();

      if (file.name.endsWith(".heic") || file.name.endsWith(".HEIC")) {
        const convertedBlob: any = await miscUtils.convertHeicToPng(file);
        if (convertedBlob) {
          const pngFileName = file.name.replace(/\.heic$/i, '.png');
          const convertedFile = new File([convertedBlob], pngFileName, { type: 'image/png' });
          inlineImageFormData.append('image', convertedFile);
        }
      } else {
        inlineImageFormData.append("image", file);
      }
      inlineImageFormData.append("category", "posts");

      setInlineImgLoading(true);
      GroupService.getInstance()
        .uploadTextEditorImage(props.group.id, inlineImageFormData)
        .then((response: any) => {
          inlineImageRef.current = response.file.url;
          onChange(editorState, true);
          props.onFocus();
        })
        .catch(error => console.error(error))
        .finally(() => {
          setInlineImgLoading(false);
        });
    }
  }

  const clickInlineGif = async (gif: any) => {
    await fetch(gif && gif.images.original.url)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], gif.title, { type: "image/gif" });

        if (file === null || !file || gif === null) return

        const inlineImageFormData = new FormData();
        inlineImageFormData.append("image", file);
        inlineImageFormData.append("category", "posts");

        setGIFLoading(true);
        GroupService.getInstance()
          .uploadTextEditorImage(props.group.id, inlineImageFormData)
          .then((response: any) => {
            inlineImageRef.current = response.file.url;
            onChange(editorState, true);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setGIFLoading(false);
          });

      })
      .catch(error => console.error(error));
  }

  const handleFilePicker = (event: any) => {
    const newFiles = event.target.files

    if (newFiles === null) return

    const newFileArray = Array.from(newFiles);
    if (newFileArray.length > MaxFileAttachmentsLimit) {
      toast("The maximum file upload limit number is 5", {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return false;
    }

    const validFiles = newFileArray.filter((file) => {
      if (!validateSize(file, MaxPhotoFileSize)) {
        toast("Maximum file upload size is 10MB", {
          type: ToastType.ERROR,
          autoClose: 3000,
        });
        return false;
      }
      if (!miscUtils.validateFileName(file)) {
        return false;
      }
      return true;
    });

    props.setCommentFile((prevCommentFile: any) =>
      Array.isArray(prevCommentFile) ?
        [...prevCommentFile, ...validFiles] : [...validFiles]
    );

  }

  const onChange = React.useCallback((newEditorState: EditorState, addImage?: boolean) => {
    if(props.onEditorChange) {
      props.onEditorChange(newEditorState);
    }
    
    let finalState = newEditorState;
    props.getEditorState(newEditorState);

    if (inlineImageRef.current && addImage) {
      finalState = imagePlugin.addImage(newEditorState, inlineImageRef.current, {});
    }
    setEditorState(finalState);

    props.setCommentDescriptionLength(getHtmlState(finalState))

  }, [inlineImageRef, imagePlugin, setEditorState]);

  return (
    <div className={`editor ${props.className}`}>

      <Editor
        editorState={editorState}
        onChange={onChange}
        plugins={plugins}
        keyBindingFn={keyBindingFn}
        handleKeyCommand={handleKeyCommand}
        placeholder={(gifLoading || inlineImgLoading) ? "" : props.placeholder}
        ref={props.ref}
        onFocus={() => {
          if (props.onFocus) {
            props.onFocus();
          }
        }}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur();
          }
        }}
        spellCheck={true}
      />

      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={onAddMention}
        entryComponent={(
          EntryComponent as unknown as React.ComponentType<EntryComponentProps>
        )}
      />

      <HorizontalSlideScroll slidingElementId="comment-file-attachment" commentFile={props.commentFile}>
        <CommentFileAttachment
          commentFile={props.commentFile}
          scrollRef={null}
          editMode={props.editMode}
          setCommentFile={props.setCommentFile}
          clickFileAttachmentInput={props.clickFileAttachmentInput}
          commentId={props.commentId}
        />
      </HorizontalSlideScroll>

      <input
        onChange={handleImageFilePicker}
        type="file"
        value=""
        accept="image/png, image/jpeg, .heic"
        hidden
        id={`comment-draft-editor-inline-image-input-${props.postId}-${props.commentId}`}
      />

      <input
        onChange={handleFilePicker}
        type="file"
        value=""
        accept=".doc,.docx,.html,.htm,.odt,.pdf,.xls,.xlsx,.ods,.ppt,.pptx,.txt"
        hidden
        multiple
        id={`comment-draft-editor-file-attachment-input-${props.postId}-${props.commentId}`}
      />

      {props.showGIFComp &&
        <div className="comment-gif-wrapper">
          <GIF
            clickInlineGif={clickInlineGif}
            setShowGIFComp={props.setShowGIFComp}
          />
        </div>
      }

      {gifLoading &&
        <span
          style={{
            color: "#9FA1A1",
            fontSize: "16px",
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
            alignItems: 'flex-start'
          }}
        >
          Loading GIF content...
        </span>
      }

      {inlineImgLoading &&
        <span
          style={{
            color: "#9FA1A1",
            fontSize: "16px",
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
            alignItems: 'flex-start'
          }}
        >
          Loading image content...
        </span>
      }

    </div>
  );
}

export default PostEditor


const CommentFileAttachment = (
  props: {
    commentFile: any
    scrollRef: any
    editMode: boolean | undefined
    setCommentFile: any
    clickFileAttachmentInput: any
    commentId: number
  }
) => {

  const {
    commentFile,
    scrollRef,
    editMode,
    setCommentFile,
    clickFileAttachmentInput,
    commentId
  } = props

  const removeFILEAttachment = (file_id?: number, file_name?: string) => {
    if (file_id) {
      const newFiles = commentFile.filter((
        file: { file_id: number }) => file.file_id !== file_id
      );
      GroupService.getInstance()
        .deleteCommentFileAttachment(commentId, file_id)
        .finally(() => {
          toast("File Attachment successfully deleted", {
            type: ToastType.SUCCESS,
            autoClose: 3000,
          });
        })
      setCommentFile(newFiles);
    }


    if (file_name) {
      const newFiles = commentFile.filter((
        file: { name: string }) => file.name !== file_name
      );
      setCommentFile(newFiles);
    }
  }

  return (
    <div className="comment-file-attachment"
      id="comment-file-attachment"
      ref={scrollRef}
    >
      {commentFile !== null && commentFile.length > 0 &&
        <>
          {commentFile.map((file: any, index: any) =>
        (
            <div className="comment-file-attachment-wrapper" key={index}>
              <div className="comment-file-attachment-details">
                <span className="comment-file-attachment-details-icon">
                  <FileIcon />
                </span>

                <div className="comment-file-attachment-details-body">
                  <div className="comment-file-attachment-details-body-title">
                    <span className="title-text">{file.name}</span>
                  </div>
                  <span className="comment-file-attachment-details-body-size">
                    {(file.size / 1000000).toFixed(2)} MB
                  </span>
                </div>

                <div className="comment-file-attachment-delete-icon">
                  {
                    editMode && !(file instanceof Blob) ?
                    <span
                      onClick={() => {
                        removeFILEAttachment(file.file_id, undefined)
                      }}
                    >
                      <TrashIcon />
                    </span>
                    :
                    <span
                      onClick={() => {
                        removeFILEAttachment(undefined, file.name)
                      }}
                    >
                      <TrashIcon />
                    </span>
                  }
                </div>
              </div>
            </div>
          ))}

        <div className="add-comment-file-attachment" >
          <span
            className='add-file-icon'
            onClick={clickFileAttachmentInput}
          >
            <AddIcon />
          </span>
        </div>
      </>

      }
    </div>
  )
}
