import React from "react";
import { IConfirmModalProps } from "./IConfirmModalProps";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "elements/modal/Modal";
import "./confirmation-modal.scss";

const ConfirmModal: React.FC<IConfirmModalProps> = props => {
  const { actionCallback, closeModal, isModalOpen, modalTitle, modalSubTitle, actionBtn, cancelBtn } = props;

  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal} size={"654px"}>
      <div className="confirmation-modal">
        <div className="confirmation-modal-content">
          <div className="confirmation-msg">
            <p className="title">{modalTitle}</p>
            <p className="subtitle">{modalSubTitle}</p>
            <div className="confirmation-btns">
              <button
                className="del-btn"
                onClick={() => {
                  actionCallback();
                }}
              >
                {actionBtn}
              </button>
              <button
                className="cancel-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                {cancelBtn ? cancelBtn : 'Cancel'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(
  connect(null, null)(ConfirmModal as any) as any
) as any;
