import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState,
  RichUtils
} from 'draft-js';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import {
  DraftHandle,
  ENTITY_MUTABILITY, SEARCH_MEMBERS_LIMIT
} from './domain';
import { GroupService } from 'api/services/groups';
import { IPostableDraftProps } from './IPostableDraftProps';
import _ from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import './PostableDraft.scss';
import createToolbarPlugin, {
  Separator,
} from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/linkify/lib/plugin.css';
import HeadlinesButton from './HeadlinesButton';
import createLinkPlugin from '@draft-js-plugins/anchor';
import '@draft-js-plugins/anchor/lib/plugin.css';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import MentionComponent from 'components/PostEditor/MentionComponent';
import EntryComponent from 'components/PostEditor/EntryComponent';
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import 'draft-js/dist/Draft.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import ImageCropper from 'elements/imageCropper';
import miscUtils from 'utils/miscUtils';
import { toast, ToastType } from 'react-toastify';
import ImageButton from './ImageButton';
import clsx from "clsx";
import GIFButton from './GIFButton';
import GIF from 'components/GIF';

//mentions plugin
const mentionPlugin = createMentionPlugin({
  entityMutability: ENTITY_MUTABILITY.IMMUTABLE,
  //@ts-ignore
  mentionComponent: MentionComponent,
  theme: {
    mention: 'postable-draft-mention-text',
    mentionSuggestions: 'postable-draft-mention-suggestions-container',
  },
  mentionPrefix: '@',
  supportWhitespace: true,
});
// eslint-disable-next-line no-shadow
const { MentionSuggestions } = mentionPlugin;
// eslint-disable-next-line no-shadow

//static toolbar plugin
const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

//linkify plugin
const linkifyPlugin = createLinkifyPlugin({
  theme: {
    link: 'postable-draft-linkify-text',
  }
});

//anchor plugin
const linkPlugin = createLinkPlugin({
  theme: {
    input: `postable-draft-link-input`,
    inputInvalid: 'postable-draft-link-input--invalid',
    link: 'postable-draft-link-text'
  }
});
const { LinkButton } = linkPlugin

//focus and alignment
const focusPlugin = createFocusPlugin();
const alignmentPlugin = createAlignmentPlugin();

const imageDecorators: any = composeDecorators(
  alignmentPlugin.decorator,
  focusPlugin.decorator,
);

//image plugin
//@ts-ignore
const imagePlugin = createImagePlugin({ imageDecorators });

const plugins = [mentionPlugin, staticToolbarPlugin, linkifyPlugin, linkPlugin, focusPlugin, alignmentPlugin, imagePlugin];

const PostableDraft: React.FC<IPostableDraftProps> = (props): React.ReactElement => {

  const { placeholder = "Write a post", acceptTitle = true, acceptCoverImage = true } = props;

  const ref = React.useRef<Editor>(null);
  const inlineImageRef = React.useRef<any>(null);

  const [gifLoading, setGIFLoading] = useState(false)

  const [editorState, setEditorState] = React.useState<EditorState>(() => {
    if (props.editMode && props.draftJSON) {
      const contentState: ContentState = convertFromRaw(JSON.parse(props.draftJSON));
      return EditorState.createWithContent(contentState);
    }

    if (props.editMode && !props.draftJSON) {
      const blocksFromHtml = convertFromHTML(props.postDescription ? props.postDescription : "");
      const contentState: ContentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  }
  );
  const [open, setOpen] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const [disableEditor, setDisableEditor] = React.useState<boolean>(true);
  const [isCoverImageCropperOpen, setIsCoverImageCropperOpen] = React.useState<boolean>(false);
  const [showCoverImageActions, setShowCoverImageActions] = React.useState<boolean>(false);
  const [showGIFComp, setShowGIFComp] = useState<boolean>(false);

  React.useEffect(() => {
    props.resetDraftRef.current = () => setEditorState(EditorState.createEmpty());
    focusTitleInput();
    if(ref.current) {
      // ref.current.focus();
      ref.current.blur();
      setTimeout(() => {
        if(ref.current) {
          // window.scrollTo({
          //   top: 0,
          //   //@ts-ignore
          //   behavior: 'instant',
          // });
          ref.current.blur();
        }
      }, 100);
    }
  }, []);

  React.useEffect(() => {
    if (props.postCoverImage) {
      const img = new Image();
      img.src = props.postCoverImage.url ? props.postCoverImage.url : URL.createObjectURL(props.postCoverImage);

      img.onload = () => {
        if (img.height < 250 || img.width < 695) {
          props.setPostCoverImage && props.setPostCoverImage(null);
          setIsCoverImageCropperOpen(false);
          toast("Minimum required size for post cover image is 695x254.", {
            type: ToastType.ERROR,
            autoClose: 3000
          });
        }
      };
    }
  }, [props.postCoverImage]);

  React.useEffect(() => {
    if (!props.acceptTitle) {
      setDisableEditor(false);
    }
  }, [props.acceptTitle])

  const onChange = React.useCallback((newState: EditorState, addImage?: boolean) => {
    if(props.onEditorChange) {
      props.onEditorChange(newState);
    }
    let finalState = newState;

    if (inlineImageRef.current && addImage) {
      finalState = imagePlugin.addImage(newState, inlineImageRef.current, {});
    }
    setEditorState(finalState);
    const contentState: ContentState = finalState.getCurrentContent();
    const rawState = convertToRaw(contentState);
    props.onStateChange(getHtmlState(contentState), JSON.stringify(rawState));
  }, [inlineImageRef, imagePlugin, props.onStateChange, setEditorState]);

  const getHtmlState = (contentState: ContentState) => {
    // eslint-disable-next-line
    const options = {
      // eslint-disable-next-line consistent-return
      entityStyleFn: (entity: any) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "mention") {
          const data = entity.getData();
          return {
            element: "span",
            attributes: {
              "data-mention-id": _.get(data, "mention.id"),
              class: "mentioned-member"
            },
            style: {
              // Put styles here...
            }
          };
        }
      }
    };
    return stateToHTML(contentState, options);
  }

  const onOpenChange = React.useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = React.useCallback(({ value }: { value: string }) => {
    searchForMembers(value);
  }, []);

  const searchForMembers = (value: string) => {
    GroupService.getInstance()
      .getGroupMembersInMention(props.group.id, value, SEARCH_MEMBERS_LIMIT)
      .then((response: any) => {
        setSuggestions(response.data.map((groupMember: any) => {
          return {
            name: groupMember.user.full_name,
            avatar: groupMember.user.profile_image,
            id: `${groupMember.user.id}`,
          }
        }));
      })
  }

  const handleKeyCommand = (command: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return DraftHandle.HANDLED;
    }

    return DraftHandle.NOTHANDLED;
  }

  const onAddMention = (mention: any) => {
    props.onAddMention(mention.id);
  }

  const focusTitleInput = () => {
    const titleInput: HTMLElement = document.getElementById(
      "postable-draft-editor-title-input") as HTMLElement;

    if (titleInput) {
      titleInput.focus();
    }
  }

  const cropCoverImage = (crop: any) => {
    props.setPostCoverImage(crop.file);
    setIsCoverImageCropperOpen(false);
  }

  const cancelCoverImageCrop = () => {
    props.setPostCoverImage(null);
    setIsCoverImageCropperOpen(false);
  }

  const MaxPhotoFileSize: number = 10000000; //unit -> bytes

  const validateSize = (file: any, maximumSize: number): boolean => {
    if (file && file.size > maximumSize) {
      return false;
    }
    return true;
  }

  const pickCoverImage = async (event: any) => {
    if (event.target.files) {
      if (!validateSize(event.target.files[0], MaxPhotoFileSize)) {
        toast("Maximum image upload size is 10MB.", {
          type: ToastType.ERROR,
          autoClose: 3000,
        });
        return false;
      }

      if (!miscUtils.validateFileName(event.target.files[0])) {
        return;
      }
      if (!miscUtils.validateSpecialChars(event.target.files[0])) {
        return false;
      }

      const file = event.target.files[0]
      if (file.name.endsWith(".heic") || file.name.endsWith(".HEIC")) {
        const convertedBlob: any = await miscUtils.convertHeicToPng(file);
        if (convertedBlob) {
          const pngFileName = file.name.replace(/\.heic$/i, '.png');
          const convertedFile = new File([convertedBlob], pngFileName, { type: 'image/png' });
          props.setPostCoverImage(convertedFile);

          const img = new Image(undefined, undefined);
          img.src = URL.createObjectURL(convertedFile);

          img.onload = () => {
            if (img.height >= 250 && img.width >= 695) {
              setIsCoverImageCropperOpen(true);
            }
          };
        }
      } else {
        props.setPostCoverImage(event.target.files[0]);
        const img = new Image(undefined, undefined);
        img.src = URL.createObjectURL(event.target.files[0]);

        img.onload = () => {
          if (img.height >= 250 && img.width >= 695) {
            setIsCoverImageCropperOpen(true);
          }
        };
      }


    }
  }


  const pickInlineImage = async (event: any) => {
    if (event.target.files) {
      const file: File = event.target.files[0];

      if (!miscUtils.validateFileName(file)) {
        return false;
      }

      if (!miscUtils.validateSpecialChars(file)) {
        return false;
      }

      if (!validateSize(file, MaxPhotoFileSize)) {
        toast("Maximum image upload size is 10MB.", {
          type: ToastType.ERROR,
          autoClose: 3000,
        });
        return false;
      }

      const inlineImageFormData = new FormData();

      if (file.name.endsWith(".heic") || file.name.endsWith(".HEIC")) {
        const convertedBlob: any = await miscUtils.convertHeicToPng(file);
        if (convertedBlob) {
          const pngFileName = file.name.replace(/\.heic$/i, '.png');
          const convertedFile = new File([convertedBlob], pngFileName, { type: 'image/png' });
          inlineImageFormData.append('image', convertedFile);
        }
      } else {
        inlineImageFormData.append("image", file);
      }

      inlineImageFormData.append("category", "posts");
      GroupService.getInstance()
        .uploadTextEditorImage(props.group.id, inlineImageFormData)
        .then((response: any) => {
          inlineImageRef.current = response.file.url;
          onChange(editorState, true);
        })
    }
  }

  const clickCoverImageInput = () => {
    const coverImageInputElement: HTMLElement = document.getElementById(`postable-draft-editor-cover-input-${placeholder}`) as HTMLElement;

    if (coverImageInputElement) {
      coverImageInputElement.click();
    }
  }

  const clickInlineImageInput = () => {
    const inlineImageInputElement: HTMLElement = document.getElementById(`postable-draft-editor-inline-image-input-${placeholder}`) as HTMLElement;

    if (inlineImageInputElement) {
      inlineImageInputElement.click();
    }
  }


  const clickInlineGif = async (gif: any) => {
    await fetch(gif && gif.images.original.url)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], gif.title, { type: "image/gif" });

        if (file === null || !file || gif === null) return

        const inlineImageFormData = new FormData();
        inlineImageFormData.append("image", file);
        inlineImageFormData.append("category", "posts");

        setGIFLoading(true);
        GroupService.getInstance()
          .uploadTextEditorImage(props.group.id, inlineImageFormData)
          .then((response: any) => {
            inlineImageRef.current = response.file.url;
            onChange(editorState, true);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setGIFLoading(false);
          });

      })
      .catch(error => console.error(error));
  }

  return (
    <div className={clsx(
      `postable-draft-container ${props.fullScreen ?
        "postable-draft-container--fullscreen" : ""} ${props.readOnly ?
          "postable-draft-container--readonly" : ""}`,
      props.containerClasses
    )}>
      {!props.readOnly && (
        <Toolbar>
          {
            //@ts-ignore
            (externalProps) => (
              <>
                <HeadlinesButton {...externalProps} />
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />

                {/*
                 // @ts-ignore */}
                <Separator {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />

                {/*
                  // @ts-ignore */}
                <Separator {...externalProps} />

                <LinkButton {...externalProps} />
                <ImageButton onClick={clickInlineImageInput} />
                <GIFButton onClick={() => setShowGIFComp(!showGIFComp)} />
              </>
            )
          }
        </Toolbar>
      )}

      <div
        className={clsx(
          `postable-draft-editor-container ${props.fullScreen ?
            "postable-draft-editor-container--fullscreen" : ""}`,
          props.editorContainerClasses
        )}>
        {ReactDOM.createPortal(
          <>
            <div
              className="postable-draft-editor-cover-portal-container"
            >
              <ImageCropper
                aspect={695 / 254}
                maxWidth={695}
                maxHeight={254}
                sourceImage={props.postCoverImage ? props.postCoverImage.url ? props.postCoverImage.url : URL.createObjectURL(props.postCoverImage) : null}
                isOpen={isCoverImageCropperOpen}
                onCropCancel={cancelCoverImageCrop}
                onCropFinished={cropCoverImage}
                imageSrc={props.postCoverImage && props.postCoverImage}
              />
            </div>
          </>,
          //@ts-ignore
          document.getElementById('gp-portal-root')
        )}
        {acceptCoverImage && !props.hideCoverImageInput ? (
          <>
            {props.postCoverImage ? (
              <>
                <div
                  className="postable-draft-editor-cover-image-container"
                  onMouseOver={() => setShowCoverImageActions(true)}
                  onMouseOut={() => setShowCoverImageActions(false)}
                >
                  <img
                    className="postable-draft-editor-cover-image-image"
                    src={props.postCoverImage.url ? props.postCoverImage.url : URL.createObjectURL(props.postCoverImage)}
                  />
                  <div
                    className={`postable-draft-editor-cover-image-action-container ${!showCoverImageActions ? 'hidden' : ''}`}
                  >
                    <button
                      className="postable-draft-editor-cover-image-action-button"
                      onClick={clickCoverImageInput}
                    >
                      Update
                    </button>
                    <button
                      className="postable-draft-editor-cover-image-action-button spaced"
                      onClick={() => props.setPostCoverImage(null)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`postable-draft-editor-cover-container`}
                  onClick={clickCoverImageInput}>
                  <span className="postable-draft-editor-cover-icon">
                    <FontAwesomeIcon icon={faImage} />
                  </span>
                  <p className="postable-draft-editor-cover-label">
                    Add cover image
                  </p>
                </div>
              </>
            )}
          </>
        ) : (<></>)}

        <input
          type="file"
          hidden
          id={`postable-draft-editor-cover-input-${placeholder}`}
          onChange={pickCoverImage}
          accept="image/png, image/jpeg, .heic"
          value=""
        />
        <input
          type="file"
          hidden
          id={`postable-draft-editor-inline-image-input-${placeholder}`}
          onChange={pickInlineImage}
          accept="image/png, image/jpeg, image/gif, .heic"
          value=""
        />

        {acceptTitle && !props.hideTitleInput ? (
          <input
            type="text"
            id="postable-draft-editor-title-input"
            className="postable-draft-editor-title-input"
            value={props.title}
            onChange={(e: any) => props.setTitle(e.target.value)}
            placeholder={props.titlePlaceholder ? props.titlePlaceholder : `Enter title ${props.requireTitle ? '(required)' : '(optional)'}`}
            onFocus={() => setDisableEditor(true)}
            onBlur={() => setDisableEditor(false)}
          />
        ) : (<></>)}

        <div onClick={() => { ref.current!.focus() }}>
          <Editor
            editorState={editorState}
            onChange={onChange}
            plugins={plugins}
            ref={ref}
            handleKeyCommand={handleKeyCommand}
            placeholder={props.editorPlaceholder ? props.editorPlaceholder : placeholder}
            readOnly={props.readOnly || disableEditor}
            spellCheck={true}
          />
          {
            gifLoading &&
            <span
                style={{
                  color: "#9FA1A1",
                  fontSize: "16px",
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  alignItems: 'flex-start'
                }}
              >
              Loading GIF content...
            </span>
          }
        </div>

        <div 
          className={`${window.location.pathname.includes('members')
            ?
            "invite-member-page-post-GIF-container"
            :
            "post-GIF-container"}`}
        >
        {showGIFComp &&
          <GIF
            clickInlineGif={clickInlineGif}
            setShowGIFComp={setShowGIFComp}
            fullScreen={props.fullScreen}
          />
        }
        </div>

      </div>
      {
        !props.disableTaggingonAutomations &&
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={onAddMention}
        entryComponent={(EntryComponent as unknown as React.ComponentType<EntryComponentProps>)}
      />
      }
    </div>
  );
}

export default PostableDraft;
