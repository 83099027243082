import React, { useEffect, useRef, useState, useReducer } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { ITopBarComponentsProps } from "./ITopBarComponentsProps";
import { authorizeActions, globalActions } from "../../store/actions";
import "./TopBarComponent.scss";
import "./topbar-responsive.scss";
import { Map } from "immutable";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faChevronDown, faCog } from "@fortawesome/free-solid-svg-icons";
import { GroupService } from "../../api/services/groups";
import miscUtils from "../../utils/miscUtils";
import { push } from "connected-react-router";
import { SearchIcon } from "../../constants/icons";
import useComponentVisible from "../../utils/visible/visible";
import { Link } from "react-router-dom";
import TopBarMenuComponent from "./topBarMenu";
import TopBarNotificationComponent from "./topBarNotification";
import { ReactComponent as GroupApp } from "./white.svg";
import appConfig from "appConfig";
import AppModal from "elements/modal/appModal";
import LectureMenuIcon from './icons/MenuIcon';
import useWindowSize from "utils/windowSize";
import { useHistory } from 'react-router';
import { SSOMethod } from "containers/IntegrationsContainer/SingleSignOn/domain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TobBarMessages from "./TopBarMessages";
import { useAtom } from "jotai";
import { chatsLoadingAtom, groupMenuMessagesShowAtom, universalSearchResultsAtom } from 'showMessageMenuAtom';
import Echo from "laravel-echo";
import ConfirmModal from "containers/messaging/ConfirmModal";
import { DirectChatsContext } from "containers/main/MainComponent";
import { setlocalStorageValue } from "utils/localStorage";

const TopBarComponent: React.FC<ITopBarComponentsProps> = (props: any) => {
  const {
    toggleHamburgerMenu,
    goTo,
    authed,
    global,
    theme,
    group,
    showPublicGroupSignup,
    isModalActive,
    setChannelSidebarStatus,
    subdomain,
  } = props;

  const windowSize = useWindowSize();

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const whiteCommunityTheme = props.theme && props.theme.custom && props.theme.custom.primaryColor === '#ffffff'

  const [results, setResults] = useState<any>([]);
  // @ts-ignore
  const [countResults, setCountResults] = useState<any>(0);
  // @ts-ignore
  const [search, setSearch] = useState<any>("");
  const [blockedGroups, setBlockedGroups] = useState<any>([]);
  const [path, setPath] = useState<string>("");
  const [isInvite, setIsInvite] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isMobilePlanSelection, setIsMobilePlanSelection] = useState<boolean>(
    false
  );
  const [hideMobileMenu, setHideMobileMenu] = useState<boolean>(false);
  const [passedSubdomain, setPassedSubdomain] = useState<any>(null);
  const [signin, setSignin] = useState<boolean>(false);
  const [homeLogin, setHomeLogin] = useState<boolean>(false);
  const [authButtonHover, setAuthButtonHover] = useState<boolean>(false);
  const [adminSection, setAdminSection] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [communitySearchOpen, setCommunitySearchOpen] = useState<boolean>(false);
  const [groupAppSearchOpen, setGroupAppSearchOpen] = useState<boolean>(false);
  const [postsSearchQuery, setPostsSearchQuery] = useState<string>('');
  const [postsSearchResults, setPostsSearchResults] = useState<any>(null);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);

  library.add(faBell);
  library.add(faCog);
  library.add(faChevronDown);

  // @ts-ignore
  const searchParama = props.location.search;
  // @ts-ignore
  const params = new URLSearchParams(searchParama);
  const history = useHistory()

  useEffect(() => {
    setPassedSubdomain(subdomain);
    const path = props.location.pathname;

    if ((subdomain === undefined || subdomain === null)
      && (group === undefined || group === null)
      && (path === '/' || path === '/login' || path.includes('/search') || isOnboarding)
    ) {
      setHomeLogin(true);
    } else {
      setHomeLogin(false);
    }
  }, [subdomain, group]);

  useEffect(() => {
    if (window.innerWidth < 991) {
      setIsMobile(true);
      setlocalStorageValue("sidebar", false);
      setlocalStorageValue("lecture-sidebar", false);
    } else {
      setlocalStorageValue("lecture-sidebar", true);
    }

    if ((subdomain === undefined || subdomain === null)
      && (group === undefined || group === null)
      && (props.location.pathname === '/' || props.location.pathname === '/login' || window.location.pathname.includes('/search'))
    ) {
      setHomeLogin(true);
    } else {
      setHomeLogin(false);
    }

    if (window.location.pathname === "/invite") {
      setIsInvite(true);
    } else {
      setIsInvite(false);
    }

    if (window.location.pathname === "/signin") {
      setSignin(true);
    } else {
      setSignin(false);
    }

    const invite = params.get("invite");
    if (invite && invite === "true") {
      setIsMobilePlanSelection(true);
    } else {
      setIsMobilePlanSelection(false);
    }

    if (props.location.pathname) {
      setPath(props.location.pathname.split("/")[1]);
    }
    if (
      props.location.pathname.includes("login") ||
      props.location.pathname.includes("forgot-password") ||
      props.location.pathname.includes("signup")
    ) {
      setHideMobileMenu(true);
    }

    if (props.location.pathname.split("/")[1] === 'settings') {
      setAdminSection(true);
    } else {
      setAdminSection(false);
    }

    if (props.location.pathname === "/onboarding") {
      setIsOnboarding(true);
    } else {
      setIsOnboarding(false);
    }
  }, [props.location.pathname]);

  useEffect(() => {
    let delayDebounceFn: any;

    if (props.group && postsSearchQuery.length > 0) {
      delayDebounceFn = setTimeout(() => {
        // getGroupPostsOnSearch();
      }, 2000)
    }

    if (postsSearchQuery.trim() === "" && isPostResultVisible) {
      setPostsSearchResults(null);
      setIsPostResultVisible(false);
    }

    return () => {
      if (delayDebounceFn) {
        clearTimeout(delayDebounceFn)
      }
    }
  }, [postsSearchQuery]);

  // const getGroupPostsOnSearch = () => {
  //   GroupService.getInstance()
  //     .getGroupPosts(
  //       props.group.id,
  //       null,
  //       null,
  //       postsSearchQuery
  //     )
  //     .then((response: any) => {
  //       if (response.data.length > 0 && !isPostResultVisible) {
  //         setIsPostResultVisible(true);
  //       }

  //       if (response.data.length === 0 && isPostResultVisible) {
  //         setIsPostResultVisible(false);
  //       }

  //       if (postsSearchQuery.trim() !== "") {
  //         setPostsSearchResults(response.data);
  //       }
  //     });
  // }

  // universal search data 
  const [, setUniversalSearchResults] = useAtom(universalSearchResultsAtom);

  const fetchCommunityContent = () => {
    if (postsSearchQuery && postsSearchQuery.length >= 2) {
      GroupService.getInstance()
        .getCommunityContent(
          props.group.id,
          postsSearchQuery
        )
        .then((response: any) => {
          window.scrollTo(0, 0)
          setUniversalSearchResults(response)
        });
    }
  }

  const shouldShowSignupButton = () => {
    if (
      props.group &&
      props.group.sso_enabled &&
      props.group.sso_type === SSOMethod.OAUTH
    ) {
      return (
        props.group.oauth_sso &&
        props.group.oauth_sso.allow_custom_signup
      )
    }

    return true;
  }

  const activePlans = props.group && props.group.plans.filter((plan: any) => (
    plan.archived_at === null && !plan.hidden
  ));

  // Take a look at this function later for its relevance.
  function redirectToPlanSelection() {
    if (props.history && props.group) {
      if (subdomain) {
        if (activePlans && activePlans.length === 0) history.push('/signup-disabled')
        else {
          history.push(`/plan-subscription`);
        }
      } else {
        history.push(`/group/${props.group.slug}/plan-subscription`);
      }
    }
  }

  const [isDeleteCancelSubModalOpen, setIsDeleteCancelSubModalOpen] = useState<boolean>(false)

  const closeIsDeleteCancelSubModalOpen = () => {
    setIsDeleteCancelSubModalOpen(false)
  }

  function cancelRequest() {
    if (props.user && props.user.id) {
      GroupService.getInstance()
        .leaveGroup(props.user.id, props.group.id)
        .then(() => {
          window.location.reload();
          setIsDeleteCancelSubModalOpen(false)
        });
    }
  }

  function clickOnLogo() {
    props.history.push('/');
  }

  function showGroupLogin() {
    if (props.location.pathname.includes('/posts/')) {
      setlocalStorageValue("next_link", window.location.pathname);
    }

    if (subdomain) {
      props.history.push('/signin');
    } else {
      if (props.location.pathname.includes('/group/')) {
        const groupSlug = props.location.pathname.split("/")[2];
        const domain = (appConfig.domain.port) ?
          `${appConfig.domain.baseDomain}:${appConfig.domain.port}`
          : appConfig.domain.baseDomain;

        window.location.assign(`http://${groupSlug}.${domain}/signin`);
      }
    }
  }

  function showGroupSignup() {
    if (
      props.group &&
      props.group.sso_enabled &&
      props.group.sso_type === SSOMethod.OAUTH &&
      props.group.oauth_sso &&
      props.group.oauth_sso.allow_custom_signup &&
      props.group.oauth_sso.custom_signup_url
    ) {
      window.open(props.group.oauth_sso.custom_signup_url, '_self');
      return;
    }
    setChannelSidebarStatus(false);
    if (subdomain) {
      window.location.assign(`${miscUtils.getRedirectBaseUrl(group)}/plan-subscription`);
    } else {
      showPublicGroupSignup();
    }
  }

  function getAdminButtonStyle() {
    const style: { [k: string]: any } = {};
    if (theme && theme.custom && !adminSection) {
      style.color = theme.custom.fontColor
      style.border = `1px solid ${theme.custom.fontColor}`;
      if (theme.custom.primaryColor) {
        style.backgroundColor = theme.custom.primaryColor;
      }
    } else {
      style.backgroundColor = '#ffffff';
      style.color = '#666';
    }
    return style;
  }

  const searchInput = useRef(null);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const {
    ref: postSearchRef,
    isComponentVisible: isPostResultVisible,
    setIsComponentVisible: setIsPostResultVisible,
  } = useComponentVisible(false);

  const onHamburgerToggle = () => {
    toggleHamburgerMenu();
  };

  useEffect(() => {
    if (props.userGroups && props.userGroups.data.length) {
      const blockedGroups: any = [];
      props.userGroups.data.forEach((userGroup: any) => {
        if (userGroup.blocked) {
          blockedGroups.push(userGroup.group.id);
        }
      });
      setBlockedGroups(blockedGroups);
    }
  }, [props.userGroups]);

  useEffect(() => {
    setSearch(props.currentView);
  }, [props.currentView]);

  useEffect(() => {
    if (communitySearchOpen) {
      focusCommunitySearchInput();
    }

    if (!communitySearchOpen && isPostResultVisible) {
      setIsPostResultVisible(false);
    }
  }, [communitySearchOpen]);

  useEffect(() => {
    if (!groupAppSearchOpen && isComponentVisible) {
      setIsComponentVisible(false);
    }
  }, [groupAppSearchOpen]);

  const query = new URLSearchParams(window.location.search);
  const searchText: any = query.get("search");

  // on reload, fetch again
  useEffect(() => {
    if (window.location.pathname === "/filter" && props.group && searchText) {
      GroupService.getInstance()
        .getCommunityContent(
          props.group.id,
          searchText
        )
        .then((response: any) => {
          setUniversalSearchResults(response)
        });
    }
  }, [props.group]);

  useEffect(() => {
    if (window.location.pathname !== "/filter") {
      if (postsSearchQuery.length > 0) {
        setPostsSearchQuery("");
      }

      if (postsSearchResults && postsSearchResults.length > 0) {
        setPostsSearchResults(null);
      }
    }

  }, [window.location.pathname]);

  function focusCommunitySearchInput() {
    const communitySearchInputElement: HTMLElement = document.getElementById("gp-community-search-input") as HTMLElement;

    if (communitySearchInputElement) {
      communitySearchInputElement.focus();
    }
  }

  function searchGroups(searchQuery: string) {
    setSearch(searchQuery);
    if (searchQuery.length > 0) {
      GroupService.getInstance()
        .getAllGroups(searchQuery, true)
        .then((response: any) => {
          if (response && response.data) {
            if (blockedGroups.length) {
              response.data = response.data.filter((groupResult: any) => {
                return blockedGroups.indexOf(groupResult.id) < 0;
              });
            }

            setResults(response.data);
            setCountResults(response.data.length);
          }
        });
    } else {
      setResults([]);
    }
  }

  function openGroup(targetGroup: any) {
    // reuse util function to open targetGroup selected from dropdown
    setResults([]);
    window.location.assign(miscUtils.getRedirectBaseUrl(targetGroup));
  }

  function handleConfirm(e: any) {
    if (windowSize.width <= 550) {
      if (!groupAppSearchOpen) {
        setGroupAppSearchOpen(true);
        return;
      }
    }
    if (
      (e.key === "Enter" || e.type === "click") &&
      search &&
      search.length > 0
    ) {
      setIsComponentVisible(false);
      goTo(`/search/?q=${search}`);
    }
  }

  function handleSearchInputClick() {
    if (searchInput && searchInput.current) {
      // @ts-ignore
      searchInput.current.setSelectionRange(0, search.length);
    }
    setIsComponentVisible(true);
  }

  function showSignUp() {
    if (
      props.group &&
      props.group.sso_enabled &&
      props.group.sso_type === SSOMethod.OAUTH &&
      props.group.oauth_sso &&
      props.group.oauth_sso.allow_custom_signup &&
      props.group.oauth_sso.custom_signup_url
    ) {
      window.open(props.group.oauth_sso.custom_signup_url, '_self');
      return;
    }
    if (subdomain) {
      if (activePlans && activePlans.length === 0) {
        history.push('/signup-disabled')
      } else {
        window.location.assign(`${miscUtils.getRedirectBaseUrl(group)}/plan-subscription`);
      }
    } else {
      showPublicGroupSignup();
    }
  }

  function onRootUrl() {
    return !passedSubdomain && props.location.pathname === "/";
  }

  function toggleAuthButtonHover() {
    setAuthButtonHover(!authButtonHover);
  }

  function checkForMobile() {
    return (
      isMobile &&
      !isMobilePlanSelection &&
      !hideMobileMenu &&
      !isInvite &&
      !signin &&
      !onRootUrl()
    );
  }

  const isModeratorAndInactive = () => {
    return (
      group &&
      !group.active_subscription &&
      miscUtils.isModerator(group.user_membership)
    )
  }

  const clickOnAdminPanel = () => {
    if (group) {
      if (
        isModeratorAndInactive()
      ) {
        setIsOpen(true);
        return;
      }
      history.push('/settings/members', '_self');
    }
  }

  const isPurchasePage = (): boolean => {
    const pathParts = window.location.pathname.split("/");
    return pathParts.pop() === "purchase";
  }

  const isPlanSubscriptionPage = (): boolean => {
    const pathParts = window.location.pathname.split("/");
    return pathParts[1] === "plan-subscription";
  }

  const isLibraryLecturePage = (): boolean => {
    const pathParts = window.location.pathname.split("/");
    return pathParts[3] === "lectures";
  }

  const isNotificationsPage = (): boolean => {
    const pathParts = window.location.pathname.split("/");
    return pathParts.pop() === "notifications";
  }

  const shouldShowLectureMenuIcon = (): boolean => {
    return ((window.location.pathname.includes("courses")) &&
      (window.location.pathname.includes("lectures")) &&
      (windowSize.width <= 960))
  }

  const shouldShowCommunitySearch = () => {
    if (
      !props.group ||
      window.location.pathname.split("/")[1] === "settings" ||
      window.location.pathname === "/invite" ||
      window.location.pathname === "/signin" ||
      window.location.pathname.split("/").pop() === "purchase" ||
      window.location.pathname === "/plan-subscription" ||
      window.location.pathname === "/plan-subscription/checkout" ||
      isOnboarding
    ) {
      return false;
    }

    return true;
  }

  const [isLoading,] = useAtom(chatsLoadingAtom);


  const { getDirectChats, getCommunityUnread, setMemberCommunityUnreadCount } = React.useContext(DirectChatsContext)

  const chatIconHide = group && group.menu['messages']['show']
  const [groupMenuMessagesShowMenu,] = useAtom(groupMenuMessagesShowAtom);

  const chatsIconsHidden = groupMenuMessagesShowMenu === undefined ? chatIconHide : groupMenuMessagesShowMenu
  const isGroupMember = miscUtils.isGroupMember(group && group)

  const pusherOptions = {
    key: appConfig.pusher.key,
    cluster: appConfig.pusher.cluster,
    broadcaster: appConfig.pusher.broadcaster,
    forceTLS: appConfig.pusher.forceTLS,
    encrypted: appConfig.pusher.encrypted,
    authEndpoint: appConfig.pusher.authEndpoint,
    auth: appConfig.pusher.auth
  };

  function initEcho() {
    return new Echo(pusherOptions);
  }

  const [echo, setEcho] = useState<any>();

  useEffect(() => {
    if (!echo) {
      const echoObj = initEcho();
      setEcho(echoObj);
    }
  }, [])


  useEffect(() => {
    if (echo && group) {
      echo.channel('chat-room')
        .listen(".chatroomsChanged_" + group.id, (response: any) => {
          if (response.chatRoom && group.id === response.chatRoom.group_id) {
            response.communityMemberIds.forEach((memberId: any, index: any) => {
              if (memberId === group.user_membership.id && response.chatRoom.owner.id !== memberId) {
                getDirectChats()
                getCommunityUnread()
              }
            })
          }
        }).listen(".unreadMessage_" + group.id, (response: any) => {
          if (response.communityMemberId === group.user_membership.id) {
            setMemberCommunityUnreadCount(response.communityMemberUnreadCount);
            getDirectChats()
          }
        })
    }
  }, [echo, group])

  const InvitePage = window.location.pathname === "/invite";

  const showJoinCommunityButton = () => {
    return (
      authed && (
        props.group &&
        props.group.membership_status &&
        props.group.membership_status !== "member" &&
        // props.group.menu['invite']['show']
        !props.group.close_signup
      ) && (
          !window.location.pathname.includes('invite')) &&
        (!window.location.pathname.includes('plan-subscription')) 
    )
  }

  return (
    <header className={`g-header ${isModalActive ? "js-modal-active" : ""}`} style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.primaryColor } : {}}>
      <div className={`${!authed ? "no-auth" : ""} header-wrap flex flex-align-center ${isOnboarding ? "onboarding" : ""} ${InvitePage ? 'no-padding' : ''}`}>
        <div className="header-action-left flex-align-center flex">
          {
            isLibraryLecturePage() &&
            (
              <div
                className="library-lecture-action"
              >
                <button
                  onClick={onHamburgerToggle}
                >
                  <span
                    style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                  />
                  <span
                    style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                  />
                  <span
                    style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                  />
                </button>
              </div>
            )
          }

          {/* Show this hamburger on notifications page */}
          {isNotificationsPage() && window.innerWidth <= 550 && (
            <div
              className="notifications-page-hamburger"
            >
              <button
                onClick={onHamburgerToggle}
              >
                <span
                  style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                />
                <span
                  style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                />
                <span
                  style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                />
              </button>
            </div>
          )}

          {((authed && !isMobilePlanSelection && group) || checkForMobile()) && (!isPurchasePage()) && (!isPlanSubscriptionPage()) && (!isLibraryLecturePage()) && (!isNotificationsPage()) && !isOnboarding && (
            <div className="header-action flex flex-justify-center flex-align-center">
              <button
                className={
                  global.toggleSidebar
                    ? "flex-align-center flex-direction-column flex is-sidebar-open"
                    : "flex-align-center flex-direction-column flex"
                }
                onClick={onHamburgerToggle}
                id={!authed ? 'hamburger-toggle-button' : ''}
              >
                <span
                  style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                />
                <span
                  style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                />
                <span
                  style={(miscUtils.customThemeSet(theme) && !adminSection) ? { backgroundColor: theme.custom.fontColor } : {}}
                />
              </button>
            </div>
          )}
          {homeLogin && subdomain === null && (
            <div onClick={() => clickOnLogo()} className={`header-logo ${window.location.pathname === "/onboarding" ? "disabled" : ""}`}>
              <GroupApp />
            </div>
          )}
          <div className={`dropdown-menu header-communities`}>
            <Link
              to="/"
              onClick={() => clickOnLogo()}
              className={`groupapp-logo ${window.location.pathname === "/onboarding" ? "disabled" : ""}`}
            >
              {group && group.profile_image && (
                <img src={group.profile_image} alt={"group-profile"} />
              )}

              {group && !group.profile_image && (
                <div className="profile-image" data-color={miscUtils.getColorInitial(`${group.name}`)}>
                  <div className="group-name">{miscUtils.getNameInitials(group.name)}</div>
                </div>
              )}
            </Link>
          </div>

          {shouldShowCommunitySearch() && (
            <>
              <div
                className={`gp-community-search-container ${(!chatsIconsHidden || (!isGroupMember && authed)) ? 'chat-icon-hidden' : ''} ${!authed ? 'unauthed' : ''}  ${communitySearchOpen ? 'mobile-open' : ''} ${(window.location.pathname.includes("courses") && window.location.pathname.includes("lectures") ? 'course-lecture-page' : '')
                  } ${showJoinCommunityButton() ? 'join-community' : ''} ${
                    window.location.pathname.includes('library') &&
                    window.location.pathname.includes("lectures") ?
                    'library-content-lecture-page' :
                    ''
                  }`}
                ref={postSearchRef}
              >
                <span
                  className={`gp-community-search-close ${communitySearchOpen ? 'mobile-open' : ''}`}
                  onClick={() => {
                    if (communitySearchOpen) {
                      setCommunitySearchOpen(false);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                  />
                </span>
                <input
                  type="text"
                  placeholder="Search"
                  className={`gp-community-search-input ${communitySearchOpen ? 'mobile-open' : ''}`}
                  id="gp-community-search-input"
                  value={postsSearchQuery}
                  onChange={(e: any) => setPostsSearchQuery(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setUniversalSearchResults(undefined)
                      if (props.group && postsSearchQuery.length >= 2) {
                        // props.setPostsQuery(postsSearchQuery);
                        // getGroupPostsOnSearch();
                        fetchCommunityContent()
                        history.push(`/filter?search=${postsSearchQuery}`)
                      }
                      // setTimeout(() => {
                      // }, 200);
                    }
                  }}
                  onClick={() => {
                    setIsPostResultVisible(true);
                  }}
                />
                <span
                  className={`gp-community-search-icon ${communitySearchOpen ? 'mobile-open' : ''} ${
                    authed ? '' : 'logged-out'
                  }`}
                  onClick={() => {
                    if (!communitySearchOpen) {
                      setCommunitySearchOpen(true);
                    }
                    // if (window.innerWidth >= 768) {
                    setUniversalSearchResults(undefined)
                      if (props.group && postsSearchQuery.length >= 2) {
                        // props.setPostsQuery(postsSearchQuery);
                        // getGroupPostsOnSearch();
                        fetchCommunityContent()

                        // setTimeout(() => {
                          history.push(`/filter?search=${postsSearchQuery}`)
                        // }, 200);
                      }
                    // }
                  }}
                >
                  <SearchIcon
                  fill={windowSize.width > 550 ? undefined : theme && theme.custom ? theme.custom.fontColor : undefined}
                  />
                </span>
              </div>
            </>
          )}

          {authed && homeLogin && subdomain === null && !isOnboarding && (
            <div
              className={`header-search ${groupAppSearchOpen ? 'mobile-open' : ''}`}
              ref={ref}>
              <span
                className={`close-groupapp-search ${groupAppSearchOpen ? 'mobile-open' : ''}`}
                onClick={() => {
                  if (groupAppSearchOpen) {
                    setGroupAppSearchOpen(false);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                />
              </span>
              <input
                ref={searchInput}
                onKeyDown={handleConfirm}
                value={search}
                onChange={e => searchGroups(e.target.value)}
                placeholder="Search for a community"
                onClick={handleSearchInputClick}
              />
              <span
                className="icon-groupapp-search"
                onClick={handleConfirm}
              >
                <SearchIcon />
              </span>

              {results.length > 0 && isComponentVisible && (
                <div className="search-results">
                  <div>
                    {results.slice(0, 5).map((result: any) => {
                      return (
                        <div
                          key={result.id}
                          onClick={() => {
                            openGroup(result);
                          }}
                          className="search-result flex flex-justify-space-between"
                        >
                          <div className="search-result-group-info flex flex-align-center">
                            {result.profile_image ? (
                              <img
                                alt={result.name}
                                className="profile-image"
                                src={result.profile_image}
                              />
                            ) : (
                              <div className="profile-image">
                                {miscUtils.getNameInitials(result.name)}
                              </div>
                            )}
                            <div className="group-name">{result.name}</div>
                          </div>

                          <div className="group-status">
                            {result.status.toLowerCase()} group
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {countResults > 5 && (
                    <div className="search-more-results flex flex-align-center flex-justify-center">
                      <Link
                        onClick={() => setIsComponentVisible(false)}
                        to={{
                          pathname: "/search",
                          search: `?q=${search}`
                        }}
                      >
                        View All Results
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="header-action-right flex-align-center flex">
          {/*
            // @ts-ignore */}
          {((authed && group && group.user_membership && group.user_membership.owner)
            || (authed && group && group.user_membership && miscUtils.isAdmin(group.user_membership))
            || (authed && group && group.user_membership && miscUtils.isModerator(group.user_membership))
          ) && (!window.location.pathname.includes("/settings"))
            && !isOnboarding && (
              <Link
                to={!group || (group && isModeratorAndInactive()) ? '#' : `/settings/members`}
                onClick={clickOnAdminPanel}
              className={`header-create-group ${shouldShowLectureMenuIcon() ? 'header-cteate-group-lecture' : ''} ${theme && theme.custom && theme.custom.primaryColor === '#ffffff' && 'hover-color'}`}
                style={getAdminButtonStyle()}
            >
              Admin Panel
              </Link>
            )}
          {authed && !isOnboarding &&
            <>
              <TopBarNotificationComponent
                subdomain={subdomain}
                group={group}
                theme={!adminSection ? theme : null}
                lectureView={shouldShowLectureMenuIcon()}
                refreshNotificationsCountRef={props.refreshNotificationsCountRef}
              />

              <TopBarMenuComponent
                group={group}
                theme={!adminSection ? theme : null}
                lectureView={shouldShowLectureMenuIcon()}
                subdomain={subdomain}
            />
            {group && chatsIconsHidden && isGroupMember && (
              <TobBarMessages
                group={group && group}
                theme={!adminSection ? theme : null}
                getCommunityUnread={getCommunityUnread}
                isLoading={isLoading}
                lectureView={shouldShowLectureMenuIcon()}
              />
            )}
            </>
          }

          {authed &&
            shouldShowLectureMenuIcon() && (
              <span
                className={`topbar-lecture-menu-toggle-button`}
                onClick={() => {
                  forceUpdate(null);
                  setTimeout(() => {
                    props.toggleLectureMenuRef.current();
                  }, 100);
                }}
              >
                <LectureMenuIcon
                  strokeColor={miscUtils.customThemeSet(theme) && theme.custom.fontColor}
                />
              </span>
            )}
          {!authed && !search && !params.get("invite") && path !== "posts" && (
            <div className="header-menu" />
          )}
          {!authed && (search && search.length > 0 || path === 'library' || path === 'courses' || path === 'events' || path === "invite-preview" || isInvite) && 
          window.location.pathname.split("/").pop() !== 'purchase' && (
            <div className="header-menu public-group-menu flex">
              {!signin && path !== "posts" && (
                <>
                  {!window.location.pathname.includes("outline") && (
                    <button
                      style={miscUtils.getBlockInverseButtonStyle(!adminSection && theme && !whiteCommunityTheme ? theme : null)}
                      onClick={() => showGroupLogin()}
                      className="btn btn-no-style btn-header-login"
                      type="button"
                    >
                      Log in
                    </button>
                  )}
                </>
              )}
              {!isInvite && !signin && path !== "invite-preview" && shouldShowSignupButton() && path !== "posts" && (props.group && !props.group.close_signup) && (
                <>
                  {!window.location.pathname.includes("outline") && (
                    <button
                      style={miscUtils.getBlockInverseButtonStyle(!adminSection && theme && !whiteCommunityTheme ? theme : null)}
                      onMouseEnter={toggleAuthButtonHover}
                      onMouseLeave={toggleAuthButtonHover}
                      onClick={() => showSignUp()}
                      className="btn btn-no-style btn-header-signup"
                      type="button"
                    >
                      Sign up
                    </button>
                  )}
                </>
              )}
            </div>
          )}
          {showJoinCommunityButton() &&
            (
              <button
                style={miscUtils.getBlockInverseButtonStyle(!adminSection ? theme : null)}
                onClick={() => {
                  if (props.group.membership_status === "requested") {
                    setIsDeleteCancelSubModalOpen(true)
                  } else {
                    redirectToPlanSelection();
                  }
                }}
              className={`${(theme && theme.custom) ? "" : 'btn-border'} "btn btn-no-style groupapp-join-community-button`}
                type="button"
              >
                {props.group.membership_status === "requested" ? "Cancel Request" : "Join Community"}
              </button>
            )}
          {path === "posts" && !authed && (
            <div className="header-menu public-group-menu flex">
              {!isInvite && (
                <button
                  style={miscUtils.getBlockInverseButtonStyle(!adminSection ? theme : null)}
                  onClick={() => showGroupLogin()}
                  className="btn btn-no-style btn-header-login"
                  type="button"
                >
                  Log in
                </button>
              )}
              {!isInvite && shouldShowSignupButton() && (props.group && !props.group.close_signup) && (
                <button
                  style={miscUtils.getBlockInverseButtonStyle(!adminSection ? theme : null)}
                  onClick={() => showGroupSignup()}
                  className="btn btn-no-style btn-header-signup"
                  type="button"
                >
                  Sign up
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <>
        <AppModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title="Community Subscription Expired"
          message="Please contact an admin to renew the subscription"
          buttonText="OK"
        />

        <ConfirmModal
          isModalOpen={isDeleteCancelSubModalOpen}
          closeModal={closeIsDeleteCancelSubModalOpen}
          actionCallback={cancelRequest}
          modalSubTitle="Are you sure you would like to cancel your pending membership request?"
          modalTitle="Confirm action"
          actionBtn="Yes"
          cancelBtn='No'
        />
      </>
    </header>
  );
};

// - Map dispatch to props
const mapDispatchToProps = (
  dispatch: any,
  ownProps: ITopBarComponentsProps
) => {
  return {
    setPostsQuery: (query: string) =>
      dispatch(globalActions.setPostsQuery(query)),
    toggleHamburgerMenu: () => dispatch(globalActions.toggleHamburgerMenu()),
    showPublicGroupLogin: () => dispatch(globalActions.showPublicGroupLogin()),
    setChannelSidebarStatus: (status: boolean) =>
      dispatch(globalActions.setSidebarForChannel(status)),
    showPublicGroupSignup: () =>
      dispatch(globalActions.showPublicGroupSignup()),
    goTo: (url: string) => dispatch(push(url)),
    logout: () => {
      dispatch(authorizeActions.dbLogout());
    },
    createGroup: () => {
      dispatch(push("/creategroup"));
    }
  };
};
const mapStateToProps = (state: any, ownProps: ITopBarComponentsProps) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const groups: any = Map(state.get("group", {})).toJS();
  return {
    global,
    theme: global.theme,
    selectedGroup: global.selectedGroup,
    currentView: global.currentView,
    userGroups: groups.userGroups,
    isModalActive: global.isModalActive
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopBarComponent as any) as any
) as any;
