import React from 'react';
import './LivePageContainer.scss';
import { ILivePageContainerProps } from './ILivePageContainerProps';
import { PageBlockTypes, WidgetType, getDefaultPageStyles } from '../SinglePageContainer/domain';
import BannerBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/BannerBlockPreview';
import HeaderBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/HeaderBlockPreview';
import SubtextBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/SubtextBlockPreview';
import TextBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/TextBlockPreview';
import ImageBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/ImageBlockPreview';
import ImageTextBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/ImageTextBlockPreview';
import ImageGridTextBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/ImageGridTextBlockPreview';
import VideoBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/VideoBlockPreview';
import ButtonBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/ButtonBlockPreview';
import PricingBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/PricingBlockPreview';
import CourseCurriculumBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/CourseCurriculumBlockPreview';
import PageBuilderFooter from 'components/PageBuilder/PageBuilderFooter';
import NotFoundComponent from 'containers/notFound/NotFoundComponent';
import AccessRestricted from 'containers/AccessRestricted';
import PageContainer from 'components/PageBuilder/PageBuilderBuildingPreview/BlockComponents/PageContainer';
import PageWrapper from 'components/PageBuilder/PageBuilderBuildingPreview/BlockComponents/PageWrapper';
import { useQuery } from 'react-query';
import CommunityService from 'api/react-query/services/CommunityService';
import PageBuilderService from 'api/react-query/services/PageBuilderService';
import Facebook from "Facebook";
import VideoTextBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/VideoTextBlockPreview';
import FAQBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/FAQBlockPreview';
import Favicon from 'react-favicon';
import PageBuilderFormPreview from 'components/PageBuilder/PageBuilderFormPreview';
import { defaultFaviconUrl } from './domain';
import PageBuilderSchedulerPreview from 'components/PageBuilder/PageBuilderBuildingPreview/PageBuilderSchedulerPreview';
import CountdownTimerBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/CountdownTimerBlockPreview/CountdownTimerBlockPreview';
import { Helmet } from 'react-helmet-async';
import ImageTextColumnBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/ImageTextColumnBlockPreview';
import EventBlockPreview from 'components/PageBuilder/PageBuilderBuildingPreview/EventBlockPreview';

const LivePageContainer: React.FC<ILivePageContainerProps> = (props) => {

  const subdomain =
   window.location.hostname.includes("group.app") || 
   window.location.hostname.includes("group.localhost") ?
   window.location.hostname.split(".")[0] :
   window.location.hostname;

  const getCommunityQuery = useQuery(
    [CommunityService.keyIds.GET_COMMUNITY, subdomain],
    CommunityService.queryFunctions.getCommunity,
  );

  const getLivePageQuery = useQuery(
    [PageBuilderService.keyIds.GET_LIVE_PAGE, {
      groupId: getCommunityQuery. data ? getCommunityQuery.data.id : undefined,
      pageSlug: window.location.pathname.split("/").pop(),
    }],
    PageBuilderService.queryFunctions.getLivePage,
    {
      enabled: false,
    },
  );

  const [pageForms, setPageForms] = React.useState<Array<any>>([]);
  const [pageFormsOpenState, setPageFormsOpenState] = React.useState<any>({});

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

      return () => {
          document.body.style.overflow = 'auto';
      }
  }, []);

  React.useEffect(() => {
    if(getCommunityQuery.data) {
      document.title = getCommunityQuery.data.name;
      getLivePageQuery.refetch();
    }
  }, [getCommunityQuery.data]);

  React.useEffect(() => {
    if(getLivePageQuery.data) {
      document.title = getLivePageQuery.data.title
      setPageForms(
        getLivePageQuery.data.configurations.widgets
        .map((pageForm: any) => ({
          ...pageForm.configuration,
          widget_id: pageForm.id,
          group_id: getCommunityQuery.data.id,
        }))
      );
    }
  }, [getLivePageQuery.data]);

  React.useEffect(() => {
    let nextPageFormsOpenState: any = {};

      pageForms.forEach((pageForm: any) => {
          nextPageFormsOpenState[pageForm.render_id] = 
              pageFormsOpenState[pageForm.render_id] ? 
              pageFormsOpenState[pageForm.render_id] :
              false;
      });

      setPageFormsOpenState(nextPageFormsOpenState);
  }, [pageForms.length]);

  const getPageBlockElements = (blockData: any) => {
    switch(blockData.type) {
      case PageBlockTypes.Banner:
        return (
          <BannerBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.Heading:
        return (
          <HeaderBlockPreview
          blockData={blockData}
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      case PageBlockTypes.Subtext:
        return (
          <SubtextBlockPreview
          blockData={blockData}
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      case PageBlockTypes.Text:
        return (
          <TextBlockPreview
          blockData={blockData}
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      case PageBlockTypes.Image:
        return (
          <ImageBlockPreview
          blockData={blockData}
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      case PageBlockTypes.ImageText:
        return (
          <ImageTextBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.ImageTextColumn:
        return (
          <ImageTextColumnBlockPreview
          blockData={
            blockData.image_shown === undefined ? 
            {
              ...blockData,
              image_shown: true,
            } :
            blockData
          }
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.ImageGridText:
        return (
          <ImageGridTextBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.Video:
        return (
          <VideoBlockPreview
          blockData={blockData}
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      case PageBlockTypes.VideoText:
        return (
          <VideoTextBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.Button:
        return (
          <ButtonBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.Pricing:
        return (
          <PricingBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.Event:
        return (
          <EventBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          pageFormsOpenState={pageFormsOpenState}
          setPageFormsOpenState={setPageFormsOpenState}
          />
        )
      case PageBlockTypes.CourseCurriculum:
        return (
          <CourseCurriculumBlockPreview
          blockData={blockData}
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      case PageBlockTypes.FAQ:
        return (
          <FAQBlockPreview
          blockData={blockData}
          containerBaseIdName="gp-pb-live-page-content-block-container--"
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      case PageBlockTypes.CountdownTimer:
        return (
          <CountdownTimerBlockPreview
          group={getCommunityQuery.data}
          buildingPreview
          blockData={blockData}
          pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
          />
        )
      default:
        return <></>
    }
  }

  const getLivePageElements = () => {
    if(getLivePageQuery.isLoading) {
      return <></>
    }

    if(getLivePageQuery.isError) {
      return (
        <div
        className="gp-pb-live-page-error-container"
        >
            <NotFoundComponent
            user
            message={<AccessRestricted
              user
              authed
              group={getCommunityQuery.data}
              message="Error 404: Page not found"
              subHeading="Please contact the website administrator"
              showActionMessage={false}
            />}
            showSubMessage={false}
          />
        </div>
      )
    }

    if(getLivePageQuery.data) {
      return (
        <PageContainer
        pageStyles={getLivePageQuery.data.page_style  || getDefaultPageStyles()}
        >
          <PageWrapper
          >
            {getLivePageQuery.data.configurations.configurations.map((pageBlock: any) => (
              <div
              key={pageBlock.render_id}
              id={`gp-pb-live-page-content-block-container--${pageBlock.render_id}`}
              className="gp-pb-live-page-content-block-container"
              >
                  {getPageBlockElements(pageBlock)}
              </div>
            ))}
            {getCommunityQuery.data && getCommunityQuery.data.powered_by_groupapp ? (
              <PageBuilderFooter
              />
            ) : <></>}
          </PageWrapper>
          {pageForms ? pageForms.map((pageForm: any) => {
            switch(pageForm.type) {
              case WidgetType.Form:
                return (
                  <PageBuilderFormPreview
                  key={pageForm.render_id}
                  pageForm={pageForm}
                  pageFormsOpenState={pageFormsOpenState}
                  setPageFormsOpenState={setPageFormsOpenState}
                  pageStyles={getLivePageQuery.data.page_style || getDefaultPageStyles()}
                  />
                )
              case WidgetType.Scheduler:
                return (
                  <PageBuilderSchedulerPreview
                  key={pageForm.render_id}
                  pageForm={pageForm}
                  pageFormsOpenState={pageFormsOpenState}
                  setPageFormsOpenState={setPageFormsOpenState}
                  pageStyles={getLivePageQuery.data.page_style || getDefaultPageStyles()}
                  />
                )
              default:
                return <></>
            }
          }) : <></>}
        </PageContainer>
      )
    }

    return <></>
  }

  return (
    <div
    className="gp-pb-live-page-container"
    >
        <Helmet
        >
          <title>
            {
              getLivePageQuery.data ? 
                getLivePageQuery.data.title : 
                getCommunityQuery.data ?
                  getCommunityQuery.data.name :
                  'GroupApp'
            }
          </title>
          <meta
          name="description"
          content={
            getLivePageQuery.data ?
              getLivePageQuery.data.title :
              "Web site created using create-react-app"
          }
          />
          <link
          rel="canonical"
          href={`${window.location.href}`}
          />
        </Helmet>
        {(
          getCommunityQuery.data &&
          getCommunityQuery.data.favicon_image
        ) ? (
          <Favicon url={getCommunityQuery.data.favicon_image} />
        ) : (
          <Favicon url={defaultFaviconUrl}/>
        )}
        {
          getCommunityQuery.data && getCommunityQuery.data.ex_config && getCommunityQuery.data.ex_config.facebook_pixel ?
          <Facebook pixelId={getCommunityQuery.data.ex_config.facebook_pixel} /> :
          <></>
        }
        {getLivePageElements()}
    </div>
  )
}

export default LivePageContainer;