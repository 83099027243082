import React from 'react';
import './gifButton.scss';

import { ReactComponent as GifIcon } from 'assets/img/gif.svg'


type GIFButtonType = {
  onClick: any
}

const GIFButton: React.FC<GIFButtonType> = ({ onClick }) => {
  return (
    <div
      className="post-draft-gif-button-container"
      onClick={onClick}
    >
      <span className='gif-icon'>
        <GifIcon />
      </span>

    </div>
  )
}

export default GIFButton;