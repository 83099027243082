export const getChannelCollections = (channels: Array<any>): Array<any> => {

    let channelCollections: Array<any> = [];

    channels.forEach((channel: any) => {
        if(channel.channel_collection) {
            let channelCollectionCaptured: boolean = false;

            channelCollections.forEach((collection: any) => {
                if(collection.id === channel.channel_collection.id) {
                    channelCollectionCaptured = true;
                }
            });

            if(!channelCollectionCaptured) {
                channelCollections = [
                    ...channelCollections, {
                        id: channel.channel_collection.id,
                        name: channel.channel_collection.title,
                        position: channel.channel_collection.position,
                        open: channel.channel_collection.expanded,
                        channels: [channel],
                    }
                ]
            } else {
                if(channel.status !== "archived") {
                    channelCollections = channelCollections.map((collection: any) => {
                        if(collection.id === channel.channel_collection_id) {
                            return {
                                ...collection,
                                channels: [...collection.channels, channel],
                            }
                        }

                        return collection;
                    });
                }
            }
        }
    });

    //sort the channels in the array
    channelCollections.forEach((collection: any, index: number) => {
        let collectionChannels = collection.channels;
        collectionChannels.sort((currentChannel: any, nextChannel: any) => (
            currentChannel.position - nextChannel.position
        ));

        channelCollections[index].channels = collectionChannels;
    });

    //sort the array according to position
    channelCollections.sort((currentCollection: any, nextCollection: any) => (
        currentCollection.position - nextCollection.position
    ))
    return channelCollections;
}

export const getUnAssignedChannels = (channels: Array<any>): Array<any> => {
    const unAssignedChannels: Array<any> = [];
    
    channels.forEach((channel: any) => {
        if(!(channel.channel_collection && channel.channel_collection_id) && channel.status !== "archived") {
            unAssignedChannels.push(channel);
        }
    });

    return unAssignedChannels;
}