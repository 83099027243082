import React, { useEffect } from "react";
import { ICloudflarePlayerProps } from "./ICloudflarePlayerProps";

const CloudflarePlayer: React.FC<ICloudflarePlayerProps> = props => {
  const { videoId, thumbnail,  height, wrapperClass, announcementView } = props;

  useEffect(() => {
  }, []);

  return (
    <div className={`${wrapperClass ? wrapperClass: 'wistia-wrapper'}`} style={{marginTop: 0}}>
      <iframe
        src={`https://iframe.videodelivery.net/${videoId}?poster=${encodeURI(thumbnail)}`}
        height={height ? height : "100%"}
        style={{border: "none", width: '100%', display: announcementView ? 'block' : ''}}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default CloudflarePlayer;
