import React from 'react';
import {
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton
} from '@draft-js-plugins/buttons';

const HeadlinesPicker = (props: any) => {

    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];

    React.useEffect(() => {
        const onWindowClick = () => {
            props.onOverrideContent(undefined);
        }

        setTimeout(() => {
            window.addEventListener('click', onWindowClick);
        });

        return () => {
            window.removeEventListener('click', onWindowClick);
        }
    }, []);
  
   

      return (
        <div>
          {buttons.map((Button, i) => {
              return (
                //@ts-ignore
                <Button key={i} {...props} />
              )
          })}
        </div>
      );
  }

  export default HeadlinesPicker;