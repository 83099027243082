import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ISEOProps } from './ISEOProps';

const SEO: React.FC<ISEOProps> = (props) => {
  return (
    <Helmet>
        {/* <meta name="description" content="Dedicated description" />
        <meta itemProp="description" content="Dedicated description item prop" /> */}
        <meta property="og:url" content={props.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta property="og:image" content={props.image} />
  </Helmet>
  )
}

export default SEO;