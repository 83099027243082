import React from 'react';

import OnboardingService from 'api/services/onboarding';
import { toast, ToastType } from 'react-toastify';
import { QuestionTypes } from 'containers/Onboarding/MembershipQuestions/QuestionMaker/domain';
import QuestionsTypesFields from '../QuestionsTypesFields/QuestionsTypesFields';
import { CommunityOnboardingStepsContext } from 'containers/CommunityOnboardingSteps/CommunityOnboardingSteps';
import ContentLoader from 'react-content-loader';


const CustomQuestions = ({ group }: { group: any }) => {

    const [isMakingRequest, setIsMakingRequest] = React.useState<boolean>(false);

    const {
        setCheckedCustomRequiredFields,
        submitCustomFieldsAnswers,
        goToNextSection,
        membershipQuestions,
        responseState,
        setResponseState,
    } = React.useContext(CommunityOnboardingStepsContext)


    const validateResponse = () => {
        let isResponseValid = true;

        //@ts-ignore
        membershipQuestions.forEach((membershipQuestion: any, index: number) => {
            if (membershipQuestion.required && membershipQuestion.type !== QuestionTypes.Checkbox && responseState[index].answer.trim() === "") {
                isResponseValid = false;
                setCheckedCustomRequiredFields(false)

            }

            if (membershipQuestion.required && membershipQuestion.type === QuestionTypes.Checkbox && responseState[index].answer.length === 0) {
                isResponseValid = false;
                setCheckedCustomRequiredFields(false)

            }
        });

        if (!isResponseValid) {
            toast("Please answer all the questions that are marked required", {
                type: ToastType.ERROR,
                autoClose: 3000,
            });
        }

        return isResponseValid;
    }

    const saveResponse = () => {
        if (validateResponse()) {
            setCheckedCustomRequiredFields(true)
            setIsMakingRequest(true);
            OnboardingService.getInstance()
                .respondToMembershipQuestions(group.id, group.user_membership.id, {
                    responses: responseState,
                })
                .then(() => {
                    setIsMakingRequest(false);
                    toast("Your answers have been saved", {
                        type: ToastType.SUCCESS,
                        autoClose: 3000,
                    });
                    submitCustomFieldsAnswers()
                    goToNextSection()
                })
                .catch(() => setIsMakingRequest(false))
                // .finally(() => getCommunityQuery.refetch())
        }
    }

    return (
        <>
            {membershipQuestions === null ?
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <ContentLoader
                        height={70}
                        width={1000}
                        speed={2}
                        primaryColor="#d9d9d9"
                        secondaryColor="#ecebeb"
                    >
                        <rect x={400} y="0" rx="4" ry="4" width={180} height={18} />
                        <rect x={350} y="26" rx="4" ry="4" width={280} height={18} />
                    </ContentLoader>
                    {Array(2).fill('').map((_e, i) => (
                        <ContentLoader
                            height={300}
                            width={1000}
                            speed={2}
                            primaryColor="#d9d9d9"
                            secondaryColor="#ecebeb"
                        >
                            <rect x={100} y="0" rx="4" ry="4" width={280} height={18} />
                            <rect x={100} y="34" rx="4" ry="4" width={800} height={100} />

                            <rect x={100} y="174" rx="4" ry="4" width={280} height={18} />
                            <circle cx="108" cy="214" r="10" />
                            <rect x={124} y="206" rx="4" ry="4" width={120} height={18} />
                            <circle cx="108" cy="238" r="10" />
                            <rect x={124} y="228" rx="4" ry="4" width={120} height={18} />
                            <circle cx="108" cy="260" r="10" />
                            <rect x={124} y="250" rx="4" ry="4" width={120} height={18} />

                        </ContentLoader>
                    ))}
                </div>
                :
                <div>
                    <QuestionsTypesFields
                        membershipQuestions={membershipQuestions as Array<any>}
                        responseState={responseState}
                        setResponseState={setResponseState}
                        isMakingRequest={isMakingRequest}
                        onComplete={saveResponse}
                        group={group}
                    />
                </div>
            }
        </>
    )
}

export default CustomQuestions;