import React, { useState, useEffect } from "react";
import { ILinkPreviewProps } from "./ILinkPreviewProps";
import "./LinkPreview.scss";
import miscUtils from "../../utils/miscUtils";

import defaultImage from "../../assets/img/bg_image.png";

const LinkPreview: React.FC<ILinkPreviewProps> = props => {
  const { metaData, url } = props;
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    setImage(metaData.image);
  }, [metaData]);

  function visitUrl() {
    const tab: any = window.open(url, "_blank");
    tab.focus();
  }

  return (
    <div
      onClick={() => visitUrl()}
      className="link-preview flex flex-direction-column"
    >
      {image ? (<img src={image} alt={"meta-data"} onError={() => setImage(null)}/>) : (<img src={defaultImage} alt={"meta-data"}/>)}
      <div className={`details ${props.announcementView ? 'details--announcement' : ''}`}>
        <p className={"link"}>{url && miscUtils.getDomainFromUrl(url)}</p>
        <p className={"title"}>{metaData.title}</p>
        <p className={"description"}>{metaData.description}</p>
      </div>
    </div>
  );
};

export default LinkPreview;
