import React from 'react'
import ContentLoader from 'react-content-loader'

interface IListsLoaderProps {
  padding?: number;
  totalItems?: number;
  width?: number;
  styles?: any;
  showBorders?: boolean
  containerStyles?: any;
}

const ListsLoader: React.FC<IListsLoaderProps> = props => {
  const { padding = 5, totalItems = 5, width = 400 } = props;

  const space: number = 5;
  const cardHeight = 25;

  const getLoaders = () => {
    const list = [];

    for (let i = 0; i < totalItems; i++) {
      const y = (cardHeight / 2);
      const y2 = y + 12
      const borderRadius = 4;
      const lineThickness = 0.3;

      list.push(
        <div style={{ marginBottom: "1.5rem", border: `1px solid #d5d8df`, ...props.styles }}>
          <ContentLoader
            viewBox={`0 0 ${width} ${cardHeight + space * 2}`}
            width={width}
            height={cardHeight + space * 2}
            primaryColor="#dbdbdb"
            secondaryColor="#ecebeb"
            {...props}
          >
            <rect x={padding} y={space} rx="5" ry="5" width={cardHeight} height={cardHeight} />
            <rect x="40" y={y} rx="0" ry="0" width="148.72" height="8" />
            <rect x="40" y={y2} rx="0" ry="0" width="89" height="2" />

            {props.showBorders && (
              <>
                <rect x="0" y={0} rx={borderRadius} ry={borderRadius} width={width} height={lineThickness} />
                <rect x="0" y={0} rx={borderRadius} ry="2" width={lineThickness} height={cardHeight + space} />
                <rect x="399" y={0} rx={borderRadius} ry={borderRadius} width={lineThickness} height={cardHeight + space} />
                <rect x="0" y={39} rx={borderRadius} ry={borderRadius} width={width} height={lineThickness} />
              </>
            )}

          </ContentLoader>
        </div>
      )
    }

    return list;
  }

  return (
    <div style={
      props.containerStyles ?
      {
        ...props.containerStyles,
        padding: `${padding}px`,
      } :
      { padding: `${padding}px` }
    }>
      {getLoaders()}
    </div>
  )

}

export default ListsLoader