import React, { useContext } from "react";
import "./userPosts.scss";

import GroupPostComponent from "components/group/groupPost";
import { ReactComponent as ArrowDown } from 'assets/img/arrow-down.svg'
import PostsFeedLoader from "components/contentLoaders/PostsFeedLoader";
import { UserProfileContext } from "../UserProfile";
import { UserService } from "api/services/users";

const UserPosts = (props: {
  userId: number
}) => {
  const { userId } = props
  const [isOpenAllPosts, setIsOpenAllPosts] = React.useState(true);

  const { groupData } = useContext(UserProfileContext)

  const [userPosts, setUserPosts] = React.useState<any>(null);
  const [userPostables, setUserPostables] = React.useState<any>(null);


  const getUserPosts = async () => {
    await UserService.getInstance()
      .getUserAuthoredPosts(groupData !== undefined && groupData, userId)
      .then((res: any) => {
        if (res && res.data) {
          setUserPosts(res.data);
        }
      });
  };

  const getUserPostables = async () => {
    await UserService.getInstance()
      .getUserAuthoredPostsPostables(groupData !== undefined && groupData, userId)
      .then((res: any) => {
        if (res) {
          setUserPostables(res);
        }
      });
  };

  React.useEffect(() => {
    getUserPostables()
    getUserPosts()
  }, [groupData]);

  return (
    <>
      <div className="user-profile-body-middle-posts">
        <span className="post-title">{userPosts && userPosts.length > 0 && 'Your posts'}</span>
      </div>
      <Dropdown
        options={userPostables}
        userId={userId}
        userPosts={userPosts}
        isOpenAllPosts={isOpenAllPosts}
        setIsOpenAllPosts={setIsOpenAllPosts}
        getUserPosts={getUserPosts}
        getUserPostables={getUserPostables}
      />
    </>
  );
};

export default UserPosts


interface IPost {
  post: any
  getUserPosts: any
  getUserPostables: any
}

const Post: React.FC<IPost> = ({
  post,
  getUserPosts,
  getUserPostables
}) => {
  const {
    refreshGroupInformations,
    groupData,
  } = useContext(UserProfileContext)

  return (
    <GroupPostComponent
      post={post}
      group={groupData !== undefined && groupData}
      newsFeedView
      comments={post.comments}
      user={groupData !== undefined && groupData.membership_status}
      onPostRequest={() => {
        refreshGroupInformations();
        getUserPosts()
        getUserPostables()
      }}
      refreshGroupPostsWithoutLoad={() => {
        refreshGroupInformations()
        getUserPosts()
        getUserPostables()
      }}
      onGroupRequest={() => {
        refreshGroupInformations()
        getUserPosts()
        getUserPostables()
      }}
    />
  )
}

interface IDropdown {
  options: any
  userId: number
  userPosts: any
  isOpenAllPosts: boolean
  setIsOpenAllPosts: any
  getUserPosts: any
  getUserPostables: any
}

const Dropdown: React.FC<IDropdown> = ({
  options, userId, userPosts, isOpenAllPosts, setIsOpenAllPosts, getUserPosts, getUserPostables
}) => {

  const [selectedOption, setSelectedOption] = React.useState<any>({
    type: '', postable: {}
  });
  const [selectcommunityID, setSelectcommunityID] = React.useState<number | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOptionClick = (type: string, option: any) => {
    setSelectedOption(
      {
        type: type,
        postable: option
      }
    );
    setIsOpenAllPosts(false)
  };

  const isLoading = React.useMemo(() => { return userPosts === null }, [userPosts]);

  const { groupData } = useContext(UserProfileContext)

  return (
    <>
      <div className="dropdown-wrapper">
        <div
          className="dropdown-menu-items"
          onMouseLeave={() => setIsOpen(false)}
        >
          {options && userPosts && userPosts.length > 0 &&
            <button
              className="dropdown-toggle"
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              Filter by
              <ArrowDown width={20} height={20} />
            </button>
          }
          {isOpen &&

            <div
              id='dropdown-menu-items-height'
              className={`dropdown-menu-items-name`}>

              {
                //  ALL POSTS 
                userPosts && userPosts.length > 0 ?
                  <li
                    className="dropdown-item"
                    onClick={() => setIsOpenAllPosts(true)}
                  >
                    All posts
                  </li>
                  :
                  <></>
              }

              {
                options && groupData !== undefined ? (
                  <>
                    {options.channels.length > 0 &&
                      <>
                        {options.channels.map((channel: any) => (
                          <li
                            key={channel.id}
                            className="dropdown-item"
                            onClick={() => handleOptionClick('channels', channel)}
                          >
                            {channel.name}
                          </li>
                        ))}
                      </>
                    }

                    {options.events.length > 0 &&
                      <>
                        {options.events.map((event: any) => (
                          <li
                            key={event.id}
                            className="dropdown-item"
                            onClick={() => handleOptionClick('events', event)}
                          >
                            {event.title}
                          </li>
                        ))}
                      </>
                    }

                    {options.videos.length > 0 &&
                      <>
                        {options.videos.map((video: any) => (
                          <li
                            key={video.id}
                            className="dropdown-item"
                            onClick={() => handleOptionClick('videos', video)}
                          >
                            {video.title}
                          </li>
                        ))
                        }
                      </>
                    }

                    {options.pdfs.length > 0 &&
                      <>
                        {options.pdfs.map((pdf: any) => (
                          <li
                            key={pdf.id}
                            className="dropdown-item"
                            onClick={() => handleOptionClick('pdfs', pdf)}
                          >
                            {pdf.title}
                          </li>
                        ))
                        }
                      </>
                    }

                    {options.embeds.length > 0 &&
                      <>
                        {
                          options && options.embeds.map((embed: any) => (
                            <li
                              key={embed.id}
                              className="dropdown-item"
                              onClick={() => handleOptionClick('embeds', embed)}
                            >
                              {embed.title}
                            </li>
                          ))
                        }
                      </>
                    }

                    {options.audios.length > 0 &&
                      <>
                        {
                          options && options.audios.map((audio: any) => (
                            <li
                              key={audio.id}
                              className="dropdown-item"
                              onClick={() => handleOptionClick('audios', audio)}
                            >
                              {audio.title}
                            </li>
                          ))
                        }
                      </>
                    }

                  </>
                )
                  :
                  <>
                    {
                      options.groups.length > 0 &&
                      <>
                        {options.groups.map((group: any) => (
                          <li
                            key={group.id}
                            className="dropdown-item"
                            onClick={() => {
                              setSelectcommunityID(group.id)
                              setIsOpenAllPosts(false)
                            }}
                          >
                            {group.name}
                          </li>
                        ))}
                      </>
                    }
                  </>
              }
            </div>
          }
        </div>

        {
          isLoading ?
            <div className="events-loader">
              <PostsFeedLoader
                padding={2}
                styles={{ background: "#FFFFFF", borderRadius: "6px" }}
              />
            </div>
            :
            isOpenAllPosts &&
            (
              userPosts.length > 0 ? userPosts.map((post: any) => (
                <li key={post.id} className='user-posts-container'>
                  <Post post={post} getUserPosts={getUserPosts} getUserPostables={getUserPostables} />
                </li>))
                :
                <span className="empty-payload-post">No authored posts yet.</span>
            )
        }

        {
          !isOpenAllPosts && selectedOption && selectcommunityID === null && (
            <div className="dropdown-selected-option">
              <UserPostableTypeDetailsComp
                postableId={selectedOption.postable.id}
                postableType={selectedOption.type}
                userId={userId}
                getUserPosts={getUserPosts}
                getUserPostables={getUserPostables}
              />
            </div>
          )
        }

        {
          selectcommunityID && (
            <div className="dropdown-selected-option">
              <UserCommunityPosts
                communityId={selectcommunityID}
                userId={userId}
              />
            </div>
          )
        }

      </div>
    </>
  );
}

interface IPostableTypeDetails {
  postableId: number
  postableType: string
  userId: number
  getUserPosts: any
  getUserPostables: any

}

const UserPostableTypeDetailsComp: React.FC<IPostableTypeDetails> = ({
  userId, postableId, postableType, getUserPosts
}) => {
  const { groupData } = useContext(UserProfileContext)
  const [userPostableTypeDetails, setUserPostableTypeDetails] = React.useState<any>(null);

  const getUserPostables = async () => {
    await UserService.getInstance()
      .getUserAuthoredPostsPostableTypeDetails(groupData, userId, postableId, postableType)
      .then((res: any) => {
        if (res && res.data) {
          setUserPostableTypeDetails(res.data);
        }
      });
  };

  React.useEffect(() => {
    getUserPostables()
  }, [postableId, postableType]);

  const isLoading = React.useMemo(() => {
    return userPostableTypeDetails === null
  }, [userPostableTypeDetails]);

  return (
    <>
      {
        isLoading ?
          <div className="events-loader">
            <PostsFeedLoader
              padding={2}
              styles={{ background: "#FFFFFF", borderRadius: "6px" }}
            />
          </div> :
          (userPostableTypeDetails && userPostableTypeDetails.map((userPostableTypeDetail: any) => (
            <li key={userPostableTypeDetail.id} className='user-posts-container'>
              <Post post={userPostableTypeDetail} getUserPosts={getUserPosts} getUserPostables={getUserPostables} />
            </li>
          )))
      }
    </>
  )
}

const UserCommunityPosts = (props: {
  communityId: number, userId: number
}) => {
  const [userCommunityPosts, setUserCommunityPosts] = React.useState<any>(null);

  const getUserCommunityPosts = async () => {
    await UserService.getInstance()
      .getUserCommunityPosts(props.userId, props.communityId)
      .then((res: any) => {
        if (res && res.data) {
          setUserCommunityPosts(res.data);
        }
      });
  };

  React.useEffect(() => {
    getUserCommunityPosts()
  }, [props.communityId]);

  const isLoading = React.useMemo(() => {
    return userCommunityPosts === null
  }, [userCommunityPosts]);

  return (
    <>
      {
        isLoading ?
          <div className="events-loader">
            <PostsFeedLoader
              padding={2}
              styles={{ background: "#FFFFFF", borderRadius: "6px" }}
            />
          </div> :
          (userCommunityPosts && userCommunityPosts.map((userCommunityPost: any) => (
            <li key={userCommunityPost.id} className='user-posts-container'>
              <GroupPostComponent
                post={userCommunityPost}
                group={userCommunityPost !== undefined && userCommunityPost.group}
                newsFeedView
                comments={userCommunityPost.comments}
                user={userCommunityPost !== undefined && userCommunityPost.group.membership_status}
              />
            </li>
          )))
      }
    </>
  )
}

