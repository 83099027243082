import React from 'react'

import './admin-popup.scss'
import { ReactComponent as XIcon } from "assets/img/close.svg"
import AdminMemberDetails from 'containers/AdminMemberDetails/AdminMemberDetails';
import { activeMemberIDAtom, memberDetailsProfilePopupAtom } from 'showMessageMenuAtom';
import { useAtom } from 'jotai';
import ReactModal from 'react-modal';

const AdminMemberDetailsPopUp = ({ group }: { group: any }) => {
  const [activeMemberID,] = useAtom(activeMemberIDAtom);
  const [isOpenmemberDetailsProfilePopup, setOpenmemberDetailsProfilePopup] = useAtom(memberDetailsProfilePopupAtom);

  return (
    <ReactModal
      isOpen={isOpenmemberDetailsProfilePopup}
      className='people-details-popup'
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 99999,
        }
      }}
    >
      <div className="people-details-popup-header">
        <span
          onClick={() => setOpenmemberDetailsProfilePopup(false)}
          className='icon'
          style={{
            display: 'flex',
            cursor: 'pointer',
            color: '#333',
            width: '24px',
            height: '24px',
          }}
        >

          <XIcon />
        </span>
      </div>
      <div className="people-details-popup-body" id="people-details-popup-body">
        <AdminMemberDetails
          group={group}
          activeMemberID={activeMemberID}
          key={activeMemberID}
          isPopUp={true}
        />
      </div>
    </ReactModal>
  )
}

export default AdminMemberDetailsPopUp