import React from "react";
import "./actions-dropdown.scss";
import "./actions-dropdown-responsive.scss";
import useComponentVisible from "../../../utils/visible/visible";
import { IActionsDropdownProps } from "./IActionsDropdownProps";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const ActionsDropdown: React.FC<IActionsDropdownProps> = props => {
  const {objectId, actions, isGroupChat} = props;

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  return (
    <div 
    className="header-settings dropdown-menu actions-dropdown-menu" 
    ref={ref}
    >
      <img 
      className="dots" src={require("../images/dots.svg")} 
      onClick={() => setIsComponentVisible(true)} 
      alt="..." 
      />
      {isComponentVisible && (
        <div className="group-dropdown dropdown member-actions-dropdown">
          <ul>
            {actions.map((action: any, index: any) => (
              <li className="btn btn-top-bar"
                onClick = {() => {
                  action.callback(objectId, isGroupChat)
                  setIsComponentVisible(false)
                }
                }
                key = {index}
              >
                {action.name}
              </li>
            ))}
          </ul>
      </div>
      )}
    </div>
  );
};

export default withRouter(
  connect(null, null)(ActionsDropdown as any) as any
) as any;
