import React, { FunctionComponent } from "react";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";

import "./NotificationComponent.scss";
import miscUtils from "../../utils/miscUtils";
// import appConfig from "../../appConfig";
import { NotificationService } from "../../api/services/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import PopOnMe from "elements/poponme";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

type NotificationProps = {
  notification: any;
  index: number;
  subdomain: any;
  group: any;
  history: any;
  updateNotification: any;
  topBarView: boolean;
  refreshNotificationsCountRef?: any;
  setDisableHidePopUp?: any;
  closeNotifications?: any;
};

const getReadablePostableType = (backendPostableType: string) => {
  switch(backendPostableType) {
    case "channels":
      return "channel";
    case "events":
      return "event";
    case "coursecontents":
      return "lesson";
    case "audios":
      return "audio";
    case "videos":
      return "video";
    case "embeds":
      return "embed";
    case "pdfs":
      return "PDF";
    default:
      return "";
  }
}

const getPostableMessage = (
  notificationActorData: any
) => {
  if(
    notificationActorData.postable_type &&
    notificationActorData.postable_name
  ) {
    return <>{" "} in <b>{notificationActorData.postable_name}</b>{" "}{getReadablePostableType(notificationActorData.postable_type)}</>
  }

  return "";
}


const notificationTemplate = (
  type: string, //notification.data.actor.type
  user: string, //notification.data.actor.name
  message: string, //notification.data.action
  slug: string, //notification.data.actor.group_slug
  group: string, //notification.data.actor.group_name
  post: number, //notification.data.actor.post
  postable_type: string, //notification.data.actor.postable_type
  course_name: string, //notification.data.actor.course_name
  channel_name: string, //notification.data.actor.channel_name
  notification: any,
) => {
  switch (type) {
    case "new_member_added":
      return (
        <>
          <b>{user}</b> {message}{" "}
          {/* <span>
            {group}
            <span className="dot">.</span>
          </span> */}
          <b>{group}</b>.
        </>
      );
    case "commented_on_post":
      if (message.split(" ").includes("follow")) {
        return (
          <>
            <b>{user}</b> {message}{getPostableMessage(notification.data.actor)}.<span />
            {
              notification.data.actor.short_content &&
              <div className="short-content">
                <span className="short-content-text">"{notification.data.actor.short_content}"</span>
              </div>
            }
          </>
        );
      }
      return (
        <>
          <b>{user}</b> {message}{" "}
          {/* <span>
            Post<span className="dot">.</span>
          </span> */}
          {`post`}{getPostableMessage(notification.data.actor)}.
          {
            notification.data.actor.short_content &&
            <div className="short-content">
              <span className="short-content-text">"{notification.data.actor.short_content}"</span>
            </div>
          }
        </>
      );

    case "liked_post":
      return (
        <>
          <b>{user}</b> {message}{" "}
          {/* <span>
            Post<span className="dot">.</span>
          </span> */}
          {`post`} {getPostableMessage(notification.data.actor)}.
        </>
      );
    case "mention":
      return (
        <>
          <b>{user}</b> {message} you in a{" "}
          {/* <span>
            Post<span className="dot">.</span>
          </span> */}
          {`post`}{getPostableMessage(notification.data.actor)}.
          {
            notification.data.actor.short_content &&
            <div className="short-content">
              <span className="short-content-text">"{notification.data.actor.short_content}"</span>
            </div>
          }
        </>
      );
    case "request_approved":
      return (
        <>
          {message}{" "}
          {/* <span>
            {group}
            <span className="dot">.</span>
          </span> */}
          <b>{group}</b>.
        </>
      );
    case "new_request_pending":
      return (
        <>
          <b>{user}</b> requested to join{" "}
          {/* <span>
            {group}
            <span className="dot">.</span>
          </span> */}
          <b>{group}</b>.
        </>
      );
    case "group_member_posted":
      if (postable_type && postable_type.includes("CourseContent")) {
        return (
          <>
            <b>{user}</b> posted in{" "}
            {/* <span>
              {course_name.substring(0, 50)}
              <span className="dot">.</span>
            </span> */}
            <b>{course_name.substring(0, 50)}</b>.
            {
              notification.data.actor.short_content &&
              <div className="short-content">
                <span className="short-content-text">"{notification.data.actor.short_content}"</span>
              </div>
            }
          </>
        );
      }
      return (
        <>
          <b>{user}</b> posted {getPostableMessage(notification.data.actor)}.
          {
            notification.data.actor.short_content &&
            <div className="short-content">
              <span className="short-content-text">"{notification.data.actor.short_content}"</span>
            </div>
          }
        </>
      );
    case "group_member_video_posted":
      return (
        <>
          Your video post {getPostableMessage(notification.data.actor)} is ready
          {/*<span>*/}
          {/*  {group}*/}
          {/*  <span className="dot">.</span>*/}
          {/*</span>*/}
        </>
      );
    case "new_video_course_content":
      return (
        <>
          Your video for the course <b>{course_name}</b> is ready
        </>
      );
    case "post_broadcast":
      return (
        <>
          <b>{user}</b> shared a{" "}
          {/* <span>
            Post<span className="dot">.</span>
          </span> */}
          {`post`} {getPostableMessage(notification.data.actor)}.
        </>
      );
    case "post_moderation":
      return (
        <>
          A post shared in{" "}
          <b>{group}</b>'s {" "}
          {
          postable_type === "channels" ?
          `channel ` :
          `course `
        }
          <b className="notification-postable-name">{postable_type === "channels" ? channel_name : course_name}</b> needs review
        </>
      );
    case "moderated_comment":
      return (
        <>
        A comment shared in{" "}
        <b>{group}</b>'s {" "} 
        {
          course_name === "" ?
          "channel ":
          "course "
        }
        <b>{channel_name === "" ? course_name : channel_name}</b> has been flagged
        </>
      )
    case "submit_assignment":
      return (
        <>
          <b>{notification.data.actor.name}</b>{" "}submitted{" "}<b>{notification.data.actor.postable_name}</b>.
          {
            notification.data.actor.short_content &&
            <div className="short-content">
              <span className="short-content-text">"{notification.data.actor.short_content}"</span>
            </div>
          }
        </>
      )
    case "comment_on_assignment":
      return (
        <>
          <b>{notification.data.actor.name}</b>{" "}commented on{" "}<b>{notification.data.actor.postable_name}</b>.
          {
            notification.data.actor.short_content &&
            <div className="short-content">
              <span className="short-content-text">"{notification.data.actor.short_content}"</span>
            </div>
          }
        </>
      )
  }
};

const NotificationComponent: FunctionComponent<NotificationProps> = ({
  notification,
  index,
  subdomain,
  group,
  history,
  updateNotification,
  topBarView,
  refreshNotificationsCountRef,
  setDisableHidePopUp,
  closeNotifications
}) => {

  const routerHistory = useHistory();

  const [isOptionsVisible, setIsOptionsVisible] = React.useState(false);
  const notificationClasses = `notification-item flex flex-align-center ${
    !notification.read_at ? "active-notification" : "not-active-notification"
  }`;

  function redirect() {
    switch (notification.data.actor.type) {
      case "new_member_added":
        // if (group) {
        //   window.location.assign(miscUtils.getRedirectBaseUrl(group));
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}`
        //   );
        // }
        routerHistory.push(`/`);
        break;
      case "mention":
        // if (group) {
        //   window.location.assign(
        //     `${miscUtils.getRedirectBaseUrl(group)}/posts/${notification.data.actor.post}`
        //   );
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/posts/${notification.data.actor.post}`
        //   );
        // }
        routerHistory.push(`/posts/${notification.data.actor.post}`);
        break;
      // case "commented_on_post":
      case "submit_assignment":
        // if (group) {
        //   window.location.assign(
        //     `${miscUtils.getRedirectBaseUrl(group)}/posts/${notification.data.actor.post}`
        //   );
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/posts/${notification.data.actor.post}`
        //   );
        // }
        routerHistory.push(`/posts/${notification.data.actor.post}`);
        break;
      case "commented_on_post":
      case "comment_on_assignment":
        if (group) {
          // direct link to a comment
          if (notification.data.actor.comment_id !== null && notification.data.actor.comment_id !== undefined) {
            // window.location.assign(
            //   `${miscUtils.getRedirectBaseUrl(group)}/posts/${notification.data.actor.post}?include=postable&commentId=${notification.data.actor.comment_id}`
            // );
            routerHistory.push(`/posts/${notification.data.actor.post}?include=postable&commentId=${notification.data.actor.comment_id}`);
            return
          }
          // window.location.assign(
          //   `${miscUtils.getRedirectBaseUrl(group)}/posts/${notification.data.actor.post}`
          // );
          routerHistory.push(`/posts/${notification.data.actor.post}`);
        } else {
          // direct link to a comment
          if (notification.data.actor.comment_id !== null && notification.data.actor.comment_id !== undefined) {
            // window.location.assign(
            //   `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/posts/${notification.data.actor.post}?include=postable&commentId=${notification.data.actor.comment_id}`
            // );
            routerHistory.push(`/posts/${notification.data.actor.post}?include=postable&commentId=${notification.data.actor.comment_id}`);
            return
          }
          // window.location.assign(
          //   `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/posts/${notification.data.actor.post}`
          // );
          routerHistory.push(`/posts/${notification.data.actor.post}`);
        }
        break;
      case "liked_post":
        // if (group) {
        //   window.location.assign(
        //     `${miscUtils.getRedirectBaseUrl(group)}/posts/${notification.data.actor.post}`
        //   );
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/posts/${notification.data.actor.post}`
        //   );
        // }
        routerHistory.push(`/posts/${notification.data.actor.post}`);
        break;
      case "group_member_video_posted":
        // if (group) {
        //   window.location.assign(
        //     `${miscUtils.getRedirectBaseUrl(group)}/posts/${notification.data.actor.post}`
        //   );
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/posts/${notification.data.actor.post}`
        //   );
        // }
        routerHistory.push(`/posts/${notification.data.actor.post}`);
        break;
      case "request_approved":
        // if (group) {
        //   window.location.assign(miscUtils.getRedirectBaseUrl(group));
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}`
        //   );
        // }
        routerHistory.push(`/`);
        break;
      case "joined":
        // if (group) {
        //   window.location.assign(miscUtils.getRedirectBaseUrl(group));
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}`
        //   );
        // }
        routerHistory.push(`/`);
        break;
      case "new_request_pending":
        // if (group) {
        //   window.location.assign(
        //     `${miscUtils.getRedirectBaseUrl(group)}/settings/moderation?tab=requests`
        //   );
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/settings/moderation?tab=requests`
        //   );
        // }
        routerHistory.push(`/settings/moderation?tab=requests`);
        break;
      case "group_member_posted":
      case "post_broadcast":
        // if (group) {
        //   window.location.assign(`${miscUtils.getRedirectBaseUrl(group)}/posts/${notification.data.actor.post}`);
        // } else {
        //   window.location.assign(
        //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/posts/${notification.data.actor.post}`
        //   );
        // }
        routerHistory.push(`/posts/${notification.data.actor.post}`);
        break;
        case "post_moderation":
        case "moderated_comment":
          // if (group) {
          //   window.location.assign(`${miscUtils.getRedirectBaseUrl(group)}/settings/moderation`);
          // } else {
          //   window.location.assign(
          //     `${appConfig.domain.protocol}://${notification.data.actor.group_slug}.${appConfig.domain.baseDomain}/settings/moderation`
          //   );
          // }
          routerHistory.push(`/settings/moderation`);
          break;
    }
  }

  function getRedirectUrl() {
    switch (notification.data.actor.type) {
      case "new_member_added":
      case "request_approved":
      case "joined":
        return '/';
      case "commented_on_post":
      case "comment_on_assignment":
        if (group) {
          // direct link to a comment
          if (notification.data.actor.comment_id !== null && notification.data.actor.comment_id !== undefined) {
            return `/posts/${notification.data.actor.post}?include=postable&commentId=${notification.data.actor.comment_id}`;
          }
          return `/posts/${notification.data.actor.post}`;
        } else {
          // direct link to a comment
          if (notification.data.actor.comment_id !== null && notification.data.actor.comment_id !== undefined) {
            return `/posts/${notification.data.actor.post}?include=postable&commentId=${notification.data.actor.comment_id}`;
          }
          return `/posts/${notification.data.actor.post}`;
        }
      case "mention":
      case "submit_assignment":
      case "liked_post":
      case "group_member_video_posted":
      case "group_member_posted":
      case "post_broadcast":
        return `/posts/${notification.data.actor.post}`;
      case "new_request_pending":
        return `/settings/moderation?tab=requests`;

      case "post_moderation":
      case "moderated_comment":
          return `/settings/moderation`;
      default:
        return '#';
    }
  }

  function markAsRead(notificationId: any){
    NotificationService.getInstance()
      .readSingleNotifications(notificationId)
      .then(response => {
        updateNotification({
          ...notification,
          read_at: true,
        });
        
        if(topBarView && closeNotifications) {
          closeNotifications();
        }
        redirect();
      })
  }

  const generateNotificationActions = (): Array<{callback: Function, label: string}> => {
    return [
      {
        label: notification.read_at? 'Mark as unread' : 'Mark as read',
        callback: () => {
          setTimeout(() => {
            setIsOptionsVisible(false);
          }, 500);

          if(!notification.read_at) {
            NotificationService.getInstance()
            .readSingleNotifications(notification.id)
            .then(() => {
              updateNotification({
                ...notification,
                read_at: true,
              });

              if(refreshNotificationsCountRef && refreshNotificationsCountRef.current) {
                refreshNotificationsCountRef.current(-1);
              }
            })
          } else {
            NotificationService.getInstance()
            .unreadSingleNofitication(notification.id)
            .then(() => {
              updateNotification({
                ...notification,
                read_at: false,
              });

              if(refreshNotificationsCountRef && refreshNotificationsCountRef.current) {
                refreshNotificationsCountRef.current(1);
              }
            })
          }
        }
      }
    ]
  }

  return (
    <Link
    to={getRedirectUrl()}
    onClick={() => {
      markAsRead(notification.id)
    }}
    key={index}
    >
    <div
      className={`${notificationClasses} ${'notification-container'}`}
      onClick={() => {
        markAsRead(notification.id)
      }}
    >
      {/* {
        !topBarView && */}
        <PopOnMe
        actions={generateNotificationActions()}
        onClick={(event: any) => {
          event.preventDefault();
          setIsOptionsVisible(true);

          if(topBarView && setDisableHidePopUp) {
            setDisableHidePopUp(true);
          }
        }}
        onClickOutside={() => {
          setTimeout(() => {
            setIsOptionsVisible(false);
          }, 500);
        }}
        >
          <span 
          className={`notification-options ${topBarView ? 'topbarview' : ''} ${isOptionsVisible && 'notification-options--visible'}`}
          >
            <FontAwesomeIcon
            icon={faEllipsisH}
            />
          </span>
        </PopOnMe>
      {/* } */}
      <div className="notification-item--image">
        {notification.data.actor.profile_image ? (
          <img
            alt={""}
            className="profile-image"
            src={notification.data.actor.profile_image}
          />
        ) : (
          <div
            className="profile-image"
            data-color={
              notification &&
              miscUtils.getColorInitial(
                `${notification.data.actor.name ||
                  notification.data.actor.full_name}`
              )
            }
          >
            {notification &&
              miscUtils.getColorInitial(
                notification.data.actor.name ||
                  notification.data.actor.full_name
              )}
          </div>
        )}
      </div>
      <div className="notification-item--message">
        <span className="notification-item--message__content">
          {notificationTemplate(
            notification.data.actor.type,
            notification.data.actor.name,
            notification.data.action,
            notification.data.actor.group_slug,
            notification.data.actor.group_name,
            notification.data.actor.post,
            notification.data.actor.postable_type,
            notification.data.actor.course_name
              ? notification.data.actor.course_name
              : "",
            notification.data.actor.channel_name 
              ? notification.data.actor.channel_name
              : "",
            notification
          )}
        </span>
        <Moment utc={true} fromNow={true}>
          {notification.created_at}
        </Moment>
      </div>
    </div>
    </Link>
  );
};

export default withRouter(NotificationComponent as React.ComponentType<any>);
