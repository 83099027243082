import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import moment from 'moment-timezone';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { toast, ToastType } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import anchorme from "anchorme";
import PerfectScrollbar from 'react-perfect-scrollbar';

import "./chatroom.scss";
import "./chatroom-responsive.scss";
import { IChatroomProps } from "./IChatroomProps";
import { MessagingService } from "../../../api/services/messaging";
import ConfirmModal from "../ConfirmModal";
import ActionsDropdown from "../ActionsDropdown";
import Modal from "elements/modal/Modal";
import UserComponent from "components/userComponent";
import GTable from "elements/table";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollSpinner from "components/scrollSpinner";
import Moment from "react-moment";
import ReadMore from "utils/readMore/readMore";
import { ChatMessagesLoader, MobileChatMesLoader } from "components/contentLoaders";
import miscUtils from "utils/miscUtils";

import "components/ProflieSlideout/ProfileSlideout.scss"
import { ReactComponent as CloseIcon } from "assets/img/close.svg";
import { ReactComponent as AddUserIcon } from "assets/img/add-user.svg";
import { ReactComponent as BackIcon } from "assets/img/arrow-left.svg";
import { ReactComponent as DeletedUserIcon } from "assets/img/deleted-user.svg";

import { chatsAtom, chatsIsUpdatedAtom, showActiveCHatAtom, showNewDirectMessageMenuAtom, showNewGroupMessageMenuAtom, showNewMessageStartupAtom } from "showMessageMenuAtom";
import { DirectChatsContext } from "containers/main/MainComponent";
import HeaderMemberDetails from "containers/AdminMemberDetails/HeaderMemberDetails/HeaderMemberDetails";
import SubscriptionMemberDetails from "containers/AdminMemberDetails/SubscriptionMemberDetails/SubscriptionMemberDetails";
import BioMemberDetails from "containers/AdminMemberDetails/BioMemberDetails/BioMemberDetails";
import CustomFieldsMemberDetails from "containers/AdminMemberDetails/CustomFieldsMemberDetails/CustomFieldsMemberDetails";
import ReactHtmlParser from "react-html-parser"

const Chatroom: React.FC<IChatroomProps> = props => {
  const {
    group,
    toggleProfilesView, isShowProfiles,
    isGroupChat, setIsGroupChat, isChatAdmin,
    chatroom, setChatroom, isViewProfile, setIsViewProfile, isLoadingMessages,
    setIsLoadingMessages, userTimezone,
    theme, setIsCreateDirectchat,
    isCreateDirectchat, setIsUnauthorizedChatroom, isUnauthorizedChatroom, echo,
    setActiveChatroom,
  } = props;

  const [member, setMember] = useState<any>(null);
  const [messages, setMessages] = useState<any>({});
  const [formData, setFormData] = useState<any>({ message: "" });
  const [message, setMessage] = useState<any>({});
  const [messageKey, setMessageKey] = useState<string>("");
  const [isDeleteMessageModalOpen, setToggleDeletMessageModal] = useState<boolean>();
  const [isModalOpen, setToggleModal] = useState<boolean>();
  const [members, setMembers] = useState<any>([]);
  const [isAddMemberModalOpen, setToggleAddMemberModal] = useState<boolean>();
  const [searchQuery, setSearchQuery] = useState<any>(null);
  const [nonGroupMembers, setNonGroupMembers] = useState<any>({
    data: [],
    total: 0,
    next_page_url: "",
    current_page: 1
  });
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [isLeaveGroupchatModalOpen, setToggleLeaveGroupchatModal] = useState<boolean>();
  const [isChatroomFetched, setIsChatroomFetched] = useState<boolean>();
  const [isMessagesFetched, setIsMessagesFetched] = useState<boolean>();
  const [isLeavingGroupChat, setIsLeavingGroupChat] = useState<boolean>(false)
  const [addMemberModalKey, setAddMemberModalKey] = useState<any>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { getCommunityUnread, membersRef, getDirectChats } = React.useContext(DirectChatsContext)

  const [activeChatroomMessage, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);
  const [, setChatIdUpdated] = useAtom(chatsIsUpdatedAtom);
  const [directChats, setDirectChats] = useAtom(chatsAtom) as any;
  const [showNewMessageStartUpText, setNewMessageStartUp] = useAtom(showNewMessageStartupAtom);

  let chatId: any = activeChatroomMessage;
  const messagesRef = useRef({
    per_page: 15,
    current_page: 1,
    next_page_url: "",
    data: [],
    total: 0
  });
  const [totalMessages, setTotalMessages] = useState<any>(0);

  const chatLimitRef = useRef(null);
  const selectionRef = useRef([]);

  useEffect(() => {
    const focusOutCallback = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }

    const sendMessageInput: HTMLElement = document.getElementById("send-message-textarea") as HTMLElement;
    sendMessageInput.addEventListener("focusout", focusOutCallback);
    return (() => sendMessageInput.removeEventListener("focusout", focusOutCallback));
  }, [])

  const getMemberCanCHat = () => {
    const groupMembership = group && group.user_membership && group.user_membership.id

    return chatroom && Object.keys(chatroom).length > 0 && chatroom.members.find((chatMember: any) => {
      if (
        chatMember.community_member &&
        (chatMember.community_member.user !== null && chatMember.community_member.user !== undefined) &&
        (chatMember.community_member && chatMember.community_member.id !== groupMembership) &&
        chatMember.community_member.can_chat
      ) {
        return true;
      }
    });
  }

  useEffect(() => {
    if (Object.keys(chatroom).length > 0) {
      setIsGroupChat(true);

      setIsChatroomFetched(true);
      setChatroomMembers(chatroom);

      if (!isMessagesFetched && !isCreateDirectchat) {
        fetchMessages(chatroom);
        setIsLoadingMessages(true);
      }

      if (isGroupChat) {
        setIsViewProfile(false);
      }
    }
    if (chatroom.id) {
      if (chatId !== "temp" && !isUnauthorizedChatroom) {
        setActiveChatroom(chatroom.id);
        localStorage.setItem("isShowChatroom", "yes")
        localStorage.setItem("activeChatroomId", chatroom.id);
      }

      const event = '.chatMessage_' + chatroom.id;
      if (echo) {
        echo.channel('chat-room')
          .listen(event, (message: any) => {
            message = message.message;

            let aciveChatId = localStorage.getItem("activeChatroomId");

            if (group.user_membership.user.id !== message.author.id && aciveChatId == message.chat_room_id) {
              fetchMessages();
            }
          })
          .listen(".messageReaction_" + chatroom.id, (message: any) => {
            updateLikeCount(messagesRef.current, message);
          })
          .listen(".messageDeleted_" + chatroom.id, (response: any) => {
            removeDeletedMessage(messagesRef.current, response.messageId)
          });
      }

      updateUnreadMessagesCount();

      getMemberCanCHat()
    }
  }, [chatroom])


  function updateUnreadMessagesCount() {
    let _chats: any = directChats && directChats.data
    const { chatrooms } = directChats

    _chats && _chats.forEach((chat: any, index: any) => {
      if (chat.id === chatroom.id) {
        chat.unread_messages_count = 0;
        setDirectChats({
          data: _chats,
          chatrooms
        })
        getCommunityUnread();
      }
    })
  }

  useEffect(() => {
    messagesRef.current = {
      per_page: 15,
      current_page: 1,
      next_page_url: "",
      data: [],
      total: 0
    };
    if (chatId !== "temp") {
      if (group && Object.keys(chatroom).length > 0 && !isUnauthorizedChatroom) {
        fetchMessages(chatroom);
        setIsLoadingMessages(true);
      }
    } else if (chatId === "temp" && isCreateDirectchat) {
      setIsLoadingMessages(false);
      setIsGroupChat(false);
      setIsUnauthorizedChatroom(false);
    } else {
      setIsUnauthorizedChatroom(true);
    }
  }, [chatId])

  useEffect(() => {
    if (group && !isChatroomFetched && !isCreateDirectchat) {
      chatLimitRef.current = group.active_subscription.plan.max_group_chat_size;

      setIsChatroomFetched(true);
      fetchChatroom(chatId);
    }
  }, [group])

  useEffect(() => {
    const timeOutId = setTimeout(() => handleConfirmSearch(), 1500);
    return () => clearTimeout(timeOutId);
  }, [searchQuery]);

  function handleConfirmSearch() {
    if (group && searchQuery !== null) {
      fetchNonGroupMembers();
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      lockBackground();
    }
  }, [isModalOpen]);

  function updateLikeCount(_messages: any, _message: any) {
    setMessages({
      data: _messages.data,
      total: _messages.total,
      next_page_url: "",
      current_page: 1,
      per_page: 1
    });
    Object.keys(_messages.data).forEach((key: any) => {
      _messages.data[key].forEach((keyMessage: any, index: any) => {
        if (keyMessage.id === _message.message.id) {
          _messages.data[key][index].reaction_like_count = _message.reactionCount;
        }
      })
    })
    messagesRef.current.total = _messages.total;
    messagesRef.current.data = _messages.data;
    setMessages(messagesRef.current);
  }

  function removeDeletedMessage(_messages: any, messageId: any) {
    setMessages({
      data: _messages.data,
      total: _messages.total,
      next_page_url: "",
      current_page: 1,
      per_page: 1
    });
    Object.keys(_messages.data).forEach((key: any) => {
      _messages.data[key].forEach((keyMessage: any, index: any) => {
        if (keyMessage.id === messageId) {
          _messages.data[key].splice(index, 1);
        }
      })
    })
    messagesRef.current.total = _messages.total - 1;
    messagesRef.current.data = _messages.data;
    setMessages(messagesRef.current);
  }

  function fetchChatroom(chatId: any) {
    if (group) {
      MessagingService.getInstance()
        .getChatroom(group.id, chatId)
        .then((_chatroom: any) => {
          setChatroom(_chatroom);
          setChatroomMembers(_chatroom);
          if (_chatroom.type === 'group') {
            membersRef.current = {
              'chatroomID': _chatroom.id,
              'members_count': _chatroom.members_count
            };
          }

        }).catch((error: any) => {
          setIsUnauthorizedChatroom(true);
        }).finally(() => {
          setIsChatroomFetched(false)
        })
    }
  }

  function setChatroomMembers(_chatroom: any) {
    if (!isUnauthorizedChatroom) {
      const newId = _chatroom ? _chatroom.id : chatId
      if (_chatroom.type === "group") {
        MessagingService.getInstance()
          .getChatroomMembers(group.id, newId)
          .then((_members: any) => {
            setMembers(_members.data);
            if (_chatroom.type === 'group') {
              membersRef.current = {
                'chatroomID': newId,
                'members_count': _members.data.length
              };
            }
          }).catch((error: any) => {
            setIsUnauthorizedChatroom(true);
          })
      } else {
        setIsGroupChat(false);
        getChattingWithMember(_chatroom.members);
      }
    } else if (isUnauthorizedChatroom && isCreateDirectchat) {
      setIsGroupChat(false);
      getChattingWithMember(_chatroom.members);
    }
  }

  function getChattingWithMember(members: any) {
    const _member = members.filter((member: any) => {
      return ((member.community_member && member.community_member.id) !== (group && group.user_membership && group.user_membership.id));
    });
    setMember(_member[0]);
  }

  function sendMessage() {
    if (group && group.messaging_allowed && group.user_membership && group.user_membership.can_chat) {
      if (isCreateDirectchat) {
        setIsSending(true)
        MessagingService.getInstance()
          .createChat(
            group.id,
            "individual",
            "",
            "",
            [chatroom.member_id]
          )
          .then((response: any) => {
            chatId = response.data.id;
            setChatroom(response.data);
            addToDirectChats(response.data);
            submitMessage();
            setActiveChatroom(chatId);
            localStorage.setItem("isShowChatroom", "yes")
            setNewMessageStartUp(false)
            // setChatIdUpdated(true)
          }).finally(() => {
            // setChatIdUpdated(false)

            setIsSending(false)
          })
      } else {
        submitMessage();
      }
    }
    else {
      toast("Messaging has been disabled.", {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return;
    }
  }

  function submitMessage() {
    if (formData.message && group) {
      setIsSending(true)
      setChatIdUpdated(true)
      MessagingService.getInstance()
        .sendMessageInChatroom(group.id, chatId, formData.message)
        .then((message: any) => {
          setFormData({ ...formData, message: "" });
          if (isCreateDirectchat) {
            setIsCreateDirectchat(false);
            setActiveChatroomMessage(chatId)
          } else {
            addNewMessage(message.data);
          }
        }).finally(() => {
          setIsSending(false)
          setChatIdUpdated(false)
        })
    }
  }

  function addToDirectChats(chat: any) {
    const { chatrooms } = directChats
    setDirectChats({
      chatrooms,
      data: [chat, ...directChats.data]
    })
  }

  function addNewMessage(message: any) {
    let _messages = messages;
    if (!messages.data) {
      _messages = messagesRef.current;

      setMessages(_messages);
    } else {
      messagesRef.current = messages;
    }

    const messageDate = message.created_at.split(" ")[0];
    let params: any = {
      isKeyExists: false,
      key: ""
    };
    Object.keys(_messages.data).every(key => {
      if (key === messageDate) {
        params.isKeyExists = true;
        params.key = key;
        return false;
      } else {
        params.isKeyExists = false;
        params.key = key;
        return true;
      }
    });

    addToMessages(params.key, messageDate, message, params.isKeyExists);
    messagesRef.current.total = messagesRef.current.total + 1;
    setMessages(messagesRef.current);
    scrollToView();
  }

  function fetchMessages(_chatroom?: any) {
    if (!isUnauthorizedChatroom) {
      const today = moment.tz(moment(), userTimezone);
      const startDate = today.utc().format("YYYY-MM-DD hh:mm:ss");

      let endDate: any;
      if (!_chatroom && chatroom) {
        endDate = group.created_at;
      } else {
        endDate = chatroom.created_at;
      }

      let per_page: any = 20;
      if (window.screen.height > 1000) {
        per_page = 40;
      }
      if (window.screen.height > 1500) {
        per_page = 60;
      }

      const newId = _chatroom ? _chatroom.id : chatId

      if (group) {
        MessagingService.getInstance()
          .getChatroomMessages(group.id, newId, 1, per_page, startDate.toString(), endDate)
          .then((_messages: any) => {
            setMessages(_messages);
            setIsLoadingMessages(false);
            setIsMessagesFetched(true);
            messagesRef.current = _messages;
            setTotalMessages(_messages.to);
            scrollToView();
          }).catch((error: any) => {
          }).finally(() => setIsMessagesFetched(false))
      }
    }
  }

  function loadMoreMessages() {
    return MessagingService.getInstance()
      .getChatroomMessages(group.id, chatId, messages.current_page + 1, messages.per_page, messages.from_date, messages.end_date)
      .then((_messages: any) => {

        setTotalMessages(_messages.to);
        messagesRef.current = messages;

        Object.keys(_messages.data).forEach(newMessageKey => (
          Object.keys(messages.data).forEach((key: any) => (
            key === newMessageKey ?
              addToMessages(key, newMessageKey, _messages.data[newMessageKey], true)
              :
              addToMessages(key, newMessageKey, _messages.data[newMessageKey], false)
          ))
        ))
        let newMessagesList = messagesRef.current;
        newMessagesList.next_page_url = _messages.next_page_url;
        newMessagesList.per_page = _messages.per_page;
        newMessagesList.current_page = _messages.current_page;
        newMessagesList.total = _messages.total;

        setMessages(newMessagesList);
        messagesRef.current = newMessagesList;
      })
  }

  function scrollToView() {
    const messageElems = document.querySelectorAll(".messages-content-body-messages-single");
    const elemsLength = messageElems.length;

    let lastMessageIndex: any;

    const _messages: any = messagesRef.current.data;
    Object.keys(_messages).forEach((key: any, index: any) => {
      if (index === 0) {
        lastMessageIndex = _messages[key].length - 1;
      }
    })

    const lastMessageElem = elemsLength < 1 ? "" : messageElems[lastMessageIndex];
    if (lastMessageElem) {
      lastMessageElem.scrollIntoView(false);
    }
  }

  function addToMessages(messagesKey: any, newMessageKey: any, fetchedMessages: any, isKeyExists: any) {
    if (isKeyExists) {
      const _messages: any = { ...messagesRef.current };
      if (Array.isArray(fetchedMessages)) {
        _messages.data[messagesKey] = [...fetchedMessages, ...messagesRef.current.data[messagesKey]];
      } else {
        _messages.data[messagesKey] = [...messagesRef.current.data[messagesKey], fetchedMessages];
      }
      messagesRef.current = _messages;
    } else {
      const _messages: any = { ...messagesRef.current };
      if (Array.isArray(fetchedMessages)) {
        _messages.data = { ...messagesRef.current.data, [newMessageKey]: fetchedMessages };
        messagesRef.current = _messages;
      } else {
        _messages.data = { [newMessageKey]: [fetchedMessages], ...messages.data };
        messagesRef.current = _messages;
      }
    }
  }

  function unlockBackground() {
    document.body.classList.remove("stop-scrolling");
  }

  function lockBackground() {
    document.body.classList.add("stop-scrolling");
  }

  function openDeleteMessageModal() {
    setToggleDeletMessageModal(true);
    lockBackground();
  }

  function closeDeleteMessageModal() {
    setToggleDeletMessageModal(false);
    unlockBackground();
  }

  function deleteMessage() {
    MessagingService.getInstance()
      .deleteMessageInChatroom(message.id)
      .then((response: any) => {

        toast("Message deleted successfully.", {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });

        const refreshedMessages: any = messages.data[messageKey].filter((_message: any) => message.id !== _message.id);
        messages.data[messageKey] = refreshedMessages;

        setMessages(messages);
        closeDeleteMessageModal()
        unlockBackground();
        setChatIdUpdated(true)
      }).finally(() => {
        setChatIdUpdated(false)
      })
  }

  const [isDeleteModalOpen, setToggleDeleteModal] = useState<boolean>();
  const [isDeletingChat, setIsDeletingChat] = useState<boolean>(false)

  const deleteGroupChatAndMessages = () => {
    if (isDeletingChat) {
      return
    }
    function deleteChat() {
      setIsDeletingChat(true)
      MessagingService.getInstance()
        .deleteChatroom(group.id, chatroom.id)
        .then((response: any) => {
          const refreshedChats = directChats.data.filter((_chatroom: any) => _chatroom.id !== chatroom.id) as any;
          const { chatrooms } = directChats
          setDirectChats({
            chatrooms,
            data: [...refreshedChats]
          })

          toast("Group Message deleted successfully.", {
            type: ToastType.SUCCESS,
            autoClose: 2000
          });

          const firstChatroomId: number = refreshedChats && refreshedChats[0].id
          fetchChatroom(firstChatroomId)
        })
        .finally(() => {
          unlockBackground();
          setToggleDeleteModal(false);
          setIsDeletingChat(false)
        })
    }
    deleteChat()
  }

  function openAddMemberModal() {
    lockBackground();
    setToggleAddMemberModal(true);
  }

  function closeAddMemberModal() {
    unlockBackground();
    setToggleAddMemberModal(false);
    setNonGroupMembers({
      data: [],
      total: 0,
      next_page_url: "",
      current_page: 1
    });
    setSelectedMembers([]);
    setSearchQuery(null);
    setAddMemberModalKey((Math.random() + 1).toString(36).substring(7));
  }

  function openModal(member: any) {
    setMember(member);
    setToggleModal(true);
  }

  function closeModal() {
    setToggleModal(false);
    unlockBackground();
    setToggleDeleteModal(false);
  }
  const [isGroupchatModal, setIsGroupchatModal] = useState<boolean>();

  function openLeaveGroupchatModal() {
    if (isGroupChat) {
      setIsGroupchatModal(true);
      setToggleLeaveGroupchatModal(true);
    }
    else {
      setToggleLeaveGroupchatModal(true);
    }
  }

  function closeLeaveGroupchatModal() {
    setToggleLeaveGroupchatModal(false);
    unlockBackground();
  }

  function addSelectedMembers() {
    const selectedMembersIds = new Set<number>();

    const selectedMembersIndexes: any = selectedMembers.map((index: any) => {
      return +index;
    });

    if (selectedMembersIndexes.length === 0) {
      return
    }

    nonGroupMembers.data.forEach((member: any, memberIndex: number) => {
      if (selectedMembersIndexes.includes(memberIndex)) {
        selectedMembersIds.add(+member.id);
      }
    });
    const groupChatLimit = group.active_subscription.plan.max_group_chat_size;

    const selectedMembersIdsArray = Array.from(selectedMembersIds); // Convert Set to an array

    if ((selectedMembers.length + members.length) > groupChatLimit) {
      toast("Only 10 members are allowed in a group chat.", {
        type: ToastType.WARNING,
        autoClose: 6000
      });
    } else {

      MessagingService.getInstance()
        .addGroupchatMember(group.id, chatId, selectedMembersIdsArray)
        .then((response: any) => {
          toast("Members added successfully.", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });

          const refreshedMembers: any = [...members, ...response.data];
          setMembers(refreshedMembers);
          membersRef.current = {
            'chatroomID': chatId,
            'members_count': refreshedMembers.length
          };

          closeAddMemberModal()
          setSelectedMembers([]);
          props.setIsMemberAddGroupChat(true)
        }).finally(() => {
          setIsButtonDisabled(false)
          props.setIsMemberAddGroupChat(false)
        })
    }
  }

  function viewProfile(_member: any) {
    setIsViewProfile(true);
    setMember(_member);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "M",
        columns: [
          {
            Header: "Community members",
            accessor: "user",
            Cell: (row: any) => {
              return (
                <span>
                  <UserComponent
                    hideAdmin={true}
                    user={row.cell.value}
                    profilePicture={row.cell.value.profile_image}
                  />
                </span>
              );
            }
          }
        ]
      },
      {
        id: "selection",
        Cell: ({ row }: any) => (
          <div>
            <input id={`checkbox_${row.index}`} type="checkbox" {...row.getToggleRowSelectedProps()} onClick={(e: any) => {
              const checkElem = document.getElementById("checkbox_" + row.index) as HTMLInputElement;

              if ((isAboveMembersLimit(chatLimitRef.current, selectionRef.current, membersRef.current.members_count) && checkElem.checked)) {
                e.preventDefault();
                return false;
              }
            }} />
          </div>
        )
      }
    ],
    []
  );

  function isAboveMembersLimit(chatLimit: any, totalSelected: any, totalMembers: any) {
    if ((totalSelected + totalMembers) >= chatLimit) {
      return true;
    } else {
      return false;
    }
  }

  const [notSearchFound, setNoSearchFound] = useState<any>()
  function fetchNonGroupMembers() {
    if (group) {
      MessagingService.getInstance()
        .getMembersNotInChatroom(group.id, chatId, false, searchQuery)
        .then((members: any) => {
          // const _nonGroupMembers = members.data.filter((_member: any) => (_member.id !== group.user_membership.id) && _member.active_subscription !== null);
          const _nonGroupMembers = members.data.filter((member: any) => {
            return (member.id !== group.user_membership.id
              && member.active_subscription !== null)
              || (member.role.name !== 'member');
          });
          if (searchQuery !== null && searchQuery !== ' ' && _nonGroupMembers.length === 0) {
            setNoSearchFound('No results found')
          }

          setNonGroupMembers({
            data: _nonGroupMembers,
            total: members.total,
            next_page_url: members.next_page_url,
            current_page: members.current_page
          });
        })
    }
  }

  const [isRemovingUser, setIsRemovingUser] = useState<boolean>(false)

  const removeMemberFromGroupChat = () => {
    if (isRemovingUser && isModalOpen) {
      return
    }
    else {
      setIsRemovingUser(true)
      MessagingService.getInstance()
        .removeGroupchatMember(group.id, chatId, member.community_member.id)
        .then((removedMember: any) => {

          toast("Member removed successfully.", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
          const refreshedMembers = members.filter((_member: any) => _member.id !== member.id);
          setMembers(refreshedMembers);
          membersRef.current = {
            'chatroomID': chatId,
            'members_count': refreshedMembers.length
          };
          closeModal()
          props.setIsMemberAddGroupChat(true)
        }).finally(() => {
          props.setIsMemberAddGroupChat(false)
          setIsRemovingUser(false)
        })
    }
  }

  const [, setShowNewDirectMessageMenu] = useAtom(showNewDirectMessageMenuAtom);

  const leaveGroupDirectChat = () => {
    if (isLeavingGroupChat) {
      return
    }
    function leaveGroupchat() {
      setIsLeavingGroupChat(true)
      let refreshedDirectChats: any
      MessagingService.getInstance()
        .leaveChatroom(group.id, chatId)
        .then((response: any) => {
          toast(`You have successfully ${isGroupChat ? 'left the group message.' : 'closed this direct message.'}`, {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });

          closeLeaveGroupchatModal();
          refreshedDirectChats = directChats.data.filter((chat: any) => chat.id !== chatroom.id);
          if (refreshedDirectChats.length === 0) {
            setShowNewDirectMessageMenu(true)
            setNewMessageStartUp(true);
            return
          }
          const { chatrooms } = directChats
          setDirectChats({
            chatrooms,
            data: [...refreshedDirectChats]
          })

          chatId = refreshedDirectChats && refreshedDirectChats[0].id
          fetchChatroom(chatId)
          setIsLeavingGroupChat(false)
          if (isGroupChat) {
            switchProfileViews()
            setIsGroupchatModal(false)
          }
          if (isViewProfile) {
            // switchProfileViews()
            setIsViewProfile(false);
          }
        }).finally(() => {
          getDirectChats()
        })
    }
    leaveGroupchat()
  }


  function getDateDiff(date: any) {
    const today = moment.tz(moment(), userTimezone);
    const messageDate = moment.tz(date.key, userTimezone);

    const days = today.diff(messageDate, "days");
    return days;
  }

  function isMessageAuthor(message: any) {
    if (group.user_membership.user.id === message.author.id) {
      return true;
    } else {
      return false;
    }
  }

  function addToSelection(selection: any) {
    const currentSelectionTotal = selectionRef.current ? selectionRef.current : 0;
    if (currentSelectionTotal < selection.length) {
      if (!isAboveMembersLimit(chatLimitRef.current, selectionRef.current, membersRef.current.members_count)) {
        setSelectedMembers(selection);
        selectionRef.current = selection.length;
      } else {
        selection.pop();
        if (group) {
          toast("Only 10 members are allowed in a group chat.", {
            type: ToastType.WARNING,
            autoClose: 6000
          });
          return
        }
      }
    } else {
      setSelectedMembers(selection);
      selectionRef.current = selection.length;
    }
  }

  function switchProfileViews() {
    toggleProfilesView();
    setIsViewProfile(false);
  }

  function toggleMessageLike(message: any, key: any, index: any) {
    if (message.liked) {
      unlikeMessage(message, key, index);
    } else {
      likeMessage(message, key, index);
    }
  }

  function likeMessage(message: any, key: any, index: any) {
    const _messages = messages;
    _messages.data[key][index].reaction_like_count ? _messages.data[key][index].reaction_like_count++ : _messages.data[key][index].reaction_like_count = 1;
    _messages.data[key][index].liked = true;

    setMessages(_messages);

    MessagingService.getInstance()
      .likeChatroomMessage(group.id, chatId, message.id, "like")
      .then((response: any) => {
        _messages.data[key][index].liked = true;
        setMessages(_messages);
      })
  }

  function unlikeMessage(message: any, key: any, index: any) {
    const _messages = messages;
    _messages.data[key][index].reaction_like_count ? _messages.data[key][index].reaction_like_count-- : _messages.data[key][index].reaction_like_count = 0;
    _messages.data[key][index].liked = false;

    setMessages(_messages);

    MessagingService.getInstance()
      .unlikeChatroomMessage(group.id, chatId, message.id, "like")
      .then((response: any) => {
        _messages.data[key][index].liked = false;
        setMessages(_messages);
      })
  }

  function scrollbarVisible(elementId: any) {
    const element = document.getElementById(elementId);

    if (element) {
      // @ts-ignore
      return element.scrollHeight > element.clientHeight;
    }
    return false;
  }

  const chatDisabled = group && group.messaging_allowed && group.user_membership && group.user_membership.can_chat
  const [, setShowNewGroupMessageMenu] = useAtom(showNewGroupMessageMenuAtom);

  function editGroupchat() {
    props.setIsEditGroupchat(true);
    props.setChat(chatroom);
    setActiveChatroomMessage(chatroom.id)
    setShowNewGroupMessageMenu(true)
  }

  function openDeleteModal() {
    setToggleDeleteModal(true);
    lockBackground();
  }

  const getChatStatus = () => {
    if (!isGroupChat && member &&
      (member.community_member.active_subscription === null && !miscUtils.isTeamMember(member.community_member))
    ) {
      return true
    }
    else {
      return false
    }
  }

  const chatroomPlaceholder = (chatDisabled === false) || !getMemberCanCHat() ? 'Messaging is disabled.' : !isGroupChat && member && ((member.community_member.active_subscription === null && !miscUtils.isTeamMember(member.community_member)) ? `${member.community_member.user.full_name} is inactive.` : 'Write message')
  return (
    <div className="messages-grouped flex">
      <div className="messages-content">
        {
          !isLoadingMessages &&
          <>
            {
              member &&
              member.community_member &&
              member.community_member.user &&
              !isGroupChat &&
              (
                <div className="messages-content-header messages-content-header-message">
                  <span
                    className="arrow-previous"
                    style={miscUtils.customThemeSet(props.theme) ? { color: props.theme.custom.primaryColor } : {}}
                    onClick={() => {
                      // removeCookieValue("isShowChatroom");
                      props.setIsShowChatroom(false);
                    }}
                  >
                    <BackIcon />
                  </span>
                  <UserComponent
                    hideAdmin={true}
                    user={member.community_member.user}
                    hideName={true}
                    profilePicture={member.community_member.user.profile_image}
                    showProfileSlider={false}
                    userMembership={member.community_member}
                  />

                  <div className="ind-action-separator">
                    <div className="messages-content-header-wrap">
                      <b>{member.community_member.user.full_name}</b>
                      <span
                        className="view-profile"
                        onClick={() => toggleProfilesView()}
                        style={miscUtils.customThemeSet(theme) ? { color: theme.custom.primaryColor } : {}}
                      >
                        View profile
                      </span>
                    </div>
                    <div className="chatroom-actions-dropdown">
                      {isChatAdmin(chatroom, group.user_membership.id) ?
                        <ActionsDropdown
                          objectId={chatroom}
                          actions={
                            [
                              {
                                "name": "Close",
                                "callback": openLeaveGroupchatModal
                              }
                            ]
                          }
                        /> :
                        <ActionsDropdown
                          objectId={chatroom}
                          actions={
                            [
                              {
                                "name": "Close",
                                "callback": openLeaveGroupchatModal
                              }
                            ]
                          }
                          isGroupChat={false}
                        />
                      }
                    </div>
                  </div>
                </div>
              )
            }
            {members.length > 0 && isGroupChat && (
              <div className="messages-content-header messages-content-header-message">
                <span
                  className="arrow-previous"
                  style={miscUtils.customThemeSet(props.theme) ? { color: props.theme.custom.primaryColor } : {}}
                  onClick={() => {
                    // removeCookieValue("isShowChatroom");
                    props.setIsShowChatroom(false);
                  }}
                >
                  <BackIcon />
                </span>
                <span
                  className={`messages-content-header-profiles ${isGroupChat ? "groupchat" : ""} ${isShowProfiles ? "active" : ""}`}
                  onClick={() => switchProfileViews()}
                >
                  {
                    chatroom.type === 'group' &&
                    <GroupProfileImages
                      members={members}
                      group={group}
                      chat={chatroom}
                    />
                  }
                </span>
                <div className="ind-action-separator">
                  <div className="messages-content-header-wrap">
                    <b style={{ fontSize: '14px' }}>{chatroom.title}</b>
                    {chatroom.description && (
                      chatroom.description.length > 100 ?
                        <ReadMore
                          text={chatroom.description}
                          numberOfLines={1}
                          lineHeight={1}
                          readMoreCharacterLimit={100}
                          showLessButton={true}
                          onContentChange={() => { }}
                        />
                        :
                        <span onClick={() => switchProfileViews()}>
                          {chatroom.description}
                        </span>
                    )}
                  </div>

                  <div className="chatroom-actions-dropdown">
                    {isChatAdmin(chatroom, group.user_membership.id) ?
                      <ActionsDropdown
                        objectId={chatroom}
                        actions={
                          [
                            {
                              "name": "Edit",
                              "callback": editGroupchat
                            },
                            {
                              "name": "Delete",
                              "callback": openDeleteModal
                            }
                          ]
                        }
                      /> :
                      <ActionsDropdown
                        objectId={chatroom}
                        actions={
                          [
                            {
                              "name": "Leave group",
                              "callback": openLeaveGroupchatModal
                            }
                          ]
                        }
                        isGroupChat={true}
                      />
                    }
                  </div>
                </div>
              </div>
            )}
          </>
        }
        <div
          className={`messages-content-body ${isGroupChat ? 'messages-content-body--group' : 'messages-content-body--direct'}`}
        >
          <div id="chatMessages"
            className={`messages-content-body-messages ${scrollbarVisible("chatMessages") ? "scrollbar-visible" : ""}`}
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >

            {isLoadingMessages || (directChats.length === 0) ?
              <>
                <div className="big-loading">
                  <ChatMessagesLoader />
                </div>
                <div className="loading">
                  <MobileChatMesLoader />
                </div>
              </>
              :
              directChats.data.length > 0 && messages.data && Object.keys(messages.data).length > 0 ?
                <InfiniteScroll
                  scrollableTarget={"chatMessages"}
                  style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  dataLength={totalMessages}
                  next={() => loadMoreMessages()}
                  hasMore={messages.next_page_url ? true : false}
                  inverse={true}
                  initialScrollY={10}
                  scrollThreshold={0.5}
                  // endMessage = {"No more messages"}
                  loader={<span><ScrollSpinner label={"Loading..."} /></span>}
                >
                  {Object.keys(messages.data).map((key: any, index: any) => (
                    <div key={index}>
                      {messages.data[key].length > 0 && (
                        <div className="messages-content-body-messages-header">
                          {getDateDiff({ key }) < 1 && (
                            <span>Today</span>
                          )}
                          {getDateDiff({ key }) === 1 && (
                            <span>Yesterday</span>
                          )}
                          {getDateDiff({ key }) > 1 && (
                            <span><Moment format="dddd, MMM DD" tz={userTimezone}>{key}</Moment></span>
                          )}
                        </div>
                      )}
                      {messages.data[key].map((message: any, index: any) => (
                        <div className="messages-content-body-messages-single" key={index}>
                          {message.author && (
                            <UserComponent
                              hideAdmin={true}
                              user={message.author}
                              hideName={true}
                              profilePicture={message.author.profile_image}
                            />
                          )}
                          <div className="messages-content-body-messages-single-wrap">
                            <div className="hover-buttons">
                              <img className="dots" src={require("../images/heart-2.svg")} alt="..." onClick={() => {
                                toggleMessageLike(message, key, index);
                              }} />
                              {isGroupChat ?
                                (isChatAdmin(chatroom, group.user_membership.id) || isMessageAuthor(message)) && (
                                  <img className="trash" src={require("../images/trash.svg")} alt="..."
                                    onClick={() => {
                                      setMessage(message);
                                      setMessageKey(key)
                                      openDeleteMessageModal()
                                    }}
                                  />
                                ) :
                                isMessageAuthor(message) && (
                                  <img className="trash" src={require("../images/trash.svg")} alt="..."
                                    onClick={() => {
                                      setMessage(message);
                                      setMessageKey(key)
                                      openDeleteMessageModal()
                                    }}
                                  />
                                )
                              }
                            </div>
                            <b>{message.author.full_name} <span><Moment utc={true} format="hh:mm A" tz={userTimezone}>{message.created_at}</Moment></span></b>
                            <p style={{ fontSize: '14px' }}>{ReactHtmlParser(anchorme(message.message))}</p>
                            {message.reaction_like_count > 0 && (
                              <span className="message-likes" onClick={() => {
                                toggleMessageLike(message, key, index);
                              }}><img className="dots" src={require("../images/heart-red.svg")} alt="..." /><span className={`${message.liked ? "message-liked" : ""}`}>{message.reaction_like_count}</span></span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </InfiniteScroll>
                :
                (showNewMessageStartUpText || directChats.data.length > 0) &&
                <div className="messages-content-body-messages-single no-chat-history">
                  {isGroupChat && (
                    <img className="group-chat" src={require("../images/chat.svg")} />
                  )}
                  {member && !isGroupChat && (
                    <UserComponent
                      hideAdmin={true}
                      user={member.community_member && (member.community_member.user)}
                      hideName={true}
                      profilePicture={member.community_member.user.profile_image}
                    />
                  )}
                  <div className="messages-content-body-messages-single-wrap">
                    <p>
                      {isGroupChat ?
                        <span>This is the beginning of your group message.</span>
                        :
                        <span>
                          This is the beginning of your direct message with <span className="chat-with">@{member && member.community_member.user.full_name}</span>.
                        </span>
                      }
                    </p>
                  </div>
                </div>
            }
          </div>
        </div>
        <div className="messages-input">
          <textarea
            className={chatroomPlaceholder === 'Write message' ? 'message-textarea' : ""}
            onChange={e => {
              e.preventDefault();
              const messageData = e.target.value
              if (messageData.trim() === '') {
                setFormData({ ...formData, message: '' })
              }
              else {
                setFormData({ ...formData, message: messageData })
              }
            }
            }
            value={formData.message}
            id="send-message-textarea"
            disabled={!chatDisabled || getChatStatus() || !getMemberCanCHat()}
            placeholder={!isLoadingMessages ? chatroomPlaceholder : ''}
          />
          <button
            className={`send-message-btn ${!formData.message ? "disabled" : ""}`}
            onClick={sendMessage}
            style={miscUtils.customThemeSet(theme) ? { backgroundColor: theme.custom.primaryColor } : {}}
            disabled={isSending || !chatDisabled || getChatStatus() || !getMemberCanCHat()}
          >
            {isSending ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
      {(member && !isGroupChat) && (
        <SingleChatMemberProfile
          member={member}
          toggleProfilesView={toggleProfilesView}
          setIsViewProfile={setIsViewProfile}
          isGroupChat={isGroupChat}
          theme={theme}
          group={group}
        />
      )}
      {isGroupChat && !isViewProfile && (
        <GroupChatProfilesSidebar
          members={members}
          openAddMemberModal={openAddMemberModal}
          closeAddMemberModal={closeAddMemberModal}
          fetchNonGroupMembers={fetchNonGroupMembers}
          ActionsDropdown={ActionsDropdown}
          openModal={openModal}
          viewProfile={viewProfile}
          toggleProfilesView={toggleProfilesView}
          chatroom={chatroom}
          isChatAdmin={isChatAdmin}
          setSearchQuery={setSearchQuery}
          openLeaveGroupchatModal={openLeaveGroupchatModal}
          leaveGroupchat={leaveGroupDirectChat}
          group={group}
          theme={theme}
        />
      )}
      {isGroupChat && isViewProfile && (
        <SingleChatMemberProfile
          member={member}
          toggleProfilesView={toggleProfilesView}
          setIsViewProfile={setIsViewProfile}
          isGroupChat={isGroupChat}
          theme={theme}
          directChats={directChats && directChats.data}
          group={group}
          setIsCreateDirectchat={setIsCreateDirectchat}
          setChatroom={setChatroom}
        />
      )}
      <ConfirmModal
        isModalOpen={isDeleteMessageModalOpen}
        closeModal={closeDeleteMessageModal}
        actionCallback={deleteMessage}
        modalSubTitle="Are you sure you want to delete this message? This action cannot be undone."
        modalTitle="Delete Message"
        actionBtn="Delete"
      />
      {member && (
        <ConfirmModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          actionCallback={removeMemberFromGroupChat}
          modalTitle={`Remove ${member.community_member.user ? member.community_member.user.full_name : ""}`}
          modalSubTitle="Are you sure you want to remove this member? This action cannot be undone."
          actionBtn={`${isRemovingUser ? "Removing..." : 'Remove'}`}
        />
      )}
      <ConfirmModal
        isModalOpen={isDeleteMessageModalOpen}
        closeModal={closeDeleteMessageModal}
        actionCallback={deleteMessage}
        modalSubTitle="Are you sure you want to delete this message? This action cannot be undone."
        modalTitle="Delete Message"
        actionBtn="Delete"
      />

      <ConfirmModal
        isModalOpen={isLeaveGroupchatModalOpen}
        closeModal={closeLeaveGroupchatModal}
        actionCallback={leaveGroupDirectChat}
        modalSubTitle={`Are you sure you want to ${isGroupchatModal ? "leave this group message? This action cannot be undone." : "close this direct message?"} `}
        modalTitle={`${isGroupchatModal ? `Leave group message (${chatroom.title})` : `Close direct message ${member && member.community_member ? `with ${member && member.community_member.user && member.community_member.user.full_name}` : ""}`}`}
        actionBtn={`${isGroupchatModal ? (isLeavingGroupChat ? "Leaving..." : "Leave") : (isLeavingGroupChat ? "Closing..." : "Close")}`}

      />
      <ConfirmModal
        isModalOpen={isDeleteModalOpen}
        closeModal={closeModal}
        actionCallback={deleteGroupChatAndMessages}
        modalTitle={`Delete Group Message`}
        modalSubTitle={`Are you sure you want to delete this group message? This action cannot be undone.`}
        actionBtn={`${isDeletingChat ? "Deleting.." : "Delete"}`}
      />
      {group && (
        <Modal isOpen={isAddMemberModalOpen} onRequestClose={closeAddMemberModal} size={"539px"}>
          <div className="messages-add-members-modal flex flex-direction-column">
            <div className="messages-add-members-modal-content confirmation-modal-content">
              <div className="messages-add-members-modal-content-header">
                <button
                  className={"modal__close"}
                  onClick={() => {
                    closeAddMemberModal();
                    setNoSearchFound(undefined)
                  }}
                >
                  <span className="close">
                    <CloseIcon />
                  </span>
                </button>
              </div>
              <p className="add-people">Add people</p>

              <div className="modal-form--wrap">
                <div className="form-group flex flex-direction-column">
                  <input
                    onChange={e => {
                      setSearchQuery(e.target.value);
                    }}
                    value={searchQuery ? searchQuery : ""}
                    name="channel_name"
                    type="text"
                    placeholder="Search by name"
                    aria-label="Full Name"
                    className="search-input"
                  />
                  <img src={require("../images/search.svg")} className="search-icon" />
                </div>

                <div className="form-group flex flex-direction-column">
                  <div
                    id={"scroll"}
                    className="members__scrollarea"
                  >
                    {
                      nonGroupMembers.data.length > 0 ?
                        <GTable
                          key={addMemberModalKey}
                          selectionChanged={(selection: any) => {
                            addToSelection(selection);
                          }}
                          classes={["modal-member"]}
                          columns={columns}
                          section={"invite_members"}
                          data={nonGroupMembers.data}
                          total={nonGroupMembers.total}
                          hideActions={true}
                          membersCounter={selectedMembers.length}
                          customCounter={`${selectedMembers.length + members.length} / ${group ? group.active_subscription.plan.max_group_chat_size : 0}`}
                          group={group}
                        />
                        :
                        <>
                          {
                            notSearchFound ?
                              <span className="loading-results">{notSearchFound}</span>
                              :
                              <span className="loading-mes">Loading community members...</span>
                          }
                        </>
                    }
                  </div>
                </div>

                <div className="form-group__action">
                  <div className="flex flex-justify-center" style={members.next_page_url ? { display: "flex" } : { display: "none" }}>
                    <span className="load-more">Load More...</span>
                  </div>

                  <div className={`flex flex-justify-center ${members.next_page_url ? "" : "adjust"}`}>
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        addSelectedMembers();
                        if (selectedMembers.length > 0) {
                          setIsButtonDisabled(true);
                        }
                      }}
                      disabled={isButtonDisabled}
                      className="btn btn-primary btn-xl-size"
                      style={miscUtils.customThemeSet(theme) ? { color: theme.custom.fontColor, backgroundColor: theme.custom.primaryColor, borderColor: theme.custom.primaryColor } : {}}
                    >
                      {
                        isButtonDisabled ? 'Adding members...' : 'Add member(s)'
                      }

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

function GroupChatProfilesSidebar(props: any) {

  const validMembers = props.members

  const InactiveMember = (_member: any) => {
    return (
      _member.community_member &&
      !_member.community_member.active_subscription &&
      !props.isChatAdmin(props.chatroom, _member.community_member.id) 
    )
  }

  return (
    validMembers && (
      <PerfectScrollbar className="messages-chat-members">
        <div className="messages-chat-members-header">
          <b>Members
            <span style={{ marginLeft: '4px' }}>({validMembers ? (validMembers.length) : 0})</span>
            <span
              className="close"
              onClick={() => props.toggleProfilesView()}
            >
              <CloseIcon />
            </span>
          </b>
        </div>
        <div className="messages-chat-members-body">
          <ul>
            {validMembers
              .sort((a: any, b: any) => props.isChatAdmin(props.chatroom, a.community_member.id) ? -1 : 1)
              .map((member: any, index: any) => (
                member.community_member.user && (
                  <li
                    key={member.id}
                    className="member-container"
                  >
                    <div
                      onClick={() => props.viewProfile(member)}
                      className={`member-details ${props.isChatAdmin(props.chatroom, member.community_member.id || InactiveMember(member)) ? "admin" : ""}`}>
                      <UserComponent
                        hideAdmin={true}
                        user={member.community_member.user}
                        profilePicture={member.community_member.user.profile_image}
                        showProfileSlider={false}
                      />
                    </div>
                    {props.isChatAdmin(props.chatroom, member.community_member.id) && (
                      <img src={require("../images/admin.svg")} />
                    )}
                    {InactiveMember(member) && member.community_member.role.name === 'member' && (
                      <span className="icon">
                        <DeletedUserIcon />
                      </span>
                    )}
                    {props.isChatAdmin(props.chatroom, props.group.user_membership.id) ?
                      <div>
                        <ActionsDropdown
                          objectId={member}
                          actions={
                            props.group.user_membership.id === member.community_member.id ?
                              [
                                {
                                  "name": "View profile",
                                  "callback": props.viewProfile
                                }
                              ] :
                              [
                                {
                                  "name": "Remove",
                                  "callback": props.openModal
                                },
                                {
                                  "name": "View profile",
                                  "callback": props.viewProfile
                                }
                              ]
                          }
                        />
                      </div>
                      :
                      <div>
                        <ActionsDropdown
                          objectId={member}
                          actions={
                            props.group.user_membership.id === member.community_member.id ?
                              [
                                {
                                  "name": "View Profile",
                                  "callback": props.viewProfile
                                },
                                {
                                  "name": "Leave",
                                  "callback": props.openLeaveGroupchatModal
                                }
                              ] :
                              [
                                {
                                  "name": "View Profile",
                                  "callback": props.viewProfile
                                }
                              ]
                          }
                        />
                      </div>

                    }
                  </li>
                )
              ))}
            <li>
              {props.isChatAdmin(props.chatroom, props.group.user_membership.id) ?
                <button
                  onClick={() => {
                    props.openAddMemberModal();
                    props.setSearchQuery("");
                  }}
                  className="user-icon-container"
                >
                  <span className="user-icon">
                    <AddUserIcon />
                  </span>
                  Add member
                </button>
                :
                <button
                  className="leave-group user-icon-container"
                  onClick={() => props.openLeaveGroupchatModal()}>
                  <img src={require("../images/trash.svg")} alt="..." />Leave group
                </button>
              }
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    )
  );
}

const GroupProfileImages = ({ members, group, chat }: { members: any, group: any, chat: any }) => {

  const { membersRef } = React.useContext(DirectChatsContext)

  const sortedMembers = chat.members.length > 0 && chat.members.sort((a: any, b: any) => a.id - b.id);
  const membersCount = membersRef.current !== null && chat.id === membersRef.current.chatroomID && membersRef.current.members_count

  return (
    <ul className="group-profile-pic">
      {sortedMembers.slice(0, 3).map((member: any, index: any) => (
        <li key={index}>
          <UserComponent
            key={chat.id}
            hideAdmin={true}
            user={member.community_member.user}
            hideName={true}
            profilePicture={member.community_member.user && member.community_member.user.profile_image}
            showProfileSlider={false}
          />
        </li>
      ))}
      {chat.members_count > 3 && (
        <li className="message-plus-members">
          <UserComponent
            key={chat.id}
            hideAdmin={true}
            user={chat.members[chat.members.length - 1].community_member.user}
            hideName={true}
            profilePicture={chat && chat.members && chat.members.length > 0 && chat.members[chat.members.length - 1].community_member && chat.members[chat.members.length - 1].community_member.user && chat.members[chat.members.length - 1].community_member.user.profile_image}
            showProfileSlider={false}
          />
          <span className="member-count">+{membersCount ? membersCount - 3 : chat.members_count - 3}</span>
        </li>
      )}
    </ul>
  )
}

function SingleChatMemberProfile(props: any) {

  // const ABOUT_ME_CHARACTER_LIMIT: number = 100;
  // let timeFormatter

  // function getTimeofMember() {
  //   let timeOptions: any = {
  //     timeZone: props.member && props.member.community_member && props.member.community_member.user.timezone && props.member.community_member.user.timezone.value,
  //     hour: 'numeric',
  //     minute: 'numeric',
  //   };
  //   const timeFormatter = new Intl.DateTimeFormat([], timeOptions).format(new Date());
  //   return timeFormatter;
  // }
  // if (props.member && props.member.community_member && props.member.community_member.user && props.member.community_member.user.timezone) {
  //   timeFormatter = getTimeofMember();
  // }

  // const [subscription, setSubscription] = React.useState<any>({
  //   name: "",
  //   amount: "",
  //   currency: "",
  //   interval: null,
  //   interval_count: null,
  //   status: null,
  //   next_billing_date: null,
  //   trial_period: null,
  //   coupon_code: null,
  // });

  // const getSubscription = (profile: any) => {

  //   //use either the active subscription, or the previous subscription
  //   if (profile.active_subscription) {
  //     setSubscription({
  //       ...subscription,
  //       name: profile.active_subscription.plan.name,
  //       currency: profile.active_subscription.plan.currency,
  //       interval: profile.active_subscription.plan.interval,
  //       interval_count: profile.active_subscription.plan.interval_count,
  //       amount: profile.active_subscription.plan.price,
  //       trial_period: profile.active_subscription.plan.trial_days ? profile.active_subscription.plan.trial_days : null,
  //       status: profile.subscription_status,
  //       next_billing_date: profile.subscription_status === "on grace" ? null : profile.active_subscription_end_date ? moment.utc(profile.active_subscription_end_date).format("MMMM Do, YYYY") : null,
  //       coupon_code: profile.active_subscription.coupon_code ? profile.active_subscription.coupon_code : null,
  //     });
  //   }

  //   if (profile.active_subscription === null && profile.previous_plan) {
  //     setSubscription({
  //       ...subscription,
  //       name: profile.previous_plan.name,
  //       currency: profile.previous_plan.currency,
  //       interval: profile.previous_plan.interval,
  //       interval_count: profile.previous_plan.interval_count,
  //       amount: profile.previous_plan.price,
  //       trial_period: profile.previous_plan.trial_days ? profile.previous_plan.trial_days : null,
  //       status: profile.subscription_status,
  //       next_billing_date: null,
  //     });
  //   }

  // }

  // React.useEffect(() => {
  //   if (props.member && props.member.community_member) {
  //     setSubscription(() => {
  //       return getSubscription(props.member.community_member);
  //     });
  //     getUserPurchases();
  //   }

  // }, [props.member && props.member.community_member]);

  // const getAnswerMarkup = (questionObject: any) => {
  //   if (questionObject.type === QuestionTypes.Checkbox) {
  //     return (
  //       <ul className="profile-slideout-content-screening-question-answer-list">
  //         {questionObject.answer.map((answer: string) => (
  //           <li>{answer}</li>
  //         ))}
  //       </ul>
  //     )
  //   }
  //   if (questionObject.type === QuestionTypes.Radio) {
  //     return (
  //       <ul className="profile-slideout-content-screening-question-answer-list">
  //         <li>{questionObject.answer}</li>
  //       </ul>
  //     )
  //   }

  //   return (
  //     <p className="profile-slideout-content-screening-question-answer-text">{`${questionObject.answer}`}</p>
  //   )
  // }

  // const formatBillingInterval = (): string => {
  //   if (subscription.interval === "one_time") {
  //     return "One time"
  //   }

  //   if (subscription.interval === "month") {
  //     switch (subscription.interval_count) {
  //       case 1:
  //         return "Monthly";
  //       case 3:
  //         return "Every 3 months";
  //       case 6:
  //         return "Every 6 months";
  //       default:
  //         return ""
  //     }
  //   }

  //   if (subscription.interval === "year") {
  //     return "Yearly";
  //   }

  //   return "";
  // }

  // const screeningAnsweredAtleastOnce = (): boolean => {
  //   let screeningHasAnAnswer: boolean = false;

  //   props.member.community_member.screening_response.forEach((response: any) => {
  //     if (response.answer) {
  //       screeningHasAnAnswer = true;
  //     }
  //   });

  //   return screeningHasAnAnswer;
  // }

  // const [userPurchasesData, setUserPurchasesData] = React.useState<any>(null);

  // const getUserPurchases = () => {
  //   PricingService.getInstance()
  //     .getUserPurchases(props.member.community_member.user && props.member.community_member.user.id, props.group.id)
  //     .then((response: any) => {
  //       setUserPurchasesData(response && response.data);
  //     })
  // }

  // const loadMorePurchases = () => {
  //   PricingService.getInstance()
  //     .loadMore(userPurchasesData.next_page_url)
  //     .then((response: any) => {
  //       setUserPurchasesData({
  //         ...userPurchasesData,
  //         next_page_url: response.data.next_page_url,
  //         data: [...userPurchasesData.data, ...response.data.data],
  //       });
  //     });
  // }

  const getActions = () => {
    return [
      {
        label: 'View profile',
        callback: () => { }
      },
    ]
  }

  return props.member ? (
    <div className="members-new-profile">

      <div className="header">
        {props.isGroupChat
          ?
          <span
            className="back"
            onClick={() => props.setIsViewProfile(false)}
            style={miscUtils.customThemeSet(props.theme) ? { color: props.theme.custom.primaryColor } : {}}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Back to members
          </span>
          :
          <span
            className="back"
            onClick={() => props.toggleProfilesView()}
            style={miscUtils.customThemeSet(props.theme) ? { color: props.theme.custom.primaryColor } : {}}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Close profile
          </span>
        }
      </div>

      <HeaderMemberDetails
        actions={getActions()}
        userMembership={{ membership: props.member.community_member && props.member.community_member }}
      />

      {
        (miscUtils.isAdmin((props.group && props.group.user_membership))
          || miscUtils.isModerator((props.group && props.group.user_membership))) &&
        <SubscriptionMemberDetails
          userMembership={props.member.community_member && props.member.community_member}
        />
      }
      <BioMemberDetails
        user={props.member.community_member && props.member.community_member}
        group={props.group && props.group}
      />

      {
        props.member.community_member &&
        props.member.community_member.screening_response &&
        <CustomFieldsMemberDetails
          screeningResponse={props.member.community_member.screening_response}
          group={props.group && props.group}
        />
      }


      {/* <div className="profile-slideout-content-info-header-container">
        {props.member && props.member.community_member.user && props.member.community_member.user.profile_image ? (
          <div className="profile-slideout-content-info-header-wrapper">
            <img
              src={props.member.community_member.user.profile_image}
              className="profile-slideout-content-info-header-image"
            />

          </div>
        ) : (
          <div
            data-color={miscUtils.getColorInitial(props.member.community_member.user && props.member.community_member.user.full_name)}
            className="profile-slideout-content-info-header-noimage"
          >
            {miscUtils.getNameInitials(props.member.community_member.user && props.member.community_member.user.full_name)}
          </div>
        )}
        <div className="profile-slideout-content-info-header-details-container">
          <p className="profile-slideout-content-info-header-details-name">
            {props.member.community_member.user && props.member.community_member.user.full_name}
          </p>

          {
            miscUtils.isAdmin((props.group && props.group.user_membership)) &&
            <span className="profile-slideout-content-info-header-details-email">
              {props.member.community_member.user && props.member.community_member.user.email}
            </span>
          }
          {
            props.member.community_member.user && props.member.community_member.user.job_title
            &&
            <p className="profile-slideout-content-info-header-details-title">
              {props.member.community_member.user.job_title}
            </p>
          }

          {props.member.community_member.user &&
            (
              props.member.community_member.user.twitter ||
              props.member.community_member.user.instagram ||
              props.member.community_member.user.facebook ||
              props.member.community_member.user.linkedin ||
              props.member.community_member.user.website
            ) &&

            <div className="profile-slideout-content-info-social-container">
              {props.member.community_member.user.twitter && (
                <span
                  className="profile-slideout-content-info-social-link"
                  onClick={() => window.open(props.member.community_member.user.twitter, '_blank')}
                >
                  <TwitterIcon />
                </span>
              )}
              {props.member.community_member.user.instagram && (
                <span
                  className="profile-slideout-content-info-social-link"
                  onClick={() => window.open(props.member.community_member.user.instagram, '_blank')}
                >
                  <InstagramIcon />
                </span>
              )}
              {props.member.community_member.user.facebook && (
                <span
                  className="profile-slideout-content-info-social-link"
                  onClick={() => window.open(props.member.community_member.user.facebook, '_blank')}
                >
                  <FacebookIcon />
                </span>
              )}
              {props.member.community_member.user.linkedin && (
                <span
                  className="profile-slideout-content-info-social-link"
                  onClick={() => window.open(props.member.community_member.user.linkedin, '_blank')}
                >
                  <LinkedInIcon />
                </span>
              )}
              {props.member.community_member.user.website && (
                <span
                  className="profile-slideout-content-info-social-link"
                  onClick={() => window.open(props.member.community_member.user.website, '_blank')}
                >
                  <WebsiteIcon />
                </span>
              )}
            </div>
          }
        </div>
      </div>
      {props.member.community_member.user && props.member.community_member.user.about_me && (
        <div className="profile-slideout-content-about-container">
          {props.member.community_member.user.about_me.length <= ABOUT_ME_CHARACTER_LIMIT ? (
            <p className="profile-slideout-content-about-text">{props.member.community_member.user.about_me}</p>
          ) : (
            <ReadMore
              text={props.member.community_member.user.about_me}
              numberOfLines={1}
              lineHeight={1}
              showLessButton={true}
              onContentChange={() => {
              }}
              readMoreCharacterLimit={ABOUT_ME_CHARACTER_LIMIT}
              className="profile-slideout-content-about-text"
              showMoreText='See more'
            />
          )}
        </div>
      )}

      <div className="profile-slideout-content-iconscon">
        {props.member.community_member.user && props.member.community_member.user.location && (
          <div className="profile-slideout-content-iconscon-icons" >
            <span className="profile-slideout-content-iconscon-icons-location">
              <MapPinIcon />
            </span>
            <span className="profile-slideout-content-iconscon-icons-value">
              {props.member.community_member.user.location}
            </span>
          </div>
        )}

        {props.member.community_member.user && props.member.community_member.user.timezone && (
          <div className="profile-slideout-content-iconscon-icons" >
            <span className="profile-slideout-content-iconscon-icons-location">
              <Clock />
            </span>
            <span className="profile-slideout-content-iconscon-icons-value">
              {timeFormatter}
              {" "}
              {props.member.community_member.user.timezone.text.split(' ')[0]}
            </span>
          </div>
        )}


      </div>

      {
        (miscUtils.isAdmin((props.group && props.group.user_membership))
          || miscUtils.isModerator((props.group && props.group.user_membership))) &&
        subscription && (
          <div
            className="profile-slideout-content-card-container"
            style={{ backgroundColor: '#f7f8f9' }}>
            <span className="profile-slideout-content-card-title">SUBSCRIPTION</span>
            <p className="profile-slideout-content-subscription-title">{subscription.name}</p>
            <div className="profile-slideout-content-subscription-details-container profile-slideout-content-card-keyvalue-wrapper">
              <div className="profile-slideout-content-card-keyvalue-container">
                <p className="profile-slideout-content-card-keyvalue-key">Amount:</p>
                <p className="profile-slideout-content-card-keyvalue-value">{miscUtils.getCurrencySymbol(miscUtils.getResourceCurrency(subscription.currency, props.group && props.group.currency))}{subscription.amount} {subscription.currency.toUpperCase()}</p>
              </div>
              {subscription.coupon_code ? (
                <div className="profile-slideout-content-card-keyvalue-container">
                  <p className="profile-slideout-content-card-keyvalue-key">Coupon applied:</p>
                  <p className="profile-slideout-content-card-keyvalue-value">{subscription.coupon_code}</p>
                </div>
              ) : (<></>)}
              {subscription.interval && (
                <div className="profile-slideout-content-card-keyvalue-container">
                  <p className="profile-slideout-content-card-keyvalue-key">Interval:</p>
                  <p className="profile-slideout-content-card-keyvalue-value">{formatBillingInterval()}</p>
                </div>
              )}
              {subscription.status && (
                <div className="profile-slideout-content-card-keyvalue-container">
                  <p className="profile-slideout-content-card-keyvalue-key">Status:</p>
                  <p
                    className="profile-slideout-content-card-keyvalue-value"
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >{subscription.status}</p>
                </div>
              )}
              {subscription.next_billing_date && (
                <div className="profile-slideout-content-card-keyvalue-container">
                  <p className="profile-slideout-content-card-keyvalue-key">Next billing date:</p>
                  <p className="profile-slideout-content-card-keyvalue-value">{subscription.next_billing_date}</p>
                </div>
              )}
              {subscription.trial_period && (
                <div className="profile-slideout-content-card-keyvalue-container">
                  <p className="profile-slideout-content-card-keyvalue-key">Trial period:</p>
                  <p className="profile-slideout-content-card-keyvalue-value">{subscription.trial_period} {subscription.trial_period === 1 ? 'day' : 'days'}</p>
                </div>
              )}
            </div>
          </div>
        )
      }

      {
        (miscUtils.isAdmin((props.group && props.group.user_membership)) ||
          miscUtils.isModerator((props.group && props.group.user_membership))) &&
        props.member.community_member.screening_response &&
        screeningAnsweredAtleastOnce() &&
        (
          <div
            className="profile-slideout-content-card-container"
            style={{ backgroundColor: '#f7f8f9' }}>
            <span className="profile-slideout-content-card-title">MEMBERSHIP QUESTIONS</span>
            <div className="profile-slideout-content-screening-container">
              {
                props.member.community_member.screening_response &&
                props.member.community_member.screening_response.map((response: any) => {
                  if (!response.answer || (response.answer && response.answer.length === 0)) {
                    return null;
                  }
                  return (
                    <div className="profile-slideout-content-screening-question-container">
                      <p className="profile-slideout-content-screening-question-title">{response.question}</p>
                      {getAnswerMarkup(response)}
                    </div>
                  )
                })}
            </div>
          </div>
        )}

      {(miscUtils.isAdmin((props.group && props.group.user_membership)) ||
        miscUtils.isModerator((props.group && props.group.user_membership))) &&
        userPurchasesData &&
        userPurchasesData.data.length > 0 ? (
        <div
          className="profile-slideout-content-card-container"
          style={{ backgroundColor: '#f7f8f9' }}
        >
          <span className="profile-slideout-content-card-title">PURCHASE HISTORY</span>
          {userPurchasesData.data.map((purchaseData: any, index: any) =>
            <div
              className="profile-slideout-content-purchase-container"
              key={index}
            >
              <p className="profile-slideout-content-purchase-title">{purchaseData.purchasable && (purchaseData.purchasable.title || purchaseData.purchasable.name)}</p>
              <div className="profile-slideout-content-card-keyvalue-container">
                <p className="profile-slideout-content-card-keyvalue-key">Amount:</p>
                <p className="profile-slideout-content-card-keyvalue-value">{miscUtils.getCurrencySymbol(miscUtils.getResourceCurrency(purchaseData.price_option.currency, props.group && props.group.currency))}{purchaseData.price_option.amount} {purchaseData.price_option.currency.toUpperCase()}</p>
              </div>
              {purchaseData.coupon_code ? (
                <div className="profile-slideout-content-card-keyvalue-container">
                  <p className="profile-slideout-content-card-keyvalue-key">Coupon applied:</p>
                  <p className="profile-slideout-content-card-keyvalue-value">{purchaseData.coupon_code}</p>
                </div>
              ) : (<></>)}
              <div className="profile-slideout-content-card-keyvalue-container">
                <p className="profile-slideout-content-card-keyvalue-key">Interval:</p>
                <p className="profile-slideout-content-card-keyvalue-value">One-time</p>
              </div>
              <div className="profile-slideout-content-card-keyvalue-container">
                <p className="profile-slideout-content-card-keyvalue-key">Date:</p>
                <p className="profile-slideout-content-card-keyvalue-value">{moment.utc(purchaseData.created_at).format("MMMM Do, YYYY")}</p>
              </div>
            </div>
          )}
          {userPurchasesData.next_page_url ? (
            <p
              className="profile-slideout-content-purchase-see-more"
              onClick={loadMorePurchases}
            >
              See more...
            </p>
          ) : (<></>)}
        </div>
      ) : (<></>)} */}

    </div>
  ) :
    (
      <></>
    )
}

export default withRouter(
  connect(null, null)(Chatroom as any) as any
) as any;
