export enum QuizVerdict {
    Pass = "pass",
    Fail = "fail",
}

export enum QuizAnswerVerdict {
    Correct = "correct",
    Wrong = "wrong",
}

export enum QuestionType {
    Checkbox = "checkbox",
    Radio = "radio",
}