import React from "react";

interface MentionComponentProps {
  mention: {
    id: string;
  };
  className?: string;
}

const MentionComponent: React.FC<MentionComponentProps> = ({
  mention,
  children,
  className = "",
}) => (
  <span
    className={`${className} selected-mention`}
    spellCheck={false}
    data-role-id={mention.id}
    style={{
      cursor: 'default',
    }}
  >
    {children}
  </span>
);

export default MentionComponent;

