import React from "react";
import ContentLoader from "react-content-loader";

const TopBarMessageLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={356}
            height={63}
            viewBox="0 0 356 63"
        >
            <rect x="65" y="26" rx="3" ry="3" width="103" height="10" />
            <circle cx="33" cy="44" r="20" />
            <rect x="65" y="47" rx="3" ry="3" width="270" height="10" />
        </ContentLoader>
    )
};

export default TopBarMessageLoader;
