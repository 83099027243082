import React, { useState, useEffect, Fragment, useRef } from "react";
import "./NotFound.scss";
import NotFoundComponent from "./NotFoundComponent";

const NotFound = (props: any) => {
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const showRef = useRef(show);
  showRef.current = show;
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;

  useEffect(() => {
    setTimeout(() => {
      setShow(!showRef.current);
      setLoading(!isLoadingRef);
    }, 4500);
  }, []);

  return (
    <Fragment>
      {!isLoading ? (
        <Fragment>
          {show ? (
            <div className="not-found-wrapper">
              <NotFoundComponent user={props.user} message={props.message} subMessage={props.subMessage} showSubMessage={true} />
            </div>
          ) : null}
        </Fragment>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default NotFound;
