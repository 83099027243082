import React, { useState, useEffect, useRef } from "react";
import "./CommentComponent.scss";
import "./comment-responsive.scss";
// import Dropdown from "../../elements/dropdown";
import { GroupService } from "../../api/services/groups";
// import ReadMore from "../../utils/readMore/readMore";
// import anchorme from "anchorme";
import Moment from "react-moment";
import miscUtils from "utils/miscUtils";
import ModerationService from "api/services/moderation";
import { toast, ToastType } from "react-toastify";
import CommentInput from "components/commentInput";
import { CommentLevels, ReactionTypes } from "./domain";
import { PostService } from "api/services/post";
import { ReactComponent as LoveIcon } from './icons/love.svg';
import { ReactComponent as LoveIconFill } from './icons/love-fill.svg';
import { ReactComponent as CommentIcon } from './icons/comment.svg';
import CommentComponentRecursive from '../commentComponent';
import useSlideoutProfile from "hooks/slideoutProfileHook";
import UserComponent from "components/userComponent";
import { ISlideoutProfileData } from "types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Map } from "immutable";

import { ReactComponent as SpinIcon } from "assets/img/Spin-1s-18px.svg";
import { ReactComponent as DownloadIcon } from 'components/group/groupPost/download.svg';
import ExpandedInlineImage from "components/ExpandedInlineImage/ExpandedInlineImage";
import PopOnMe from "elements/poponme";
import { setlocalStorageValue } from "utils/localStorage";
import { scrollToCommentAtom } from "showMessageMenuAtom";
import { useAtom } from "jotai";
import styled from "styled-components";
import ResizeObserver from 'resize-observer-polyfill';
import DraftContent from "components/group/groupPost/DraftContent";

const CommentWrapper = styled.div`
  position: relative;
`;

const CommentMainGuide = styled.div`
  position: absolute;
  width: 1px;
  border-left: 1px solid #d5d8df;
  top: 40px;
`;

const CommentRepliesGuide = styled.div`
  position: absolute;
  width: 25px;
  border-left: 1px solid #d5d8df;
  border-bottom: 1px solid #d5d8df;
  border-radius: 0 0 0 4px;
  top: 40px;
  left: 17px;
`;

const CommentReplyInputGuide = styled.div`
  display: none;
  position: absolute;
  width: 25px;
  border-left: 1px solid #d5d8df;
  border-bottom: 1px solid #d5d8df;
  border-radius: 0 0 0 4px;
  top: 40px;
`;

function CommentComponent(props: {
  group: any;
  comment: any | undefined;
  onCommentRemoved?: any;
  userId?: any;
  isOwner?: boolean;
  post?: any;
  refreshGroupPostsWithoutLoad?: any
  userGroups?: any;
  canInteract?: any;
  onPostRequest: any;
  theme?: any;
  user?: any;
  onPost?: any;
  setLoadingReacters?: any;
  setReactersData?: any;
  view?: any;
  publicLogin?: any;
  setIsReactersModalOpen?: any;
  incrementCommentsCount?: any;
  isEnrolled?: boolean;
  global?: any;
  setCommentsCount?: (value: number) => void
  commentsCount?: number
  setShowCommentSection?: any
  lastReplyRef?: any
  onSearchPage?: boolean
}) {

  const lastReplyRef = React.useRef<any>(null);
  const [commentWrapperHeight, setCommentWrapperHeight] = React.useState<number>(0);
  const [lastReplyHeight, setLastReplyHeight] = React.useState<number>(0);

  const [imageSrc, setImageSrc] = React.useState<string>('');
  const containersRef = React.useRef<any>([])
  const [showComponent, setShowComponent] = React.useState<boolean>(false);

  const handleImageClick = React.useCallback((event: MouseEvent) => {
    const clickedImage = event.target as HTMLElement;
    if (clickedImage) {
      containersRef.current = clickedImage
      setImageSrc(containersRef.current.src)
      setShowComponent(true)
    }
  }, []);

  React.useEffect(() => {
    handleImageCommentClick();
  }, []);

  React.useEffect(() => {
    computeCommentWrapperHeight();
  }, []);

  useEffect(() => {
    if(lastReplyRef.current) {
      // Function to update the height state
      const updateHeight = () => {
        setLastReplyHeight(lastReplyRef.current ? lastReplyRef.current.offsetHeight : 0);
      };

      // Create a new ResizeObserver instance
      const resizeObserver = new ResizeObserver(() => {
        updateHeight();
      });

      // Start observing the div element
      resizeObserver.observe(lastReplyRef.current);

      // Initial height calculation
      updateHeight();

      // Cleanup function to unobserve the element
      return () => {
        // resizeObserver.unobserve(lastReplyRef.current);
        if (lastReplyRef.current) {
          resizeObserver.unobserve(lastReplyRef.current);
        }
      };
    }
  }, [lastReplyRef.current]);

  const computeCommentWrapperHeight = () => {
    const commentWrapperElement = document.getElementById(`comment-wrapper-${props.comment.id}`);
    if (commentWrapperElement) {
      const commentWrapperHeight = commentWrapperElement.offsetHeight;
      setCommentWrapperHeight(commentWrapperHeight);
    }
  }

  function handleImageCommentClick() {
    const containers = document.querySelectorAll(`.inline-img-${props.comment.id} figure`);

    if (containers) {
      containers.forEach((container) => {
        const images = container.querySelectorAll('img');
        images.forEach((image) => {
          (image as HTMLElement).style.cursor = 'pointer';
          image.addEventListener('click', handleImageClick);
        });

        return () => {
          images.forEach((image) => {
            image.removeEventListener('click', handleImageClick);
          });
        };
      });
    }
  }

  const inputReference = useRef(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [mentions,] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [replies, setReplies] = useState<any>(null);
  const [repliesData, setRepliesData] = useState<any>(null);
  const [showReplies, setShowReplies] = useState<boolean>(false);
  const [showReplyInput, setShowReplyInput] = useState<boolean>(false);
  const [commentLikesCount, setCommentLikesCount] = useState<number>(() => props.comment.reaction_like_count !== undefined && props.comment.reaction_like_count ?
    props.comment.reaction_like_count : 0);
  const [commentRepliesCount, setCommentRepliesCount] = useState<number>(() => props.comment.comments_count ?
    props.comment.comments_count : 0);
  const [commentLiked, setCommentLiked] = useState<boolean>(() => {
    return props.comment.liked ? props.comment.liked as boolean : false;
  });

  useEffect(() => {
    if (showReplies && props.group && props.comment && props.comment.level !== CommentLevels.THIRD) {
      getCommentComments();
      setCommentRepliesCount(props.comment.comments_count)
      if (props.setShowCommentSection) {
        props.setShowCommentSection(true)
      }
    }
  }, [props.group, props.comment, showReplies, commentLikesCount, props.post]);

  const { openSlideout } = useSlideoutProfile();
  const openedSlideoutProfileData: ISlideoutProfileData = props.global.slideoutProfileData;

  const [loadingCommentsReplies, setLoadingCommentsReplies] = useState<boolean>(false);

  // comment linking
  const [scrollToComment,] = useAtom(scrollToCommentAtom);
  const [commentHighlight, setCommentHighlight] = useState(false);
  const commentRef = useRef<any>(null);

  const copyCommentLink = () => {
    const _post = props.post && props.post
    const propsComment = props.comment && props.comment.id
    const commentLink = `${window.location.origin}/posts/${_post.slug}?include=postable&commentId=${propsComment}`
    window.navigator.clipboard.writeText(commentLink);
    toast("Comment link copied", {
      type: ToastType.SUCCESS,
      autoClose: 3000
    });
  }

  // Scroll to the comment and highlight it
  useEffect(() => {
    if (scrollToComment) {
      commentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setCommentHighlight(true);

      // Set a timeout to show replies after a short delay
      const Repliestimer = setTimeout(() => { setShowReplies(true) }, 1000);
      const timer = setTimeout(() => { setCommentHighlight(false) }, 6000);

      return () => {
        clearTimeout(Repliestimer);
        clearTimeout(timer);
      };
    }
  }, [scrollToComment])

  const getCommentComments = () => {
    if (scrollToComment) {
      if (Array.isArray(props.comment && props.comment.comments)) {
        setReplies(props.comment && props.comment.comments);
      }
    } else {
      setLoadingCommentsReplies(true)
      PostService.getInstance()
        .getCommentComments(props.group.id, props.comment.id)
        .then((response: any) => {
          setRepliesData(response.data);
          setReplies(response.data.data);
        })
        .catch()
        .finally(() => {
          setLoadingCommentsReplies(false)
          computeCommentWrapperHeight();
        });
    }
  }

  const [viewAllClicked, setViewAllClicked] = useState<boolean>(false)

  const viewAllCommentReplies = () => {
    setLoadingCommentsReplies(true)
    PostService.getInstance()
      .getCommentComments(props.group.id, props.comment.id)
      .then((response: any) => {
        setRepliesData(response.data);
        setReplies(response.data.data);
      })
      .catch()
      .finally(() => {
        setLoadingCommentsReplies(false)
        setViewAllClicked(true)
        // computeCommentWrapperHeight();
      });
  }

  const loadMoreReplies = () => {
    PostService.getInstance()
      .loadMore(repliesData.next_page_url)
      .then((response: any) => {
        let newReplies = JSON.parse(JSON.stringify(replies));
        setRepliesData({ ...repliesData, next_page_url: response.data.next_page_url });

        response.data.data.map((incomingReply: any) => {
          let replyExists: boolean = false;
          replies.map((existingReply: any) => {
            if (existingReply.id === incomingReply.id) {
              replyExists = true;
            }
          });

          if (!replyExists) {
            newReplies.push(incomingReply);
          }
        });

        setReplies(newReplies);
        // computeCommentWrapperHeight();
      })
      .catch();
  }

  function deleteComment() {
    GroupService.getInstance()
      .deletePostComment(props.comment.id)
      .finally(() => {
        if (props.onCommentRemoved) {
          props.onCommentRemoved(props.comment.id);
        }
        GroupService.getInstance().getGroupPost(props.group.id, props.post.id)
          .then((response: any) => {
            if (props.setCommentsCount && props.commentsCount) {
              props.setCommentsCount(response.all_comments_count);
            }
          })
        if (props.setShowCommentSection) {
          props.setShowCommentSection(true)
        }

      })
  }

  const onChange = (e: any) => {
    setComment(e.target.value)
  }

  const onSubmitEdit = (e: any) => {
    e.preventDefault();
    GroupService.getInstance()
      .editPostComment(props.comment.id, comment, mentions)
      .then(() => {
        setEditMode(false);
        setComment("");
        props.refreshGroupPostsWithoutLoad()
      })
  }

  const flagComment = () => {
    ModerationService.getInstance().flagComment(props.post.id, props.comment.id).then(() => {
      toast("Comment flagged!", {
        type: ToastType.SUCCESS,
        autoClose: 2000,
      })
    })
  }

  const userIsAnAdmin = () => {
    return props.group && props.group.user_membership && (
      miscUtils.isOwner(props.group.user_membership) ||
      miscUtils.isAdmin(props.group.user_membership) ||
      miscUtils.isModerator(props.group.user_membership)
    )
  }

  const commentActions: Array<any> = [];

  commentActions.push({ label: "Copy link", callback: () => copyCommentLink() });

  if (userIsAnAdmin() || (props.userId && props.userId === props.comment.commentator.id)) {
    commentActions.push({ label: "Edit comment", callback: () => setIsEditing(true) });
  }

  if (userIsAnAdmin() || (props.userId && props.userId === props.comment.commentator.id)) {
    commentActions.push({ label: "Delete comment", callback: deleteComment });
  }

  // logged out users should not see this option
  if (props.userId && props.userId) {
    commentActions.push({ label: "Flag comment", callback: flagComment });
  }


  const getCommentLevel = () => {
    switch (props.comment.level as CommentLevels) {
      case CommentLevels.FIRST:
        return 'first';
      case CommentLevels.SECOND:
        return 'second';
      case CommentLevels.THIRD:
        return 'third';
      default:
        return '';
    }
  }

  const getRepliesMarkup = () => {
    return replies.map((reply: any, index: number) => (
      <div className="single-comment-reply-container" id={`single-comment-reply-container-${reply.id}`}>
        <CommentComponentRecursive
          {...props}
          comment={reply}
          onCommentRemoved={(commentId: number) => {
            setCommentRepliesCount(commentRepliesCount - 1);
            setRepliesData({
              ...repliesData,
              total: repliesData && repliesData.total - 1,
            });
            setReplies(replies.filter((reply: any) => reply.id !== commentId))
            if (props.setShowCommentSection) {
              props.setShowCommentSection(true)
            }
          }}
          lastReplyRef={
            index + 1 === replies.length ? 
            lastReplyRef :
            undefined
          }
        />
        <div className="single-comment-reply-guide"
        />
      </div>
    ))
  }

  const likeCommentAction = () => {
    const commentIsLiked: boolean = !!commentLiked;
    setCommentLiked(!commentLiked);

    if (!commentIsLiked) {
      setCommentLikesCount(commentLikesCount + 1);
      PostService.getInstance()
        .reactToComment(
          props.group.id,
          props.comment.id,
          {
            type: ReactionTypes.LIKE,
          }
        );
    } else {
      setCommentLikesCount(commentLikesCount - 1);
      PostService.getInstance()
        .removeCommentReaction(
          props.group.id,
          props.comment.id,
          {
            type: ReactionTypes.LIKE,
          }
        );
    }
  }

  const getCommentReacters = () => {
    setTimeout(() => {
      props.setIsReactersModalOpen(true);
    }, 100);
    props.setLoadingReacters(true);
    PostService.getInstance()
      .getReactersToComment(props.group.id, props.comment.id)
      .then((response: any) => {
        props.setLoadingReacters(false);
        props.setReactersData(response.data);
      });
  }

  const clickOnCommentOptions = () => {
    const commentOptionsTriggerElement: HTMLElement = document.getElementById(`comment-popup-actions-${props.comment.id}`) as HTMLElement;
    if (commentOptionsTriggerElement) {
      commentOptionsTriggerElement.click();
    }
  }

  // to hold the edited comment
  const [editedCOmment, setEditedCOmment] = useState<any>(props.comment)

  useEffect(() => {
    setEditedCOmment(props.comment)
  }, [props.comment]);

  const getMainGuideOffset = () => {
    if(
      showReplyInput &&
      props.user &&
      props.comment.level !== CommentLevels.THIRD &&
      !(props.post && props.post.commenting_closed) &&
      (props.group && props.group.membership_status === "member")
    ) {
      return 160;
    }

    return 80;
  }

  const getRepliesGuideOffset = () => {
    if(
      showReplyInput &&
      props.user &&
      props.comment.level !== CommentLevels.THIRD &&
      !(props.post && props.post.commenting_closed) &&
      ((props.group && props.group.membership_status === "member") ||
      (!props.group || (props.group && props.group.membership_status !== "member") && props.isEnrolled))
    ) {
      return 177.5;
    }

    return 50;
  }


  const isShowingViewReplies = () => {
    return (
      !(replies && replies.length) && 
      !loadingCommentsReplies &&
        (
        (
          commentRepliesCount && commentRepliesCount !== 0 && !showReplies && !scrollToComment
        ) ||
        (
          commentRepliesCount &&
          commentRepliesCount !== 0 &&
          scrollToComment &&
          !viewAllClicked
          &&
          replies === null
        ) ||
        (
          commentRepliesCount &&
          commentRepliesCount !== 0 &&
          scrollToComment &&
          !viewAllClicked
          &&
          replies !== null &&
          replies && replies.length &&
          (props.comment.comments_count - (replies && replies.length)) > 0
        )
      )
    )
  }

  const isShowingReplyInput = () => {
    return (
      showReplyInput &&
      props.user &&
      props.comment.level !== CommentLevels.THIRD &&
      !(props.post && props.post.commenting_closed) &&
      ((props.group && props.group.membership_status === "member") ||
      (!props.group || (props.group && props.group.membership_status !== "member") && props.isEnrolled))
    )
  }


  if (isEditing) {
    return (
      <CommentInput
        group={props.group}
        theme={props.theme}
        inputReference={inputReference}
        postId={props.post.id}
        user={props.comment.commentator}
        onPost={(_editedCOmment: any) => {
          if (scrollToComment) {
            setEditedCOmment(_editedCOmment)
          }
          props.onPost()
        }
        }
        editMode
        comment={editedCOmment}
        onCancelEdit={() => setIsEditing(false)}
        loggedInUser={props.user}
      />
    )
  }
  console.log('editedCOmment', editedCOmment)

  return (
    <>
      <ExpandedInlineImage
        imageSrc={imageSrc}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        isCommentComponent
      />
      {/* <div className={`post-comment inline-img-${props.comment.id}`}> */}
      <CommentWrapper
      ref={props.lastReplyRef ? props.lastReplyRef : undefined}
      id={`comment-wrapper-${props.comment.id}`}
      >
        {
        showReplies && 
        replies &&
        replies.length ? (
          <CommentMainGuide
          style={{
            height: `${commentWrapperHeight - (lastReplyHeight + getMainGuideOffset())}px`,
            left: props.comment.level === CommentLevels.FIRST ? `15px` : `20px`,
          }}
          />
        ) : <></>}

        {isShowingViewReplies() ? (
          <CommentRepliesGuide
          style={{
            height: `${commentWrapperHeight - getRepliesGuideOffset()}px`,
          }}
          />
        ) : <></>}

        {isShowingReplyInput() ? (
          <CommentReplyInputGuide
          style={{
            height: `${commentWrapperHeight}px`,
            left: replies && replies.length ? 
            props.comment.level === CommentLevels.FIRST ? `15px` : `20px` :
            '17px',
          }}
          />
        ) : <></>}
        <div
          className={`post-comment inline-img-${props.comment.id}`}
          ref={commentRef}
        >
          <div className="comment-header">
              <UserComponent
                hideAdmin={true}
                user={props.comment.commentator}
                profilePicture={props.comment.commentator.profile_image}
                minimizableName
                group={props.group}
                hideName
                isPlacedonIntendedComp={true}
                roleId={props.comment.author_role_id}
                isCommentComponent={true}
            />
          </div>
          <div
            className="comment-content"
          >
            <div className={`comment-content-background ${getCommentLevel()}-level-comment-content ${props.onSearchPage ? 'maxWidthSearchPage' : ''}`}
              id={`comment-${Number(scrollToComment)}`}
              style={{
                backgroundColor: `comment-${Number(scrollToComment)}` === `comment-${props.comment.id}` && commentHighlight ? `#d5d8df` : '',
              }}
            >
              <div className="info-component">
                <div className="name" onClick={() => {
                  if (!miscUtils.isTeamMember(props.group)) {
                    return;
                  }
                  openSlideout({ user: props.comment.commentator }, openedSlideoutProfileData && openedSlideoutProfileData.user);
                }}>{props.comment.commentator.full_name}</div>
                <div className="period"></div>

                {props.comment.created_at && (
                  <div className="timestamp">
                    <Moment utc={true} fromNow={true} ago={true} style={{
                      // fontFamily: 'Arial',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '13px',
                      // lineHeight: '15px',
                      color: '#767A8A',
                      opacity: 0.7,
                    }}>
                      {props.comment.created_at}
                    </Moment>
                    <span style={{
                      // fontFamily: 'Arial',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '13px',
                      // lineHeight: '15px',
                      color: '#767A8A',
                      opacity: 0.7,
                    }}>
                      {""} ago
                    </span>
                  </div>
                )}

              </div>
              <div className="headline">
                <span
                  className='headline-text'
                >
                  {props.comment.commentator.headline && props.comment.commentator.headline}
                </span>
              </div>
              {editMode ? (
                <form onSubmit={onSubmitEdit}>
                  <input type="text" form-datatype='text' className='edit-comment-input' onChange={onChange} defaultValue={props.comment.comment} />
                  <div className="flex">
                    <button type='submit' className="edit-btn flex btn btn-primary">
                      Save
                    </button>
                    <button
                      className="edit-btn flex btn btn-primary"
                      onClick={() => { setEditMode(false) }}
                      style={{ marginLeft: '.5rem' }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              ) : (
                <>
                    {/* {props.comment.comment.length > 280 && (
                    <div onClick={handleImageCommentClick}>
                      <ReadMore
                        text={editedCOmment.comment}
                        numberOfLines={1}
                        lineHeight={1}
                        showLessButton
                        className="comment-markup-container"
                      />
                    </div>
                  )} */}

                    {/* {props.comment.comment.length <= 280 && (
                    <span
                      dangerouslySetInnerHTML={{ __html: anchorme(editedCOmment.comment) }}
                      className="comment-markup-container"
                    />
                  )} */}

                    <DraftContent
                      post={{
                        id: editedCOmment.comment.id,
                        description: editedCOmment.comment,
                        description_json: editedCOmment.comment_json
                      }}
                      theme={props.theme}
                      draftContentWrapperClassName='comment-draft-content'
                    />

                  {
                    props.comment.files !== undefined &&
                    props.comment.files !== null &&
                    props.comment.files.length > 0 &&
                    props.comment.files.map((file: any, index: any) =>
                    (
                      <div className="comment-file-content" key={index}>
                        <div className="comment-file-content-container">
                          <a
                            className="comment-file-content-container-download-link"
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: (props.theme && props.theme.custom && props.theme.custom.primaryColor)
                                ? props.theme.custom.primaryColor
                                : '#888'
                            }}
                          >
                            <DownloadIcon />
                          </a>
                          <div className="comment-file-content-container-details">
                            <a
                              href={file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="comment-file-content-container-details-name">
                                {file.original_file_name}
                              </span>
                            </a>
                            <span className="comment-file-content-container-details-size">
                              {(file.file_size / 1000000).toFixed(2)} MB
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  }

                </>
              )}
            </div>
            {
              !props.onSearchPage && commentActions && commentActions.length > 0 &&
              <div
                className="options comment-options-container"
                onClick={clickOnCommentOptions}
              >
                <button
                  type="button"
                  className={`btn btn-dots comment-popup-actions-menu-icon`}
                >
                  <span />
                  <span />
                  <span />
                </button>
                <PopOnMe
                  actions={commentActions}
                    tippyWrapperStyles={{
                      paddingTop: '0px',
                      paddingBottom: '0px'
                    }}
                    tippyActionButtonStyle={{
                      height: '40px'
                    }}
                >
                  <span
                    id={`comment-popup-actions-${props.comment.id}`}
                    className="comment-popup-actions-menu-trigger"
                  />
                </PopOnMe>
              </div>
            }
          </div>
        </div>
        {
          !props.onSearchPage &&
        <div className="comment-actions-container">

          <div className="comment-actions-container-upper">
            <div className="comment-actions-container-first">
              <div className="comment-actions-action-container like">
                <span
                  className={`${commentLiked ? 'liked-comment-p' : ""}`}
                  onClick={() => {
                    if (!props.user) {
                      if (window.location.pathname !== '/') {
                        let url = new URL(window.location.href)
                        let pathandQuery = url.pathname + url.search;
                        setlocalStorageValue("next_link", pathandQuery)
                      }
                      props.publicLogin();
                      return;
                    }

                    if (!props.group || (props.group && props.group.membership_status !== "member") && !props.isEnrolled) {
                      toast("Only community members can react to this post", {
                        type: ToastType.ERROR,
                        autoClose: 3000,
                      });
                      return;
                    }

                    likeCommentAction();
                  }}
                >
                  {commentLiked ? <LoveIconFill /> : <LoveIcon />}
                  {commentLiked ? 'Liked' : 'Like'}
                </span>
                {/* <p
              className="comment-actions-action-count like-count"
              onClick={() => {
                if (props.view === "public") {
                  props.publicLogin();
                  return;
                }

                getCommentReacters();
              }}
            >{commentLikesCount}</p> */}
              </div>
              {
                props.comment.level !== CommentLevels.THIRD && (
                  <div
                    className="comment-actions-action-container comment"
                    onClick={() => {
                      if (!props.user) {
                        if (window.location.pathname !== '/') {
                          let url = new URL(window.location.href)
                          let pathandQuery = url.pathname + url.search;
                          setlocalStorageValue("next_link", pathandQuery)
                        }
                        props.publicLogin();
                        return;
                      }

                      if (!props.group || (props.group && props.group.membership_status !== "member") && !props.isEnrolled) {
                        setShowReplies(!showReplies);
                        toast("Only community members can react to this post", {
                          type: ToastType.ERROR,
                          autoClose: 3000,
                        });
                        return;
                      }

                      // if (!showReplyInput) {
                      setShowReplyInput(!showReplyInput);

                      //   if (showReplies) {
                      //     return;
                      //   }
                      // }

                      // setShowReplies(!showReplies);
                    }}
                  >
                    <span><CommentIcon /></span>
                    <span>
                      Reply
                    </span>
                    {/* <p
                      className={`${commentLiked ? 'comment-liked' : ''} comment-actions-action-count`}
                >{commentRepliesCount}</p> */}
                  </div>
                )
              }
            </div >

            <div className="comment-actions-action-count">
              <p
                className={`comment-actions-action-count like-count ${commentLiked ? 'liked-comment-p' : ""}`}
                onClick={() => {
                  if (props.view === "public") {
                    props.publicLogin();
                    return;
                  }

                  getCommentReacters();
                }}
              >
                {/* {commentLikesCount} */}
                {
                  commentLikesCount !== undefined && commentLikesCount !== null ?
                    <>
                      {commentLikesCount === 0 ? (
                        <span>0 likes</span>
                      ) : commentLikesCount === 1 ? (
                        <span>1 like</span>
                      ) : (
                        <span>{commentLikesCount} likes</span>
                      )}
                    </>
                    :
                    null
                }

              </p>

            </div>

          </div >
          {
            commentRepliesCount && commentRepliesCount !== 0 && !showReplies && !scrollToComment ?
              <div
                className="comment-actions-container-lower"
                onClick={() => {
                  if (!props.user) {
                    let url = new URL(window.location.href)
                    let pathandQuery = url.pathname + url.search;
                    setlocalStorageValue("next_link", pathandQuery)
                    props.publicLogin();
                    return;
                  }
                  setShowReplies(!showReplies);
                }}
              >
                {commentRepliesCount === 1 ? (
                  <span>View 1 reply</span>
                ) : (
                  <span>View {commentRepliesCount} replies</span>
                )}
              </div>
              :
              null
          }

          {/* Replies is null */}
          {
            commentRepliesCount &&
              commentRepliesCount !== 0 &&
              scrollToComment &&
              !viewAllClicked
              &&
              replies === null
              ?
              <div
                className="comment-actions-container-lower"
                onClick={() => {
                  if (!props.user) {
                    let url = new URL(window.location.href)
                    let pathandQuery = url.pathname + url.search;
                    setlocalStorageValue("next_link", pathandQuery)
                    props.publicLogin();
                    return;
                  }
                  // setShowReplies(!showReplies);
                  if (showReplies && !viewAllClicked) {
                    viewAllCommentReplies()
                  }
                }}
              >
                {commentRepliesCount === 1 ? (
                  <span>View 1 reply</span>
                ) : (
                  <span>View {commentRepliesCount} replies</span>
                )}
              </div>
              :
              null
          }

          {/* Replies is not null */}
          {
            commentRepliesCount &&
              commentRepliesCount !== 0 &&
              scrollToComment &&
              !viewAllClicked
              &&
              replies !== null &&
              replies && replies.length &&
              (props.comment.comments_count - (replies && replies.length)) > 0
              ?
              <div
                className="comment-actions-container-lower"
                onClick={() => {
                  if (!props.user) {
                    let url = new URL(window.location.href)
                    let pathandQuery = url.pathname + url.search;
                    setlocalStorageValue("next_link", pathandQuery)
                    props.publicLogin();
                    return;
                  }
                  // setShowReplies(!showReplies);
                  if (showReplies && !viewAllClicked) {
                    viewAllCommentReplies()
                  }
                }}
              >
                {commentRepliesCount === 1 ? (
                  <span>View 1 more reply</span>
                ) : (
                  <span>
                    View {props.comment.comments_count - (replies && replies.length)} more {props.comment.comments_count - (replies && replies.length) === 1 ? 'reply' : 'replies'}
                  </span>

                )}
              </div>
              :
              null
          }
        </div >
        }

        {showReplies && (
          <div className={`comment-replies-container ${getCommentLevel()}-level`}>
            {
              (loadingCommentsReplies && !replies) &&
              <div className="loading-comments-icon">
                <SpinIcon />
              </div>
            }

            {replies && replies.length > 0 && (
              <>
                <div>
                  {getRepliesMarkup()}
                </div>
                {repliesData && repliesData.next_page_url && (
                  <p
                    className="load-more-comment-replies"
                    onClick={loadMoreReplies}
                  >View {repliesData.total - replies.length} more repl{repliesData.total - replies.length === 1 ? 'y' : 'ies'}</p>
                )}
              </>
            )}
          </div>
        )}
        <div className={`comment-replies-container ${getCommentLevel()}-level`}>
          {
            showReplyInput &&
            props.user &&
            props.comment.level !== CommentLevels.THIRD &&
            !(props.post && props.post.commenting_closed) &&
            // (props.group && props.group.membership_status === "member") 
            ((props.group && props.group.membership_status === "member") ||
              (!props.group || (props.group && props.group.membership_status !== "member") && props.isEnrolled))

            && (
              <div>
                <CommentInput
                  group={props.group}
                  theme={props.theme}
                  inputReference={inputReference}
                  postId={props.post.id}
                  user={props.user}
                  onPost={(newReply: any) => {
                    let currentReplies = replies ? replies : [];
                    setReplies([...currentReplies, newReply]);
                    // dont close the reply container after replying to a comment
                    setShowReplies(true)

                    setShowReplyInput(false);
                    setCommentRepliesCount(commentRepliesCount + 1);
                    setRepliesData({ ...repliesData, total: repliesData && repliesData.total + 1 });
                    if (!scrollToComment) {
                      props.incrementCommentsCount();
                      getCommentComments()
                    }
                    if (props.setShowCommentSection) {
                      props.setShowCommentSection(true)
                    }
                  }}
                  comment={props.comment}
                  replyView
                  level={props.comment.level}
                  loggedInUser={props.user}
                />
              </div>
            )
          }
        </div>
      </CommentWrapper>
    </>
  );
}

const mapStateToProps = (state: Map<string, any>) => {
  const global = Map(state.get("global", {})).toJS() as any;

  return {
    global,
  };
};

export default withRouter(
  connect(mapStateToProps, null)(CommentComponent as any) as any
) as any;
