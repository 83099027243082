import React from 'react';
import { IVideoBlockPreviewProps } from './IVideoBlockPreviewProps';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { VideoType } from 'containers/PageBuilder/SinglePageContainer/domain';
import BunnyVideoPlayer from 'components/BunnyVideoPlayer';
import { getYouTubeVideoId } from 'containers/CoursesContainer/SectionsList/SectionItem/SectionItemContent/AdminCourseLesson/utis';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { v4 as uuidv4 } from 'uuid';
import WistiaPlayer from 'components/WistiaPlayer';
import useWindowSize from 'utils/windowSize';
import { LivePagePreviewView } from 'containers/PageBuilder/LivePagePreviewContainer/domain';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import styled from 'styled-components';

const opts = {
  playerVars: {
    rel: 0,
    autoplay: 0,
    cc_load_policy: 0,
    iv_load_policy: 3,
    modestbranding: 1,
  }
};

const VideoBlockVideoContainer: any = styled.div`
  width: 100%;
  height: 490px;

  &.mobile {
      height: 210px;
  }

  @media(max-width: 550px) {
      height: 210px;
  }
`;

const VideoBlockVideoUploadContainer: any = styled.div`
  width: 100%;
  height: 100%;

  .video-in-block-preview-yt-player-container {
      width: 100%;
      height: 100%;
  }

  .video-in-block-preview-yt-player {
      width: 100%;
      height: 100%;
  }

  .video-in-block-preview-vimeo-player {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe,
      object,
      frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
  }
`;

const VideoBlockVideoEmptyContainer: any = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D5D8DF;
`;

const VideoBlockVideoEmptyIconContainer: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoBlockPreview: React.FC<IVideoBlockPreviewProps> = (props) => {

  const screenWidth = useWindowSize().width;

  const getVideoUploadMarkup = () => {
    if(props.blockData.video_type === VideoType.Uplaod) {
      return (
        <BunnyVideoPlayer
        videoUrl={props.blockData.video_url}
        />
      )
    } else {
      if(props.blockData.video_url.includes('you')) {
        return (
            <YouTube
            // @ts-ignore
            opts={opts}
            videoId={getYouTubeVideoId(props.blockData.video_url)}
            className="video-in-block-preview-yt-player"
            containerClassName="video-in-block-preview-yt-player-container"
            />
        )
      }

      if(props.blockData.video_url.includes('vimeo')) {
          return (
              <Vimeo
              key={uuidv4()}
              video={props.blockData.video_url}
              className="video-in-block-preview-vimeo-player"
              style={{
                  height: screenWidth <= 550 || props.view === LivePagePreviewView.Mobile ? '210px' : '490px',
                  marginTop: 0,
                  backgroundColor: '#333333',
              }}
              />
          )
      }

      if(props.blockData.video_url.includes('wistia')) {
          return (
              <WistiaPlayer
              videoId={props.blockData.video_url.split("/")[4]}
              />
          )
      }

      return <></>
    }
  }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
      <BlockContentContainer
      design={props.blockData.design}
      >
        <VideoBlockVideoContainer
        className={`${props.view ? props.view : ''}`}
        >
          {props.blockData.video_url ? (
            <>
            <VideoBlockVideoUploadContainer
            className="gp-pb-video-in-block-preview-video-upload-container"
            >
              {getVideoUploadMarkup()}
            </VideoBlockVideoUploadContainer>
            </>
          ) : 
            <VideoBlockVideoEmptyContainer
            >
              <VideoBlockVideoEmptyIconContainer
              >
                <PlayIcon
                />
              </VideoBlockVideoEmptyIconContainer>
            </VideoBlockVideoEmptyContainer>
          }
        </VideoBlockVideoContainer>
      </BlockContentContainer>
    </BlockContainer>
  )
}

export default VideoBlockPreview;