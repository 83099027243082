export enum LibraryType {
    PDF = "pdf",
    AUDIO = "audio",
    VIDEO = "video",
    COURSE = "course",
    EMBED = "embed",
    ARTICLE = "article",
}

export enum LibraryAccess {
    PAID = "paid",
    FREE = "free",
    PRICED = "priced",
    PLAN = "plan",
    SEGMENT = "segment",
}

export type LibraryItem = {
    type: LibraryType;
    title: string;
    summary: string;
    description: string;
    description_json?: string | null;
    access: LibraryAccess;
    content_url: string;
    original_file_name: string;
    file_size: number;
    storage_id: string;
    discussion: boolean;
    downloadable: boolean;
    requires_moderation: boolean;
    thumbnail_image?: string | null;
    slug: string;
    plan_ids: Array<any>;
    segment_ids: Array<any>;
    currency?: string;
    visibility: ContentVisibility;
    price: any;
    display_authorized_plans: boolean;
    lock_screen_active: boolean;
    lock_screen_btn_label: string;
    lock_screen_btn_url: string;
    lock_screen_instructions: string;
    post_title_required: boolean;
    disable_comments_and_reactions: boolean;
    cover_image_required: boolean;
}

export enum ContentType {
    PDF= "pdfs",
    AUDIO = "audios",
    VIDEO = "videos",
    COURSE = "courses",
    EMBED = "embeds",
    ARTICLE = "articles",
    CATEGORY = "category"
}

export enum ContentVisibility {
    COMMUNITY = "member",
    PUBLIC = "public",
    RESTRICTED = "restricted",
}

export enum LibraryTabs {
    DETAILS = "details",
    MEMBERS = "members",
    PRICING = "pricing",
}