import React from 'react';

const AddIcon = (props: any) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 0C7.16421 0 7.5 0.335786 7.5 0.75L7.5 6L12.75 6C13.1642 6 13.5 6.33579 13.5 6.75C13.5 7.16421 13.1642 7.5 12.75 7.5L7.5 7.5L7.5 12.75C7.5 13.1642 7.16421 13.5 6.75 13.5C6.33579 13.5 6 13.1642 6 12.75L6 7.5L0.75 7.5C0.335786 7.5 0 7.16421 0 6.75C0 6.33579 0.335786 6 0.75 6L6 6L6 0.75C6 0.335786 6.33579 0 6.75 0Z" fill={props.fill ? props.fill : "#3A394C"}/>
    </svg>
  )
}

export default AddIcon;