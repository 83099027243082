import React from 'react';
import { deviceDetect } from "react-device-detect";
import publicIp from "public-ip";

const useUAIP = () => {

    const [ua, setUa] = React.useState<string>('');
    const [ip, setIp] = React.useState<string>('');

    React.useEffect(() => {
        // Getting browser user-agent.
        const browserInfo = deviceDetect();
        setUa(browserInfo.userAgent);
    
        // Getting IP of user
        (async () => {
          const ipV4 = await publicIp.v4();
          setIp(ipV4);
        })();
    }, []);

    return {
        ua,
        ip,
    }
}

export default useUAIP;