import { UserService } from 'api/services/users';
import { useDispatch } from 'react-redux';
import { globalActions } from 'store/actions';
import { ISlideoutProfileData } from 'types';
// import { getCookieValue } from 'utils/cookieStorage';

function useSlideoutProfile() {
    const dispatch = useDispatch();

    const openSlideout = (slideoutData: ISlideoutProfileData, openedProfile?: any) => {
        if (slideoutData.user) {

            // const sliderStatus = getCookieValue("profile_slider");

            // if (sliderStatus) {
            //     closeSlideout();
            // } else {
                dispatch(globalActions.openUserProfileSlider());
                dispatch(globalActions.setSlideoutProfileData(slideoutData));
                lockBackground();
            // }
            // dispatch(globalActions.toggleUserProfileSlider());
        }
    }

    const closeSlideout = () => {
        dispatch(globalActions.closeUserProfileSlider());

        setTimeout(() => {
            dispatch(globalActions.clearSlideoutProfileData());
        }, 100)

        unlockBackground();
    }

    function unlockBackground() {
        document.body.classList.remove("stop-scrolling");
    }

    function lockBackground() {
        document.body.classList.add("stop-scrolling");
    }

    const fetchUserAndOpenSlideout = (groupId: number, userId: number) => {
        UserService.getInstance()
            .getUserMembership(groupId, userId)
            .then((response: any) => {
                openSlideout({ user: response });
            })
    }

    return { openSlideout, closeSlideout, lockBackground, unlockBackground, fetchUserAndOpenSlideout };
}

export default useSlideoutProfile;