import React from 'react';
import anchorme from 'anchorme';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import styled from 'styled-components';
import BlockTextContainer from '../BlockComponents/BlockTextContainer';
import { IFAQBlockPreviewProps } from './IFAQBlockPreviewProps';
import FAQInBlockPreview from './FAQInBlockPreview';
import { isValidHexColor } from 'components/PageBuilder/PageBuilderSideBar/BlockSettings/domain';

const FAQBlockContentContainer: any = styled(BlockContentContainer)`
    align-items: ${(props: any) =>
        props.design.text_alignment === 'right' ? 
        `flex-end`:
        props.design.text_alignment === 'left' ?
        'flex-start' :
        'center'
    };
`;

const FAQBlockQuestionsContainer: any = styled.div<{design: any}>`
    width: 100%;
    margin-top: 25px;
    border: ${(props: any) => 
        props.design.question_container_border_color && 
        isValidHexColor(props.design.question_container_border_color) ?
        `${
            props.design.question_container_border_width ?
            props.design.question_container_border_width :
            1
        }px solid ${props.design.question_container_border_color}` :
        `${
            props.design.question_container_border_width ?
            props.design.question_container_border_width :
            1
        }px solid #D5D8DF`
    };
`;

const FAQBlockPreview: React.FC<IFAQBlockPreviewProps> = (props) => {

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <FAQBlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
          <BlockTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="heading"
          fallbackTextColor="#000000"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.heading),
          }}
          />

          <FAQBlockQuestionsContainer
          design={props.blockData.design}
          >
            {props.blockData.blocks.map((block: any, index: number) => (
                <FAQInBlockPreview
                key={block.render_id}
                buildingPreview={props.buildingPreview}
                index={index}
                blockData={{
                    ...block,
                    design: {
                        ...props.blockData.design,
                    }
                }}
                questionsLength={props.blockData.blocks.length}
                view={props.view}
                pageStyles={props.pageStyles}
                />
            ))}
          </FAQBlockQuestionsContainer>
        </FAQBlockContentContainer>
    </BlockContainer>
  )
}

export default FAQBlockPreview;