import React from "react";
import "./GroupListCard.scss";
import { IGroupListCardProps } from "./IGroupListCardProps";
import { Map } from "immutable";
import { push } from "connected-react-router";
import { withRouter } from "react-router";
import { connect } from "react-redux";
// import { GroupService } from "../../../api/services/groups";
import miscUtils from "../../../utils/miscUtils";
import ReadMore from "../../../utils/readMore/readMore";
// import appConfig from "appConfig";

const GroupCard: React.FC<IGroupListCardProps> = (props: any) => {
  // const [paidExists, setPaidExist] = useState<boolean>(false);
  // const [freePlanId, setFreePlanId] = useState<any>();

  // useEffect(() => {
  //   if (props.group && props.group.plans) {
  //     const _tempPlans = miscUtils.prepareGroupPlans(props.group.plans);
  //
  //     _tempPlans.forEach(function(plan: any) {
  //       setPlanId(plan);
  //     });
  //   }
  // }, [props.group]);

  // function setPlanId(plan: any) {
  //   if (plan.name === "free") {
  //     setFreePlanId(plan.id);
  //   }
  //   if (plan.interval === "year") {
  //     setPaidExist(true);
  //   }
  //   if (plan.interval === "month" && plan.interval_count === 3) {
  //     setPaidExist(true);
  //   }
  //   if (plan.interval === "month") {
  //     setPaidExist(true);
  //   }
  //   if (plan.interval === "one_time") {
  //     setPaidExist(true);
  //   }
  // }

  // function buttonAction() {
  //   if (props.group.membership_status !== "member") {
  //     if (
  //       props.group &&
  //       props.group.payment_enabled &&
  //       props.group.membership_status !== "member" &&
  //       props.group.membership_status !== "requested"
  //     ) {
  //       if (paidExists) {
  //         if (props.subdomain) {
  //           window.location.assign(
  //             `http://${props.group.subdomain}.${appConfig.domain.baseDomain}/plan-subscription`
  //           );
  //         } else {
  //           props.history.push(`/group/${props.group.slug}/plan-subscription`);
  //         }
  //       } else {
  //         membershipRequest(freePlanId);
  //       }
  //     }
  //
  //     if (
  //       props.group &&
  //       !props.group.payment_enabled &&
  //       props.group.membership_status !== "member" &&
  //       props.group.membership_status !== "requested"
  //     ) {
  //       joinGroup();
  //     }
  //   }
  //
  //   if (props.group.membership_status === "requested") {
  //     cancelRequest();
  //   }
  //
  //   if (props.group.membership_status === "member") {
  //     leaveGroup();
  //   }
  // }

  // function membershipRequest(groupPlanId: any) {
  //   GroupService.getInstance()
  //     .membershipRequest(props.uid, props.group.id, "", groupPlanId)
  //     .then((response: any) => {
  //       props.onGroupRequest();
  //     });
  // }

  function goToGroup() {
    window.location.assign(miscUtils.getRedirectBaseUrl(props.group))
  }

  // function joinGroup() {
  //   GroupService.getInstance()
  //     .joinGroup(props.uid, props.group.id)
  //     .then((response: any) => {
  //       props.onGroupRequest();
  //     });
  // }

  // function leaveGroup() {
  //   GroupService.getInstance()
  //     .leaveGroup(props.uid, props.group.id)
  //     .then((response: any) => {
  //       props.onGroupRequest();
  //     });
  // }

  // function cancelRequest() {
  //   GroupService.getInstance()
  //     .leaveGroup(props.uid, props.group.id)
  //     .then(() => {
  //       props.onGroupRequest();
  //     });
  // }

  // function headerButtonLabel() {
  //   if (props.isOwner) {
  //     return <span>Owner</span>;
  //   }
  //   if (props.group.membership_status === "member") {
  //     return (
  //       <>
  //         <span>Leave</span>
  //       </>
  //     );
  //   }
  //   if (props.group.membership_status === "requested") {
  //     return <span>Cancel Request</span>;
  //   }
  //   if (
  //     props.group.membership_status !== "member" &&
  //     props.group.membership_status !== "requested"
  //   ) {
  //     return <span>Join</span>;
  //   }
  // }

  return (
    <div className="card card-recommendation flex flex-justify-space-between dashboard-card">
      <div className="card-recommendation--content flex">
        <div className="card-recommendation--content-media">
          {!props.group.profile_image && (
            <div
              className="group-name flex flex-justify-center flex-align-center"
              data-color={miscUtils.getColorInitial(`${props.group.name}`)}
            >
              {miscUtils.getNameInitials(props.group.name)}
            </div>
          )}
          {props.group.profile_image && (
            <img
              src={props.group.profile_image}
              alt=""
              className={"card-recommendation--content-image"}
            />
          )}
        </div>
        <div className="card-recommendation--content-page-info">
          <h3 onClick={() => goToGroup()}>{props.group.name}</h3>
          <span className="page--group-type">{props.group.status}</span>
          <div className="page--group-content group-description">
            {props.group.description.length > 280 ? (
              <ReadMore
                text={props.group.description}
                numberOfLines={1}
                lineHeight={1}
                showLessButton={true}
                onContentChange={() => {}}
                className='page--group-content group-description'
              />
            ) : (
              props.group.description
            )}
          </div>
          <span className="page--member-count">
            {props.group.members_count} member
            {(props.group.members_count > 1 ||
              props.group.members_count === 0) &&
              "s"}
          </span>
        </div>
      </div>
      <div className="card-recommendation--action">
        <button
          className="btn btn-primary auto-height"
          onClick={() => {
            window.location.assign(miscUtils.getRedirectBaseUrl(props.group))
          }}
        >
          {/*{headerButtonLabel()}*/}
          Launch
          <img
            src={require("./arrow-right.svg")}
            alt=""
          />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: IGroupListCardProps) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const uid: string = state.getIn(["authorize", "uid"]) as string;
  return {
    global,
    uid
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IGroupListCardProps) => {
  return {
    viewGroup: (groupSlug: any) => {
      dispatch(push(`/group/${groupSlug}`));
    }
    /*joinGroup: (userId: number, groupId: number) => {
      dispatch(groupActions.dbGroupJoin(userId, groupId));
    },
    leaveGroup: (userId: number, groupId: number) => {
      dispatch(groupActions.dbGroupLeave(userId, groupId));
    }*/
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupCard as any) as any
) as any;
