import React from 'react'

const MenuIcon = (props: any) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 6.87598H21" stroke={props.strokeColor ? props.strokeColor : '#666666'} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 12.876H21" stroke={props.strokeColor ? props.strokeColor : '#666666'}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 18.876H21" stroke={props.strokeColor ? props.strokeColor : '#666666'}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 6.87598H3.01" stroke={props.strokeColor ? props.strokeColor : '#666666'}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 12.876H3.01" stroke={props.strokeColor ? props.strokeColor : '#666666'}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 18.876H3.01" stroke={props.strokeColor ? props.strokeColor : '#666666'}  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default MenuIcon