import React from 'react';

const Home: React.FC<{ strokeColor?: string }> = ({ strokeColor }) => {
  return (
    <>
      {
        strokeColor
          ?
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4774 1.33636C11.8197 1.24368 12.1805 1.24368 12.5228 1.33636C12.9202 1.44395 13.2547 1.7066 13.5218 1.91623C15.8251 3.72462 18.1429 5.51471 20.4564 7.31011C20.833 7.6024 21.1648 7.8599 21.4121 8.19421C21.6291 8.48759 21.7907 8.8181 21.8891 9.1695C22.0012 9.56992 22.0007 9.98993 22.0002 10.4667C21.9976 12.924 22.0001 15.3813 22.0001 17.8386C22.0002 18.3657 22.0002 18.8204 21.9696 19.195C21.9373 19.5904 21.866 19.9836 21.6732 20.362C21.3855 20.9264 20.9266 21.3854 20.3621 21.673C19.9837 21.8658 19.5905 21.9371 19.1952 21.9694C18.3937 22.0349 17.5807 21.9996 16.777 22C16.6579 22 16.5156 22.0001 16.3903 21.9899C16.2451 21.978 16.0371 21.9475 15.8192 21.8365C15.5369 21.6927 15.3074 21.4632 15.1636 21.181C15.0526 20.963 15.0221 20.755 15.0103 20.6098C15 20.4845 15.0001 20.3422 15.0001 20.2231L15.008 14.6269C15.0088 14.0661 15.0092 13.7857 14.9003 13.5715C14.8045 13.3831 14.6515 13.2298 14.4632 13.1338C14.2492 13.0246 13.9688 13.0246 13.408 13.0246H10.6082C10.0488 13.0246 9.76916 13.0246 9.55541 13.1334C9.36738 13.2291 9.21443 13.3818 9.11846 13.5697C9.00936 13.7833 9.00896 14.063 9.00816 14.6223L9.00014 20.2231C9.00019 20.3422 9.00025 20.4845 8.99001 20.6098C8.97814 20.755 8.9477 20.963 8.83665 21.181C8.69284 21.4632 8.46337 21.6927 8.18113 21.8365C7.96318 21.9475 7.75519 21.978 7.60997 21.9899C7.48465 22.0001 7.3424 22 7.22329 22C6.4196 21.9996 5.60663 22.0349 4.80511 21.9694C4.40977 21.9371 4.01655 21.8658 3.63817 21.673C3.07368 21.3854 2.61474 20.9264 2.32712 20.362C2.13433 19.9836 2.06301 19.5904 2.03071 19.195C2.00011 18.8204 2.00012 18.3657 2.00014 17.8385C2.00014 15.3812 2.00273 12.9239 2.00007 10.4667C1.99955 9.98993 1.9991 9.56992 2.11118 9.1695C2.20954 8.8181 2.37119 8.48759 2.58818 8.19421C2.83544 7.85991 3.16725 7.6024 3.54388 7.31012C5.85732 5.51479 8.17524 3.72458 10.4785 1.91623C10.7455 1.7066 11.0801 1.44395 11.4774 1.33636Z"
              fill={strokeColor}
            />
          </svg>
          :
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9823 2.764C12.631 2.49075 12.4553 2.35412 12.2613 2.3016C12.0902 2.25526 11.9098 2.25526 11.7387 2.3016C11.5447 2.35412 11.369 2.49075 11.0177 2.764L4.23539 8.03912C3.78202 8.39175 3.55534 8.56806 3.39203 8.78886C3.24737 8.98444 3.1396 9.20478 3.07403 9.43905C3 9.70352 3 9.9907 3 10.5651V17.8C3 18.9201 3 19.4801 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21H8.2C8.48003 21 8.62004 21 8.727 20.9455C8.82108 20.8976 8.89757 20.8211 8.9455 20.727C9 20.62 9 20.48 9 20.2V13.6C9 13.0399 9 12.7599 9.10899 12.546C9.20487 12.3578 9.35785 12.2049 9.54601 12.109C9.75992 12 10.0399 12 10.6 12H13.4C13.9601 12 14.2401 12 14.454 12.109C14.6422 12.2049 14.7951 12.3578 14.891 12.546C15 12.7599 15 13.0399 15 13.6V20.2C15 20.48 15 20.62 15.0545 20.727C15.1024 20.8211 15.1789 20.8976 15.273 20.9455C15.38 21 15.52 21 15.8 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4801 21 18.9201 21 17.8V10.5651C21 9.9907 21 9.70352 20.926 9.43905C20.8604 9.20478 20.7526 8.98444 20.608 8.78886C20.4447 8.56806 20.218 8.39175 19.7646 8.03913L12.9823 2.764Z" stroke="#767A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

      }

    </>
  )
}



export default Home