import React from 'react'

import miscUtils from 'utils/miscUtils'
import PrivilegeBadge from '../privilegeBadge'
import { getCookieValue } from 'utils/cookieStorage'
import { ToastType, toast } from 'react-toastify';
import { showActiveCHatAtom, showMessagePopUpAtom } from 'showMessageMenuAtom';
import { useAtom } from 'jotai';
import { DirectChatsContext } from 'containers/main/MainComponent';

const MiniProfile = (
    { fullName,
        nonCommunityMember,
        setShowMiniProfile,
        ProfileImage,
        determinePrivilege,
        openProfileSlider,
        user,
        userDetails,
        isUserAuthed
    }:
        {
            fullName: string,
            nonCommunityMember?: boolean,
            setShowMiniProfile: (value: boolean) => void,
            ProfileImage: string,
            determinePrivilege: () => string,
            openProfileSlider: () => void,
            user: any,
            userDetails?: any
            isUserAuthed?: any
        }
) => {

    const [, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);
    const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);

    const {
        directChats,
        setChatroom,
        setIsCreateDirectchat,
        group,
        // setChatHistory,
        // chatHistory
    } = React.useContext(DirectChatsContext)

    // function getUserChatHistory(userID: number) {
    //     MessagingService.getInstance()
    //         .fetchUserChatHistory(group && group.id, userID)
    //         .then((res: any) => {
    //             setChatHistory(res && res.data)
    //             if (res && res.data.id) {
    //                 setActiveChatroomMessage(res && res.data.id)
    //                 console.log('UserId', user && user.id, res && res.data)
    //                 setOpenMessagePopUp(true)
    //             }
    //         })
    // }

    function getExistingChat() {
        if (isUserAuthed) {
            if (directChats && directChats.length > 0) {
                return directChats.find((chat: { members: any[]; }) => {
                    return chat.members.some((member: any) => {
                        return member.community_member.user && member.community_member.user.uuid === (user && user.uuid);
                    });
                });
            }
        }
    }

    const handleClick = () => {
        const member = { community_member: userDetails && userDetails };
        if ((userDetails && ((!userDetails.can_chat) && !miscUtils.isTeamMember(userDetails))) || !userDetails) {
            toast("Messaging has been disabled for this member.", {
                type: ToastType.ERROR,
                autoClose: 2000,
            });
            return;
        }
        const existingChat = getExistingChat();

        if (existingChat !== null && existingChat !== undefined) {
            localStorage.setItem("isShowChatroom", "yes")
            setActiveChatroomMessage(existingChat.id)
            setOpenMessagePopUp(true)
            document.body.style.overflow = 'hidden';
            localStorage.setItem("activeChatroomId", existingChat.id);
        }
        else {
            if (group && group.messaging_allowed && group.user_membership && group.user_membership.can_chat) {

                // getUserChatHistory(user && user.id)

                // if (Object.keys(chatHistory).length === 0) {
                const _chatroom = {
                    description: "",
                    members: [{ community_member: group && group.user_membership }, member && member],
                    members_count: 2,
                    owner: group.user_membership,
                    title: "",
                    type: "individual",
                    group_id: group.id,
                    created_at: null,
                    member_id: member && member.community_member && member.community_member.id
                }
                const temp: any = 'temp'
                setChatroom(_chatroom);
                setIsCreateDirectchat(true);
                setActiveChatroomMessage(temp)
                setOpenMessagePopUp(true)
                localStorage.setItem("isShowChatroom", "yes")
            }
            // }
            else {
                toast("Messaging has been disabled.", {
                    type: ToastType.ERROR,
                    autoClose: 2000,
                });
                return;
            }
        }
    };

    const cookieUserID = getCookieValue("user") && getCookieValue("user")['id']

    return (
        <div
            className="mini-profile"
            style={{
                visibility: nonCommunityMember ? 'hidden' : 'initial'
            }}
            onMouseEnter={() => setShowMiniProfile(true)}
            onMouseLeave={() => setShowMiniProfile(false)}
        >
            <div className="mini-profile-upper">
                {
                    ProfileImage ?
                        <img
                            alt='profile image'
                            className="profile-image"
                            src={ProfileImage}
                        />
                        :
                        <div
                            className="no-profile-image"
                            data-color={miscUtils.getColorInitial(`${fullName}`)}
                        >
                            {miscUtils.getNameInitials(fullName)}
                        </div>
                }

                <div className="mini-profile-names">
                    <span className="member-name">{fullName} </span>
                    <PrivilegeBadge privilege={determinePrivilege()} />
                </div>
            </div>
            {
                cookieUserID &&
                <div className='mini-profile-lower'>
                        <button
                            className="view-profile-btn"
                            onClick={() => openProfileSlider()}
                        >
                            View profile
                        </button>
                        {
                            cookieUserID !== (user && user.id) &&
                            <button
                                className="message-btn"
                                    onClick={() => {
                                        handleClick()
                                    }}
                            >
                                Message
                            </button>
                        }
                    </div>
            }

        </div>
    )
}

export default MiniProfile