import React from 'react'
import ContentLoader from 'react-content-loader'

const ViewEventLoader = ({ width, thumbnailHeight }: { width: number, thumbnailHeight: number }) => {

    const imageHeight = thumbnailHeight
    const height = imageHeight + 140;
    const space = 10

    return (
        <div
            style={
                {
                    width: "100%",
                    padding: `${0}px`,
                    overflow: "hidden",
                    maxHeight: "calc(100vh - 100px)",
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                }
            }>
            <div
            style={{ 
                backgroundColor: '#ffffff', 
                border: `1px solid #d5d8df`,
                overflow: `hidden`, 
            }}>
                <ContentLoader
                    viewBox={`0 0 ${width} ${width === 300 ? height - 50 : height - 80}`}
                    width={width}
                    height={width === 300 ? height - 50 : height - 80}
                    primaryColor={"#dbdbdb"}
                    secondaryColor="#ecebeb"
                >
                    <rect x="0" y={0} width={width} height={imageHeight} rx="5" ry="0" />

                    <rect x="10" y={15 + imageHeight} width={width * (3 / 4)} height="15" rx="3" />

                    <rect x="10" y={35 + imageHeight} width="16" height="16" rx="3" />
                    <rect x="30" y={35 + imageHeight} width={width / 2} height="5" rx="3" />
                    <rect x="30" y={45 + imageHeight} width={width / 4} height="5" rx="3" />

                    {
                        width === 300 ?
                            <>
                                <circle cx={20} cy={70 + imageHeight} r="10" />
                                <circle cx={30} cy={70 + imageHeight} r="10" />
                                <circle cx={40} cy={70 + imageHeight} r="10" />
                            </>
                            :
                            <>
                                <circle cx={width - 35} cy={40 + imageHeight} r="10" />
                                <circle cx={width - 20} cy={40 + imageHeight} r="10" />
                                <circle cx={width - 50} cy={40 + imageHeight} r="10" />
                            </>
                    }
                </ContentLoader>
            </div>

            <div
                style={
                    {
                        marginBlock: '16px',
                        width: "100%",
                        display: 'flex',
                        flexDirection: width === 300 ? 'column' : 'row'
                    }}
            >
                <div
                    style={
                        {
                            backgroundColor: '#ffffff',
                            marginRight: '16px',
                            width: width === 300 ? "100%" : '65%',
                            marginBottom: '16px',
                            border: `1px solid #d5d8df`,
                        }}
                >

                    <ContentLoader
                        viewBox={`0 0 ${width} ${height - 50}`}
                        width={width}
                        height={height - 50}
                        primaryColor={"#dbdbdb"}
                        secondaryColor="#ecebeb"
                    >

                        <rect x="10" y={imageHeight - (space * 13)} width={(width / 3) + (space * 8)} height="10" rx="3" />
                        <rect x="10" y={imageHeight - (space * 10)} width={width - (space * 8)} height="10" rx="3" />

                        <rect x="10" y={imageHeight - (space * 7)} width={width / 3} height="10" rx="3" />
                        <rect x="10" y={imageHeight - (space * 5)} width={width / 3} height="10" rx="3" />
                        <rect x="10" y={imageHeight - (space * 3)} width={width / 3} height="10" rx="3" />

                        <circle cx='30' cy={imageHeight + 10} r="20" />
                        <rect x="60" y={imageHeight} width={width / 2} height="10" rx="3" />

                        <rect x="10" y={imageHeight + 35} width={width - (space * 3)} height="20" rx="3" />
                    </ContentLoader>
                </div>

                <div
                    style={
                        {
                            backgroundColor: '#ffffff',
                            width: width === 300 ? "100%" : '45%',
                            height: width === 300 ? '100%' : "50%",
                            border: `1px solid #d5d8df`,
                        }}
                >

                    <ContentLoader
                        viewBox={`0 0 ${width} ${height - 150}`}
                        width={width}
                        height={height - 120}
                        primaryColor={"#dbdbdb"}
                        secondaryColor="#ecebeb"
                    >

                        <rect x="10" y={imageHeight - (space * 13)} width={(width / 3) + (space * 8)} height="10" rx="3" />
                        <rect x="10" y={imageHeight - (space * 10)} width={(width / 3) + (space * 8)} height="10" rx="3" />

                        <rect x="10" y={imageHeight - (space * 7)} width={width / 3} height="10" rx="3" />
                        <rect x="10" y={imageHeight - (space * 5)} width={width / 3} height="10" rx="3" />
                        <rect x="10" y={imageHeight - (space * 3)} width={width / 3} height="10" rx="3" />
                    </ContentLoader>
                </div>
            </div>
        </div>
    )
}

export default ViewEventLoader