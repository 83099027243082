import { ICourseService } from "../../../core/services/course";
import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class EventsService implements ICourseService {
  private dbInstance: any = databaseInstance;

  private static instance: EventsService;

  constructor() {
    EventsService.instance = this;
  }

  public static getInstance() {
    if (!EventsService.instance) {
      EventsService.instance = new EventsService();
    }
    return EventsService.instance;
  }

  public uploadCoverImage: (eventId: number, image: any) => Promise<void> = (
    eventId,
    image
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/events/${eventId}/cover-image`, image)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getEvent: (groupId: number, eventId: any, query?: any) => Promise<void> = (groupId, eventId, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/groups/${groupId}/events/${eventId}${query}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });
    });
  };

  public publishEvent: (eventId: number) => Promise<void> = eventId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/events/${eventId}/published-events`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public unpublishEvent: (courseId: number) => Promise<void> = eventId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/events/${eventId}/published-events`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateCoverImage: (
    eventId: number,
    coverImage: any
  ) => Promise<void> = (eventId, coverImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/events/${eventId}/cover-image`, coverImage)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getEvents: (
    groupId: number,
    query?: string,
    page?: number
  ) => Promise<void> = (
    groupId,
    query = "",
    page = 1
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .get(`/groups/${groupId}/events?page=${page}${query}`)
          .then((response: any) => {
            resolve(response);
          });
      });
    };

    public getAllEvents: (
      groupId: number,
      query?: string,
    ) => Promise<void> = (
      groupId,
      query = "",
    ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
        .get(`/groups/${groupId}/events${query}`)
          .then((response: any) => {
            resolve(response);
          });
      })
    }

  public getAvailableEventsForCourses: (
    groupId: number,
    query?: string,
  ) => Promise<void> = (
    groupId,
    query = "",
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/events/available-events-for-courses${query}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch(() => reject());
    })
  }

  public deleteEvent: (eventId: number) => Promise<void> = eventId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`/events/${eventId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public createEvent: (
    groupId: number,
    payload: any
  ) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/events`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public updateEvent: (
    groupId: number,
    eventId: number,
    payload: any
  ) => Promise<void> = (
    groupId,
    eventId,
    payload
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(`/groups/${groupId}/events/${eventId} `, {
            ...payload
          })
          .then((response: any) => {
            resolve(response);
          })
          .catch(() => reject());
      });
    };

  public rsvpEvent: (
    eventId: number
  ) => Promise<void> = (eventId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/events/${eventId}/rsvps`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public removeEventRsvp: (
    eventId: number
  ) => Promise<void> = (eventId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/events/${eventId}/rsvps`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public getEventAttendees: (
    eventId: number,
    query?: string,
    limit?: string
  ) => Promise<void> = (
    eventId,
    query,
    limit
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .get(`/events/${eventId}/rsvps${query && query.length > 0 ? query : ''}${limit ? limit : ''}`)
          .then((response: any) => {
            resolve(response);
          });
      });
    };

  public loadMore: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getEventPosts: (
      groupId: number, 
      eventId: number
    ) => Promise<void> = ( groupId, eventId )=> {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/postables/${eventId}/posts?postable_type=event&sort=published_at:desc&include=comments&include=postable`
        )
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public createEventPost: (
    groupId: number,
    eventId: number,
    postObject: any
  ) => Promise<void> = (groupId, eventId, postObject) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/postables/${eventId}/posts`, postObject)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public exportAttendeesList: (
    eventId: number
  ) => Promise<void> = (eventId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/events/${eventId}/rsvps/export`)
        .then((response: any) => {
          if (response) {
            resolve(response);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public getEventUninvitedMembers: (
    eventId: string,
    query?: string,
  ) => Promise<void> = (eventId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/events/${eventId}/non-invites?search=${encodeURIComponent(query)}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public getEventInvitedMembers: (
    eventId: string,
    query?: string,
  ) => Promise<void> = (eventId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/events/${eventId}/invites?search=${encodeURIComponent(query)}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
    };

  public loadMoreEventInvitedMembers: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public inviteEventAttendees: (
    eventId: number,
    userIds: any
  ) => Promise<void> = (eventId, userIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/events/${eventId}/invites`, { user_ids: userIds })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public inviteAllEventAttendees: (eventId: number) => Promise<void> = (eventId,) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/events/${eventId}/invites`, {})
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  };

  public removeInvitedAttendee: (
    eventId: number, 
    userId: number
  ) => Promise<void> = (eventId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/events/${eventId}/invites`, {
          data: {
            user_ids: [userId]
          }
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public purchaseEvent: (
    eventId: number, 
    paymentMethodId: number
  ) => Promise<void> = (eventId, paymentMethodId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/events/${eventId}/rsvps`, 
          {
            payment_method_id: paymentMethodId
          }
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public featureEvent: (
    groupId: number,
    eventId: number, 
  ) => Promise<void> = (groupId, eventId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/events/${eventId}/featured`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public unfeatureEvent: (
    groupId: number,
    eventId: number, 
  ) => Promise<void> = (groupId, eventId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/events/${eventId}/featured`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public getFeaturedEvents: (
    groupId: number,
  ) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/events/featured`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });;
    });
  };

  public addEventHosts: (
    groupId: number,
    eventId: number,
    userData: any
  ) => Promise<void> = (groupId, eventId, userData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/events/${eventId}/hosts`, userData)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  };

  public deleteEventHosts: (
    groupId: number,
    eventId: number,
    userId: any
  ) => Promise<void> = (groupId, eventId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`groups/${groupId}/events/${eventId}/hosts/${userId}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  };

  public editEventHosts: (
    groupId: number,
    eventId: number,
    userId: any,
    payload: any
  ) => Promise<void> = (groupId, eventId, userId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/events/${eventId}/hosts/${userId}`, payload)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  };

  public editEventReminder: (
    groupId: number,
    eventId: number,
    reminderId: number,
    payload: any
  ) => Promise<void> = (groupId, eventId, reminderId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`groups/${groupId}/events/${eventId}/reminders/${reminderId}`, payload)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  };

  public deleteEventReminder: (
    groupId: number,
    eventId: number,
    reminderId: number,
  ) => Promise<void> = (groupId, eventId, reminderId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`groups/${groupId}/events/${eventId}/reminders/${reminderId}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  };

}
