import React from 'react';
import Tooltip from 'react-tooltip-lite';
import "./AppToolTip.scss";

const AppToolTip = (props: any) => {
  return (
    <Tooltip
    content={props.content}
    zIndex={999999}
    useDefaultStyles
    className="react-tooltip-lite--wrapper"
    >
      {props.children}
    </Tooltip>
  );
};

export default AppToolTip;

