import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { ILoginProps } from "./ILoginProps";
import { Link, withRouter } from "react-router-dom";
import { Map } from "immutable";
import { authorizeActions, globalActions } from "store/actions";
import { connect } from "react-redux";
import "./Login.scss";
import "./login-responsive.scss";
import { deviceDetect } from "react-device-detect";
import publicIp from "public-ip";
import { GroupService } from "api/services/groups";
import { toast, ToastType } from "react-toastify";
import { LoginViews } from "./domain";
import miscUtils from "utils/miscUtils";
import Button from "elements/button/Button";
import { ILoginComponentProps } from "containers/login/ILoginComponentProps";
import useForm from "utils/validator/validator";
import { ILoginComponentState } from "containers/login/ILoginComponentState";
import AuthenticationLayout from "../AuthenticationLayout";
import { groupDataLoadingAtom } from "showMessageMenuAtom";
import { useAtom } from "jotai";
import { getlocalStorageValue, setlocalStorageValue } from "utils/localStorage";

const Login: React.FC<ILoginProps> = (props: any) => {
  const {
    message,
    title,
    subtitle,
    extClass,
    noAccount,
    funcToClose,
    global,
    email,
    uid,
    sigin,
    onGroupRequest,
  } = props;

  const [formData, setFormData] = useState<ILoginComponentState>({
    email: "",
    password: ""
  });
  const [isErrorOnServer, setIsErrorOnServer] = useState<boolean>(false);
  const [ua, setUa] = useState<string>('');
  const [ip, setIp] = useState<string>('');
  const [loginView, setLoginView] = useState(() => {
    if (miscUtils.OAuthActivated(props.group) || miscUtils.useNbeaFlow(props.group)) {
      return LoginViews.OAUTH;
    }

    return LoginViews.DEFAULT;
  });
  const [isInitiatingSSO, setIsInitiatingSSO] = useState<boolean>(false);
  const [useNBEAFlow, setUseNBEAFlow] = useState<boolean>(() => {
    // return !!props.nbeaFlow;
    return miscUtils.useNbeaFlow(props.group);
  });
  const [groupDataisLoading] = useAtom(groupDataLoadingAtom);

  const search = props.location.search;
  // @ts-ignore
  // eslint-disable-next-line
  const params = new URLSearchParams(search);

  const planUrl = params.get("utm_content");
  const nextLink = params.get("to");

  const activePlans = props.group && props.group.plans.filter((plan: any) => (
    plan.archived_at === null && !plan.hidden
  ));


  useEffect(() => {
    if (nextLink) {
      setlocalStorageValue("next_link", `about?section=${nextLink}`);

      // A temp workaround to prevent the param from being appended
      //  to the sign in url after logout
      setTimeout(() => {
        window.location.reload()
      }, 10000);
    }
  }, [params]);
  const event_toast_displayed = getlocalStorageValue('event_toast_displayed') && getlocalStorageValue('event_toast_displayed').value
  const eventLink = getlocalStorageValue('next_link') && getlocalStorageValue('next_link').value
  useEffect(() => {
    if (
      eventLink &&
      eventLink.includes('/events/') &&
      !props.authed &&
      !groupDataisLoading &&
      event_toast_displayed
    ) {
      toast(`Log in to view page.`, {
        type: ToastType.ERROR,
        autoClose: 3000
      })
    }
    setTimeout(() => {
      localStorage.removeItem('event_toast_displayed');
    }, 3000);
  }, [event_toast_displayed, eventLink, groupDataisLoading]);

  useEffect(() => {
    setFormData({ ...formData, email })

    // Getting browser user-agent.
    const browserInfo = deviceDetect();
    setUa(browserInfo.userAgent);

    // Getting IP of user
    (async () => {
      const ipV4 = await publicIp.v4();
      setIp(ipV4);
    })();
  }, []);

  useLayoutEffect(() => {
    if (props.isAuthed && props.group) {
      props.history.push("/");
    }
  }, [props.isAuthed, props.group]);

  useEffect(() => {
    if (global && !global.errorOnServer) {
      setIsErrorOnServer(!isErrorOnServer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global, global.errorOnServer]);

  useEffect(() => {
    if (isErrorOnServer) {
      closeDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorOnServer]);

  const initiateOAuthSSO = () => {
    if (props.purchaseView) {
      setlocalStorageValue("next_link", window.location.pathname);
    }
    setIsInitiatingSSO(true);
    GroupService.getInstance()
      .initiateGroupOAuthSSO(props.group.id)
      .then((response: any) => {
        setIsInitiatingSSO(false);
        if (response.data) {
          window.open(`${response.data}`, '_self');
        }
      })
      .catch(() => {
        setIsInitiatingSSO(false);
      })
  }

  const { errors, handleSubmit } = useForm(handleForm, formData);
  const uidRef = useRef(uid);
  uidRef.current = uid;

  function handleForm() {
    const { login } = props;

    const error = false;

    if (!error) {
      const isJoin = params.get("join") === "true" ? true : false;
      const _isInvite = params.get("invite") === "true" ? true : false;

      if(planUrl) {
        setlocalStorageValue("next_link", `/creategroup?utm_content=${planUrl}`);
      }

      login!(formData.email, formData.password, _isInvite, ip, ua, planUrl, isJoin);
      setTimeout(() => {
        if (props.location.pathname.includes("courses")) {
          onGroupRequest();
        }
      }, 1000);
    }
  }

  function nbeaLogin() {
    if (formData.email.trim() === "") {
      toast("Username field is required", {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return;
    }
    if (formData.password.trim() === "") {
      toast("Password field is required", {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return;
    }
    if (props.purchaseView) {
      setlocalStorageValue("next_link", window.location.pathname);
    }
    GroupService.getInstance()
      .nbeaLogin({
        username: formData.email,
        password: formData.password,
      })
      .then((response: any) => {
        const isJoin = params.get("join") === "true";
        const _isInvite = params.get("invite") === "true";
        props.nbeaLogin!(response, _isInvite, ip, ua, planUrl, isJoin);
      })
      .catch((error: any) => {
        toast(`${error.data.message}`, {
          type: ToastType.ERROR,
          autoClose: 3000,
        });
      });
  }

  function closeDialog() {
    if (funcToClose) {
      funcToClose();
    }
  }

  const shouldSignupShow = () => {
    if (props.OAuthActive || props.nbeaFlow) {
      return (
        props.group &&
        props.group.oauth_sso &&
        props.group.oauth_sso.allow_custom_signup
      )
    }

    return true;
  }

  function getSignupElement() {
    if (
      (props.OAuthActive || props.nbeaFlow) &&
      props.group &&
      props.group.oauth_sso &&
      props.group.oauth_sso.allow_custom_signup &&
      props.group.oauth_sso.custom_signup_url
    ) {
      return (
        <button id="signup_link" className="signup_link"
          onClick={() => {
            window.open(`${props.group.oauth_sso.custom_signup_url}`, '_self');
          }}
        >
          Sign up
        </button>
      );
    }
    if (sigin) {
      return (
        <button id="signup_link" className="signup_link"
          onClick={() => {
            if (activePlans && activePlans.length === 0) {
              props.history.push('/signup-disabled')
            } else {
              props.history.push(`/plan-subscription${planUrl ? `?utm_content=${planUrl}` : ""}`)
            }
          }}
        >
          Sign up
        </button>
      );
    }
    return (
      <button
        className="signup_link"
        onClick={() => {
          if (activePlans && activePlans.length === 0) {
            props.history.push('/signup-disabled')
          }
          else {
            const redirectUrl = props.group ? `/plan-subscription${planUrl ? `?utm_content=${planUrl}` : ""}` : `/signup${planUrl ? `?utm_content=${planUrl}` : ""}`
            props.history.push(redirectUrl)
          }
        }}
      >
        Sign up
      </button>
    );
    // <Link
    //   className="link__to-login"
    //   to={
    //     // activePlans && activePlans.length === 0 ? props.history.push('/signup-disabled')
    //     //   :
    //     props.group ? `/plan-subscription${planUrl ? `?utm_content=${planUrl}` : ""}` : `/signup${planUrl ? `?utm_content=${planUrl}` : ""}`
    //   }
    // >
    //   Sign up1
    //   </Link>
    // );
  }

  return (
    <>
      {!groupDataisLoading &&
    <AuthenticationLayout
      group={props.group}
    >
      <div
        className={
          `login-wrapper flex flex-justify-center flex-direction-column ${extClass && extClass.length > 0
            ? `${extClass}` : ""} ${props.nbeaFlow ? 'nbea' : ''
          } ${(title === "Welcome to GroupApp" || props.noTopBorder) ? 'no-border' : ''} `
        }
      >

        <h1>{title}</h1>
        <p>{subtitle}</p>

        {message && message.message && (
          <div className="error-message flex flex-align-center flex-justify-center">
            <p>{message.message}</p>
          </div>
        )}

        {loginView === LoginViews.DEFAULT && (
          <form
            onSubmit={e => e.preventDefault()}
            className={"flex flex-direction-column flex-align-center"}
          >
            <div
              className={errors && errors.email ? "form-group error" : "form-group"}
            >
              <div className="form-group-header flex flex-justify-space-between">
                <label>{useNBEAFlow ? 'Username' : 'Email'}</label>
                <span className="error-label">
                  {errors.email && errors.email.errorComponent}
                </span>
              </div>
              <input
                onChange={e => setFormData({ ...formData, email: e.target.value })}
                name="email"
                type={useNBEAFlow ? "text" : "email"}
                form-datatype={useNBEAFlow ? "text" : 'email'}
                value={formData.email}
                aria-label={useNBEAFlow ? "Username" : "Email"}
              />
            </div>
            <div
              className={
                errors && errors.password ? "form-group error" : "form-group"
              }
            >
              <div className="form-group-header flex flex-justify-space-between">
                <label>Password</label>
                <span className="error-label">
                  {errors.password && errors.password.errorComponent}
                </span>
              </div>
              <input
                onChange={e =>
                  setFormData({ ...formData, password: e.target.value })
                }
                name="password"
                type="password"
                    form-datatype='password'
                aria-label="Password"
              />
            </div>
            {!useNBEAFlow && (
              <div className="link-group flex">
                <Link className="btn btn-link" to="/forgot-password">
                  Forgot Password
                </Link>
              </div>
            )}
            <Button theme={global.theme} onClick={useNBEAFlow ? nbeaLogin : handleSubmit} label="Log In" />
            {(
              (props.OAuthActive || (props.nbeaFlow &&
                props.group &&
                props.group.oauth_sso &&
                props.group.oauth_sso.allow_groupapp_login
              )) &&
                props.group &&
                props.group.oauth_sso &&
                props.group.oauth_sso.oauth_provider ?
                (
                  <div
                    className="gp-login-oauth-container mail-view"
                  >
                    <p
                    >
                      or
                    </p>
                    {props.nbeaFlow ? (
                      <>
                        <button
                          className="gp-login-oauth-action-oauth"
                          onClick={() => {
                            if (useNBEAFlow) {
                              if (props.loginCallback) {
                                props.loginCallback();
                                return;
                              }
                            }
                            setUseNBEAFlow(!useNBEAFlow)
                          }
                          }
                          disabled={isInitiatingSSO}
                          style={miscUtils.getBlockButtonStyle(global.theme)}
                        >
                          {useNBEAFlow ? 'Sign in with an email' : `Continue with ${props.group.oauth_sso.name}`}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="gp-login-oauth-action-oauth"
                          onClick={initiateOAuthSSO}
                          disabled={isInitiatingSSO}
                          style={miscUtils.getBlockButtonStyle(global.theme)}
                        >
                          Continue with {props.group.oauth_sso.name}
                        </button>
                      </>
                    )}
                  </div>
                ) : (<></>)
            )}
          </form>
        )}

        {loginView === LoginViews.OAUTH && props.group && props.group.oauth_sso && (
          <div
            className="gp-login-oauth-container"
          >
            {props.group.oauth_sso.allow_groupapp_login && (
              <button
                className="gp-login-oauth-action-default"
                onClick={() => {
                  if (props.loginCallback) {
                    props.loginCallback();
                    return;
                  }
                  if (props.nbeaFlow) {
                    setUseNBEAFlow(false);
                  }
                  setLoginView(LoginViews.DEFAULT)
                }}
                disabled={isInitiatingSSO}
                style={miscUtils.getBlockButtonStyle(global.theme)}
              >
                Sign in with an email
              </button>
            )}

            <button
              className="gp-login-oauth-action-oauth"
              onClick={() => {
                if (props.nbeaFlow) {
                  setUseNBEAFlow(true);
                  setLoginView(LoginViews.DEFAULT);
                  return;
                }

                initiateOAuthSSO();
              }}
              disabled={isInitiatingSSO}
              style={miscUtils.getOutlineButtonStyle(global.theme)}
            >
              Continue with {props.group.oauth_sso.name}
            </button>
          </div>
        )}
            {
              noAccount &&
              !props.subdomain &&
              shouldSignupShow() &&
              (!props.group || (props.group && !props.group.close_signup)) &&
              (
                <div className="modal-footer">
                  Don't have an account? {getSignupElement()}
                </div>
              )
            }
      </div>
    </AuthenticationLayout>
      }
    </>
  );
};

const mapStateToProps = (state: Map<string, any>) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const { message } = global;
  return {
    global,
    message
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ILoginComponentProps) => {
  return {
    login: (email: string, password: string, isInvite: boolean, ip: any, ua: any, planUrl: any, isJoin: any) => {
      dispatch(globalActions.showTopLoading());
      dispatch(authorizeActions.dbLogin(email, password, isInvite, ip, ua, planUrl, isJoin));
    },
    nbeaLogin: (response: any, isInvite: boolean, ip: any, ua: any, planUrl: any, isJoin: any) => {
      dispatch(authorizeActions.dbOAuthLogin(response, isInvite, ip, ua, planUrl, isJoin));
    },
    logout: () => {
      dispatch(authorizeActions.dbLogout());
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login as any) as any
) as any;
