import React from 'react';
import { ReactComponent as AdminIcon } from '../admin.svg';
import "./PrivilegeBadge.scss";
import { ReactComponent as DeletedUserIcon } from 'assets/img/deleted-user.svg';

const PrivilegeBadge: React.FC<{ privilege: string, notGroupMember?: boolean }> = (props) => {
    return (
        <span
            className="admin-label"
            style={{
                cursor: props.notGroupMember || props.privilege === 'Removed' ? 'auto' : 'pointer'
            }}
        >
            {
                props.privilege === 'Removed'
                    ?
                    <span className='icon'>
                        <DeletedUserIcon />
                    </span>
                    :
                    <AdminIcon />
            }
            {" "}{props.privilege}
        </span>
    )
}

export default PrivilegeBadge
