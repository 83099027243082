import React from 'react';

const ChevronDownIcon = (props: any) => {
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.274175 0.774947C0.449957 0.599384 0.688237 0.500773 0.936675 0.500773C1.18511 0.500773 1.42339 0.599384 1.59918 0.774947L4.99918 4.17495L8.39918 0.774947C8.485 0.682839 8.5885 0.608961 8.7035 0.557721C8.8185 0.506481 8.94264 0.478929 9.06852 0.476708C9.1944 0.474487 9.31944 0.497643 9.43617 0.544794C9.55291 0.591945 9.65895 0.662126 9.74797 0.75115C9.837 0.840173 9.90718 0.946215 9.95433 1.06295C10.0015 1.17969 10.0246 1.30472 10.0224 1.4306C10.0202 1.55648 9.99264 1.68062 9.9414 1.79562C9.89016 1.91062 9.81628 2.01412 9.72417 2.09995L5.66167 6.16245C5.48589 6.33801 5.24761 6.43662 4.99918 6.43662C4.75074 6.43662 4.51246 6.33801 4.33667 6.16245L0.274175 2.09995C0.0986117 1.92417 0 1.68589 0 1.43745C0 1.18901 0.0986117 0.950729 0.274175 0.774947Z" fill={props.fill ? props.fill : "#666666"}/>
    </svg>
  )
}

export default ChevronDownIcon;