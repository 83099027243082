import React from 'react';
import { isValidHexColor } from 'components/PageBuilder/PageBuilderSideBar/BlockSettings/domain';
import anchorme from 'anchorme';
import { IImageTextInGridPreviewProps } from './IImageTextInGridPreviewProps';
import { ButtonAction, VideoType, scrollElementIntoView } from 'containers/PageBuilder/SinglePageContainer/domain';
import { LivePagePreviewView } from 'containers/PageBuilder/LivePagePreviewContainer/domain';
import useWindowSize from 'utils/windowSize';
import styled from 'styled-components';
import { getGridCardMargin, getGridCardWidth, isEdgeCard, isInLastRow, isLastCard } from './domain';
import BlockImage from '../../BlockComponents/BlockImage';
import BlockTextContainer, { BlockSubTextContainer } from '../../BlockComponents/BlockTextContainer';
import BlockButton from '../../BlockComponents/BlockButton';
import { ImageTextBlockTextContainer } from '../../ImageTextBlockPreview/ImageTextBlockPreview';
import { ReactComponent as PlayIcon } from '../../VideoBlockPreview/icons/play.svg';
import BunnyVideoPlayer from 'components/BunnyVideoPlayer';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import WistiaPlayer from 'components/WistiaPlayer';
import { v4 as uuidv4 } from 'uuid';
import { getYouTubeVideoId } from 'containers/CoursesContainer/SectionsList/SectionItem/SectionItemContent/AdminCourseLesson/utis';

const opts = {
    playerVars: {
      rel: 0,
      autoplay: 0,
      cc_load_policy: 0,
      iv_load_policy: 3,
      modestbranding: 1,
    }
};

const ImageTextInGridVideoContainer: any = styled.div`
    width: 100%;
    min-width: 100%;
    height: 215px;

    &.mobile {
        width: 100%;
        min-width: 100%;
        height: 215px;
    }

    @media(max-width: 801px) {
        width: 100%;
        min-width: 100%;
        height: 215px;
    }
`;

const ImageTextInGridVideoUploadContainer: any = styled.div`
    width: 100%;
    height: 100%;

    .video-in-block-preview-yt-player-container {
        width: 100%;
        height: 100%;
    }

    .video-in-block-preview-yt-player {
        width: 100%;
        height: 100%;
    }

    .video-in-block-preview-vimeo-player {
        position: relative;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        iframe,
        object,
        frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
`;

const ImageTextInGridVideoEmptyContainer: any = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D5D8DF;
`;

const ImageTextInGridVideoEmptyIconContainer: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;


const ImageTextInGridContainer: any = styled.div<{
    design: any,
    view: LivePagePreviewView,
    screenWidth: number,
    index: number,
    parentLength: number,
    attachmentShown: boolean,
}>`
    width: ${(props: any) => 
        getGridCardWidth(props.design.cards_per_row ? props.design.cards_per_row : 3)
    };
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: ${(props: any) => 
        props.attachmentShown ? `10px` : `15px`
    };
    background-color: ${(props: any) => 
        props.design.single_container_background_color &&
        isValidHexColor(props.design.single_container_background_color) ?
        props.design.single_container_background_color :
        'transparent'
    };
    border: ${(props: any) => 
        props.design.single_container_border_color &&
        isValidHexColor(props.design.single_container_border_color) ?
        `${
            props.design.single_container_border_width ?
            props.design.single_container_border_width :
            1
        }px solid ${props.design.single_container_border_color }`:
        `${
            props.design.single_container_border_width ?
            props.design.single_container_border_width :
            1
        }px solid #FAFAFA`
    };
    margin-right: ${(props: any) => 
        props.view && props.view === LivePagePreviewView.Mobile ||
        props.screenWidth <= 550 ?
        '0px' :
        isEdgeCard(props.index, props.design.cards_per_row ? props.design.cards_per_row : 3) || isLastCard(props.index, props.parentLength) ? 
        '0px' : 
        getGridCardMargin(props.design.cards_per_row ? props.design.cards_per_row : 3)
    };
    margin-bottom: ${(props: any) => 
        props.view && props.view === LivePagePreviewView.Mobile ||
        props.screenWidth <= 801 ?
        props.index === props.parentLength - 1 ?
        `0px` :
        getGridCardMargin(props.design.cards_per_row ? props.design.cards_per_row : 3, true) :
        isInLastRow(
            props.index, 
            props.parentLength, 
            props.design.cards_per_row ? props.design.cards_per_row : 3
        ) ? 
        '0px' :
        getGridCardMargin(props.design.cards_per_row ? props.design.cards_per_row : 3, true) 
    };
    border-radius: ${(props: any) => 
        props.design.single_container_border_radius &&
        props.design.single_container_border_radius <= 30 ?
        `${props.design.single_container_border_radius}px` :
        `0px`
    };

    &.mobile {
        width: 100%;
    }

    @media(max-width: 801px) {
        width: 100%;
    }
`;

const ImageTextInGridWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImageTextInGridImageContainer: any = styled.div`
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageTextInGridImage: any = styled(BlockImage)`
    width: ${(props: any) => 
        props.design.image_width ?
        `${props.design.image_width}px` :
        'auto'
    };
    height: auto;
    max-width: 100%;
`;

const ImageTextInGridGutter: any = styled.div<{design: any}>`
    height: ${(props: any) => 
        props.design.gutter_height ?
        `${props.design.gutter_height}px` :
        `10px`
    };
`

const ImageTextInGridTextContainer: any = styled(ImageTextBlockTextContainer)`
    padding: 0;
`;

const ImageTextInGridPreview: React.FC<IImageTextInGridPreviewProps> = (props) => {

    const screenWidth = useWindowSize().width;

    const getVideoUploadMarkup = () => {
        if(props.blockData.video_type === VideoType.Uplaod) {
          return (
            <BunnyVideoPlayer
            videoUrl={props.blockData.video_url}
            />
          )
        } else {
          if(props.blockData.video_url.includes('you')) {
            return (
                <YouTube
                // @ts-ignore
                opts={opts}
                videoId={getYouTubeVideoId(props.blockData.video_url)}
                className="video-in-block-preview-yt-player"
                containerClassName="video-in-block-preview-yt-player-container"
                />
            )
          }
    
          if(props.blockData.video_url.includes('vimeo')) {
              return (
                  <Vimeo
                  key={uuidv4()}
                  video={props.blockData.video_url}
                  className="video-in-block-preview-vimeo-player"
                  style={{
                      height: '215px',
                      marginTop: 0,
                      backgroundColor: '#333333',
                  }}
                  />
              )
          }
    
          if(props.blockData.video_url.includes('wistia')) {
              return (
                  <WistiaPlayer
                  videoId={props.blockData.video_url.split("/")[4]}
                  />
              )
          }
    
          return <></>
        }
    }

    const onClickImage = () => {
        if(props.buildingPreview) {
            return;
        }
        
        if(
            props.blockData &&
            props.blockData.image_link
        ) {
            window.open(props.blockData.image_link, '_blank');
        }
    }

    const ctaOnClick = () => {
        if(props.buildingPreview) {
            return;
        }

        if(
            props.blockData &&
            (
                props.blockData.button_action === ButtonAction.URL ||
                props.blockData.button_action === ButtonAction.URLTab
            ) &&
            props.blockData.button_url
        ) {
            window.open(
                props.blockData.button_url, 
                props.blockData.button_action === ButtonAction.URLTab ?
                '_blank' :
                '_self'
            );
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Scroll &&
            props.blockData.button_url &&
            props.containerBaseIdName
        ) {
            scrollElementIntoView(`${props.containerBaseIdName}${props.blockData.button_url}`);
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Popup &&
            props.blockData.button_url
        ) {
            let nextPageFormsOpenState = JSON.parse(JSON.stringify(props.pageFormsOpenState));

            nextPageFormsOpenState[props.blockData.button_url] = true;

            props.setPageFormsOpenState(nextPageFormsOpenState);
        }
    }

    const getAttachmentMarkup = () => {
        if(props.blockData.attachment_type === 'video') {
            return (
                <>
                <ImageTextInGridVideoContainer
                className={`${props.view ? props.view : ''}`}
                >
                {props.blockData.video_url ? (
                    <>
                    <ImageTextInGridVideoUploadContainer
                    >
                    {getVideoUploadMarkup()}
                    </ImageTextInGridVideoUploadContainer>
                    </>
                ) : 
                    <ImageTextInGridVideoEmptyContainer
                    >
                    <ImageTextInGridVideoEmptyIconContainer
                    >
                        <PlayIcon
                        />
                    </ImageTextInGridVideoEmptyIconContainer>
                    </ImageTextInGridVideoEmptyContainer>
                }
                </ImageTextInGridVideoContainer>
                </>
            )
        }

        return (
            <>
            <ImageTextInGridImageContainer
            >
                <ImageTextInGridImage
                design={props.blockData.design}
                src={props.blockData.image_url}
                alt={props.blockData.image_alt}
                onClick={onClickImage}
                />
            </ImageTextInGridImageContainer>
            </>
        )
    }

  return (
    <ImageTextInGridContainer
    className={`${props.view ? props.view : ''}`}
    design={props.blockData.design}
    view={props.view}
    screenWidth={screenWidth}
    index={props.index}
    parentLength={props.parentLength}
    attachmentShown={props.blockData.attachment_shown}
    >
        <ImageTextInGridWrapper
        >
            {props.blockData.attachment_shown ? (
                <>
                {getAttachmentMarkup()}

                <ImageTextInGridGutter
                design={props.blockData.design}
                />
                </>
            ) : <></>}

            <ImageTextInGridTextContainer
            design={props.blockData.design}
            >
                <BlockTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="heading"
                fallbackTextColor="#000000"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.heading),
                }}
                />

                <BlockSubTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="subtext"
                fallbackTextColor="#666666"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.subtext),
                }}
                />

                {props.blockData.button_shown ? (
                    <BlockButton
                    design={props.blockData.design}
                    pageStyles={props.pageStyles}
                    onClick={ctaOnClick}
                    >
                        {props.blockData.button_text}
                    </BlockButton>
                ) : <></>}
            </ImageTextInGridTextContainer>
        </ImageTextInGridWrapper>
    </ImageTextInGridContainer>
  )
}

export default ImageTextInGridPreview;