export const getYouTubeVideoId = (url: string) => {
    let urlParse;
    let videoId;
    const videoURL = url.split("/");
    videoURL.map((part: any, index: any) => {
        if (part.includes("watch")) {
        urlParse = part.split("=")[1];
        videoId = urlParse;
        } else {
        if (index === 3) {
            videoId = part;
        }
        }
        return null;
    });

    return videoId;
}