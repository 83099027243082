import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router';
import { Emoji } from "emoji-mart";
import { Link, } from 'react-router-dom';
import { useHistory } from 'react-router';
import { toast, ToastType } from 'react-toastify';

import './admin-member-details.scss'
import HeaderMemberDetails from './HeaderMemberDetails/HeaderMemberDetails';
import BioMemberDetails from './BioMemberDetails/BioMemberDetails';
import SubscriptionMemberDetails from './SubscriptionMemberDetails/SubscriptionMemberDetails';

import { ReactComponent as WriteIcon } from "assets/img/colored-write-post.svg";
import { ReactComponent as CommentsIcon } from "assets/img/colored-comments.svg";
import { ReactComponent as EyeIcon } from "assets/img/colored-open-eye.svg";
import { ReactComponent as HashTagIcon } from "assets/img/channel-hashtag.svg";
import { ReactComponent as OpenBookIcon } from "assets/img/colored-book-open.svg";
import { ReactComponent as FilmIcon } from "assets/img/film.svg";
import { ReactComponent as HeadPhonesIcon } from "assets/img/headphones.svg";
import { ReactComponent as FileIcon } from "assets/img/file-pdf.svg";
import { ReactComponent as EmbedIcon } from "assets/img/embed.svg";
import { ReactComponent as SegmentIcon } from "assets/img/partition.svg";
import { ReactComponent as XIcon } from "assets/img/close.svg"
import { ReactComponent as BackIcon } from "assets/img/back-people.svg"
import Dropdown from 'elements/dropdown';
import { GroupService } from 'api/services/groups';
import ChannelHashTag from 'components/sidebar/newIcons/ChannelHashTag';
import { ChannelService } from 'api/services/channels';
import ChannelSelector from 'components/channel/channelSelector';
import AppSelect from 'elements/appSelect';
import { CourseService } from 'api/services/course';
import { EntityTypes } from 'elements/appMultipleSelect/domain';
import { SegmentService } from 'api/services/segment';
import { AudienceTypes } from 'containers/AdminMessaging/domain';
import LibraryDropdownSelector from './LibraryDropdownSelector/LibraryDropdownSelector';
import { LibraryService } from 'api/services/library';
import NoDataState from './NoDataState/NoDataState';

import LibrarySelector from './LibrarySelector';
import PaginationOptions from './PaginationOptions/PaginationOptions';
import { fetchGroupMembers } from 'api/react-query/services/CommunityService/queryFns';
import DeleteConfirmation from 'containers/channels/collections/deleteConfirmation';
import miscUtils from 'utils/miscUtils';
import { SubscriptionStatus } from 'components/CancelSubscriptionModal/CancelSubscriptionModal';
import ReactModal from 'react-modal';
import ContentLoader from 'react-content-loader';
import CustomFieldsMemberDetails from './CustomFieldsMemberDetails/CustomFieldsMemberDetails';
import { memberDetailsProfilePopupAtom } from 'showMessageMenuAtom';
import { useAtom } from 'jotai';

export const AdminMemberDetailsContext = React.createContext<any>({});


const AdminMemberDetails = (
  {
    group,
    groupMembersCallbacksRef,
    setPageTitle,
    activeMemberID,
    isPopUp
  }:
    {
      group: any,
      groupMembersCallbacksRef?: any
      setPageTitle?: (arg0: string) => void
      activeMemberID?: any
      isPopUp?: any
    }
) => {

  const pagehistory = useHistory()
  const { userID } = useParams<any>()

  const memberID = userID || activeMemberID

  const settingsPage = window.location.pathname.includes('/settings/members')

  const [userMembershipDetails, setUserMembershipDetails] = useState<any>()
  const [currentTab, setCurrentTab] = useState<string>(settingsPage ? 'overview' : 'member-info');
  const [selectedItemToRemove, setSelectedItemToRemove] = useState<any>(null);
  const [selectedItemToAdd, setSelectedItemToAdd] = useState<any>(null);
  const [messageState, setMessageState] = useState<any>(null)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false);
  const [memberBlockState, setMemberBlockState] = useState<any>("");
  const [isOpenCancelSubModal, setIsOpenCancelSubModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dialogisOpen, setDialogIsOpen] = useState<boolean>(false)

  const membershipID = userMembershipDetails && userMembershipDetails.membership && userMembershipDetails.membership.id
  const body = document.body;
  const Admin = Boolean((group && group.user_membership && group.user_membership.owner) ||
    (group && group.user_membership && miscUtils.isAdmin(group.user_membership)) ||
    (group && group.user_membership && miscUtils.isModerator(group.user_membership)))

  function disableScroll() {
    body.style.overflow = 'hidden';
  }

  function enableScroll() {
    body.style.overflow = 'auto';
  }

  const openDialog = (itemType: string) => {
    const DialogEl = document.getElementById(`items-dialog-${itemType}`) as HTMLDialogElement
    if (DialogEl) {
      DialogEl.showModal();
      disableScroll()
      setDialogIsOpen(true)
    }
  }

  const closeDNSDialog = (itemType: string) => {
    const DialogEl = document.getElementById(`items-dialog-${itemType}`) as HTMLDialogElement
    if (DialogEl) {
      DialogEl.close();
      enableScroll()
      setDialogIsOpen(false)
    }
  }

  const fetchUserMembershipDetails = () => {
    if (Number(memberID)) {
      GroupService.getInstance()
        .getUserMembershipDetails(group && group.id, Number(memberID))
        .then((res: any) => {
          if (res) {
            setUserMembershipDetails(res)
          }
        }).finally(() => setIsLoading(false))
    }
  }

  useEffect(() => {
    setPageTitle && setPageTitle("");
    fetchUserMembershipDetails()

    const el = document.getElementById(`people-details-popup-body`)
    if (el) el.scrollTo(0, 0)
  }, [memberID])

  useEffect(() => {
    setMemberBlockState(userMembershipDetails && userMembershipDetails.membership && userMembershipDetails.membership.blocked);
  }, [userMembershipDetails, memberID]);

  function removeMember() {
    GroupService.getInstance()
      .removeGroupMember(group.id, membershipID)
      .then(() => pagehistory.push(`/settings/members?section=community-members`))
      .finally(() => {
        fetchGroupMembers(group.id, '',)
      })
  }

  function blockMember(selection: any, state: boolean) {
    if (
      miscUtils.isOwner(userMembershipDetails && userMembershipDetails.membership) ||
      miscUtils.isAdmin(userMembershipDetails && userMembershipDetails.membership)
    ) {
      toast("You can't perform actions on group admins", {
        type: ToastType.ERROR,
        autoClose: 3000
      });
      return
    }
    else {
      GroupService.getInstance().changeMembershipBlockState(
        group.id,
        membershipID,
        state
      ).then(() => {
        fetchUserMembershipDetails()
        toast(`Member ${state ? 'blocked' : 'unblocked'}`, {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
      })
    }
  }

  function cancelSubscription(cancel: any) {
    if (
      miscUtils.isOwner(userMembershipDetails && userMembershipDetails.membership) ||
      miscUtils.isAdmin(userMembershipDetails && userMembershipDetails.membership)
    ) {
      toast("You can't perform actions on group admins", {
        type: ToastType.ERROR,
        autoClose: 3000
      });
      return
    }
    else {
      GroupService.getInstance()
        .cancelSubscription(
          group.id,
          membershipID,
          SubscriptionStatus.CancelImmediately === cancel && "Immediately"
        )
        .then((response: any) => {
          toast("Member's subscription cancelled.", {
            type: ToastType.SUCCESS,
            autoClose: 3000
          });
          fetchUserMembershipDetails()
          setIsOpenCancelSubModal(false)
        }).finally(() => {
          setIsOpenCancelSubModal(false)
        })
    }
  }

  const tableActions: any =
    [
      {
        label: "Remove",
        callback: () => setIsConfirmDeleteModalOpen(true),
      },
      {
        label: "Block",
        callback: blockMember,
        value: true,
        hide: memberBlockState
      },
      {
        label: "Unblock",
        callback: blockMember,
        value: false,
        hide: !memberBlockState
      },
      {
        label: "Cancel subscription",
        callback: () => setIsOpenCancelSubModal(true)
      },
    ];

  return (
    <AdminMemberDetailsContext.Provider
      value={{
        group, memberID,
        selectedItemToRemove, setSelectedItemToRemove,
        selectedItemToAdd, setSelectedItemToAdd,
        disableScroll, enableScroll,
        openDialog, closeDNSDialog,
        membershipID,
        messageState, setMessageState,
        isLoading, settingsPage,
        dialogisOpen, fetchUserMembershipDetails,
        Admin,
        userMembershipDetails
      }}
    >
      {
        (!isPopUp) &&
        <>
          {
            !isLoading &&
            <Link
              to={"/settings/members?section=community-members"}
            >
              <span className='back-people-button'>
                <BackIcon />
                Back to people
              </span>
            </Link>
          }
        </>
      }

      <div className={`admin-details-container ${settingsPage ? 'admin-details-container-styles' : ''} ${(isPopUp && settingsPage) ? 'pop-up-styles' : ''}`}>
        <div className='admin-details-container-tab'>
          {
            !settingsPage &&
            <AdminMemberDetailsHeaderTab
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          }
        </div>

        {
          !settingsPage &&
          <>
            {
              currentTab === 'member-info' &&
              <div className='admin-details-container-body'>
                <div className='admin-details-container-noadmin-left'>
                  <HeaderMemberDetails actions={tableActions} userMembership={userMembershipDetails} />
                  {
                    Admin &&
                    <div style={{ marginTop: '20px' }}>
                      <SubscriptionMemberDetails userMembership={userMembershipDetails && userMembershipDetails.membership} />
                    </div>
                  }
                  <div style={{ marginTop: '20px' }}>
                      <BioMemberDetails
                        user={userMembershipDetails && userMembershipDetails.membership}
                        group={group && group}
                      />
                  </div>
                    {/* {
                    Admin && */}
                    <div style={{ marginTop: '20px' }}>
                      <CustomFieldsMemberDetails
                        screeningResponse={userMembershipDetails && userMembershipDetails.membership && userMembershipDetails.membership.screening_response}
                        group={group && group}
                      />
                    </div>
                    {/* } */}

                </div>
                <div className='admin-details-container-noadmin-right'>
                  <div className='admin-details-container-noadmin-right-left'>
                    <div className='admin-details-container-noadmin-figures'>
                      {isLoading ?
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {Array(Admin ? 3 : 2).fill('').map((_e, i) => (
                            <div
                              key={i}
                              style={
                                {
                                  marginRight: '10px',
                                  width: '100%',
                                  padding: '6px',
                                  paddingBottom: '2px',
                                  background: '#fff',
                                  border: `1px solid #d5d8df`,
                                  borderRadius: '4px',
                                }
                              }
                            >
                              <Loader />
                            </div>
                          ))}
                        </div>
                        :
                        <>
                          {
                            userMembershipDetails &&
                            <>
                              <Figures icon={<WriteIcon />} figureName={'Posts'} figureNumber={userMembershipDetails.posts_count} />
                              <Figures icon={<CommentsIcon />} figureName={'Comments'} figureNumber={userMembershipDetails.comments_count} />
                              {
                                Admin &&
                                <Figures icon={<EyeIcon />} figureName={'Visits'} figureNumber={userMembershipDetails.community_visits_count} />
                              }
                            </>
                          }
                        </>
                      }
                    </div>
                    <div className='admin-details-container-noadmin-channels'>
                      <AdminMemberChannels
                        group={group && group}
                        channels={userMembershipDetails && userMembershipDetails.channels}
                      />
                    </div>

                    <div className='admin-details-container-noadmin-channels'>
                      <AdminMemberCourses
                        courses={userMembershipDetails && userMembershipDetails.course_enrollments}
                      />
                    </div>

                    {
                      Admin &&
                      <>
                        <div className='admin-details-container-noadmin-channels'>
                          <AdminMemberSegment
                            segments={userMembershipDetails && userMembershipDetails.segments}
                          />
                        </div>

                        <div className='admin-details-container-noadmin-channels'>
                          <AdminMemberLib
                            lib={userMembershipDetails && userMembershipDetails.library_contents}
                          />
                        </div>
                      </>
                    }

                    </div>
                </div>
              </div>
            }

          </>
        }

        {
          settingsPage &&
          <div className='admin-details-container-left'>
            <HeaderMemberDetails actions={tableActions} userMembership={userMembershipDetails} />
            <SubscriptionMemberDetails userMembership={userMembershipDetails && userMembershipDetails.membership} />
              <BioMemberDetails user={userMembershipDetails && userMembershipDetails.membership} group={group && group} />
              <CustomFieldsMemberDetails
                screeningResponse={userMembershipDetails && userMembershipDetails.membership && userMembershipDetails.membership.screening_response}
                group={group && group}
              />
          </div>
        }

        {
          settingsPage &&
          <div className='admin-details-container-right'>
            <AdminMemberDetailsHeaderTab
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />

            {currentTab === 'overview'
              &&
              <div className='overview-body'>
                <div className='overview-body-figures'>
                  {
                    isLoading ?
                      <div
                        style={
                          {
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center'
                          }
                        }
                      >
                        {
                          Array(3).fill('').map((_e, i) => (
                            <div
                              style={
                                {
                                  marginRight: '10px',
                                  width: '100%',
                                  padding: '6px',
                                  paddingBottom: '2px',
                                  background: '#fff',
                                  border: `1px solid #d5d8df`,
                                  borderRadius: `4px`,
                                }
                              }
                            >
                              <Loader />
                            </div>
                          ))}
                      </div>
                      :
                      <>
                        {
                          userMembershipDetails &&
                          <>
                            <Figures icon={<WriteIcon />} figureName={'Posts'} figureNumber={userMembershipDetails.posts_count} />
                            <Figures icon={<CommentsIcon />} figureName={'Comments'} figureNumber={userMembershipDetails.comments_count} />
                            <Figures icon={<EyeIcon />} figureName={'Visits'} figureNumber={userMembershipDetails.community_visits_count} />
                          </>
                        }

                      </>
                  }
                </div>

                <div className='overview-body-channels'>
                  <AdminMemberChannels
                    group={group && group}
                    channels={userMembershipDetails && userMembershipDetails.channels}
                  />
                </div>

                <div className='overview-body-channels'>
                  <AdminMemberCourses
                    courses={userMembershipDetails && userMembershipDetails.course_enrollments}
                  />
                </div>

                <div className='overview-body-channels'>
                  <AdminMemberSegment
                    segments={userMembershipDetails && userMembershipDetails.segments}
                  />
                </div>

                <div className='overview-body-channels'>
                  <AdminMemberLib
                    lib={userMembershipDetails && userMembershipDetails.library_contents}
                  />
                </div>
              </div>
            }
          </div>
        }
      </div>

      <DeleteConfirmation
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={() => {
          setIsConfirmDeleteModalOpen(false);
          removeMember();
        }}
        headline="Are you sure about this action?"
        message="Removing members will revoke their access to courses, events, and content hosted in your community."
        deleteBtnLabel="Yes, remove"
        hideNoUndoWaring
      />

      <CancelSubscriptionModal
        action={cancelSubscription}
        isOpen={isOpenCancelSubModal}
        setIsOpen={setIsOpenCancelSubModal}
      />
    </AdminMemberDetailsContext.Provider>
  )
}


const AdminMemberDetailsHeaderTab = (
  {
    currentTab,
    setCurrentTab
  }:
    {
      currentTab: string,
      setCurrentTab: (arg0: string) => void
    }
) => {


  return (
    <div className='member-tabs-details'>
      <div
        className={`${currentTab === 'member-info' && 'active'}`}
        onClick={() => setCurrentTab('member-info')}
      >
        <h1>
          Member info
        </h1>
      </div>
      <div
        className={`${currentTab === 'overview' && 'active'}`}
        onClick={() => setCurrentTab('overview')}
      >
        <h1>
          Overview
        </h1>
      </div>

      <div
        className={`${currentTab === 'feed' && 'active'}`}
        onClick={() => setCurrentTab('feed')}
      >
        <h1>
          Feed
        </h1>
      </div>

    </div>
  )
}

const Figures = (
  {
    icon,
    figureName,
    figureNumber
  }:
    {
      icon: JSX.Element,
      figureName: string,
      figureNumber: number
    }
) => {

  return (
    <div className='figures-container'>
      <div className='icon-con'>
        <div className='icon'>
          {icon}
        </div>
        <h1>{figureName}</h1>
      </div>
      <div>
        <span>{figureNumber}</span>
      </div>

      <div className='icon-con-small'>
        <div className='icon'>
          {icon}
        </div>
      </div>
      <div className='icon-con-small-body'>
        <h1>{figureName}</h1>
        <span>{figureNumber}</span>
      </div>

    </div>
  )
}

const AdminMemberChannels = (
  { channels, group }: { channels: any[], group: any }
) => {
  // const { userID } = useParams<any>();

  const [channelsData, setChannelsData] = useState<any[]>(channels)
  const [currentPage, setCurrentPage] = useState(0);

  const {
    selectedItemToRemove, setSelectedItemToRemove,
    selectedItemToAdd, setSelectedItemToAdd,
    openDialog, closeDNSDialog,
    isLoading, fetchUserMembershipDetails, settingsPage, memberID
  } = React.useContext(AdminMemberDetailsContext)

  const itemsPerPage = settingsPage ? 10 : 15

  useEffect(() => {
    const filteredCourses = channels && channels.filter((item: any) => item.course !== null);
    setChannelsData(filteredCourses);
  }, [channels]);

  const lastPage = Math.ceil(channelsData && channelsData.length / itemsPerPage) - 1;

  const channelActions: any =
    [
      {
        label: "Add or remove channels",
        callback: () => { openDialog('channel') },
      },
    ];

  const removeChannelMembers = () => {
    const promises: any = [];

    selectedItemToRemove && selectedItemToRemove.forEach((itemToRemove: any) => {
      promises.push(
        ChannelService.getInstance()
          .removeChannelMember(
            group && group.id,
            itemToRemove.id,
            [Number(memberID)]
          )
      )
    });

    if (promises.length) {
      Promise.all(promises).then(() => {
        setSelectedItemToRemove(null)
        toast('Member removed from channel', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('channel')
        fetchUserMembershipDetails()
      })
        .catch(() => closeDNSDialog('channel'))
        .finally(() => closeDNSDialog('channel'))
    }
  }

  const addSelectedMembers = () => {
    const promises: any = [];
    setChannelsData([...channelsData, ...selectedItemToAdd])

    selectedItemToAdd && selectedItemToAdd.forEach((itemToAdd: any) => {
      promises.push(
        ChannelService.getInstance()
          .inviteMembersToChannel(
            itemToAdd.id,
            group && group.id,
            [Number(memberID)]
          )
      )
    });

    if (promises.length) {
      Promise.all(promises).then(() => {
        setSelectedItemToAdd(null)
        toast('Member added to channel', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('channel')
      })
    }
  }

  return (
    <>
      <ItemsHeader
        ItemIcon={<HashTagIcon />}
        ItemName={'Channels'}
        ItemActions={channelActions}
      />
      {
        isLoading ?
          <div
            style={
              {
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }
            }
          >
            {Array(4).fill('').map((_e, i) => (
              <div
                style={{
                  marginRight: '10px',
                  width: '100%',
                  background: '#fafafa',
                  padding: '6px',
                  paddingBottom: '2px'
                }}
              >
                <Loader />
              </div>
            ))}
          </div>
          :
          <>
            {
              channelsData && channelsData.length > 0
                ?
                <>
                  <ul className="channel-list">
                    {channelsData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((channel, index) => {
                      return (
                        <div className='channel-body channel-emoji-container' key={index}>
                          {
                            channel.emoji ?
                              (
                                <span
                                  className="emoji-container"
                                  dangerouslySetInnerHTML={{
                                    //@ts-ignore
                                    __html: Emoji({
                                      html: true,
                                      set: "apple",
                                      emoji: channel.emoji,
                                      size: 18
                                    })
                                  }}
                                />
                              )
                              :
                              (
                                <span>
                                  <ChannelHashTag
                                    fill="#000"
                                  />
                                </span>
                              )}
                          <h1>
                            {channel.name}
                          </h1>
                        </div>
                      )
                    })
                    }
                  </ul>
                  <div className='footer-pagination'>
                    {
                      channelsData && channelsData.length > 10 &&
                      <PaginationOptions
                        lastPage={lastPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        key='channels'
                      />
                    }
                  </div>
                </>
                :
                <NoDataState
                  itemType={'No channels'}
                  desc={'This member is not part of any channels.'}
                />
            }
          </>
      }
      <ItemsDialog
        itemType={'channel'}
        items={channelsData && channelsData}
        removeItemAction={removeChannelMembers}
        addItemAction={addSelectedMembers}
        setItems={setChannelsData}
      />
    </>
  )
}

const AdminMemberCourses = ({ courses, }: { courses: any, }) => {

  const {
    memberID,
    selectedItemToAdd, setSelectedItemToAdd,
    selectedItemToRemove, setSelectedItemToRemove,
    openDialog, closeDNSDialog, isLoading,
    fetchUserMembershipDetails, settingsPage, Admin,
    userMembershipDetails
  } = React.useContext(AdminMemberDetailsContext)

  const itemsPerPage = settingsPage ? 10 : 15

  const [coursesData, setCoursesData] = useState<any[]>(courses)
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const filteredCourses = courses && courses.filter((item: any) => item.course !== null);
    setCoursesData(filteredCourses);
  }, [courses]);

  const lastPage = Math.ceil(coursesData && coursesData.length / itemsPerPage) - 1;

  const courseActions: any =
    [
      {
        label: "Add or remove courses",
        callback: () => { openDialog('course') },
      },
    ];

  const addMembersToCourse = () => {
    const promises: any = [];

    setCoursesData([...coursesData, ...selectedItemToAdd])

    selectedItemToAdd && selectedItemToAdd.forEach((itemToAdd: any) => {
      promises.push(
        CourseService.getInstance()
          .enrolUser(itemToAdd.id, Number(memberID))
      )
    });

    if (promises.length) {
      Promise.all(promises).then(() => {
        setSelectedItemToAdd(null)
        toast('Member enrolled to course', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('course')
        fetchUserMembershipDetails()
      });
    }
  }

  const removeEnrollment = () => {
    const promises: any = [];

    selectedItemToRemove && selectedItemToRemove.forEach((itemToRemove: any) => {
      promises.push(
        CourseService.getInstance()
          .removeCourseEnrolment(itemToRemove.course_id, itemToRemove.id)
      )
    });

    if (promises.length) {
      Promise.all(promises).then(() => {
        setSelectedItemToRemove(null)
        toast('Member removed from course', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('course')
        fetchUserMembershipDetails()
      })
        .catch(() => closeDNSDialog('course'))
        .finally(() => closeDNSDialog('course'))
    }
  }
  const [, setOpenmemberDetailsProfilePopup] = useAtom(memberDetailsProfilePopupAtom);

  const memberName = userMembershipDetails && userMembershipDetails.membership && userMembershipDetails.membership.user.full_name
  const history = useHistory();
  const fetchCourseEnrollments = (courseId: any) => {
    CourseService.getInstance()
      .getCourseEnrolments(Number(courseId), memberName)
      .then((response: any) => {
        if (response.data) {
          response.data.data.map((res: any) => {
            if (res.user.id === Number(memberID)) {
              history.push(`/settings/courses/${courseId}/enrollments/${res.id}/activities`);
            }
          })
        }
      }).finally(() => setOpenmemberDetailsProfilePopup(false))
  }

  return (
    <>
      <ItemsHeader
        ItemIcon={<OpenBookIcon />}
        ItemName={'Courses'}
        ItemActions={courseActions}
      />
      {
        isLoading ?
          <div
            style={
              {
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }
            }
          >
            {
              Array(4).fill('').map((_e, i) => (
                <div
                  style={
                    {
                      marginRight: '10px',
                      width: '100%',
                      background: '#fafafa',
                      padding: '6px',
                      paddingBottom: '2px'

                    }
                  }
                >
                  <Loader section='courses' />
                </div>
              ))}
          </div>
          :
          <>

            {coursesData && coursesData.length > 0 ?
              <>
                <ul className="channel-list">
                  {coursesData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((data, index) => {
                    return (
                      <div
                        style={{ cursor: Admin ? 'pointer' : '' }}
                        className='channel-body'
                        key={index}
                        onClick={() => {
                          if (Admin) fetchCourseEnrollments(data.course_id)
                        }}
                      >
                        {data.course ?
                          <h1>
                            {data.course.title}
                          </h1>
                          :
                          <h1>
                            {data.title}
                          </h1>
                        }
                        {
                          Admin &&
                          <div className='course-perc-container'>
                            <div className='course-perc'>
                              <div
                                style={{
                                  width: `${Math.floor(((data.completion_rate !== undefined ? data.completion_rate : 0) / 100) * 100)}%`,
                                  backgroundColor: '#6FCF97',
                                  borderRadius: '50px',
                                  color: 'transparent',
                                  height: '7px',
                                  display: 'flex'
                                }}
                              />
                            </div>
                            <span className='progress'>
                              {Number((Math.floor((data.completion_rate !== undefined ? data.completion_rate : 0)) / 100 * 100).toFixed(0))} %
                            </span>
                          </div>
                        }
                      </div>
                    )
                  })
                  }
                </ul>
                <div className='footer-pagination'>
                  {
                    coursesData && coursesData.length > 10 &&
                    <PaginationOptions
                      lastPage={lastPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      key='courses'
                    />
                  }
                </div>
              </>
              :
              <NoDataState
                itemType={'No courses'}
                desc={'This member is not enrolled in any courses.'}
              />
            }
          </>
      }
      <ItemsDialog
        itemType={'course'}
        items={coursesData && coursesData}
        removeItemAction={removeEnrollment}
        addItemAction={addMembersToCourse}
        setItems={setCoursesData}
      />
    </>
  )
}

const AdminMemberLib = ({ lib, }: { lib: any, }) => {

  const {
    selectedItemToAdd, setSelectedItemToAdd,
    selectedItemToRemove, setSelectedItemToRemove,
    openDialog,
    closeDNSDialog,
    memberID,
    messageState, isLoading,
    fetchUserMembershipDetails, settingsPage,
    group
  } = React.useContext(AdminMemberDetailsContext)

  const itemsPerPage = settingsPage ? 10 : 15

  const [libData, setlibData] = useState<any>(lib)
  const [currentPage, setCurrentPage] = useState(0);

  const lastPage = Math.ceil(libData && libData.length / itemsPerPage) - 1;

  useEffect(() => {
    const filteredCourses = lib && lib.filter((item: any) => item !== null);
    setlibData(filteredCourses);
  }, [lib]);

  const libActions: any =
    [
      {
        label: "Add or remove library",
        callback: () => { openDialog('library') },
      },
    ];


  const addMembersToLibrary = () => {
    const promises: any = [];
    setlibData([...libData, ...selectedItemToAdd])

    selectedItemToAdd && selectedItemToAdd.forEach((itemToAdd: any) => {
      promises.push(
        LibraryService.getInstance()
          .grantUserLibraryAccess(
            group.id,
            itemToAdd.id,
            {
              type: `${messageState}`,
              user_id: Number(memberID)
            }
          )
      )
    });

    if (promises.length) {
      Promise.all(promises).then(() => {
        setSelectedItemToAdd(null)
        toast('Member added to library', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('library')
        fetchUserMembershipDetails()
      });
    }
  }

  const removeMembersFromLibrary = () => {
    const promises: any = [];
    selectedItemToRemove && selectedItemToRemove.forEach((itemToAdd: any) => {
      promises.push(
        LibraryService.getInstance()
          .deleteUserLibraryAccess(
            group.id,
            itemToAdd.id,
            {
              type: itemToAdd.type,
              user_id: Number(memberID)
            }
          )
      )
    });

    if (promises.length) {
      Promise.all(promises).then(() => {
        setSelectedItemToRemove(null)
        toast('Member added to library', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('library')
        fetchUserMembershipDetails()
      });
    }
  }

  return (
    <>
      <ItemsHeader
        ItemIcon={<OpenBookIcon />}
        ItemName={'Library'}
        ItemActions={libActions}
      />
      {
        isLoading ?
          <div
            style={
              {
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }
            }
          >
            {
              Array(4).fill('').map((_e, i) => (
                <div
                  style={
                    {
                      marginRight: '10px',
                      width: '100%',
                      background: '#fafafa',
                      padding: '6px',
                      paddingBottom: '2px'

                    }
                  }
                >
                  <Loader />
                </div>
              ))}
          </div>
          :
          <>
            {
              libData && libData.length > 0 ?
                <>
                  <ul className="channel-list">
                    {libData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((course: any) => {
                      return (
                        <div className='channel-body channel-emoji-container' key={course.id}>
                          {
                            (course && course.type) ?
                              <div>
                                {
                                  course.type === 'video'
                                  &&
                                  <FilmIcon />
                                }

                                {
                                  course.type === 'audio'
                                  &&
                                  <HeadPhonesIcon />
                                }

                                {
                                  course.type === 'pdf'
                                  &&
                                  <FileIcon />
                                }

                                {
                                  course.type === 'embed'
                                  &&
                                  <EmbedIcon />
                                }

                              </div>
                              :
                              <>
                                {
                                  (course && course.contentable_type)
                                  &&
                                  <div>
                                    {
                                      course.contentable_type === 'videos'
                                      &&
                                      <FilmIcon />
                                    }

                                    {
                                      course.contentable_type === 'audios'
                                      &&
                                      <HeadPhonesIcon />
                                    }

                                    {
                                      course.contentable_type === 'pdfs'
                                      &&
                                      <FileIcon />
                                    }

                                    {
                                      course.contentable_type === 'embeds'
                                      &&
                                      <EmbedIcon />
                                    }

                                  </div>
                                }
                              </>
                          }
                          {
                            course && course.title &&
                            <h1>
                              {course.title}
                            </h1>
                          }
                        </div>
                      )
                    })
                    }
                  </ul>
                  <div className='footer-pagination'>
                    {
                      libData && libData.length > 10 &&
                      <PaginationOptions
                        lastPage={lastPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        key='library contents'
                      />
                    }
                  </div>
                </>
                :
                <NoDataState
                  itemType={'No library content'}
                  desc={'This member has not accessed any library content.'}
                />
            }
          </>
      }
      <ItemsDialog
        itemType={'library'}
        items={libData && libData}
        removeItemAction={removeMembersFromLibrary}
        addItemAction={addMembersToLibrary}
        setItems={setlibData}
      />
    </>
  )
}

const AdminMemberSegment = ({ segments, }: { segments: any, }) => {

  const {
    group,
    selectedItemToAdd, setSelectedItemToAdd,
    selectedItemToRemove, setSelectedItemToRemove,
    openDialog, closeDNSDialog,
    membershipID, isLoading,
    fetchUserMembershipDetails, settingsPage
  } = React.useContext(AdminMemberDetailsContext)

  const [segmentData, setSegmentData] = useState<any[]>(segments)
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = settingsPage ? 10 : 15

  const lastPage = Math.ceil(segmentData && segmentData.length / itemsPerPage) - 1;

  useEffect(() => {
    const filteredCourses = segments && segments.filter((item: any) => item !== null);
    setSegmentData(filteredCourses)
  }, [segments])

  const segmentActions: any =
    [
      {
        label: "Add or remove segments",
        callback: () => { openDialog('segment') },
      },
    ];

  const addMembersToSegment = () => {
    const changeBlockStatePromises: any = [];
    setSegmentData([...segmentData, ...selectedItemToAdd,])

    selectedItemToAdd && selectedItemToAdd.forEach((itemToAdd: any) => {
      changeBlockStatePromises.push(
        SegmentService.getInstance()
          .addMembersToSegment(
            group.id,
            itemToAdd.id,
            [Number(membershipID)]
          )
      );
    });

    if (changeBlockStatePromises.length) {
      Promise.all(changeBlockStatePromises).then(() => {
        setSelectedItemToAdd(null)
        toast('Member added to segment', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('segment')
      });
    }
  }

  const removeMembersFromSegment = () => {
    const removePromises: any = []

    selectedItemToRemove && selectedItemToRemove.forEach((itemToAdd: any) => {
      removePromises.push(
        SegmentService.getInstance()
          .removeMembersFromSegment(
            group.id,
            itemToAdd.id,
            [Number(membershipID)],
            true
          )
      );
    });

    if (removePromises.length) {
      Promise.all(removePromises).then(() => {
        setSelectedItemToRemove(null)
        toast('Member removed from segment', {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        closeDNSDialog('segment')
        fetchUserMembershipDetails()

      });
    }

  }

  return (
    <>
      <ItemsHeader
        ItemIcon={<SegmentIcon />}
        ItemName={'Segments'}
        ItemActions={segmentActions}
      />
      {
        isLoading ?
          <div
            style={
              {
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }
            }
          >
            {
              Array(4).fill('').map((_e, i) => (
                <div
                  style={
                    {
                      marginRight: '10px',
                      width: '100%',
                      background: '#fafafa',
                      padding: '6px',
                      paddingBottom: '2px'

                    }
                  }
                >
                  <Loader />
                </div>
              ))}
          </div>
          :
          <>
            {
              segmentData && segmentData.length > 0 ?
                <>
                  <ul className="channel-list">
                    {segmentData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((segment: any) => {
                      return (
                        <div className='channel-body channel-emoji-container'>
                          {
                            segment && segment.name &&
                            <h1>
                              {segment.name}
                            </h1>
                          }
                        </div>
                      )
                    })}
                  </ul>
                  <div className='footer-pagination'>
                    {
                      segmentData && segmentData.length > 10 &&
                      <PaginationOptions
                        lastPage={lastPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        key='segments'
                      />
                    }
                  </div>
                </>
                :
                <NoDataState
                  itemType={'No segments'}
                  desc={'This member is not part of any segments.'}
                />
            }
          </>
      }
      <ItemsDialog
        itemType={'segment'}
        items={segmentData && segmentData}
        removeItemAction={removeMembersFromSegment}
        addItemAction={addMembersToSegment}
        setItems={setSegmentData}
      />
    </>
  )
}

const ItemsHeader = (
  {
    ItemIcon,
    ItemName,
    ItemActions
  }
    :
    {
      ItemIcon: JSX.Element,
      ItemName: string,
      ItemActions: any[]
    }
) => {
  const {
    Admin,
    userMembershipDetails
  } = React.useContext(AdminMemberDetailsContext)
  const userTempPlan = userMembershipDetails && userMembershipDetails.membership && userMembershipDetails.membership.request_status === 'requested'

  return (
    <div className='header'>
      <div>
        {ItemIcon}
        <h1>{ItemName}</h1>
      </div>
      {
        Admin && !userTempPlan &&
        <Dropdown
          showMenuDots
          tippyCompatibleActions
          actions={ItemActions}
        />
      }
    </div>

  )

}

const ItemsDialog = (
  { itemType,
    items,
    removeItemAction,
    addItemAction,
    setItems
  }:
    {
      itemType: string,
      items: any[],
      removeItemAction?: any,
      addItemAction?: any,
      setItems: any
    }
) => {

  const {
    group,
    selectedItemToRemove, setSelectedItemToRemove,
    selectedItemToAdd, setSelectedItemToAdd,
    closeDNSDialog,
    messageState, setMessageState
  } = React.useContext(AdminMemberDetailsContext)

  const [tempItems, setTempItems] = useState<any>(setItems)
  const [searchString, setSearchString] = useState<string>('');

  const hideDropdownRef = React.useRef();

  const getSegmentValue = (): string => {
    switch (messageState) {
      case AudienceTypes.AUDIO:
        return 'Audios';
      case AudienceTypes.VIDEO:
        return 'Videos';
      case AudienceTypes.PDF:
        return 'PDFs';
      case AudienceTypes.EMBED:
        return 'Embeds';
      default:
        return '';
    }
  }

  const onSegmentSelected = (segment: any) => {
    setMessageState(segment.value)
    setSelectedItemToAdd(null)
  }

  useEffect(() => {
    setTempItems(items)
  }, [items])


  const selectedItemToAddIds = selectedItemToAdd ? selectedItemToAdd.map((channel: { id: any; }) => channel.id) : []
  const tempItemsIds = itemType === 'course' ? (tempItems ? tempItems.map((channel: { course_id: any; }) => channel.course_id) : [])
    : tempItems ? tempItems.map((channel: any) => channel.id) : []

  return (
    <dialog className='items-dialog' id={`items-dialog-${itemType}`} key={itemType}>
      <div className='dialog-header'>
        <h1>{`${itemType === 'course' ? 'Enroll' : 'Add'} or remove from ${itemType}${itemType !== 'library' ? 's' : ''} ${itemType === 'course' ? '' : itemType === 'library' ? "contents" : ''} `}</h1>
        <span
          onClick={() => {
            closeDNSDialog(itemType)
            setMessageState(null)
            if (selectedItemToRemove && selectedItemToRemove.length > 0) {
              // setItems([...items, ...selectedItemToRemove])
              // setSelectedItemToRemove(null)

              // setItems([...items, ...selectedItemToRemove])
              setSelectedItemToRemove(null)
              setTempItems(items)
            }
            if (selectedItemToAdd) {
              const updatedChannels = items.filter(item => !selectedItemToAdd.some((ItemToAdd: any) => ItemToAdd.id === item.id));
              setItems(updatedChannels)
              setSelectedItemToAdd(null);
            }
          }}
        >
          <XIcon />
        </span>
      </div>
      <div className='dialog-body'>
        <div className='dialog-body-upper'>
          <h1>{`${itemType === 'course' ? 'Enroll' : 'Add'} to ${itemType === 'library' ? 'library contents' : `${itemType}s`}`}</h1>
          {
            itemType === 'channel' &&
            <>
              <AppSelect
                value={searchString}
                onChangeValue={(e: any) => setSearchString(e.target.value)}
                placeholder="Select channels"
                hideDropdownRef={hideDropdownRef}
              >
                <ChannelSelector
                  group={group}
                  onChannelSelected={(channel: any) => {
                    setSearchString('');
                    setSelectedItemToAdd((prevSelectedItems: any) => [...(prevSelectedItems || []), channel]);

                    if (hideDropdownRef.current) {
                      //@ts-ignore
                      hideDropdownRef.current();
                    }
                  }}
                  searchString={searchString}
                  excludes={[...selectedItemToAddIds, ...tempItemsIds]}
                // excludes={items && selectedItemToAdd && [...items, ...selectedItemToAdd].map((channel: any) => channel.id)}
                />
              </AppSelect>
              {
                selectedItemToAdd &&
                selectedItemToAdd.length > 0 &&
                <ul className="selected-list">
                  {selectedItemToAdd.map((channel: any) => (
                    <div>
                      {channel.emoji ?
                        <span
                          className="emoji-container"
                          dangerouslySetInnerHTML={{
                            //@ts-ignore
                            __html: Emoji({
                              html: true,
                              set: "apple",
                              emoji: channel.emoji,
                              size: 18
                            })
                          }}
                        />
                        :
                        <span><ChannelHashTag fill="#fff" /></span>
                      }
                      {
                        channel && <h1> {channel.name}</h1>
                      }
                      <span
                        className='x-icon'
                        onClick={() => {
                          if (selectedItemToAdd.filter((audience: any) => audience.id !== channel.id).length === 0) {
                            setSelectedItemToAdd(null);
                          }
                          else {
                            setSelectedItemToAdd(selectedItemToAdd.filter((audience: any) => audience.id !== channel.id));
                          }
                        }}
                      >
                        <XIcon />
                      </span>
                    </div>
                  ))}
                </ul>
              }
            </>
          }

          {
            itemType === 'course' &&
            <>
              <AppSelect
                value={searchString}
                onChangeValue={(e: any) => setSearchString(e.target.value)}
                placeholder={`Select ${itemType}s`}
                hideDropdownRef={hideDropdownRef}
              >
                <LibrarySelector
                  group={group}
                  onChannelSelected={(channel: any) => {
                    setSearchString('');
                    setSelectedItemToAdd((prevSelectedItems: any) => {
                      const newContentable = channel.contentable;
                      const existingItems = Array.isArray(prevSelectedItems) ? prevSelectedItems : [];
                      const updatedItems = [...existingItems, newContentable];
                      return updatedItems;
                    });
                    if (hideDropdownRef.current) {
                      //@ts-ignore
                      hideDropdownRef.current();
                    }
                  }}
                  searchString={searchString}
                  excludes={[...selectedItemToAddIds, ...tempItemsIds]}
                  itemType={EntityTypes.COURSE}
                />
              </AppSelect>
              {
                selectedItemToAdd &&
                selectedItemToAdd.length > 0 &&
                <ul className="selected-list">
                  {selectedItemToAdd.map((channel: any) => (
                    <div>
                      {
                        channel && <h1> {channel.title}</h1>
                      }
                      <span
                        className='x-icon'
                        onClick={() => {
                          if (selectedItemToAdd.filter((audience: any) => audience.id !== channel.id).length === 0) {
                            setSelectedItemToAdd(null);
                          }
                          else {
                            setSelectedItemToAdd(selectedItemToAdd.filter((audience: any) => audience.id !== channel.id));
                          }
                        }}
                      >
                        <XIcon />
                      </span>
                    </div>
                  ))}
                </ul>
              }
            </>
          }

          {
            itemType === 'segment' &&
            <>
              <AppSelect
                value={searchString}
                onChangeValue={(e: any) => setSearchString(e.target.value)}
                placeholder={`Select ${itemType}s`}
                hideDropdownRef={hideDropdownRef}
              >
                <LibrarySelector
                  group={group}
                  onChannelSelected={(segment: any) => {
                    setSearchString('');
                    setSelectedItemToAdd((prevSelectedItems: any) => [...((prevSelectedItems) || []), segment]);

                    if (hideDropdownRef.current) {
                      //@ts-ignore
                      hideDropdownRef.current();
                    }
                  }}
                  searchString={searchString}
                  excludes={[...selectedItemToAddIds, ...tempItemsIds]}
                  // excludes={items && selectedItemToAdd && [...items, ...selectedItemToAdd].map((segment: any) => segment.id)}
                  itemType={EntityTypes.SEGMENT}
                />
              </AppSelect>
              {
                selectedItemToAdd &&
                selectedItemToAdd.length > 0 &&
                <ul className="selected-list">
                  {selectedItemToAdd.map((channel: any) => (
                    <div>
                      {
                        channel && <h1> {channel.name}</h1>
                      }
                      <span
                        className='x-icon'
                        onClick={() => {
                          if (selectedItemToAdd.filter((audience: any) => audience.id !== channel.id).length === 0) {
                            setSelectedItemToAdd(null);
                          }
                          else {
                            setSelectedItemToAdd(selectedItemToAdd.filter((audience: any) => audience.id !== channel.id));
                          }
                        }}
                      >
                        <XIcon />
                      </span>
                    </div>
                  ))}
                </ul>
              }
            </>
          }

          <div className='library-item'>
            {
              itemType === 'library' &&
              <div className='library-body'>
                <AppSelect
                  value={getSegmentValue()}
                  onChangeValue={() => { }}
                  placeholder="Select library type"
                  hideDropdownRef={hideDropdownRef}
                >
                  <LibraryDropdownSelector
                    selectedSegment={messageState}
                    onSegmentSelected={onSegmentSelected}
                  />
                </AppSelect>
                {
                  messageState &&
                  <div className='library-body-section'>
                    <AppSelect
                      value={searchString}
                      onChangeValue={(e: any) => setSearchString(e.target.value)}
                      placeholder={`Select ${messageState}s`}
                      hideDropdownRef={hideDropdownRef}
                    >
                      <LibrarySelector
                        group={group}
                        onChannelSelected={(channel: any) => {
                          setSearchString('');
                          setSelectedItemToAdd((prevSelectedItems: any) => {
                            let newContentable = channel.contentable;
                            newContentable.contentable_type = channel.contentable_type

                            // Make sure prevSelectedItems is an array
                            const existingItems = Array.isArray(prevSelectedItems) ? prevSelectedItems : [];

                            // Create a new array with the existing items and the new one
                            const updatedItems = [...existingItems, newContentable];

                            return updatedItems;
                          });
                          if (hideDropdownRef.current) {
                            //@ts-ignore
                            hideDropdownRef.current();
                          }
                        }}
                        searchString={searchString}
                        excludes={[...selectedItemToAddIds, ...tempItemsIds]}
                        // excludes={items && selectedItemToAdd && [...items, ...selectedItemToAdd].map((channel: any) => channel.id)}
                        itemType={messageState}
                      />
                    </AppSelect>
                    {
                      selectedItemToAdd &&
                      selectedItemToAdd.length > 0 &&
                      <ul className="selected-list">
                        {selectedItemToAdd.map((channel: any) => (
                          <div>
                            {
                              channel && <h1> {channel.title}</h1>
                            }
                            <span
                              className='x-icon'
                              onClick={() => {
                                if (selectedItemToAdd.filter((audience: any) => audience.id !== channel.id).length === 0) {
                                  setSelectedItemToAdd(null);
                                }
                                else {
                                  setSelectedItemToAdd(selectedItemToAdd.filter((audience: any) => audience.id !== channel.id));
                                }
                              }}
                            >
                              <XIcon />
                            </span>
                          </div>
                        ))}
                      </ul>
                    }
                  </div>
                }
              </div>

            }
          </div>

        </div>
        <div className='dialog-body-lower'>
          <h1>{`Member's ${itemType === 'library' ? `${itemType} contents` : `${itemType}s`}`}</h1>

          <ul className="item-list">
            {
              tempItems && tempItems.length === 0 &&
              <NoDataState
                itemType={`No ${itemType === 'library' ? `${itemType} contents` : `${itemType}s`}`}
              />
            }
            {tempItems && tempItems.map((channel: any) => {
              return (
                <div className='item-body channel-emoji-container' key={channel && channel.id}>
                  {
                    itemType === 'channel' &&
                    <>
                      {channel.emoji ?
                        <span
                          className="emoji-container"
                          dangerouslySetInnerHTML={{
                            //@ts-ignore
                            __html: Emoji({
                              html: true,
                              set: "apple",
                              emoji: channel.emoji,
                              size: 18
                            })
                          }}
                        />
                        :
                        <span>
                          <ChannelHashTag
                            fill="#000"
                          />
                        </span>
                      }
                    </>
                  }
                  {
                    itemType === 'library' &&
                    <>
                      {(channel && channel.type) ?
                        <div>
                          {
                            channel.type === 'video'
                            &&
                            <FilmIcon />
                          }

                          {
                            channel.type === 'audio'
                            &&
                            <HeadPhonesIcon />
                          }

                          {
                            channel.type === 'pdf'
                            &&
                            <FileIcon />
                          }

                          {
                            channel.type === 'embed'
                            &&
                            <EmbedIcon />
                          }
                        </div>
                        :
                        <>
                          {
                            (channel && channel.contentable_type) &&
                            <div>
                              {
                                channel.contentable_type === 'videos'
                                &&
                                <FilmIcon />
                              }

                              {
                                channel.contentable_type === 'audios'
                                &&
                                <HeadPhonesIcon />
                              }

                              {
                                channel.contentable_type === 'pdfs'
                                &&
                                <FileIcon />
                              }

                              {
                                channel.contentable_type === 'embeds'
                                &&
                                <EmbedIcon />
                              }

                            </div>
                          }
                        </>
                      }
                    </>
                  }
                  {
                    channel &&
                    <h1>
                      {
                        (itemType === 'channel' || itemType === 'segment') ? channel.name :
                          (itemType === 'course' && channel.course) ? channel.course.title : channel.title
                      }
                    </h1>
                  }
                  <span
                    className='x-icon'
                    onClick={() => {
                      setSelectedItemToRemove((prevSelectedItems: any) => [...(prevSelectedItems || []), channel]);
                      // const updatedChannels = items.filter(item => item.id !== channel.id);
                      const updatedChannels = tempItems.filter((item: any) => item.id !== channel.id);
                      setTempItems(updatedChannels)
                      // setItems(updatedChannels)
                    }}
                  >
                    <XIcon />
                  </span>
                </div>
              )
            })
            }
          </ul>
        </div>
      </div>
      <div className="footer">
        <button
          className='discard'
          onClick={() => {
            closeDNSDialog(itemType)
            setMessageState(null)
            if (selectedItemToRemove && selectedItemToRemove.length > 0) {
              // setItems([...items, ...selectedItemToRemove])
              setSelectedItemToRemove(null)
              setTempItems(items)
            }
            if (selectedItemToAdd) {
              const updatedChannels = items.filter(item => !selectedItemToAdd.some((ItemToAdd: any) => ItemToAdd.id === item.id));
              setItems(updatedChannels)
              setSelectedItemToAdd(null);
            }
          }}
        >
          Discard
        </button>
        <button
          onClick={() => {
            setMessageState(null)
            if (selectedItemToRemove) {
              removeItemAction()
            }
            if (selectedItemToAdd) {
              addItemAction()
            }
          }}
          className='save'>Save</button>
      </div>
    </dialog>
  )
}

const CancelSubscriptionModal = (
  {
    action,
    isOpen,
    setIsOpen,
  }
    : {
      action: any,
      isOpen: boolean,
      setIsOpen: (arg0: boolean) => void
    }
) => {

  const [subscriptionState, setSubscriptionState] = React.useState<SubscriptionStatus | null>(null);

  const changeAccessState = (event: any) => {
    setSubscriptionState(event.target.value);
  }

  React.useEffect(() => {
    if (!isOpen && subscriptionState) {
      setSubscriptionState(null);
    }
  }, [isOpen])

  const submit = () => {
    if (!subscriptionState) {
      toast('Please select one option', {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return;
    }
    action(subscriptionState)
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          width: "500px",
          maxWidth: "calc(100vw - 10px)",
          minHeight: "auto",
          borderRadius: "4px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          position: "fixed",
          background: "white",
          border: 0,
          padding: 0,
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 99999,
        }
      }}
    >
      <div className="cancel-subscription">
        <div className='dialog-header'>
          <span className='dialog-header-text'>
            Cancel subscription
          </span>
          <span
            className='dialog-close-icon'
            onClick={() => setIsOpen(false)}>
            <XIcon />
          </span>
        </div>

        <div className='dialog-body'>
          <span className='dialog-body-desc'>
            You are about to cancel member's subscription.
          </span>

          <div className='dialog-body-options'>
            <span className="dialog-body-options-text">
              Cancel
            </span>
            <div className="dialog-body-options-body">
              <div className="dialog-body-options-body-immediately">
                <input
                  type="radio"
                  className="dialog-body-options-body-immediately-choice"
                  value={SubscriptionStatus.CancelImmediately}
                  checked={subscriptionState === SubscriptionStatus.CancelImmediately}
                  onClick={changeAccessState}
                />
                <span className="dialog-body-options-body-immediately-label"> Immediately</span>
              </div>

              <div className='dialog-body-options-body-later'>
                <input
                  type="radio"
                  className="dialog-body-options-body-later-choice"
                  value={SubscriptionStatus.CancelLater}
                  checked={subscriptionState === SubscriptionStatus.CancelLater}
                  onClick={changeAccessState}
                />
                <span className="dialog-body-options-body-later-label"> End of billing period</span>
              </div>
            </div>
          </div>
        </div>

        <div className='dialog-footer'>
          <button
            className="dialog-footer-btn"
            onClick={submit}
          >
            Confirm
          </button>
        </div>

      </div>
    </ReactModal>
  )
}

const Loader = ({ section }: { section?: string }) => {

  const {
    Admin
  } = React.useContext(AdminMemberDetailsContext)

  return (
    <ContentLoader
      height={window.innerWidth <= 500 ? 90 : 30}
      width={120}
      speed={2}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      viewBox="0 0 120 30"
    >
      {
        !section &&
        <rect
          x="1"
          y="12.5"
          rx="4"
          ry="4"
          width={window.innerWidth <= 500 ? 280 : 12}
          height={window.innerWidth <= 500 ? 64 : 10}
        />
      }
      <rect
        x={section ? 0 : 20}
        y={section ? 6.5 : 12.5}
        rx="4" ry="4"
        width={window.innerWidth <= 500 ? 280 : section ? 90 : 120}
        height={window.innerWidth <= 500 ? 64 : 10}
      />
      {
        section && Admin &&
        <rect
          x="0"
          y="22.5"
          rx="4"
          ry="4"
          width={window.innerWidth <= 500 ? 280 : 120}
          height={window.innerWidth <= 500 ? 64 : 4}
        />
      }
    </ContentLoader>
  )
}


export default AdminMemberDetails