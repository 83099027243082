import React from 'react';
import { ISSLVerificationContainerProps } from './ISSLVerificationContainerProps';

const SSLVerificationContainer: React.FC<ISSLVerificationContainerProps> = (props) => {
  return (
    <>
     SSLVerificationContainer
    </>
  )
}

export default SSLVerificationContainer;