import { injectable } from "inversify";
import databaseInstance from "../../index";
import Axios from "axios";
import appConfig from "appConfig";

@injectable()
export class PageBuilderService {
  private dbInstance: any = databaseInstance;

  private static instance: PageBuilderService;

  constructor() {
    PageBuilderService.instance = this;
  }

  public static getInstance() {
    if (!PageBuilderService.instance) {
        PageBuilderService.instance = new PageBuilderService();
    }
    return PageBuilderService.instance;
  }

  public getCommunityPages: (
    groupId: number,
    page: number,
    searchString?: string,
  ) => Promise<void> = (groupId, page, searchString) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/pages?page=${page}${searchString ? `&search=${searchString}` : ''}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public getSinglePage: (
    groupId: number,
    pageId: number,
  ) => Promise<void> = (groupId, pageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/pages/${pageId}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public createCommunityPage: (
    groupId: number,
    pageDetails: any,
  ) => Promise<void> = (groupId, pageDetails) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/pages`, pageDetails)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public updateCommunityPage: (
    groupId: number,
    pageId: number,
    pageDetails: any,
  ) => Promise<void> = (groupId, pageId, pageDetails) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/pages/${pageId}`, pageDetails)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public addBlocksToPage: (
    groupId: number,
    pageId: number,
    blocksData: any,
  ) => Promise<void> = (groupId, pageId, blocksData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/pages/${pageId}/blocks`, blocksData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public uploadBlockImages: (
    groupId: number,
    pageId: number,
    imagesData: any,
  ) => Promise<void> = (groupId, pageId, imagesData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/pages/${pageId}/block-uploads`, imagesData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public previewPage: (
    groupId: number,
    pageId: number,
  ) => Promise<void> = (groupId, pageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/pages/${pageId}/preview`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public publishPage: (
    groupId: number,
    pageId: number,
  ) => Promise<void> = (groupId, pageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/pages/${pageId}/publish`)
      .then((response: any) => resolve(response))
      .catch((error: any) => reject(error));
    });
  }

  public unpublishPage: (
    groupId: number,
    pageId: number,
  ) => Promise<void> = (groupId, pageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/pages/${pageId}/publish`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public getLivePage: (
    groupId: number,
    pageId: number,
  ) => Promise<void> = (groupId, pageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/pages/${pageId}/live`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public duplicatePage: (
    groupId: number,
    pageId: number,
  ) => Promise<void> = (groupId, pageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/pages/${pageId}/duplicate`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public deletePage: (
    groupId: number,
    pageId: number,
  ) => Promise<void> = (groupId, pageId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/pages/${pageId}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public getGoogleFonts: () => Promise<void> = () => {
    return new Promise<void>((resolve, reject) => {
      Axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${appConfig.google_fonts.api_key}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public addWidgetsToPage: (
    groupId: number,
    pageId: number,
    widgetsData: any,
  ) => Promise<void> = (groupId, pageId, widgetsData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/pages/${pageId}/widgets`, widgetsData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public submitFormLead: (
    groupId: number,
    widgetId: number,
    submissionData: any,
  ) => Promise<void> = (groupId, widgetId, submissionData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/widgets/${widgetId}/lead-submission`, submissionData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public getCommunityWidgets: (
    groupId: number,
    query?: string
  ) => Promise<void> = (groupId, query) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/widgets${query ? `?${query}` : ''}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public exportFormLeads: (
    groupId: number,
    widgetId: number,
  ) => Promise<void> = (groupId, widgetId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/widgets/${widgetId}/leads/export`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }
}
