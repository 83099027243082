import React from 'react';
import miscUtils from 'utils/miscUtils';
import { IReacterProps } from './IReacterProps';
import './Reacter.scss';

const Reacter: React.FC<IReacterProps> = (props) => {
  return (
    <div
    className="reacter-container"
    >
        <div className="reacter-avater-container">
            {props.reacter.profile_image ? (
                <img
                src={props.reacter.profile_image}
                alt="avater"
                className="reacter-avater-image"
                />
            ) : (
                <span
                data-color={miscUtils.getColorInitial(
                    props.reacter.full_name
                )}
                className="reacter-avater-no-image"
                >
                {miscUtils.getNameInitials(props.reacter.full_name)}
                </span>
            )}
        </div>
        <p className="reacter-name">{props.reacter.full_name}</p>
    </div>
  )
}

export default Reacter