import styled from "styled-components";

const BlockContentContainer: any = styled.div<{design: any}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${(props: any) => 
        props.design.content_container_max_width ?
        `${props.design.content_container_max_width}px` :
        `0px`
    };
    max-width: 90%;

    &.mobile {
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media(max-width: 550px) {
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px; 
    }
`;

export default BlockContentContainer;