import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { ModalGateway, Modal } from "react-images";
import { PhotoCollageComponent } from './PhotoCollageComponent';
import { ReactComponent as XIcon } from "assets/img/close.svg";
import { ReactComponent as PreviousIcon } from "assets/img/arrow-previous.svg";
import { ReactComponent as NextIcon } from "assets/img/arrow-next.svg";
import "./GroupPost.scss";

const createPhotoIds = (photos: any[]) => {
    return photos.map((data: any, i: any) => {
        return { ...data, id: i }
    });
}
const createLayoutPhotoMaps = (layout: any[], photos: { source: string; }[]) => {
    const newPhotos = createPhotoIds(photos);
    const newMaps: any = {};
    layout.reduce((accumulator: any, currentValue: any, currentIndex: string | number) => {
        newMaps[currentIndex] = newPhotos.slice(accumulator, accumulator + currentValue);
        return accumulator + currentValue;
    }, 0);

    return newMaps;
}

interface PhotoCollageContainerProps {
    width?: string;
    height?: Array<string>;
    layout: Array<number>;
    photos: Array<{ source: string }>;
    showNumOfRemainingPhotos?: boolean
    memoizedFiles?: any
}
const checkProps = (props: PhotoCollageContainerProps) => {
    const defaultProps = {
        width: '800px',
        height: new Array(props.layout.length),
        layout: [],
        photos: [],
        showNumOfRemainingPhotos: false
    }
    const newProps = { ...defaultProps, ...props };
    if (newProps.height.length < newProps.layout.length) {
        for (let i = 0; i < newProps.layout.length; i++) {
            newProps.height[i] = '200px';
        }
    }
    return newProps;
}

const PhotoCollageContainer: React.FC<PhotoCollageContainerProps> = (props) => {
    const currProps = useMemo(() => checkProps(props), [props]);
    const { memoizedFiles, width, height, layout, photos, showNumOfRemainingPhotos } = currProps;
    const layoutNum = layout.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const remainingNum = photos.length - layoutNum;
    const [allowRender, setAllowRender] = useState<boolean>(false);
    const [layoutPhotoMaps, setLayoutPhotoMaps] = useState<any>({});
    const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<number>(0);

    useEffect(() => {
        setLayoutPhotoMaps(createLayoutPhotoMaps(layout, photos));
    }, [memoizedFiles]);

    useEffect(() => {
        Object.keys(layoutPhotoMaps).length ? setAllowRender(true) : setAllowRender(false);
    }, [layoutPhotoMaps]);

    const openLightbox = useCallback((id) => {
        setCurrentImage(parseInt(id));
        setViewerIsOpen(true);
    }, []);
    const closeLightbox = useCallback(() => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    }, []);

    const modalStyles = {
        blanket: (base: any) => ({
            ...base,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }),
        dialog: (base: any) => ({
            ...base,
            width: "98%",
            height: "97%",
            borderRadius: "8px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            position: "fixed",
            border: 0,
            overflow: "hidden",
            padding: 0,
            margin: 0,
            backgroundColor: "white",
        }),
        content: () => ({

        }),
        close: (base: any) => ({
            ...base,
            color: 'black',
        }),
    };

    if (allowRender) {
        return (
            <React.Fragment>
                <PhotoCollageComponent
                    width={width}
                    height={height}
                    layout={layout}
                    layoutPhotoMaps={layoutPhotoMaps}
                    layoutNum={layoutNum}
                    remainingNum={remainingNum}
                    showNumOfRemainingPhotos={showNumOfRemainingPhotos}
                    openLightbox={openLightbox}
                />
                <ModalGateway>
                    {
                        viewerIsOpen ?
                            (
                                <Modal styles={modalStyles} onClose={closeLightbox}>
                                    <Carousel
                                        images={photos}
                                        onClose={closeLightbox}
                                        currentImage={currentImage}
                                    />
                                </Modal>
                            ) : null
                    }
                </ModalGateway>
            </React.Fragment>
        );
    }
    return null;
}

export default PhotoCollageContainer;


const Carousel = (props: { images: any, onClose: any, currentImage: number }) => {
    const { images, onClose, currentImage } = props
    const [currentImageIndex, setCurrentImageIndex] = useState(currentImage);

    const prevImage = () => {
        if (currentImageIndex > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    };

    const nextImage = () => {
        if (currentImageIndex < images.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    };

    const closeCarousel = () => {
        // close the carousel
        onClose()
    };

    const showPrevButton = currentImageIndex > 0;
    const showNextButton = currentImageIndex < images.length - 1;

    const filenameWithExtension = images[currentImageIndex].originalFileName !== null && images[currentImageIndex].originalFileName.replace(/[^\w\s.-]/gi, " ")

    return (
        <div className="carousel-container">
            <div className="carousel-header">
                {
                    (window.innerWidth < 768) ?
                        <span
                            style={{
                                maxWidth: '220px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {filenameWithExtension}
                        </span>
                        :
                        <span>{filenameWithExtension}</span>
                }

                <span
                    onClick={closeCarousel}
                    style={{
                        height: 'auto',
                        width: '24px',
                        stroke: '#505050fe',
                        color: '#505050fe',
                        cursor: 'pointer'
                    }}>
                    <XIcon />
                </span>
            </div>

            <div className="carousel-image-container">
                <img
                    src={images[currentImageIndex].source}
                    alt="carousel-image"
                    style={{ width: "100%", height: "auto" }}
                />
            </div>
            <div className="carousel-buttons-container">
                {showPrevButton &&
                    <button
                        className="carousel-buttons-container-left"
                        onClick={prevImage}
                    >
                        <PreviousIcon />
                    </button>}
                {showNextButton &&
                    <button
                        className="carousel-buttons-container-right"
                        onClick={nextImage}
                    >
                        <NextIcon />
                    </button>}
            </div>
        </div>
    );
};
