import React from "react";
import ReactModal from "react-modal";
import { toast, ToastType } from "react-toastify";

import { ReactComponent as CloseIcon } from "assets/img/close.svg";
import './cancelsubscription.scss'

export enum SubscriptionStatus {
    CancelImmediately = 'cancel-immediately',
    CancelLater = 'cancel-later',
}

const CancelSubscriptionModal = (
    {
        memberName,
        action,
        isOpen,
        setIsOpen
    }
        : {
            memberName?: string,
            action: (arg0: string) => void,
            isOpen: boolean,
            setIsOpen: (arg0: boolean) => void
        }
) => {

    const [subscriptionState, setSubscriptionState] = React.useState<SubscriptionStatus | null>(null);

    const changeAccessState = (event: any) => {
        setSubscriptionState(event.target.value);
    }

    React.useEffect(() => {
        if (!isOpen && subscriptionState) {
            setSubscriptionState(null);
        }
    }, [isOpen])

    const submit = () => {
        if (!subscriptionState) {
            toast('Please select one option', {
                type: ToastType.ERROR,
                autoClose: 2000,
            });
            return;
        }
        action(subscriptionState)
    }

    return (
        <ReactModal
            isOpen={isOpen}
            style={{
                content: {
                    width: "500px",
                    maxWidth: "calc(100vw - 10px)",
                    minHeight: "auto",
                    borderRadius: "4px",
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    transform: "translate(-50%, -50%)",
                    position: "fixed",
                    background: "white",
                    border: 0,
                    padding: 0,
                },
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 99999,
                }
            }}
        >
            <div className="cancel-subscription">
                <div className='dialog-header'>
                    <span className='dialog-header-text'>
                        Cancel subscription
                    </span>
                    <span
                        className='dialog-close-icon'
                        onClick={() => setIsOpen(false)}>
                        <CloseIcon />
                    </span>
                </div>

                <div className='dialog-body'>
                    <span className='dialog-body-desc'>
                        You are about to cancel {memberName ? <span className='dialog-body-desc-member'>{memberName}'s</span> : "member"} subscription.
                    </span>

                    <div className='dialog-body-options'>
                        <span className="dialog-body-options-text"> Cancel </span>
                        <div className="dialog-body-options-body">
                            <div className="dialog-body-options-body-immediately">
                                <input
                                    type="radio"
                                    className="dialog-body-options-body-immediately-choice"
                                    value={SubscriptionStatus.CancelImmediately}
                                    checked={subscriptionState === SubscriptionStatus.CancelImmediately}
                                    onClick={changeAccessState}
                                />
                                <span className="dialog-body-options-body-immediately-label"> Immediately</span>
                            </div>

                            <div className='dialog-body-options-body-later'>
                                <input
                                    type="radio"
                                    className="dialog-body-options-body-later-choice"
                                    value={SubscriptionStatus.CancelLater}
                                    checked={subscriptionState === SubscriptionStatus.CancelLater}
                                    onClick={changeAccessState}
                                />
                                <span className="dialog-body-options-body-later-label"> End of billing period</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='dialog-footer'>
                    <button
                        className="dialog-footer-btn"
                        onClick={submit}
                    >
                        Confirm
                    </button>
                </div>

            </div>
        </ReactModal>
    )
}

export default CancelSubscriptionModal
