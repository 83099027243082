import { CommonConstants } from "../constants/common";
import Cookie from "js-cookie";

export const environment = {
  env: "dev",
  domain: {
    baseDomain: "group.localhost",
    protocol: "http",
    port: "3000",
  },
  server: {
    baseUrl: process.env.BASE_URL,
    port: process.env.SERVER_PORT
  },
  api: {
    baseUrl: process.env.REACT_APP_BASE_URL,
    staticBaseUrl: process.env.REACT_APP_STATIC_BASE_URL,
    storageBaseUrl: process.env.REACT_APP_CLOUDFLARE_STORAGE_BASE_URL
  },
  stripe: {
    public_key: "pk_test_29WjRIa1dBCGqHtDOq9w4sgy005aqUrCn9",
    connect_client_id: "ca_HA816Q9MptXXiHBSw15nStpgX75onwU4"
  },
  pusher: {
    key: "bc177ff518f725014655",
    cluster: "us3",
    broadcaster: "pusher",
    forceTLS: true,
    encrypted: false,
    authEndpoint: "https://api.stg.group.app/api/broadcasting/auth",
    auth: {
      headers: {
        Authorization: "Bearer " + Cookie.get(CommonConstants.USER_TOKEN),
        Accept: "application/json"
      }
    }
  },
  bunny: {
    post_videos: {
      library_id: process.env.REACT_APP_BUNNY_POST_VIDEOS_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_POST_VIDEOS_LIBRARY_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_POST_VIDEOS_LIBRARY_PULL_ZONE
    },
    standalone_library_videos: {
      library_id: process.env.REACT_APP_BUNNY_STANDALONE_LIBRARY_VIDEOS_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_STANDALONE_LIBRARY_VIDEOS_LIBRARY_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_STANDALONE_LIBRARY_VIDEOS_LIBRARY_PULL_ZONE
    },
    course_content_videos: {
      library_id: process.env.REACT_APP_BUNNY_COURSE_CONTENT_VIDEOS_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_COURSE_CONTENT_VIDEOS_LIBRARY_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_COURSE_CONTENT_VIDEOS_LIBRARY_PULL_ZONE
    },
    course_intro_videos: {
      library_id: process.env.REACT_APP_BUNNY_COURSE_INTRO_VIDEOS_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_COURSE_INTRO_VIDEOS_LIBRARY_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_COURSE_INTRO_VIDEOS_LIBRARY_PULL_ZONE
    },
    invite_videos: {
      library_id: process.env.REACT_APP_BUNNY_INVITE_VIDEOS_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_INVITE_VIDEOS_LIBRARY_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_INVITE_VIDEOS_LIBRARY_PULL_ZONE
    },
    community_welcome_videos: {
      library_id: process.env.REACT_APP_BUNNY_COMMUNITY_WELCOME_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_COMMUNITY_WELCOME_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_COMMUNITY_WELCOME_PULL_ZONE
    },
    quiz_question_videos: {
      library_id: process.env.REACT_APP_BUNNY_QUIZ_QUESTION_VIDEOS_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_QUIZ_QUESTION_VIDEOS_LIBRARY_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_QUIZ_QUESTION_VIDEOS_LIBRARY_PULL_ZONE
    },
    page_builder_videos: {
      library_id: process.env.REACT_APP_BUNNY_PAGE_BUILDER_VIDEOS_LIBRARY_ID,
      library_api_key: process.env.REACT_APP_BUNNY_PAGE_BUILDER_VIDEOS_LIBRARY_API_KEY,
      pull_zone: process.env.REACT_APP_BUNNY_PAGE_BUILDER_VIDEOS_LIBRARY_PULL_ZONE
    }
  },
  appsumo: {
    tier4_uuid: process.env.REACT_APP_APPSUMO_TIER4_UUID
  },
  gleap: {
    api_key: process.env.REACT_APP_GLEAP_API_KEY
  },
  google_fonts: {
    api_key: process.env.REACT_APP_GOOGLE_FONTS_API_KEY
  }
};
