import React from 'react';
import './SidebarLoader.scss';

const SidebarLoader = () => {
  return (
    <div
    className="sidebar-loader-container"
    ></div>
  )
}

export default SidebarLoader