import React, { useContext } from 'react'
import './giphy.scss'

import { Grid, SearchBar, SearchContext, SearchContextManager } from '@giphy/react-components';

import { ReactComponent as XIcon } from 'assets/img/close.svg';
import { ReactComponent as PoweredBYGIPHY } from 'assets/img/poweredbyGIPHY.svg'
import useWindowSize from 'utils/windowSize';

interface IGIF {
    clickInlineGif: (gif: any) => void
    setShowGIFComp: (close: boolean) => void
    fullScreen?: any
}

export const GIF: React.FC<IGIF> = ({ clickInlineGif, setShowGIFComp, fullScreen }) => {
    const giphyKey = process.env.REACT_APP_GIPHY_KEY;
    const close = false

    return (
        <div
            className="gif-grid-container"
            style={{
                height: fullScreen ? '400px' : '300px'
            }}
        >
            <span
                className='close-icon'
                onClick={() => setShowGIFComp(close)}
            >
                <XIcon />
            </span>
            <SearchContextManager
                // @ts-ignore
                // giphyKey might be undefined
                apiKey={giphyKey}
                isFocused={true}
            >
                <Components
                    clickInlineGif={clickInlineGif}
                    setShowGIFComp={setShowGIFComp}
                />
            </SearchContextManager >
        </div>
    );
}


const Components = (props:
    {
        clickInlineGif: (gif: any) => void
        setShowGIFComp: any
    }
) => {
    const { fetchGifs, searchKey } = useContext(SearchContext)
    const windowSize = useWindowSize();

    const Loader = () => {
        return (
            <span
                style={{ color: 'black', fontSize: '16px' }}
            >
                Loading...
            </span>
        )
    }

    return (
        <>
            <SearchBar
                placeholder={'Search for GIFS'}
                className='giphy-search-input'
            />
            <Grid
                key={searchKey}
                columns={2}
                width={windowSize.width <= 550 ? 270 : 330}
                fetchGifs={fetchGifs}
                gutter={6}
                borderRadius={8}
                hideAttribution={true}
                noResultsMessage={'No GIFs results found'}
                loader={() => <Loader />}
                // callback function to handle GIF click
                onGifClick={(gif, e) => {
                    e.preventDefault();
                    props.clickInlineGif(gif)
                    setTimeout(() => {
                        props.setShowGIFComp(false)
                    }, 750);
                }}
                className="gif-grid"
            />
            <div className="powered-by-gif">
                <div className="powered-by-gif-icon" >
                    <PoweredBYGIPHY />
                </div>
            </div>
        </>
    )
}
