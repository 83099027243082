import databaseInstance from "api";

const dbInstance: any = databaseInstance;

export const getCommunity = async (params: any) => {
    const { queryKey } = params;
    const [, communitySlug] = queryKey;

    if (!communitySlug) {
        throw new Error("Community Id Unavailable");
    }

    try {
        const response: any = await dbInstance.get(`/groups/${communitySlug}?include=channels:30,plans`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchCoursesSections = async (courseId: any) => {

    try {
        const response: any = await dbInstance.get(`/courses/${courseId}/sections`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}


export const fetchSectionContent = async (courseId: number, sectionId: number) => {

    try {
        const response: any = await dbInstance.get(`/courses/${courseId}/sections/${sectionId}/contents`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchCourseEnrollments = async (courseId: number, query?: string) => {

    try {
        const response: any = await dbInstance.get(`/courses/${courseId}/enrolments?nsearch=${query}`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchFlaggedContent = async (groupId: number) => {
    try {
        const response: any = await dbInstance.get(`/groups/${groupId}/flagged-contents`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchPostsToModerate = async (groupId: number) => {
    try {
        const response: any = await dbInstance.get(`/groups/${groupId}/moderated-posts`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchMembershipRequests = async (groupId: number, query: string = '') => {
    try {
        const response: any = await dbInstance.get(`/groups/${groupId}/membership-requests?search=${query}`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchModeratedContent = async (groupId: number) => {

    try {
        const response: any = await dbInstance.get(`/groups/${groupId}/moderated-contents`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchCoursesList = async (courseId: any) => {

    try {
        const response: any = await dbInstance.get(`/courses/${courseId}/sections`);
        return response.data;
    } catch (error) {
        throw new Error((error as any).message);
    }
}

export const fetchGroupMembers = async (
    groupId: number,
    query?: string,
    limit?: number,
    filter?: string,
    page?: number
) => {

    try {
        const response: any = await dbInstance.get(
            `/groups/${groupId}/memberships?search=${encodeURIComponent(query ? query : "")}&limit=${limit ? limit : 50}${filter && filter.length > 0 ? `&${filter}` : ""}&page=${page}`);
        if (response && response.data) {
            const groupMembers: any = response.data;

            groupMembers.data = groupMembers.data.map((member: any) => {
                let memberType = "";
                let memberStatus = "";

                if (member.approved) {
                    memberType = "Member";
                    memberStatus = "Active";
                }

                if (member.admin || member.owner) {
                    memberType = "Administrator";
                }

                if (member.blocked) {
                    memberStatus = "Blocked";
                }

                if (!member.user.job_title) {
                    member.user.job_title = "N/A";
                }

                if (!member.user.location) {
                    member.user.location = "N/A";
                }

                member.user = {
                    ...member.user,
                    type: memberType,
                    status: memberStatus
                };
                return member;
            });

            return groupMembers;
        }
    } catch (error) {
        throw new Error((error as any).message);
    }
}
