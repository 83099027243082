import React from 'react';
import { FooterIconTypes } from '../domain';
import FooterIcon from './FooterIcon';
import { IPostableEditorFooterProps } from './IPostableEditorFooterProps';
import "./PostableEditorFooter.scss";
import "./PostableEditorFooterResponsive.scss";
import AppToolTip from 'elements/tooltip/AppToolTip';
import {ReactComponent as SendIcon} from './FooterIcon/send.svg';

const PostableEditorFooter: React.FC<IPostableEditorFooterProps> = (props) => {

    const [highlightIcons, setHighlightIcons] = React.useState<Array<FooterIconTypes>>([]);
    const [screenWidth, setScreenWidth] = React.useState<number>(document.documentElement.clientWidth);

    React.useEffect(() => {
        const resizeCallback = () => {
          setScreenWidth(document.documentElement.clientWidth);
        };
        window.addEventListener("resize", resizeCallback);
    
        return () => {
          window.removeEventListener("resize", resizeCallback);
        };
      }, []);

    const getButtonText = () => {
        if(props.submitButtonText) {
            if(
                (props.isMakingRequest || props.fileIsUploading) &&
                props.submitButtonTextLoading
            ) {
                return props.submitButtonTextLoading;
            }
            return props.submitButtonText;
        }
        
        if(screenWidth <= 550) {
            return <SendIcon />
        }
        if(props.editMode) {
            if(props.isMakingRequest || props.fileIsUploading) {
                return 'Saving...';
            }

            return 'Save';
        }

        if(props.isMakingRequest || props.fileIsUploading) {
            return 'Posting...';
        }

        return 'Post';
    } 

    const hoverOverIcon = (icon: FooterIconTypes) => {
        if(props.activeIcon !== icon) {
            setHighlightIcons([...highlightIcons, icon]);
        }
    }

    const hoverOutsideIcon = (icon: FooterIconTypes) => {
        setHighlightIcons(highlightIcons.filter((highlightIcon: any) => highlightIcon !== icon))
    }


    const whiteCommunityTheme = props.theme && props.theme.custom && props.theme.custom.primaryColor === '#ffffff'

    return (
        <div className={`postable-editor-footer-container ${props.courseLessonMode ? 'hidden' : ''}`}>
            <div className="postable-editor-footer-actions-container">
                <AppToolTip
                content="Web link"
                >
                    <span
                    onClick={() => props.onIconClick(FooterIconTypes.GLOBE)}
                    onMouseOver={() => hoverOverIcon(FooterIconTypes.GLOBE)}
                    onMouseOut={() => hoverOutsideIcon(FooterIconTypes.GLOBE)}
                    >
                        <FooterIcon 
                        iconType={FooterIconTypes.GLOBE}
                            // theme={props.theme}
                            theme={!whiteCommunityTheme && props.theme}
                        active={props.activeIcon === FooterIconTypes.GLOBE || highlightIcons.includes(FooterIconTypes.GLOBE)}
                        />
                    </span>
                </AppToolTip>
                <AppToolTip
                content="Photo"
                >
                    <span
                    onClick={() => props.onIconClick(FooterIconTypes.IMAGE)}
                    onMouseOver={() => hoverOverIcon(FooterIconTypes.IMAGE)}
                    onMouseOut={() => hoverOutsideIcon(FooterIconTypes.IMAGE)}
                    >
                        <FooterIcon 
                        iconType={FooterIconTypes.IMAGE}
                            // theme={props.theme}
                            theme={!whiteCommunityTheme && props.theme}
                        active={props.activeIcon === FooterIconTypes.IMAGE || highlightIcons.includes(FooterIconTypes.IMAGE)}
                        />
                    </span>
                </AppToolTip>
                <AppToolTip
                content="Video"
                >
                    <span
                    onClick={() => props.onIconClick(FooterIconTypes.FILM)}
                    onMouseOver={() => hoverOverIcon(FooterIconTypes.FILM)}
                    onMouseOut={() => hoverOutsideIcon(FooterIconTypes.FILM)}
                    >
                        <FooterIcon 
                        iconType={FooterIconTypes.FILM}
                            // theme={props.theme}
                            theme={!whiteCommunityTheme && props.theme}
                        active={props.activeIcon === FooterIconTypes.FILM || highlightIcons.includes(FooterIconTypes.FILM)}
                        />
                    </span>
                </AppToolTip>
                <AppToolTip
                content="Web video link"
                >
                    <span
                    onClick={() => props.onIconClick(FooterIconTypes.VIDEO)}
                    onMouseOver={() => hoverOverIcon(FooterIconTypes.VIDEO)}
                    onMouseOut={() => hoverOutsideIcon(FooterIconTypes.VIDEO)}
                    >
                        <FooterIcon
                        iconType={FooterIconTypes.VIDEO}
                            // theme={props.theme}
                            theme={!whiteCommunityTheme && props.theme}
                        active={props.activeIcon === FooterIconTypes.VIDEO || highlightIcons.includes(FooterIconTypes.VIDEO)}
                        />
                    </span>
                </AppToolTip>
                <AppToolTip
                content="Audio"
                >
                    <span
                    onClick={() => props.onIconClick(FooterIconTypes.HEADPHONES)}
                    onMouseOver={() => hoverOverIcon(FooterIconTypes.HEADPHONES)}
                    onMouseOut={() => hoverOutsideIcon(FooterIconTypes.HEADPHONES)}
                    >
                        <FooterIcon 
                        iconType={FooterIconTypes.HEADPHONES}
                            // theme={props.theme}
                            theme={!whiteCommunityTheme && props.theme}
                        active={props.activeIcon === FooterIconTypes.HEADPHONES || highlightIcons.includes(FooterIconTypes.HEADPHONES)}
                        />
                    </span>
                </AppToolTip>
                <AppToolTip
                content="Attach file"
                >
                    <span
                    onClick={() => props.onIconClick(FooterIconTypes.FILE)}
                    onMouseOver={() => hoverOverIcon(FooterIconTypes.FILE)}
                    onMouseOut={() => hoverOutsideIcon(FooterIconTypes.FILE)}
                    >
                        <FooterIcon 
                        iconType={FooterIconTypes.FILE}
                            // theme={props.theme}
                            theme={!whiteCommunityTheme && props.theme}
                        active={props.activeIcon === FooterIconTypes.FILE || highlightIcons.includes(FooterIconTypes.FILE)}
                        />
                    </span>
                </AppToolTip>
                <AppToolTip
                content="Embed"
                >
                    <span
                    onClick={() => props.onIconClick(FooterIconTypes.EMBED)}
                    onMouseOver={() => hoverOverIcon(FooterIconTypes.EMBED)}
                    onMouseOut={() => hoverOutsideIcon(FooterIconTypes.EMBED)}
                    >
                        <FooterIcon
                        iconType={FooterIconTypes.EMBED}
                            theme={!whiteCommunityTheme && props.theme}
                        active={props.activeIcon === FooterIconTypes.EMBED || highlightIcons.includes(FooterIconTypes.EMBED)}
                        />
                    </span>
                </AppToolTip>
            </div>

            <button 
            className={`postable-editor-footer-submit ${props.isSubmitDisabled ? 'postable-editor-footer-submit-disabled' : ''} ${screenWidth <= 550 ? 'postable-editor-footer-submit--icon' : ''} ${props.messagingMode || props.hideSubmitButton ? 'postable-editor-footer-submit-hidden' : ''}`}
            style={{
                backgroundColor: (props.theme && props.theme.custom)? props.theme.custom.primaryColor : '',
                color: whiteCommunityTheme ? '#000' : '',
                border: whiteCommunityTheme ? '1px solid #000' : ''
            }}
            onClick={props.onSubmit}
            disabled={props.isMakingRequest || props.fileIsUploading}
            id="groupapp-post-builder-submit-button"
            >
                {getButtonText()}
            </button>
        </div>
    )
}

export default PostableEditorFooter;
