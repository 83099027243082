export enum DisplayMode {
    CARD = 'card-ui-display',
    LIST = 'list-ui-display',
}

export enum DisplayVariant {
    COURSE = "course",
    STANDALONE = "standalone",
    CATEGORY = "category"
}

export enum LIBRARY_DISPLAY_TABS {
    ALL_COURSES = "All Courses", 
    CATEGORIES = "Categories", 
    ALL_CONTENT = "All Content"
}