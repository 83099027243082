import { FooterIconTypes } from 'components/post/domain';
import React from 'react';
import { IFooterIconProps } from './IFooterIconProps';

const FooterIcon: React.FC<IFooterIconProps> = (props) => {

    const strokeColor: string = props.active ? props.theme && props.theme.custom && props.theme.custom.primaryColor !== '#ffffff' ? `${props.theme.custom.primaryColor}` : '#365DE6' : '#D5D8DF';

    switch(props.iconType) {
        case FooterIconTypes.GLOBE:
            return (
                <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 12H22" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </>
            );
        
        case FooterIconTypes.IMAGE:
            return (
                <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 15L16 10L5 21" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </>
            );
        
        case FooterIconTypes.FILM:
            return (
                <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.82 2H4.18C2.97602 2 2 2.97602 2 4.18V19.82C2 21.024 2.97602 22 4.18 22H19.82C21.024 22 22 21.024 22 19.82V4.18C22 2.97602 21.024 2 19.82 2Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 2V22" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17 2V22" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 12H22" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 7H7" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 17H7" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17 17H22" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17 7H22" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </>
            );
        
        case FooterIconTypes.VIDEO:
            return (
                <>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5401 7.40444C22.4213 6.92985 22.1794 6.49501 21.8387 6.14384C21.4981 5.79268 21.0708 5.53762 20.6001 5.40444C18.8801 4.98444 12.0001 4.98444 12.0001 4.98444C12.0001 4.98444 5.12008 4.98444 3.40008 5.44444C2.92933 5.57762 2.50206 5.83268 2.16143 6.18384C1.8208 6.53501 1.57887 6.96985 1.46008 7.44444C1.1453 9.18999 0.991319 10.9607 1.00008 12.7344C0.988863 14.5215 1.14285 16.3057 1.46008 18.0644C1.59104 18.5243 1.83839 18.9426 2.17823 19.2789C2.51806 19.6152 2.9389 19.8582 3.40008 19.9844C5.12008 20.4444 12.0001 20.4444 12.0001 20.4444C12.0001 20.4444 18.8801 20.4444 20.6001 19.9844C21.0708 19.8513 21.4981 19.5962 21.8387 19.245C22.1794 18.8939 22.4213 18.459 22.5401 17.9844C22.8524 16.252 23.0064 14.4948 23.0001 12.7344C23.0113 10.9474 22.8573 9.16313 22.5401 7.40444V7.40444Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.75 16.0044L15.5 12.7344L9.75 9.46442V16.0044Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </>
            )
        
        case FooterIconTypes.HEADPHONES:
            return (
                <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 18V12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12V18" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H18C17.4696 21 16.9609 20.7893 16.5858 20.4142C16.2107 20.0391 16 19.5304 16 19V16C16 15.4696 16.2107 14.9609 16.5858 14.5858C16.9609 14.2107 17.4696 14 18 14H21V19ZM3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H6C6.53043 21 7.03914 20.7893 7.41421 20.4142C7.78929 20.0391 8 19.5304 8 19V16C8 15.4696 7.78929 14.9609 7.41421 14.5858C7.03914 14.2107 6.53043 14 6 14H3V19Z" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </>
            );
        
        case FooterIconTypes.FILE:
            return (
                <>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.4398 11.05L12.2498 20.24C11.124 21.3658 9.59699 21.9983 8.0048 21.9983C6.41262 21.9983 4.88565 21.3658 3.7598 20.24C2.63396 19.1142 2.00146 17.5872 2.00146 15.995C2.00146 14.4028 2.63396 12.8758 3.7598 11.75L12.9498 2.56C13.7004 1.80943 14.7183 1.38777 15.7798 1.38777C16.8413 1.38777 17.8592 1.80943 18.6098 2.56C19.3604 3.31056 19.782 4.32854 19.782 5.39C19.782 6.45145 19.3604 7.46943 18.6098 8.22L9.4098 17.41C9.03452 17.7853 8.52553 17.9961 7.9948 17.9961C7.46407 17.9961 6.95508 17.7853 6.5798 17.41C6.20452 17.0347 5.99369 16.5257 5.99369 15.995C5.99369 15.4643 6.20452 14.9553 6.5798 14.58L15.0698 6.1" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </>
            );
        
        case FooterIconTypes.EMBED:
            return (
                <>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_907_1957)">
                <path d="M23 8C23.0001 7.86634 22.9733 7.73402 22.9212 7.61092C22.8691 7.48783 22.7928 7.37648 22.6968 7.2835L15.7068 0.292875C15.701 0.287125 15.6949 0.28225 15.689 0.27675C15.6715 0.259687 15.6534 0.243259 15.6347 0.2275C15.6264 0.22075 15.6175 0.215 15.6092 0.2085C15.5917 0.195 15.5743 0.181375 15.5559 0.169C15.5489 0.164375 15.5414 0.160375 15.5342 0.155875C15.5138 0.142631 15.493 0.130123 15.4717 0.118375C15.4667 0.115875 15.4613 0.113625 15.4562 0.111C15.4323 0.0985061 15.4079 0.0869963 15.3831 0.0764999C15.3797 0.0749999 15.3761 0.0740002 15.3726 0.0726252C15.3459 0.0616769 15.3187 0.0519172 15.2911 0.043375C15.2877 0.042375 15.284 0.04175 15.2806 0.04075C15.2529 0.0324434 15.2249 0.0253531 15.1966 0.0194998C15.188 0.0178748 15.1791 0.017125 15.1706 0.015625C15.1471 0.011625 15.1236 0.00737512 15.0996 0.00500012C15.0664 0.00166636 15.0331 -2.50825e-06 14.9997 2.82948e-09H2.99982C2.46962 0.00059553 1.9613 0.211501 1.58639 0.586444C1.21148 0.961388 1.0006 1.46975 1 2V13C1 13.2652 1.10535 13.5196 1.29287 13.7071C1.48039 13.8946 1.73472 14 1.99991 14C2.2651 14 2.51943 13.8946 2.70695 13.7071C2.89447 13.5196 2.99982 13.2652 2.99982 13V2H13.9998V8C13.9998 8.26522 14.1052 8.51957 14.2927 8.70711C14.4802 8.89464 14.7345 9 14.9997 9H20.9992V13C20.9992 13.2652 21.1045 13.5196 21.292 13.7071C21.4796 13.8946 21.7339 14 21.9991 14C22.2643 14 22.5186 13.8946 22.7061 13.7071C22.8937 13.5196 22.999 13.2652 22.999 13V8.02C22.9991 8.01325 23 8.00675 23 8ZM15.9996 3.41425L19.5852 7H15.9996V3.41425Z" fill={strokeColor}/>
                <path d="M10.8508 19.3422C11.0175 19.1772 11.1111 18.9534 11.1111 18.72C11.1111 18.4866 11.0175 18.2628 10.8508 18.0977C10.6841 17.9327 10.458 17.84 10.2222 17.84H6.66667C6.43092 17.84 6.20483 17.9327 6.03813 18.0977C5.87143 18.2628 5.77778 18.4866 5.77778 18.72C5.77778 18.9534 5.87143 19.1772 6.03813 19.3422C6.20483 19.5073 6.43092 19.6 6.66667 19.6H7.55556V23.12C7.55556 23.3534 7.64921 23.5772 7.8159 23.7422C7.9826 23.9073 8.2087 24 8.44444 24C8.68019 24 8.90628 23.9073 9.07298 23.7422C9.23968 23.5772 9.33333 23.3534 9.33333 23.12V19.6H10.2222C10.458 19.6 10.6841 19.5073 10.8508 19.3422Z" fill={strokeColor}/>
                <path d="M3.37146 18.0977C3.53816 17.9327 3.76425 17.84 4 17.84C4.23575 17.84 4.46184 17.9327 4.62854 18.0977C4.79524 18.2628 4.88889 18.4866 4.88889 18.72V23.12C4.88889 23.3534 4.79524 23.5772 4.62854 23.7422C4.46184 23.9073 4.23575 24 4 24C3.76425 24 3.53816 23.9073 3.37146 23.7422C3.20476 23.5772 3.11111 23.3534 3.11111 23.12V21.8H1.77778V23.12C1.77778 23.3534 1.68413 23.5772 1.51743 23.7422C1.35073 23.9073 1.12464 24 0.888889 24C0.653141 24 0.427049 23.9073 0.26035 23.7422C0.0936505 23.5772 0 23.3534 0 23.12V18.72C0 18.4866 0.0936505 18.2628 0.26035 18.0977C0.427049 17.9327 0.653141 17.84 0.888889 17.84C1.12464 17.84 1.35073 17.9327 1.51743 18.0977C1.68413 18.2628 1.77778 18.4866 1.77778 18.72V20.04H3.11111V18.72C3.11111 18.4866 3.20476 18.2628 3.37146 18.0977Z" fill={strokeColor}/>
                <path d="M21.3333 22.24H23.1111C23.3469 22.24 23.573 22.3327 23.7397 22.4977C23.9063 22.6628 24 22.8866 24 23.12C24 23.3534 23.9063 23.5772 23.7397 23.7422C23.573 23.9073 23.3469 24 23.1111 24H20.4444C20.2087 24 19.9826 23.9073 19.8159 23.7422C19.6492 23.5772 19.5556 23.3534 19.5556 23.12V18.72C19.5556 18.4866 19.6492 18.2628 19.8159 18.0977C19.9826 17.9327 20.2087 17.84 20.4444 17.84C20.6802 17.84 20.9063 17.9327 21.073 18.0977C21.2397 18.2628 21.3333 18.4866 21.3333 18.72V22.24Z" fill={strokeColor}/>
                <path d="M17.0498 17.8851C17.2282 17.8256 17.4213 17.8252 17.6 17.884C17.7817 17.9369 17.941 18.0472 18.0534 18.1982C18.1657 18.3492 18.2251 18.5325 18.2222 18.72V23.12C18.2222 23.3534 18.1286 23.5772 17.9619 23.7422C17.7952 23.9073 17.5691 24 17.3333 24C17.0976 24 16.8715 23.9073 16.7048 23.7422C16.5381 23.5772 16.4444 23.3534 16.4444 23.12V21.47L15.8333 22.306C15.7526 22.4217 15.6447 22.5163 15.5189 22.5816C15.3931 22.647 15.2532 22.6811 15.1111 22.6811C14.9691 22.6811 14.8291 22.647 14.7033 22.5816C14.5775 22.5163 14.4696 22.4217 14.3889 22.306L13.7778 21.47V23.12C13.7778 23.3534 13.6841 23.5772 13.5174 23.7422C13.3507 23.9073 13.1246 24 12.8889 24C12.6531 24 12.427 23.9073 12.2603 23.7422C12.0936 23.5772 12 23.3534 12 23.12V18.72C12.0015 18.5343 12.0623 18.3538 12.1736 18.2044C12.285 18.055 12.4413 17.9443 12.6201 17.8882C12.7989 17.832 12.991 17.8333 13.169 17.8918C13.347 17.9503 13.5018 18.0631 13.6111 18.214L15.1111 20.282L16.6111 18.214C16.7174 18.0601 16.8713 17.9447 17.0498 17.8851Z" fill={strokeColor}/>
                </g>
                <defs>
                <clipPath id="clip0_907_1957">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
                </>
            );

        default:
            return <></>
    }
}

export default FooterIcon;
