import React from 'react';
import { isValidHexColor } from 'components/PageBuilder/PageBuilderSideBar/BlockSettings/domain';
import styled from 'styled-components';
import { IFAQInBlockPreviewProps } from './IFAQInBlockPreviewProps';
import BlockTextContainer from '../../BlockComponents/BlockTextContainer';
import anchorme from 'anchorme';
import MinusIcon from './icons/MinusIcon';
import AddIcon from './icons/AddIcon';

const FAQInBlockContainer: any = styled.div<{
    design: any, 
}>`
  width: 100%;
`;

const FAQInBlockHeaderContainer: any = styled.div<{
    design: any, 
    index: number, 
}>`
    width: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-top: ${(props: any) => 
        props.index ?
        props.design.question_container_border_color && 
        isValidHexColor(props.design.question_container_border_color) ?
        `${
            props.design.question_container_border_width ?
            props.design.question_container_border_width :
            1
        }px solid ${props.design.question_container_border_color}` :
        `${
            props.design.question_container_border_width ?
            props.design.question_container_border_width :
            1
        }px solid #D5D8DF` :
        `none`
    };
    background-color: ${(props: any) => 
        props.design.question_container_background_color && 
        isValidHexColor(props.design.question_container_background_color) ?
        props.design.question_container_background_color :
        '#EEF5FF'
    };

    &.mobile {
        padding: 12px;
    }

    @media(max-width: 550px) {
        padding: 12px;
    }
`;

const FAQInBlockHeaderTextContainer: any = styled.div`
    width: 95%
`;

const FAQInBlockHeaderIconContainer: any = styled.span`
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const FAQInBlockContentContainer: any = styled.div<{
    design: any,
}>`
    padding: 24px;
    border-top: ${(props: any) => 
        props.design.question_container_border_color && 
        isValidHexColor(props.design.question_container_border_color) ?
        `${
            props.design.question_container_border_width ?
            props.design.question_container_border_width :
            1
        }px solid ${props.design.question_container_border_color}` :
        `${
            props.design.question_container_border_width ?
            props.design.question_container_border_width :
            1
        }px solid #D5D8DF`
    };
    background-color: ${(props: any) => 
        props.design.answer_container_background_color && 
        isValidHexColor(props.design.answer_container_background_color) ?
        props.design.answer_container_background_color :
        '#FFFFFF'
    };

    &.mobile {
        padding: 12px;
    }

    @media(max-width: 550px) {
        padding: 12px;
    }
`;

const FAQInBlockTextContainer: any = styled(BlockTextContainer)`
  text-align: left;
`;

const FAQInBlockPreview: React.FC<IFAQInBlockPreviewProps> = (props) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <FAQInBlockContainer
    design={props.blockData.design}
    >
        <FAQInBlockHeaderContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        index={props.index}
        onClick={() => setIsOpen(!isOpen)}
        >
            <FAQInBlockHeaderTextContainer
            >
                <FAQInBlockTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="question"
                fallbackTextColor="#000000"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.question),
                }}
                />
            </FAQInBlockHeaderTextContainer>

            <FAQInBlockHeaderIconContainer
            >
                {isOpen ? (
                    <MinusIcon
                    fill={
                        props.blockData.design.question_container_icon_color && 
                        isValidHexColor(props.blockData.design.question_container_icon_color) ?
                        props.blockData.design.question_container_icon_color :
                        `#D5D8DF`
                    }
                    />
                ) : (
                    <AddIcon
                    fill={
                        props.blockData.design.question_container_icon_color && 
                        isValidHexColor(props.blockData.design.question_container_icon_color) ?
                        props.blockData.design.question_container_icon_color :
                        `#D5D8DF`
                    }
                    />
                )}
            </FAQInBlockHeaderIconContainer>
        </FAQInBlockHeaderContainer>

        {isOpen ? (
            <FAQInBlockContentContainer
            className={`${props.view ? props.view : ''}`}
            design={props.blockData.design}
            >
                <FAQInBlockTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="answer"
                fallbackTextColor="#000000"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.answer),
                }}
                />
            </FAQInBlockContentContainer>
        ) : <></>}
    </FAQInBlockContainer>
  )
}

export default FAQInBlockPreview;