import React from 'react';
import { IPricingBlockPreviewProps } from './IPricingBlockPreviewProps';
import anchorme from 'anchorme';
import PriceInBlockPreview from './PriceInBlockPreview';
import { ButtonAction, scrollElementIntoView } from 'containers/PageBuilder/SinglePageContainer/domain';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import styled from 'styled-components';
import BlockTextContainer, { BlockSubTextContainer } from '../BlockComponents/BlockTextContainer';
import BlockButton from '../BlockComponents/BlockButton';

const PricingBlockContentContainer: any = styled(BlockContentContainer)`
    align-items: ${(props: any) =>
        props.design.text_alignment === 'right' ? 
        `flex-end`:
        props.design.text_alignment === 'left' ?
        'flex-start' :
        'center'
    };
`;

const PricingBlockPricesContainer: any = styled.div`
    margin-top: 40px;
`;

const PricingBlockPreview: React.FC<IPricingBlockPreviewProps> = (props) => {

    const ctaOnClick = () => {
        if(props.buildingPreview) {
            return;
        }

        if(
            props.blockData &&
            (
                props.blockData.button_action === ButtonAction.URL ||
                props.blockData.button_action === ButtonAction.URLTab
            ) &&
            props.blockData.button_url
        ) {
            window.open(
                props.blockData.button_url, 
                props.blockData.button_action === ButtonAction.URLTab ?
                '_blank' :
                '_self'
            );
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Scroll &&
            props.blockData.button_url &&
            props.containerBaseIdName
        ) {
            scrollElementIntoView(`${props.containerBaseIdName}${props.blockData.button_url}`);
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Popup &&
            props.blockData.button_url
        ) {
            let nextPageFormsOpenState = JSON.parse(JSON.stringify(props.pageFormsOpenState));

            nextPageFormsOpenState[props.blockData.button_url] = true;

            props.setPageFormsOpenState(nextPageFormsOpenState);
        }
    }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <PricingBlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
          <BlockTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="heading"
          fallbackTextColor="#000000"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.heading),
          }}
          />

          <BlockSubTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="subtext"
          fallbackTextColor="#666666"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.subtext),
          }}
          />

          <PricingBlockPricesContainer
          >
            {props.blockData.blocks.map((block: any, index: number) => (
                <PriceInBlockPreview
                key={block.render_id}
                buildingPreview={props.buildingPreview}
                index={index}
                blockData={{
                    ...block,
                    design: {
                        ...props.blockData.design,
                    }
                }}
                view={props.view}
                pageStyles={props.pageStyles}
                />
            ))}
          </PricingBlockPricesContainer>

            <BlockButton
            design={props.blockData.design}
            pageStyles={props.pageStyles}
            onClick={ctaOnClick}
            >
                {props.blockData.button_text}
            </BlockButton>
        </PricingBlockContentContainer>
    </BlockContainer>
  )
}

export default PricingBlockPreview;