import React, { Component } from "react";

import { IReadMoreProps } from "./IReadMoreProps";
import { IReadMoreState } from "./IReadMoreState";

import "./readMore.scss";
import anchorme from "anchorme";
import ReactHtmlParser from "react-html-parser";

const ELLIPSES = "…";

export default class ReadMore extends Component<
  IReadMoreProps,
  IReadMoreState
> {
  public static defaultProps = {
    numberOfLines: 2,
    lineHeight: 2,
    readMoreCharacterLimit: 250,
    showLessButton: false,
    showDefaultButton: true
  };

  public state = {
    showingAll: false
  };

  public componentDidMount() {
    if (this.props.onContentChange) {
      this.props.onContentChange();
    }
  }

  public toggleReadMore = () => {
    this.setState({
      showingAll: !this.state.showingAll
    });
  };

  public getReadMoreParts = ({
    text,
    numberOfLines,
    readMoreCharacterLimit
  }: {
    text: string | undefined;
    numberOfLines: number;
    readMoreCharacterLimit: any;
  }) => {
    let teaserText = "";
    let remainingText = "";
    const remainingWordsArray = [];

    if (text) {
      const teaserWordsArray = text.split(" ");

      while (teaserWordsArray.join(" ").length > readMoreCharacterLimit) {
        remainingWordsArray.unshift(teaserWordsArray.pop());
      }

      teaserText = teaserWordsArray.join(" ");
      if (!teaserText.length) {
        teaserText = text.split(" ")[0].substring(0, readMoreCharacterLimit);
      }

      if (remainingWordsArray.length > 0) {
        remainingText = remainingWordsArray.join(" ");
        remainingText = " " + remainingText;
      }
    }

    return {
      teaserText,
      remainingText
    };
  };

  public getActionButton = ({
    showingAll,
    showLessButton,
    showDefaultButton
  }: {
    showingAll: any;
    showLessButton: any;
    showDefaultButton: any
  }) => {
    if (showingAll && !showLessButton) {
      return;
    }
    if (!showDefaultButton) {
      return;
    }
    const SHOW_LESS_TEXT = this.props.showLessText || "Show Less";
    const SHOW_MORE_TEXT = this.props.showMoreText || "Read More";

    const buttonText = showingAll ? SHOW_LESS_TEXT : SHOW_MORE_TEXT;

    return (
      <button onClick={this.toggleReadMore} className="read-more__button">
        {buttonText}
      </button>
    );
  };

  public getText = ({
    showingAll,
    text,
    readMoreCharacterLimit,
    numberOfLines,
    showLessButton,
    showDefaultButton
  }: {
    showingAll: any;
    text: string | undefined;
    readMoreCharacterLimit: any;
    numberOfLines: number;
    showLessButton: any;
    showDefaultButton: any
  }) => {
    const { teaserText, remainingText } = this.getReadMoreParts({
      text,
      numberOfLines,
      readMoreCharacterLimit
    });

    const actionButton =
      text &&
      text.length > readMoreCharacterLimit &&
      this.getActionButton({ showingAll, showLessButton, showDefaultButton });

    // @ts-ignore
    if (!showingAll && text && text.length > readMoreCharacterLimit) {
      return (
        <span
          style={this.props.fontStyle ? this.props.fontStyle : {}}
          className={this.props.className ? this.props.className : ""}
        >
          {// @ts-ignore
            ReactHtmlParser(anchorme(teaserText.replace(/\s*$/, "")))}
          {ELLIPSES}
          {actionButton}
          <span className="read-more__text--remaining read-more__text--hide">
            {remainingText && ReactHtmlParser(anchorme(remainingText))}
            {ELLIPSES}
            {actionButton}
          </span>
        </span>
      );
    }

    return (
      <span
        style={this.props.fontStyle ? this.props.fontStyle : {}}
        className={this.props.className ? this.props.className : ""}
      >
        {(teaserText || remainingText) &&
          ReactHtmlParser(
            anchorme(teaserText.concat(remainingText).concat("  "))
          )}
        {actionButton}
      </span>
    );
  };

  public render() {
    const {
      text,
      readMoreCharacterLimit,
      showLessButton,
      numberOfLines,
      showDefaultButton,
    } = this.props;

    const { showingAll } = this.state;
    const textToDisplay = this.getText({
      showingAll,
      text,
      readMoreCharacterLimit,
      numberOfLines,
      showLessButton,
      showDefaultButton
    });

    return <div className="read-more">{textToDisplay}</div>;
  }
}
