import React from 'react';
import './ProfileSlideout.scss';

import { Animate } from 'react-simple-animate';
import PerfectScrollbar from 'react-perfect-scrollbar';
import miscUtils from 'utils/miscUtils';
import { ReactComponent as TwitterIcon } from 'assets/img/twitter.svg';
import { ReactComponent as InstagramIcon } from 'assets/img/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/img/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/img/linkedin.svg';
import { ReactComponent as WebsiteIcon } from 'assets/img/globe.svg';
import { ReactComponent as MapPinIcon } from 'assets/img/map-pin.svg';
import { ReactComponent as XIcon } from 'assets/img//x.svg';
import { ReactComponent as Clock } from 'assets/img/clock.svg';
import { ReactComponent as MenuIcon } from 'assets/img/menu.svg';
import ReadMore from 'utils/readMore/readMore';
import useSlideoutProfile from 'hooks/slideoutProfileHook';
import clsx from "clsx";
import { UserService } from 'api/services/users';
import AppLoader from 'components/contentLoaders/appLoader';
import PricingService from 'api/services/pricing';
import { GroupService } from 'api/services/groups';
import { QuestionTypes } from 'containers/Onboarding/MembershipQuestions/QuestionMaker/domain';
import moment from 'moment';
import { toast, ToastType } from 'react-toastify';
import PopOnMe from 'elements/poponme';
import { getCookieValue } from 'utils/cookieStorage';

import { useAtom } from 'jotai';
import { showActiveCHatAtom, showMessagePopUpAtom } from 'showMessageMenuAtom';
import { DirectChatsContext } from 'containers/main/MainComponent';
import PrivilegeBadge from 'components/userComponent/privilegeBadge';

const ABOUT_ME_CHARACTER_LIMIT: number = 100;

interface IProfileSlideoutProps {
    group: any;
    playSlideoutAnimation: any;
    setPlaySlideoutAnimation: any;
    selectedSlideoutProfile: any;
    groupMembersCallbacksRef?: any;
    communityView?: boolean;
    topOffset?: number;
    showMenu?: boolean;
    extraHeight?: string;
    classes?: string;
}

const ProfileSlideout: React.FC<IProfileSlideoutProps> = (props) => {

    const [screenWidth, setScreenWidth] = React.useState<number>(document.documentElement.clientWidth);
    const [userIsBlocked, setUserIsBlocked] = React.useState<boolean>(false);
    const [subscription, setSubscription] = React.useState<any>({
        name: "",
        amount: "",
        currency: "",
        interval: null,
        interval_count: null,
        status: null,
        next_billing_date: null,
        trial_period: null,
        coupon_code: null,
    });
    const [canChat, setCanChat] = React.useState<boolean>(() => {
        if (props.selectedSlideoutProfile) {
            return !!props.selectedSlideoutProfile.can_chat
        }
        return false;
    });
    const [userPurchasesData, setUserPurchasesData] = React.useState<any>(null);
    const [shouldShowMenu, setShouldShowMenu] = React.useState<boolean>(false);
    const [userMembership, setUserMembership] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>(true);

    const { closeSlideout } = useSlideoutProfile();

    React.useEffect(() => {
        if (props.selectedSlideoutProfile) {
            if (isUserMembership(props.selectedSlideoutProfile)) {
                setUserMembership(props.selectedSlideoutProfile);
                setLoading(false);
            } else {
                UserService.getInstance()
                    .getUserMembership(props.group.id, props.selectedSlideoutProfile.id)
                    .then((response: any) => {
                        if (response === null) {
                            setUserMembership({ user: props.selectedSlideoutProfile, status: 'Removed' });
                        } else {
                            setUserMembership(response);
                        }

                        setLoading(false);
                    })
            }
        }
    }, [props.selectedSlideoutProfile])


    React.useEffect(() => {
        if (userMembership) {
            setSubscription(() => {
                return getSubscription(userMembership);
            });
            setCanChat(!!userMembership.can_chat);
            setUserIsBlocked(userMembership.blocked);
            setUserPurchasesData(null);
            getUserPurchases();
        }
    }, [userMembership]);

    React.useEffect(() => {
        const resizeCallback = () => {
            setScreenWidth(document.documentElement.clientWidth);
        };
        window.addEventListener("resize", resizeCallback);

        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, []);

    React.useEffect(() => {
        setShouldShowMenu(!!props.showMenu && userMembership && !userMembership.owner);
    }, [userMembership, props.showMenu])

    const endStyles = {
        zIndex: 9999,
        position: 'absolute',
        top: props.topOffset ? props.topOffset : 40,
        right: props.communityView ? 0 : screenWidth > 550 ? 0 : '20px',
        width: screenWidth > 550 ? '355px' : '100vw',
        height: props.extraHeight ? `calc(100vh + ${props.extraHeight})` : 'calc(100vh - 50px)',
        visibility: "visible"
    }

    const startStyles = {
        zIndex: 9999,
        position: 'absolute',
        top: props.topOffset ? props.topOffset : 40,
        right: screenWidth > 550 ? '-6px' : '-100vw',
        width: screenWidth > 550 ? '355px' : '100vw',
        height: props.extraHeight ? `calc(100vh + ${props.extraHeight})` : 'calc(100vh - 50px)',
    }

    const isUserMembership = (user: any): boolean => {
        if (user && user.user && user.role) {
            return true;
        }
        return false;
    }

    const getSubscription = (profile: any) => {
        if (window.location.pathname.includes('moderation')) {
            if (profile.temp_plan) {
                //use temp_plan if exists
                setSubscription({
                    ...subscription,
                    name: profile.temp_plan.name,
                    currency: profile.temp_plan.currency,
                    interval: profile.temp_plan.interval,
                    interval_count: profile.temp_plan.interval_count,
                    amount: profile.temp_plan.price,
                    trial_period: profile.temp_plan.trial_days ? profile.temp_plan.trial_days : null,
                    next_billing_date: 'Awaiting approval',
                    status: 'Pending',
                });
            }
        } else {
            //use either the active subscription, or the previous subscription
            if (profile.active_subscription) {
                setSubscription({
                    ...subscription,
                    name: profile.active_subscription.plan.name,
                    currency: profile.active_subscription.plan.currency,
                    interval: profile.active_subscription.plan.interval,
                    interval_count: profile.active_subscription.plan.interval_count,
                    amount: profile.active_subscription.plan.price,
                    trial_period: profile.active_subscription.plan.trial_days ? profile.active_subscription.plan.trial_days : null,
                    status: profile.subscription_status,
                    next_billing_date: profile.subscription_status === "on grace" ? null : profile.active_subscription_end_date ? moment.utc(profile.active_subscription_end_date).format("MMMM Do, YYYY") : null,
                    coupon_code: profile.active_subscription.coupon_code ? profile.active_subscription.coupon_code : null,
                });
            }

            if (!profile.active_subscription && profile.previous_plan) {
                setSubscription({
                    ...subscription,
                    name: profile.previous_plan.name,
                    currency: profile.previous_plan.currency,
                    interval: profile.previous_plan.interval,
                    interval_count: profile.previous_plan.interval_count,
                    amount: profile.previous_plan.price,
                    trial_period: profile.previous_plan.trial_days ? profile.previous_plan.trial_days : null,
                    status: profile.subscription_status,
                    next_billing_date: null,
                });
            }
        }
    }

    const screeningAnsweredAtleastOnce = (): boolean => {
        let screeningHasAnAnswer: boolean = false;

        userMembership.screening_response.forEach((response: any) => {
            if (response.answer) {
                screeningHasAnAnswer = true;
            }
        });

        return screeningHasAnAnswer;
    }

    const getAnswerMarkup = (questionObject: any) => {
        if (questionObject.type === QuestionTypes.Checkbox) {
            return (
                <ul className="profile-slideout-content-screening-question-answer-list">
                    {questionObject.answer.map((answer: string) => (
                        <li>{answer}</li>
                    ))}
                </ul>
            )
        }

        if (questionObject.type === QuestionTypes.Radio) {
            return (
                <ul className="profile-slideout-content-screening-question-answer-list">
                    <li>{questionObject.answer}</li>
                </ul>
            )
        }

        return (
            <p className="profile-slideout-content-screening-question-answer-text">{`${questionObject.answer}`}</p>
        )
    }

    const toggleMemberChatState = () => {
        GroupService.getInstance()
            .toggleMemberChatState(props.group.id, userMembership.id, !canChat)
            .then(() => {
                const message = !canChat ? "Member chat enabled successfully" : "Member chat disabled successfully";
                toast(message, {
                    type: ToastType.SUCCESS,
                    autoClose: 1500
                });
                setCanChat(!canChat);
            });
    }

    const getMenuActions = (): Array<any> => {
        if (props.communityView) {
            return [
                {
                    label: canChat ? 'Disable chat' : 'Enable chat',
                    callback: () => {
                        toggleMemberChatState();
                    }
                },
            ]
        }
        return [
            {
                label: 'Cancel subscription',
                callback: () => {
                    props.groupMembersCallbacksRef.current.cancelSubscription([userMembership.indexInMembersData]);
                }
            },
            {
                label: 'Remove',
                callback: () => {
                    props.groupMembersCallbacksRef.current.removeMember([userMembership.indexInMembersData]);
                    setTimeout(() => {
                        props.setPlaySlideoutAnimation(false);
                    }, 2000);
                }
            },
            {
                label: userIsBlocked ? 'Unblock' : 'Block',
                callback: () => {
                    props.groupMembersCallbacksRef.current.changeMemberBlockState([userMembership.indexInMembersData], !userIsBlocked);
                    setTimeout(() => {
                        setUserIsBlocked(!userIsBlocked);
                    }, 2000)
                }
            },
            {
                label: canChat ? 'Disable chat' : 'Enable chat',
                callback: () => {
                    toggleMemberChatState();
                }
            },
        ]
    }

    const openMenu = () => {
        const menuTrigger: HTMLElement = document.getElementById("profile-slideout-menu-icon-trigger") as HTMLElement;

        if (menuTrigger) {
            menuTrigger.click();
        }
    }

    const formatBillingInterval = (): string => {
        if (subscription.interval === "one_time") {
            return "One time"
        }

        if (subscription.interval === "month") {
            switch (subscription.interval_count) {
                case 1:
                    return "Monthly";
                case 3:
                    return "Every 3 months";
                case 6:
                    return "Every 6 months";
                default:
                    return ""
            }
        }

        if (subscription.interval === "year") {
            return "Yearly";
        }

        return "";
    }

    const getUserPurchases = () => {
        if (props.group && miscUtils.isTeamMember(props.group.user_membership) && userMembership) {
            PricingService.getInstance()
                .getUserPurchases(userMembership.user.id, props.group.id)
                .then((response: any) => {
                    setUserPurchasesData(response && response.data);
                })
        }
    }

    const loadMorePurchases = () => {
        PricingService.getInstance()
            .loadMore(userPurchasesData.next_page_url)
            .then((response: any) => {
                setUserPurchasesData({
                    ...userPurchasesData,
                    next_page_url: response.data.next_page_url,
                    data: [...userPurchasesData.data, ...response.data.data],
                });
            });
    }

    const closeProfileSlideout = () => {
        props.setPlaySlideoutAnimation(false);
        closeSlideout();
    }

    let timeFormatter

    function getTimeofMember() {
        let timeOptions: any = {
            timeZone: userMembership && userMembership.user.timezone && userMembership.user.timezone.value,
            hour: 'numeric',
            minute: 'numeric',
        };
        const timeFormatter = new Intl.DateTimeFormat([], timeOptions).format(new Date());
        return timeFormatter;
    }
    if (userMembership && userMembership.user.timezone) {
        timeFormatter = getTimeofMember();
    }

    const {
        directChats,
        setChatroom,
        setIsCreateDirectchat,
        // setChatHistory,
        // chatHistory
    } = React.useContext(DirectChatsContext)

    const theme = {
        custom: props.group.custom_branding ? props.group.custom_branding : null
    };

    const sender = props.group && props.group.user_membership

    // function getUserChatHistory(userID: number) {
    //     MessagingService.getInstance()
    //         .fetchUserChatHistory(props.group && props.group.id, userID)
    //         .then((res: any) => {
    //             setChatHistory(res && res.data)
    //             if (res && res.data.id) {
    //                 setActiveChatroomMessage(res && res.data.id)
    //                 setOpenMessagePopUp(true)
    //             }
    //         })
    // }

    function getExistingChat() {
        if (directChats && directChats.length > 0) {

            return directChats.find((chat: { members: any[]; }) => {
                return chat.members.some((member: any) => {
                    return member.community_member.user && member.community_member.user.uuid === (userMembership.user && userMembership.user.uuid);
                });
            });
        }
    }

    const [, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);
    const [, setOpenMessagePopUp] = useAtom(showMessagePopUpAtom);

    const handleClick = () => {
        const member = { community_member: userMembership && userMembership };

        if ((userMembership && (!userMembership.can_chat)) || !userMembership) {
            // if (userMembership && !userMembership.can_chat) {
            toast("Messaging has been disabled for this member.", {
                type: ToastType.ERROR,
                autoClose: 2000,
            });
            return;
        }
        const existingChat = getExistingChat();

        if (existingChat !== null && existingChat !== undefined) {
            localStorage.setItem("isShowChatroom", "yes")
            setActiveChatroomMessage(existingChat.id)
            setOpenMessagePopUp(true)
            document.body.style.overflow = 'hidden';

            localStorage.setItem("activeChatroomId", existingChat.id);
        }
        else {
            if (props.group && props.group.messaging_allowed && sender && sender.can_chat) {

                // if (Object.keys(chatHistory).length === 0) {
                const _chatroom = {
                    description: "",
                    members: [{ community_member: sender }, member && member],
                    members_count: 2,
                    owner: sender,
                    title: "",
                    type: "individual",
                    group_id: props.group && props.group.id,
                    created_at: null,
                    member_id: member && member.community_member && member.community_member.id
                }

                const temp: any = 'temp'
                setChatroom(_chatroom);
                setIsCreateDirectchat(true);
                setActiveChatroomMessage(temp)
                setOpenMessagePopUp(true)
                localStorage.setItem("isShowChatroom", "yes")
                // }
            }
            else {
                toast("Messaging has been disabled.", {
                    type: ToastType.ERROR,
                    autoClose: 2000,
                });
                return;
            }
        }
    };

    const iconsExist = userMembership && (userMembership.user.twitter ||
        userMembership.user.instagram ||
        userMembership.user.facebook ||
        userMembership.user.linkedin ||
        userMembership.user.website)

    return (
        <>
            <Animate
                play={props.playSlideoutAnimation}
                start={startStyles}
                end={endStyles}
            >
                <div
                    className={clsx(
                        "profile-slideout-container",
                        props.classes
                    )}
                >
                    {loading && !userMembership && (
                        <AppLoader label='Just a sec...' />
                    )}

                    {!loading && userMembership && (
                        <>
                            <div className="profile-slideout-header-container">
                                <h1 className="profile-slideout-header-title">Member profile</h1>
                                <span
                                    onClick={closeProfileSlideout}
                                    className="profile-slideout-header-icon"
                                >
                                    <XIcon />
                                </span>
                            </div>

                            {userMembership && props.group && (
                                <div
                                    className="profile-slideout-content-container"
                                >
                                    <PerfectScrollbar
                                        className="profile-slideout-content-scrollarea"
                                        options={{ wheelPropagation: false }}
                                    >

                                        {shouldShowMenu && miscUtils.isTeamMember(props.group.user_membership) && (
                                            <div
                                                className="profile-slideout-menu-container"
                                                onClick={openMenu}
                                            >
                                                <span className="profile-slideout-menu-icon-container">
                                                    <MenuIcon />
                                                    <PopOnMe
                                                        actions={getMenuActions()}
                                                    >
                                                        <span
                                                            className="profile-slideout-menu-icon-trigger"
                                                            id="profile-slideout-menu-icon-trigger"
                                                        />
                                                    </PopOnMe>
                                                </span>
                                            </div>
                                        )}

                                        <div className="profile-slideout-content-info-header-container">
                                            {userMembership.user.profile_image ? (
                                                <div className="profile-slideout-content-info-header-wrapper">
                                                    <img
                                                        src={userMembership.user.profile_image}
                                                        className="profile-slideout-content-info-header-image"
                                                    />

                                                </div>
                                            ) : (
                                                <div
                                                    data-color={miscUtils.getColorInitial(userMembership.user.full_name)}
                                                    className="profile-slideout-content-info-header-noimage"
                                                >
                                                    {miscUtils.getNameInitials(userMembership.user.full_name)}
                                                </div>
                                            )}
                                            <div className="profile-slideout-content-info-header-details-container">
                                                <p className="profile-slideout-content-info-header-details-name">{userMembership.user.full_name}</p>
                                                {
                                                    userMembership.status && userMembership.status.length > 0 &&
                                                    <PrivilegeBadge privilege={userMembership.status} />
                                                }
                                                {
                                                    miscUtils.isAdmin(props.group.user_membership) &&
                                                    <span className="profile-slideout-content-info-header-details-email">
                                                        {userMembership.user.email}
                                                    </span>
                                                }
                                                {
                                                    userMembership.user.job_title && userMembership.user.job_title !== 'N/A'
                                                    &&
                                                    <p className="profile-slideout-content-info-header-details-title">
                                                        {userMembership.user.job_title}
                                                    </p>
                                                }

                                                {
                                                    (
                                                        userMembership.user.twitter ||
                                                        userMembership.user.instagram ||
                                                        userMembership.user.facebook ||
                                                        userMembership.user.linkedin ||
                                                        userMembership.user.website
                                                    ) &&

                                                    <div className="profile-slideout-content-info-social-container">
                                                        {userMembership.user.twitter && (
                                                            <span
                                                                className="profile-slideout-content-info-social-link"
                                                                onClick={() => window.open(userMembership.user.twitter, '_blank')}
                                                            >
                                                                <TwitterIcon />
                                                            </span>
                                                        )}
                                                        {userMembership.user.instagram && (
                                                            <span
                                                                className="profile-slideout-content-info-social-link"
                                                                onClick={() => window.open(userMembership.user.instagram, '_blank')}
                                                            >
                                                                <InstagramIcon />
                                                            </span>
                                                        )}
                                                        {userMembership.user.facebook && (
                                                            <span
                                                                className="profile-slideout-content-info-social-link"
                                                                onClick={() => window.open(userMembership.user.facebook, '_blank')}
                                                            >
                                                                <FacebookIcon />
                                                            </span>
                                                        )}
                                                        {userMembership.user.linkedin && (
                                                            <span
                                                                className="profile-slideout-content-info-social-link"
                                                                onClick={() => window.open(userMembership.user.linkedin, '_blank')}
                                                            >
                                                                <LinkedInIcon />
                                                            </span>
                                                        )}
                                                        {userMembership.user.website && (
                                                            <span
                                                                className="profile-slideout-content-info-social-link"
                                                                onClick={() => window.open(userMembership.user.website, '_blank')}
                                                            >
                                                                <WebsiteIcon />
                                                            </span>
                                                        )}
                                                    </div>

                                                }
                                                <div className="profile-slideout-content-info-message">
                                                    {
                                                        getCookieValue("user")['uuid'] !== userMembership.user.uuid && userMembership.approved &&
                                                        <button
                                                                className={`${iconsExist && 'top-space'} profile-message-btn`}
                                                            style={(props.group && props.group.custom_branding) ? { backgroundColor: theme.custom.primaryColor } : {}}
                                                            onClick={handleClick}
                                                        >
                                                            Message
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {userMembership.user.about_me && (
                                            <div className="profile-slideout-content-about-container">
                                                {userMembership.user.about_me.length <= ABOUT_ME_CHARACTER_LIMIT ? (
                                                    <p className="profile-slideout-content-about-text">{userMembership.user.about_me}</p>
                                                ) : (
                                                    <ReadMore
                                                        text={userMembership.user.about_me}
                                                        numberOfLines={1}
                                                        lineHeight={1}
                                                        showLessButton={true}
                                                        onContentChange={() => {
                                                        }}
                                                        readMoreCharacterLimit={ABOUT_ME_CHARACTER_LIMIT}
                                                        className="profile-slideout-content-about-text"
                                                        showMoreText='See more'
                                                    />
                                                )}
                                            </div>
                                        )}

                                        <div className="profile-slideout-content-iconscon">
                                            {userMembership.user.location && (
                                                <div className="profile-slideout-content-iconscon-icons" >
                                                    <span className="profile-slideout-content-iconscon-icons-location">
                                                        <MapPinIcon />
                                                    </span>
                                                    <span className="profile-slideout-content-iconscon-icons-value">
                                                        {userMembership.user.location}
                                                    </span>
                                                </div>
                                            )}

                                            {userMembership.user.timezone && (
                                                <div className="profile-slideout-content-iconscon-icons" >
                                                    <span className="profile-slideout-content-iconscon-icons-location">
                                                        <Clock />
                                                    </span>
                                                    <span className="profile-slideout-content-iconscon-icons-value">
                                                        {timeFormatter}
                                                        {" "}
                                                        {userMembership.user.timezone.text.split(' ')[0]}
                                                    </span>
                                                </div>
                                            )}


                                        </div>


                                        {miscUtils.isTeamMember(props.group.user_membership) && subscription && (
                                            <div
                                                className="profile-slideout-content-card-container"
                                                style={{ backgroundColor: '#f7f8f9' }}>
                                                <h3 className="profile-slideout-content-card-title">SUBSCRIPTION</h3>
                                                <p className="profile-slideout-content-subscription-title">{subscription.name}</p>
                                                <div className="profile-slideout-content-subscription-details-container profile-slideout-content-card-keyvalue-wrapper">
                                                    <div className="profile-slideout-content-card-keyvalue-container">
                                                        <p className="profile-slideout-content-card-keyvalue-key">Amount:</p>
                                                        <p className="profile-slideout-content-card-keyvalue-value">{miscUtils.getCurrencySymbol(miscUtils.getResourceCurrency(subscription.currency, props.group && props.group.currency))}{subscription.amount} {subscription.currency.toUpperCase()}</p>
                                                    </div>
                                                    {subscription.coupon_code ? (
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Coupon applied:</p>
                                                            <p className="profile-slideout-content-card-keyvalue-value">{subscription.coupon_code}</p>
                                                        </div>
                                                    ) : (<></>)}
                                                    {subscription.interval && (
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Interval:</p>
                                                            <p className="profile-slideout-content-card-keyvalue-value">{formatBillingInterval()}</p>
                                                        </div>
                                                    )}
                                                    {subscription.status && (
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Status:</p>
                                                            <p
                                                                className={`profile-slideout-content-card-keyvalue-value capitalize-first-word`}
                                                            >{subscription.status === "past_due" ? 'past due' : subscription.status}</p>
                                                        </div>
                                                    )}
                                                    {subscription.next_billing_date && (
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Next billing date:</p>
                                                            <p className="profile-slideout-content-card-keyvalue-value">{subscription.next_billing_date}</p>
                                                        </div>
                                                    )}
                                                    {subscription.trial_period && (
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Trial period:</p>
                                                            <p className="profile-slideout-content-card-keyvalue-value">{subscription.trial_period} {subscription.trial_period === 1 ? 'day' : 'days'}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {miscUtils.isTeamMember(props.group.user_membership) && userMembership.screening_response && screeningAnsweredAtleastOnce() && (
                                            <div
                                                className="profile-slideout-content-card-container"
                                                style={{ backgroundColor: '#f7f8f9' }}>
                                                <h3 className="profile-slideout-content-card-title">MEMBERSHIP QUESTIONS</h3>
                                                <div className="profile-slideout-content-screening-container">
                                                    {userMembership.screening_response.map((response: any) => {
                                                        if (!response.answer || response.answer && response.answer.length === 0) {
                                                            return null;
                                                        }

                                                        return (
                                                            <div className="profile-slideout-content-screening-question-container">
                                                                <p className="profile-slideout-content-screening-question-title">{response.question}</p>
                                                                {getAnswerMarkup(response)}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}

                                        {miscUtils.isTeamMember(props.group.user_membership) && userPurchasesData && userPurchasesData.data.length > 0 ? (
                                            <div
                                                className="profile-slideout-content-card-container"
                                                style={{ backgroundColor: '#f7f8f9' }}
                                            >
                                                <h3 className="profile-slideout-content-card-title">PURCHASE HISTORY</h3>
                                                {userPurchasesData.data.map((purchaseData: any, index: any) =>
                                                    <div
                                                        className="profile-slideout-content-purchase-container"
                                                        key={index}
                                                    >
                                                        <p className="profile-slideout-content-purchase-title">{purchaseData.purchasable.title || purchaseData.purchasable.name}</p>
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Amount:</p>
                                                            <p className="profile-slideout-content-card-keyvalue-value">{miscUtils.getCurrencySymbol(miscUtils.getResourceCurrency(purchaseData.price_option.currency, props.group && props.group.currency))}{purchaseData.price_option.amount} {purchaseData.price_option.currency.toUpperCase()}</p>
                                                        </div>
                                                        {purchaseData.coupon_code ? (
                                                            <div className="profile-slideout-content-card-keyvalue-container">
                                                                <p className="profile-slideout-content-card-keyvalue-key">Coupon applied:</p>
                                                                <p className="profile-slideout-content-card-keyvalue-value">{purchaseData.coupon_code}</p>
                                                            </div>
                                                        ) : (<></>)}
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Interval:</p>
                                                            <p className="profile-slideout-content-card-keyvalue-value">One-time</p>
                                                        </div>
                                                        <div className="profile-slideout-content-card-keyvalue-container">
                                                            <p className="profile-slideout-content-card-keyvalue-key">Date:</p>
                                                            <p className="profile-slideout-content-card-keyvalue-value">{moment.utc(purchaseData.created_at).format("MMMM Do, YYYY")}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                {userPurchasesData.next_page_url ? (
                                                    <p
                                                        className="profile-slideout-content-purchase-see-more"
                                                        onClick={loadMorePurchases}
                                                    >
                                                        See more...
                                                    </p>
                                                ) : (<></>)}
                                            </div>
                                        ) : (<></>)}


                                    </PerfectScrollbar>
                                </div>
                            )}
                        </>
                    )}

                    {!loading && !userMembership && (
                        <>
                            <div className="profile-slideout-header-container">
                                <h1 className="profile-slideout-header-title"></h1>
                                <span
                                    onClick={closeProfileSlideout}
                                    className="profile-slideout-header-icon"
                                >
                                    <XIcon />
                                </span>

                            </div>
                            <p style={{ padding: "10px" }}>Failed to load user profile data. Please try again later. </p>
                        </>

                    )}

                </div>
            </Animate>
        </>

    )


}

export default ProfileSlideout;
