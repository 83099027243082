import React from 'react';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import { ICountdownTimerBlockPreviewProps } from './ICountdownTimerBlockPreviewProps';
import styled from 'styled-components';
import { isValidHexColor } from 'components/PageBuilder/PageBuilderSideBar/BlockSettings/domain';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const BACKEND_DATE_FORMATTING: string = "YYYY-MM-DD HH:mm:00";

const DisplayDateContainer: any = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DisplayDateUnitContainer: any = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const DisplayDateText: any = styled.div<{design: any}>`
    margin: 0;
    padding: 0;
    font-weight: 700;

    font-size: ${(props: any) =>
    props.design.date_h1_font_size &&
    props.design.date_h1_font_size <= 80 ?
    `${props.design.date_h1_font_size}px` :
    `35px`
    };
    line-height: ${(props: any) =>
    props.design.date_h1_line_height &&
    props.design.date_h1_line_height <= 80 ?
        `${props.design.date_h1_line_height}px` :
        `45px`
    };
    font-family: ${(props: any) =>
    props.design.date_h1_font_family ?
        `${props.design.date_h1_font_family} !important` :
        `-apple-system, Arial !important`
    };
    color: ${(props: any) =>
    props.design.date_h1_color &&
    isValidHexColor(props.design.date_h1_color) ?
    props.design.date_h1_color :
    props.design.date_color && 
    isValidHexColor(props.design.date_color) ?
    props.design.date_color :
    '#333333'
    };
`;

const DisplayDateLabel: any = styled.p<{design: any}>`
    margin: 0;
    padding: 0;
    margin-top: 10px
    font-weight: 700;

    font-size: ${(props: any) =>
    props.design.date_label_p_font_size &&
    props.design.date_label_p_font_size <= 80 ?
    `${props.design.date_label_p_font_size}px` :
    `20px`
    };
    line-height: ${(props: any) =>
    props.design.date_label_p_line_height &&
    props.design.date_label_p_line_height <= 80 ?
        `${props.design.date_label_p_line_height}px` :
        `25px`
    };
    font-family: ${(props: any) =>
    props.design.date_label_p_font_family ?
        `${props.design.date_label_p_font_family} !important` :
        `-apple-system, Arial !important`
    };
    color: ${(props: any) =>
    props.design.date_label_p_color &&
    isValidHexColor(props.design.date_label_p_color) ?
    props.design.date_label_p_color :
    props.design.date_label_color && 
    isValidHexColor(props.design.date_label_color) ?
    props.design.date_label_color :
    '#333333'
    };
`;

const CountdownTimerBlockPreview: React.FC<ICountdownTimerBlockPreviewProps> = (props) => {

    const [displayDate, setDisplayDate] = React.useState<any>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    React.useEffect(() => {
        const timerInterval: any = setInterval(() => {
            if(isFutureDate(props.blockData.date ? props.blockData.date : dayjs.utc().format(BACKEND_DATE_FORMATTING))) {
                const nextDisplayDate = timeToFutureDate(props.blockData.date ? props.blockData.date : dayjs.utc().format(BACKEND_DATE_FORMATTING));
                setDisplayDate(() => nextDisplayDate);
            }
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [props.blockData.date]);

    const isFutureDate = (dateString: string) => {
        // Parse the date string in UTC
        const givenDate = dayjs.utc(dateString);
        
        // Get the current date and time in UTC
        const now = dayjs.utc();
      
        // Compare the given date with the current date
        return givenDate.isAfter(now);
      }

    const timeToFutureDate = (futureDateString: string) => {
        // Parse the future date string in UTC
        const futureDate = dayjs.utc(futureDateString);
        
        // Get the current date in the user's local timezone
        const now = dayjs();
      
        // Calculate the duration between the future date and now
        const diff = dayjs.duration(futureDate.diff(now));
      
        // Extract days, hours, minutes, and seconds
        const days = diff.days();
        const hours = diff.hours();
        const minutes = diff.minutes();
        const seconds = diff.seconds();
      
        return {
          days,
          hours,
          minutes,
          seconds
        };
    }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <BlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
            <DisplayDateContainer
            >
                <DisplayDateUnitContainer
                >
                    <DisplayDateText
                    design={props.blockData.design}
                    >
                        {displayDate.days}
                    </DisplayDateText>
                    <DisplayDateLabel
                    design={props.blockData.design}
                    >
                        DAYS
                    </DisplayDateLabel>
                </DisplayDateUnitContainer>

                <DisplayDateUnitContainer
                >
                    <DisplayDateText
                    design={props.blockData.design}
                    >
                        {displayDate.hours}
                    </DisplayDateText>
                    <DisplayDateLabel
                    design={props.blockData.design}
                    >
                        HOURS
                    </DisplayDateLabel>
                </DisplayDateUnitContainer>

                <DisplayDateUnitContainer
                >
                    <DisplayDateText
                    design={props.blockData.design}
                    >
                        {displayDate.minutes}
                    </DisplayDateText>
                    <DisplayDateLabel
                    design={props.blockData.design}
                    >
                        MINUTES
                    </DisplayDateLabel>
                </DisplayDateUnitContainer>

                <DisplayDateUnitContainer
                >
                    <DisplayDateText
                    design={props.blockData.design}
                    >
                        {displayDate.seconds}
                    </DisplayDateText>
                    <DisplayDateLabel
                    design={props.blockData.design}
                    >
                        SECONDS
                    </DisplayDateLabel>
                </DisplayDateUnitContainer>
            </DisplayDateContainer>
        </BlockContentContainer>
    </BlockContainer>
  )
}

export default CountdownTimerBlockPreview;