export enum PostTypes {
    TEXT = 'text',
    LINK = 'link',
    VIDEO = 'video',
    AUDIO = 'audio',
    FILE = 'attachment',
    EMBED = 'embed',
    ASSIGNMENT = 'assignment',
}

export enum PostableTypes {
    CHANNEL = 'channel',
    EVENT = 'event',
    COURSECONTENT = 'course-content',
    HOMEPAGE='homepage',
}

export enum FooterIconTypes {
    GLOBE = 'globe',
    IMAGE = 'image',
    FILM = 'film',
    VIDEO= 'video',
    HEADPHONES = 'headphones',
    FILE = 'file',
    EMBED = 'embed',
}

export enum AttachmentTypes {
    IMAGE = 'image',
    LINK = 'link',
    VIDEOLINK = 'video-link',
    AUDIO = 'audio',
    VIDEO = 'video',
    FILE = 'attachment',
    EMBED = 'embed',
}

export enum PostDataState {
    IDLE = "idle",
    WAITING = "waiting",
    READY = "ready",
}

export enum AcceptedFileFormats {
    UPLOADAUDIO = ".mp3,.m4a,.flac,.wav",
    UPLOADVIDEO = "video/mp4,video/mov,video/avi,video/x-flv,video/quicktime,video/webm,.flv"
}