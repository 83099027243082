import React from 'react';

const Course: React.FC<{ strokeColor?: string }> = ({ strokeColor }) => {
  return (
    <>
      {
        strokeColor ?
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2211 1.99773C18.4271 1.9988 18.6329 1.99986 18.8385 1.99986C19.3657 1.99984 19.8205 1.99982 20.195 2.03043C20.5904 2.06273 20.9836 2.13404 21.362 2.32684C21.9265 2.61446 22.3854 3.0734 22.673 3.63789C22.8658 4.01627 22.9371 4.40949 22.9694 4.80483C23 5.1794 23 5.63415 23 6.16129V14.8384C23 15.3655 23 15.8203 22.9694 16.1949C22.9371 16.5902 22.8658 16.9835 22.673 17.3618C22.3854 17.9263 21.9265 18.3853 21.362 18.6729C20.9836 18.8657 20.5904 18.937 20.195 18.9693C19.526 19.024 18.8486 19.0151 18.1742 19.0064C17.9239 19.0031 17.6741 18.9999 17.4252 18.9999C16.1106 18.9999 15.6617 19.01 15.269 19.1293C14.8916 19.2439 14.5405 19.4318 14.2359 19.6822C13.776 20.0602 13.4387 20.5973 13.1147 21.1133C13.021 21.2625 12.9284 21.41 12.8342 21.5513C12.7087 21.7412 12.5189 21.8876 12.2898 21.957C11.868 22.0847 11.4124 21.9212 11.168 21.5546C11.0731 21.4122 10.9798 21.2636 10.8853 21.1133C10.5613 20.5973 10.224 20.0602 9.76414 19.6822C9.45946 19.4318 9.10839 19.2439 8.73102 19.1293C8.3383 19.01 7.88938 18.9999 6.57483 18.9999C6.32594 18.9999 6.07608 19.0031 5.82581 19.0064C5.15136 19.0151 4.47398 19.024 3.80497 18.9693C3.40963 18.937 3.01641 18.8657 2.63803 18.6729C2.07354 18.3853 1.6146 17.9263 1.32698 17.3618C1.13419 16.9835 1.06287 16.5902 1.03057 16.1949C0.999969 15.8203 0.999984 15.3656 1 14.8384V6.16131C0.999984 5.63418 0.999969 5.17939 1.03057 4.80483C1.06287 4.40949 1.13419 4.01627 1.32698 3.63789C1.6146 3.0734 2.07354 2.61446 2.63803 2.32684C3.01641 2.13404 3.40963 2.06273 3.80497 2.03043C4.17955 1.99982 4.63431 1.99984 5.16145 1.99986C5.36708 1.99986 5.57293 1.9988 5.77887 1.99773C6.62267 1.99338 7.46827 1.98902 8.30863 2.05768C9.03748 2.11723 9.67766 2.24304 10.27 2.54483C10.9535 2.89312 11.5442 3.39214 12 3.99985C12.4558 3.39214 13.0465 2.89312 13.73 2.54483C14.3223 2.24304 14.9625 2.11723 15.6914 2.05768C16.5317 1.98902 17.3773 1.99338 18.2211 1.99773Z"
              fill={strokeColor} />
          </svg>
          :
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4" stroke="#767A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
      }
    </>
  )
}

export default Course