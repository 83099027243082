export enum MessagingWizardSteps {
    AUDIENCE = "AUDIENCE",
    COMPOSE = "COMPOSE",
    REVIEW = "REVIEW",
}

export enum MessagingItems {
    POST = "post",
}

export enum MessageTypes {
    POST = 1,
    EMAIL = 2,
}

export enum AudienceTypes {
    PDF = 'pdf',
    VIDEO = 'video',
    AUDIO = 'audio',
    EMBED = 'embed',
    CHANNEL = 'channel',
    COURSE = 'course',
    EVENT = 'event',
    SUBSCRIPTION = 'subscription',
    SEGMENT = 'segment',
    ARTICLE = 'article',
}

export const dummyProfiles = [
    {
        id: 1,
        full_name: "Dornubari Vizor",
        profile_image: "https://s3-us-east-2.amazonaws.com/group-app/users/NKQqVyTrtIx1qlN4Rjv4VHznWcc89DecYIo4UHBK.jpeg",
    },
    {
        id: 10,
        full_name: "Joel Funu",
        profile_image: "https://groupapp-staging.s3-us-east-2.amazonaws.com/users/ODjQp0tvfJEvden2hFwPiC3Rna2lhkTC3fTBXT9h.jpg",
    },
    {
        id: 163,
        full_name: "hesax",
        profile_image: null,
    },
    {
        id: 257,
        full_name: "Grant Johnson",
        profile_image: "https://groupapp-staging.s3-us-east-2.amazonaws.com/users/Kn0Y8cTOtahzkkUvUrhUeDzFO56CgUIPlAOncjfJ.jpg",
    },
    {
        id: 276,
        full_name: "jimmy cliffset",
        profile_image: "https://group-app.s3-us-east-2.amazonaws.com/users/YJPOqawFnmtldjjXfS1lROUogYlUujjVhTIFRLZ9.jpeg",
    },
]