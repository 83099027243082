import React from 'react';
import './HorizontalSlideScroll.scss';
import { IHorizontalSlideScrollProps } from './IHorizontalSlideScrollProps';
import { ReactComponent as ArrowButtonIcon } from './icons/arrow-button.svg';
import useSmoothScroll from 'react-smooth-scroll-hook';

const HorizontalSlideScroll: React.FC<IHorizontalSlideScrollProps> = (props) => {

  const ref = React.useRef(null);

  const { scrollTo, reachedTop, reachedBottom } = useSmoothScroll({
    ref,
    direction: 'x',
    speed: 100,
  })

  const [hasOverflow, setHasOverflow] = React.useState<boolean>(false);

  React.useEffect(() => {
    setHasOverflow(checkOverflow());
  }, [props.commentFile]);

  const isHorizontallyOverflown = (element: HTMLElement) => {
    return element.scrollWidth > element.clientWidth;
  }

  const checkOverflow = () => {
    const containerElement: HTMLElement = document.getElementById(props.slidingElementId) as HTMLElement;
    return isHorizontallyOverflown(containerElement);
  }

  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      //@ts-ignore
      return React.cloneElement(child, { scrollRef: ref });
    }
    return child;
  });

  return (
    <div className={`horizontal-slide-scroll-container`}>
      {childrenWithProps}
      {hasOverflow && !reachedTop && (
            <>
            <span 
            className={`horizontal-slide-scroll-action action-left ${window.location.pathname === "/" ? 'home-slider' : ''}`}
            onClick={() => {
              scrollTo(-500);
            }}
            >
              <ArrowButtonIcon />
            </span>
            <div className="horizontal-slide-scroll-left-fade"/>
            </>
      )}
      {hasOverflow && !reachedBottom && (
            <>
            <span 
            className={`horizontal-slide-scroll-action ${window.location.pathname === "/" ? 'home-slider' : ''}`}
            onClick={() => {
              scrollTo(500);
            }}
            >
              <ArrowButtonIcon />
            </span>
            <div className="horizontal-slide-scroll-right-fade"/>
            </>
      )}
    </div>
  )
}

export default HorizontalSlideScroll;