import React from 'react';
import { IPageBuilderFooterProps } from './IPageBuilderFooterProps';
import './PageBuilderFooter.scss';
import { ReactComponent as GroupAppIcon } from "assets/img/group-app-logo.svg";
import { POWERED_BY_GROUAPP_LINK } from "constants/common";

const PageBuilderFooter: React.FC<IPageBuilderFooterProps> = (props) => {

    const onClickFooter = () => {
        window.open(POWERED_BY_GROUAPP_LINK, '_blank');
    }
    
  return (
    <div
    className="gp-pb-page-builder-footer-container"
    >
        <div
        className="gp-pb-page-builder-footer-content-container"
        >
            <div
            className="gp-pb-page-builder-footer-wrapper"
            onClick={onClickFooter}
            >
                <p
                className="gp-pb-page-builder-footer-label"
                >
                    Powered by
                </p>
                
                <span
                className="gp-pb-page-builder-footer-icon-container"
                >
                    <GroupAppIcon
                    />
                </span>
            </div>
        </div>
    </div>
  )
}

export default PageBuilderFooter;