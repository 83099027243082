import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { IShareGroupProps } from "./IShareGroupProps";

import "./ShareGroup.scss";
import miscUtils from "../../utils/miscUtils";

const ShareGroup: React.FC<IShareGroupProps> = props => {
  const { groupData, usersGroups, theme } = props;
  const [buttonText, setButtonText] = useState<string>("Copy Link");



  return (
    <div className="invite-wrap-container">
      <h1 className="heading">Invite community members</h1>
      {usersGroups ? (
        <p className="sub-heading">
          Enter individual email addresses & send invitations to join your
          Community.
        </p>
      ) : (
        <p className="sub-heading">
          Invite members to join this Community using the link below
        </p>
      )}
      <div className="invite-link">
        <div className="invite-link-lables">
          <p className="label">Community invite link</p>
          <CopyToClipboard
            text={groupData && groupData.link}
            onCopy={() => {
              setButtonText("Copied");
              setTimeout(() => setButtonText("Copy Link"), 1000);
            }}
          >
            <button
              style={miscUtils.getTextButtonStyle(theme)}
              className="copy-link-btn" type={"button"}
            >
              {buttonText}
            </button>
          </CopyToClipboard>
        </div>
        <input
          type="text"
          defaultValue={groupData && groupData.link}
          placeholder={groupData && groupData.link}
          className="invite-input"
        />
      </div>
    </div>
  );
};

export default ShareGroup;
