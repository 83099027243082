export enum AccessLevels {
  Open = "open",
  Private = "private",
  Paid = "paid",
  Plan = "plan",
  Segment = "segment",
  Priced = "priced",
}

export enum PostingPermissions {
  Admin = "admin",
  Members = "members"
}

export enum ChannelLayouts {
  Feed = "feed",
  List = "list",
  Card = "card",
}

export enum ChannelVisibility {
  COMMUNITY = "community",
  PUBLIC = "public",
  RESTRICTED = "restricted",
}
