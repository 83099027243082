import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { IRoute } from "./IRoute";
import { Map } from "immutable";

export class PublicRoute extends Component<IRoute, any> {
  public render() {
    const { authed, user, path, component, disableRedirect } = this.props;
    return (
      <Route
        path={path}
        render={() => {
          if (authed && !disableRedirect) {
            if (
              user &&
              !user.verified &&
              user.shouldVerify &&
              path !== "/verify-account" &&
              path !== "/signup"
            ) {
              return <Redirect to="/verify-account?s=false" />;
            }
          }

          if (authed && path === "/login") {
            return <Redirect to="/" />;
          }

          // if (!authed && path === "/posts/:id") {
          //   return <Redirect to="/" />;
          // }

          if (!authed && path === "/plan-subscription") {
            const query = new URLSearchParams(window.location.search);
            const plan_utm = query.get("utm_content");

            if (plan_utm) {
              return <Redirect to={`/invite?utm_content=${plan_utm}`} />;
            }
          }

          return (() => component)();
        }}
      />
    );
  }
}

const mapStateToProps = (state: Map<string, any>, nextProps: IRoute) => {
  const user: any = state.getIn(["authorize", "user"]);

  return {
    user,
    authed: user !== undefined && user !== null
  };
};

export default connect(mapStateToProps)(PublicRoute as any);
