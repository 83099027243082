import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class PricingService {
  private dbInstance: any = databaseInstance;

  private static instance: PricingService;

  constructor() {
    PricingService.instance = this;
  }

  public static getInstance() {
    if (!PricingService.instance) {
      PricingService.instance = new PricingService();
    }
    return PricingService.instance;
  }

  public createResourcePrice: (
    groupId: number,
    resourceId: number,
    priceData: any,
  ) => Promise<void> = (groupId, resourceId, priceData) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/resources/${resourceId}/prices`, priceData)
        .then((response: any) => resolve(response))
        .catch(() => reject());
    });
  };

  public createResourcePriceOption: (
    groupId: number,
    priceId: number,
    priceOptionData: any,
  ) => Promise<void> = (groupId, priceId, priceOptionData) => {
    return new Promise((resolve, reject) => {
        this.dbInstance
        .post(`/groups/${groupId}/prices/${priceId}/options`, priceOptionData)
        .then((response: any) => resolve(response))
        .catch(() => reject());
    })
  }

  public purchaseResource: (
    groupId: number,
    resourceId: number,
    purchaseData: any,
  ) => Promise<void> = (groupId, resourceId, purchaseData) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/resources/${resourceId}/purchases`, purchaseData)
      .then((response: any) => resolve(response))
      .catch((error: any) => reject(error));
    })
  }

  public validateResourceCoupon: (groupId: number, couponCode: string, priceOptionId: number) => Promise<any> = (groupId, couponCode, priceOptionId) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance.post(`/groups/${groupId}/coupons/validate`, { code: couponCode, price_option_id: priceOptionId })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public updatePriceOption: (
    groupId: number,
    optionId: number,
    optionData: any,
  ) => Promise<void> = (groupId, optionId, optionData) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/price-options/${optionId}`, optionData)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public getPriceOptionPurchasers: (
    groupId: number,
    optionId: number,
    query?: string,
  ) => Promise<void> = (groupId, optionId, query="") => {
    return new Promise((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/price-options/${optionId}/purchasers${query}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public loadMore: (url: string) => Promise<void> = url => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(url).then((response: any) => resolve(response))
      .catch(() => reject());
    });
  };

  public getUserPurchases: (
    userId: number,
    groupId: number,
  ) => Promise<void> = (userId, groupId) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
      .get(`/users/${userId}/groups/${groupId}/purchases`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public exportPriceOptionPurchasers: (
    groupId: number,
    optionId: number,
  ) => Promise<void> = (groupId, optionId) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/price-options/${optionId}/purchasers/export`)
      .then(() => resolve())
      .catch(() => reject());
    })
  }

  public savePriceBenefits: (
    groupId: number,
    optionId: number,
    benefits: any
  ) => Promise<void> = (groupId, optionId, benefits) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/price-options/${optionId}/bulk-benefits`, {
        benefits,
      })
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }
}
