import React from 'react';
import Modal from '../Modal';
import "./AppModal.scss";
import { ReactComponent as Money } from '../../../components/group/groupInformation/money.svg';
import { CloseIcon } from '../../../constants/icons';
import {IAppModalProps} from './IAppModalProps';

const AppModal: React.FC<IAppModalProps> = (props) => {
    return (
        <Modal isOpen={props.isOpen} onRequestClose={props.onClose} size={"456px"}>
        <div className="upload-limit-popup">
          <button type="button" className="close-modal" onClick={props.onClose}>
            <CloseIcon />
          </button>
          <div className="">
            <Money fill='#365DE6'/>
            <div className="message-content">
              <p className="heading">{props.title}</p>
              <p className="sub-heading">
                {props.message}
              </p>
              <button onClick={() => {
                if(props.callback) {
                  props.callback();
                  return;
                }
                props.onClose();
              }}
              className="app-modal-button"
              >
                {props.buttonText}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
}

export default AppModal
