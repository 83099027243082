import { PageBlockTypes, WidgetType } from "containers/PageBuilder/SinglePageContainer/domain";

export const isValidHexColor = (color: string) => {
    // Hex color code regex pattern (e.g., #ffffff or #fff)
    const hexPattern = /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
    return hexPattern.test(color);
}

export const getPageBlockLabel = (pageBlock: any) => {
    if(pageBlock.name) {
        return pageBlock.name;
    }

    switch(pageBlock.type) {
        case PageBlockTypes.Banner:
            return 'Banner';
        case PageBlockTypes.Heading:
            return 'Heading';
        case PageBlockTypes.Subtext:
            return 'Text';
        case PageBlockTypes.Text:
            return 'Heading + Text';
        case PageBlockTypes.Image:
            return 'Image';
        case PageBlockTypes.ImageText:
            return 'Image + Text';
        case PageBlockTypes.ImageTextColumn:
            return 'Image + Text Column';
        case PageBlockTypes.ImageGridText:
            return 'Grid + Text';
        case PageBlockTypes.Video:
            return 'Video';
        case PageBlockTypes.VideoText:
            return 'Video + Text';
        case PageBlockTypes.Button:
            return 'Button';
        case PageBlockTypes.Pricing:
            return 'Pricing';
        case PageBlockTypes.Event:
            return 'Event';
        case PageBlockTypes.CourseCurriculum:
            return 'Course Curriculum';
        case PageBlockTypes.FAQ:
            return 'FAQ'
        case PageBlockTypes.CountdownTimer:
            return 'Countdown Timer'
        default:
            return '';
    }
}

const getWidgeType = (widget: any) => {
    switch(widget.type) {
        case WidgetType.Form:
            return 'Form';
        case WidgetType.Scheduler:
            return 'Scheduler App';
        default:
            return '';
    }
}

export const getWidgetLabel = (widget: any, widgetIndex: number) => {
    return widget.name ? widget.name : `Widget ${widgetIndex + 1} (${getWidgeType(widget)})`;
}

export const getFirstHtmlTag = (htmlString: string) => {
    // if(!htmlString) {
    //     return 'p';
    // }

    // Regular expression to match the first HTML tag
    const tagMatch = htmlString.match(/<(\w+)[^>]*>/);

    // If a match is found, return the tag name if it's h1, h2, h3, or p, otherwise return 'p'
    if (tagMatch) {
        const tagName = tagMatch[1].toLowerCase(); // Ensure case insensitivity
        if (['h1', 'h2', 'h3', 'p'].includes(tagName)) {
            return tagName;
        }
    }

    // Default return value
    return 'p';
}