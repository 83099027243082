import { ICourseService } from "../../../core/services/course";
import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class LibraryService implements ICourseService {
  private dbInstance: any = databaseInstance;

  private static instance: LibraryService;

  constructor() {
    LibraryService.instance = this;
  }

  public static getInstance() {
    if (!LibraryService.instance) {
      LibraryService.instance = new LibraryService();
    }
    return LibraryService.instance;
  }

  public getContents: (groupId: number,
    type: string,
    page: number,
    query: string,
    published?: boolean,
    has_category?: boolean,
  ) => Promise<void> = (groupId, type, page, query = '', published, has_category) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/library-contents?${type.length > 0 ? `type=${type}` : ''}&page=${page}&search=${query}${published !== undefined ? `&published=${published}` : ''}${has_category !== undefined ? `&has_category=${has_category}` : ""}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch(() => reject())
        .catch((error: any) => reject(error));
    });
  };

  public getLibraryContents: (
    groupId: number,
    query?: string,
  ) => Promise<void> = (groupId, query = '') => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/library-contents${query}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => reject(error));
    })
  }

  public getLibraryContentById: (
    groupId: number,
    contentId: number,
  ) => Promise<void> = (groupId, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/library-contents/${contentId}`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => reject(error));
    })
  }

  public createLibraryContent: (
    groupId: number,
    contentData: any,
  ) => Promise<void> = (groupId, contentData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/library-contents`, contentData)
        .then((response: any) => resolve(response))
        .catch(() => reject());
    })
  }

  public publishLibraryContent: (
    contentType: string,
    contentId: number,
  ) => Promise<void> = (contentType, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/${contentType}/${contentId}/publish`)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    })
  }

  public unpublishLibraryContent: (
    contentType: string,
    contentId: number,
  ) => Promise<void> = (contentType, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/${contentType}/${contentId}/publish`)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    })
  }

  public deleteLibraryContent: (
    groupId: number,
    contentType: string,
    contentId: number,
  ) => Promise<void> = (groupId, contentType, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/${contentType}/${contentId}`)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    })
  }

  public editLibraryContent: (
    groupId: number,
    contentType: string,
    contentId: number,
    contentData: any,
  ) => Promise<void> = (groupId, contentType, contentId, contentData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/${contentType}/${contentId}`, contentData)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    })
  }

  public editArticleLibraryContent: (
    groupId: number,
    contentType: string,
    contentId: number,
    contentData: any,
  ) => Promise<void> = (groupId, contentType, contentId, contentData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/${contentType}/${contentId}`, contentData)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    })
  }

  public editLibraryContentThumbNail: (
    contentType: string,
    contentId: number,
    formData: any,
  ) => Promise<void> = (contentType, contentId, formData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/${contentType}/${contentId}/thumbnail-image`, formData)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    })
  }

  public getLibraryContent: (
    groupId: number,
    contentType: any,
    contentId: any,
    query?: string,
  ) => Promise<void> = (groupId, contentType, contentId, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/${contentType}/${contentId}${query}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    })
  }

  public getLibraryItemAuthorizedUsers: (
    libraryItemType: string,
    libraryItemId: number,
    page?: number,
    query?: string
  ) => Promise<void> = (libraryItemType, libraryItemId, page = 1, query = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/${libraryItemType}/${libraryItemId}/authorized-users?page=${page}${query.length > 0 ? `&search=${query}` : ""}`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public addLibraryItemAuthorizedUser: (
    libraryItemType: string,
    libraryItemId: number,
    userId: number
  ) => Promise<void> = (libraryItemType, libraryItemId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/${libraryItemType}/${libraryItemId}/authorized-users`, { user_id: userId })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public removeLibraryItemAuthorizedUser: (
    libraryItemType: string,
    libraryItemId: number,
    userId: number
  ) => Promise<void> = (libraryItemType, libraryItemId, userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/${libraryItemType}/${libraryItemId}/authorized-users`,
          {
            data: {
              user_id: userId
            }
          })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public getLibraryItemNonAuthorizedUsers: (
    groupId: number,
    libraryItemType: string,
    libraryItemId: string,
    query?: string,
    limit?: number,
    filter?: string
  ) => Promise<void> = (groupId, libraryItemType, libraryItemId, query = "", limit = 50, filter: string = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/library-contents/${libraryItemId}/non-authorized-users?content_type=${libraryItemType}&search=${encodeURIComponent(
            query
          )}&limit=${limit}${filter.length > 0 ? `&${filter}` : ""}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public loadMoreLibraryItemNonAuthorizedUsers: (
    groupId: number,
    libraryItemType: string,
    libraryItemId: string,
    pageNumber: number,
  ) => Promise<void> = (groupId, libraryItemType, libraryItemId, pageNumber) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/library-contents/${libraryItemId}/non-authorized-users?content_type=${libraryItemType}&page=${pageNumber}`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public downloadLibraryItemAuthorizedUsers: (
    groupId: number,
    contentType: string,
    contentId: number,
  ) => Promise<void> = (groupId, contentType, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/library-contents/${contentId}/authorized-users/export`, {
          content_type: contentType,
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public shareContentPost: (
    contentId: number,
    postObject: any
  ) => Promise<void> = (contentId, postObject) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/contents/${contentId}/posts`, postObject)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public getContentPost: (contentType: string, contentId: number) => Promise<void> = (contentType, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/${contentType}/${contentId}/posts?sort=published_at:desc&include=comments`
        )
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public getPinPostCourse: (contentType: string, contentId: number) => Promise<void> = (contentType, contentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`${contentType}/${contentId}/posts/pinned?include=comments`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public pinPostCourse: (contentType: string, contentId: number, postId: number) => Promise<void> = (
    contentType,
    contentId,
    postId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`${contentType}/${contentId}/posts/${postId}/pinned`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public unpinPostCourse: (
    contentType: string,
    contentId: number,
    postId: number
  ) => Promise<void> = (contentType, contentId, postId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`${contentType}/${contentId}/posts/${postId}/pinned`)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    });
  };

  public loadMoreContent: (url: string) => Promise<void> = (url) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => reject(error));
    })
  }

  public accessLibraryItem: (
    groupId: number,
    contentId: number,
    metaData: any,
  ) => Promise<void> = (groupId, contentId, metaData) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/library-contents/${contentId}/accesses`, metaData)
        .then(() => resolve())
        .catch((error: any) => reject(error))
    })
  }

  public getLibraryItemViewers: (
    groupId: number,
    contentId: number,
    contentType: string,
    query?: string,
    pageNumber?: number
  ) => Promise<void> = (groupId, contentId, contentType, query = "", pageNumber) => {

    let url = `/groups/${groupId}/library-contents/${contentId}/accesses?type=${contentType}${query}`;

    if (pageNumber !== undefined) {
      url += `&page=${pageNumber}`;
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(url)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  public exportLibraryItemViewers: (
    groupId: number,
    contentId: number,
    contentType: string,
  ) => Promise<void> = (groupId, contentId, contentType) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/library-contents/${contentId}/accesses/export`, {
          content_type: contentType,
        })
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    })
  }

  public enableEnforceLessonCompletion: (
    courseId: number,
  ) => Promise<void> = (courseId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/courses/${courseId}/content-completion`)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    })
  }

  public disableEnforceLessonCompletion: (
    courseId: number,
  ) => Promise<void> = (courseId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/courses/${courseId}/content-completion`)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    })
  }

  public updateContentPositions: (
    groupId: number,
    payload: any,
  ) => Promise<void> = (groupId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/library-contents/positions`, payload)
        .then((response: any) => resolve(response))
        .catch((error: Error) => reject(error))
    })
  }

  public grantUserLibraryAccess: (
    groupId: number,
    contentId: number,
    contentObj: any
  ) => Promise<void> = (groupId, contentId, contentObj) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/library-contents/${contentId}/accesses-by-admin`, contentObj)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public deleteUserLibraryAccess: (
    groupId: number,
    contentId: number,
    contentObj: any
  ) => Promise<void> = (groupId, contentId, contentObj) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .delete(`/groups/${groupId}/library-contents/${contentId}/accesses-by-admin`,
          {
            data: contentObj
          }
        )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public createArticleContent: (
    groupId: number,
    articleId: number,
    articleObj: any
  ) => Promise<void> = (groupId, articleId, articleObj) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/articles/${articleId}/content`, articleObj)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public removeUserFromLib: (
    groupId: number,
    contentId: number,
    contentObj: any
  ) => Promise<void> = (groupId, contentId, contentObj) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .delete(`/groups/${groupId}/library-contents/${contentId}/accesses-by-admin`,
          {
            data: contentObj
          }
        )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public expireUserAccess: (
    groupId: number,
    accessesId: number,
  ) => Promise<void> = (groupId, accessesId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/accesses/${accessesId}/expire`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public activateUserAccess: (
    groupId: number,
    accessesId: number,
  ) => Promise<void> = (groupId, accessesId) => {
    return new Promise<void>((resolve, reject) => {
      return this.dbInstance
        .post(`/groups/${groupId}/accesses/${accessesId}/activate`)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  public getSpecificContent: (
    groupId: number,
    type: string,
    query: string,
  ) => Promise<void> = (groupId, type, query) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/library-contents?type=${type}&search=${query}`)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  };
}
