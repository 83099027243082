import React from 'react'

import Select from 'react-select';
import styles from './timezone-select.module.scss'

const TimezoneSelect = ({
    timezones,
    customdata,
    showField,
    onChange,
    userData
}: {
    timezones: any
    customdata: any
    showField: any
    onChange: any
    userData: any
}) => {
    const timezoneOptions = timezones.map((timezone: any) => ({
        value: timezone.id,
        label: timezone.text
    }));

    const defaultTimezone = userData && userData.timezone ? {
        value: userData.timezone.id,
        label: userData.timezone.text
    } : null;

    const customStyles = {
        option: (provided: any) => ({
            ...provided,
            textAlign: 'left'
        }),
        singleValue: (provided: any) => ({
            ...provided,
            textAlign: 'left'
        }),
        placeholder: (provided: any) => ({
            ...provided,
            textAlign: 'left'
        })
    };

    return (
        customdata &&
        customdata.filter((category: any) => category.name === 'Timezone')
            .map((data: any) =>
                showField('timezone_id') && (
                    <div className={styles.profileFieldsSection} key={data.id}>
                        <label htmlFor={data.name}>
                            {data.name} {" "}
                            {data.required && <span style={{ color: 'red' }}>*</span>}
                        </label>
                        <div className={styles.customSelect}>
                            <Select
                                options={timezoneOptions}
                                defaultValue={defaultTimezone}
                                onChange={(selectedOption) => onChange({
                                    target: {
                                        name: 'timezone_id',
                                        value: selectedOption ? selectedOption.value : null
                                    }
                                })}
                                name="timezone_id"
                                aria-label={data.name}
                                required={data.required}
                                id='timezone_id'
                                isClearable
                                styles={customStyles}
                                placeholder="Search or select a timezone"
                            />
                        </div>
                    </div>
                )
            )
    );
};

export default TimezoneSelect