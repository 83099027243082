import styled from "styled-components";

const BlockImage: any = styled.img<{design: any}>`
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: ${(props: any) => 
        props.design.image_border_radius &&
        props.design.image_border_radius <= 200 ?
        `${props.design.image_border_radius}px` :
        `0px`
    };
`;

export default BlockImage;