import { IBillingService } from "../../../core/services/billing";
import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class BillingService implements IBillingService {
  private dbInstance: any = databaseInstance;

  private static instance: BillingService;

  constructor() {
    BillingService.instance = this;
  }

  public static getInstance() {
    if (!BillingService.instance) {
      BillingService.instance = new BillingService();
    }
    return BillingService.instance;
  }

  public getPlans: () => Promise<void> = () => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get("/subscription-plans").then((response: any) => {
        resolve(response);
      });
    });
  };

  public updateSubscription: (groupId: any, planId: any, couponCode: string | null) => Promise<void> = (
    groupId,
    planId,
    couponCode,
  ) => {
    return new Promise<void>((resolve, reject) => {
      let payload: any = {
        plan_id: planId,
      }

      if(couponCode) {
        payload = {
          ...payload,
          coupon_code: couponCode,
        }
      }
      this.dbInstance
        .put(`/groups/${groupId}/subscriptions`, payload)
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });
    });
  };

  public cancelSubscription: (groupId: any, planId: any, payload?: any) => Promise<void> = (
    groupId,
    planId,
    payload
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/subscriptions/${planId}`, { data: payload})
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public stopSubscriptionCancellation: (groupId: any, subscriptionId: any) => Promise<void> = (
    groupId,
    subscriptionId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/subscriptions/${subscriptionId}/resume`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public planSubscription: (
    groupId: any,
    planId: any,
    paymentId: any
  ) => Promise<void> = (groupId, planId, paymentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/subscriptions`, {
          plan_id: planId,
          payment_method_id: paymentId
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getInvoices: (groupId: any) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/invoices`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updatePaymentMethod: (
    groupId: any,
    paymentId: any
  ) => Promise<void> = (groupId, paymentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/payment-method`, {
          payment_method_id: paymentId
        })
        .then((response: any) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });
    });
  };

  public validateCouponCode: (
    code: string,
    planId: any,
    groupId?: number,
  ) => Promise<void> = (code, planId, groupId=-1) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/coupons/validate`, {
          code,
          group_id: groupId,
          plan_id: planId
        })
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    })
  }

  public getAppSumoChangeTierCallback: (
    groupId: number,
  ) => Promise<void> = (groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/appsumo-change-tier`)
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    })
  }
}
