import React from 'react'

import './user-custom-fields.scss'
import { QuestionTypes } from 'containers/Onboarding/MembershipQuestions/QuestionMaker/domain';
import OnboardingService from 'api/services/onboarding';
import QuestionsTypesFields from 'containers/CommunityOnboardingSteps/CommunityMembershipQuestions/QuestionsTypesFields/QuestionsTypesFields';
import { toast, ToastType } from 'react-toastify';
import { UserProfileContext } from '../UserProfile';
import ReadMore from 'utils/readMore/readMore';

const UserCustomFields = (
    {
        groupData,
        membershipQuestions,
        responseState,
        setResponseState
    }:
        {
            groupData: any,
            membershipQuestions: any
            responseState: any
            setResponseState: any
        }
) => {
    const { showCustomEditMode, setShowCustomEditMode } = React.useContext(UserProfileContext)

    const [isMakingRequest, setIsMakingRequest] = React.useState<boolean>(false);

    React.useEffect(() => {
        // Find the element by id
        const completeQuestionsButton = document.getElementById('complete-questions-button');

        // Check if the element exists before updating
        if (completeQuestionsButton) {
            // Update the visibility based on showCustomEditMode
            completeQuestionsButton.style.display = showCustomEditMode ? 'none' : 'flex';
        }
    }, [showCustomEditMode]);

    const validateResponse = () => {
        let isResponseValid = true;

        //@ts-ignore
        membershipQuestions.forEach((membershipQuestion: any, index: number) => {
            if (membershipQuestion.required && membershipQuestion.type !== QuestionTypes.Checkbox && responseState[index].answer.trim() === "") {
                isResponseValid = false;

            }

            if (membershipQuestion.required && membershipQuestion.type === QuestionTypes.Checkbox && responseState[index].answer.length === 0) {
                isResponseValid = false;

            }
        });

        if (!isResponseValid) {
            toast("Please answer all the questions that are marked required", {
                type: ToastType.ERROR,
                autoClose: 3000,
            });
        }

        return isResponseValid;
    }

    const saveResponse = () => {
        if (validateResponse()) {
            setIsMakingRequest(true);
            OnboardingService.getInstance()
                .respondToMembershipQuestions(groupData.id, groupData.user_membership.id, {
                    responses: responseState,
                })
                .then(() => {
                    setIsMakingRequest(false);
                    toast("Your answers have been saved", {
                        type: ToastType.SUCCESS,
                        autoClose: 3000,
                    });
                    setShowCustomEditMode(true)
                })
                .catch(() => setIsMakingRequest(false))
        }
    }
    const existingMemberAnswer = groupData && groupData.user_membership && groupData.user_membership.screening_response

    const discardChanges = () => {
        if (membershipQuestions) {
            setResponseState(membershipQuestions.map((membershipQuestion: any) => {
                const matchingScreeningQuestion = existingMemberAnswer && existingMemberAnswer.find(
                    (screenQuestion: any) => (screenQuestion.type === membershipQuestion.type) &&
                        (screenQuestion.question === membershipQuestion.name)
                );

                return {
                    question: membershipQuestion.name,
                    type: membershipQuestion.type,
                    answer: matchingScreeningQuestion ? matchingScreeningQuestion.answer :
                        (membershipQuestion.type === QuestionTypes.Checkbox ? [] : ""),
                };
            }));
            setShowCustomEditMode(true)
        }
    }


    return (
        <div className='user-custom-fields-wrapper'>
            {
                showCustomEditMode ?
                    <div className='user-custom-fields-wrapper-question-section'>
                        {responseState && responseState.sort((a: any, b: any) => a.position - b.position).map((newResponse: any) => (
                            <div key={newResponse.question}>
                                <span>{newResponse.question}</span>
                                {newResponse.answer ?
                                    <div>
                                        <h4 style={{ maxWidth: '330px' }}                                        >
                                            {
                                                typeof newResponse.answer === 'object' ?
                                                    <>
                                                        {newResponse.answer.join(', ')}
                                                    </>
                                                    :
                                                    <>
                                                        {newResponse.answer.length > 100 ?
                                                            <ReadMore
                                                                text={newResponse.answer}
                                                                readMoreCharacterLimit={100}
                                                                numberOfLines={1}
                                                                lineHeight={1}
                                                                showLessButton
                                                                onContentChange={() => { }}
                                                                showMoreText="Read answer"
                                                                showLessText="Hide answer"
                                                            />
                                                            :
                                                            newResponse.answer
                                                        }
                                                    </>
                                            }
                                        </h4>

                                    </div>
                                    :
                                    <h4>-</h4>
                                }
                            </div>
                        ))}
                    </div>
                    :
                    <>
                        <QuestionsTypesFields
                            membershipQuestions={membershipQuestions as Array<any>}
                            responseState={responseState}
                            setResponseState={setResponseState}
                            isMakingRequest={isMakingRequest}
                            onComplete={saveResponse}
                            group={groupData}
                        />
                        {
                            !showCustomEditMode &&
                            <div className='user-custom-field-discard-btn'>
                                <button onClick={() => discardChanges()}>Discard</button>
                            </div>
                        }

                    </>
            }

        </div>
    )
}

export default UserCustomFields