import React from 'react';
import { IImageButtonProps } from './IImageButtonProps';
import './ImageButton.scss';

import { ReactComponent as InlineImageIcon } from "assets/img/inline-image.svg";

const ImageButton: React.FC<IImageButtonProps> = (props: any) => {
  return (
    <div
      className="post-draft-image-button-container"
      onClick={props.onClick}
    >
      <span className='inline-image-icon'>
        <InlineImageIcon />
      </span>
    </div>
  )
}

export default ImageButton;