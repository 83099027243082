import React, { useEffect, useState } from "react";
import { ITopBarNotificationComponentProps } from "./ITopBarNotificationComponentProps";
import NotificationComponent from "../../NotificationComponent";
import "./TopBarNotificationComponent.scss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-regular-svg-icons";
import useComponentVisible from "../../../utils/visible/visible";
import { NotificationService } from "../../../api/services/notification";
import { Map } from "immutable";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import ScrollArea from "react-scrollbar";
import { Link } from "react-router-dom";
import { TopBarPopUpsAtom, groupMenuMessagesShowAtom } from "showMessageMenuAtom";
import { useAtom } from "jotai";
import miscUtils from "utils/miscUtils";
import { ReactComponent as NotificationIcon } from "assets/img/notificationIcon.svg";

const TopBarNotificationComponent: React.FC<ITopBarNotificationComponentProps> = props => {
  const { uid, subdomain, theme, group} = props;
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
    setDisableHide,
  } = useComponentVisible(false);
  const [notifications, setNotifications] = useState<any>([]);
  const [counter, setCounter] = useState<any>(0);
  const [notificationCount, setNotificationCount] = useState<any>(0);

  useEffect(() => {
    if(props.refreshNotificationsCountRef) {
      props.refreshNotificationsCountRef.current = (countDiff: any) => {
        if(countDiff === 'all') {
          setNotificationCount(0);
        } else {
          setNotificationCount(notificationCount + countDiff)
        }
      }
    }
  });

  useEffect(() => {
    if(counter === 1 || counter === 2){
      if(group){
        userGroupNotifications();
        userGroupUnreadNotificationCount();
      }
    }

    if(subdomain === null || subdomain === 'my' || subdomain === 'staging'){
      userNotification();
      userUnreadNotificationCount();
    }

    setCounter(counter+1);
  }, [subdomain, group]);

  function userUnreadNotificationCount()
  {
    NotificationService.getInstance()
      .getUserUnreadNotificationsCount(uid)
      .then((res: any) => {
        if (res) {
          setNotificationCount(res.data);
        }
      });
  }

  function userGroupUnreadNotificationCount()
  {
    NotificationService.getInstance()
      .getGroupUnreadNotificationsCount(uid, group.id)
      .then((res: any) => {
        if (res) {
          setNotificationCount(res.data);
        }
      });
  }

  function userNotification() {
    NotificationService.getInstance()
      .getNotifications("", uid, 1)
      .then((res: any) => {
        if (res) {
          setNotifications(res.data.data);
        }
      });
  }

  function userGroupNotifications() {
    NotificationService.getInstance()
      .getGroupNotifications("", uid, group.id,1, )
      .then((res: any) => {
        if (res) {
          setNotifications(res.data.data);
        }
      });
  }

  function unreadNotificationCount() {
    let notificationValue = "";
    if (notificationCount > 9) notificationValue = "9+";
    else if (notificationCount) notificationValue = `${notificationCount}`;
    return notificationValue;
  }

  const updateNotification = (newNotification: any) => {
    setNotifications(notifications.map((notification: any) => {
      if(notification.id === newNotification.id) {
        return newNotification;
      }
      return notification;
    }))
  }

  const chatsIconsHidden = group && group.menu['messages']['show']
  const [groupMenuMessagesShowMenu,] = useAtom(groupMenuMessagesShowAtom);
  const chathide = groupMenuMessagesShowMenu === undefined ? !chatsIconsHidden : !groupMenuMessagesShowMenu

  const isGroupMember = miscUtils.isGroupMember(group && group)
  const [topBarPopUps, setTopBarPopUps] = useAtom(TopBarPopUpsAtom);

  return (
    <div
      className={`header-notifications ${chathide || !isGroupMember && 'no-chats-icon'}  dropdown-menu ${props.lectureView ? 'header-notifications-lecture' : ''}
      ${props.lectureView ? 'header-notifications-lecture-ipad' : ''}`}
      ref={ref}
    >
      <button
        className="header-notifications__top-bar--button"
        type="button"
        onClick={() => {
          setIsComponentVisible(true);
          setTopBarPopUps({
            message: false,
            notification: true
          })
        }}
      >
        {unreadNotificationCount() && (
          <span className="notifications-count">
            {unreadNotificationCount()}
          </span>
        )}
        <span
          className="top-bar-notifications-icon"
          style={{
            color: theme && theme.custom ? theme.custom.fontColor : '#666'
          }}>
          <NotificationIcon />
        </span>
      </button>
      <div className="res-notifications-link">
        <Link to={{ pathname: "/notifications" }}>
          <span
            className="res-notifications-link--span"
            style={{
              color: theme && theme.custom ? theme.custom.fontColor : '#666'
            }}>
            <NotificationIcon />
          </span>
          {unreadNotificationCount() && (
            <span className="notifications-count">
            {unreadNotificationCount()}
          </span>
          )}
        </Link>
      </div>
      {isComponentVisible && topBarPopUps.notification && (
        <div className="header-notifications-wrap dropdown flex flex-direction-column">
          <ScrollArea
            speed={0.8}
            className="notification-js-area"
            contentClassName="sidebar-js-content"
          >
            <div className="header-notifications-box">
              {notifications && notifications.length ? (
                notifications.map((notification: any, index: number) => (
                  <NotificationComponent
                    group={group}
                    subdomain={subdomain}
                    notification={notification}
                    index={index}
                    key={index}
                    topBarView
                    refreshNotificationsCountRef={props.refreshNotificationsCountRef}
                    setDisableHidePopUp={setDisableHide}
                    updateNotification={updateNotification}
                    closeNotifications={() => setIsComponentVisible(false)}
                  />
                ))
              ) : (
                <div className="header-notifications-no-items">
                  No notifications to show
                </div>
              )}
            </div>
          </ScrollArea>
          {notifications && notifications.length && (
            <div className="header-notifications-footer flex flex-align-center flex-justify-center">
              <Link
                style={theme && theme.custom && theme.custom.primaryColor ? {color : theme.custom.primaryColor} : {} }
                onClick={() => setIsComponentVisible(false)}
                to={{ pathname: "/notifications" }}
              >
                View more notifications
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (
  state: Map<string, any>,
  ownProps: ITopBarNotificationComponentProps
) => {
  const uid: string = state.getIn(["authorize", "uid"]) as string;
  const user: any = state.getIn(["authorize", "user"]);

  return {
    uid,
    user
  };
};

export default withRouter(
  connect(mapStateToProps, {})(TopBarNotificationComponent as any) as any
) as any;
