import React, { useEffect, useState } from "react";
import { ISidebarForChannelProps } from "./ISidebarForChannelProps";

import "./SidebarForChannelComponent.scss";
import "./sidebarforchannel-responsive.scss";
import miscUtils from "../../../utils/miscUtils";
import { push } from "connected-react-router";
import { globalActions } from "../../../store/actions";
import { Map } from "immutable";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { GroupService } from "../../../api/services/groups";
import { ChannelService } from "../../../api/services/channels";
import { SearchIcon, CloseIcon } from "../../../constants/icons";
// import ContentLoader from "react-content-loader";
import Collapse from "rc-collapse";
import "rc-collapse/assets/index.css";
import Modal from "elements/modal/Modal";
import ShareGroup from "components/shareGroup";
import { Link } from "react-router-dom";
import { Emoji } from "emoji-mart";
import { AccessLevels } from "containers/channels/channelDetails/domain";
import { EventsService } from "api/services/events";
// import InviteMembersIcon from '../newIcons/AddMembers';
import LibraryIcon from '../newIcons/Library';
import CalenderIcon from '../newIcons/Calender';
import HomeIcon from '../newIcons/Home';
import FeedIcon from '../newIcons/Feed';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { getChannelCollections, getUnAssignedChannels } from "../domain";
import { ReactComponent as PlusCircleIcon } from '../icons/plus-circle.svg';
import ChannelHashTag from "../newIcons/ChannelHashTag";
import { setlocalStorageValue } from "utils/localStorage";

const SidebarForChannelComponent: React.FC<ISidebarForChannelProps> = (
  props: any
) => {
  const {
    global: { currentView, theme },
    subdomain,
  } = props;

  const [groupChannels, setGroupChannels] = useState<any>([]);
  const [selectedChannel, selectChannel] = useState<number>(-1);
  const [selectedGroup, selectGroup] = useState<any>(-1);
  const [postsSearchQuery, setPostsSearchQuery] = useState<string>("");
  const [chosenChannel, setChosenChannel] = useState<any>();
  // const [visibleOpen, setVisibleOpen] = useState<number>(0);
  // const [visiblePrivate, setVisiblePrivate] = useState<number>(0);
  // const [visiblePaid, setVisiblePaid] = useState<number>(0);
  // const [visiblePlan, setVisiblePlan] = useState<number>(0);
  const [isModalOpen, setToggleModal] = useState<boolean>(false);
  const [showEvents, setShowEvents] = React.useState<any>(false);
  // const [openChannelsActive, setOpenChannelsActive] = useState<boolean>(true);
  // const [inviteChannelsActive, setInviteChannelsActive] = useState<boolean>(true);
  // const [paidChannelsActive, setPaidChannelsActive] = useState<boolean>(true);
  const [channelCollections, setChannelCollections] = useState<Array<any>>([]);
  const [unAssignedChannels, setUnAssignedChannels] = useState<Array<any>>([]);
  const [unAssignedChannelsActive, setUnAssignedChannelsActive] = useState<boolean>(true);
  const [shouldShowHomePage, setShouldShowHomePage] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.group &&
      props.group.home_page_config &&
      props.group.home_page_config.status
    ) {
      setShouldShowHomePage(true);
    }
  }, [props.group]);

  useEffect(() => {
    if (groupChannels.length > 0) {
      if (channelCollections.length === 0) {
        setChannelCollections(getChannelCollections(groupChannels));
      }
      if (unAssignedChannels.length === 0) {
        setUnAssignedChannels(getUnAssignedChannels(groupChannels));
      }
    }
  }, [groupChannels]);

  useEffect(() => {
    if (props.group) {
      setGroupChannels(props.group.channels);
      getEvents();
    }
  }, [props.group]);

  useEffect(() => {
    if (subdomain) {
      const groupData: any = miscUtils.getGroupSlugChannel(subdomain);

      switchToGroup(subdomain || groupData.slug, groupData.channel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdomain]);

  useEffect(() => {
    if (window.innerWidth < 992) {
      setlocalStorageValue("sidebar", false);
    }

    if (props.location.pathname.split("/").includes("posts")) {
      selectChannel(props.currentChannel);
    }
  }, [props.location.pathname, props.currentChannel]);

  useEffect(() => {
    if (groupChannels) {
      checkNumberOfChannels("open");
      checkNumberOfChannels("private");
      checkNumberOfChannels("paid");
      checkNumberOfChannels(AccessLevels.Plan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChannels]);

  function checkNumberOfChannels(query: any) {
    // let count = 0;
    // if (groupChannels) {
    //   groupChannels.map((channel: any) => {
    //     if (channel.status === query && channel.status !== "archived") {
    //       count += 1;
    //     }
    //     return null;
    //   });
    // }
    // if (query === "open") {
    //   setVisibleOpen(count);
    // }
    // if (query === "private") {
    //   setVisiblePrivate(count);
    // }
    // if (query === "paid") {
    //   setVisiblePaid(count);
    // }
    // if (query === AccessLevels.Plan) {
    //   setVisiblePlan(count)
    // }
  }

  function openModal() {
    setToggleModal(true);
  }

  function closeModal() {
    setToggleModal(false);
  }

  function viewChannel(channelId: number) {
    setChosenChannel(channelId);
    selectChannel(channelId);
    props.goToChannel(selectedGroup, channelId, props.isSubdomain);
  }

  const determineChosenChannel = (): string | null => {
    const urlLastPart: string = window.location.pathname.split("/").pop() as string;
    if (urlLastPart) {
      return urlLastPart;
    }

    return null;
  }

  function switchToGroup(groupSlug: string, channelId?: number) {
    GroupService.getInstance()
      .getGroup(subdomain || groupSlug)
      .then((groupData: any) => {
        if (groupData && groupData.id) {
          ChannelService.getInstance()
            .getGroupChannels(groupData.id, "")
            .then((channels: any) => {
              setGroupChannels(channels.data);
              selectGroup(subdomain || groupData.slug);
              selectChannel(
                chosenChannel ? chosenChannel : determineChosenChannel()
              );
            });
        }
      });
  }

  // const ChannelContentLoader = () => {
  //   return (
  //     <ContentLoader
  //       height={220}
  //       width={200}
  //       speed={1}
  //       primaryColor="#dbdbdb"
  //       secondaryColor="#ecebeb"
  //     >
  //       <rect x="0" y="0" rx="3" ry="3" width="200" height="20" />
  //       <rect x="0" y="35" rx="3" ry="3" width="200" height="34" />
  //       <rect x="0" y="100" rx="3" ry="3" width="70" height="16" />
  //       <rect x="10" y="130" rx="3" ry="3" width="120" height="16" />
  //       <rect x="10" y="160" rx="3" ry="3" width="120" height="16" />
  //       <rect x="10" y="190" rx="3" ry="3" width="120" height="16" />
  //     </ContentLoader>
  //   );
  // };

  function changeGroupInviteLink() {
    if (props.group) {
      GroupService.getInstance()
        .changeGroupInviteLink(props.group.id)
        .then(() => { });
    }
  }

  function setChannelToActive(selectedChannel: any, channel: any) {
    return selectedChannel === channel.slug &&
      !window.location.pathname.split("/").includes("library") &&
      !window.location.pathname.split("/").includes("members") &&
      !window.location.pathname.split("/").includes("courses")
      ? "channel-link active"
      : "channel-link";
  }

  function getHomeLinkStyle() {
    if (theme && theme.custom && theme.custom.primaryColor && window.location.pathname === "/") {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  function getFeedLinkStyle() {
    if (theme && theme.custom && theme.custom.primaryColor && window.location.pathname === "/feed") {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  function getLibraryLinkStyles(url: string) {
    if (theme && theme.custom && theme.custom.primaryColor && window.location.pathname.split("/").includes(url)) {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  function getEventsLinkStyle() {
    if (theme && theme.custom && theme.custom.primaryColor && window.location.pathname.split("/").includes("events")) {
      const rgbCommunityColor: any = miscUtils.hexToRgb(theme.custom.primaryColor);

      return {
        color: theme.custom.fontColor,
        backgroundColor: rgbCommunityColor ? `rgba(${rgbCommunityColor.r}, ${rgbCommunityColor.g}, ${rgbCommunityColor.b}, .1)` : '',
      }
    }
    return {}
  }

  const getEvents = () => {
    const user_time = miscUtils.getDateInCurrentTimezone(new Date()).format("YYYY-MM-DD HH:mm:ss");
    let query = `&limit=30&published=true&user_time=${user_time}`;

    if (props.group) {
      EventsService.getInstance()
        .getEvents(props.group && props.group.id, query)
        .then((response: any) => {

          if (response) {
            if (Object.keys(response.data.data).length > 0) {
              setShowEvents(true);
            }
          }
        });
    }
  };

  const getDisplayedUnreadPosts = (count: number) => {
    if (count <= 9) {
      return `${count}`
    }

    return '9+'
  }

  const readChannelPosts = (channelId: any) => {
    ChannelService.getInstance()
      .readChannelPosts(channelId)
      .then(() => { })
      .catch(() => { });
  }

  const clearChannelPostCount = (channelId: any, channelIndex: any, collectionIndex?: any) => {
    if (collectionIndex || collectionIndex === 0) {
      if (channelCollections[collectionIndex].channels[channelIndex].unread_post_count > 0) {
        const channelCollectionsCopy: any = JSON.parse(JSON.stringify(channelCollections));

        channelCollectionsCopy[collectionIndex].channels[channelIndex] = {
          ...channelCollectionsCopy[collectionIndex].channels[channelIndex],
          unread_post_count: 0,
        }

        setChannelCollections([...channelCollectionsCopy]);

        readChannelPosts(channelId);
      }
    } else {
      if (unAssignedChannels[channelIndex] && unAssignedChannels[channelIndex].unread_post_count > 0) {
        const unAssignedChannelsCopy: any = JSON.parse(JSON.stringify(unAssignedChannels));

        unAssignedChannelsCopy[channelIndex] = {
          ...unAssignedChannelsCopy[channelIndex],
          unread_post_count: 0,
        }

        setUnAssignedChannels([...unAssignedChannelsCopy]);

        readChannelPosts(channelId);
      }
    }
  }

  return (
    <>
      {document.location.pathname !== "/login" && document.location.pathname !== "/signin" && document.location.pathname.split("/").pop() !== 'purchase' && (
        <div className={`sidebar-channel-only__wrap sub-nav`}>
          <div className="sidebar__content-channels-list">
            <PerfectScrollbar
              className="sidebar-logout-scroll-area"
              options={{ wheelPropagation: false }}
            >
              <>
                <div className="sidebar-logout-header">
                  {props.group && props.group.status === "open" ? (
                    <div className="group-name">
                      {currentView || (props.group && props.group.name)}
                    </div>
                  ) : (
                    <div className="group-name-closed">
                      {currentView || (props.group && props.group.name)}
                    </div>
                  )}
                  {props.group && props.group.status === "open" && (
                    <div className="sidebar-channel__search-wrap">
                      <input
                        placeholder={"Search"}
                        value={postsSearchQuery}
                        onChange={e => setPostsSearchQuery(e.target.value)}
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            props.setPostsQuery(postsSearchQuery);
                          }
                        }}
                        className="channel-search"
                      />
                      <span>
                        <SearchIcon />
                      </span>
                    </div>
                  )}
                </div>
                <div className="joined-channels">
                  <div className="sidebar-actions">
                    {
                      shouldShowHomePage && (
                        <Link
                          style={getHomeLinkStyle()}
                          onClick={() => selectChannel(-1)}
                          className={`action ${window.location.pathname === "/" && "active-courses"}`}
                          to={
                            subdomain
                              ? `/`
                              : `/group/${props.group && props.group.slug}/`
                          }
                        >
                          <HomeIcon
                          strokeColor={theme && theme.custom && window.location.pathname === "/" && theme.custom.primaryColor}
                          />
                          Home
                          {/* ({group.published_library_contents_count}) */}
                        </Link>)}
                        {props.group && props.group.menu['feed']['show'] && (
                        <Link
                          style={getFeedLinkStyle()}
                          onClick={() => selectChannel(-1)}
                          className={`action ${window.location.pathname === "/feed" && "active-courses"}`}
                          to={
                            subdomain
                              ? `/feed`
                              : `/group/${props.group && props.group.slug}/feed`
                          }
                        >
                          <FeedIcon 
                          strokeColor={theme && theme.custom && window.location.pathname === "/feed" && theme.custom.primaryColor}
                          />
                          Feed
                          {/* ({group.published_library_contents_count}) */}
                        </Link>)}

                    {props.group && props.group.published_courses_count > 0 && props.group.menu['courses']['show'] ? (
                      <Link
                        style={getLibraryLinkStyles("courses")}
                        onClick={() => selectChannel(-1)}
                        className={`action ${window.location.pathname.includes(
                          "/courses"
                        ) && "active-courses"}`}
                        to="/courses"
                      >
                        <LibraryIcon
                          strokeColor={theme && theme.custom && window.location.pathname.split("/").includes("courses") && theme.custom.primaryColor}
                        />
                        Courses
                      </Link>
                    ) : (<></>)}
                    {props.group && props.group.published_library_contents_count > 0 && props.group.menu['library']['show'] ? (
                      <Link
                        style={getLibraryLinkStyles("library")}
                        onClick={() => selectChannel(-1)}
                        className={`action ${window.location.pathname.split("/").includes(
                          "library"
                        ) && "active-courses"}`}
                        to="/library"
                      >
                        <LibraryIcon
                          strokeColor={theme && theme.custom && window.location.pathname.includes("/library") && theme.custom.primaryColor}
                        />
                        Library
                      </Link>
                    ) : (<></>)}
                    {props.group && showEvents && props.group.published_events_count && props.group.published_events_count > 0 && props.group.menu['events']['show'] ? (
                      <Link
                        style={getEventsLinkStyle()}
                        className={`action ${window.location.pathname.split("/").includes("events") && "active-courses"}`}
                        to={
                          "/events"
                        }
                      >
                        <CalenderIcon
                          strokeColor={theme && theme.custom && window.location.pathname.split("/").includes("events") && theme.custom.primaryColor}
                        />
                        Events
                      </Link>
                    ) : <></>}
                    {props.group && props.group.menu['invite']['show'] && (
                      <span
                        onClick={() => openModal()}
                        className="invite-members-action"
                      >
                        <span><PlusCircleIcon /></span>
                        Invite members
                      </span>
                    )}
                  </div>
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    size={"540px"}
                  >
                    <div className="invite--group__header-card">
                      <button
                        type="button"
                        className="close-modal"
                        onClick={closeModal}
                      >
                        <CloseIcon />
                      </button>
                      <ShareGroup
                        theme={theme}
                        usersGroups={false}
                        groupData={props.group}
                        generateNewLink={true}
                        changeLink={changeGroupInviteLink}
                        closeModal={closeModal}
                      />
                    </div>
                  </Modal>
                  <div className="public-joined-channels">
                    {
                      channelCollections.map((collection: any, collectionIndex: any) => (
                        <Collapse
                          accordion={null}
                          className="header-parent"
                          defaultActiveKey={collection.open ? "0" : null}
                          onChange={(key: Array<any>) => setChannelCollections(channelCollections.map((channelCollection: any) => {
                            if (channelCollection.id === collection.id) {
                              return {
                                ...channelCollection,
                                open: key.length > 0,
                              }
                            }

                            return channelCollection;
                          }))}
                          expandIcon={() => <FontAwesomeIcon icon={collection.open ? faChevronDown : faChevronUp} />}
                        >
                          <Collapse.Panel
                            header={collection.name.toUpperCase()}
                            className="header-item"
                          >
                            <ul>
                              {collection.channels.map((channel: any, channelIndex: any) => (
                                <Link
                                  to={`/channel/${channel.slug}`}
                                  onClick={() => {
                                    clearChannelPostCount(channel.id, channelIndex);
                                    viewChannel(channel.slug);
                                  }}
                                >
                                  <li
                                    key={channelIndex}
                                    onClick={() => {
                                      clearChannelPostCount(channel.id, channelIndex, collectionIndex);
                                      viewChannel(channel.slug);
                                    }
                                    }
                                    style={miscUtils.getChannelLinksColor(channel, selectedChannel, theme)}
                                    className={setChannelToActive(
                                      selectedChannel,
                                      channel
                                    )}
                                  >
                                    <div 
                                    title={channel.name} 
                                    className="gp-sidebar-channel-container"
                                    >
                                      <div
                                      className="gp-channel-emoji-container"
                                      >
                                        {
                                          channel.emoji ?
                                          <span
                                            className="sidebar-emoji"
                                            dangerouslySetInnerHTML={{
                                              //@ts-ignore
                                              __html: Emoji({
                                                html: true,
                                                set: "apple",
                                                emoji: channel.emoji,
                                                size: 18
                                              })
                                            }}
                                          /> : 
                                          <ChannelHashTag
                                          fill={
                                            theme &&
                                            theme.custom &&
                                            selectedChannel === channel.slug ?
                                            theme.custom.primaryColor : 
                                            channel.unread_post_count ? 
                                            '#000' :
                                            undefined
                                          }
                                          />
                                        }
                                      </div>
                                      <p
                                        className={`gp-sidebar-channel-name ${channel.unread_post_count ? 'gp-sidebar-channel-name--badge' : ''}`}
                                      >
                                        {channel.name}
                                      </p>
                                    </div>
                                    {channel.unread_post_count ? (
                                      <div
                                        className={`items-count-container--channel`}
                                        style={{
                                          backgroundColor: (theme && theme.custom) ? theme.custom.primaryColor : "",
                                        }}
                                      >
                                        {getDisplayedUnreadPosts(channel.unread_post_count)}
                                      </div>
                                    ) : (<></>)}
                                  </li>
                                </Link>
                              ))}
                            </ul>
                          </Collapse.Panel>
                        </Collapse>
                      ))
                    }
                    {
                      unAssignedChannels.length > 0 &&
                      <Collapse
                        accordion={null}
                        className="header-parent"
                        defaultActiveKey="0"
                          onChange={(key: Array<any>) => setUnAssignedChannelsActive(key.length > 0)}
                          expandIcon={() => <FontAwesomeIcon icon={unAssignedChannelsActive ? faChevronDown : faChevronUp} />}
                        >
                          <Collapse.Panel
                            header="CHANNELS"
                            className="header-item"
                          >
                            <ul>
                              {unAssignedChannels.map((channel: any, channelIndex: any) => (
                                <Link
                                  to={`/channel/${channel.slug}`}
                                  onClick={() => {
                                    clearChannelPostCount(channel.id, channelIndex);
                                    viewChannel(channel.slug);
                                  }}
                                >
                                  <li
                                  key={channelIndex}
                                  onClick={() => {
                                    clearChannelPostCount(channel.id, channelIndex)
                                    viewChannel(channel.slug);
                                  }
                                  }
                                  style={miscUtils.getChannelLinksColor(channel, selectedChannel, theme)}
                                  className={setChannelToActive(
                                    selectedChannel,
                                    channel
                                  )}
                                >
                                  <div 
                                  title={channel.name} 
                                  className="gp-sidebar-channel-container"
                                  >
                                     <div
                                      className="gp-channel-emoji-container"
                                      >
                                        {
                                          channel.emoji ?
                                          <span
                                            className="sidebar-emoji"
                                            dangerouslySetInnerHTML={{
                                              //@ts-ignore
                                              __html: Emoji({
                                                html: true,
                                                set: "apple",
                                                emoji: channel.emoji,
                                                size: 18
                                              })
                                            }}
                                          /> : 
                                          <ChannelHashTag
                                          fill={
                                            theme &&
                                            theme.custom &&
                                            selectedChannel === channel.slug ?
                                            theme.custom.primaryColor : 
                                            channel.unread_post_count ? 
                                            '#000' :
                                            undefined
                                          }
                                          />
                                        }
                                      </div>
                                    <p
                                        className={`gp-sidebar-channel-name ${channel.unread_post_count ? 'gp-sidebar-channel-name--badge' : ''}`}
                                    >
                                      {channel.name}
                                    </p>
                                  </div>
                                  {channel.unread_post_count ? (
                                      <div
                                        className={`items-count-container--channel`}
                                        style={{
                                          backgroundColor: (theme && theme.custom) ? theme.custom.primaryColor : "",
                                        }}
                                    >
                                      {getDisplayedUnreadPosts(channel.unread_post_count)}
                                    </div>
                                  ) : (<></>)}
                                </li>
                                </Link>
                              ))}
                          </ul>
                        </Collapse.Panel>
                      </Collapse>
                    }
                  </div>
                </div>

              </>
              {/* )} */}
            </PerfectScrollbar>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any, ownProps: ISidebarForChannelProps) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const groups = Map(state.get("group", {})).toJS() as any;
  const uid: string = state.getIn(["authorize", "uid"]) as string;
  return {
    uid,
    global,
    groups
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ISidebarForChannelProps
) => {
  return {
    goToChannel: (
      groupSlug: string,
      channelId: number,
      isSubdomain: boolean
    ) => {
      dispatch(
        push(
          isSubdomain
            ? `/channel/${channelId}`
            : `/channel/${groupSlug}/${channelId}`
        )
      );
    },
    setPostsQuery: (query: string) =>
      dispatch(globalActions.setPostsQuery(query))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SidebarForChannelComponent as any) as any
) as any;
