import React from "react";
import { INewDirectMessageProps } from "./INewDirectMessageProps";
import "./new-direct-message.scss"
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SearchForNewMembers from "../NewGroupMessage/SearchForMembers"
import { toast, ToastType } from "react-toastify";
import { useAtom } from "jotai";
import { showActiveCHatAtom } from "showMessageMenuAtom";

const NewDirectMessage: React.FC<INewDirectMessageProps> = props => {
  const
    {
      group,
      setChatroom, directChats, setIsCreateDirectchat,
      setIsLoadingMessages
    } = props;

  const [, setActiveChatroomMessage] = useAtom(showActiveCHatAtom);

  function createDirectChat(member: any) {
    member = { community_member: member };
    if (member.community_member && !member.community_member.can_chat) {
      toast("Messaging has been disabled for this member.", {
        type: ToastType.ERROR,
        autoClose: 2000,
      });
      return;
    }
    const existingChat = getExistingChat(member);
    if (existingChat) {
      setIsLoadingMessages(true);
      setActiveChatroomMessage(existingChat.id)
    } else {
      const _chatroom = {
        description: "",
        members: [{ community_member: group.user_membership }, member],
        members_count: 2,
        owner: group.user_membership,
        title: "",
        type: "individual",
        group_id: group.id,
        created_at: null,
        member_id: member.community_member.id
      }
      const temp: any = 'temp'
      setChatroom(_chatroom);
      setIsCreateDirectchat(true);
      setActiveChatroomMessage(temp)
    }
  }

  function getExistingChat(_member: any) {
    if (directChats && directChats.length > 0) {

      return directChats.find((chat: any) => {
        if (chat.type === "individual") {
          return chat.members.some((member: any) => {
            return member.community_member.user && member.community_member.user.uuid === (_member && _member.community_member.user.uuid);
          });
        }
        return false
      });
    }
  }

  return (
    <div className="messages-content">
      <div className="messages-content-header new-direct-message">
        <b style={{ fontSize: '14px' }}>New direct message</b>
        <SearchForNewMembers
          group={group}
          isGroup={false}
          createDirectChat={createDirectChat}
        />
      </div>
    </div>
  );
};

export default withRouter(
  connect(null, null)(NewDirectMessage as any) as any
) as any;
