import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import './completed-quiz.scss'
import { CourseService } from 'api/services/course'
import { Link } from 'react-router-dom'
import CustomUserComponent from 'components/CustomUserComponent/CustomUserComponent'
import { UserService } from 'api/services/users'
import { QuestionType, QuizAnswerVerdict, QuizVerdict } from './domain'
import { ReactComponent as PassIcon } from './icons/pass.svg';
import { ReactComponent as FailIcon } from './icons/fail.svg';
import { ReactComponent as PercentIcon } from './icons/percent.svg';
import { ReactComponent as RetakeIcon } from './icons/retake.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as CorrectIcon } from './icons/correct.svg';
import { ReactComponent as WrongIcon } from './icons/wrong.svg';
import miscUtils from 'utils/miscUtils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ContentLoader from 'react-content-loader'
import BackIcon from './icons/BackIcon';

const TITLE_CHARACTER_LIMIT: number = 50;

dayjs.extend(utc);

const CompletedQuiz = ({ group, userId, history }: { group: any, userId: any, history: any }) => {
    const { enrollmentId, quizId, submissionId } = useParams<any>();
    const [userDetails, setUserDetails] = useState<any>();
    const [submissionData, setSubmissionData] = useState<any>();
    const [content, setContent] = useState<any>();

    useEffect(() => {
        if(
            group &&
            (
                !miscUtils.isTeamMember(group.user_membership) &&
                userId != enrollmentId
            )
        ) {
          history.push("/");
        }

    }, [group, userId]);

    const fetchMemberDetails = () => {
        UserService.getInstance()
            .getUserMembership(group && group.id, Number(enrollmentId))
            .then((res: any) => { if (res) setUserDetails(res) })
    }

    const getUserQuizResult = () => {
        CourseService.getInstance()
            .getUserQuizResults(
                group.id,
                enrollmentId,
                quizId,
                Number(submissionId)
            )
            .then((response: any) => {
                setSubmissionData(response.data)
                setContent(response.data.content);
            })
    }

    useEffect(() => {
        fetchMemberDetails()
    }, [enrollmentId, group])

    useEffect(() => {
        if (group) {
            getUserQuizResult();
        }
    }, [group, enrollmentId, quizId])

    const showQuestionWarning = (questionData: any) => {
        let allCorectAreSubmitted: boolean = true;
        let atLeastOneCorrectWasSubmitted: boolean = false;

        questionData.options.forEach((qOption: any) => {
            if (
                qOption.is_correct &&
                !qOption.submitted
            ) {
                allCorectAreSubmitted = false;
            }

            if (
                qOption.is_correct &&
                qOption.submitted
            ) {
                atLeastOneCorrectWasSubmitted = true;
            }
        });

        return (
            questionData.type === QuestionType.Checkbox &&
            !allCorectAreSubmitted &&
            atLeastOneCorrectWasSubmitted
        );
    }

    const showAnswerVerdict = (qOption: any) => {
        return (
            qOption.is_correct ||
            (
                qOption.submitted &&
                'is_correct' in qOption
            )
        )
    }

    const getAnswerVerdict = (qOption: any) => {
        if (qOption.is_correct) {
            return QuizAnswerVerdict.Correct;
        } else {
            return QuizAnswerVerdict.Wrong;
        }
    }

    const getRetakesRemaining = () => {
        if (
            content &&
            content.settings &&
            content.settings.max_retakes
        ) {
            let retakesRemaining = Number(content.settings.max_retakes - submissionData.retakes);

            if (retakesRemaining < 0 || isNaN(retakesRemaining)) {
                return 0
            }

            return retakesRemaining
        }

        return 0;
    }

    const getContentTitle = () => {
        return (
            submissionData.content.title.length > TITLE_CHARACTER_LIMIT ?
                `${submissionData.content.title.slice(0, TITLE_CHARACTER_LIMIT)}...` :
                submissionData.content.title
        )
    }

    return (
        <div className='quiz-container'>
            <div className="quiz-container-header">
                <Link
                    className="left"
                    to={`/courses/${submissionData && submissionData.content.course.slug}/lectures/${quizId}`}
                >
                    <div className="icon">
                        <BackIcon
                            fill={
                                miscUtils.customThemeSet(group ? { custom: group.custom_branding } : {}) ?
                                    group.custom_branding.primaryColor :
                                    undefined
                            }
                        />
                    </div>
                    <h4
                        style={miscUtils.customThemeSet(group ? { custom: group.custom_branding } : {}) ? {
                            color: group.custom_branding.primaryColor,
                        } : {}}
                    >Back to lesson</h4>
                </Link>

                {submissionData ? (
                    <div className="right">
                        {
                            submissionData.content && submissionData.content.title ?
                                <h1>{getContentTitle()}</h1>
                                :
                                <h1>Title</h1>
                        }
                    </div>
                ) : (<></>)}
            </div>

            <>
                {!submissionData ?
                    <div className='head-loader'>
                        <ContentLoader
                            speed={2}
                            width={699}
                            height={50}
                            viewBox={`0 0 ${699} 50`}
                        >
                            <circle cx="25" cy="24" r="25" />
                            <rect x="53" y="10" rx="3" ry="3" width="83" height="14" />
                            <rect x="53" y="27" rx="3" ry="3" width="52" height="14" />

                            <rect x="620" y="20" rx="3" ry="3" width="72" height="14" />
                        </ContentLoader>

                        <div className='head-loader-results'>
                            <ContentLoader
                                speed={2}
                                width={699}
                                height={50}
                                viewBox={`0 0 ${699} 50`}
                            >
                                <rect x="4" y="20" rx="3" ry="3" width="83" height="14" />
                                <rect x="123" y="20" rx="3" ry="3" width="352" height="14" />
                                <rect x="620" y="20" rx="3" ry="3" width="72" height="14" />
                            </ContentLoader>
                        </div>

                        {Array(window.innerWidth <= 550 ? 7 : 5).fill('').map((_e, i) => (
                            <div className='head-loader-body'>
                                <ContentLoader
                                    speed={2}
                                    width={699}
                                    height={150}
                                    viewBox={`0 0 ${699} 150`}
                                >
                                    <rect x="4" y="20" rx="3" ry="3" width="452" height="14" />
                                    <rect x="4" y="40" rx="3" ry="3" width="252" height="14" />

                                    <circle cx="20" cy="86" r="10" />
                                    <rect x="40" y="80" rx="3" ry="3" width="600" height="14" />

                                    <circle cx="20" cy="116" r="10" />
                                    <rect x="40" y="110" rx="3" ry="3" width="300" height="14" />
                                </ContentLoader>
                            </div>
                        ))}

                    </div>
                    :
                    <div className="quiz-body">
                        <div className='top'>
                            <div className="user-section">
                                {
                                    userDetails &&
                                    <CustomUserComponent user={userDetails && userDetails} group={group && group} />
                                }
                            </div>
                            {userDetails && submissionData ? (
                                <div className="submitted">
                                    {/* quiz submitted date */}
                                    Submitted on {dayjs.utc(submissionData.updated_at).local().format(`MMM, DD, YYYY, h:mm A`)}
                                </div>
                            ) : (<></>)
                            }
                        </div>

                        {submissionData ? (
                            <div
                                className="quiz-results-admin-container"
                            >
                                <div
                                    className={`quiz-results-summary-container ${submissionData && submissionData.status === QuizVerdict.Pass ? QuizVerdict.Pass : QuizVerdict.Fail}`}
                                >
                                    <div
                                        className="quiz-results-summary-left-container"
                                    >
                                        <div
                                            className="quiz-results-summary-left-status-container"
                                        >
                                            <span
                                                className="quiz-results-summary-left-status-icon"
                                            >
                                                {submissionData && submissionData.status === QuizVerdict.Pass ? (
                                                    <PassIcon
                                                    />
                                                ) : (
                                                    <FailIcon
                                                    />
                                                )}
                                            </span>

                                            <p
                                                className="quiz-results-summary-left-status-text"
                                            >
                                                {submissionData && submissionData.status === QuizVerdict.Pass ? 'Passed' : 'Failed'}
                                            </p>
                                        </div>

                                        <div
                                            className="quiz-results-summary-left-target-container"
                                        >
                                            <span
                                                className="quiz-results-summary-left-target-icon"
                                            >
                                                <PercentIcon
                                                />
                                            </span>

                                            <p
                                                className="quiz-results-summary-left-target-score"
                                            >
                                                Quiz score:
                                                <span
                                                    className="quiz-results-summary-left-target-score highlight"
                                                >
                                                    {submissionData.percentage}%
                                                </span>
                                                <span
                                                    className="quiz-results-summary-left-target-score subtext"
                                                >
                                                    (To pass: min. {submissionData.min_pass_percentage}%)
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className="quiz-results-summary-right-container"
                                    >
                                        <div
                                            className="quiz-results-summary-right-retakes-container"
                                        >
                                            <span
                                                className="quiz-results-summary-right-retakes-icon"
                                            >
                                                <RetakeIcon
                                                />
                                            </span>

                                            <p
                                                className="quiz-results-summary-right-retakes-text"
                                            >
                                                Retakes remaining:
                                                <span
                                                    className="quiz-results-summary-right-retakes-text highlight"
                                                >
                                                    {getRetakesRemaining()}
                                                </span>
                                                {/* <span
                                        className="quiz-results-summary-right-retakes-text subtext"
                                        >
                                            (Max retakes: 3)
                                        </span> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="quiz-results-answers-container"
                                >
                                    {submissionData.submission.map((questionData: any, index: number) => (
                                        <div
                                            className={`quiz-results-answers-question-container ${index ? 'margin-top' : ''}`}
                                            key={`${questionData.id}`}
                                        >
                                            <div
                                                className="quiz-results-answers-question-title-container"
                                            >
                                                <p
                                                    className="quiz-results-answers-question-title-text"
                                                >
                                                    {`${index + 1}. ${questionData.question}`}
                                                </p>

                                                {'is_correct' in questionData.options[0] ? (
                                                    <p
                                                        className="quiz-results-answers-question-title-count"
                                                    >
                                                        {questionData.scored_point}/{questionData.max_point} point
                                                    </p>
                                                ) : <></>}
                                            </div>

                                            {questionData.description ? (
                                                <p
                                                    className="quiz-results-answers-question-description"
                                                >
                                                    {questionData.description}
                                                </p>
                                            ) : (<></>)}

                                            <div
                                                className="quiz-results-answers-question-options-container"
                                            >
                                                {questionData.options.map((qOption: any, qOptionIndex: number) => (
                                                    <div
                                                        className={`quiz-results-answers-question-options-option-container ${qOption.submitted ? 'highlighted' : ''}`}
                                                        style={miscUtils.customThemeSet({ custom: group.custom_branding }) &&
                                                            group.custom_branding.primaryColor !== '#ffffff' &&
                                                            qOption.submitted ? {
                                                            border: `1px solid ${group.custom_branding.primaryColor}`,
                                                        } : {}}
                                                    >
                                                        <div
                                                            className={`quiz-results-answers-question-options-option-left-container ${showAnswerVerdict(qOption) ? 'max-width' : ''}`}
                                                        >
                                                            <input
                                                                className="quiz-results-answers-question-options-option-left-input"
                                                                type={questionData.type}
                                                                checked={qOption.submitted}
                                                            />

                                                            <p
                                                                className="quiz-results-answers-question-options-option-left-text"
                                                            >
                                                                {qOption.option}
                                                            </p>
                                                        </div>

                                                        {showAnswerVerdict(qOption) ? (
                                                            <div
                                                                className={`quiz-results-answers-question-options-option-right-container ${getAnswerVerdict(qOption)}`}
                                                            >
                                                                <span
                                                                    className="quiz-results-answers-question-options-option-right-icon"
                                                                >
                                                                    {getAnswerVerdict(qOption) === QuizAnswerVerdict.Correct ? (
                                                                        <CorrectIcon
                                                                        />
                                                                    ) : (
                                                                        <WrongIcon
                                                                        />
                                                                    )}
                                                                </span>

                                                                <p
                                                                    className="quiz-results-answers-question-options-option-right-text"
                                                                >
                                                                    {getAnswerVerdict(qOption) === QuizAnswerVerdict.Correct ? 'Correct' : 'Incorrect'}
                                                                </p>
                                                            </div>
                                                        ) : (<></>)}
                                                    </div>
                                                ))}
                                            </div>

                                            {showQuestionWarning(questionData) ? (
                                                <div
                                                    className="quiz-results-question-warning-container"
                                                >
                                                    <span
                                                        className="quiz-results-question-warning-icon"
                                                    >
                                                        <WarningIcon
                                                        />
                                                    </span>

                                                    <p
                                                        className="quiz-results-question-warning-text"
                                                    >
                                                        Not all correct answers were selected.
                                                    </p>
                                                </div>
                                            ) : <></>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (<></>)}
                    </div>
                }

            </>
        </div>
    )
}

export default CompletedQuiz