import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import styles from './PageBuilderSchedulerPreview.module.scss';
import { ReactComponent as XIcon } from 'containers/channels/collections/deleteConfirmation/icons/x.svg';
import anchorme from 'anchorme';
import { IPageBuilderSchedulerPreviewProps } from './IPageBuilderSchedulerPreviewProps';
import { isValidHexColor } from 'components/PageBuilder/PageBuilderSideBar/BlockSettings/domain';
import BlockTextContainer, { BlockSubTextContainer } from '../BlockComponents/BlockTextContainer';
import InnerHTML from 'dangerously-set-html-content';

const ContentContainer: any = styled.div<{design: any}>`
    width: 100%;
    border-radius: 4px;
    position: relative;
    padding-top: ${(props: any) => 
      props.design.container_padding_top ?
      `${props.design.container_padding_top}px` :
      `0px`
    };
    padding-bottom: 0px;
    background-color: ${(props: any) => 
      props.design.container_background_color &&
      isValidHexColor(props.design.container_background_color) ?
      props.design.container_background_color :
      'transparent'
  };
`;

const IconContainer: any = styled.span<{design: any}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  svg {
    path {
      stroke: ${(props: any) => 
        props.design.close_icon_color &&
        isValidHexColor(props.design.close_icon_color) ?
        props.design.close_icon_color :
        '#333333'
      };
  }
`;

const ContentWrapper: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 130px);
  overflow-y: hidden;
`;

const ContentHeaderContainer: any = styled.div`
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

const EmbedContentContainer: any = styled.div`
  margin-top: 10px;
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
`;


const PageBuilderSchedulerPreview: React.FC<IPageBuilderSchedulerPreviewProps> = (props) => {

    const closeForm = () => {
        let pageFormsOpenStateCopy = JSON.parse(JSON.stringify(props.pageFormsOpenState));

        if(
            pageFormsOpenStateCopy &&
            pageFormsOpenStateCopy[props.pageForm.render_id]
        ) {
            pageFormsOpenStateCopy[props.pageForm.render_id] = false;

            props.setPageFormsOpenState(pageFormsOpenStateCopy);
        }
    }

    const getEmbedHtml = () => {
      if(props.pageForm.scheduler_url) {
        return `
          <!-- Calendly inline widget begin -->
          <div class="calendly-inline-widget" data-url="${props.pageForm.scheduler_url}?hide_event_type_details=1&hide_gdpr_banner=1" style="min-width:320px;height:700px;"></div>
          <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
          <!-- Calendly inline widget end -->
        `
      }

      return `<div></div>`;
    }
    
  return (
    <ReactModal
    isOpen={props.pageFormsOpenState[props.pageForm.render_id]}
    style={{
        content: {
          width: `${props.pageForm.design.container_width}px`,
          maxWidth: props.parentSelectorId ? `100%` : `100vw`,
          overflowY: `hidden`,
          borderRadius: "4px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          position: props.parentSelectorId ? 'absolute' : 'fixed',
          border: 0,
          padding: 0,
        },
        overlay: {
          position: props.parentSelectorId ? 'absolute' : 'fixed',
          backgroundColor: `rgba(0, 0, 0, .5)`,
          zIndex: 99999,
        }
    }}
    parentSelector={
      props.parentSelectorId ? 
      () => document.getElementById(props.parentSelectorId as string) as HTMLElement :
      undefined
    }
    portalClassName={styles.modal_portal}
    >
        <ContentContainer
        design={props.pageForm.design}
        >
          <IconContainer
          design={props.pageForm.design}
          onClick={closeForm}
          >
            <XIcon
            />
          </IconContainer>

            <ContentWrapper
            >
              <ContentHeaderContainer
              >
                <BlockTextContainer
                design={props.pageForm.design}
                pageStyles={props.pageStyles}
                textKey="heading"
                fallbackTextColor="#FFFFFF"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.pageForm.heading),
                }}
                />
    
    
                <BlockSubTextContainer
                design={props.pageForm.design}
                pageStyles={props.pageStyles}
                textKey="subtext"
                fallbackTextColor="#FFFFFF"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.pageForm.subtext),
                }}
                />
              </ContentHeaderContainer>

              <EmbedContentContainer
              >
                <InnerHTML
                html={getEmbedHtml()}
                />
              </EmbedContentContainer>
  
            </ContentWrapper>
        </ContentContainer>
    </ReactModal>
  )
}

export default PageBuilderSchedulerPreview;