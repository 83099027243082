import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { IMainComponentProps } from "./IMainComponentProps";
import MainLoading from "../../components/mainLoading";
import { Map } from "immutable";
import {
  authorizeActions,
  globalActions,
  groupActions
} from "../../store/actions";
import { push } from "connected-react-router";
import TopBarComponent from "../../components/topBar";
import "./MainComponent.scss";
import "./main-responsive.scss";
import { AuthorizeService } from "../../api/services/authorize";
import UserSettingsContainer from "containers/UserSettingsContainer";
import SearchContainer from "containers/SearchContainer";
import UserAccesContainer from "containers/UserAccesContainer/UserAccesContainer";
import NotificationsContainer from "containers/NotificationsContainer";
import GroupContainer from "containers/GroupContainer";
import CreateGroupContainer from "containers/CreateGroupContainer";
import appConfig from "appConfig";
import jsonwebtoken from "jsonwebtoken";
import NotFound from "../notFound";
import { GroupService } from "../../api/services/groups";
import store from "../../store/configureStore";
import Moment from "react-moment";
import miscUtils from "utils/miscUtils";
import Favicon from "react-favicon";
import TagManager from "react-gtm-module";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { setCookieValue } from "../../utils/cookieStorage";
import { CommonConstants } from "../../constants/common";
import VerifyAccountComponent from "containers/selfSignup/VerifyAccountComponent";
import SSLVerificationContainer from "containers/SSLVerificationContainer";
import { toast, ToastType } from "react-toastify";
import AppInfoBanner from "./AppInfoBanner";
import { AppSumoTierCoupons, defaultFaviconUrl } from "./domain";
import { BillingService } from "api/services/billing";
import { chatsAtom, chatsIsUpdatedAtom, chatsLoadingAtom, groupDataLoadingAtom, resourceLinkLoadingAtom, showMessagePopUpAtom } from "showMessageMenuAtom";
import { useAtom } from "jotai";
import MessagingContainer from "containers/messaging";
import { MessagingService } from "api/services/messaging";
import EmailWhitelabelingSuccess from "components/EmailWhitelabelingSuccess";
import Home from "containers/home";
import Facebook from "Facebook";
import * as Sentry from "@sentry/react";
export const DirectChatsContext = React.createContext<any>({});


const MainComponent: React.FC<IMainComponentProps> = props => {

  const toggleLectureMenuRef = useRef();
  const refreshNotificationsCountRef = useRef();

  const [subdomain, setCurrentSubdomain] = useState<any>();
  const [onboardingChecklistActive, setOnboardingChecklistActive] = useState<string>("");
  const [user, setUser] = useState(null);
  const [group, setGroup] = useState<any>(null);
  const [isLoadingGroup, setIsLoadingGroup] = useState<boolean>(true);
  const [isCustomDomain, setIsCustomDomain] = useState<boolean>(false);
  const {
    global,
    isAuthed,
    logout,
    login,
    getUserGroups,
    hideTopLoading
  } = props;
  let baseResponsiveClass = "";
  const [onTrial, setOnTrial] = useState<boolean>();
  const [communityFavicon, setCommunityFavicon] = useState<string>("");
  const [checkingRedirect, setCheckingRedirect] = useState<boolean>(true);
  const [showFavicon, setShowFavicon] = useState<boolean>(false);
  const [isInvitePreview, setIsInvitePreview] = useState<boolean>(false);
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const [isTeamMember, setIsTeamMember] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>('');
  const [showTrialBanner, setShowTrialBanner] = useState<boolean>(true);
  const [showAppSumoUpgradeBanner, setShowAppSumoUpgradeBanner] = useState<boolean>(false);
  const [appSumoTier4Plan, setAppSumoTier4Plan] = useState<any>(null);
  const [appSumoTier4PlanCoupon, setAppSumoTier4PlanCoupon] = useState<any>(null);

  const search = props.location.search;
  const params = new URLSearchParams(search);
  const membersRef = React.useRef(null);

  const [openMessagePopUp,] = useAtom(showMessagePopUpAtom);
  const [, setCommunityChats] = useAtom(chatsAtom) as any;
  const [, setIsLoading] = useAtom(chatsLoadingAtom);
  const [chatIdUpdated,] = useAtom(chatsIsUpdatedAtom);
  const [resourceLinkLoading,] = useAtom(resourceLinkLoadingAtom);

  const pixelId = group && group.ex_config && group.ex_config.facebook_pixel

  function getIndividualChatMembers(chat: any) {
    try {
      const encounteredIds = new Set();
      const individualChat = chat.members.filter((member: any) => {
        if (
          member.community_member &&
          (member.community_member.user !== null && member.community_member.user !== undefined)
        ) {
          const memberId = member.community_member.id;
          if (!encounteredIds.has(memberId)) {
            encounteredIds.add(memberId);
            return true;
          }
        }
      });

      return individualChat;
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  }

  const [memberCommunityUnreadCount, setMemberCommunityUnreadCount] = useState<number>(0);
  const [directChats, setdirectChat] = useState<any>([]);

  const getDirectChats = async (limit?: number) => {
    try {
      if (chatIdUpdated) {
        return
      } else {
        const chatrooms: any = await MessagingService.getInstance().getUserChatrooms(group && group.id, "", 1, "");
        const sortedChats = chatrooms && chatrooms.data.filter((chat: any) => {
          if (chat.type === "group") {
            return chat.members.length >= 2;
          } else if (chat.type === "individual") {
            const individualChat = getIndividualChatMembers(chat);
            if (individualChat && individualChat.length > 1) {
              return true;
            }
          }
        })

        setCommunityChats(
          {
            data: sortedChats,
            chatrooms
          },
        )
        setIsLoading(false);
        const filteredMinusGroup = sortedChats.length > 0 && sortedChats.filter((chat: any) => chat.type === 'individual')
        setdirectChat(filteredMinusGroup)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const loadMoreChats = async () => {
    try {
      const chatrooms: any = await MessagingService.getInstance().loadMoreChatrooms(directChats && directChats.chatrooms.next_page_url);
      const sortedChats = chatrooms && chatrooms.data.filter((chat: any) => {
        if (chat.type === "group") {
          return chat.members.length >= 2;
        } else if (chat.type === "individual") {
          const individualChat = getIndividualChatMembers(chat);
          if (individualChat && individualChat.length > 1) {
            return true;
          }
        }
      })
      setCommunityChats(
        {
          data: [...directChats.data, ...sortedChats],
          chatrooms
        },
      )
    } catch (error) {
      console.log(error)
    }
  };

  const [chatHistory, setChatHistory] = useState<any>({})
  const [isMemberAddGroupChat, setIsMemberAddGroupChat] = useState<boolean>(false)

  const getCommunityUnread = () => {
    const groupSlug = props.location.pathname.split("/")[2];
    if (chatIdUpdated) {
      return
    } else {
      GroupService.getInstance()
        .getGroup(subdomain || params || groupSlug)
        .then((groupData: any) => {
          const groupCount = groupData.user_membership && groupData.user_membership.unread_messages_count;

          if (groupCount > 0) {
            setMemberCommunityUnreadCount(parseInt(groupCount));
          }
          else {
            setMemberCommunityUnreadCount(0);
          }
        })
    }
  }

  useEffect(() => {
    if (miscUtils.isGroupMember(group && group)) {
      getDirectChats()
      getCommunityUnread()
    }
  }, [group, chatIdUpdated])

  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [chatroom, setChatroom] = useState<any>({});
  const [isCreateDirectchat, setIsCreateDirectchat] = useState<boolean>();
  const [chatIsclicked, setChatIsclicked] = useState<boolean>(false)
  const [, setGroupDataisLoading] = useAtom(groupDataLoadingAtom);

  useEffect(() => {
    if (window.location.hostname.includes("group.app") || window.location.hostname.includes("group.localhost")) {
      const [currentSubdomain] = window.location.hostname.split(".");

      if (currentSubdomain && !appConfig.reservedSubdomains.includes(currentSubdomain)) {
        setCurrentSubdomain(currentSubdomain);
        setGroupDataisLoading(true)
        GroupService.getInstance()
          .getGroup(currentSubdomain)
          .then((groupData: any) => {
            setIsLoadingGroup(false);
            // setGroupDataisLoading(false)
            setGroup(groupData);
            determineLoginRedirect(groupData);
            if (
              groupData &&
              groupData.custom_domain_status === "active" &&
              appConfig.env !== "dev"
            ) {
              // const redirectUrl = `http://${groupData.custom_domain}${window.location.pathname}`;
              // window.location.assign(redirectUrl);
              const url = new URL(window.location.href)
              const redirectUrl = `http://${groupData.custom_domain}${window.location.pathname}`;

              // fix the issue of query params disappering
              const pathandQuery = redirectUrl + url.search;
              window.location.assign(pathandQuery);
            }
          })
          .catch(() => {
            setIsLoadingGroup(false)
            setGroupDataisLoading(false)
          })
          .finally(() => setGroupDataisLoading(false))
      } else {
        setCurrentSubdomain(null);
      }
    } else {
      setIsCustomDomain(true);
      GroupService.getInstance()
        .getGroup(window.location.hostname)
        .then((groupData: any) => {
          if (groupData) {
            determineLoginRedirect(groupData);
            setIsLoadingGroup(false);
            setGroup(groupData);
            setCurrentSubdomain(groupData.subdomain);
          } else {
            setCurrentSubdomain(null);
          }
        })
        .catch(() => setIsLoadingGroup(false))
    }

    if (
      !window.location.pathname.includes("channel") &&
      props.location.pathname !== "/"
    ) {
      setOnboardingChecklistActive("");
    }

    if (window.location.pathname === "/invite-preview") {
      setIsInvitePreview(true);
    } else {
      setIsInvitePreview(false);
    }

    if (window.location.pathname === "/invite" && (!isTeamMember)) {
      setIsInvited(true);
    } else {
      setIsInvited(false);
    }
    setQueryString(params.toString());

    const { pathname } = window.location;
    if (group) {
      if (((pathname === "/courses" || pathname === "/courses/") && !group.menu['courses']['show'])
        || ((pathname === "/library" || pathname === "/library/") && !group.menu['library']['show'])
        || ((pathname === "/events" || pathname === "/events/") && !group.menu['events']['show'])
        // || ((pathname === "/messages" || pathname === "/messages/") && !group.menu['messages']['show'])
        || (pathname === "/members" && !group.menu['people']['show'])
        || (pathname === "/about" && !group.menu['profile']['show'])
        || (pathname === "/feed" && !group.menu['feed']['show'])
      ) {
        if (group.home_page_config.status) {
          window.location.assign('/');
        } else {
          window.location.assign(`/channel/${group.default_channel.slug}`);
        }
      }
    }

  }, [props.location.pathname, isTeamMember]);

  useEffect(() => {
    if (group) {
      if (group && group.google_tag_id) {
        TagManager.initialize({ gtmId: group.google_tag_id });
      }

      setCommunityFavicon(getCommunityFavicon());
      setShowFavicon(true);
      document.title = group.name;
      const theme = {
        custom: group.custom_branding ? group.custom_branding : null
      };
      store.store.dispatch(globalActions.setGroupTheme(theme));
      getTrialStatus();

      if (group && group.user_membership) {
        setIsTeamMember(true);
      } else {
        setIsTeamMember(false);
      }
    }
  }, [group]);

  useEffect(() => {
    AuthorizeService.getInstance().onAuthStateChanged(handleAuthStateChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthed]);

  //check if community is on appsumo plan
  //and can upgrade to tier 4
  useEffect(() => {
    if (
      group &&
      group.active_subscription &&
      group.active_subscription.plan &&
      group.active_subscription.plan.uuid &&
      group.active_subscription.plan.uuid.startsWith("sumo") &&
      !group.active_subscription.plan.uuid.endsWith("tier4") &&
      miscUtils.isAdmin(group.user_membership) &&
      !(
        window.location.pathname.includes('/settings/billing/plans') &&
        window.location.pathname.includes('/confirmation')
      )
    ) {
      setShowAppSumoUpgradeBanner(false); //appsumo upgrade banner is hidden
    }
  }, [group]);

  useEffect(() => {
    if (showAppSumoUpgradeBanner) {
      getAppSumoTier4Plan();
    }
  }, [showAppSumoUpgradeBanner]);

  useEffect(() => {
    if (appSumoTier4Plan) {
      getAppSumoTier4PlanCoupon();
    }
  }, [appSumoTier4Plan]);

  const getAppSumoTier4Plan = () => {
    const appsumoTier4uuid = appConfig.appsumo.tier4_uuid as string;
    GroupService.getInstance()
      .getSinglePlan(appsumoTier4uuid)
      .then((response: any) => {
        setAppSumoTier4Plan(response);
      })
      .catch((error: any) => {

      })
  }

  const getAppSumoTier4PlanDiscountedPrice = () => {
    let discountedPrice: number = appSumoTier4Plan.price;
    if (appSumoTier4PlanCoupon.amount_off) {
      discountedPrice = (appSumoTier4Plan.price - appSumoTier4PlanCoupon.amount_off);
    }

    if (appSumoTier4PlanCoupon.percent_off) {
      discountedPrice = (((100 - appSumoTier4PlanCoupon.percent_off) / 100) * appSumoTier4Plan.price);
    }

    if (discountedPrice % 1 === 0) {
      return discountedPrice.toLocaleString();
    } else {
      return discountedPrice.toFixed(2).toLocaleString();
    }
  }

  const getAppSumoTier4PlanCoupon = () => {
    let couponCode: any;

    if (
      group &&
      group.active_subscription &&
      group.active_subscription.plan &&
      group.active_subscription.plan.uuid &&
      group.active_subscription.plan.uuid.endsWith("tier1")
    ) {
      couponCode = AppSumoTierCoupons.TIER1;
    }

    if (
      group &&
      group.active_subscription &&
      group.active_subscription.plan &&
      group.active_subscription.plan.uuid &&
      group.active_subscription.plan.uuid.endsWith("tier2")
    ) {
      couponCode = AppSumoTierCoupons.TIER2;
    }

    if (
      group &&
      group.active_subscription &&
      group.active_subscription.plan &&
      group.active_subscription.plan.uuid &&
      group.active_subscription.plan.uuid.endsWith("tier3")
    ) {
      couponCode = AppSumoTierCoupons.TIER3;
    }

    if (couponCode) {
      BillingService.getInstance()
        .validateCouponCode(couponCode, appSumoTier4Plan.id, group.id)
        .then((response: any) => {
          setAppSumoTier4PlanCoupon({
            ...response.data,
            coupon: couponCode,
          });
        })
        .catch((error: any) => {
        });
    }
  }

  function handleAuthStateChanges(user: any) {
    hideTopLoading!();
    const query = new URLSearchParams(props.location.search);
    const t = query.get("t");
    const q = query.get("q");
    if (q && t) {
      const usr = jsonwebtoken.verify(q, 'dLS*@eP+YuGe!AzD&vUwH%Fch*$R-&mdu?9!4zRfmXrzcp8uQfjZazW3S_dE+Bw', { algorithms: ['HS256'] });
      // tslint:disable-next-line:no-parameter-reassignment
      setCookieValue(CommonConstants.USER_TOKEN, t);
      setCookieValue(CommonConstants.USER_KEY, usr);

      user = usr;
    }

    if (user) {
      setUser(user);
      if (typeof user.id !== "undefined") {
        // Identify the user
        Sentry.configureScope((scope: any) => {
          scope.setUser({ email: user.email });
        });
        (window as any).clarity("set", "USER_EMAIL", user.email);
        (window as any).clarity("set", "USER_NAME", user.full_name);
        (window as any).clarity("identify", user.email);
      }
      getUserGroups(user.id);
      login(user);
    }
  }

  function determineLoginRedirect(group: any) {
    let selectPlan = false;
    let freePlanId: any;

    if (group.plans) {
      group.plans.forEach(function (plan: any) {
        if (plan.price > 0 && !plan.archived_at && !plan.hidden) {
          selectPlan = true;
        }
        if (plan.price === 0) {
          freePlanId = plan.id;
        }
      });
      const freePlans = group.plans.filter((plan: any) => plan.price === 0 && !plan.archived_at);
      if (freePlans.length > 1) {
        selectPlan = true;
      }
    }

    if (group &&
      !group.membership_status ||
      group.membership_status === "not a member" &&
      !group.user_membership &&
      isAuthed &&
      window.location.pathname === "/") {
      const planUrl = params.get("utm_content");

      if (planUrl && window.location.pathname === "/") {
        props.history.push(`/plan-subscription${queryString.length > 0 ? `?${queryString}` : ""}`);
      }

      if (params.get("join") === "true" && selectPlan && window.location.pathname === "/") {
        if (subdomain) {
          props.history.push(`/plan-subscription${queryString.length > 0 ? `?${queryString}` : ""}`);
        } else {
          props.history.push(`/group/${group.slug}/plan-subscription${queryString.length > 0 ? `?${queryString}` : ""}`);
        }
      }
      if (params.get("join") === "true" && !selectPlan && window.location.pathname === "/") {
        joinGroup(freePlanId);
      }
    } else {
      setCheckingRedirect(false);
    }
  }

  useEffect(() => {
    if (window.location.pathname === "/" && (params.get("join") || params.get("utm_content"))) {
      setCheckingRedirect(true);
    } else {
      setCheckingRedirect(false);
    }
  }, [window.location.pathname])

  function joinGroup(freePlanId: any) {
    const _user: any = user;
    if (_user) {
      GroupService.getInstance()
        .membershipRequest(_user.id, group.id, "", freePlanId)
        .then((response: any) => {
          window.location.reload();
        });
    } else {
      window.location.reload();
    }
  }

  function getCommunityFavicon() {
    if (group.favicon_image) {
      return group.favicon_image;
    }
  }

  const groupAppHomePage = Boolean(window.location.href === miscUtils.getBaseDomain() + "/")

  function determineContainer() {

    if (!isLoadingGroup && ((isCustomDomain && !subdomain) || (subdomain && !group))) {
      return <NotFound
        message={"Page not found.!!!!!"}
        subMessage={!user ? "Sign up to create or join a group. " : undefined}
        user={user}
      />;
    }

    if (subdomain !== undefined) {
      if (subdomain === null) {
        if (window.location.pathname === "/" && !isAuthed) {
          switch (true) {
            case window.location.pathname === "/" &&
              subdomain === null &&
              !isAuthed:
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-login";
              return <UserAccesContainer subdomain={subdomain} group={group} />;
          }
        }
        // added because I cant figure what causing the slowness on user group  page
        else if (groupAppHomePage && isAuthed && window.location.pathname === "/") {
          switch (true) {
            case window.location.pathname === "/" && groupAppHomePage:
              baseResponsiveClass = "res-group-container groupapp-home-page";
              return <Home />;
          }

        }
        else {
          switch (true) {
            case window.location.pathname === "/" &&
              subdomain === null &&
              !isAuthed:
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-login";
              return <UserAccesContainer subdomain={subdomain} group={group} />;
            case window.location.pathname.includes("members") &&
              !window.location.pathname.includes("settings"):
              baseResponsiveClass = "res-group-container";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.split('/').pop() === "notifications":
              baseResponsiveClass = "res-notification-container res-no-sub-nav";
              return (
                <NotificationsContainer
                  subdomain={subdomain}
                  group={group}
                  refreshNotificationsCountRef={refreshNotificationsCountRef}
                />
              );
            case window.location.pathname.includes("/stripe-connect"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("/integration-authorized"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("/plan-subscription"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} group={group} />;
            case window.location.pathname.includes("courses") &&
              !window.location.pathname.includes("settings"):
              baseResponsiveClass = "res-group-container res-courses";
              return <GroupContainer subdomain={subdomain} toggleLectureMenuRef={toggleLectureMenuRef} />;
            case window.location.pathname.includes("library") &&
              !window.location.pathname.includes("settings"):
              baseResponsiveClass = "res-group-container res-library";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("events") &&
              !window.location.pathname.includes("settings"):
              baseResponsiveClass = "res-group-container";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.split("/")[1] === "pages-preview":
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.split("/")[1] === "pages":
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("/plan-subscription-preview"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("/manage-subscription"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("/user-subscriptions"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("settings"):
              baseResponsiveClass =
                "res-group-container res-no-sub-nav res-admin-page";
              return <GroupContainer subdomain={subdomain} setCommunityFavicon={setCommunityFavicon} />;
            case window.location.pathname.includes("/group"):
              baseResponsiveClass = "res-group-container";
              return (
                <GroupContainer
                  subdomain={subdomain}
                  toggleOnboardingChecklist={toggleOnboardingChecklist}
                />
              );
            case window.location.pathname.includes("email_unsubscription"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("group_billing_cancellation"):
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname === "/":
              baseResponsiveClass = "res-group-container";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname === "/settings":
              baseResponsiveClass = "res-group-container res-no-sub-nav";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("posts"):
              baseResponsiveClass = "res-group-container";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("/feed"):
              baseResponsiveClass = "res-group-container";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("channel"):
              baseResponsiveClass = "res-group-container";
              return <GroupContainer subdomain={subdomain} />;
            case window.location.pathname.includes("/creategroup"):
              baseResponsiveClass = "res-create-group-container res-no-sub-nav";
              return <CreateGroupContainer />;
            case window.location.pathname.includes("about"):
              baseResponsiveClass = "res-user-settings-container res-no-sub-nav";
              return (
                <UserSettingsContainer subdomain={subdomain} group={group} />
              );
            case window.location.pathname.includes("search"):
              baseResponsiveClass = "res-search-container res-no-sub-nav";
              return <SearchContainer />;
            case window.location.pathname.includes("login"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-login";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("signup"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-signup self-signup";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("verify-account"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-signup self-signup";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("create-community"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-signup self-signup";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("forgot-password"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-forgot-password";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("reset-password"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-reset-password";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("email_unsubscription") &&
              !isAuthed:
              baseResponsiveClass = "res-user-access-container res-no-sub-nav";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("onboarding"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-reset-password";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("join_group"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-reset-password";
              return <UserAccesContainer group={group} />;
            case window.location.pathname.includes("community-email-verification-success"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-signup self-signup";
              return <EmailWhitelabelingSuccess />;
            case window.location.pathname.includes("community-email-verification-failure"):
              baseResponsiveClass =
                "res-user-access-container res-no-sub-nav res-signup self-signup";
              return <EmailWhitelabelingSuccess />;
          }
        }
      } else {
        switch (true) {
          case window.location.pathname.includes("verify-email"):
            baseResponsiveClass =
              "res-user-access-container res-no-sub-nav res-signup self-signup";
            return <VerifyAccountComponent
              // @ts-ignore
              groupId={group && group.id}
              CommuinityTheme={group && group.custom_branding}
            />;
          // case window.location.pathname.includes("community-email-verification-success"):
          //   baseResponsiveClass =
          //     "res-user-access-container res-no-sub-nav res-signup self-signup";
          //   return <EmailWhitelabelingSuccess />;
          case window.location.pathname.includes("members") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} group={group} />;
          case window.location.pathname.includes("members") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("channels") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("custom-domain") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("courses") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("library") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("events") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("subscription") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("requests") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("teams") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("onboarding") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("verify-account"):
            window.location.replace(`${miscUtils.getBaseDomain()}/verify-account?s=false`);
          case window.location.pathname.includes("automations") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("homepage") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("landing-pages") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("moderation") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("segments") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("getting-started") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("analytics") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("details") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} setCommunityFavicon={setCommunityFavicon} />;
          case window.location.pathname.includes("billing") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("invite") &&
            window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.split('/').pop() === "notifications":
            baseResponsiveClass = "res-notification-container res-no-sub-nav";
            return (
              <NotificationsContainer
                subdomain={subdomain}
                group={group}
                refreshNotificationsCountRef={refreshNotificationsCountRef}
              />
            );
          case window.location.pathname === '/signup-disabled':
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname === '/plan-disabled':
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("courses") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-group-container res-courses";
            return <GroupContainer subdomain={subdomain} toggleLectureMenuRef={toggleLectureMenuRef} />;
          case window.location.pathname.includes("alts") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-group-container res-courses";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("library") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-group-container res-library";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("events") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/stripe-connect"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/integration-authorized"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/invite") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-user-access-container res-no-sub-nav";
            return <UserAccesContainer subdomain={subdomain} group={group} isAuthed={isAuthed} />;
          case window.location.pathname.includes("/plan-subscription"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} group={group} />;
          case window.location.pathname.includes("/sso/oauth/callback") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.split("/")[1] === "pages-preview":
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.split("/")[1] === "pages":
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/plan-subscription-preview"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/manage-subscription"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/user-subscriptions"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/group"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/messages"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/filter"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("email_unsubscription"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("group_billing_cancellation"):
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname === "/":
            baseResponsiveClass = "res-group-container";
            return (
              <GroupContainer
                toggleOnboardingChecklist={toggleOnboardingChecklist}
                subdomain={subdomain}
              />
            );
          case window.location.pathname.includes("/signin") &&
            !window.location.pathname.includes("settings"):
            baseResponsiveClass = "res-group-container";
            return <UserAccesContainer subdomain={subdomain} group={group} isAuthed={props.isAuthed} />;
          case window.location.pathname === "/settings":
            baseResponsiveClass = "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("posts"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("/feed"):
            baseResponsiveClass = "res-group-container";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("channel"):
            baseResponsiveClass = "res-group-container";
            return (
              <GroupContainer
                toggleOnboardingChecklist={toggleOnboardingChecklist}
                subdomain={subdomain}
              />
            );
          case window.location.pathname.includes("/creategroup"):
            baseResponsiveClass = "res-create-group-container res-no-sub-nav";
            return <CreateGroupContainer />;
          case window.location.pathname.includes("about"):
            baseResponsiveClass = "res-user-settings-container res-no-sub-nav";
            return (
              <UserSettingsContainer subdomain={subdomain} group={group} />
            );
          case window.location.pathname.includes("search"):
            baseResponsiveClass = "res-search-container res-no-sub-nav";
            return <SearchContainer />;
          case window.location.pathname.includes("login"):
            baseResponsiveClass =
              "res-user-access-container res-no-sub-nav res-login";
            return <UserAccesContainer subdomain={subdomain} group={group} />;
          case window.location.pathname.includes("signup"):
            baseResponsiveClass =
              "res-user-access-container res-no-sub-nav res-signup";
            return <UserAccesContainer group={group} />;
          case window.location.pathname.includes("forgot-password"):
            baseResponsiveClass =
              "res-user-access-container res-no-sub-nav res-forgot-password";
            return <UserAccesContainer group={group} />;
          case window.location.pathname.includes("reset-password"):
            baseResponsiveClass =
              "res-user-access-container res-no-sub-nav res-reset-password";
            return <UserAccesContainer group={group} />;
          case window.location.pathname.includes("email_unsubscription") &&
            !isAuthed:
            baseResponsiveClass = "res-user-access-container res-no-sub-nav";
            return <UserAccesContainer group={group} />;
          case window.location.pathname.includes("integrations"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("events"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("onboarding")
            && window.location.pathname.includes("settings"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("automations"):
            baseResponsiveClass =
              "res-group-container res-no-sub-nav res-admin-page";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname === "/onboarding":
            baseResponsiveClass =
              "res-group-container res-no-sub-nav";
            return <GroupContainer subdomain={subdomain} />;
          case window.location.pathname.includes("join_group"):
            baseResponsiveClass =
              "res-user-access-container res-no-sub-nav res-reset-password";
            return <UserAccesContainer group={group} />;
          default:
            return <NotFound message={"Page not found.!"} />;
        }
      }
    }
  }

  function showTopBar() {
    return (
      window.location.pathname !== "/signup" &&
      window.location.pathname !== "/verify-account" &&
      window.location.pathname !== "/create-community" &&
      window.location.pathname !== "/signin" &&
      window.location.pathname !== "/forgot-password" &&
      window.location.pathname !== "/reset-password" &&
      window.location.pathname.split("/")[1] !== "pages" &&
      window.location.pathname.split("/")[1] !== "pages-preview" &&
      !(
        window.location.pathname.split("/")[2] === "landing-pages" &&
        window.location.pathname.split("/").pop() === "edit"
      )
    );
  }

  function toggleOnboardingChecklist(onboardingChecklistClass: string) {
    setOnboardingChecklistActive(onboardingChecklistClass);
  }

  function getTrialDate() {
    // const tz = moment.tz.guess();
    if (group) {
      return miscUtils.convertDateStringToUsertimezoneFormat(group.active_subscription.trial_ends_at);
      // return moment.tz(group.active_subscription.trial_ends_at, tz);

    }
  }

  function getTrialStatus() {
    if (group && group.active_subscription && group.active_subscription.on_trial && window.location.pathname !== "/onboarding") {
      setOnTrial(true);
    } else {
      setOnTrial(false);
    }
  }

  function isADayLeftOnTrial() {
    if (miscUtils.getDateDiffInHours(group && group.active_subscription.trial_ends_at) >= 22 && miscUtils.getDateDiffInHours(group && group.active_subscription.trial_ends_at) <= 35) {
      return true;
    }

    return false;
  }

  /**
   * Render app DOM component
   *
   * @returns
   *
   * @memberof Main
   */
  const container = determineContainer();

  const TrialBannerRemover = () => {
    return (
      <span
        className="gp-trial-banner-remover-container"
        onClick={() => setShowTrialBanner(false)}
      >
        <FontAwesomeIcon
          icon={faTimes}
        />
      </span>
    )
  }

  const emailNotVerified = (group && group.user_membership && !group.user_membership.user.verified) &&
    (!miscUtils.isAdmin(group && group.user_membership)) &&
    window.location.pathname !== "/signin" &&
    isAuthed;


  function resendCode() {
    AuthorizeService.getInstance()
      .resendVerificationCode(group && group.id)
      .finally(() => {
        toast("Verification code has been sent.", {
          type: ToastType.SUCCESS,
          autoClose: 3000
        });
        window.open('verify-email?cm=true&s=false', '_self')
      });
  }
  const EmailVerificationBanner = () => {
    return (
      <div className={`${!window.location.pathname.includes("/verify-email") && "email-verification-banner-container"}`}>

        <div className="email-verification-banner">
          <span className="verify-text">Please verify your email address.</span>
          <button
            className="verify-btn"
            onClick={resendCode}
          >
            Verify email
          </button>
        </div>
      </div>
    )
  }

  if (window.location.pathname.includes("/.well-known/cf-custom-hostname-challenge")) {
    return (
      <SSLVerificationContainer
      />
    )
  }

  return (
    <Sentry.ErrorBoundary fallback={<div>An error has occured. Please reload the page.</div>}>
    <div id="master" className={`${baseResponsiveClass} ${onboardingChecklistActive}`}>
      {
        pixelId &&
        <Facebook pixelId={pixelId} />
      }

      <DirectChatsContext.Provider
        value={{
          directChats,
          isLoadingMessages,
          setIsLoadingMessages,
          chatroom,
          setChatroom,
          isCreateDirectchat,
          setIsCreateDirectchat,
          group,
          memberCommunityUnreadCount,
          getCommunityUnread,
          chatIsclicked, setChatIsclicked,
          chatHistory, setChatHistory,
          isMemberAddGroupChat, setIsMemberAddGroupChat,
          membersRef,
          getDirectChats,
          setMemberCommunityUnreadCount,
          loadMoreChats
        }}
      >
        {
          resourceLinkLoading ?
            <div className="link-loading">
              <span>Loading...</span>
            </div>
            :
            <>
              {showTopBar() && (
                <TopBarComponent
                  user={user}
                  getUserGroups={getUserGroups}
                  authed={isAuthed}
                  logout={logout}
                  subdomain={subdomain}
                  toggleLectureMenuRef={toggleLectureMenuRef}
                  group={group}
                  refreshNotificationsCountRef={refreshNotificationsCountRef}
                />
              )}

              {emailNotVerified && <EmailVerificationBanner />}

              {showAppSumoUpgradeBanner && appSumoTier4Plan && appSumoTier4PlanCoupon ? (
                <>
                  <AppInfoBanner
                    showByDefault
                    message={
                      <span
                      >
                        Upgrade your LTD to tier 4 for ${getAppSumoTier4PlanDiscountedPrice()}
                        &nbsp;
                        <Link
                          className="upgrade-now"
                          to={`/settings/billing/plans/${appSumoTier4Plan.id}/confirmation?plan-uuid=${appSumoTier4Plan.uuid}&promo_code=${appSumoTier4PlanCoupon.coupon}&hide-coupon=true`}
                          onClick={() => setShowAppSumoUpgradeBanner(false)}
                        >
                          Upgrade
                        </Link>
                      </span>
                    }
                  />
                </>
              ) : <></>}
              {
                openMessagePopUp &&
                <MessagingContainer
                  isAuthed={isAuthed}
                  subdomain={subdomain}
                  group={group && group}
                  theme={global.theme}
                />
              }

              {onTrial && miscUtils.isAdmin(group && group.user_membership) && (
                <div className={`trial-countdown ${!showTrialBanner ? 'trial-countdown--hidden' : ''}`}>
                  <TrialBannerRemover />
                  <span>
                    {isADayLeftOnTrial() ?
                      <>1 day</> :
                      <><Moment fromNow ago>{getTrialDate()}</Moment></>

                    }
                    &nbsp;left on your free trial.&nbsp;
                    {group && group.active_subscription && group.active_subscription.plan && group.active_subscription.plan.price === 0 ? (
                      <span><Link className="upgrade-now" to={`/settings/billing/plans`}>Select a plan</Link></span>
                    ) : (<></>)}
                  </span>
                </div>
              )}
              {global.showTopLoading && (
                <MainLoading
                  isLoading={global.showTopLoading}
                  pastDelay={false}
                  timedOut={false}
                  retry={() => {
                  }}
                  error={null}
                />
              )}
              {!checkingRedirect && (
                <main id={"site-content"} className={`${onTrial && isAuthed && miscUtils.isAdmin(group && group.user_membership) && showTrialBanner ? "show-trial-banner" : ""} ${isInvitePreview ? "is--invite-preview" : ""} ${isInvited ? "is--invited-wrap" : ""} ${showTopBar() ? "show-topbar" : ""} ${emailNotVerified ? 'email-verification-banner-bottom-space' : ''}`}>
                  {isAuthed && (
                    <>
                      {!window.location.pathname.includes("group_billing_cancellation")
                        && !window.location.pathname.includes("email_unsubscription") && (
                          <>
                            {!onTrial && miscUtils.isAdmin(group && group.user_membership) && (group.active_subscription && group.active_subscription.stripe_status === "past_due") && (
                              <div className={`trial-countdown ${!showTrialBanner ? 'trial-countdown--hidden' : ''}`}>
                                <TrialBannerRemover />
                                <span>Charging your card failed. </span>
                                <a className="upgrade-now" onClick={() => {
                                  props.history.replace("/settings/billing");
                                }}>Update card now</a>
                              </div>
                            )}
                            {!onTrial && miscUtils.isAdmin(group && group.user_membership) && (!group.active_subscription || group.active_subscription && group.active_subscription.stripe_status === "cancelled") && !window.location.pathname.includes("/settings/billing/plans") && !window.location.pathname.includes("settings/details") && (
                              props.history.replace("/settings/billing/plans")
                            )}
                          </>
                        )}
                    </>
                  )}

                  {container}
                </main>
              )}
              {showFavicon ? (
                <Favicon url={communityFavicon} />
              ) : (
                <Favicon url={defaultFaviconUrl} />
              )}
            </>
        }

      </DirectChatsContext.Provider>
    </div>
    </Sentry.ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: IMainComponentProps) => {
  return {
    login: (user: any) => {
      dispatch(authorizeActions.login(user));
    },
    logout: () => {
      dispatch(authorizeActions.dbLogout());
    },
    getUserGroups: (userId: any) => {
      if (userId) {
        dispatch(groupActions.dbGetUserGroups(userId));
      }
    },
    goTo: (url: string) => dispatch(push(url)),
    hideTopLoading: () => dispatch(globalActions.hideTopLoading()),
    showMasterLoading: () => dispatch(globalActions.showMasterLoading()),
    hideMasterLoading: () => dispatch(globalActions.hideMasterLoading()),
    clearMessage: () => dispatch(globalActions.clearMessage())
  };
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const global = Map(state.get("global", {})).toJS() as any;
  const selectedGroup: string = state.getIn(["group", "selectedGroup"]) as string;
  const globalUser: any = state.getIn(["authorize", "user"]);
  const { sendFeedbackStatus, progress } = global;

  return {
    globalUser,
    global,
    sendFeedbackStatus,
    progress,
    selectedGroup,
    guest: authorize.guest,
    uid: authorize.uid,
    isAuthed: authorize.authed
  };
};

const EnhancedMainComponent = Sentry.withProfiler(MainComponent as any);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(EnhancedMainComponent as any)) as any
);
