import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class ModerationService {
  private dbInstance: any = databaseInstance;

  private static instance: ModerationService;

  constructor() {
    ModerationService.instance = this;
  }

  public static getInstance() {
    if (!ModerationService.instance) {
      ModerationService.instance = new ModerationService();
    }
    return ModerationService.instance;
  }

  public getMembershipRequests: (
    groupId: number,
    query?: string
  ) => Promise<void> = (groupId, query = "") => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/membership-requests${query}`)
        .then((response: any) => resolve(response));
    });
  };

  public acceptMembershipRequest: (
    requestId: number
  ) => Promise<void> = requestId => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .post(`memberships`, {
          group_membership_request_id: requestId
        })
        .then(() => {
          resolve();
        });
    });
  };

  public rejectMembershipRequest: (
    requestId: number
  ) => Promise<void> = requestId => {
    return new Promise((resolve, reject) => {
      this.dbInstance.delete(`/membership-requests/${requestId}`).then(() => {
        resolve();
      });
    });
  };

  public getPostsToModerate: (groupId: number) => Promise<void> = groupId => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/moderated-posts`)
        .then((response: any) => resolve(response));
    });
  };

  public acceptIncomingPost: (
    groupId: number,
    postId: number
  ) => Promise<void> = (groupId, postId) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/moderated-posts/${postId}`)
        .then(() => {
          resolve();
        });
    });
  };

  public getFlaggedContent: (groupId: number) => Promise<void> = groupId => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/flagged-contents`)
        .then((response: any) => resolve(response));
    });
  };

  public getModeratedContent: (groupId: number) => Promise<void> = groupId => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/moderated-contents`)
        .then((response: any) => resolve(response));
    });
  };

  public loadMore: (url: string) => Promise<void> = url => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(url).then((response: any) => resolve(response));
    });
  };

  public flagPost: (groupId: number, postId: number) => Promise<void> = (
    groupId,
    postId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/posts/${postId}/flag`)
        .then(() => {
          resolve();
        });
    });
  };

  public flagComment: (postId: number, commentId: number) => Promise<void> = (
    postId,
    commentId
  ) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .post(`/posts/${postId}/comments/${commentId}/flag`)
        .then(() => resolve());
    });
  };

  public approveComment: (
    groupId: number,
    commentId: number
  ) => Promise<void> = (groupId, commentId) => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/flagged-comments/${commentId}`)
        .then(() => resolve());
    });
  };

  public getModerationContentCount: (
    groupId: number
  ) => Promise<void> = groupId => {
    return new Promise((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/moderated-content-count`)
        .then((response: any) => resolve(response));
    });
  };
}
