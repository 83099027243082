import React, { useEffect, useState } from 'react'

import './profile-details-steps.scss'
import { ReactComponent as PhotoUploadIcon } from 'assets/img/photo-upload.svg'
import { profileUpdatesDisabled } from 'containers/userSettings/utils'
import { toast, ToastType } from 'react-toastify'
import ImageCropper from 'elements/imageCropper'
import { UserService } from 'api/services/users'
import { ReactComponent as Twitter } from 'assets/img/twitter.svg'
import { ReactComponent as Facebook } from 'assets/img/facebook.svg'
import { ReactComponent as IG } from 'assets/img/instagram.svg'
import { ReactComponent as LinkedIn } from 'assets/img/linkedin.svg'
import { ReactComponent as Website } from 'assets/img/globe.svg'
import ContentLoader from 'react-content-loader'
import miscUtils from 'utils/miscUtils'
import TimezoneSelect from 'components/TimezoneSelect/TimezoneSelect'
import CountrySelect from 'components/CountrySelect/CountrySelect'

const ProfileDetails = (
    {
        group,
        profileDetailsStepsData,
        profileData,
        setProfileData,
        profileFile,
        setProfileFile,
        userId,
        userData,
        setUserData,
        profileNewSrc,
        setProfileNewSrc,
        requiredDefaultKeys
    }:
        {
            group: any
            profileDetailsStepsData: any
            profileData: any
            setProfileData: any
            profileFile: any
            setProfileFile: any
            userId: any
            userData: any
            setUserData: any
            profileNewSrc: any
            setProfileNewSrc: any
            requiredDefaultKeys: any
        }
) => {

    const [isCropperOpen, setIsCropperOpen] = useState<boolean>(false)
    const [countries, setCountries] = useState<any>();
    const [timezones, setTimezones] = useState<any>();

    const customdata = profileDetailsStepsData &&
        profileDetailsStepsData.filter((category: any) => category.category === 'default'
            && (category.required || category.ask_during_sign_up)
            // && category.ask_during_sign_up
        )

    const profileImgToBeSet = customdata && customdata.filter((category: any) => category.type === 'image')
    const hasprofileImgToBeSet = Boolean(profileImgToBeSet && profileImgToBeSet.length > 0);

    const clickUploadInput = () => {
        if (profileUpdatesDisabled(group)) {
            toast("Profile photo upload is disabled", {
                type: ToastType.ERROR,
                autoClose: 3000,
            });
            return;
        }
        const uploadInput: HTMLElement = document.getElementById("photo-uploader-input") as HTMLElement;
        if (uploadInput) uploadInput.click();
    }

    function handleProfileCrop(crop: any) {
        setProfileFile(crop.file);
        setProfileNewSrc(crop.url);
        setIsCropperOpen(false);
    }

    const onChange = (e: any) => {
        setProfileData({ ...profileData, [e.target.name]: e.target.value })
        setUserData({ ...userData, [e.target.name]: e.target.value })
    };

    const getCountries = () => {
        UserService.getInstance()
            .getCountries()
            .then((countriesResponse: any) => {
                setCountries(countriesResponse.data);
            });
    };

    const getTimezones = () => {
        UserService.getInstance()
            .getTimezones()
            .then((timezonesResponse: any) => {
                setTimezones(timezonesResponse.data);
            });
    };

    const deletePhoto = () => {
        UserService.getInstance().updateUserProfile(
            userId, { profile_image: null })
    }

    useEffect(() => {
        if (profileDetailsStepsData) {
            getCountries()
            getTimezones()
        }
    }, [profileDetailsStepsData])

    useEffect(() => {
        if (userData && userData.profile_image) {
            setProfileNewSrc(userData && userData.profile_image)
        }
    }, [userData])

    useEffect(() => {
        if (userData && userData.country_id && countries) {
            const selectedCountry = countries.find((country: any) => country.id == userData.country_id);
            setUserData({
                ...userData,
                country: selectedCountry ? selectedCountry : ''
            });
        }
    }, [userData && userData.country_id, countries]);

    useEffect(() => {
        if (userData && userData.timezone_id && timezones) {
            const selectedTimezone = timezones.find((timezone: any) => timezone.id == userData.timezone_id);
            setUserData({
                ...userData,
                timezone: selectedTimezone ? selectedTimezone : ''
            });
        }
    }, [userData && userData.country_id, timezones]);

    const newMemberCheck = miscUtils.newMemberCheck(userId, group)

    const showField = (fieldName: string) => {
        return (newMemberCheck === true) || (!newMemberCheck && requiredDefaultKeys.includes(fieldName))
    }

    return (
        <div className='onboarding-profile-steps'>
            {
                profileDetailsStepsData === null
                    ?
                    <>
                        <ContentLoader
                            height={320}
                            width={1080}
                            speed={2}
                            primaryColor="#d9d9d9"
                            secondaryColor="#ecebeb"
                        >
                            <rect x={430} y="0" rx="4" ry="4" width={280} height={28} />
                            <rect x={320} y="52" rx="4" ry="4" width={480} height={28} />
                            <circle cx="554" cy="204" r="90" />
                        </ContentLoader>

                        {Array(6).fill('').map((_e, i) => (
                            <ContentLoader
                                height={120}
                                width={1080}
                                speed={2}
                                primaryColor="#d9d9d9"
                                secondaryColor="#ecebeb"
                            >
                                <rect x={50} y="20" rx="4" ry="4" width={280} height={28} />
                                <rect x={50} y="64" rx="4" ry="4" width={1000} height={58} />
                            </ContentLoader>
                        ))}
                    </>
                    :
                    <>
                        <div className='onboarding-profile-steps-header'>
                            <h1>Create your profile</h1>
                            <span>Fill in the information below and create your profile.</span>
                        </div>

                        {hasprofileImgToBeSet && showField('profile_image') && 
                            <div className='onboarding-profile-steps-photo-upload'>
                                {profileNewSrc === null
                                    ?
                                    <div onClick={clickUploadInput}><PhotoUploadIcon /></div>
                                    :
                                    <div className='onboarding-profile-steps-photo-upload-photo'>
                                        <img src={profileNewSrc} alt='profile picture' />
                                    </div>
                                }
                                {profileNewSrc === null
                                    ?
                                    <div className='onboarding-profile-steps-photo-upload-new'>
                                        <h4 onClick={clickUploadInput}>Upload photo</h4>
                                        <span>Recommended size 180x180</span>
                                    </div>
                                    :
                                    <h4
                                        style={{ color: profileNewSrc ? '#EB5757' : '' }}
                                        onClick={() => {
                                            deletePhoto()
                                            setProfileNewSrc(null)
                                        }}
                                    >
                                        Remove photo
                                    </h4>
                                }

                                <input
                                    id="photo-uploader-input"
                                    onChange={e => {
                                        setProfileFile(
                                            e.target.files ? e.target.files[0] : null
                                        );
                                        setProfileNewSrc(
                                            URL.createObjectURL(
                                                //@ts-ignore
                                                e.target.files ? e.target.files[0] : null
                                            )
                                        );
                                        setIsCropperOpen(true);
                                    }}
                                    type="file"
                                    value=""
                                    name="profile-picture"
                                    accept="image/png, image/jpeg"
                                    hidden
                                    multiple={false}
                                />
                                <ImageCropper
                                    aspect={1}
                                    maxWidth={180}
                                    maxHeight={180}
                                    sourceImage={profileNewSrc}
                                    isOpen={isCropperOpen}
                                    onCropCancel={() => {
                                        setProfileFile(null);
                                        setProfileNewSrc(null);
                                        setIsCropperOpen(false);
                                    }}
                                    onCropFinished={handleProfileCrop}
                                    imageSrc={profileFile}
                                />
                            </div>
                        }


                        <div className='onboarding-profile-steps-profile-info'>
                            <div className='onboarding-profile-steps-profile-info-general'>
                                {
                                    (
                                        showField('headline') ||
                                        showField('country_id') ||
                                        showField('timezone_id') ||
                                        showField('about_me') ||
                                        showField('location') ||
                                        showField('job_title')
                                    )
                                    &&
                                <h1>GENERAL INFO</h1>
                                }

                                <div className='onboarding-profile-steps-profile-info-general-details'>
                                    {customdata && customdata.filter((category: any) => (
                                        category.type === 'text'
                                        && category.name === "Headline"
                                    )).map((data: any) =>
                                        showField('headline') &&
                                        (
                                            <div className='profile-fields-section' key={data.id}>
                                                <label htmlFor={data.name}>{data.name} {" "} {data.required && <span style={{ color: 'red' }}>*</span>}</label>
                                                <input
                                                    name='headline'
                                                    type={data.type}
                                                    aria-label={data.name}
                                                    value={(userData && userData.headline) || ''}
                                                    onChange={onChange}
                                                    placeholder='Write headline'
                                                    required={data.required}
                                                    id='headline'
                                                />
                                            </div>

                                        )
                                    )}

                                    {/* {countries && customdata &&
                                        customdata.filter((category: any) => category.name === 'Country')
                                        .map((data: any) =>
                                            showField('country_id') && (
                                                <div className='profile-fields-section' key={data.id}>
                                                    <label htmlFor={data.name}>{data.name} {" "} {data.required && <span style={{ color: 'red' }}>*</span>}</label>
                                                    <div className="custom-select">
                                                        <select
                                                            onChange={onChange}
                                                            name='country_id'
                                                            aria-label={data.name}
                                                            required={data.required}
                                                            id='country_id'
                                                        >
                                                            <option value="null" className="">Select Country</option>
                                                            {countries &&
                                                                countries.map((country: any) => {
                                                                    return (
                                                                        <option
                                                                            key={country.id}
                                                                            value={country.id}
                                                                            selected={
                                                                                userData &&
                                                                                userData.country &&
                                                                                // profileData.value &&
                                                                                userData.country.id == country.id
                                                                            }
                                                                        >
                                                                            {country.name}
                                                                        </option>
                                                                    )
                                                                })}
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        )} */}

                                    {
                                        countries &&
                                        <CountrySelect
                                            countries={countries}
                                            customdata={customdata}
                                            showField={showField}
                                            onChange={onChange}
                                            userData={userData} />
                                    }

                                    {customdata && customdata.filter((category: any) => (
                                        category.type === 'text'
                                        && category.name === 'City'
                                    )).map((data: any) => {
                                        if (
                                            (newMemberCheck === true) ||
                                            (!newMemberCheck && requiredDefaultKeys.includes('location'))
                                        ) {
                                            return (
                                                <div className='profile-fields-section' key={data.id}>
                                                    <label htmlFor={data.name}>{data.name} {" "} {data.required && <span style={{ color: 'red' }}>*</span>}</label>
                                                    <input
                                                        name='location'
                                                        type={data.type}
                                                        aria-label={data.name}
                                                        value={(userData && userData.location) || ''}
                                                        onChange={onChange}
                                                        placeholder='Enter city'
                                                        required={data.required}
                                                        id='location'
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    }

                                    {/* {timezones &&
                                        customdata &&
                                        customdata.filter((category: any) => category.name === 'Timezone')
                                        .map((data: any) =>
                                            showField('timezone_id') && (
                                                <div className='profile-fields-section' key={data.id}>
                                                    <label htmlFor={data.name}>{data.name} {" "} {data.required && <span style={{ color: 'red' }}>*</span>}</label>
                                                    <div className="custom-select">
                                                        <select
                                                            onChange={onChange}
                                                            name="timezone_id"
                                                            aria-label={data.name}
                                                            required={data.required}
                                                            id='timezone_id'
                                                        >
                                                            <option value="null" className="">Select Timezone</option>
                                                            {timezones &&
                                                                timezones.map((timezone: any) => {
                                                                    return (
                                                                        <option
                                                                            key={timezone.id}
                                                                            value={timezone.id}
                                                                            selected={
                                                                                userData &&
                                                                                userData.timezone &&
                                                                                // profileData.value &&
                                                                                userData.timezone.id === timezone.id
                                                                            }
                                                                        >
                                                                            {timezone.text}
                                                                        </option>
                                                                    )
                                                                })}
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    } */}
                                    {
                                        timezones &&
                                        <TimezoneSelect
                                            timezones={timezones}
                                            customdata={customdata}
                                            showField={showField}
                                            onChange={onChange}
                                            userData={userData}
                                        />
                                    }

                                    {
                                        customdata && customdata.filter((category: any) => (
                                            category.type === 'text'
                                            && category.name === 'Job title'
                                        )
                                        ).map((data: any) => {
                                            if (
                                                (newMemberCheck === true) ||
                                                (!newMemberCheck && requiredDefaultKeys.includes('job_title'))
                                            ) {
                                                return (
                                                    <div className='profile-fields-section' key={data.id}>
                                                        <label htmlFor={data.name}>{data.name} {" "} {data.required && <span style={{ color: 'red' }}>*</span>}</label>
                                                        <input
                                                            name="job_title"
                                                            type={data.type}
                                                            aria-label={data.name}
                                                            value={(userData && userData.job_title) || ''}
                                                            onChange={onChange}
                                                            placeholder='Enter job title'
                                                            required={data.required}
                                                            id='job_title'
                                                        />
                                                    </div>
                                                )
                                            }
                                        }
                                        )}

                                    {
                                        customdata &&
                                        customdata.filter((category: any) => category.name === 'Bio')
                                            .map((data: any) =>
                                                showField('about_me') && (
                                                    <div className='profile-fields-section' key={data.id}>
                                                        <label htmlFor={data.name}>{data.name} {" "} {data.required && <span style={{ color: 'red' }}>*</span>}</label>
                                                        <textarea
                                                            onChange={onChange}
                                                            value={(userData && userData.about_me) || ''}
                                                            name="about_me"
                                                            placeholder='Write something about yourself'
                                                            required={data.required}
                                                            id='about_me'
                                                        />
                                                    </div>
                                                )
                                            )}
                                </div>
                            </div>
                        </div>

                        <div className='onboarding-profile-steps-profile-info'>
                            <div className='onboarding-profile-steps-profile-info-general link-title'>
                                {
                                    customdata &&
                                    customdata.filter((category: any) => category.type === 'url').length > 0 &&
                                    (
                                        showField('facebook') ||
                                        showField('instagram') ||
                                        showField('linkedin') ||
                                        showField('twitter') ||
                                        showField('website')
                                    )
                                    &&
                                    <h1>LINKS</h1>
                                }
                            </div>
                            <div className='onboarding-profile-steps-profile-info-general-details'>
                                {
                                    customdata && customdata.filter((category: any) => (
                                        category.type === 'url' &&
                                        (
                                            category.name === 'Instagram'
                                            || category.name === 'Facebook'
                                            || category.name === 'LinkedIn'
                                            || category.name === 'Web URL'
                                            || category.name === 'Twitter'
                                        )
                                    )).map((data: any) => (
                                        <div key={data.id}>
                                            {data.name === 'Facebook' && showField('facebook') && (
                                                <FacebookComponent data={data} userData={userData} onChange={onChange} />
                                            )}
                                            {data.name === 'Instagram' && showField('instagram') && (
                                                <InstagramComponent data={data} userData={userData} onChange={onChange} />
                                            )}
                                            {data.name === "LinkedIn" && showField('linkedin') && (
                                                <LinkeinComponent data={data} userData={userData} onChange={onChange} />
                                            )}
                                            {data.name === 'Twitter' && showField('twitter') && (
                                                <TwitterComponent data={data} userData={userData} onChange={onChange} />
                                            )}
                                            {data.name === 'Web URL' && showField('website') && (
                                                <WebsiteComponent data={data} userData={userData} onChange={onChange} />
                                            )}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </>
            }
        </div>
    )
}

const InstagramComponent = ({ data, userData, onChange }: { data: any, userData: any, onChange: any }) => (
    <div className='profile-fields-section' key={data.id}>
        <label htmlFor={data.name}>Instagram URL {data.required && <span style={{ color: 'red' }}>*</span>}</label>
        <div className='profile-fields-section-links'>
            <div className='profile-fields-section-links-icon'>
                <IG />
            </div>
            <input
                required={data.required}
                name='instagram'
                type={data.type}
                aria-label='Instagram'
                value={(userData && userData.instagram) || ''}
                onChange={onChange}
                placeholder='Enter Instagram URL'
                id='instagram'
            />
        </div>
    </div>
);

const FacebookComponent = ({ data, userData, onChange }: { data: any, userData: any, onChange: any }) => (
    <div className='profile-fields-section' key={data.id}>
        <label htmlFor={data.name}>Facebook URL {data.required && <span style={{ color: 'red' }}>*</span>}</label>
        <div className='profile-fields-section-links'>
            <div className='profile-fields-section-links-icon'>
                <Facebook />
            </div>
            <input
                required={data.required}
                name='facebook'
                type={data.type}
                aria-label='Facebook'
                value={(userData && userData.facebook) || ''}
                onChange={onChange}
                placeholder='Enter Facebook URL'
                id='facebook'
            />
        </div>
    </div>
);

const LinkeinComponent = ({ data, userData, onChange }: { data: any, userData: any, onChange: any }) => (
    <div className='profile-fields-section' key={data.id}>
        <label htmlFor={data.name}>LinkedIn URL {data.required && <span style={{ color: 'red' }}>*</span>}</label>
        <div className='profile-fields-section-links'>
            <div className='profile-fields-section-links-icon'>
                <LinkedIn />
            </div>
            <input
                required={data.required}
                name='linkedin'
                type={data.type}
                aria-label='LinkedIn'
                value={(userData && userData.linkedin) || ''}
                onChange={onChange}
                placeholder='Enter LinkedIn URL'
                id='LinkedIn'
            />
        </div>
    </div>
);

const WebsiteComponent = ({ data, userData, onChange }: { data: any, userData: any, onChange: any }) => (
    <div className='profile-fields-section' key={data.id}>
        <label htmlFor={data.name}>Website URL {data.required && <span style={{ color: 'red' }}>*</span>}</label>
        <div className='profile-fields-section-links'>
            <div className='profile-fields-section-links-icon'>
                <Website />
            </div>
            <input
                required={data.required}
                name='website'
                type={data.type}
                aria-label='website'
                value={userData && userData.website || ''}
                onChange={onChange}
                placeholder='Enter Website URL'
                id='website'
            />
        </div>
    </div>
);

const TwitterComponent = ({ data, userData, onChange }: { data: any, userData: any, onChange: any }) => (
    <div className='profile-fields-section' key={data.id}>
        <label htmlFor={data.name}>Twitter URL {data.required && <span style={{ color: 'red' }}>*</span>}</label>
        <div className='profile-fields-section-links'>
            <div className='profile-fields-section-links-icon'>
                <Twitter />
            </div>
            <input
                required={data.required}
                name='twitter'
                type={data.type}
                aria-label='twitter'
                value={(userData && userData.twitter) || ''}
                onChange={onChange}
                placeholder='Enter Twitter URL'
                id='twitter'
            />
        </div>
    </div>
);

export default ProfileDetails