import React from "react";
import miscUtils from "utils/miscUtils";
import './EntryComponent.scss';

interface EntryComponentProps {
  mention: {
    name: string;
    id: string;
    avatar?: string;
  };
  theme: {
    mentionSuggestionsEntryContainer: string;
    mentionSuggestionsEntryContainerRight: string;
    mentionSuggestionsEntryText: string;
  };
  searchValue?: string;
  isFocused?: boolean;
}


const EntryComponent: React.FC<EntryComponentProps> = ({
  mention,
  theme,
  searchValue,
  isFocused,
  ...parentProps
}) => {
  return (
    <div {...parentProps}>
      <div className={`${theme.mentionSuggestionsEntryContainer} mention-entry-container`}>
        <div
          data-role-id={`${mention.id}`}
          className={`${theme.mentionSuggestionsEntryContainerRight} mention-entry-right`}
        >
          <div className={`${theme.mentionSuggestionsEntryText} mention-entry-text`}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {mention.avatar ? (
              <img src={mention.avatar} alt="av" className="avatar-image mention-entry-avatar-image" />
            ) : (
              <div
                className="mention-no-avatar mention-entry-no-avatar"
                data-color={miscUtils.getColorInitial(`${mention.name}`)}
              >
                {miscUtils.getNameInitials(mention.name)}
              </div>
            )}
            <span className="member-name mention-entry-name">{mention.name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntryComponent;
