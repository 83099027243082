import React from 'react';

import Select from 'react-select';
import styles from './country-select.module.scss'

const CountrySelect = ({
    countries,
    customdata,
    showField,
    onChange,
    userData
}: {
    countries: any
    customdata: any
    showField: any
    onChange: any
    userData: any
}) => {
    const countryOptions = countries.map((country: any) => ({
        value: country.id,
        label: country.name
    }));

    const defaultCountry = userData && userData.country ? {
        value: userData.country.id,
        label: userData.country.name
    } : null;

    const customStyles = {
        option: (provided: any) => ({
            ...provided,
            textAlign: 'left'
        }),
        singleValue: (provided: any) => ({
            ...provided,
            textAlign: 'left'
        }),
        placeholder: (provided: any) => ({
            ...provided,
            textAlign: 'left'
        })
    };

    return (
        customdata &&
        customdata.filter((category: any) => category.name === 'Country')
            .map((data: any) =>
                showField('country_id') && (
                    <div className={styles.profileFieldsSection} key={data.id}>
                        <label htmlFor={data.name}>
                            {data.name} {" "}
                            {data.required && <span style={{ color: 'red' }}>*</span>}
                        </label>
                        <div className={styles.customSelect}>
                            <Select
                                options={countryOptions}
                                defaultValue={defaultCountry}
                                onChange={(selectedOption) => onChange({
                                    target: {
                                        name: 'country_id',
                                        value: selectedOption ? selectedOption.value : null
                                    }
                                })}
                                name="country_id"
                                aria-label={data.name}
                                required={data.required}
                                id='country_id'
                                isClearable
                                styles={customStyles}
                                placeholder="Search or select a country"
                            />
                        </div>
                    </div>
                )
            )
    );
};

export default CountrySelect;
