import React, { useContext } from "react";
import "./userProfileHeader.scss";

import { ReactComponent as Info } from 'assets/img/info.svg'
import { ReactComponent as WritePost } from 'assets/img/write-posts.svg'
import { ReactComponent as Calender } from 'assets/img/calendar.svg'
import { ReactComponent as Comments } from 'assets/img/comments.svg'
import { ReactComponent as Twitter } from 'assets/img/twitter.svg'
import { ReactComponent as Facebook } from 'assets/img/facebook.svg'
import { ReactComponent as IG } from 'assets/img/instagram.svg'
import { ReactComponent as LinkedIn } from 'assets/img/linkedin.svg'
import { ReactComponent as Website } from 'assets/img/globe.svg'
import ReadMore from "utils/readMore/readMore";
import ContentLoader from "react-content-loader";
import { UserProfileContext } from "../UserProfile";
import ProfilePhoto from "../utils/ProfilePhoto";


const UserProfileHeader = (props: {
  group: any;
  userData: any
  profileSrc: string | null
  handleTabClick: any
  activeTab: string
  userId: number
}) => {

  const { userData, handleTabClick, activeTab } = props

  const space = 10;
  const sectionHeaderHeight = 12;
  const suggestedContentHeight = 90;
  const suggestedContentWidth = 150;

  const { setShowProfilePhotoModal, showProfilePhotoModal } = useContext(UserProfileContext)

  return (
    <div className="user-profile-body-wrapper">
      <div className="user-profile-body">
        {
          userData.length === 0 ?
            <>
              <div className="content-loader-profile-header">
                <ContentLoader
                  primaryColor={"#dbdbdb"}
                  secondaryColor="#ecebeb"
                >
                  <circle cx={65} cy={65} r={60} />
                  <rect y={sectionHeaderHeight - 10} rx="2" ry="2" x={sectionHeaderHeight + (space * 45)} height={12} width={suggestedContentWidth / 2} />
                  <rect y={sectionHeaderHeight - 10} rx="2" ry="2" x={sectionHeaderHeight + (space * 16)} height={12} width={suggestedContentWidth} />
                  <rect y={sectionHeaderHeight + space} rx="2" ry="2" x={sectionHeaderHeight + (space * 16)} height={32} width={suggestedContentWidth} />
                  <rect y={sectionHeaderHeight + space * 5} x={sectionHeaderHeight + (space * 16)} rx="2" ry="2" width={400} height={suggestedContentHeight} />
                </ContentLoader>
              </div>


              <div className="content-loader-profile-header-small">
                <ContentLoader
                  primaryColor={"#dbdbdb"}
                  secondaryColor="#ecebeb"
                >
                  <circle cx={65} cy={65} r={60} />
                  <rect y={sectionHeaderHeight - 10} rx="2" ry="2" x={sectionHeaderHeight + (space * 45)} height={12} width={suggestedContentWidth / 2} />
                  <rect y={sectionHeaderHeight - 10} rx="2" ry="2" x={sectionHeaderHeight + (space * 16)} height={12} width={suggestedContentWidth} />
                  <rect y={sectionHeaderHeight + space} rx="2" ry="2" x={sectionHeaderHeight + (space * 16)} height={32} width={suggestedContentWidth} />
                  <rect y={sectionHeaderHeight + space * 5} x={sectionHeaderHeight + (space * 16)} rx="2" ry="2" width={400} height={suggestedContentHeight} />
                </ContentLoader>
              </div>
            </>
            :
            <>
              <div className="user-profile-image-container">
                <div className="user-profile-image-wrapper">
                  {!userData.profile_image
                    && <span className="no-image-text">No image</span>
                  }

                  {userData.profile_image &&
                    (
                      <img
                        className="user-profile-image"
                      src={userData.profile_image}
                        alt='profile picture'
                      />
                    )
                  }
                </div>

                {
                  showProfilePhotoModal &&
                  <ProfilePhoto
                  />
                }

                <span
                  className="edit-photo-text"
                  onClick={() => setShowProfilePhotoModal(true)}
                >
                  {!userData.profile_image ? 'Upload photo' : 'Edit photo'}
                </span>
              </div>

              <div className="user-profile-desc-container">
                <div className="user-profile-header">
                  <div className="user-profile-header-name-title">
                    <span className="user-profile-full-name">
                      {(userData && userData.full_name) || ""}
                    </span>

                    <span className="user-profile-job-title">
                      {(userData && userData.job_title) || ""}
                    </span>
                  </div>

                  <div className="user-profile-header-links">
                    {
                      (userData && userData.website) &&
                      <a href={userData.website} className='user-profile-header-links-icon'>
                        <Website width={20} height={20} />
                      </a>

                    }
                    {
                      (userData && userData.linkedin) &&
                      <a href={userData.linkedin} className='user-profile-header-links-icon'>
                        <LinkedIn width={20} height={20} />
                      </a>
                    }
                    {
                      (userData && userData.facebook) &&
                      <a href={userData.facebook} className='user-profile-header-links-icon'>
                        <Facebook width={20} height={20} />
                      </a>
                    }
                    {
                      (userData && userData.instagram) &&
                      <a href={userData.instagram} className='user-profile-header-links-icon'>
                        <IG width={20} height={20} />
                      </a>
                    }
                    {
                      (userData && userData.twitter) &&
                      <a href={userData.twitter} className='user-profile-header-links-icon'>
                        <Twitter width={20} height={20} />
                      </a>
                    }

                  </div>
                </div>

                <div className="user-profile-body-bio">
                  {
                    userData.about_me &&
                    <span className="user-profile-bio">
                      {
                        userData.about_me && userData.about_me.length > 100 ? (
                          <ReadMore
                            text={userData.about_me}
                            readMoreCharacterLimit={100}
                            numberOfLines={1}
                            lineHeight={1}
                            showLessButton
                            onContentChange={() => { }}
                            showMoreText="Read Full Bio"
                            showLessText="Hide Full Bio"
                          />
                        ) : (userData.about_me)
                      }
                      </span>
                  }
                </div>

              </div>
            </>
        }

      </div>
      <div className="user-profile-body-bio-small-device">
        {
          userData.about_me &&
          <span className="user-profile-bio-small-device">
            {
              userData.about_me && userData.about_me.length > 100 ? (
                <ReadMore
                  text={userData.about_me}
                  readMoreCharacterLimit={100}
                  numberOfLines={1}
                  lineHeight={1}
                  showLessButton
                  onContentChange={() => { }}
                  showMoreText="Read Full Bio"
                  showLessText="Hide Full Bio"
                />
              ) : (userData.about_me)
            }
          </span>
        }
      </div>
      <div className="user-profile-footer">

        <div
          onClick={() => handleTabClick("profile")}
          className={`${activeTab === 'profile' ? 'active-tab active-tab-selected' : 'active-tab'}`}
        >
          <div className={`${activeTab === 'profile' ? 'active-icon' : 'icon'}`}>
            <Info width={25} height={25} strokeWidth={1} />
          </div>
          <span className={`${activeTab === "profile" ? "user-profile-footer-text-tabs-selected" : "user-profile-footer-text-tabs"}`}>
            Profile info
          </span>
        </div>

        <div
          onClick={() => handleTabClick("posts")}
          className={`${activeTab === 'posts' ? 'active-tab active-tab-selected' : 'active-tab'}`}
        >
          <div className={`${activeTab === 'posts' ? 'active-icon' : 'icon'}`}>
            <WritePost width={25} height={25} strokeWidth={0.5} />
          </div>

          <span className={`${activeTab === "posts" ? "user-profile-footer-text-tabs-selected" : "user-profile-footer-text-tabs"}`}>
            Posts
          </span>
        </div>

        <div
          onClick={() => handleTabClick("comments")}
          className={`${activeTab === 'comments' ? 'active-tab active-tab-selected' : 'active-tab'}`}
        >
          <div className={`${activeTab === 'comments' ? 'active-icon' : 'icon'}`}>
            <Comments width={20} height={20} strokeWidth={1} />
          </div>
          <span className={`${activeTab === "comments" ? "user-profile-footer-text-tabs-selected" : "user-profile-footer-text-tabs"}`}>
            Comments
          </span>
        </div>

        <div
          onClick={() => handleTabClick("events")}
          className={`${activeTab === 'events' ? 'active-tab active-tab-selected' : 'active-tab'}`}
        >
          <div className={`${activeTab === 'events' ? 'active-icon' : 'icon'}`}>
            <Calender width={20} height={20} strokeWidth={2} />
          </div>
          <span className={`${activeTab === "events" ? "user-profile-footer-text-tabs-selected" : "user-profile-footer-text-tabs"}`}>
            Events
          </span>
        </div> 

      </div>
    </div>
  );
};

export default UserProfileHeader


