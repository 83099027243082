import React from 'react';
import './fileButton.scss';

import { ReactComponent as FileIcon } from "assets/img/file.svg";

interface IFileButtonProps {
  onClick: any;
}

const FileButton: React.FC<IFileButtonProps> = (props: any) => {
  return (
    <div
      className="file-button-container"
      onClick={props.onClick}
    >
      <span className='file-icon'>
        <FileIcon />
      </span>
    </div>
  )
}

export default FileButton;