import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class SegmentService {
  private dbInstance: any = databaseInstance;

  private static instance: SegmentService;

  constructor() {
    SegmentService.instance = this;
  }

  public static getInstance() {
    if (!SegmentService.instance) {
      SegmentService.instance = new SegmentService();
    }
    return SegmentService.instance;
  }

  public getCommunitySegments: (
      groupId: number,
      query?: string,
  ) => Promise<void> = (groupId, query="") => {
      return new Promise<void>((resolve, reject) => {
          this.dbInstance
          .get(`/groups/${groupId}/segments${query}`)
          .then((response: any) => resolve(response))
          .catch(() => reject());
      })
  }

  public createCommunitySegment: (
    groupId: number,
    segmentDetails: any,
  ) => Promise<void> = (groupId, segmentDetails) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/segments`, segmentDetails)
      .then(() => resolve())
      .catch(() => reject());
    });
  }

  public updateSegment: (
    groupId: number,
    segmentId: number,
    segmentDetails: any
  ) => Promise<void> = (groupId, segmentId, segmentDetails) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .put(`/groups/${groupId}/segments/${segmentId}`, segmentDetails)
      .then(() => resolve())
      .catch(() => reject());
    })
  }

  public deleteSegment: (
    groupId: number,
    segmentId: number,
  ) => Promise<void> = (groupId, segmentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/segments/${segmentId}`)
      .then(() => resolve())
      .catch(() => reject());
    })
  }

  public getSegmentMembers: (
    groupId: number,
    segmentId: number,
    query?: string,
  ) => Promise<void> = (groupId, segmentId, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/segments/${segmentId}/members${query}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    })
  }

  public getNonSegmentMembers: (
    groupId: number,
    segmentId: number,
    query?: string,
  ) => Promise<void> = (groupId, segmentId, query="") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .get(`/groups/${groupId}/segments/${segmentId}/non-members${query}`)
      .then((response: any) => resolve(response))
      .catch(() => reject());
    });
  }

  public addMembersToSegment: (
    groupId: number,
    segmentId: number,
    membershipIds: Array<number>,
  ) => Promise<void> = (groupId, segmentId, membershipIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .post(`/groups/${groupId}/segments/${segmentId}/members`, {
        membership_ids: membershipIds
      })
      .then(() => resolve())
      .catch(() => reject());
    });
  }

  public removeMembersFromSegment: (
    groupId: number, 
    segmentId: number,
    membershipIds: Array<number>,
    removeResourceAccess: boolean,
  ) => Promise<void> = (groupId, segmentId, membershipIds, removeResourceAccess) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
      .delete(`/groups/${groupId}/segments/${segmentId}/members`, {
        data: {
          membership_ids: membershipIds,
          remove_resource_access: removeResourceAccess,
        }
      })
      .then(() => resolve())
      .catch(() => reject());
    })
  }

  public loadMore: (url: string) => Promise<void> = url => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(url).then((response: any) => resolve(response));
    });
  }

  public exportSegmentMembers: (
    groupId: number,
    segmentId: number,
  ) => Promise<void> = (groupId, segmentId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/segments/${segmentId}/members/export`, {
          groupId, segmentId
        })
        .then((response: any) => resolve(response))
        .catch(() => reject());
    });
  };
}
