import React from 'react';
import { QuestionTypes } from 'containers/Onboarding/MembershipQuestions/QuestionMaker/domain';
import './single-answer.scss';
import { UserProfileContext } from 'containers/userSettings/userProfile/UserProfile';


interface IAnswerableQuestionProps {
    membershipQuestion: any;
    questionIndex: number;
    responseState: any;
    setResponseState: any;
    group: any
}
const SingleQuestion: React.FC<IAnswerableQuestionProps> = (props) => {

    const { showCustomEditMode, } = React.useContext(UserProfileContext)

    const getTextAreaValue = () => {
        let textAreaValue: string = "";

        props.responseState && props.responseState.forEach((responseObject: any, index: number) => {
            if (index === props.questionIndex) {
                textAreaValue = responseObject.answer;
            }
        });

        return textAreaValue;
    }

    const setSingleAnswer = (answer: string) => {
        props.setResponseState(props.responseState && props.responseState.map((responseObject: any, index: number) => {
            if (index === props.questionIndex) {
                return {
                    ...responseObject,
                    answer,
                }
            }

            return responseObject;
        }));
    }

    const setMultipleAnswer = (answer: string) => {
        props.setResponseState(props.responseState.map((responseObject: any, index: number) => {
            if (index === props.questionIndex) {
                return {
                    ...responseObject,
                    answer: [
                        ...props.responseState[index].answer,
                        answer,
                    ]
                }
            }

            return responseObject;
        }))
    }

    const removeCheckboxAnswer = (answer: string) => {
        let answersArray: Array<string> = [];

        props.responseState && props.responseState.forEach((responseObject: any, index: number) => {
            if (index === props.questionIndex) {
                answersArray = responseObject.answer;
            }
        });

        answersArray = answersArray.filter((singleAnswer) => singleAnswer !== answer);

        props.setResponseState(props.responseState.map((responseObject: any, index: number) => {
            if (index === props.questionIndex) {
                return {
                    ...responseObject,
                    answer: answersArray,
                }
            }

            return responseObject;
        }));
    }

    const isRadioChecked = (answerOption: string) => {
        let selectedAnswer: string = "";

        props.responseState && props.responseState.forEach((responseObject: any, index: number) => {
            if (index === props.questionIndex) {
                selectedAnswer = responseObject.answer;
            }
        });

        return selectedAnswer === answerOption;
    }

    const isCheckboxChecked = (answerOption: string) => {
        let answersArray: Array<string> = [];

        props.responseState && props.responseState.forEach((responseObject: any, index: number) => {
            if (index === props.questionIndex) {
                answersArray = responseObject.answer;
            }
        });

        let hasAnswerOption = false;

        answersArray.forEach((answer: string) => {
            if (answer === answerOption) {
                hasAnswerOption = true;
            }
        });

        return hasAnswerOption;
    }

    const getQuestionOptions = () => {
        switch (props.membershipQuestion.type) {
            case QuestionTypes.Text:
                return (
                    <textarea
                        className="comm-answerable-question-options-textarea"
                        placeholder={
                            props.membershipQuestion.placeholder ? props.membershipQuestion.placeholder : 'Write answer'
                        }
                        value={getTextAreaValue()}
                        onChange={(event: any) => setSingleAnswer(event.target.value)}
                        maxLength={1000}
                        rows={5}
                        disabled={showCustomEditMode}
                    />
                );
            case QuestionTypes.Checkbox:
                return (
                    <div className="comm-answerable-question-options-option-container">
                        {props.membershipQuestion.options.map((answerOption: any) => (
                            <div className="comm-answerable-question-options-option-wrapper">
                                <input
                                    disabled={showCustomEditMode}
                                    className="comm-answerable-question-options-option-checkbox"
                                    type="checkbox"
                                    value={answerOption.option}
                                    checked={isCheckboxChecked(answerOption.option)}
                                    onClick={!isCheckboxChecked(answerOption.option) ? (event: any) => setMultipleAnswer(event.target.value) : (event: any) => removeCheckboxAnswer(event.target.value)}
                                />
                                <p className={`comm-answerable-question-options-option-label ${isCheckboxChecked(answerOption.option) ? 'comm-answerable-question-options-option-label--checked' : ''}`}>{answerOption.option}</p>
                            </div>
                        ))}
                    </div>
                );
            case QuestionTypes.Radio:
                return (
                    <div className="comm-answerable-question-options-option-container">
                        {props.membershipQuestion.options.map((answerOption: any) => (
                            <div className="comm-answerable-question-options-option-wrapper">
                                <input
                                    disabled={showCustomEditMode}
                                    className="comm-answerable-question-options-option-radio"
                                    type="radio"
                                    name={`${props.questionIndex}`}
                                    value={answerOption.option}
                                    checked={isRadioChecked(answerOption.option)}
                                    onClick={(event: any) => setSingleAnswer(event.target.value)}
                                />
                                <p className={`comm-answerable-question-options-option-label ${isRadioChecked(answerOption.option)
                                    ? 'comm-answerable-question-options-option-label--checked' : ''}`}
                                >
                                    {answerOption.option}
                                </p>
                            </div>
                        ))}
                    </div>
                );
            default:
                return <></>
        }
    }

    // this might be a reusable function in future
    const questionStringify = (questionText: string) => {

        //  split by one or more spaces
        const wordList = questionText.split(/\s+/);

        // Filter the word list to find any URLs
        const urlList = wordList.filter((item: string) => /^(http|https)\:\//.test(item));

        // If there are no URLs, just return the original question text in a paragraph
        if (urlList.length === 0) {
            return <>{questionText}</>;
        }

        // If there are URLs, replace them with anchor tags and return the result
        const replacedElements = urlList.map((url: string) => {
            const textToFind = new RegExp(url, 'g');
            const replacementText = '';
            const newString = questionText.replace(textToFind, replacementText);

            return (
                <>
                    {newString}
                    <a
                        href={url}
                        target="_blank"
                        style={{ color: "#365de6", textDecoration: "underLine" }}
                        rel="noreferrer">{url}
                    </a>
                </>
            );
        });

        return <>{replacedElements}</>;
    };


    return (
        <div className="comm-answerable-question-container">
            <p className="comm-answerable-question-title">
                {
                    questionStringify(props.membershipQuestion.name)
                }
                {
                    props.membershipQuestion.required ? <span>*</span> : <></>
                }
            </p>

            <div className="comm-answerable-question-options-container">
                {getQuestionOptions()}
            </div>

        </div>
    )
}

export default SingleQuestion;