import { INotificationService } from "../../../core/services/notification";
import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class NotificationService implements INotificationService {
  private dbInstance: any = databaseInstance;

  private static instance: NotificationService;

  constructor() {
    NotificationService.instance = this;
  }

  public static getInstance() {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  public determineQuery = (filter: string): string => {
    let query: string = ""
    switch (filter) {
      case "read":
        query = "&read=true"
        break;
      case "unread":
        query = "&read=false"
        break
      default:
        break;
    }

    return query;
  }

  public getNotifications: (
    filter: string,
    userId: number,
    currentPage: any
  ) => Promise<void> = (filter, userId, currentPage) => {
    const query: string = this.determineQuery(filter);
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/users/${userId}/notifications?limit=25&page=${currentPage}${query}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getGroupNotifications: (
    filter: string,
    userId: number,
    groupId: number,
    currentPage: any
  ) => Promise<void> = (filter, userId, groupId, currentPage) => {
    const query: string = this.determineQuery(filter);
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/users/${userId}/groups/${groupId}/notifications?limit=25&page=${currentPage}${query}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getGroupUnreadNotificationsCount: (
    userId: number,
    groupId: number,
  ) => Promise<void> = (userId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/users/${userId}/groups/${groupId}/unread-notifications/count`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getUserUnreadNotificationsCount: (userId: number,) => Promise<void> = (userId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/users/${userId}/unread-notifications/count`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateGroupNotification: (
    userId: any,
    groupId: any,
    summary: any,
    broadcast: any,
    post: any,
    unreadMessages: any,
    courseEmail: any,
    communityEmail: any,
    trendingPost: any,
    digestEmails: any,
    activeUpcomingEventEmail: any,
    RSVPemail: any,
    newContentEmail: any,
    mentions: any,
    communityAdminNotifications: any
  ) => Promise<void> = (
    userId, groupId, summary, broadcast,
    post, unreadMessages, courseEmail,
    communityEmail, trendingPost, digestEmails,
    activeUpcomingEventEmail, RSVPemail, newContentEmail, 
    mentions, communityAdminNotifications
  ) => {
      return new Promise<void>((resolve, reject) => {
        this.dbInstance
          .put(`/users/${userId}/groups/${groupId}/notification-preferences`, {
            weekly_summary_email_notification: summary,
            broadcast_email_notification: broadcast,
            post_email_notification: post,
            unread_messages_email_notification: unreadMessages,
            course_email_notification: courseEmail,
            scheduled_email_notification: communityEmail,
            trending_post_push_notification: trendingPost,
            digest_email_frequency: digestEmails,
            event_digest_email_frequency: activeUpcomingEventEmail,
            event_rsvp_email_notification: RSVPemail,
            library_digest_email_frequency: newContentEmail,
            mention_email_notification: mentions,
            community_admin_settings: communityAdminNotifications,
          })
          .then((response: any) => {
            resolve(response);
          });
      });
    };

  public unsubscribeNotifications: (
    userId: any,
    groupId: any,
    notificationType: any
  ) => Promise<void> = (userId, groupId, notificationType) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/users/${userId}/groups/${groupId}/notification-preferences`, {
          [notificationType]: false,
          t: notificationType,
          u: userId
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateUserNotifications: (
    userId: any,
    postId: any
  ) => Promise<void> = (userId, postId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/users/${userId}/posts/notification-preferences`, {
          post_id: postId
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public readNotifications: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/users/${userId}/unread-notifications`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public readGroupNotifications: (userId: number, groupId: number) => Promise<void> = (userId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/users/${userId}/groups/${groupId}/unread-notifications`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public readSingleNotifications: (notificationId: number) => Promise<void> = notificationId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/notifications/${notificationId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public unreadSingleNofitication: (notificationId: number) => Promise<void> = notificationId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/notifications/${notificationId}`)
        .then((response: any) => {
          resolve(response);
        })
    })
  }
}
