import React from 'react';

const TOPBAROFFSET = 50;

export const useScrollTopOffset = (elementId: string) => {
    const [scrollTopOffset, setScrollTopOffset] = React.useState<number>(0);

    React.useEffect(() => {
        const onScroll = () => {
          const element: HTMLElement = document.getElementById(elementId) as HTMLElement;
          if(element) {
            setScrollTopOffset(TOPBAROFFSET - (element.getBoundingClientRect().y));
          }
        }
        document.addEventListener('scroll', onScroll);
        return () => {
          document.removeEventListener('scroll', onScroll);
        }
    }, []);

    return scrollTopOffset;
}