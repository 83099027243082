import React from 'react';

const BackIcon = (props: any) => {
  return (
    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="svg6">
    <path id="Vector" d="M2.29427 3.69689L5.71047 0.226483C5.87523 0.0591083 6.14235 0.0591084 6.30709 0.226483L6.70554 0.631251C6.87001 0.798341 6.87033 1.06914 6.70624 1.23663L3.99884 3.99995L6.70624 6.76325C6.87033 6.93073 6.87001 7.20154 6.70554 7.36863L6.30709 7.77339C6.14233 7.94077 5.87521 7.94077 5.71047 7.77339L2.29427 4.303C2.12953 4.13563 2.12953 3.86427 2.29427 3.69689Z" fill={props.fill ? props.fill : "#365de6"}/>
    </g>
    </svg>
  )
}

export default BackIcon;