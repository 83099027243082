import React from 'react';

const MinusIcon = (props: any) => {
  return (
    <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.75C0 0.335786 0.335786 0 0.75 0L12.75 0C13.1642 0 13.5 0.335786 13.5 0.75C13.5 1.16421 13.1642 1.5 12.75 1.5L0.75 1.5C0.335786 1.5 0 1.16421 0 0.75Z" fill={props.fill ? props.fill : "#3A394C"}/>
    </svg>
  )
}

export default MinusIcon;