import React, { useState, useEffect } from "react";
import { withRouter, Switch } from "react-router-dom";
import MainLoadingComponent from "components/mainLoading";
import Loadable from "react-loadable";
import SidebarComponent from "components/sidebar";
import SidebarForChannelComponent from "components/sidebar/sidebarForChannel";
import { connect } from "react-redux";
import { Map } from "immutable";
import PrivateRoute from "routes/PrivateRoute";
import { IUserSettingsContainerProps } from "./IUserSettingsContainerProps";
import { getlocalStorageValue, setlocalStorageValue } from "utils/localStorage";

const AsyncAbout: any = Loadable({
  loader: () => import("containers/userSettings"),
  loading: MainLoadingComponent
});

const UserSettingsContainer: React.FC<IUserSettingsContainerProps> = props => {
  const [isSideBar, setIsSideBar] = useState<any>("");
  const [sideBarIsOpen, setSideBarIsOpen] = useState<string>("");

  const { isAuthed, global, globalUser, subdomain, group } = props;

  const sectionClass = 'profile-details';

  useEffect(() => {
    if (window.innerWidth < 992) {
      setlocalStorageValue("sidebar", false);
    }

    if (window.innerWidth > 992) {
      setSideBarIsOpen("sidebar-is-open");
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value) {
      setSideBarIsOpen("sidebar-is-open");
    }else {
      setSideBarIsOpen("");
    }
    setIsSideBar(getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getlocalStorageValue("sidebar") && getlocalStorageValue("sidebar").value]);

  return (
    <div
      className={
        isSideBar && isAuthed
          ? `main-wrap ${sideBarIsOpen} ${sectionClass}`
          : `main-wrap ${sideBarIsOpen} ${sectionClass}`
      }
    >
      {isAuthed ? (
        <SidebarComponent subdomain={subdomain} group={null} />
      ) : (
        <SidebarForChannelComponent
          subdomain={subdomain || (global && global.currentSlug)}
        />
      )}
      <div
        className={`content-wrap flex flex-justify-center ${!isAuthed &&
          "no-auth"}`}
      >
        <Switch>
          <PrivateRoute
            user={globalUser}
            group={group}
            authed={isAuthed}
            exact={true}
            path={"/about"}
            component={<AsyncAbout uid={props.uid} subdomain={subdomain} group={group} theme={global.theme} />}
          />
        </Switch>
      </div>
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IUserSettingsContainerProps
) => {
  return {};
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const global = Map(state.get("global", {})).toJS() as any;
  const globalUser: any = state.getIn(["authorize", "user"]);

  return {
    globalUser,
    global,
    guest: authorize.guest,
    uid: authorize.uid,
    isAuthed: authorize.authed
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserSettingsContainer as any) as any
) as any;
