import styled from "styled-components";

const BlockBackgroundImage: any = styled.img<{design: any}>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
`;

export const BlockBackgroundImageGradient: any = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, .4);
`

export default BlockBackgroundImage;