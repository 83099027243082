import AppLoader from 'components/contentLoaders/appLoader';
import React from 'react';
import './library-selector.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ILibSelectorProps } from './ILibSelectorProps';
import { LibraryService } from 'api/services/library';
import { SegmentService } from 'api/services/segment';

const LibrarySelector: React.FC<ILibSelectorProps> = (props) => {
  const [libraryItemsData, setLibraryItemsData] = React.useState<any>(null);
  const [loadingChannels, setLoadingChannels] = React.useState<boolean>(true);

  React.useEffect(() => {
    getLibraryItems();
  }, [props.searchString, props.itemType]);

  const getLibraryItems = () => {
    if (props.itemType === 'segment') {
      let query: string = "";
      if (props.searchString.length > 0) {
        query = `?search=${props.searchString}`
      }

      SegmentService.getInstance()
        .getCommunitySegments(props.group.id, query)
        .then((response: any) => {
          setLibraryItemsData(response.data.data);
          setLoadingChannels(false);
        })
        .finally(() => setLoadingChannels(false))
    }
    else {
      let query: string = `${props.itemType ? `?type=${props.itemType}&limit=50` : ''}`;
      if (props.searchString.length > 0) {
        query = `${query}${!props.itemType ? '?' : '&'}search=${props.searchString}&limit=50`;
      }
      setLoadingChannels(true);
      LibraryService.getInstance()
        .getLibraryContents(props.group.id, query)
        .then((response: any) => {
          setLibraryItemsData(response.data.data);
          setLoadingChannels(false);
        })
        .finally(() => setLoadingChannels(false))
    }
  };

  return (
    <div className="channel-selector-container">
      {
        loadingChannels ?
          (
            <AppLoader
              label={`Loading ${props.itemType}s...`}
            />
          )
          :
          (
            <div className="channel-selector-channels-container">
              {
                libraryItemsData && libraryItemsData.length === 0 ?
                  (
                    <div className="channel-selector-empty-container">
                      <p className="channel-selector-empty-text">No results found</p>
                    </div>
                  )
                  :
                  (
                    <PerfectScrollbar
                      className="channel-selector-channels-scrollarea"
                      options={{ wheelPropagation: false }}
                    >
                      {/* {
                        libraryItemsData && libraryItemsData.map((channel: any) => {
                          if (props.excludes && props.excludes.includes(channel.contentable.id)) {
                            return;
                          }
                          return (
                            <div
                              className="channel-selector-channel-container"
                              onClick={() => { props.onChannelSelected(channel) }}
                              key={channel.id}
                            >
                              <p className="channel-selector-channel-name">{channel.contentable.title}</p>
                            </div>
                          )
                        })
                      } */}
                      {
                        libraryItemsData &&
                        libraryItemsData.map((channel: any) =>
                          props.itemType === 'segment' ?
                            (
                              props.excludes && props.excludes.includes(channel.id) ? null :
                                <div
                                  className="channel-selector-channel-container"
                                  onClick={() => { props.onChannelSelected(channel) }}
                                  key={channel.id}
                                >
                                  <p className="channel-selector-channel-name">{channel.name}</p>
                                </div>
                            )
                            :
                            props.excludes && props.excludes.includes(channel.contentable.id) ? null : (
                              <div
                                className="channel-selector-channel-container"
                                onClick={() => { props.onChannelSelected(channel) }}
                                key={channel.id}
                              >
                                <p className="channel-selector-channel-name">{channel.contentable.title}</p>
                              </div>
                            )
                        )
                      }

                    </PerfectScrollbar>
                  )
              }
            </div>
          )}
    </div>
  )
}

export default LibrarySelector;