import React from "react";
import "./userEvents.scss";

import miscUtils from "utils/miscUtils";
import Moment from "react-moment";
import moment from "moment";
import ListsLoader from "components/contentLoaders/ListsLoader";
import { UserProfileContext } from "../UserProfile";
import { UserService } from "api/services/users";


const UserEvents = (props: {
  groupId: any;
  userId: any
}) => {
  const { userId } = props

  const { groupData } = React.useContext(UserProfileContext)

  const [show, setShow] = React.useState('upcoming');
  const [userRsvpEvents, setUserRsvpEvents] = React.useState<any>(null);
  const [userUpcomingRsvpEvents, setUserUpcomingRsvpEvents] = React.useState<any>([]);
  const [userPastRsvpEvents, setUserPastRsvpEvents] = React.useState<any>([]);

  const isLoading = React.useMemo(() => { return userRsvpEvents === null }, [userRsvpEvents]);

  const getUserEvents = async (eventType?: string) => {
    await UserService.getInstance()
      .getUserRsvpEvents(groupData !== undefined && groupData, userId, eventType)
      .then((res: any) => {
        setUserRsvpEvents(res.data);
      });
  };

  const getUserUpcomingEvents = async () => {
    await UserService.getInstance()
      .getUserRsvpEvents(groupData !== undefined && groupData, userId, 'upcoming')
      .then((res: any) => {
        setUserUpcomingRsvpEvents(res.data);
      });
  };

  const getUserPastEvents = async () => {
    await UserService.getInstance()
      .getUserRsvpEvents(groupData !== undefined && groupData, userId, 'past')
      .then((res: any) => {
        setUserPastRsvpEvents(res.data);
      });
  };

  React.useEffect(() => {
    getUserEvents()
    getUserUpcomingEvents()
    getUserPastEvents()
  }, [groupData]);

  // group upcoming events by month
  const upcomingEventsByMonth: any = {};
  for (const rsvpevent of userUpcomingRsvpEvents) {
    const month = moment(rsvpevent.event.start_time).format("MMMM YYYY");
    if (!upcomingEventsByMonth[month]) {
      upcomingEventsByMonth[month] = [rsvpevent];
    } else {
      upcomingEventsByMonth[month].push(rsvpevent);
    }
  }

  // group past events by month
  const pastEventsByMonth: any = {};
  for (const rsvpevent of userPastRsvpEvents) {
    const month = moment(rsvpevent.event !== null && rsvpevent.event.start_time).format("MMMM YYYY");
    if (!pastEventsByMonth[month]) {
      pastEventsByMonth[month] = [rsvpevent];
    } else {
      pastEventsByMonth[month].push(rsvpevent);
    }
  }

  return (
    <>
      <div className="title-header">
        {
          userRsvpEvents !== null && userRsvpEvents.length > 0 &&
          <>
            <span
              className={`${show === 'upcoming' ? 'title-header-upcoming selected-events' : 'title-header-upcoming'}`}
              onClick={() => setShow('upcoming')}
            >
              Upcoming events
            </span>

            <span
              className={`${show === 'past' ? 'title-header-past selected-events' : 'title-header-past'}`}
              onClick={() => setShow('past')}
            >
              Past events
            </span>
          </>
        }
      </div>

      {
        isLoading ?
          <div className="events-loader user-profile-events-loader-container">
            <ListsLoader
              totalItems={20}
              padding={2}
              styles={{ background: "#FFFFFF", borderRadius: "6px" }}
            />
          </div>
          :
          <>
            {userRsvpEvents !== null && userRsvpEvents.length > 0 ?
              <>
                {show === 'upcoming' &&
                  <>
                  {userUpcomingRsvpEvents !== null && userUpcomingRsvpEvents.length > 0 ?
                      <>
                        {Object.keys(upcomingEventsByMonth).reverse().map((month, i) => (
                          <div key={i}>
                            <div className="past-events-month">{month}</div>
                            {upcomingEventsByMonth[month].map((rsvpevent: any, j: any) => (
                              <div key={j} className='user-event-container'>
                                <div className='user-event-container-date'>
                                  {
                                    moment.tz(rsvpevent.event.start_time, miscUtils.getUserTimezone()).utc().isSame(rsvpevent.event.end_time, "month") ?
                                      <div className="event-date">
                                        {miscUtils.isSameDayEvent(rsvpevent) ?
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment>

                                          </span>
                                          :
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment>
                                            <span>-
                                              <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time}</Moment>
                                            </span>
                                          </span>
                                        }
                                        <span className="date-month">
                                          <Moment utc={true} format="MMMM" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time && rsvpevent.event.end_time.toUpperCase()}</Moment>
                                        </span>
                                      </div>
                                      :
                                      <div className="event-date-dual-month">
                                        <div className="event-date">
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment>
                                          </span>
                                          <span className="date-month">
                                            <Moment utc={true} format="MMMM" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time && rsvpevent.event.start_time.toUpperCase()}</Moment>
                                          </span>
                                        </div>
                                        <span className="to-hyphen">-</span>
                                        <div className="event-date">
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time}</Moment>
                                          </span>
                                          <span className="date-month">
                                            <Moment utc={true} format="MMMM" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time && rsvpevent.event.end_time.toUpperCase()}</Moment>
                                          </span>
                                        </div>
                                      </div>
                                  }
                                </div>
                                <div className="user-event-container-details">
                                  <div className="event-details">
                                    <button className="event-name">
                                      <a href={`${rsvpevent.event.group && rsvpevent.event.group.url}/events/${rsvpevent.event.slug}`} target="_self">
                                        {rsvpevent.event.title}
                                      </a>
                                    </button>
                                    <span className="event-time">
                                      <Moment utc={true} format="h:mm A" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment> - {" "}
                                      <Moment utc={true} format="h:mm A z" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time}</Moment>
                                      {miscUtils.getReadableTimezoneOffset(miscUtils.getUserTimezone()) && ` (${miscUtils.getReadableTimezoneOffset(miscUtils.getUserTimezone())})`}
                                    </span>
                                  </div>
                                  <div className="fresh-event">
                                    <Moment
                                      utc
                                      fromNow
                                      tz={miscUtils.getUserTimezone()}
                                    >
                                      {rsvpevent.event.start_time}
                                    </Moment>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </>
                      :
                      <span className="empty-payload">No upcoming rsvp'd events yet.</span>
                    }
                  </>
                }
                {show === 'past' &&
                  <>
                  {userPastRsvpEvents !== null && userPastRsvpEvents.length > 0 ?
                      <>
                        {Object.keys(pastEventsByMonth).map((month, i) => (
                          <div key={i}>
                            <div className="past-events-month">{month}</div>
                            {pastEventsByMonth[month].map((rsvpevent: any, j: any) => (
                              <div key={j} className='user-event-container'>
                                <div className='user-event-container-date'>
                                  {
                                    moment.tz(rsvpevent.event.start_time, miscUtils.getUserTimezone()).utc().isSame(rsvpevent.event.end_time, "month") ?
                                      <div className="event-date">
                                        {miscUtils.isSameDayEvent(rsvpevent) ?
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment>
                                          </span>
                                          :
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment>
                                            <span>-
                                              <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time}</Moment>
                                            </span>
                                          </span>
                                        }
                                        <span className="date-month">
                                          <Moment utc={true} format="MMMM" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time && rsvpevent.event.end_time.toUpperCase()}</Moment>
                                        </span>
                                      </div>
                                      :
                                      <div className="event-date-dual-month">
                                        <div className="event-date">
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment>
                                          </span>
                                          <span className="date-month">
                                            <Moment utc={true} format="MMMM" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time && rsvpevent.event.start_time.toUpperCase()}</Moment>
                                          </span>
                                        </div>
                                        <span className="to-hyphen">-</span>
                                        <div className="event-date">
                                          <span className="date-days">
                                            <Moment utc={true} format="DD" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time}</Moment>
                                          </span>
                                          <span className="date-month">
                                            <Moment utc={true} format="MMMM" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time && rsvpevent.event.end_time.toUpperCase()}</Moment>
                                          </span>
                                        </div>
                                      </div>
                                  }
                                </div>
                                <div className="user-event-container-details">
                                  <div className="event-details">
                                    <button className="event-name">
                                      <a href={`${rsvpevent.event.group && rsvpevent.event.group.url}/events/${rsvpevent.event.slug}`} target="_self">
                                        {rsvpevent.event.title}
                                      </a>
                                    </button>
                                    <span className="event-time">
                                      <Moment utc={true} format="h:mm A" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.start_time}</Moment> - {" "}
                                      <Moment utc={true} format="h:mm A z" tz={miscUtils.getUserTimezone()}>{rsvpevent.event.end_time}</Moment>
                                      {miscUtils.getReadableTimezoneOffset(miscUtils.getUserTimezone()) && ` (${miscUtils.getReadableTimezoneOffset(miscUtils.getUserTimezone())})`}
                                    </span>
                                  </div>
                                  <div className="fresh-event">
                                    <Moment
                                      utc
                                      fromNow
                                      tz={miscUtils.getUserTimezone()}
                                    >
                                      {rsvpevent.event.start_time}
                                    </Moment>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </>
                      :
                      <span className="empty-payload">No past rsvp'd events yet.</span>
                    }
                  </>

                }
              </>
              :
              <span className="empty-payload">No rsvp'd events yet.</span>
            }
          </>
      }
    </>
  );
};

export default UserEvents
