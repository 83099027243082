import React from "react";
import { IAuthenticationLayoutProps } from "./IAuthenticationLayoutProps";

import "./AuthenticationLayout.scss";
import "./AuthenticationLayoutResponsive.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { POWERED_BY_GROUAPP_LINK } from "constants/common";
import SEO from "components/group/seo";
import { ReactComponent as GroupApp } from "assets/img/group-app-logo.svg";

const AuthenticationLayout: React.FC<IAuthenticationLayoutProps> = props => {
  library.add(faAngleDown);

  return (
    <div className={`authentication-container`}>
      {props.group && (
        <SEO
        url={props.group.link}
        title={props.group.name}
        description={props.group.description}
        image={props.group.cover_image || ""}
        />
      )}

      <div className="card--auth">
        {props.group && (
          <div className="auth-group-info">
            {props.group.profile_image && (
              <img src={props.group.profile_image} alt="Group profile image" className="group-profile-image" />
            )}
            <h3 className="group-name">{props.group.name}</h3>
          </div>
        )}
        <>
          {props.children}
        </>
      </div>

      {/* Powered by GroupApp */}
      {props.group && props.group.powered_by_groupapp === 1 && (
        <a href={POWERED_BY_GROUAPP_LINK} target="_blank" className="powered-by-groupapp-a">
          <p className="powered-by-groupapp">Powered by</p> <GroupApp />
        </a>
      )}
    </div>
  )
};

export default withRouter(
  connect(null, null)(AuthenticationLayout as any) as any
) as any;
