import React from 'react';
import useComponentVisible from 'utils/visible/visible';
import './AppSelect.scss';
import { IAppSelectProps } from './IAppSelectProps';
import { ReactComponent as ArrowDownIcon } from './icons/arrowdown.svg';
import FlexyPopOnMe from 'elements/flexypoponme';

const AppSelect: React.FC<IAppSelectProps> = (props) => {
  const { disableValueClick = false } = props;

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  React.useEffect(() => {
    if (props.hideDropdownRef) {
      props.hideDropdownRef.current = () => setIsComponentVisible(false);
    }
  }, []);

  React.useEffect(() => {
    if (!isComponentVisible && props.onDropDownClosed) {
      props.onDropDownClosed();
    }
  }, [isComponentVisible, props.onDropDownClosed])

  const onFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  }

  const closeSelectPopUp = () => {
    setTimeout(() => {
      setIsComponentVisible(false);
    }, 100)
  }

  const childrenWithProps = React.Children.map(props.children, child => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      //@ts-ignore
      return React.cloneElement(child, { closeSelectPopUp, });
    }
    return child;
  });

  return (
    <div className="groupapp-select-container">
      <div className="groupapp-select-input-container">
        {props.label ? <p className="groupapp-select-input-label" style={props.labelStyles ? props.labelStyles : {}}>{props.label}{props.markRequired && (<span className="groupapp-select-input-label-required-asterisk">*</span>)}{props.extraLabelMarkup ? props.extraLabelMarkup : <></>}</p> : <></>}
        {props.valueElement ? (
          <div
            className={`groupapp-select-input-wrapper ${isComponentVisible ? 'highlighted' : ''} ${!props.label ? 'no-label' : ''}`}
            onChange={() => { }}
            onClick={() => {
              onFocus();
              if (props.disabled || disableValueClick || isComponentVisible) {
                return;
              }
              setIsComponentVisible(true);
              if(props.onDropDownOpened) {
                props.onDropDownOpened();
              }
            }}
          >{props.valueElement && props.valueElement}</div>
        ) : props.useTippy ? (
          <FlexyPopOnMe
          component={(
            <div
              className={`groupapp-select-popup--tippy ${!props.label ? 'no-label' : ''} ${props.tippyContainerClassName ? props.tippyContainerClassName : ''}`}
              style={props.popupStyles}
            >
              {childrenWithProps}
            </div>
          )}
          closeOnComponentClick
          placement={props.tippyPlacement ? props.tippyPlacement : undefined}
          >
               <input
                type="text"
                className={`groupapp-select-input-input ${!props.label ? 'no-label' : ''} ${props.noCaret ? 'no-caret' : ''
                  } ${props.cursorPointer ? 'cursor-pointer' : ''
                  } ${
                    props.inputClassName ? 
                    props.inputClassName : ''
                  }`}
                onClick={() => {
                  onFocus();

                  if (props.disabled) {
                    return;
                  }
                }}
                value={props.value}
                onChange={props.onChangeValue}
                placeholder={props.placeholder}
                disabled={props.disabled}
              />
          </FlexyPopOnMe>
        ) : (
          <input
            type="text"
            className={`groupapp-select-input-input ${!props.label ? 'no-label' : ''} ${props.noCaret ? 'no-caret' : ''
              } ${props.cursorPointer ? 'cursor-pointer' : ''
              } ${
                props.inputClassName ? 
                props.inputClassName : ''
              }`}
            onClick={() => {
              onFocus();

              if (props.disabled) {
                return;
              }
              setIsComponentVisible(true);
              if(props.onDropDownOpened) {
                props.onDropDownOpened();
              }
            }}
            value={props.value}
            onChange={props.onChangeValue}
            placeholder={props.placeholder}
            disabled={props.disabled}
            style={props.inputStyles ? props.inputStyles : undefined}
          />
        )}
        <span className={`groupapp-select-input-icon ${!props.label ? 'no-label' : ''} ${
          props.inputIconClassName ? props.inputIconClassName : ''
        }`}
          onClick={() => {
            onFocus();

            if (props.disabled) {
              return;
            }
            setIsComponentVisible(true);
            if(props.onDropDownOpened) {
              props.onDropDownOpened();
            }
          }}
        >
          <ArrowDownIcon />
        </span>
      </div>
      {isComponentVisible && (
        <div
          className={`groupapp-select-popup ${!props.label ? 'no-label' : ''}`}
          ref={ref}
          style={props.popupStyles}
        >
          {childrenWithProps}
        </div>
      )}
    </div>
  )
}

export default AppSelect;