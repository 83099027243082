import React from 'react';
import { ITextBlockPreviewProps } from './ITextBlockPreviewProps';
import anchorme from 'anchorme';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import BlockTextContainer, { BlockSubTextContainer } from '../BlockComponents/BlockTextContainer';

const TextBlockPreview: React.FC<ITextBlockPreviewProps> = (props) => {

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <BlockContentContainer
        className={`${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
          <BlockTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="heading"
          fallbackTextColor="#000000"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.heading),
          }}
          />

          <BlockSubTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="subtext"
          fallbackTextColor="#000000"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.text),
          }}
          />
        </BlockContentContainer>
    </BlockContainer>
  )
}

export default TextBlockPreview;