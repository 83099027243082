import React from 'react';

const Feed: React.FC<{strokeColor?: string}> = ({strokeColor}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 17.9999C1.45 17.9999 0.979002 17.8039 0.587002 17.4119C0.195002 17.0199 -0.000664969 16.5493 1.69779e-06 15.9999V1.99994C1.69779e-06 1.44994 0.196002 0.97894 0.588002 0.586941C0.980002 0.194941 1.45067 -0.000726004 2 -5.93374e-05H13L18 4.99994V15.9999C18 16.5499 17.804 17.0209 17.412 17.4129C17.02 17.8049 16.5493 18.0006 16 17.9999H2ZM2 15.9999H16V5.99994H12V1.99994H2V15.9999ZM4 13.9999H14V11.9999H4V13.9999ZM4 5.99994H9V3.99994H4V5.99994ZM4 9.99994H14V7.99994H4V9.99994Z" fill={strokeColor ? strokeColor : "#767A8A"}/>
    </svg>
  )
}

export default Feed