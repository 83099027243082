import React from 'react';
import anchorme from 'anchorme';
import { ButtonAction, VideoType, scrollElementIntoView } from 'containers/PageBuilder/SinglePageContainer/domain';
import BlockContainer from '../BlockComponents/BlockContainer';
import styled from 'styled-components';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import BlockTextContainer, { BlockSubTextContainer } from '../BlockComponents/BlockTextContainer';
import BlockButton from '../BlockComponents/BlockButton';
import { IVideoTextBlockPreviewProps } from './IVideoTextBlockPreviewProps';
import { ReactComponent as PlayIcon } from '../VideoBlockPreview/icons/play.svg';
import useWindowSize from 'utils/windowSize';
import BunnyVideoPlayer from 'components/BunnyVideoPlayer';
import YouTube from 'react-youtube';
import { getYouTubeVideoId } from 'containers/CoursesContainer/SectionsList/SectionItem/SectionItemContent/AdminCourseLesson/utis';
import Vimeo from '@u-wave/react-vimeo';
import { v4 as uuidv4 } from 'uuid';
import WistiaPlayer from 'components/WistiaPlayer';
import { LivePagePreviewView } from 'containers/PageBuilder/LivePagePreviewContainer/domain';

const opts = {
    playerVars: {
      rel: 0,
      autoplay: 0,
      cc_load_policy: 0,
      iv_load_policy: 3,
      modestbranding: 1,
    }
};

const VideoTextBlockVideoContainer: any = styled.div`
    width: 448px;
    min-width: 448px;
    height: 260px;

    &.mobile {
        width: 100%;
        min-width: 100%;
        height: 220px;
    }

    @media(max-width: 801px) {
        width: 100%;
        min-width: 100%;
        height: 220px;
    }
`;

const VideoTextBlockVideoUploadContainer: any = styled.div`
    width: 100%;
    height: 100%;

    .video-in-block-preview-yt-player-container {
        width: 100%;
        height: 100%;
    }

    .video-in-block-preview-yt-player {
        width: 100%;
        height: 100%;
    }

    .video-in-block-preview-vimeo-player {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        iframe,
        object,
        frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
`;

const VideoTextBlockVideoEmptyContainer: any = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D5D8DF;
`;

const VideoTextBlockVideoEmptyIconContainer: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const VideoTextBlockContentContainer: any = styled(BlockContentContainer)`
    flex-direction: row;
    align-items: center;

    &.right {
        flex-direction: row-reverse;
    }

    &.mobile {
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        &.right {
            flex-direction: column-reverse;
        }
    }

    @media(max-width: 801px) {
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        &.right {
            flex-direction: column-reverse;
        }
    }
`;

export const VideoTextBlockGutter: any = styled.div`
    min-width: 20px;

    &.mobile {
        min-height: 10px;
    }

    @media(max-width: 801px) {
        min-height: 10px;
    }
`;

export const VideoTextBlockTextContainer: any = styled.div<{design: any}>`
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props: any) => 
        props.design.text_alignment === 'left' ?
        `flex-start` :
        props.design.text_alignment === 'right' ?
        `flex-end` :
        props.design.text_alignment
    }

    &.mobile {
        padding: 0;
    }

    @media(max-width: 801px) {
        padding: 0;
    }
`;

const VideoTextBlockPreview: React.FC<IVideoTextBlockPreviewProps> = (props) => {

    const screenWidth = useWindowSize().width;

    const getVideoUploadMarkup = () => {
      if(props.blockData.video_type === VideoType.Uplaod) {
        return (
          <BunnyVideoPlayer
          videoUrl={props.blockData.video_url}
          />
        )
      } else {
        if(props.blockData.video_url.includes('you')) {
          return (
              <YouTube
              // @ts-ignore
              opts={opts}
              videoId={getYouTubeVideoId(props.blockData.video_url)}
              className="video-in-block-preview-yt-player"
              containerClassName="video-in-block-preview-yt-player-container"
              />
          )
        }
  
        if(props.blockData.video_url.includes('vimeo')) {
            return (
                <Vimeo
                key={uuidv4()}
                video={props.blockData.video_url}
                className="video-in-block-preview-vimeo-player"
                style={{
                    height: screenWidth <= 801 || props.view === LivePagePreviewView.Mobile ? '220px' : '260px',
                    marginTop: 0,
                    backgroundColor: '#333333',
                }}
                />
            )
        }
  
        if(props.blockData.video_url.includes('wistia')) {
            return (
                <WistiaPlayer
                videoId={props.blockData.video_url.split("/")[4]}
                />
            )
        }
  
        return <></>
      }
    }


    const ctaOnClick = () => {
        if(props.buildingPreview) {
            return;
        }

        if(
            props.blockData &&
            (
                props.blockData.button_action === ButtonAction.URL ||
                props.blockData.button_action === ButtonAction.URLTab
            ) &&
            props.blockData.button_url
        ) {
            window.open(
                props.blockData.button_url, 
                props.blockData.button_action === ButtonAction.URLTab ?
                '_blank' :
                '_self'
            );
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Scroll &&
            props.blockData.button_url &&
            props.containerBaseIdName
        ) {
            scrollElementIntoView(`${props.containerBaseIdName}${props.blockData.button_url}`);
            return;
        }

        if(
            props.blockData &&
            props.blockData.button_action === ButtonAction.Popup &&
            props.blockData.button_url
        ) {
            let nextPageFormsOpenState = JSON.parse(JSON.stringify(props.pageFormsOpenState));

            nextPageFormsOpenState[props.blockData.button_url] = true;

            props.setPageFormsOpenState(nextPageFormsOpenState);
        }
    }

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <VideoTextBlockContentContainer
        className={`${props.blockData.video_layout} ${props.view ? props.view : ''}`}
        design={props.blockData.design}
        >
            <VideoTextBlockVideoContainer
            className={`${props.view ? props.view : ''}`}
            >
            {props.blockData.video_url ? (
                <>
                <VideoTextBlockVideoUploadContainer
                >
                {getVideoUploadMarkup()}
                </VideoTextBlockVideoUploadContainer>
                </>
            ) : 
                <VideoTextBlockVideoEmptyContainer
                >
                <VideoTextBlockVideoEmptyIconContainer
                >
                    <PlayIcon
                    />
                </VideoTextBlockVideoEmptyIconContainer>
                </VideoTextBlockVideoEmptyContainer>
            }
            </VideoTextBlockVideoContainer>

            <VideoTextBlockGutter
            className={`${props.view ? props.view : ''}`}
            />

            <VideoTextBlockTextContainer
            className={`${props.blockData.video_layout} ${props.view ? props.view : ''}`}
            design={props.blockData.design}
            >
                <BlockTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="heading"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.heading),
                }}
                />

                <BlockSubTextContainer
                design={props.blockData.design}
                pageStyles={props.pageStyles}
                textKey="subtext"
                dangerouslySetInnerHTML={{
                    __html: anchorme(props.blockData.subtext),
                }}
                />

                {props.blockData.button_shown ? (
                    <BlockButton
                    design={props.blockData.design}
                    pageStyles={props.pageStyles}
                    onClick={ctaOnClick}
                    >
                        {props.blockData.button_text}
                    </BlockButton>
                ) : <></>}
            </VideoTextBlockTextContainer>
        </VideoTextBlockContentContainer>
    </BlockContainer>
  )
}

export default VideoTextBlockPreview;