import React from 'react';
import anchorme from 'anchorme';
import { IHeaderBlockPreviewProps } from './IHeaderBlockPreviewProps';
import BlockContainer from '../BlockComponents/BlockContainer';
import BlockContentContainer from '../BlockComponents/BlockContentContainer';
import BlockTextContainer from '../BlockComponents/BlockTextContainer';

const HeaderBlockPreview: React.FC<IHeaderBlockPreviewProps> = (props) => {

  return (
    <BlockContainer
    design={props.blockData.design}
    >
        <BlockContentContainer
        design={props.blockData.design}
        className={`${props.view ? props.view : ''}`}
        >
          <BlockTextContainer
          design={props.blockData.design}
          pageStyles={props.pageStyles}
          textKey="heading"
          fallbackTextColor="#000000"
          dangerouslySetInnerHTML={{
              __html: anchorme(props.blockData.heading),
          }}
          />
        </BlockContentContainer>
    </BlockContainer>
  )
}

export default HeaderBlockPreview;