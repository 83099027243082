import React from 'react'
import ContentLoader from 'react-content-loader'

interface IListsLoaderProps {
  padding?: number;
  totalItems?: number;
  width?: number;
  height?: number;
  showThumbnail?: boolean;
  thumbnailHeight?: number;
  styles?: any;
}

const PostsFeedLoader: React.FC<IListsLoaderProps> = props => {
  const { padding = 10, totalItems = 5, width = 400, height = 150, showThumbnail = false, thumbnailHeight = 80, styles = { background: "#FFFFFF" } } = props;

  const getLoaders = () => {
    const loadersList = [];
    for (let i = 0; i < totalItems; i++) {
      const iamgeHeight = showThumbnail ? thumbnailHeight : 0;

      loadersList.push(
        <div style={{ ...styles, marginBottom: "1.5rem", border: `1px solid #d5d8df` }} key={i}>
          <ContentLoader
            viewBox={`0 0 ${width} ${height + iamgeHeight}`}
            width={width}
            height={iamgeHeight + height}
            primaryColor={"#dbdbdb"}
            secondaryColor="#ecebeb"
            {...props}
            key={i}
          >
            <>
              {/* Thumbnail  */}
              <rect x="0" y={0} width="399" height={iamgeHeight} rx="3" />

              <circle cx="20" cy={20 + iamgeHeight} r="15" />
              <rect x="42" y={15 + iamgeHeight} width="88" height="6" rx="3" />

              <rect x="10" y={46 + iamgeHeight} width="150" height="12" rx="3" />

              <rect x="10" y={80 + iamgeHeight} width="380" height="6" rx="3" />
              <rect x="10" y={96 + iamgeHeight} width="350" height="6" rx="3" />
              <rect x="10" y={111 + iamgeHeight} width="178" height="6" rx="3" />
            </>
          </ContentLoader>
        </div>
      )
    }
    return loadersList;
  }

  return (
    <div style={{ width: "100%", padding: `${padding}px`, height: "100%", maxHeight: "100vh", borderRadius: "6px", overflow: "hidden" }}>
      {getLoaders()}
    </div>
  )

}

export default PostsFeedLoader