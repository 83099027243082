import React, { useState } from 'react'

import './custom-user.scss'
import miscUtils from 'utils/miscUtils'
import { UserService } from 'api/services/users'
import MiniProfile from 'components/userComponent/MiniProfile'
import { RoleIds } from 'containers/teamsContainer/domain'
import { activeMemberIDAtom, memberDetailsProfilePopupAtom } from 'showMessageMenuAtom'
import { useAtom } from 'jotai'

const CustomUserComponent = ({ user, group, rightPlacement = true, isHost = undefined }: { user: any, group: any, rightPlacement?: boolean, isHost?: any }) => {
    const [miniProfile, setShowMiniProfile] = useState(false)
    const [isfetching, setIsFetching] = useState<boolean>(false)
    const [userDetails, setUserDetails] = useState<any>()

    const [, setActiveMemberID] = useAtom(activeMemberIDAtom);

    const memberID = user && user.user.id

    const OpenMiniProfile = () => {
        if (rightPlacement) {
            fetchMemberDetails()
            setShowMiniProfile(true)
        }
    }

    const fetchMemberDetails = () => {
        if (isfetching) return

        setIsFetching(true)
        UserService.getInstance()
            .getUserMembership(group && group.id, memberID)
            .then((res: any) => { if (res) setUserDetails(res) })
            .finally(() => setIsFetching(false))
    }

    const determinePrivilege: () => "Owner" | "Admin" | "Moderator" | 'Member' = () => {
        if (isHost) {
            return isHost;
        } else {
            switch (user && user.role_id) {
                case RoleIds.Owner:
                    return "Owner";
                case RoleIds.Admin:
                    return "Admin";
                case RoleIds.Moderator:
                    return "Moderator";
                default:
                    return "Member";
            }
        }
    }

    const [, setOpenmemberDetailsProfilePopup] = useAtom(memberDetailsProfilePopupAtom);

    const openPeopleDialog = () => {
        // const el = document.getElementById(`people-details-dialog`) as HTMLDialogElement
        // if (el) {
        //   el.showModal();
        //   // disableScroll()
        // }
        setOpenmemberDetailsProfilePopup(true)
    }


    const openProfileSlider = () => {
        setActiveMemberID(memberID)
        openPeopleDialog()
    }


    return (
        <div className='custom-user'>
            <div
                className='header'
                onMouseEnter={() => { OpenMiniProfile() }}
                onMouseLeave={() => setShowMiniProfile(false)}
            >
                {
                    user.user.profile_image
                        ?
                        <div className='member-profile-img'>
                            <img
                                className={`member-profile-img`}
                                alt='user profile'
                                src={user.user.profile_image}
                            />
                        </div>
                        :
                        <div
                            className={`member-profile-img`}
                            data-color={miscUtils.getColorInitial(`${user.user.full_name}`)}

                        >
                            {miscUtils.getNameInitials(user.user.full_name)}
                        </div>
                }
            </div>

            <div className='right'>
                <span
                    className='name'
                    onClick={() => OpenMiniProfile()
                    }
                >
                    {user && user.user.full_name}
                </span>
                {
                    !isHost &&
                <span className='email'>{user && user.user.email}</span>
                }
                {
                    isHost && <span className='email'>{isHost}</span>
                }
            </div>

            {
                miniProfile &&
                <MiniProfile
                    fullName={user && user.user.full_name}
                    setShowMiniProfile={setShowMiniProfile}
                    ProfileImage={user && user.user.profile_image}
                    determinePrivilege={determinePrivilege}
                    openProfileSlider={openProfileSlider}
                    userDetails={userDetails}
                    user={user && user.user}
                    isUserAuthed
                />
            }
        </div>
    )
}

export default CustomUserComponent