import React from 'react';
import './DeleteConfirmation.scss';
import { IDeleteConfirmationProps } from './IDeleteConfirmationProps';
import ReactModal from 'react-modal';
// import { ReactComponent as TrashIcon } from './icons/trash.svg';
import { ReactComponent as XIcon } from './icons/x.svg';

const DeleteConfirmation: React.FC<IDeleteConfirmationProps> = (props) => {
  const { deleteBtnLabel = "Delete" } = props;

  return (
    <ReactModal
      isOpen={props.isOpen}
      style={{
        content: {
          width: "450px",
          maxWidth: "calc(100vw - 10px)",
          minHeight: props.minHeight ? `${props.minHeight}px` : "250px",
          borderRadius: "4px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          position: "fixed",
          background: "white",
          border: 0,
          padding: 0,
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 99999,
        }
      }}
      onRequestClose={(e: any) => {
        e.stopPropagation();
        props.onClose();
      }}
    >
      <div className="collections-delete-confirmation-container">
        <span
          className="collections-delete-confirmation-x"
          onClick={(e: any) => {
            e.stopPropagation();
            props.onClose();
          }}
        >
          <XIcon />
        </span>
        {/* <span className="collections-delete-confirmation-trash">
                <TrashIcon />
            </span> */}
        <div className='collections-delete-confirmation-modal-content'>
          <h1 className="collections-delete-confirmation-title">{props.headline ? props.headline : 'Are you sure?'}</h1>
          <p className="collections-delete-confirmation-text">
            {props.message ? (
              <>{props.message}</>
            ) : (
              <>
                You're about to delete <span>{props.deletable && props.deletableNameKey ? props.deletable[props.deletableNameKey] : ''}</span>
                <span style={{ fontWeight: "normal" }}>{props.deletableResource ? ` ${props.deletableResource}.` : ""}</span>
              </>
            )}

          </p>
          {!props.hideNoUndoWaring ? <p className="collections-delete-confirmation-text">This action can't be undone.</p> : <></>}
        </div>

        <div className="collections-delete-confirmation-actions-container">
          <button
            className="collections-delete-confirmation-actions-cancel"
            onClick={(e: any) => {
              e.stopPropagation();
              props.onClose();
            }}
          >Cancel</button>
          <button
            className="collections-delete-confirmation-actions-delete"
            onClick={(e: any) => {
              e.stopPropagation();
              props.onConfirm();
            }}
          >{deleteBtnLabel}</button>
        </div>
      </div>
    </ReactModal>
  )
}

export default DeleteConfirmation;