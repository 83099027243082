import { atom } from 'jotai';

export const groupMenuMessagesShowAtom = atom();
export const showMessagePopUpAtom = atom(false);
export const showActiveCHatAtom = atom(undefined);
export const showNewDirectMessageMenuAtom = atom(false);
export const showNewGroupMessageMenuAtom = atom(false);
export const disableActiveChatSelectionAtom = atom(false);
export const chatsAtom = atom([]);
export const chatsLoadingAtom = atom(true);
export const chatsIsUpdatedAtom = atom(false);
export const showNewMessageStartupAtom = atom(false);

// refetch react query
export const refectSectionAtom = atom(false);
export const TopBarPopUpsAtom = atom({
    message: false,
    notification: false
});

// disable view profile on analytics
export const IsCOurseAnalyticsPageAtom = atom(false);

export const FeaturedPostAddedAtom = atom(false);
export const activeSubReloadAtom = atom(false);
export const eventCalledAtom = atom(false);

export const groupDataLoadingAtom = atom(false);

export const resourceLinkLoadingAtom = atom(false);
export const activeDigestEmailItemAtom = atom('');
export const activeUpcomingEventEmailItemAtom = atom('');

export const newContentEmailItemAtom = atom('');
export const courseSectionContentIsCreatedAtom = atom(false);
export const firstSectionCreationAtom = atom(false);
export const activeMemberIDAtom = atom(undefined);

// profile pop up
export const memberDetailsProfilePopupAtom = atom(false);

// file upload progress
export const postFileIsUploadingAtom = atom(false);

// add members to segment pop up
export const AddableSegmentMembers = atom({
    isOpen: false,
    segmentId: undefined,
    segmentName: undefined
})

// add member to channel pop up
export const AddableChannelMemberAtom = atom({
    open: false,
    channelId: undefined
});

// add member to teammates pop up
export const AddableTeamMateAtom = atom(false);

// height of the right side menu list
export const settingsMenuContainerHeightAtom = atom();

// Pricing Header styling 
export const resourceSearchStylingAtom = atom('');

// Purchasers Search styling
export const purchasersStylingAtom = atom('');

// scrollToComment
export const scrollToCommentAtom = atom(undefined);

// add unaothorized users lib/course
export const unAuthorizedUsersAtom = atom({
    open: false,
    contentId: undefined,
    contentName: undefined
});

// universal search data 
export const universalSearchResultsAtom = atom();