import React, { useEffect, useState } from 'react'

import './comm-wel-screen.scss'
import DraftContent from 'components/group/groupPost/DraftContent'
import YouTube from 'react-youtube'
import Vimeo from '@u-wave/react-vimeo'
import WistiaPlayer from 'components/WistiaPlayer'
import BunnyVideoPlayer from 'components/BunnyVideoPlayer'
import ContentLoader from 'react-content-loader'

const CommunityWelcomeScreen = (
    {
        welcomeScreenData
    }:
        {
            welcomeScreenData: any
        }
) => {
    const opts = {
        playerVars: {
            rel: 0,
            autoplay: 0,
            cc_load_policy: 0,
            iv_load_policy: 3,
            modestbranding: 1
        }
    };

    const [videoType, setVideoType] = useState<any>(null)
    const [videoId, setVideoId] = useState<any>()

    useEffect(() => {
        if (welcomeScreenData && welcomeScreenData.video_url && welcomeScreenData.video_url.includes('http')) {
            if (welcomeScreenData.video_url.includes("you")) {
                setVideoType("youtube");
                let urlParse;
                const videoURL = welcomeScreenData.video_url.split("/");
                videoURL.map((part: any, index: any) => {
                    if (part.includes("watch")) {
                        urlParse = part.split("=")[1];
                        setVideoId(urlParse);
                    } else {
                        if (index === 3) {
                            setVideoId(part);
                        }
                    }
                    return null;
                });
            }

            if (welcomeScreenData.video_url.includes("vimeo")) {
                setVideoType("vimeo");
                setVideoId(welcomeScreenData.video_url);
            }
            if (welcomeScreenData.video_url.includes("wistia")) {
                setVideoType("wistia");
                const videoURL = welcomeScreenData.video_url.split("/");
                setVideoId(videoURL[4]);
            }

            if (welcomeScreenData.video_url.includes("bunnycdn")) {
                setVideoType("bunny");
                setVideoId(welcomeScreenData.video_url);
            }
        }
    }, [welcomeScreenData]);

    return (
        <div className='comm-welcome'>
            {welcomeScreenData === undefined
                ?
                <ContentLoader
                    height={44}
                    width={620}
                    speed={2}
                    primaryColor="#d9d9d9"
                    secondaryColor="#ecebeb"
                >
                    <rect x={0} y="0" rx="6" ry="6" width={600} height={window.innerWidth <= 550 ? 44 : 24} />
                </ContentLoader>
                :
                <h1 className='comm-welcome-title'>{welcomeScreenData.title}</h1>
            }

            <div className='comm-welcome-desc'>
                {
                    welcomeScreenData === undefined
                        ?
                        <>
                            {Array(6).fill('').map((_e, i) => (
                                <ContentLoader
                                    height={40}
                                    width={680}
                                    speed={2}
                                    primaryColor="#d9d9d9"
                                    secondaryColor="#ecebeb"
                                >
                                    <rect x={80} y={window.innerWidth <= 550 ? 24 : 0} rx="6" ry="6" width={480} height={window.innerWidth <= 550 ? 44 : 24} />
                                </ContentLoader>
                            ))}
                        </>
                        :
                        <DraftContent
                            post={{
                                id: welcomeScreenData.id,
                                description: welcomeScreenData.description,
                                description_json: welcomeScreenData.description_json
                            }}
                            disableCharacterLimit
                        />
                }

                {
                    welcomeScreenData === undefined
                        ?
                        <ContentLoader
                            height={265}
                            width={680}
                            speed={2}
                            primaryColor="#d9d9d9"
                            secondaryColor="#ecebeb"
                        >
                            <rect x={0} y={window.innerWidth <= 550 ? 34 : 0} rx="6" ry="6" width={680} height={250} />
                        </ContentLoader>
                        :
                        <>
                            {
                                welcomeScreenData.video_url &&
                                <div className='comm-welcome-desc-video'>
                                    {videoType === "youtube" && (
                                        <YouTube
                                            // @ts-ignore
                                            opts={opts}
                                            videoId={videoId}
                                            className={"yt-player"}
                                            containerClassName={`yt-player-container`}
                                        />
                                    )}
                                    {videoType === "vimeo" && (
                                        <Vimeo
                                            video={videoId}
                                            className={`vimeo-player`}
                                        />
                                    )}
                                    {videoType === "wistia" && (
                                        <WistiaPlayer videoId={videoId} />
                                    )}

                                    {videoType === "bunny" && (
                                        <BunnyVideoPlayer videoUrl={videoId} />
                                    )}
                                </div>
                            }
                        </>
                }
            </div>

        </div>
    )
}

export default CommunityWelcomeScreen