import React from 'react';
import './answerable-quiz.scss';
import SingleQuestion from './SingleQuestion/SingleQuestion';


interface IAnswerableQuestionsProps {
  membershipQuestions: Array<any>;
  responseState: any;
  setResponseState: any;
  isMakingRequest?: boolean;
  onComplete?: any;
  group: any
}
const QuestionsTypesFields: React.FC<IAnswerableQuestionsProps> = (props) => {

  if (!props.membershipQuestions) {
    return <></>
  }

  return (
    <div className='community-questions-container'>
      <div className='community-questions-header'>
        <h1>Tell us more</h1>
        <span>Please answer the questions below.</span>
      </div>

      <ol className="community-questions-content-container">
        {props.membershipQuestions.map((membershipQuestion: any, index: number) => (
          <li key={index}>
            <SingleQuestion
              membershipQuestion={membershipQuestion}
              questionIndex={index}
              key={membershipQuestion.id}
              responseState={props.responseState}
              setResponseState={props.setResponseState}
              group={props.group}
            />
          </li>
        ))}
      </ol>
      <div className={`community-questions-footer-container`}>
        <button
          id='complete-questions-button'
          className={`community-questions-footer-complete ${props.isMakingRequest ? "community-questions-footer-complete--preview" : ""}`}
          disabled={props.isMakingRequest}
          onClick={() => {
            if (props.onComplete) {
              props.onComplete();
            }
          }}
        >
          {props.isMakingRequest ? 'Saving...' : 'Complete'}
        </button>
      </div>
    </div>
  )
}

export default QuestionsTypesFields