import React, { useEffect, useState } from 'react'

import './steps.scss'
import ReactModal from 'react-modal'
import CommunityWelcomeScreen from './CommunityWelcomeScreen/CommunityWelcomeScreen'
import { GroupService } from 'api/services/groups'
import CommunityIntroduction from './CommunityIntroduction/CommunityIntroduction'
import { PostService } from 'api/services/post'
import { toast, ToastType } from 'react-toastify'
import ProfileDetails from './ProfileDetails/ProfileDetails'
import { UserService } from 'api/services/users'
import miscUtils from 'utils/miscUtils'
import imageCompression from 'browser-image-compression'
import CustomQuestions from './CommunityMembershipQuestions/CustomQuestions/CustomQuestions'
import { QuestionTypes } from 'containers/Onboarding/MembershipQuestions/QuestionMaker/domain'

export const CommunityOnboardingStepsContext = React.createContext<any>({});

const CommunityOnboardingSteps = (
    {
        group,
        isOpen,
        setIsOpen,
        userId,
        requiredDefaultKeys,
        getCommunityQuery,
        setRequiredDefaultKeys,
        memberData
    }
        :
        {
            group: any
            isOpen: boolean
            setIsOpen: (arg0: boolean) => void
            userId: any
            requiredDefaultKeys: any
            getCommunityQuery: any
            setRequiredDefaultKeys: any
            memberData: any
        }
) => {
    const onboardingStepsList = miscUtils.onboardingPopUpShouldOpen(group, userId, requiredDefaultKeys)

    const [welcomeScreenData, setwelcomeScreenData] = React.useState<any>()
    const [introData, setIntroData] = React.useState<any>()
    const [stepsCovered, setStepsCovered] = useState<any>(1);
    const [postData, setpostData] = useState<any>(null)
    const [communityFieldsData, setCommunityFieldsData] = useState<any>(null)
    const [profileData, setProfileData] = useState<any>()
    const [profileFile, setProfileFile] = useState<any>()
    const [requiredKeys, setRequiredKeys] = useState<any>([])
    const [requiredCustomDefaultdata, setRequiredCustomDefaultdata] = useState<any>()
    const [checkedCustomRequiredFields, setCheckedCustomRequiredFields] = useState<boolean>(true)
    const [isPosting, setIsPosting] = useState<boolean>(false)
    const [isFirstStepIndex, setisFirstStepIndex] = useState<any>(0)
    const [currentSection, setCurrentSection] = useState<any>(onboardingStepsList[0])
    const prevNextSectionToGoToRef: any = React.useRef<any>();
    const [userData, setUserData] = useState<any>(memberData)
    const [profileNewSrc, setProfileNewSrc] = useState<any>(null)
    const [membershipQuestions, setMembershipQuestions] = useState<any>();
    const [responseState, setResponseState] = React.useState<Array<any>>([]);

    const [BEonboardingcompletedSteps, setBEonboardingcompletedSteps] = useState<any>(
        group &&
        group.user_membership &&
        group.user_membership.onboarding_completed
    )
    const popupRef = React.useRef<any>(null);

    const existingMemberAnswer = group && group.user_membership && group.user_membership.screening_response

    useEffect(() => {
        setUserData(memberData)
    }, [memberData])

    React.useEffect(() => {

        if (membershipQuestions) {
            setResponseState(membershipQuestions.map((membershipQuestion: any) => {
                const matchingScreeningQuestion = existingMemberAnswer && existingMemberAnswer.find(
                    (screenQuestion: any) => (screenQuestion.type === membershipQuestion.type) &&
                        (screenQuestion.question === membershipQuestion.name)
                );

                return {
                    question: membershipQuestion.name,
                    type: membershipQuestion.type,
                    answer: matchingScreeningQuestion ? matchingScreeningQuestion.answer :
                        (membershipQuestion.type === QuestionTypes.Checkbox ? [] : ""),
                };
            }));
        }
    }, [membershipQuestions]);

    const createPost = () => {
        const postBuilderSubmitButton: HTMLElement = document.getElementById("groupapp-post-builder-submit-button") as HTMLElement;

        if (postBuilderSubmitButton) {
            postBuilderSubmitButton.click();
            setBEonboardingcompletedSteps((prev: any) => ({
                ...prev,
                introduction: true,
            }));
        }
    }

    // utils
    function capitalizeFirstLetter(str: any) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const requiredKeyFinder = (keyToFind: string) => {
        const El = document.getElementById(keyToFind);
        if (El) {
            return (
                El.hasAttribute("required") &&
                profileData &&
                (
                    profileData.hasOwnProperty(keyToFind) &&
                    profileData[keyToFind] === ''
                )
            )
        }
    }

    function getCommunityFieldsData() {
        if (group && userId && onboardingStepsList.length > 0) {
            GroupService.getInstance()
                .getCommunityFields(group && group.id)
                .then((response: any) => {
                    if (response) setCommunityFieldsData(response.data)
                })
        }
    }

    function fetchWelcomeScreenData() {
        if (group && userId && onboardingStepsList.length > 0 && group.user_membership) {
            GroupService.getInstance()
                .getWelcomeScreen(group && group.id)
                .then((response: any) => {
                    if (response) setwelcomeScreenData(response.data)
                });
        }
    }

    function fetchIntroductions() {
        if (group && userId && onboardingStepsList.length > 0 && group.user_membership) {
            GroupService.getInstance()
                .getIntroductions(group && group.id)
                .then((response: any) => {
                    if (response) setIntroData(response.data)
                });
        }
    }

    // Function to go to the previous step
    const goPreviousSection = () => {

        // Scroll to the top of the pop-up
        if (popupRef.current) popupRef.current.scrollTop = 0;

        const currentIndex = onboardingStepsList.indexOf(prevNextSectionToGoToRef.current);
        const previousStep = onboardingStepsList[currentIndex - 1];
        setCurrentSection(previousStep)
        setisFirstStepIndex(currentIndex - 1)
        setStepsCovered((prev: any) => prev - 1)
    };

    const newMemberCheck = miscUtils.newMemberCheck(userId, group)

    const goToNextSection = async () => {

        // Scroll to the top of the pop-up
        if (popupRef.current) popupRef.current.scrollTop = 0;

        const currentIndex = onboardingStepsList.indexOf(currentSection);

        if (currentIndex === onboardingStepsList.length - 1) {
            // because the state is not update in time for intro to be true
            if (onboardingStepsList.length === 1 && onboardingStepsList[0] === 'introduction') {
                updateMemberOnboardingStepsData({
                    ...BEonboardingcompletedSteps,
                    introduction: true,
                })
                if (!newMemberCheck) {
                    getCommunityQuery.refetch()
                    setRequiredDefaultKeys(undefined)
                }
                setIsOpen(false)
                return
            }

            if (onboardingStepsList.length === 1 && onboardingStepsList[0] === 'custom_fields') {
                updateMemberOnboardingStepsData({
                    ...BEonboardingcompletedSteps,
                    custom_fields: true,
                });
                setBEonboardingcompletedSteps((prev: any) => ({
                    ...prev,
                    custom_fields: true,
                }));
                setIsOpen(false)
                if (!newMemberCheck) {
                    getCommunityQuery.refetch()
                    setRequiredDefaultKeys(undefined)
                }
                return;
            }

            if (onboardingStepsList.length === 1 && onboardingStepsList[0] === 'default_fields') {
                updateMemberOnboardingStepsData({
                    ...BEonboardingcompletedSteps,
                    default_fields: true,
                });
                saveProfileDetails()
                setIsOpen(false)
                return;
            }

            if (onboardingStepsList.length === 1 && onboardingStepsList[0] === 'welcome') {
                updateMemberOnboardingStepsData({
                    ...BEonboardingcompletedSteps,
                    welcome: true,
                });
                setIsOpen(false)
                if (!newMemberCheck) {
                    getCommunityQuery.refetch()
                    setRequiredDefaultKeys(undefined)
                }
                return;
            }

            if (onboardingStepsList.includes('default_fields')) {
                await saveProfileDetails()
            }

            await updateMemberOnboardingStepsData({
                ...BEonboardingcompletedSteps,
            })
            setIsOpen(false)
            if (!newMemberCheck) {
                if (!profileFile) {
                    setTimeout(() => {
                        getCommunityQuery.refetch()
                        setRequiredDefaultKeys(undefined)

                    }, 1000);
                }
            }
            return;
        }

        const nextStep = onboardingStepsList[currentIndex + 1];
        setCurrentSection(nextStep)
        setisFirstStepIndex(currentIndex + 1)
        setStepsCovered((prev: any) => prev + 1)
    };

    useEffect(() => {
        if (currentSection === 'default_fields') {
            setBEonboardingcompletedSteps((prev: any) => ({
                ...prev,
                default_fields: true,
            }));
        }
        if (currentSection === 'introduction') {
            setBEonboardingcompletedSteps((prev: any) => ({
                ...prev,
                introduction: true,
            }));
        }
        if (currentSection === 'custom_fields') {
            setBEonboardingcompletedSteps((prev: any) => ({
                ...prev,
                custom_fields: true,
            }));
        }
        if (currentSection === 'welcome') {
            setBEonboardingcompletedSteps((prev: any) => ({
                ...prev,
                welcome: true,
            }));
        }
    }, [currentSection])


    const determineNextButtonText = () => {
        const currentIndex = onboardingStepsList.indexOf(currentSection);

        const currentStep = onboardingStepsList[currentIndex];
        if (currentSection === 'introduction') {
            if (isPosting) {
                return 'Posting...'
            }
            return 'Post'
        }
        if (currentIndex === onboardingStepsList.length - 1) {
            if (currentStep === 'custom_fields') {
                return 'Complete'
            }
            return 'Finish'
        }

        return 'Continue'
    }

    //TODO
    const updateMemberOnboardingStepsData = async (stepsCompleted: any) => {
        if (group) {
            GroupService.getInstance().updateOnboardingSteps(
                group.id,
                group.user_membership.id,
                {
                    onboarding_completed: stepsCompleted
                }
            ).finally(() => {
                if (newMemberCheck) {
                    getCommunityQuery.refetch()
                }
            })
        }
    }

    const profileImgToBeSet = communityFieldsData &&
        communityFieldsData.filter((category: any) => (
            category.type === 'image' &&
            // category.ask_during_sign_up &&
            category.category === 'default' &&
            category.required === true
        ))
    const hasprofileImgToBeSet = Boolean(profileImgToBeSet && profileImgToBeSet.length > 0);

    function validateProfileFields() {
        if ((profileNewSrc === undefined || profileNewSrc === null) && hasprofileImgToBeSet) {
            toast(`Profile image is required`, {
                type: ToastType.ERROR,
                autoClose: 3000
            });
            return
        }
        if (requiredKeys.length > 0) {
            if ((!profileData || Object.keys(profileData).length === 0) &&
                requiredKeys.filter((key: any) => key !== 'profile-picture').length > 0
            ) {
                toast(`Please fill out all the required fields`, {
                    type: ToastType.ERROR,
                    autoClose: 3000
                });
                return false
            }

            if (profileData) {
                const missingKeys = requiredKeys.filter((key: any) => key !== 'profile-picture'
                    && (!profileData.hasOwnProperty(key) || profileData[key] === ''));

                if (missingKeys.length > 0) {
                    if (missingKeys[0] === 'location') {
                        toast(`City is required`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeys[0] === 'timezone_id') {
                        toast(`Timezone is required`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeys[0] === 'country_id') {
                        toast(`Country is required`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeys[0] === 'about_me') {
                        toast(`Bio is required`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeys[0] === 'job_title') {
                        toast(`Job title is required`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    toast(`${capitalizeFirstLetter(missingKeys[0])} is required`, {
                        type: ToastType.ERROR,
                        autoClose: 3000
                    });
                    return false
                }
            }

            if (profileData) {

                const missingKeyWithoutProfile = requiredKeys.filter((key: any) => key !== 'profile-picture');
                const missingKeysK = missingKeyWithoutProfile.filter((key: any) => {
                    const value = profileData[key];
                    const isValueMissingOrEmpty = !value || !String(value).trim();
                    return isValueMissingOrEmpty;
                });
                if (missingKeysK.length > 0) {
                    if (missingKeysK[0] === 'location') {
                        toast(`City is empty`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeysK[0] === 'timezone_id') {
                        toast(`Timezone is empty`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeysK[0] === 'country_id') {
                        toast(`Country is empty`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeysK[0] === 'about_me') {
                        toast(`Bio is empty`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    if (missingKeysK[0] === 'job_title') {
                        toast(`Job title is empty`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                    toast(`${missingKeysK[0]} is empty`, {
                        type: ToastType.ERROR,
                        autoClose: 3000
                    });
                    return false;
                }

            }

            if (profileData) {
                // Regular expressions for matching different social media URL formats
                const regexMap = {
                    instagram: /^https:\/\/.*instagram/i,
                    linkedin: /^https:\/\/.*linkedin/i,
                    facebook: /^https:\/\/.*facebook/i,
                    twitter: /^https:\/\/.*twitter/i,
                };


                if (profileData.instagram) {
                    if (!regexMap['instagram'].test(profileData.instagram)) {
                        toast(`Invalid Instagram link`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                }
                if (profileData.linkedin) {
                    if (!regexMap['linkedin'].test(profileData.linkedin)) {
                        toast(`Invalid LinkedIn link`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                }
                if (profileData.facebook) {
                    if (!regexMap['facebook'].test(profileData.facebook)) {
                        toast(`Invalid Facebook link`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                }
                if (profileData.twitter) {
                    if (!regexMap['twitter'].test(profileData.twitter)) {
                        toast(`Invalid Twitter link`, {
                            type: ToastType.ERROR,
                            autoClose: 3000
                        });
                        return false
                    }
                }
            }

            return true
        }
        else {
            setBEonboardingcompletedSteps((prev: any) => ({
                ...prev,
                default_fields: true,
            }));
            return true
        }
    }

    const saveProfileDetails = async () => {
        const editPromises: any[] = [];
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
        if (profileFile) {
            imageCompression(profileFile, options).then(function (
                compressedFile: any
            ) {
                const dataImage = new FormData();
                dataImage.append(
                    "profile_image",
                    miscUtils.blobToFile(compressedFile, profileFile)
                );
                editPromises.push(
                    UserService.getInstance()
                        .updateUserProfileImage(userId, dataImage)
                        .finally(() => {
                                if (!newMemberCheck) {
                                    getCommunityQuery.refetch()
                                    setRequiredDefaultKeys(undefined)
                                }
                        })
                );
            });
        }
        editPromises.push(
            UserService.getInstance()
                .updateUserProfile(userId, profileData)
                .then((res: any) => {
                })
        );


        Promise.all(editPromises).then(() => {
            toast("Profile details saved successfully", {
                type: ToastType.SUCCESS,
                autoClose: 3000
            });

        }).finally(() => {
            // to counter the profile image request running at the same time as the group data refresh request
            if (onboardingStepsList.length === 1 &&
                onboardingStepsList[0] === 'default_fields' &&
                !profileFile
            ) {
                if (!newMemberCheck) {
                    getCommunityQuery.refetch()
                    setRequiredDefaultKeys(undefined)
                }
            }
        })
    };

    useEffect(() => {
        prevNextSectionToGoToRef.current = currentSection;
    });

    useEffect(() => {
        if (
            group.onboarding_steps &&
            (
                group.onboarding_steps.default_fields ||
                group.onboarding_steps.required_default_fields ||
                group.onboarding_steps.custom_fields ||
                group.onboarding_steps.required_custom_fields
            )
        ) {
            getCommunityFieldsData()
        }
        if (
            group.onboarding_steps &&
            (
                group.onboarding_steps.welcome ||
                group.onboarding_steps.required_welcome
            )
        ) {

            fetchWelcomeScreenData()
        }
        if (
            group.onboarding_steps &&
            (
                group.onboarding_steps.introduction ||
                group.onboarding_steps.required_introduction
            )
        ) {
            fetchIntroductions()
        }
    }, [group, userId])

    useEffect(() => {
        if (isOpen &&
            group &&
            group.user_membership &&
            group.user_membership.onboarding_completed === null
        ) {
            setBEonboardingcompletedSteps(
                {
                    welcome: false,
                    introduction: false,
                    default_fields: false,
                    custom_fields: false
                }
            )
        }
    }, [group, isOpen])

    useEffect(() => {
        setBEonboardingcompletedSteps(
            group &&
            group.user_membership &&
            group.user_membership.onboarding_completed)
    }, [group])

    useEffect(() => {
        if (communityFieldsData) {
            setRequiredCustomDefaultdata(
                communityFieldsData.filter((category: any) => (
                    category.required === true
                )))

            const customQuestions = communityFieldsData
                .filter((newData: any) => (
                    newData.category === 'custom' &&
                    newData.ask_during_sign_up === true
                ))
                .sort((a: any, b: any) => a.position - b.position);

            setMembershipQuestions([...customQuestions]);
        }

    }, [communityFieldsData])

    useEffect(() => {
        if (requiredCustomDefaultdata) {
            requiredCustomDefaultdata.map((data: any) => {
                if (
                    (data.name === 'City' && userData && userData.location === null)
                    || requiredKeyFinder('location')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'location',
                    ]);
                }

                if (
                    (data.name === 'Country' && userData && userData.country_id === null)
                    || requiredKeyFinder('country_id')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'country_id',
                    ]);
                }
                if (
                    (data.name === 'Timezone' && userData && userData.timezone_id === null)
                    || requiredKeyFinder('timezone_id')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'timezone_id',
                    ]);
                }

                if (
                    (data.name === 'Profile photo' && userData && userData.profile_image === null)
                    || requiredKeyFinder('profile-picture')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'profile-picture',
                    ]);
                }

                const headlineEl = document.getElementById("headline");
                if (data.name === 'Headline' && userData && userData.headline === null) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'headline',
                    ]);
                } else if (headlineEl && headlineEl.hasAttribute("required") &&
                    profileData &&
                    (profileData.hasOwnProperty('headline') && profileData['headline'] === '')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'headline',
                    ]);
                }

                if (
                    (data.name === 'Job title' && userData && userData.job_title === null)
                    || requiredKeyFinder('job_title')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'job_title',
                    ]);
                }

                const bioEl = document.getElementById("about_me");
                if (data.name === 'Bio' && userData && userData.about_me === null) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'about_me',
                    ]);
                } else if (bioEl && bioEl.hasAttribute("required") && data.name === 'Bio' &&
                    profileData &&
                    (profileData.hasOwnProperty('about_me') && profileData['about_me'] === '')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'about_me',
                    ]);
                }

                if (
                    (data.name === 'Instagram' && userData && userData.instagram === null)
                    || requiredKeyFinder('instagram')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'instagram',
                    ]);
                }
                if ((data.name === 'Facebook' && userData && userData.facebook === null)
                    || requiredKeyFinder('facebook')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'facebook',
                    ]);
                }
                if ((data.name === 'LinkedIn' && userData && userData.linkedin === null)
                    || requiredKeyFinder('linkedin')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'linkedin',
                    ]);
                }
                if ((data.name === 'Web URL' && userData && userData.website === null)
                    || requiredKeyFinder('website')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'website',
                    ]);
                }
                if ((data.name === 'Twitter' && userData && userData.twitter === null)
                    || requiredKeyFinder('twitter')
                ) {
                    setRequiredKeys((prevRequiredKeys: any) => [
                        ...prevRequiredKeys,
                        'twitter',
                    ]);
                }

            })
        }
    }, [requiredCustomDefaultdata, userData])

    const postToChannel = () => {
        setIsPosting(true)
        PostService.getInstance()
            .createIntroductionPost(
                group && group.id,
                postData
            )
            .then((response: any) => {
                if (response) {
                    toast("Introduction posted successfully", {
                        autoClose: 3000,
                        type: ToastType.SUCCESS,
                    });
                }
            })
            .finally(() => {
                // history.push(`/`)
                goToNextSection()
                setIsPosting(false)
            }).catch(() => setIsPosting(false))
    }

    useEffect(() => {
        if (postData !== null) {
            if (
                postData.description &&
                (
                    postData.description.trim() === '<p><br></p>' ||
                    postData.description.trim() === '' ||
                    (/^<p>(&nbsp;|\s)*<\/p>$/.test(postData.description))
                )
            ) {
                toast("Introduction description is required", {
                    type: ToastType.ERROR,
                    autoClose: 3000,
                });
                return
            } else {
                postToChannel()
            }
        } else {
            return
        }
    }, [postData])

    const submitCustomFieldsAnswers = () => {
        //TODO
        // updateMemberOnboardingStepsData({
        //     ...BEonboardingcompletedSteps,
        //     custom_fields: true,
        // })
        setBEonboardingcompletedSteps((prev: any) => ({
            ...prev,
            custom_fields: true,
        }));
    }


    const submitBtnFn = () => {
        if (currentSection === 'welcome') {
            // updateMemberOnboardingStepsData({
            //     ...BEonboardingcompletedSteps,
            //     welcome: true,
            // })
            setBEonboardingcompletedSteps((prev: any) => ({
                ...prev,
                welcome: true,
            }));
            goToNextSection()
        }
        if (currentSection === 'default_fields') {
            if (validateProfileFields()) {
                setBEonboardingcompletedSteps((prev: any) => ({
                    ...prev,
                    default_fields: true,
                }));
                goToNextSection()
            }
        }
        if (currentSection === 'custom_fields') {

            const saveEl = document.getElementById(`complete-questions-button`) as HTMLElement;
            if (saveEl) saveEl.click()
        }
        if (currentSection === 'introduction') {
            createPost();
        }
    }

    const customcommunityFieldsData = communityFieldsData &&
        communityFieldsData.filter((category: any) => category.category === 'default'
            && (category.required || category.ask_during_sign_up)
        )

    return (
        <CommunityOnboardingStepsContext.Provider
            value={{
                checkedCustomRequiredFields,
                setCheckedCustomRequiredFields,
                submitCustomFieldsAnswers,
                goToNextSection,
                membershipQuestions,
                responseState, setResponseState
            }}
        >
            <ReactModal
                className="onboarding-steps-overlay"
                isOpen={isOpen}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        zIndex: 99999,
                    }
                }}
            >
                <div className='onboarding-step' ref={popupRef}>
                    <div className="onboarding-step-header">
                        <span> Step {stepsCovered} of {' '} {onboardingStepsList.length}</span>
                        <div className='steps-indicator'>
                            {
                                onboardingStepsList.includes('welcome') &&
                                <div
                                    className='indicator'
                                    style={{
                                        backgroundColor: currentSection === 'welcome'
                                            ? '#365de6' : ''
                                    }}
                                    key='welcome'
                                />
                            }

                            {
                                onboardingStepsList.includes('default_fields') &&
                                <div
                                    className='indicator'
                                    style={{
                                        backgroundColor: currentSection === 'default_fields'
                                            ? '#365de6' : ''
                                    }}
                                    key='default_fields'
                                />
                            }

                            {
                                onboardingStepsList.includes('custom_fields') &&
                                <div
                                    className='indicator'
                                    style={{
                                        backgroundColor: currentSection === 'custom_fields'
                                            ? '#365de6' : ''
                                    }}
                                    key='custom_fields'
                                />
                            }

                            {
                                onboardingStepsList.includes('introduction') &&
                                <div
                                    className='indicator'
                                    style={{
                                        backgroundColor: currentSection === 'introduction'
                                            ? '#365de6' : ''
                                    }}
                                    key='introduction'
                                />
                            }
                        </div>
                    </div>

                    <div
                        className={`onboarding-step-body ${currentSection === 'default_fields' && customcommunityFieldsData && customcommunityFieldsData.length <= 5 ? 'full-default-screen' : ''}`}
                    >
                        {currentSection === 'welcome' &&
                            <CommunityWelcomeScreen
                                welcomeScreenData={welcomeScreenData && welcomeScreenData}
                            />
                        }
                        {currentSection === 'default_fields' &&
                            <ProfileDetails
                                group={group && group}
                                profileDetailsStepsData={communityFieldsData}
                                profileData={profileData}
                                setProfileData={setProfileData}
                                profileFile={profileFile}
                                setProfileFile={setProfileFile}
                                userId={userId}
                                userData={userData}
                                setUserData={setUserData}
                            profileNewSrc={profileNewSrc}
                            setProfileNewSrc={setProfileNewSrc}
                            requiredDefaultKeys={requiredDefaultKeys}
                            />
                        }
                        {currentSection === 'custom_fields' &&
                            <CustomQuestions group={group && group} />
                        }
                        {currentSection === 'introduction' &&
                            <CommunityIntroduction
                                introData={introData && introData}
                                group={group}
                            postData={postData}
                            setpostData={setpostData}
                            popupRef={popupRef}
                            />
                        }
                    </div>

                    <div
                        className={`onboarding-step-button-footer ${currentSection === 'custom_fields' ?
                            'custom-field-footer' : ""} ${currentSection === 'default_fields' ? 'profile-field-btn' : ""}`
                        }
                    >
                        {isFirstStepIndex !== 0 &&
                            <button
                                className='previous'
                                onClick={() => goPreviousSection()}
                            >
                                Previous
                            </button>
                        }

                        <button onClick={() => submitBtnFn()}>
                            {determineNextButtonText()}
                        </button>
                    </div>
                </div>
            </ReactModal>
        </CommunityOnboardingStepsContext.Provider>
    )
}

export default CommunityOnboardingSteps