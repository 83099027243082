import React from 'react';

const EmbedIcon = (props: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
    <path d="M12 2L2 7L12 12L22 7L12 2Z" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 17L12 22L22 17" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 12L12 17L22 12" stroke={props.stroke ? props.stroke : "#767A8A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
  )
}

export default EmbedIcon;