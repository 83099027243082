const validator: any = {
  name: {
    rules: [
      {
        // test: /^.{0,2}$/,
        test: /^.{0,1}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: (value: any) => {
          return /[^A-Za-z0-9]*$/.test(value);
        },
        message: "Name can't contain special characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  accepted_tos: {
    rules: [
      {
        test: (value: any) => {
          return value === true;
        },
        message: "Accept terms of service & privacy policy."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  bt_filter: {
    rules: [
      {
        test: (value: any) => {
          return value === null;
        },
        message: "you a bot"
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  channel_name: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: (value: any) => {
          return value.length <= 21;
        },
        message: "Name must not be longer than 21 characters"
      },
      {
        test: (value: any) => {
          return /^[a-zA-Z0-9_-]*$/.test(value);
        },
        message: "Can’t contain special characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  group_name: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  groupName: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  description: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  subdomain: {
    rules: [
      {
        // test: /^.{0,2}$/,
        test: /^.{0,1}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: (value: any) => {
          return /^[a-zA-Z0-9_]*$/.test(value);
        },
        message: "Name can't contain special characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  posting_permission: {
    rules: [
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: true,
    invalid: true,
    state: ""
  },
  status: {
    rules: [
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: true,
    invalid: true,
    state: ""
  },
  full_name: {
    rules: [
      {
        test: /[^a-zA-Z \']/g,
        message: "Name can't contain special characters"
      },
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  lastname: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  },
  email: {
    rules: [
      // {
      //   test: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //   message: "Email is not valid",
      //   invertTest: true
      // },
      {
        test: /^.{0,2}$/,
        message: "Email or username must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  },
  password: {
    rules: [
      {
        test: (value: any) => {
          return value.length >= 8;
        },
        message: "Password must be at least 8 characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      },
      {
        test: (value: any) => {
          return value.length <= 20;
        },
        message: "Password cannot be more than 20 characters"
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  },
  confirmPassword: {
    rules: [
      {
        matchPassword: (confirmPassword: string, password: string) => {
          return confirmPassword === password;
        },
        message: "Password do not match"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  }
};

export default validator;
