import React from "react";
import { ILoginComponentProps } from "./ILoginComponentProps";

import { withRouter } from "react-router-dom";

import "./LoginComponent.scss";
import "./LoginComponentResponsive.scss";
import Login from "containers/AuthContainer/loginComponent";

const LoginComponent: React.FC<ILoginComponentProps> = props => {


  return (
    <div className="gp-login-wrapper">
      <div className="gp-login-logo">
        <img src={require("containers/selfSignup/logo.png")} alt="link" />
      </div>
      <Login
        title="Welcome to GroupApp"
        subtitle="Log in to your account."
        extClass="popup"
        subdomain={props.subdomain}
        noAccount={true}
      />
    </div>
  );
};

export default withRouter((LoginComponent as any) as any) as any;
