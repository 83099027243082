import React from 'react';
import './GroupPostListView.scss';
// import anchorme from 'anchorme';
import Moment from 'react-moment';
import { ReactComponent as NoImageIcon } from '../../../../containers/groupView/GroupDetailsCard/icons/no-image.svg';
// import DraftContent from '../DraftContent';
import { ReactComponent as LoveIcon } from 'components/commentComponent/icons/love.svg';
import { ReactComponent as LoveIconFill } from 'components/commentComponent/icons/love-fill.svg';
import { ReactComponent as CommentIcon } from 'components/commentComponent/icons/comment.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast, ToastType } from 'react-toastify';
import miscUtils from 'utils/miscUtils';
import { useHistory } from 'react-router';
import FlexyPopOnMe from 'elements/flexypoponme';
import { ReactComponent as Pinned } from "../flag.svg";
import ReadMore from 'utils/readMore/readMore';
import { Link } from "react-router-dom";

const TITLE_CHARACTER_LIMIT: number = 100;

const GroupPostListView = (props: any) => {
    const history = useHistory();

    const openSinglePostPage = () => {
        history.push(`/posts/${props.post.slug}`);
    }

    const copyToClipboard = () => {
        toast("Post URL copied", {
            type: ToastType.SUCCESS,
            autoClose: 3000
        });
    }

    const getCustomTitle = () => {
        return miscUtils.stripHTML(props.post.description).slice(0, 200);
    }

    return (
        <div
            className={`group-post-list-container ${props.topCard ? 'topcard' : ''}`}
        // onClick={openSinglePostPage}
        >
            {!props.hideCover ? (
                <div
                    className="group-post-list-cover-container"
                    onClick={openSinglePostPage}
                >
                    {props.post.cover_image_url ? (
                        <div
                            className="group-post-list-cover-wrapper"
                        >
                            <img
                                className="group-post-list-cover-image"
                                src={props.post.cover_image_url}
                            />
                        </div>
                    ) : (
                        <div
                            className="group-post-list-cover-wrapper"
                        >
                            <span
                                className="group-post-list-cover-no-image"
                            >
                                <NoImageIcon
                                />
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="group-post-list-cover-container hidden-cover"
                    onClick={openSinglePostPage}
                >
                    <div
                        className="group-post-list-cover-wrapper hidden-cover"
                    >
                        {props.post.author.profile_image ? (
                            <img
                                alt="avatar"
                                className="group-post-list-no-cover-image"
                                src={props.post.author.profile_image}
                            />
                        ) : (
                            <div
                                className="group-post-list-no-cover-no-image"
                                data-color={miscUtils.getColorInitial(`${props.post.author.full_name}`)}
                            >
                                {miscUtils.getNameInitials(props.post.author.full_name)}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div
                className={`group-post-list-content-container ${props.hideCover ? 'full-width' : ''}`}
            >
                {props.post.title ? (
                    <>
                        {/* <h1
                className={`group-post-list-content-title ${props.hideCover ? 'hidden-cover' : ''}`}
                onClick={openSinglePostPage}
                >
                    {props.post.title}
                </h1> */}
                        <Link
                            className={`group-post-list-content-title-container ${props.hideCover ? 'hidden-cover' : ''}`}
                            // onClick={openSinglePostPage}
                            to={`/posts/${props.post.slug}`}
                        >
                            <ReadMore
                                text={props.post.title}
                                numberOfLines={1}
                                lineHeight={1}
                                showDefaultButton={false}
                                readMoreCharacterLimit={TITLE_CHARACTER_LIMIT}
                            />
                        </Link>
                    </>
                ) : (
                    <>
                        {/* <h1
                className={`group-post-list-content-title ${props.hideCover ? 'hidden-cover' : ''}`}
                onClick={openSinglePostPage}
                >
                    {getCustomTitle()}
                </h1> */}
                        <Link
                            className={`group-post-list-content-title-container ${props.hideCover ? 'hidden-cover' : ''}`}
                            // onClick={openSinglePostPage}
                            to={`/posts/${props.post.slug}`}
                        >
                            <ReadMore
                                text={getCustomTitle()}
                                numberOfLines={1}
                                lineHeight={1}
                                showDefaultButton={false}
                                readMoreCharacterLimit={TITLE_CHARACTER_LIMIT}
                            />
                        </Link>
                    </>
                )}
                {/* {
            props.post && props.post.description && props.post.description_json ?
            (<div
            className={`group-post-list-content-description-container ${!props.post.title ? 'no-title' : ''}`}
            >
                <DraftContent
                post={props.post}
                theme={props.theme}
                disableCharacterLimit={props.disableCharacterLimit}
                smallHeader
                />
            </div>) : (<></>)
            } */}
                {/* {
            props.post && props.post.description && !props.post.description_json &&
            <div
            className={`group-post-list-content-description-container ${!props.post.title ? 'no-title' : ''}`}
            >
                <span
                    dangerouslySetInnerHTML={{
                    __html: anchorme(props.post.description)
                    }}
                />
            </div>
            } */}
                <div
                    className="group-post-list-content-footer-container"
                >
                    <div
                        className="group-post-list-content-footer-user-container"
                        onClick={openSinglePostPage}
                    >
                        <p
                            className="group-post-list-content-footer-name"
                        >
                            {props.post.author.full_name}
                        </p>

                        <div
                            className="group-post-list-content-footer-timestamp"
                        >
                            <Moment utc={true} fromNow ago>
                                {props.post.published_at}
                            </Moment>
                            <span>ago</span>
                        </div>
                    </div>
                </div>

                {(props.pinnedpost || props.announcementPost) ? (
                    <div
                        className={`group-post-list-content-pinned-container ${props.hideCover ? 'hidden-cover' : ''}`}
                    >
                        <Pinned
                            fill={
                                props.theme && props.theme.custom ? props.theme.custom.primaryColor : "#365DE6"
                            }
                        />
                    </div>
                ) : (<></>)}

                <div
                    className={`group-post-list-content-footer-reaction-container ${props.hideCover ? props.pinnedpost ? 'hidden-cover-pinned' : 'hidden-cover' : ''}`}
                >
                    {!props.disableReactions ? (
                        <>
                            <div
                                className="group-post-list-content-footer-unit-container no-margin"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    props.likeUnlikeReaction();
                                }}
                            >
                                {props.postReactionLikeState.liked ? <span><LoveIconFill /></span> : <span><LoveIcon /></span>}
                                <p
                                    className="group-post-list-content-footer-unit-label"
                                >
                                    {props.postReactionLikeState.reaction_like_count}
                                </p>
                            </div>

                            <div
                                className="group-post-list-content-footer-unit-container"
                                onClick={() => {
                                    if (props.view === "public") {
                                        props.publicLogin();
                                        return;
                                    }

                                    openSinglePostPage();
                                }}
                            >
                                {<span><CommentIcon /></span>}
                                <p
                                    className="group-post-list-content-footer-unit-label"
                                >
                                    {props.commentsCount}
                                </p>
                            </div>
                        </>
                    ) : (<></>)}

                    <CopyToClipboard
                        text={`${window.location.origin}/posts/${props.post.slug}`}
                        onCopy={() => {
                            copyToClipboard();
                        }}
                    >
                        <div
                            className="group-post-list-content-footer-unit-container"
                            onClick={(e: any) => e.stopPropagation()}
                        >
                            <span><ShareIcon /></span>
                        </div>
                    </CopyToClipboard>

                    {props.user && props.isFlexyOpen ? (
                        <FlexyPopOnMe
                            alwaysOpen={false}
                            component={
                                <>
                                    <ul className="group-dropdown-post dropdown post-dropdown-listview">
                                        <li>
                                            {((props.group && props.group.user_membership && miscUtils.isAdmin(props.group.user_membership)) || (props.user && props.user.id === props.post.author.id)) && (!props.announcementPost) && (
                                                <button
                                                    onClick={() => {
                                                        props.setIsEditMode(true);
                                                    }}
                                                    className="group-post-dropdown-btn group-post-dropdown-btn--edit-post"
                                                >
                                                    <p className="main-text">Edit</p>
                                                </button>
                                            )}
                                            {props.canBroadcast && (
                                                <button
                                                    onClick={() => props.broadcastPost()}
                                                    className="group-post-dropdown-btn"
                                                >
                                                    <p className="main-text">Broadcast</p>
                                                </button>
                                            )}

                                            {!props.announcementPost && props.isAdmin && props.pinnable && (
                                                <>
                                                    {props.post.pinned_at ? (
                                                        <button
                                                            onClick={() => {
                                                                if (
                                                                    window.location.pathname.includes("lectures") && (window.location.pathname.includes("library") || window.location.pathname.includes("courses"))
                                                                ) {
                                                                    if (props.match.params.contentId) {
                                                                        props.unpinPostFromPostable(props.match.params.contentId, props.post.id, 'course-content')
                                                                    }

                                                                    if (props.match.params.libraryItemId) {
                                                                        props.unpinPostFromPostable(props.post.postable_id, props.post.id, props.match.params.libraryItemType.slice(0, -1))
                                                                    }
                                                                } else if (
                                                                    window.location.pathname.split("/")[1] === "events"
                                                                ) {
                                                                    props.unpinPostFromPostable(props.post.postable_id, props.post.id, 'event')
                                                                } else {
                                                                    props.unPinPost(props.post.postable_id, props.post.id);
                                                                }
                                                            }}
                                                            className="group-post-dropdown-btn"
                                                        >
                                                            <p className="main-text">Unpin post</p>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => {
                                                                if (
                                                                    window.location.pathname.includes("lectures") && (window.location.pathname.includes("library") || window.location.pathname.includes("courses"))
                                                                ) {
                                                                    if (props.match.params.contentId) {
                                                                        props.pinPostFromPostable(props.match.params.contentId, props.post.id, 'course-content')
                                                                    }

                                                                    if (props.match.params.libraryItemId) {
                                                                        props.pinPostFromPostable(props.post.postable_id, props.post.id, props.match.params.libraryItemType.slice(0, -1))
                                                                    }
                                                                } else if (
                                                                    window.location.pathname.split("/")[1] === "events"
                                                                    ) {
                                                                        props.pinPostFromPostable(props.post.postable_id, props.post.id, 'event')
                                                                    } else {
                                                                        props.pinPost(props.post.postable_id, props.post.id);
                                                                    }
                                                                }}
                                                                className="group-post-dropdown-btn"
                                                            >
                                                            <p className="main-text">Pin post</p>
                                                        </button>
                                                    )}
                                                </>
                                            )}

                                            {/* Feature a post */}
                                            {props.post && (miscUtils.isTeamMember(props.group && props.group.user_membership)) && window.location.pathname.split("/")[1] === 'channel' ? (
                                                <button
                                                    className="group-post-dropdown-btn"
                                                    onClick={() => props.post.featured_at === null ? props.featureAPost() : props.removefeaturedPost()}
                                                >
                                                    <p className="main-text">
                                                        {props.post.featured_at === null ? 'Feature this post' : 'Remove featured post'}
                                                    </p>
                                                </button>
                                            ) : (<></>)}

                                            {!props.announcementPost && (
                                                <button
                                                    onClick={() => props.updateUserNotifications(props.user.id, props.post.id)}
                                                    className="group-post-dropdown-btn"
                                                >
                                                    <p className="main-text">
                                                        {props.post &&
                                                            props.post.post_settings &&
                                                            props.post.post_settings.email_notification
                                                            ? "Turn off post notifications"
                                                            : "Turn on post notifications"}
                                                    </p>
                                                </button>
                                            )}

                                            {/* end members can flag a post */}
                                            {props.post && (miscUtils.isTeamMember(props.group && props.group.user_membership)) ? (
                                                <button
                                                    className="group-post-dropdown-btn"
                                                    onClick={props.updateCommentingStatus}
                                                >
                                                    <p
                                                        className="main-text"
                                                    >
                                                        {props.post.commenting_closed ? 'Open commenting' : 'Close commenting'}
                                                    </p>
                                                </button>
                                            ) : (<></>)}

                                            {/* Convert into an article */}
                                            {
                                                props.post &&
                                                    props.post.postable_type !== 'articles' &&
                                                    (miscUtils.isTeamMember(props.group && props.group.user_membership)) &&
                                                    !props.announcementPost ? (
                                                    <button
                                                        className="group-post-dropdown-btn"
                                                        onClick={props.convertPostToArticle}
                                                    >
                                                        <p className="main-text">Convert into an article</p>
                                                    </button>
                                                ) : (<></>)}


                                            {/* members can flag a post */}
                                            {(!props.announcementPost) && (props.user.id !== props.post.author.id) &&
                                                <button
                                                onClick={() => props.flagPost(props.post.id)}
                                                className="group-post-dropdown-btn"
                                            >
                                                <p className="main-text">Flag post</p>
                                            </button>}

                                            {(!props.announcementPost && (props.isAdmin || (props.user && props.user.id === props.post.author.id))) && (
                                                <button
                                                    onClick={() => props.openDeleteModal()}
                                                    className="group-post-dropdown-btn"
                                                >
                                                    <p className="main-text">Delete post</p>
                                                </button>
                                            )}
                                        </li>
                                    </ul>
                                </>
                            }
                        >
                            <div
                                className="group-post-list-content-footer-unit-container list-view-menu-container"
                            >
                                <button
                                    className="btn btn-dots btn-post-dots"
                                >
                                    <span />
                                    <span />
                                    <span />
                                </button>
                            </div>
                        </FlexyPopOnMe>
                    ) : (<></>)}
                </div>
            </div>
        </div>
    )
}

export default GroupPostListView;