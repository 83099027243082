import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IAppInfoBannerProps } from './IAppInfoBannerProps';
import './AppInfoBanner.scss';

const AppInfoBanner: React.FC<IAppInfoBannerProps> = (props) => {

  const [showBanner, setShowBanner] = React.useState<boolean>(props.showByDefault)

  return (
    <div className={`app-info-banner ${!showBanner ? 'app-info-banner--hidden' : ''}`}>
        <span
        className="gp-app-info-banner-remover-container"
        onClick={() => setShowBanner(false)}
      >
        <FontAwesomeIcon
          icon={faTimes}
        />
      </span>
        <span>
            {props.message}
        </span>
    </div>
  )
}

export default AppInfoBanner;