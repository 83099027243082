import React from 'react';
import './PostAttachment.scss';
import './PostAttachmentResponsive.scss';

import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { IPostAttachmentProps } from './IPostAttachmentProps';
import HorizontalSlideScroll from 'components/HorizontalSlideScroll';
import { AttachmentTypes, FooterIconTypes } from '../domain';
import FooterIcon from '../PostableEditorFooter/FooterIcon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as TrashIcon } from './trash.svg';
import { ReactComponent as AddIcon } from 'assets/img/add.svg';
import { ReactComponent as FileIcon } from 'assets/img/file.svg';


const SingleImageAttachment = (
    props: {
        attachment: any,
        editMode: boolean | undefined,
        removeIMGAttachment: any,
        fileIsUploading: boolean | undefined
        uploadProgress: number
        theme: any
        onCancelUpload: any
        fullScreen: any
    }
) => {

    const [imageSrc, setImageSrc] = React.useState<any>(null);
    const [previousDataUrl, setPreviousDataUrl] = React.useState("");
    const [showCancelButton, setShowCancelButton] = React.useState<boolean>(false);

    const { attachment } = React.useMemo(() => props, [props]);

    async function handleImageFileUpload(fileImage: File) {
        if (!fileImage) return;

        const reader = new FileReader();

        const dataUrl = await new Promise<string>((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                reject(new DOMException('Problem parsing input image file.'));
            };

            reader.onloadend = () => {
                resolve(reader.result as string);
            };

            reader.readAsDataURL(fileImage);
        });

        if (dataUrl !== previousDataUrl) {
            setImageSrc(dataUrl);
            setPreviousDataUrl(dataUrl);
        }
    }

    React.useEffect(() => {
        if (!props.editMode || (attachment instanceof Blob)) {
            handleImageFileUpload(attachment)
        }
    }, [imageSrc, attachment, previousDataUrl])

    const [screenWidth, setScreenWidth] = React.useState<number>(document.documentElement.clientWidth);
    React.useEffect(() => {
        const resizeCallback = () => {
            setScreenWidth(document.documentElement.clientWidth);
        };
        window.addEventListener("resize", resizeCallback);

        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, []);

    return (
        <div
            className="image-attachment-upload-attachment-container"
            style={{
                marginRight: (props.fullScreen && screenWidth > 768) ? '20px' : '',
                width: (props.fullScreen && screenWidth > 768) ? '305px' : '',
                height: (props.fullScreen && screenWidth > 768) ? '250px' : '',
            }}
        >
            {imageSrc === null && (attachment instanceof Blob)
                ?
                <span className="loading-image-text">
                    Loading image...
                </span>
                :
                <img
                    className="image-attachment-upload-attachment-details-image"
                    id='image_attachment'
                    src={
                        props.editMode && !(attachment instanceof Blob)
                            ? attachment.image_src : imageSrc !== null && imageSrc
                    }
                    alt="image attachment"
                    style={{ width: (props.fullScreen && screenWidth > 768) ? '301px' : '' }}
                />
            }

            <div className="image-attachment-upload-attachment-details-footer">
                {props.editMode && !(attachment instanceof Blob) ?
                    <span
                        onClick={() => {
                            props.removeIMGAttachment(attachment.image_id, undefined)
                        }}
                    >
                        <TrashIcon />
                    </span>
                    :
                    <span
                        onClick={() => {
                            props.removeIMGAttachment(undefined, attachment.name)
                        }}
                    >
                        <TrashIcon />
                    </span>
                }
            </div>

            <div className="post-attachment-upload-attachment-action-container">
                {props.fileIsUploading &&
                    <div
                        className="post-attachment-upload-attachment-upload-progress-container"
                        onMouseOver={() => setShowCancelButton(true)}
                        onMouseOut={() => setShowCancelButton(false)}
                    >
                        <CircularProgressbarWithChildren
                            value={props.uploadProgress}
                            className="post-attachment-upload-attachment-upload-progress-spinner"
                            styles={buildStyles({
                                pathColor: (props.theme && props.theme.custom) ? props.theme.custom.primaryColor : '#365DE6',
                            })}
                        >
                            {!showCancelButton &&
                                <span className="post-attachment-upload-attachment-upload-progress-spinner-percentage">
                                    {props.uploadProgress}%
                                </span>
                            }
                            {showCancelButton &&
                                <span
                                    className="post-attachment-upload-attachment-upload-progress-spinner-cancel"
                                    onClick={props.onCancelUpload}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                            }
                        </CircularProgressbarWithChildren>
                    </div>
                }
            </div>
        </div>
    )
}

const ImageAttachmentDisplay = (props: {
    attachments: any,
    scrollRef: any,
    editMode: any,
    removeIMGAttachment: any
    fileIsUploading: any
    uploadProgress: any
    theme: any
    onCancelUpload: any
    onIconClick: any
    announcementMode: boolean
    fullScreen: any
}) => {
    const [screenWidth, setScreenWidth] = React.useState<number>(document.documentElement.clientWidth);
    React.useEffect(() => {
        const resizeCallback = () => {
            setScreenWidth(document.documentElement.clientWidth);
        };
        window.addEventListener("resize", resizeCallback);

        return () => {
            window.removeEventListener("resize", resizeCallback);
        };
    }, []);

    return (
        <div
            className='attachment-wrapper'
            id="attachment-wrapper"
            ref={props.scrollRef}
        >
            {props.attachments.map((attachment: any, index: any) => (
                <SingleImageAttachment
                    key={index}
                    attachment={attachment}
                    editMode={props.editMode}
                    removeIMGAttachment={props.removeIMGAttachment}
                    fileIsUploading={props.fileIsUploading}
                    uploadProgress={props.uploadProgress}
                    theme={props.theme}
                    onCancelUpload={props.onCancelUpload}
                    fullScreen={props.fullScreen}
                />
            ))}
            <div
                className={`add-image-attachment`}
                style={{
                    padding: (props.fullScreen && screenWidth > 800) ? '0px 120px ' : '',
                    marginLeft: (props.attachments && (props.attachments.length >= 4 && props.attachments.length !== 5) && window.innerWidth <= 550) ? '160px' : '',
                    display: props.attachments && props.attachments.length >= 5 ? 'none' : ''
                }}
            >
                <span
                    className='add-icon'
                    onClick={() => props.onIconClick(FooterIconTypes.IMAGE)}>
                    <AddIcon />
                </span>
            </div>

            {
                /* placeholder divs so that the arrows appear on large screen.
                This was a only quick  way I could work around the issue
                of missing arrows on larges screens */
            }

            {
                window.innerWidth > 550 &&
                <div
                    className="add-image-attachment-small"
                    style={{ visibility: 'hidden' }}
                >
                </div>
            }

            {(props.fullScreen || props.announcementMode)
                && <>
                {
                    window.innerWidth > 550 &&
                    <div
                    className="add-image-attachment-small"
                    style={{ visibility: 'hidden' }}
                    >
                    </div>
                }
                    <div
                    className="add-image-attachment-small"
                    style={{ visibility: 'hidden' }}
                    >
                    </div>
                </>
            }
        </div>
    )
}

const ImageAttachment = React.memo((props:
    {
        attachment: any;
        editMode: any;
        removeIMGAttachment: any;
        fileIsUploading: any;
        uploadProgress: any;
        theme: any;
        onCancelUpload: any;
        onIconClick: any
        announcementMode: any
        fullScreen: any

    }) => {

    return (
        <HorizontalSlideScroll slidingElementId="attachment-wrapper">
            <ImageAttachmentDisplay
                attachments={props.attachment}
                scrollRef={null}
                editMode={props.editMode}
                removeIMGAttachment={props.removeIMGAttachment}
                fileIsUploading={props.fileIsUploading}
                uploadProgress={props.uploadProgress}
                theme={props.theme}
                onCancelUpload={props.onCancelUpload}
                onIconClick={props.onIconClick}
                announcementMode={props.announcementMode}
                fullScreen={props.fullScreen}
            />
        </HorizontalSlideScroll>
    )
});

const FileAttachMent = (
    props: {
        attachment: any,
        removeFILEAttachment: any,
        scrollRef: any,
        onIconClick: any
        fileIsUploading: any
        uploadProgress: any
        theme: any
        onCancelUpload: any
        editMode: boolean | undefined
        fullScreen: boolean | undefined
    }) => {

    const [showCancelButton, setShowCancelButton] = React.useState<boolean>(false);

    const getFileAttachmentSize = (attachmentSize: any) => {
        return (attachmentSize / 1000000).toFixed(2);
    }

    return (
        <div
            className="file-attachment"
            id='file-attachment-wrapper'
            ref={props.scrollRef}
        >
            {props.attachment.map((att: any, index: any) => (
                <div
                    key={index}
                    className="file-attachment-wrapper"
                >
                    <div className="file-attachment-details" >
                        <span className="file-attachment-details-icon">
                            <FileIcon />
                        </span>
                        <div className="file-attachment-details-body">
                            <div
                                className="file-attachment-details-body-title"
                                style={{
                                    maxWidth: (props.fullScreen && window.innerWidth > 800) ? '180px' : '',
                                    width: (props.fullScreen && window.innerWidth > 800) ? '180px' : '',
                                }}
                            >
                                <span className="title-text">{att.name}</span>
                            </div>
                            <span className="file-attachment-details-body-size">
                                {getFileAttachmentSize(att.size)} MB
                            </span>
                        </div>
                    </div>

                    <div className="post-attachment-upload-attachment-action-container">
                        {props.fileIsUploading ?
                            <div
                                className="post-attachment-upload-attachment-upload-progress-container"
                                onMouseOver={() => setShowCancelButton(true)}
                                onMouseOut={() => setShowCancelButton(false)}
                            >
                                <CircularProgressbarWithChildren
                                    value={props.uploadProgress}
                                    className="post-attachment-upload-attachment-upload-progress-spinner"
                                    styles={buildStyles({
                                        pathColor: (props.theme && props.theme.custom) ? props.theme.custom.primaryColor : '#365DE6',
                                    })}
                                >
                                    {
                                        !showCancelButton &&
                                        <span className="post-attachment-upload-attachment-upload-progress-spinner-percentage">
                                            {props.uploadProgress}%
                                        </span>
                                    }
                                    {
                                        showCancelButton &&
                                        <span
                                            className="post-attachment-upload-attachment-upload-progress-spinner-cancel"
                                            onClick={props.onCancelUpload}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                            />
                                        </span>
                                    }
                                </CircularProgressbarWithChildren>
                            </div>
                            :
                            <>
                                {props.editMode && !(att instanceof Blob) ?
                                    <span
                                        onClick={() => {
                                            props.removeFILEAttachment(att.file_id, undefined)
                                        }}
                                    >
                                        <TrashIcon />
                                    </span>
                                    :
                                    <span
                                        onClick={() => {
                                            props.removeFILEAttachment(undefined, att.name)
                                        }}
                                    >
                                        <TrashIcon />
                                    </span>
                                }
                            </>
                        }
                    </div>
                </div>
            ))}

            <div
                className="add-file-attachment"
                style={{
                    padding: (props.fullScreen && window.innerWidth > 800) ? '12px 140px ' : ''

                }}
            >
                <span
                    className='add-file-icon'
                    onClick={() => props.onIconClick(FooterIconTypes.FILE)}>
                    <AddIcon />
                </span>
            </div>

            {
                /* placeholder divs so that the arrows appear on large screen.
                This was a only quick  way I could work around the issue
                of missing arrows on larges screens */
            }

            <div
                className="add-file-attachment"
                style={{ visibility: 'hidden' }}
            >
            </div>
            <div
                className="add-file-attachment"
                style={{ visibility: 'hidden' }}
            >
            </div>

            {props.fullScreen &&
                <>
                    <div
                        className="add-file-attachment"
                        style={{ visibility: 'hidden' }}
                    >
                    </div>
                    <div
                        className="add-file-attachment"
                        style={{ visibility: 'hidden' }}
                    >
                    </div>
                    <div
                        className="add-file-attachment"
                        style={{ visibility: 'hidden' }}
                    >
                    </div>
                    <div
                        className="add-file-attachment"
                        style={{ visibility: 'hidden' }}
                    >
                    </div>
                </>
            }
        </div>
    )
}

const PostAttachment: React.FC<IPostAttachmentProps> = (props) => {

    const [showCancelButton, setShowCancelButton] = React.useState<boolean>(false);

    const getDisplayAttachmentSize = () => {
        return (props.attachment.size / 1000000).toFixed(2);
    }

    const getInputPlaceholder = (): string => {
        switch (props.attachmentType) {
            case AttachmentTypes.VIDEOLINK:
                return 'Paste link from Youtube, Vimeo, Wistia URL';
            case AttachmentTypes.EMBED:
                return 'Add embed code';
            default:
                return 'Paste web link';
        }
    }

    const getAttachmentLabel = (): string => {
        switch (props.attachmentType) {
            case AttachmentTypes.LINK:
                return 'Add web link';
            case AttachmentTypes.VIDEOLINK:
                return 'Paste video URL';
            case AttachmentTypes.EMBED:
                return 'Add embed code';
            default:
                return '';
        }
    }

    switch (props.attachmentType) {
        case AttachmentTypes.LINK:
        case AttachmentTypes.VIDEOLINK:
        case AttachmentTypes.EMBED:
            return (
                <div className="post-attachment-url-attachment-container">
                    <div className="post-attachment-url-attachment-wrapper">
                        <p className="post-attachment-url-attachment-label">{getAttachmentLabel()}</p>
                        <div
                            className="post-attachment-url-attachment-input-container"
                            style={{
                                borderColor: props.theme && props.theme.custom ? props.theme.custom.primaryColor : '',
                            }}
                        >
                            <input
                                type="text"
                                className="post-attachment-url-attachment-input"
                                value={props.inputValue}
                                onChange={props.inputOnChange}
                                placeholder={getInputPlaceholder()}
                            />
                            <button
                                className="post-attachment-url-attachment-action"
                                onClick={() => props.removeAttachment()}
                                style={{
                                    color: props.theme && props.theme.custom ? props.theme.custom.primaryColor : '',
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            );
        case AttachmentTypes.AUDIO:
        case AttachmentTypes.VIDEO:
            if (props.attachment) {
                return (
                    <div className="post-attachment-upload-attachment-container">
                        <div className="post-attachment-upload-attachment-details-container">
                            <FooterIcon
                                iconType={props.activeIcon}
                                theme={props.theme}
                                active
                            />
                            <div className="post-attachment-upload-attachment-details">
                                <p className="post-attachment-upload-attachment-details-title">{props.attachment.name}</p>
                                <p className="post-attachment-upload-attachment-details-size">{getDisplayAttachmentSize()} MB</p>
                            </div>
                        </div>
                        <div className="post-attachment-upload-attachment-action-container">
                            {
                                props.fileIsUploading ?
                                    <>
                                        <div
                                            className="post-attachment-upload-attachment-upload-progress-container"
                                            onMouseOver={() => setShowCancelButton(true)}
                                            onMouseOut={() => setShowCancelButton(false)}
                                        >
                                            <CircularProgressbarWithChildren
                                                value={props.uploadProgress}
                                                className="post-attachment-upload-attachment-upload-progress-spinner"
                                                styles={buildStyles({
                                                    pathColor: (props.theme && props.theme.custom) ? props.theme.custom.primaryColor : '#365DE6',
                                                })}
                                            >
                                                {
                                                    !showCancelButton &&
                                                    <span className="post-attachment-upload-attachment-upload-progress-spinner-percentage">
                                                        {props.uploadProgress}%
                                                    </span>
                                                }
                                                {
                                                    showCancelButton &&
                                                    <span
                                                        className="post-attachment-upload-attachment-upload-progress-spinner-cancel"
                                                        onClick={props.onCancelUpload}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                        />
                                                    </span>
                                                }
                                            </CircularProgressbarWithChildren>
                                        </div>
                                    </> :
                                    <>
                                        <span
                                            onClick={() => props.removeAttachment()}
                                        >
                                            <TrashIcon />
                                        </span>
                                    </>
                            }
                        </div>
                    </div>
                );
            }
            return <></>
        case AttachmentTypes.IMAGE:
            return <ImageAttachment
                attachment={props.attachment}
                editMode={props.editMode}
                removeIMGAttachment={props.removeIMGAttachment}
                fileIsUploading={props.fileIsUploading}
                uploadProgress={props.uploadProgress}
                theme={props.theme}
                onCancelUpload={props.onCancelUpload}
                onIconClick={props.onIconClick}
                announcementMode={props.announcementMode}
                fullScreen={props.fullScreen}
            />
        case AttachmentTypes.FILE:
            return (
                <HorizontalSlideScroll slidingElementId="file-attachment-wrapper">
                    <FileAttachMent
                        attachment={props.attachment}
                        scrollRef={null}
                        removeFILEAttachment={props.removeFILEAttachment}
                        onIconClick={props.onIconClick}
                        fileIsUploading={props.fileIsUploading}
                        uploadProgress={props.uploadProgress}
                        theme={props.theme}
                        onCancelUpload={props.onCancelUpload}
                        editMode={props.editMode}
                        fullScreen={props.fullScreen}
                    />
                </HorizontalSlideScroll>
            );
        default:
            return <></>
    }
}

export default PostAttachment;
